import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { PushNotification } from 'types/pushNotification';

export type PushNotificationsContextValue = {
  pushNotifications: PushNotification[];
  selectedPushNotification: null | PushNotification;
  setSelectedPushNotification: Dispatch<SetStateAction<PushNotification | null>>;
};

const PushNotificationsContext = createContext<PushNotificationsContextValue>({} as PushNotificationsContextValue);
export const PushNotificationsProvider = PushNotificationsContext.Provider;
export const PushNotificationsConsumer = PushNotificationsContext.Consumer;

export function usePushNotifications(): PushNotificationsContextValue {
  const context = useContext(PushNotificationsContext);
  return context;
}
