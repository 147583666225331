import React, { useRef, useEffect } from 'react';
import { TextField, Grid, MenuItem, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import MyDatePicker from 'components/pickers/DatePicker';
import ImageUpload from 'components/image-upload/ImageUpload';
import { usePromotion } from 'pages/promotion/hooks/usePromotion';

const useStyles = makeStyles({
  imageContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  imageContent: {
    height: 300,
  },
});

PromotionForm.propTypes = {
  promotion: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default function PromotionForm({ promotion, handleChange, validation }) {
  const { handleNext } = usePromotion();
  const classes = useStyles();

  const inputs = {
    name: useRef(null),
    description: useRef(null),
  };

  useEffect(() => {
    if (validation.name) inputs.name.focus();
    else if (validation.description) inputs.description.focus();
    // eslint-disable-next-line
  }, [validation]);

  function handleSubmit(event) {
    event.preventDefault();
    handleNext();
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
          <TextField
            inputRef={ref => (inputs.name = ref)}
            margin="normal"
            fullWidth
            label="Nome"
            placeholder="Digite o nome da promoção"
            helperText={!!validation.name && validation.name}
            error={!!validation.name}
            autoFocus
            value={promotion.name}
            onChange={e => handleChange('name', e.target.value)}
          />
          <TextField
            inputRef={ref => (inputs.description = ref)}
            margin="normal"
            fullWidth
            label="Descrição"
            placeholder="Digite o a descrição da promoção"
            helperText={!!validation.description && validation.description}
            error={!!validation.description}
            value={promotion.description}
            onChange={e => handleChange('description', e.target.value)}
            multiline
            minRows={5}
          />
          <ImageUpload
            className={classes.imageContainer}
            containerClassname={classes.imageContent}
            image={promotion.image}
            validationError={validation.image}
            label="Imagem da promoção"
            handleRemoveImage={() => handleChange('image', null)}
            handleSetImage={image => handleChange('image', image)}
          />
          <TextField
            select
            margin="normal"
            label="Tipo da promoção"
            value={promotion.type}
            onChange={e => handleChange('type', e.target.value)}
            fullWidth
          >
            <MenuItem value="get">Compre e ganhe</MenuItem>
            <MenuItem value="safe">Desconto no carrinho</MenuItem>
          </TextField>
          <TextField
            select
            margin="normal"
            label="Regras de promoção"
            value={promotion.rule_type}
            onChange={e => handleChange('rule_type', e.target.value)}
            fullWidth
          >
            <MenuItem value="product">Produtos</MenuItem>
            <MenuItem value="category">Categorias</MenuItem>
            <MenuItem value="order_value">Valor do pedido</MenuItem>
          </TextField>
          <MyDatePicker
            label="Válido até"
            placeholder="Digite a data de expiração da promoção"
            value={promotion.valid_at}
            onChange={date => handleChange('valid_at', date)}
            helperText={validation.valid_at && validation.valid_at}
            error={!!validation.valid_at}
            autoOk
          />
        </Grid>
      </Grid>
      <button type="submit" style={{ display: 'none' }} />
    </form>
  );
}
