import { createContext, useContext } from 'react';
import { Board } from 'types/board';

export type BoardContextValue = {
  boards: Board[];
  selectedBoard: null | Board;
  setSelectedBoard(board: null | Board): void;
};

const BoardContext = createContext<BoardContextValue>({} as BoardContextValue);
export const BoardContextProvider = BoardContext.Provider;
export const BoardContextConsumer = BoardContext.Consumer;

export function useBoards(): BoardContextValue {
  const context = useContext(BoardContext);
  return context;
}
