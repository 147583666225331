import React, { DragEvent, HTMLAttributes, useState } from 'react';
import AddPhotoIcon from '@material-ui/icons/AddAPhoto';
import { Zoom, IconButton, Typography, Button, CircularProgress, makeStyles, alpha } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ImageSelector from 'components/image-selector/ImageSelector';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import { Image } from 'types/image';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  image: {
    width: '100%',
    borderRadius: 4,
    backgroundColor: '#eee',
  },
  imageContainer: ({ error }: { error: boolean }) => ({
    display: 'flex',
    width: '100%',
    height: 300,
    border: `2px dashed ${error ? theme.palette.error.main : alpha(theme.palette.primary.main, 0.2)}`,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
  }),
  inDraggableZone: {
    border: `3px dashed ${theme.palette.primary.main}`,
    opacity: 0.5,
  },
  imageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    display: 'flex',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    '& > svg': {
      position: 'absolute',
      bottom: 0,
      right: 0,
    },
  },
  label: {
    display: 'flex',
    cursor: 'pointer',
    minWidth: 110,
  },
  icon: {
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
  loading: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    display: 'flex',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 100,
    backgroundColor: 'rgba(250, 250, 250, 1)',
  },
}));

interface ImageUploadProps extends HTMLAttributes<HTMLDivElement> {
  image: Image | null;
  validationError?: string;
  label: string;
  handleRemoveImage(): void;
  handleSetImage(image: Image | null): void;
  containerClassname?: string;
  disabled?: boolean;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  image,
  handleRemoveImage,
  handleSetImage,
  validationError,
  label,
  className,
  containerClassname,
  disabled = false,
  ...rest
}) => {
  const [imageSelector, setImageSelector] = useState(false);
  const classes = useStyles({ error: !!validationError });
  const [selected, setSelected] = useState(false);
  const [dragIn, setDragIn] = useState(false);
  const [saving, setSaving] = useState(false);
  const messaging = useMessaging();

  function handleUploadFiles(files: FileList | null) {
    if (!files) return;

    const form = new FormData();
    Array.from(files).forEach((file, index) => {
      form.append(`files[${index}]`, file);
    });

    setSaving(true);

    api
      .post('/images', form)
      .then(response => {
        handleSetImage(response.data[0]);
      })
      .catch(() => {
        messaging.handleOpen('Não foi possível carregar a imagem');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleDropFile(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    handleUploadFiles(files);
    setDragIn(false);
  }

  function handleDragEnter(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  function handleDragLeave(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(false);
  }

  function handleDragOver(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  return (
    <div className={`${classes.container} ${className}`}>
      {imageSelector && (
        <ImageSelector onExited={() => setImageSelector(false)} handleSetImageId={image => handleSetImage(image)} />
      )}
      {!image ? (
        <div
          {...rest}
          className={
            dragIn
              ? `${classes.imageContainer} ${classes.inDraggableZone} ${containerClassname}`
              : `${classes.imageContainer} ${containerClassname}`
          }
          onDrop={e => handleDropFile(e)}
          onDragLeave={e => handleDragLeave(e)}
          onDragEnter={e => handleDragEnter(e)}
          onDragOver={e => handleDragOver(e)}
          draggable
        >
          {saving ? (
            <CircularProgress />
          ) : (
            <IconButton disabled={disabled} size="medium" onClick={() => setImageSelector(true)}>
              <AddPhotoIcon color="primary" />
            </IconButton>
          )}
        </div>
      ) : (
        <div
          className={`${classes.imageContainer} ${containerClassname}`}
          onClick={() => setSelected(selected => !selected)}
        >
          <Zoom in={selected}>
            <div className={classes.imageWrapper}>
              <CheckCircleIcon className={classes.icon} color="primary" />
              <Button size="small" variant="contained" color="primary" onClick={handleRemoveImage}>
                Remover
              </Button>
            </div>
          </Zoom>
          <img alt="Foto do produto" className={classes.image} src={image.imageUrl} />
        </div>
      )}
      {validationError ? (
        <Typography variant="caption" color="error">
          {validationError}
        </Typography>
      ) : (
        <Typography variant="caption" display="block">
          {label}
        </Typography>
      )}
    </div>
  );
};

export default ImageUpload;
