import { moneyFormat } from 'helpers/NumberFormat';
import { Order } from 'types/order';

export function updateTotal(order: Order): Order {
  const discount = getDiscount(order);

  const subtotal = order.products.reduce((sum, value) => sum + value.final_price, 0);

  const tax = getTax(order);

  const total = subtotal - discount + tax;

  return {
    ...order,
    tax,
    subtotal,
    total,
    discount,
    formattedSubtotal: moneyFormat(subtotal),
    formattedDiscount: moneyFormat(discount),
    formattedTax: moneyFormat(tax),
    formattedTotal: moneyFormat(total),
  };
}

function getCouponDiscount(order: Order) {
  const subtotal = order.products.reduce((sum, value) => sum + value.final_price, 0);

  if (!order.coupon) {
    return 0;
  }

  return order?.coupon.discount_type === 'percent' ? subtotal * (order?.coupon.discount / 100) : order?.coupon.discount;
}

function getDiscount(order: Order) {
  const couponDiscount = getCouponDiscount(order);

  const promotionDiscount = order.promotionDiscount ?? 0;

  if (order.settings?.cart_accumulate_discount) {
    return promotionDiscount + couponDiscount;
  }

  return couponDiscount > promotionDiscount ? couponDiscount : promotionDiscount;
}

function getTax(order: Order) {
  if (order.shipment?.shipment_method !== 'delivery') {
    return 0;
  }

  const { settings } = order;

  const quantity = order.products.reduce((carry, product) => carry + product.amount, 0);
  const subtotal = order.products.reduce((sum, value) => sum + value.final_price, 0);

  if (settings?.tax_mode === 'order_value') {
    return settings.tax_value > 0 && subtotal < settings.order_minimum_value ? settings.tax_value : 0;
  }

  if (settings?.tax_mode === 'products_amount') {
    return quantity <= settings?.order_minimum_products_amount ? settings.tax_value : 0;
  }

  if (settings?.tax_mode === 'distance') {
    return order.shipment?.distance_tax || 0;
  }

  if (settings?.tax_mode === 'district') {
    return order.shipment.area_region?.tax || 0;
  }

  return order.tax;
}
