import React from 'react';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  button: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

function ProductsAction() {
  const classes = useStyles();

  return (
    <>
      <Tooltip title="Adicionar produto" className={classes.button}>
        <IconButton component={Link} to={'/menu/product'} color={'inherit'}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default ProductsAction;
