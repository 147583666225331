import React from 'react';
import ProductItem from './ProductItem';
import { Product } from 'types/product';
import List from 'components/list/List';

interface ProductListProps {
  products: Product[];
  onlyOneRow?: boolean;
}

const ProductList: React.FC<ProductListProps> = ({ products, onlyOneRow = false }) => {
  return (
    <List onlyOneRow={onlyOneRow}>
      {products.map(product => product.activated && <ProductItem key={product.id} product={product} />)}
    </List>
  );
};

export default ProductList;
