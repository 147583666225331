import React, { useState, useEffect, FormEvent } from 'react';
import { makeStyles } from '@material-ui/core';
import { useCustomerReducer } from 'store/modules/customers/reducer';
import {
  change,
  setCustomer,
  addAddress,
  updateAddress,
  setMainCustomerAddress,
  deleteCustomerAddress,
} from 'store/modules/customers/actions';
import { api } from 'services/api';
import axios from 'axios';
import Loading from 'components/loading/Loading';
import { moneyFormat } from 'helpers/NumberFormat';
import { parseISO, formatRelative, formatDistanceStrict } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useMessaging } from 'hooks/messaging';
import InsideLoading from 'components/loading/InsideLoading';
import { useParams } from 'react-router-dom';
import history from 'services/history';
import { useCustomerValidation } from '../validation/customerValidation';
import { CustomerAddress } from 'types/customerAddress';
import { Order } from 'types/order';
import { Customer } from 'types/customer';
import CustomerTabs from '../CustomerTabs';
import CustomerForm from '../CustomerForm';
import CustomerOrdersList from './orders/CustomerOrderList';
import { CustomerProvider } from '../hooks/useCustomer';
import CustomerAddresses from './addresses/CustomerAddresses';
import CustomerAction from './CustomerEditAction';
import { formatId } from 'helpers/formatOrderId';
import { useSelector } from 'store/redux/selector';
import NewAddress from './addresses/registration/places-api/new/NewAddress';
import EditAddress from './addresses/registration/places-api/edit/EditAddress';
import CustomerAddressNew from './addresses/registration/new/CustomerAddressNew';
import CustomerAddressEdit from './addresses/registration/edit/CustomerAddressEdit';
import Appbar from 'components/appbar/Appbar';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

const CustomerEdit: React.FC = () => {
  const classes = styles();
  const { handleOpen } = useMessaging();
  const [customer, dispatch] = useCustomerReducer();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [dialogNewAddress, setDialogNewAddress] = useState(false);
  const [dialogEditAddress, setDialogEditAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<CustomerAddress | null>(null);
  const [tab, setTab] = useState(0);
  const [validation, setValidation, validate] = useCustomerValidation();
  const { id } = useParams<{ id: string }>();
  const restaurant = useSelector(state => state.restaurant);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    let request = true;

    setLoading(true);

    api
      .get(`customers/${id}`, { cancelToken: CancelToken.source().token })
      .then(response => {
        const _customer = {
          ...response.data,
          // orders: handleSetOrders(response.data.orders),
          orders: [],
        };

        dispatch(setCustomer(_customer));
        request = false;
        setLoading(false);
      })
      .catch(err => {
        request = false;
        console.log(err);
      })
      .finally(() => setLoading(false));

    return () => {
      if (request) CancelToken.source().cancel();
      request = false;
    };
  }, [dispatch, id]);

  function handleValidation(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();
    setValidation({});

    validate(customer)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/customers/${id}`, customer)
      .then(() => {
        setSaving(false);
        handleOpen('Salvo');
        history.push('/customers');
      })
      .catch(err => {
        handleOpen(err.response ? err.response.data.error : 'Não foi possível salvar o cliente');
        setSaving(false);
      });
  }

  async function handleAddressSubmit(address: CustomerAddress, areaRegionId?: number | null) {
    try {
      setSaving(true);
      const response = await api.post(`/customers/${customer.id}/addresses`, {
        ...address,
        area_region_id: areaRegionId ?? null,
      });
      dispatch(addAddress(response.data));
      setTab(1);
      handleOpen('Salvo');
    } finally {
      setSaving(false);
    }
  }

  async function handleAddressUpdateSubmit(address: CustomerAddress, areaRegionId?: number | null) {
    try {
      setSaving(true);
      const response = await api.put(`/customers/${customer.id}/addresses/${selectedAddress?.id}`, {
        ...address,
        area_region_id: areaRegionId ?? null,
      });
      dispatch(updateAddress(response.data));
      handleOpen('Atualizado');
    } finally {
      setSaving(false);
    }
  }

  function handleUpdateIsMainAddress() {
    setSaving(true);
    api
      .put(`customer/addresses/main/${selectedAddress?.id}`)
      .then(() => {
        dispatch(setMainCustomerAddress(selectedAddress?.id as number));
      })
      .catch(() => {
        handleOpen('Não foi possível atualizar o endereço');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleDeleteAddress() {
    setSaving(true);

    api
      .delete(`addresses/${selectedAddress?.id}`)
      .then(() => {
        dispatch(deleteCustomerAddress(selectedAddress?.id as number));
      })
      .catch(() => {
        handleOpen('Não foi possível atualizar o endereço');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleSetOrders(orders: Order[]) {
    return orders.map(order => {
      const date = parseISO(order.created_at);
      return {
        ...order,
        formattedId: formatId(order.id),
        formattedTotal: moneyFormat(order.total),
        formattedChange: moneyFormat(order.change - order.total),
        formattedDate: formatRelative(date, new Date(), { locale: ptBR }),
        formattedSubtotal: moneyFormat(order.subtotal),
        formattedDiscount: moneyFormat(order.discount),
        dateDistance: formatDistanceStrict(date, new Date(), { locale: ptBR, roundingMethod: 'ceil' }),
      };
    });
  }

  function handleChange(index: keyof Customer, value: any) {
    dispatch(change(index, value));
  }

  function handleTabChange(value: number) {
    setTab(value);
  }

  return (
    <CustomerProvider
      value={{
        setDialogEditAddress,
        setDialogNewAddress,
        handleDeleteAddress,
        handleUpdateIsMainAddress,
        dispatch,
        customer,
        setSelectedAddress,
        selectedAddress,
      }}
    >
      {dialogNewAddress && restaurant?.configs.use_google_map_addresses && (
        <NewAddress
          handleAddressSubmit={handleAddressSubmit}
          onExited={() => setDialogNewAddress(false)}
          saving={saving}
        />
      )}

      {dialogNewAddress && !restaurant?.configs.use_google_map_addresses && (
        <CustomerAddressNew
          handleAddressSubmit={handleAddressSubmit}
          onExited={() => setDialogNewAddress(false)}
          saving={saving}
        />
      )}

      {dialogEditAddress && selectedAddress && restaurant?.configs.use_google_map_addresses && (
        <EditAddress
          handleAddressUpdateSubmit={handleAddressUpdateSubmit}
          selectedAddress={selectedAddress}
          onExited={() => setDialogEditAddress(false)}
          saving={saving}
        />
      )}

      {dialogEditAddress && selectedAddress && !restaurant?.configs.use_google_map_addresses && (
        <CustomerAddressEdit
          handleAddressUpdateSubmit={handleAddressUpdateSubmit}
          selectedAddress={selectedAddress}
          handleModalState={() => setDialogEditAddress(false)}
          saving={saving}
        />
      )}

      <Appbar
        title="Editar cliente"
        ActionComponents={
          <CustomerAction
            openDialogCustomerForm={() => setDialogNewAddress(true)}
            loading={loading}
            saving={saving}
            handleValidation={handleValidation}
          />
        }
        Tab={<CustomerTabs tab={tab} handleTabChange={handleTabChange} />}
      />
      {loading ? (
        <InsideLoading />
      ) : (
        <div className={classes.container}>
          {saving && <Loading />}
          {tab === 0 && (
            <CustomerForm type="update" customer={customer} handleChange={handleChange} validation={validation} />
          )}
          {tab === 1 && <CustomerAddresses />}
          {tab === 2 && <CustomerOrdersList />}
        </div>
      )}
    </CustomerProvider>
  );
};

export default CustomerEdit;
