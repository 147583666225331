import { TableTemplate } from 'types/tableTemplate';

export const receiptsTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 70,
  },
  {
    id: 'sequence',
    description: 'NÚMERO',
    originalId: 'sequence',
    width: 80,
  },
  {
    id: 'serial',
    description: 'SÉRIE',
    originalId: 'serial',
    width: 70,
  },
  {
    id: 'type',
    description: 'TIPO',
    originalId: 'fiscalSerialType',
    width: 70,
  },
  {
    id: 'order_id',
    description: 'PEDIDO',
    originalId: 'order_id',
    width: 100,
  },
  {
    id: 'formattedCurrentStatus',
    description: 'STATUS',
    originalId: 'current_status',
    width: 200,
  },
  {
    id: 'customer_document',
    description: 'CPF / CNPJ',
    originalId: 'customer_document',
    width: 200,
  },
  {
    id: 'customer_name',
    description: 'NOME',
    originalId: 'customer_name',
    width: 300,
  },
  {
    id: 'shipment_city',
    description: 'CIDADE',
    originalId: 'shipment_city',
    width: 200,
  },
  {
    id: 'shipment_state',
    description: 'ESTADO',
    originalId: 'shipment_state',
    width: 150,
  },
  {
    id: 'formattedCreatedAt',
    description: 'CRIADO EM',
    originalId: 'created_at',
    width: 150,
  },
  {
    id: 'formattedTotalValue',
    description: 'VALOR',
    originalId: 'total_value',
    width: 150,
  },
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 350,
  },
];
