import { MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useProduct } from 'pages/products/registration/hooks/useProduct';
import { TaxRule } from 'pages/tax-rules/types/taxRule';

const useStyles = makeStyles({
  container: {
    maxWidth: 400,
    display: 'flex',
    flexDirection: 'column',
  },
});

interface ProductFiscalStepProps {
  taxRules: TaxRule[];
}

export default function ProductFiscalStep({ taxRules }: ProductFiscalStepProps) {
  const classes = useStyles();
  const { product, handleChange, handleNext } = useProduct();

  function handleSubmit(event) {
    event.preventDefault();
    handleNext();
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.container}>
        <TextField
          label="Unidade de medida"
          placeholder="Unidade de medida"
          margin="normal"
          fullWidth
          value={product.measurement_unit}
          onChange={event => handleChange(event.target.value, 'measurement_unit')}
          autoFocus
          helperText="Informação é obrigatória para emissão de cupom fiscal"
        />

        <TextField
          label="EAN 13"
          placeholder="Código EAN 13"
          margin="normal"
          fullWidth
          value={product.ean13}
          onChange={event => handleChange(event.target.value, 'ean13')}
          helperText="Informação é obrigatória para emissão de cupom fiscal"
          inputMode="decimal"
        />

        <TextField
          label="NCM"
          placeholder="Código NCM"
          margin="normal"
          fullWidth
          value={product.ncm_code}
          onChange={event => handleChange(event.target.value, 'ncm_code')}
          helperText="Informação é obrigatória para emissão de cupom fiscal"
          inputMode="decimal"
        />

        <TextField
          helperText="Informação é obrigatória para emissão de cupom fiscal"
          label="CEST"
          placeholder="Código CEST"
          margin="normal"
          fullWidth
          value={product.cest_code}
          onChange={event => handleChange(event.target.value, 'cest_code')}
          inputMode="decimal"
        />

        <TextField
          helperText="Informação é obrigatória para emissão de cupom fiscal"
          label="Regra fiscal"
          margin="normal"
          fullWidth
          value={product.tax_rule_id}
          onChange={event => handleChange(event.target.value, 'tax_rule_id')}
          inputMode="decimal"
          select
        >
          {taxRules.map(rule => (
            <MenuItem value={rule.id} key={rule.id}>
              {rule.description}
            </MenuItem>
          ))}
        </TextField>

        <button type="submit" style={{ display: 'none' }}>
          Salvar
        </button>
      </div>
    </form>
  );
}
