import { UserActionTypes, SET_USER } from './types';
import { User } from 'types/user';
import { Image } from 'types/image';

const INITIAL_STATE: User = {
  id: 0,
  active: true,
  email: '',
  image: {} as Image,
  name: '',
  phone: '',
  rule: 'admin-admin',
  image_id: null,
  created_at: '',
};

export default function user(state = INITIAL_STATE, action: UserActionTypes): User {
  switch (action.type) {
    case SET_USER: {
      return {
        ...action.user,
      };
    }
    default: {
      return state;
    }
  }
}
