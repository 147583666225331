import React, { useState, useEffect } from 'react';
import { api } from 'services/api';
import { Address } from 'types/address';
import { CustomerAddressProvider } from '../hooks/useCustomerAddress';
import GoogleMap from '../map/GoogleMap';
import Form from '../Form';
import Modal from 'components/modal/Modal';
import InsideSaving from 'components/loading/InsideSaving';
import { useAddressValidation } from '../validation/useAddressValidation';
import { Position } from 'types/position';
import EditAddressActions from './EditAddressActions';
import { useMessaging } from 'hooks/messaging';
import GoogleMapsProvider from 'providers/google-maps/MapProvider';
import { CustomerAddress } from 'types/customerAddress';

interface EditAddressProps {
  handleAddressUpdateSubmit(address: Address, areaRegionId?: number | null): Promise<void>;
  onExited(): void;
  saving: boolean;
  selectedAddress: CustomerAddress;
}

const EditAddress: React.FC<EditAddressProps> = ({ handleAddressUpdateSubmit, onExited, saving, selectedAddress }) => {
  const [loadingAddress, setLoadingAddress] = useState(true);
  const messaging = useMessaging();
  const [validation, setValidation, validate] = useAddressValidation();
  const [address, setAddress] = useState<CustomerAddress>(selectedAddress);
  const [position, setPosition] = useState<null | Position>(null);
  const [step, setStep] = useState<number>(1);
  const [areaRegionId, setAreaRegionId] = useState<number | null>(address.area_region?.area_region_id ?? null);

  useEffect(() => {
    if (selectedAddress.latitude && selectedAddress.longitude) {
      setPosition({
        lat: selectedAddress.latitude,
        lng: selectedAddress.longitude,
      });
      setLoadingAddress(false);
      return;
    }

    const fullAddress = `${selectedAddress.address} ${selectedAddress.number} ${selectedAddress.district} ${selectedAddress.city} ${selectedAddress.region}`;

    api
      .get('/coordinates', { params: { address: fullAddress } })
      .then(response => {
        const geometryLocation = response.data.geometry.location;
        const _address = response.data.address;
        setPosition(geometryLocation);
        setAddress(state => ({
          ...state,
          address: _address.street,
          number: _address.street_number,
          district: _address.neighborhood,
          region: _address.state,
          city: _address.city,
          latitude: geometryLocation.lat,
          longitude: geometryLocation.lng,
        }));
      })
      .catch(err => console.error(err))
      .finally(() => setLoadingAddress(false));
  }, [selectedAddress]);

  function handleValidation(handleModalClose: () => void) {
    validate(address, areaRegionId)
      .then(() => handleSubmit(handleModalClose))
      .catch(err => console.error(err));
  }

  function handleSubmit(handleModalClose: () => void) {
    setValidation({});
    handleAddressUpdateSubmit(address, areaRegionId)
      .then(handleModalClose)
      .catch(err => {
        messaging.handleOpen(err.response.data.error);
      });
  }

  function handleNext() {
    setStep(step => step + 1);
  }

  function handleBack() {
    setStep(step => step - 1);
  }

  function handleChange(index: keyof Address, value: any) {
    setAddress(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleGetPlaceLatitudeLongitude(addressDescription: string) {
    setLoadingAddress(true);

    api
      .get('/coordinates', { params: { address: addressDescription } })
      .then(response => {
        const geometryLocation = response.data.geometry.location;
        const _address = response.data.address;
        setPosition(geometryLocation);
        setAddress(state => ({
          ...state,
          postal_code: '00000000',
          address: _address.street,
          number: _address.street_number,
          district: _address.neighborhood,
          region: _address.state,
          city: _address.city,
          latitude: geometryLocation.lat,
          longitude: geometryLocation.lng,
        }));
        handleNext();
      })
      .catch(err => console.error(err))
      .finally(() => setLoadingAddress(false));
  }

  function handleRendering() {
    const components = {
      1: <>{position && <GoogleMap position={position} address={address} />}</>,
      2: (
        <Form
          areaRegionId={areaRegionId}
          setAreaRegionId={setAreaRegionId}
          handleChange={handleChange}
          validation={validation}
          address={address}
        />
      ),
    };

    return components[step as keyof typeof components];
  }

  return (
    <CustomerAddressProvider
      value={{
        handleGetPlaceLatitudeLongitude,
        handleChange,
        handleNext,
        handleBack,
        handleValidation,
        setPosition,
        setAddress,
        setStep,
        step,
      }}
    >
      <Modal
        title="editar endereço"
        onExited={onExited}
        backAction={step !== 1 ? handleBack : undefined}
        componentActions={<EditAddressActions saving={saving} handleValidation={handleValidation} />}
        maxWidth="sm"
        height="80vh"
        disablePadding={step === 1}
      >
        {(loadingAddress || saving) && <InsideSaving />}
        <GoogleMapsProvider>{handleRendering()}</GoogleMapsProvider>
      </Modal>
    </CustomerAddressProvider>
  );
};

export default EditAddress;
