import React, { useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import PaymentMethodItemTable from './PaymentMethodItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import ReceiptListMenu from '../PaymentMethodListMenu';
import history from 'services/history';
import { PaymentMethodForList } from 'types/paymentMethod';
import { usePaymentMethods } from 'pages/payment-methods/hooks/usePaymentMethods';
import { taxRulesTableTemplate } from 'pages/payment-methods/constants/taxRulesTableTemplate';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type PaymentMethodListTableProps = {
  paymentMethods: PaymentMethodForList[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const PaymentMethodListTable: React.FC<PaymentMethodListTableProps> = ({
  paymentMethods,
  handleSort,
  orderedIndex,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { setSelected } = usePaymentMethods();

  function handleClick(paymentMethod: PaymentMethodForList) {
    setSelected(paymentMethod);
    history.push(`/payment-methods/${paymentMethod.uuid}`);
  }

  return (
    <>
      <ReceiptListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <TableContainer tableTemplate={taxRulesTableTemplate}>
        <TableContent>
          <TableHeader>
            {taxRulesTableTemplate
              .filter(item => !item.notShow)
              .map(item => (
                <div
                  className={classes.headerItem}
                  key={item.id}
                  onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
                >
                  <Typography noWrap variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId && (
                    <>
                      {orderedIndex.direction === 'asc' ? (
                        <ArrowUpward color="primary" />
                      ) : (
                        <ArrowDownward color="primary" />
                      )}
                    </>
                  )}
                </div>
              ))}
          </TableHeader>

          <TableBody>
            {paymentMethods.map(item => (
              <TableRow key={item.id} onClick={() => handleClick(item)}>
                <PaymentMethodItemTable setAnchorEl={el => setAnchorEl(el)} paymentMethod={item} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </>
  );
};

export default PaymentMethodListTable;
