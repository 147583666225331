import { Dispatch, SetStateAction, useState } from 'react';
import { RestaurantConfig } from 'types/restaurant';
import * as yup from 'yup';

export interface RestaurantConfigValidation {
  tax_value?: string;
  order_minimum_value?: string;
  delivery_time?: string;
  board_time?: string;
  order_sequence?: string;
}

type UseRestaurantConfigValidation = [
  RestaurantConfigValidation,
  Dispatch<SetStateAction<RestaurantConfigValidation>>,
  (settings: RestaurantConfig) => Promise<void>
];

export function useRestaurantConfigValidation(): UseRestaurantConfigValidation {
  async function handleValidation(settings: RestaurantConfig) {
    const schema = yup.object().shape({
      tax_value: yup.mixed().test('test', 'Você deve informar o valor da taxa', value => {
        if (settings.tax_mode === 'order_value') {
          return !!value;
        } else return true;
      }),

      order_minimum_value: yup
        .number()
        .typeError('Informe um número válido')
        .required('O valor mínimo do pedido é obrigatório'),

      order_sequence: yup
        .number()
        .typeError('Informe um número válido')
        .required('A sequência de pedido é obrigatória'),

      delivery_time: yup
        .number()
        .typeError('Você deve informar um número válido')
        .required('O tempo de entrega é obrigatório'),

      board_time: yup
        .number()
        .typeError('Você deve informar um número válido')
        .required('O tempo de entrega para mesa é obrigatório'),
    });

    try {
      await schema.validate(settings);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }
      throw err;
    }
  }

  const [validation, setValidation] = useState<RestaurantConfigValidation>({});
  return [validation, setValidation, handleValidation];
}
