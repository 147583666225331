import React, { MouseEvent } from 'react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@material-ui/icons';
import { User } from 'types/user';
import { useUsers } from 'pages/users/hooks/useUsers';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type UserItemTableProps = {
  user: User;
  setAnchorEl(el: HTMLButtonElement): void;
};

const UserItemTable: React.FC<UserItemTableProps> = ({ user, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedUser } = useUsers();

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setSelectedUser(user);
    setAnchorEl(event.currentTarget);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton onClick={handleMoreClick} key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{user[item.id]}</Typography>
            </div>
          )
        )}
    </>
  );
};

export default UserItemTable;
