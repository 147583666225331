import { Button, Typography, makeStyles } from '@material-ui/core';
import { ArrowBack, Error } from '@material-ui/icons';
import Modal from 'components/modal/Modal';
import { ModalConsumer } from 'components/modal/hooks/useModal';
import React from 'react';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
  },
  code: {
    padding: 20,
    border: '1px solid #ccc',
    backgroundColor: '#eee',
    marginTop: 20,
    maxWidth: 600,
    width: '100%',
  },
  actions: {
    display: 'flex',
    gap: 10,
  },
});

interface ErrorHandlerDialogProps {
  onExited(): void;
  message: string;
  detail?: string;
}

const ErrorHandlerDialog: React.FC<ErrorHandlerDialogProps> = ({ onExited, message, detail }) => {
  const classes = styles();

  return (
    <Modal fullscreen onExited={onExited}>
      <div className={classes.container}>
        <Error color="error" fontSize="large" />

        <Typography align="center" variant="h5">
          {message}
        </Typography>

        <code className={classes.code}>
          <Typography gutterBottom variant="caption" color="textSecondary">
            detalhe
          </Typography>
          <Typography variant="subtitle2">{detail}</Typography>
        </code>
        <ModalConsumer>
          {context => (
            <div className={classes.actions}>
              <Button onClick={context.handleModalClose} startIcon={<ArrowBack />} color="primary" variant="contained">
                voltar
              </Button>
            </div>
          )}
        </ModalConsumer>
      </div>
    </Modal>
  );
};

export default ErrorHandlerDialog;
