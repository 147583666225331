export type StepIds = 'STEP_1' | 'STEP_2' | 'STEP_3';

interface Steps {
  id: StepIds;
  order: number;
  title: string;
  description: string;
}

const steps: Steps[] = [
  {
    id: 'STEP_1',
    order: 1,
    title: 'Categoria',
    description: 'Escolha uma categoria de produtos',
  },
  {
    id: 'STEP_2',
    order: 2,
    title: 'Produto',
    description: 'Selecione o produto.',
  },
  {
    id: 'STEP_3',
    order: 3,
    title: 'Finalizar',
    description: 'Confirmação do pedido',
  },
];

export { steps };
