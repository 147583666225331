import { TableTemplate } from 'types/tableTemplate';

export const usersTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 70,
  },
  {
    id: 'name',
    description: 'NOME',
    originalId: 'name',
    width: 400,
  },
  {
    id: 'phone',
    description: 'TELEFONE',
    originalId: 'phone',
    width: 150,
  },
  {
    id: 'email',
    description: 'E-MAIL',
    originalId: 'email',
    width: 300,
  },
  {
    id: 'formattedRule',
    description: 'PERMISSÃO',
    originalId: 'formattedRule',
    width: 300,
  },
  {
    id: 'formattedActive',
    description: 'ATIVO',
    originalId: 'active',
    width: 100,
  },
  {
    id: 'formattedCreatedAt',
    description: 'CRIADO EM',
    originalId: 'created_at',
    width: 350,
  },
];
