import React, { useState } from 'react';
import List from 'components/list/List';
import ReceiptItemModule from './ReceiptItemModule';
import BoardMovementsListMenu from '../ReceiptListMenu';
import { ReceiptOnList } from 'pages/receipts/types/receipt';

type ReceiptListModuleProps = {
  receipts: ReceiptOnList[];
};

const ReceiptListModule: React.FC<ReceiptListModuleProps> = ({ receipts }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <BoardMovementsListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <List>
        {receipts.map(item => (
          <ReceiptItemModule setAnchorEl={setAnchorEl} key={item.id} receipt={item} />
        ))}
      </List>
    </>
  );
};

export default ReceiptListModule;
