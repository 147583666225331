import { Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const styles = makeStyles({
  container: {
    flex: 0.7,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
  },
});

interface BoardTransferErrorProps {
  errorText: string;
  handleResetError(): void;
}

const BoardTransferError: React.FC<BoardTransferErrorProps> = ({ errorText, handleResetError }) => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <Typography>{errorText}</Typography>
      <Button variant="contained" color="primary" onClick={handleResetError}>
        Tentar novamente
      </Button>
    </div>
  );
};

export default BoardTransferError;
