import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CustomAppbar from 'components/appbar/Appbar';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  title: {},
  icon: {
    fontSize: 40,
  },
});

const PrivateNotFound: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <CustomAppbar title="Página não encontrada" />
      <div className={classes.container}>
        <Typography variant="h4" align="center" className={classes.title} gutterBottom>
          404 - página não encontrada
        </Typography>
        <Typography color="textSecondary">
          <Link to="/dashboard">Voltar ao início</Link>
        </Typography>
      </div>
    </>
  );
};

export default PrivateNotFound;
