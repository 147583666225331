import React, { useState, useEffect } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import { OpeningHourAction } from 'components/opening-hour/OpeningHourAction';
import { Grid } from '@material-ui/core';
import OpeningHoursList from 'components/opening-hour/OpeningHoursList';
import Loading from 'components/loading/Loading';
import { parseISO, isAfter, setYear, setMonth, setDate, setSeconds } from 'date-fns';
import OpenHourListLoading from './OpenHourListLoading';
import ConnectionErrorMessage from 'components/errors/ConnectionErrorMessage';
import { api, getCancelTokenSource } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import PageHeader from 'components/page-header/PageHeader';

const daysOfTheWeek = {
  0: 'domingo',
  1: 'segunda-feira',
  2: 'terça-feira',
  3: 'quarta-feira',
  4: 'quinta-feira',
  5: 'sexta',
  6: 'sábado',
};

const initialWorkingTime = [
  {
    day: 0,
    open: false,
    working_hours: [
      {
        starting_hour: null,
        final_hour: null,
      },
    ],
  },
  {
    day: 1,
    open: false,
    working_hours: [
      {
        starting_hour: null,
        final_hour: null,
      },
    ],
  },
  {
    day: 2,
    open: false,
    working_hours: [
      {
        starting_hour: null,
        final_hour: null,
      },
    ],
  },
  {
    day: 3,
    open: false,
    working_hours: [
      {
        starting_hour: null,
        final_hour: null,
      },
    ],
  },
  {
    day: 4,
    open: false,
    working_hours: [
      {
        starting_hour: null,
        final_hour: null,
      },
    ],
  },
  {
    day: 5,
    open: false,
    working_hours: [
      {
        starting_hour: null,
        final_hour: null,
      },
    ],
  },
  {
    day: 6,
    open: false,
    working_hours: [
      {
        starting_hour: null,
        final_hour: null,
      },
    ],
  },
];

export default function OpeningHour() {
  const [loading, setLoading] = useState(false);
  const [httpStatusCode, setHttpStatusCode] = useState(0);
  const [saving, setSaving] = useState(false);
  const [workingTime, setWorkingTime] = useState(initialWorkingTime);
  const messaging = useMessaging();

  function handleSubmit(event) {
    event.preventDefault();

    setSaving(true);

    let validation = true;

    workingTime.forEach(item => {
      if (item.open && validation) {
        if (item.working_hours.length === 0) {
          messaging.handleOpen('Informe algum turno para ' + daysOfTheWeek[item.day].toUpperCase());
          validation = false;
        } else {
          let lastFinalHourVerified = null;
          item.working_hours.forEach((hour, indexHour) => {
            if (validation) {
              const shift = indexHour + 1;

              if (!hour.starting_hour) {
                messaging.handleOpen(
                  'Hora inicial inválida para ' + daysOfTheWeek[item.day].toUpperCase() + ', turno ' + shift
                );
                validation = false;
              } else if (!hour.final_hour) {
                messaging.handleOpen(
                  'Hora final inválida para ' + daysOfTheWeek[item.day].toUpperCase() + ', turno ' + shift
                );
                validation = false;
              } else if (isAfter(hour.starting_hour, hour.final_hour)) {
                messaging.handleOpen(
                  'Hora inicial maior que a hora final para ' +
                    daysOfTheWeek[item.day].toUpperCase() +
                    ', turno ' +
                    shift
                );
                validation = false;
              }
              if (indexHour >= 1) {
                if (isAfter(lastFinalHourVerified, hour.starting_hour)) {
                  messaging.handleOpen(
                    'Hora inicial inválida para ' + daysOfTheWeek[item.day].toUpperCase() + ', turno ' + shift
                  );
                  validation = false;
                }
              }
              lastFinalHourVerified = hour.final_hour;
            }
          });
        }
      }
    });

    if (!validation) {
      setSaving(false);
      return;
    }

    api
      .post('/workingDays', workingTime)
      .then(response => {
        messaging.handleOpen('Salvo');
        const _workingTime = parseDates(response.data.data);
        setWorkingTime(_workingTime);
      })
      .catch(() => {
        messaging.handleOpen('Error');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  const handleChangeDay = index => (event, value) => {
    const workingTimeCopy = workingTime.slice();
    workingTimeCopy[index].open = value;

    setWorkingTime(workingTimeCopy);
  };

  const handleDateChange = (indexDay, indexTurn, indicator) => date => {
    const _workingTime = workingTime.slice();
    const newDate = setSeconds(setYear(setMonth(setDate(date, 1), 0), 2019), 0);
    _workingTime[indexDay].working_hours[indexTurn] = {
      ..._workingTime[indexDay].working_hours[indexTurn],
      [indicator]: newDate,
    };

    setWorkingTime(_workingTime);
  };

  const handleClickNewTurn = index => {
    const _workingTime = workingTime.slice();
    _workingTime[index].working_hours.push({
      starting_hour: null,
      final_hour: null,
    });

    setWorkingTime(_workingTime);
  };

  const handleDeleteShift = (indexDay, indexShift) => {
    const _workingTime = workingTime.slice();
    const _history = JSON.parse(JSON.stringify(_workingTime));

    _workingTime[indexDay].working_hours = workingTime[indexDay].working_hours.filter((item, index) => {
      return indexShift !== index;
    });

    setWorkingTime(_workingTime);
    messaging.handleOpen('Turno excluído', () => {
      setWorkingTime(_history);
    });
  };

  const parseDates = dates => {
    return dates.map(date => {
      const newDate = date;

      newDate.working_hours = newDate.working_hours.map(hour => {
        return {
          ...hour,
          starting_hour: hour.starting_hour ? parseISO(hour.starting_hour) : hour.starting_hour,
          final_hour: hour.final_hour ? parseISO(hour.final_hour) : hour.final_hour,
        };
      });

      return newDate;
    });
  };

  useEffect(() => {
    setLoading(true);

    let request = true;
    const source = getCancelTokenSource();

    api
      .get('/workingDays/restaurant', { cancelToken: source.token })
      .then(response => {
        if (request)
          if (response.data.length > 0) {
            const workingTime = parseDates(response.data);
            setWorkingTime(workingTime);
            setHttpStatusCode(response.status);
          } else {
            setHttpStatusCode(response.status);
          }
      })
      .catch(err => {
        if (request) {
          if (err.response) setHttpStatusCode(err.response.status);
          else setHttpStatusCode(0);
        }
      })
      .finally(() => {
        if (request) setLoading(false);
        request = false;
      });

    return () => {
      if (request) source.cancel();
      request = false;
    };
  }, []);

  return (
    <>
      {saving && <Loading />}
      <CustomAppbar
        title="Horário de funcionamento"
        ActionComponents={<OpeningHourAction saving={saving} handleOpeningHourSubmit={handleSubmit} />}
      />

      <Grid container spacing={0} direction="column">
        <PageHeader
          title="Cadastre os dias e horários que você quer receber pedidos"
          description="Se necessário, poderá criar turnos."
        />

        {loading ? (
          <Grid item xs={12} xl={5} lg={6} md={8} sm={12}>
            <OpenHourListLoading />
          </Grid>
        ) : httpStatusCode === 200 || httpStatusCode === 204 ? (
          <Grid item xs={12} xl={5} lg={6} md={8} sm={12}>
            <form onSubmit={handleSubmit}>
              <OpeningHoursList
                daysOfTheWeek={daysOfTheWeek}
                handleChangeDay={handleChangeDay}
                handleClickNewTurn={handleClickNewTurn}
                handleDateChange={handleDateChange}
                workingTime={workingTime}
                handleSubmit={handleSubmit}
                handleDeleteShift={handleDeleteShift}
              />
            </form>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <ConnectionErrorMessage statusCode={httpStatusCode} />
          </Grid>
        )}
      </Grid>
    </>
  );
}
