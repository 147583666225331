import React, { ReactNode, useState } from 'react';
import { Dialog, DialogContent, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { makeStyles } from '@material-ui/core/styles';
import { Print } from '@material-ui/icons';
import { DialogPrintContextProvider } from './useDialogPrint';

const useStyles = makeStyles(theme => ({
  modal: {
    overflowY: 'auto',
    padding: '0 30px 40px',
    [theme.breakpoints.down('md')]: {
      padding: '0 30px 40px !important',
    },
  },
  root: {
    paddingRight: '0!important',
  },
  appbar: {
    '@media print': {
      display: 'none',
    },
  },
  appbarSpace: {
    marginBottom: 64,
    [theme.breakpoints.down('md')]: {
      marginBottom: 75,
    },
    [theme.breakpoints.between('xs', 'xs') + ' and (orientation: landscape)']: {
      marginBottom: 70,
    },
    '@media print': {
      '&': {
        margin: 0,
      },
    },
  },
  grow: {
    flexGrow: 1,
  },
  background: {
    backgroundColor: '#fff',
  },
  content: {
    '@media print': {
      '&': {
        padding: 9,
      },
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

interface DialogPrintProps {
  onExited(): void;
  title: string;
  children: ReactNode;
}

const DialogPrint: React.FC<DialogPrintProps> = ({ onExited, title, children }) => {
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  function handleClose() {
    setOpen(false);
  }

  return (
    <Dialog
      classes={{
        root: classes.root,
        paper: classes.background,
      }}
      open={open}
      onClose={handleClose}
      fullScreen
      fullWidth
      TransitionProps={{
        onExited,
      }}
    >
      {title && (
        <AppBar className={classes.appbar}>
          <Toolbar>
            <IconButton color="inherit" onClick={handleClose}>
              <NavigateBeforeIcon />
            </IconButton>
            <Typography variant={'h6'} color="inherit" noWrap>
              {title}
            </Typography>
            <div className={classes.grow} />
            <div>
              <IconButton color="inherit" onClick={() => window.print()}>
                <Print />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      )}
      <div className={classes.appbarSpace} />
      <DialogContent className={classes.content}>
        <DialogPrintContextProvider value={{ handleClose }}>
          <div className={classes.container}>
            <div className="print">{children}</div>
          </div>
        </DialogPrintContextProvider>
      </DialogContent>
    </Dialog>
  );
};

export default DialogPrint;
