import React, { useState } from 'react';
import List from 'components/list/List';
import { usePagination } from 'hooks/pagination';
import CashRegisterItemModule from './CashRegisterItemModule';
import { CashRegister } from 'types/cashRegister';
import CashRegisterListMenu from '../CashRegisterListMenu';

type CashRegisterListModuleProps = {
  cashRegisters: CashRegister[];
};

const CashRegisterListModule: React.FC<CashRegisterListModuleProps> = ({ cashRegisters }) => {
  const { rowsPerPage, page } = usePagination();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <CashRegisterListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <List>
        {cashRegisters.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(cashRegister => (
          <CashRegisterItemModule setAnchorEl={setAnchorEl} key={cashRegister.id} cashRegister={cashRegister} />
        ))}
      </List>
    </>
  );
};

export default CashRegisterListModule;
