import React from 'react';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';

const styles = makeStyles(theme => ({
  container: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

type ReceiptsAppbarButtonsProps = {
  openDialog(): void;
};

const ReceiptsAppbarButtons: React.FC<ReceiptsAppbarButtonsProps> = ({ openDialog }) => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <Tooltip title="Adicionar categoria">
        <IconButton onClick={openDialog} color="inherit">
          <FilterList />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ReceiptsAppbarButtons;
