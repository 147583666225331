import { Button, makeStyles, Typography } from '@material-ui/core';
import React, { Dispatch, SetStateAction } from 'react';

const styles = makeStyles({
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
  },
});

interface BoardControlPaymentErrorProps {
  error: string;
  setError: Dispatch<SetStateAction<string>>;
}

const BoardControlPaymentError: React.FC<BoardControlPaymentErrorProps> = ({ error, setError }) => {
  const classes = styles();

  function handleClick() {
    setError('');
  }

  return (
    <div className={classes.container}>
      <Typography gutterBottom>{error}</Typography>
      <Button onClick={handleClick} size="small" variant="contained" color="primary">
        voltar
      </Button>
    </div>
  );
};

export default BoardControlPaymentError;
