import CertificateUpload from 'components/certificate-upload/CertificateUpload';
import React, { useState } from 'react';
import { Certificate } from './types/certificate';
import { FiscalSettingsNfce } from './types/fiscal-setting';
import DialogInput, { DialogInputConsumer } from 'components/dialog/DialogInput';
import { Button, makeStyles } from '@material-ui/core';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: 300,
  },
  actions: {
    display: 'flex',
    marginTop: 20,
    justifyContent: 'flex-end',
  },
});

interface CertificateUploadDialogProps {
  onExited(): void;
  handleNfceSettingsChange(index: keyof FiscalSettingsNfce, value: string): void;
  settings: FiscalSettingsNfce;
}

const CertificateUploadDialog: React.FC<CertificateUploadDialogProps> = ({
  onExited,
  handleNfceSettingsChange,
  settings,
}) => {
  const classes = styles();
  const [certificate, setCertificate] = useState<Certificate | null>(null);

  function handleSetCertificate(certificate: Certificate) {
    setCertificate(certificate);
    handleNfceSettingsChange('certificate_name', certificate.filename);
  }

  return (
    <DialogInput maxWidth="sm" disableBackdropClick onExited={onExited}>
      <div className={classes.container}>
        <CertificateUpload
          onUploaded={handleSetCertificate}
          handleRemove={() => setCertificate(null)}
          certificate={certificate}
          label="certificado digital A1"
          password={settings.certificate_password}
        />
        <div className={classes.actions}>
          <DialogInputConsumer>
            {content => (
              <Button onClick={content.handleCloseDialog} size="small" variant="contained" color="primary">
                fechar
              </Button>
            )}
          </DialogInputConsumer>
        </div>
      </div>
    </DialogInput>
  );
};

export default CertificateUploadDialog;
