import React from 'react';
import { Button, Theme, makeStyles } from '@material-ui/core';

const styles = makeStyles<Theme>({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
});

interface ReceiptsPageHeaderButtonsProps {
  openDownloadReceiptModal(): void;
}

const ReceiptsPageHeaderButtons: React.FC<ReceiptsPageHeaderButtonsProps> = ({ openDownloadReceiptModal }) => {
  const classes = styles();

  function handleDownloadClick() {
    openDownloadReceiptModal();
  }

  return (
    <div className={classes.container}>
      <Button onClick={handleDownloadClick} variant="contained" size="small" color="primary">
        download XML
      </Button>
    </div>
  );
};

export default ReceiptsPageHeaderButtons;
