import React from 'react';
import { TextField, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { usePaymentMethods } from '../../hooks/usePaymentMethods';

const useStyles = makeStyles({
  formControl: {
    marginTop: 15,
  },
});

export default function PaymentMethodCielo() {
  const classes = useStyles();
  const { handleConfigsChange, gatewaySettings } = usePaymentMethods();

  return (
    <>
      <TextField
        label="Cielo Merchant Id"
        margin="normal"
        fullWidth
        value={gatewaySettings.cielo_merchant_id}
        onChange={e => handleConfigsChange('cielo_merchant_id', e.target.value)}
        placeholder="Informe o merchant id"
        autoFocus
      />
      <TextField
        label="Cielo Merchant Key"
        margin="normal"
        fullWidth
        value={gatewaySettings.cielo_merchant_key}
        onChange={e => handleConfigsChange('cielo_merchant_key', e.target.value)}
        placeholder="Informe o merchant key"
      />
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Ambiente</FormLabel>
        <RadioGroup
          name="discountType"
          value={gatewaySettings.cielo_env}
          onChange={e => handleConfigsChange('cielo_env', e.target.value)}
        >
          <FormControlLabel value="production" control={<Radio />} label="Produção" />
          <FormControlLabel value="development" control={<Radio />} label="Teste" />
        </RadioGroup>
      </FormControl>
    </>
  );
}
