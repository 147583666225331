import React from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import { Grid } from '@material-ui/core';
import PageHeader from 'components/page-header/PageHeader';
import { Link } from 'react-router-dom';

export default function Report() {
  return (
    <>
      <CustomAppbar title="Relatórios" />
      <PageHeader title="Relatórios"></PageHeader>
      <Grid container>
        <Grid item xs={12}>
          <Link to="/reports/top-customers">Clientes que mais compraram em um período</Link>
        </Grid>
      </Grid>
    </>
  );
}
