import { FiscalSerial } from 'pages/fiscal-serials/types/fiscal-serial';
import { FiscalSerialActions } from './types';

export function fiscalSerialChange(key: keyof FiscalSerial, value: any): FiscalSerialActions {
  return {
    type: 'FISCAL_SERIAL_CHANGE',
    key,
    value,
  };
}

export function setFiscalSerial(serial: FiscalSerial): FiscalSerialActions {
  return {
    type: 'SET_FISCAL_SERIAL',
    serial,
  };
}
