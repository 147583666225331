import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import ptbr from 'date-fns/locale/pt-BR';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';

interface MyDateTimePickerProps {
  handleDateChange(date: Date | null): void;
  value: Date | null | string;
  label: string;
  required?: boolean;
  autoOk?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  disabled?: boolean;
}

const MyDateTimePicker: React.FC<MyDateTimePickerProps> = ({
  handleDateChange,
  value,
  label,
  required = false,
  autoOk = false,
  fullWidth = true,
  error = false,
  helperText = '',
  placeholder = 'Ex: 01/01/2020',
  disabled = false,
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptbr}>
      <KeyboardDateTimePicker
        required={required || false}
        clearable
        clearLabel="Limpar"
        cancelLabel="Cancelar"
        label={label}
        value={value}
        placeholder={placeholder}
        onChange={date => handleDateChange(date)}
        format="dd/MM/yyyy HH:mm"
        fullWidth={fullWidth}
        margin="normal"
        invalidDateMessage="Data inválida"
        autoOk={autoOk}
        error={error}
        helperText={helperText}
        ampm={false}
        disabled={disabled}
      />
    </MuiPickersUtilsProvider>
  );
};

export default MyDateTimePicker;
