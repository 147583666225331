import React from 'react';
import { alpha, ListItem, makeStyles, Typography } from '@material-ui/core';
import { Board } from 'types/board';
import { useModal } from 'components/modal/hooks/useModal';

const styles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    width: '100%',
    height: 150,
    alignItems: 'flex-start',
    flexDirection: 'column',
    backgroundColor: alpha(theme.palette.success.main, 0.2),
    cursor: 'pointer',
    transition: 'background 0.3s ease',
    position: 'relative',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: alpha(theme.palette.success.main, 0.3),
    },
    '&.busy': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.3),
      },
    },
  },
  tableNumber: {
    fontWeight: 400,
    color: theme.palette.success.dark,
  },
  content: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}));

interface BoardControlTransferItemProps {
  board: Board;
  handleTransfer(board: Board, closeModal: () => void): void;
}

const BoardControlTransferItem: React.FC<BoardControlTransferItemProps> = ({ board, handleTransfer }) => {
  const classes = styles();
  const { handleModalClose } = useModal();

  function handleClick() {
    handleTransfer(board, handleModalClose);
  }

  return (
    <ListItem className={classes.listItem} onClick={handleClick}>
      <Typography className={classes.tableNumber} variant="h6" align="right">
        {board.number}
      </Typography>
    </ListItem>
  );
};

export default BoardControlTransferItem;
