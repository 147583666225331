import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { useCustomDialog } from 'components/dialog/CustomDialog';

interface BillingCardActionsProps {
  handleValidation(handleCloseDialog: () => void): void;
}

const BillingCardActions: React.FC<BillingCardActionsProps> = ({ handleValidation }) => {
  const { handleCloseDialog } = useCustomDialog();

  function handleConfirm() {
    handleValidation(handleCloseDialog);
  }

  return (
    <Tooltip title="Confirmar cartão">
      <IconButton onClick={handleConfirm} color="inherit">
        <Done />
      </IconButton>
    </Tooltip>
  );
};

export default BillingCardActions;
