import React from 'react';
import NoData from 'components/nodata/NoData';
import { useCustomer } from '../../hooks/useCustomer';
import CustomerAddressList from './CustomerAddressList';

const CustomerAddresses: React.FC = () => {
  const { customer } = useCustomer();

  return (
    <>
      {customer.addresses.length ? (
        <CustomerAddressList />
      ) : (
        <NoData backgroundColor="inherit" message="Não há endereços cadastrados" />
      )}
    </>
  );
};

export default CustomerAddresses;
