import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import PrintTypography from 'components/print-typography/PrintTypography';
import { Theme } from '@material-ui/core';
import { useSelector } from 'store/redux/selector';
import { moneyFormat } from 'helpers/NumberFormat';
import BoardControlBillAction from './BoardControlBillActions';
import BoardControlBillDeveloper from './BoardControlBillDeveloper';
import BoardControlBillTotal from './BoardControlBillTotal';
import BoardControlProducts from './BoardControlBillProducts';
import DialogFullscreen from 'components/dialog/DialogFullscreen';
import { useBoardControl } from '../hooks/useBoardControl';

interface UseStylesProps {
  fontSize: number;
  noMargin: boolean;
}

const useStyles = makeStyles<Theme, UseStylesProps>({
  container: props => ({
    maxWidth: '80mm',
    minHeight: 300,
    padding: 15,
    fontSize: props.fontSize,
    backgroundColor: '#faebd7',
    border: '2px dashed #ccc',
    '@media print': {
      '&': {
        backgroundColor: 'transparent',
        border: 'none',
        padding: props.noMargin ? '0 0 0 0' : '0 0 0 10px',
        marginRight: 30,
      },
    },
  }),
  annotation: {
    marginLeft: 10,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  customerData: {
    display: 'grid',
    gridTemplateColumns: '75px 1fr',
    marginBottom: 2,
    columnGap: 7,
  },
  header: {
    borderBottom: '1px dashed #000',
    marginBottom: 20,
    paddingBottom: 10,
    textAlign: 'center',
  },
});

interface BoardControlBillProps {
  onExited(): void;
}

const BoardControlBill: React.FC<BoardControlBillProps> = ({ onExited }) => {
  const { movement } = useBoardControl();
  const restaurant = useSelector(state => state.restaurant);
  const classes = useStyles({
    fontSize: restaurant?.printer_settings?.font_size || 14,
    noMargin: !!restaurant?.printer_settings?.no_margin,
  });
  const formattedTotal = moneyFormat(movement?.total ?? 0);
  const formattedDiscount = moneyFormat(movement?.total ?? 0);

  useEffect(() => {
    setTimeout(window.print, 200);
  }, [movement]);

  return (
    <DialogFullscreen
      backgroundColor="#fff"
      title="imprimir conta"
      handleModalState={onExited}
      componentActions={<BoardControlBillAction />}
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <PrintTypography fontSize={1.2} bold>
            CONTA - MESA {movement?.board?.number}
          </PrintTypography>
        </div>

        <PrintTypography gutterBottom>{movement?.formattedCreatedAt}</PrintTypography>

        <div className={classes.customerData}>
          <PrintTypography noWrap>Cliente</PrintTypography>
          <PrintTypography>{movement?.customer?.name}</PrintTypography>
        </div>

        <BoardControlProducts products={movement?.products ?? []} />

        <BoardControlBillTotal
          formattedTotal={formattedTotal}
          formattedDiscount={formattedDiscount}
          discount={movement?.discount ?? 0}
        />
        <BoardControlBillDeveloper />
      </div>
    </DialogFullscreen>
  );
};

export default BoardControlBill;
