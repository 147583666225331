import React, { Dispatch, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { useOrder } from 'pages/board-management/registration/registration/hooks/useOrder';
import { useApp } from 'App';
import { useSelector } from 'store/redux/selector';

const styles = makeStyles(theme => ({
  action: {
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    bottom: 0,
    right: 400,
    borderTop: '1px solid #eee',
    backgroundColor: '#fff',
    padding: 15,
    zIndex: 100,
    transition: 'all 0.4s ease 0s',
    [theme.breakpoints.between('xs', 'sm')]: {
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
}));

interface OrderButtonsProps {
  setShowCustomerDialog: Dispatch<SetStateAction<boolean>>;
}

function OrderButtons({ setShowCustomerDialog }: OrderButtonsProps) {
  const app = useApp();
  const { saving, handleSubmit } = useOrder();
  const classes = styles();
  const order = useSelector(state => state.order);

  function handleClick() {
    if (!order?.customer) {
      setShowCustomerDialog(true);
      return;
    }

    handleSubmit();
  }

  return (
    <div
      style={{
        left: app.isOpenedMenu ? 300 : 0,
      }}
      className={classes.action}
    >
      <Typography color="textSecondary" variant="body2">
        Pronto para enviar
      </Typography>
      <Button disabled={saving} size="large" variant="contained" color="primary" onClick={handleClick}>
        salvar
      </Button>
    </div>
  );
}

export default OrderButtons;
