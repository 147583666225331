import React, { useRef, useEffect } from 'react';
import { FormControlLabel, TextField, Switch, makeStyles, Typography } from '@material-ui/core';
import { Board } from 'types/board';
import { BoardValidation } from './validation/useBoardValidation';

const styles = makeStyles({
  switch: {},
  switchesContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '25px 0',
  },
  radioContainer: {},
});

interface BoardFormProps {
  handleChange(key: keyof Board, value: any): void;
  board: Board;
  validation: BoardValidation;
}

const BoardForm: React.FC<BoardFormProps> = ({ handleChange, board, validation }) => {
  const classes = styles();

  const inputs = {
    number: useRef<HTMLInputElement>(null),
    description: useRef<HTMLInputElement>(null),
    max_value: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;
    if (!inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <>
      <TextField
        inputRef={inputs.number}
        error={!!validation.number}
        helperText={validation.number}
        label="Número"
        placeholder="Informe o número"
        fullWidth
        margin="normal"
        value={board.number}
        onChange={e => handleChange('number', e.target.value)}
        autoFocus
      />
      <TextField
        inputRef={inputs.description}
        error={!!validation.description}
        helperText={validation.description}
        label="Descrição"
        placeholder="Informe a descrição"
        fullWidth
        margin="normal"
        value={board.description}
        onChange={e => handleChange('description', e.target.value)}
      />
      <TextField
        inputRef={inputs.max_value}
        error={!!validation.max_value}
        helperText={validation.max_value}
        label="Limite de consumo"
        placeholder="Informe o limite de consumo"
        fullWidth
        margin="normal"
        value={board.max_value}
        onChange={e => handleChange('max_value', e.target.value)}
        inputProps={{ inputMode: 'decimal' }}
      />
      <div className={classes.switchesContainer}>
        <Typography color="textSecondary" variant="body2">
          Opções
        </Typography>
        <FormControlLabel
          className={classes.switch}
          control={
            <Switch checked={board.active} onChange={e => handleChange('active', e.target.checked)} color="primary" />
          }
          label="Ativo"
        />
        <FormControlLabel
          className={classes.switch}
          control={
            <Switch
              checked={board.customer_name_required}
              onChange={e => handleChange('customer_name_required', e.target.checked)}
              color="primary"
            />
          }
          label="Solicita nome do cliente"
        />
        <FormControlLabel
          className={classes.switch}
          control={
            <Switch
              checked={board.delivery_location_required}
              onChange={e => handleChange('delivery_location_required', e.target.checked)}
              color="primary"
            />
          }
          label="Solicita local de entrega"
        />
      </div>
    </>
  );
};

export default BoardForm;
