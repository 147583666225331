import { createContext, useContext, Dispatch } from 'react';
import { PaymentMethodsActions } from 'store/modules/payment/types';
import { PaymentMethodConfigs, PicPayConfig } from 'types/paymentMethod';

type PaymentMethodsValue = {
  loading: boolean;
  handleChange(paymentMethodId: number | string): void;
  picpaySettings: PicPayConfig;
  gatewaySettings: PaymentMethodConfigs;
  dispatch: Dispatch<PaymentMethodsActions>;
  handleSubmit(): void;
  handleConfigsChange(index: keyof PaymentMethodConfigs, value: any): void;
};

const PaymentMethodsContext = createContext<PaymentMethodsValue>({} as PaymentMethodsValue);
export const PaymentMethodsProvider = PaymentMethodsContext.Provider;

export function usePaymentMethods(): PaymentMethodsValue {
  const context = useContext(PaymentMethodsContext);
  return context;
}
