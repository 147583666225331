import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { TaxRule } from '../types/taxRule';

interface TaxRuleContextValue {
  selectedTaxRule: TaxRule | null;
  setSelectedTaxRule: Dispatch<SetStateAction<TaxRule | null>>;
}

const TaxRulesContext = createContext<TaxRuleContextValue>({} as TaxRuleContextValue);
export const TaxRulesProvider = TaxRulesContext.Provider;
export const TaxRulesConsumer = TaxRulesContext.Consumer;

export function useTaxRules(): TaxRuleContextValue {
  return useContext(TaxRulesContext);
}
