import { Restaurant } from 'types/restaurant';
import { RestaurantActionTypes, SET_RESTAURANT, SET_IS_OPEN } from './types';

const INITIAL_STATE: Restaurant | null = null;

export default function restaurant(state = INITIAL_STATE, action: RestaurantActionTypes): Restaurant | null {
  switch (action.type) {
    case SET_RESTAURANT: {
      return {
        ...action.restaurant,
      };
    }
    case '@restaurant/ADD_CREDIT_CARD': {
      if (!state) return null;

      return {
        ...state,
        credit_card: action.card,
      };
    }
    case '@restaurant/DELETE_CREDIT_CARD': {
      if (!state) return null;
      return {
        ...state,
        credit_card: null,
      };
    }
    case '@restaurant/UPDATE_RESTAURANT_PLAN': {
      if (!state) return null;

      return {
        ...state,
        plan: action.plan,
        plan_id: action.plan.id,
      };
    }

    case SET_IS_OPEN: {
      if (!state) return null;

      return {
        ...state,
        is_open: action.isOpen,
      };
    }

    case '@restaurant/SET_IS_KITCHEN_OPEN': {
      if (!state) {
        return null;
      }

      return {
        ...state,
        is_kitchen_open: action.isKitchenOpen,
      };
    }

    case '@restaurant/SET_PRINTER_SETTINGS': {
      if (!state) return null;

      return {
        ...state,
        printer_settings: action.settings,
      };
    }

    case '@restaurant/UPDATE_SETTING': {
      if (!state) {
        return null;
      }

      return {
        ...state,
        configs: {
          ...state.configs,
          [action.key]: action.value,
        },
      };
    }

    default: {
      return state;
    }
  }
}
