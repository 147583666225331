import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useTaxRules } from '../hooks/useTaxRules';

type ReceiptListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl(anchorEl: null | HTMLButtonElement): void;
};

const ReceiptListMenu: React.FC<ReceiptListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedTaxRule } = useTaxRules();

  function handleClick() {
    setAnchorEl(null);
  }

  return (
    <>
      {selectedTaxRule && (
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={handleClick}>copiar ID</MenuItem>
        </Menu>
      )}
    </>
  );
};

export default ReceiptListMenu;
