import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    backgroundColor: '#fff',
    padding: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

interface FilterBoxProps {
  children: ReactNode;
}

const FilterBox: React.FC<FilterBoxProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>{children}</div>
    </>
  );
};

export default FilterBox;
