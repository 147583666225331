import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { api } from 'services/api';
import ProductLoading from 'pages/products/ProductsLoading';
import ProductList from './ProductList';
import { IconButton, InputAdornment, TextField, makeStyles } from '@material-ui/core';
import { Product } from 'types/product';
import { Clear, Search } from '@material-ui/icons';
import NoData from 'components/nodata/NoData';
import ProductPizzaComplement from './detail/pizza_complement/ProductPizzaComplement';
import ProductComplement from './detail/complement/ProductComplement';
import { useDispatch } from 'react-redux';
import { addProducts } from 'store/redux/modules/order/actions';
import { OrderProduct } from 'types/order';
import { ProductsContextValue, ProductsProvider } from './hooks/useProducts';
import ProductSimple from './detail/simple/ProductSimple';
import Modal from 'components/modal/Modal';
import { moneyFormat } from 'helpers/NumberFormat';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useMessaging } from 'hooks/messaging';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
});

interface SearchProductsProps {
  onExited(): void;
}

let timer: NodeJS.Timeout;

const SearchProducts: React.FC<SearchProductsProps> = ({ onExited }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const inputRef = useRef<HTMLInputElement>();
  const { handleOpen } = useMessaging();

  const fetchProducts = useCallback((query: string) => {
    setLoading(true);

    api
      .get('/products', { params: { page: 1, rows: 20, q: query } })
      .then(response => {
        const productsResponse: Product[] = response.data.data;

        setProducts(
          productsResponse.map(product => {
            product.formattedPrice = moneyFormat(product.price);
            product.formattedSpecialPrice = moneyFormat(product.special_price);
            product.hasComplements = product.category.has_complement ? 'Sim' : 'Não';
            product.formattedActive = product.activated ? 'Sim' : 'Não';
            product.formattedCreatedAt = format(parseISO(product.created_at), 'PPp', { locale: ptBR });
            product.categoryName = product.category.name;
            return product;
          })
        );
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const isPizza = useMemo(() => {
    return !!selectedProduct?.category.is_pizza;
  }, [selectedProduct]);

  const isComplement = useMemo(() => {
    return !!selectedProduct?.category.has_complement && !selectedProduct?.category.is_pizza;
  }, [selectedProduct]);

  const isSimple = useMemo(() => {
    return selectedProduct ? !selectedProduct.category.has_complement : false;
  }, [selectedProduct]);

  useEffect(() => {
    if (searchValue === '') {
      setProducts([]);
    }
  }, [searchValue]);

  const handleAddProduct = useCallback(
    (product: OrderProduct, amount: number) => {
      dispatch(addProducts(product, amount));
      inputRef.current?.select();
      handleOpen('Incluído');
    },
    [dispatch, handleOpen]
  );

  function handleSearch(value: string) {
    setSearchValue(value);

    clearTimeout(timer);

    if (value.length > 0 && value.length <= 2) {
      return;
    }

    timer = setTimeout(() => fetchProducts(value), 500);
  }

  const context: ProductsContextValue = {
    selectedProduct,
    handleSelectProduct: (product: Product | null) => setSelectedProduct(product),
    handleAddProduct,
    isPizza,
    isComplement,
    isSimple,
  };

  function handleClear() {
    setSearchValue('');
    inputRef.current?.focus();
  }

  return (
    <Modal onExited={onExited} title="pesquisar" maxWidth="sm">
      <ProductsProvider value={context}>
        <div className={classes.container}>
          {isSimple && <ProductSimple hideBackdrop />}

          {isPizza && <ProductPizzaComplement hideBackdrop />}

          {isComplement && <ProductComplement hideBackdrop />}

          <TextField
            inputRef={inputRef}
            fullWidth
            label="Pesquisa"
            placeholder="Digite sua pesquisa"
            autoFocus
            value={searchValue}
            onChange={e => handleSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: !!searchValue && (
                <InputAdornment position="end">
                  <IconButton onClick={handleClear} size="small">
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {loading ? (
            <ProductLoading oneColumn quantity={5} />
          ) : products.length > 0 ? (
            <ProductList onlyOneRow products={products} />
          ) : (
            products.length === 0 && <NoData message="Nenhum produto para mostrar" backgroundColor="inherit" />
          )}
        </div>
      </ProductsProvider>
    </Modal>
  );
};

export default SearchProducts;
