import { FiscalSerial } from 'pages/fiscal-serials/types/fiscal-serial';
import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';

export interface FiscalSerialValidation {
  sequence?: string;
  type?: string;
  serial?: string;
}

type UseFiscalSerialValidation = [
  FiscalSerialValidation,
  Dispatch<SetStateAction<FiscalSerialValidation>>,
  (serial: FiscalSerial) => Promise<void>
];

export function useFiscalSerialValidation(): UseFiscalSerialValidation {
  const [validation, setValidation] = useState<FiscalSerialValidation>({} as FiscalSerialValidation);

  async function handleValidation(serial: FiscalSerial) {
    const schema = yup.object().shape({
      sequence: yup.string().required('a sequência é obrigatória'),
      serial: yup.string().required('a série é obrigatória'),
      type: yup.string().required('o tipo da série é obrigatóaria'),
    });

    try {
      await schema.validate(serial);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  return [validation, setValidation, handleValidation];
}
