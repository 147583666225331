import React, { useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import CategoryItemTable from './CustomerItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { customersTableTemplate } from '../../customersTableTemplate';
import history from 'services/history';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import CategoryListMenu from '../CustomersListMenu';
import { Customer } from 'types/customer';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type CustomerListTableProps = {
  customers: Customer[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const CustomerListTable: React.FC<CustomerListTableProps> = ({ customers, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <CategoryListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <TableContainer tableTemplate={customersTableTemplate}>
        <TableContent>
          <TableHeader>
            {customersTableTemplate
              .filter(item => !item.notShow)
              .map(item => (
                <div
                  className={classes.headerItem}
                  key={item.id}
                  onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
                >
                  <Typography noWrap variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId && (
                    <>
                      {orderedIndex.direction === 'asc' ? (
                        <ArrowUpward color="primary" />
                      ) : (
                        <ArrowDownward color="primary" />
                      )}
                    </>
                  )}
                </div>
              ))}
          </TableHeader>
          <TableBody>
            {customers.map(customer => (
              <TableRow key={customer.id} onClick={() => history.push(`/customers/${customer.id}`)}>
                <CategoryItemTable setAnchorEl={el => setAnchorEl(el)} customer={customer} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </>
  );
};

export default CustomerListTable;
