import React from 'react';
import { Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { Coupon } from 'types/coupon';

type CouponItemTableProps = {
  coupon: Coupon;
};

const CouponItemTable: React.FC<CouponItemTableProps> = ({ coupon }) => {
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div key={item.id}>
            <Typography variant="body2">{coupon[item.id] as keyof Coupon}</Typography>
          </div>
        ))}
    </>
  );
};

export default CouponItemTable;
