import React, { useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import BoardMovementItemTable from './BoardMovementItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { boardMovementsTableTemplate } from '../../boardMovementsTableTemplate';
import history from 'services/history';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import BoardMovementsListMenu from '../BoardMovementsListMenu';
import { BoardMovement } from 'types/boardMovement';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type BoardMovementListTableProps = {
  movements: BoardMovement[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const BoardMovementListTable: React.FC<BoardMovementListTableProps> = ({ movements, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <BoardMovementsListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <TableContainer tableTemplate={boardMovementsTableTemplate}>
        <TableContent>
          <TableHeader>
            {boardMovementsTableTemplate
              .filter(item => !item.notShow)
              .map(item => (
                <div
                  className={classes.headerItem}
                  key={item.id}
                  onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
                >
                  <Typography noWrap variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId && (
                    <>
                      {orderedIndex.direction === 'asc' ? (
                        <ArrowUpward color="primary" />
                      ) : (
                        <ArrowDownward color="primary" />
                      )}
                    </>
                  )}
                </div>
              ))}
          </TableHeader>
          <TableBody>
            {movements.map(item => (
              <TableRow key={item.id} onClick={() => history.push(`/board-management/movements/${item.id}`)}>
                <BoardMovementItemTable setAnchorEl={el => setAnchorEl(el)} boardMovement={item} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </>
  );
};

export default BoardMovementListTable;
