import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { BoardMovement } from 'types/boardMovement';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
  opened: { backgroundColor: '#ffc107', color: '#000', padding: '4px 10px', marginRight: 10 },
  closed: { backgroundColor: '#6c757d', color: '#fff', padding: '4px 10px', marginRight: 10 },
});

type BoardMovementItemTableProps = {
  boardMovement: BoardMovement;
  setAnchorEl(el: HTMLButtonElement): void;
};

const BoardMovementItemTable: React.FC<BoardMovementItemTableProps> = ({ boardMovement }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'formattedIsOpen' ? (
            <div key={item.id} className={boardMovement.is_open ? classes.opened : classes.closed}>
              <Typography variant="body2">{boardMovement.formattedIsOpen}</Typography>
            </div>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{boardMovement[item.id]}</Typography>
            </div>
          )
        )}
    </>
  );
};

export default BoardMovementItemTable;
