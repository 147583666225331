import { moneyFormat, percentFormat } from 'helpers/NumberFormat';
import { endOfDay } from 'date-fns';
import { useReducer } from 'react';

export const INITIAL_STATE = {
  name: '',
  description: '',
  valid_at: null,
  type: 'get', // get; safe
  rule_type: 'product', // product; category; order_value
  categories: [],
  products: [],
  order_value: null,
  offered_products: [],
  safe: null,
  activated: true,
  image: null,
  availability: [
    { day: 0, status: true },
    { day: 1, status: true },
    { day: 2, status: true },
    { day: 3, status: true },
    { day: 4, status: true },
    { day: 5, status: true },
    { day: 6, status: true },
  ],
  is_available_on_board: true,
  is_available_on_delivery: true,
};

export default function promotion(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_PROMOTION': {
      return {
        ...action.promotion,
        order_value: action.promotion.order_value && {
          ...action.promotion.order_value,
          formattedOrderValue: moneyFormat(action.promotion.order_value.order_value),
        },
        rule_type:
          action.promotion.products.length > 0
            ? 'product'
            : action.promotion.categories.length > 0
            ? 'category'
            : 'order_value',
        safe: action.promotion.safe && {
          ...action.promotion.safe,
          formattedDiscount: action.promotion.safe.discount
            ? action.promotion.safe.discount_type === 'value'
              ? moneyFormat(action.promotion.safe.discount)
              : percentFormat(action.promotion.safe.discount)
            : '',
        },
        categories: action.promotion.categories.map(category => {
          category.formattedValue = moneyFormat(category.value);
          return category;
        }),
        offered_products: action.promotion.offered_products.map(product => {
          product.formattedPrice = moneyFormat(product.price);
          product.formattedSpecialPrice = moneyFormat(product.special_price);
          product.additional = product.additional.map(additional => {
            additional.formattedPrice = moneyFormat(additional.price);
            return additional;
          });
          let pizzaSize;
          if (product.category.is_pizza) {
            const { complements } = product.complement_categories.find(category => category.is_pizza_size);
            pizzaSize = complements[0];
          }
          product.complement_categories = product.complement_categories.map(category => {
            category.complements = category.complements.map(complement => {
              complement.formattedPrice = complement.price && moneyFormat(complement.price);
              complement.prices = complement.prices.map(price => {
                price.formattedPrice = price.price && moneyFormat(price.price);
                price.selected = price.product_complement_size_id === pizzaSize.product_complement_id;
                return price;
              });
              complement.formattedPrice = complement.price && moneyFormat(complement.price);
              complement.ingredients = complement.ingredients.map(ingredient => {
                ingredient.product_complement_ingredient_id = ingredient.id;
                return ingredient;
              });

              complement.additional = complement.additional.map(additional => {
                additional.product_complement_additional_id = additional.id;
                additional.prices = additional.prices.map(price => {
                  price.product_complement_additional_price_id = price.id;
                  price.formattedPrice = price.price && moneyFormat(price.price);
                  price.selected = price.product_complement_size_id === pizzaSize.product_complement_id;
                  return price;
                });
                return additional;
              });
              return complement;
            });
            return category;
          });
          return product;
        }),
        products: action.promotion.products.map(product => {
          product.ready = true;
          product.formattedPrice = moneyFormat(product.price);
          product.formattedSpecialPrice = moneyFormat(product.special_price);
          product.additional = product.additional.map(additional => {
            additional.formattedPrice = moneyFormat(additional.price);
            return additional;
          });
          product.complement_categories = product.complement_categories.map(category => {
            category.complements = category.complements.map(complement => {
              complement.prices = complement.prices.map((price, index) => {
                price.product_complement_price_id = price.id;
                price.formattedPrice = price.price && moneyFormat(price.price);
                price.selected = index === 0;
                return price;
              });
              return complement;
            });
            return category;
          });
          return product;
        }),
      };
    }
    case 'CHANGE': {
      if (action.index === 'discount') {
        return {
          ...state,
          safe: {
            ...state.safe,
            discount: action.value,
            formattedDiscount:
              state.safe.discount_type === 'value' ? moneyFormat(action.value) : percentFormat(action.value),
          },
        };
      } else if (action.index === 'discount_type') {
        return {
          ...state,
          safe: {
            ...state.safe,
            discount_type: action.value,
            formattedDiscount:
              action.value === 'value' ? moneyFormat(state.safe.discount) : percentFormat(state.safe.discount),
          },
        };
      } else if (action.index === 'type') {
        if (action.value === 'safe') {
          return {
            ...state,
            [action.index]: action.value,
            safe: {
              discount: '',
              discount_type: 'percent',
            },
          };
        } else
          return {
            ...state,
            [action.index]: action.value,
            safe: null,
          };
      } else if (action.index === 'valid_at') {
        return {
          ...state,
          [action.index]: endOfDay(action.value),
        };
      }

      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case 'SET_CATEGORY': {
      return {
        ...state,
        categories: [
          ...state.categories,
          {
            ...action.category,
            formattedValue: moneyFormat(action.category.value),
          },
        ],
      };
    }

    case 'DELETE_CATEGORY': {
      return {
        ...state,
        categories: state.categories.filter(category => category.id !== action.categoryId),
      };
    }

    case 'SET_PRODUCT': {
      return {
        ...state,
        products: [
          ...state.products,
          {
            uid: new Date().getTime(),
            ...action.product,
          },
        ],
      };
    }

    case 'EDIT_PRODUCT': {
      const products = state.products.map(product => {
        return product.uid === action.product.uid ? action.product : product;
      });

      return {
        ...state,
        products,
      };
    }

    case 'DELETE_PRODUCT': {
      const products = state.products.filter(product => product.uid !== action.productUid);

      return {
        ...state,
        products,
      };
    }

    case 'SET_OFFERED_PRODUCT': {
      return {
        ...state,
        offered_products: [
          ...state.offered_products,
          {
            uid: new Date().getTime(),
            ...action.product,
          },
        ],
      };
    }

    case 'EDIT_OFFERED_PRODUCT': {
      const products = state.offered_products.map(product => {
        return product.uid === action.product.uid ? action.product : product;
      });

      return {
        ...state,
        offered_products: products,
      };
    }

    case 'DELETE_OFFERED_PRODUCT': {
      const products = state.offered_products.filter(product => product.uid !== action.productUid);

      return {
        ...state,
        offered_products: products,
      };
    }

    case 'SET_ORDER_VALUE': {
      if (!action.value) {
        return {
          ...state,
          order_value: null,
        };
      }

      return {
        ...state,
        order_value: {
          order_value: action.value,
          formattedOrderValue: moneyFormat(action.value),
        },
      };
    }

    case 'AVAILABILITY_CHANGE': {
      const availability = state.availability.map(item =>
        item.day === action.day ? { ...item, status: action.status } : item
      );

      return {
        ...state,
        availability,
      };
    }

    default: {
      return state;
    }
  }
}

export function usePromotionReducer() {
  const promotionReducer = useReducer(promotion, INITIAL_STATE);
  return promotionReducer;
}
