import { useEffect, useState } from 'react';
import { api, getCancelTokenSource } from 'services/api';
import { DashboardData } from 'types/dashboardData';

const portugueseDaysOfWeek = {
  Monday: 'segunda',
  Tuesday: 'terça',
  Wednesday: 'quarta',
  Thursday: 'quinta',
  Friday: 'sexta',
  Saturday: 'sábado',
  Sunday: 'domingo',
};

export function useFetchDashboard(initialDate: Date, finalDate: Date): [DashboardData | null, boolean] {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<DashboardData | null>(null);

  useEffect(() => {
    let request = true;
    const source = getCancelTokenSource();

    setLoading(true);

    api
      .get<DashboardData>('/dashboard', {
        params: {
          initial_date: initialDate,
          final_date: finalDate,
        },
      })
      .then(response => {
        setData({
          ...response.data,
          days_of_week: response.data.days_of_week.map(item => ({
            ...item,
            name: portugueseDaysOfWeek[item.name],
          })),
        });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (request) setLoading(false);
        request = false;
      });

    return () => {
      if (request) {
        source.cancel();
      }
      request = false;
    };
  }, [finalDate, initialDate]);

  return [data, loading];
}
