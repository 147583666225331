import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';

type PaymentData = {
  payment_method_id: number;
  value: number;
};

export interface BoardPaymentValidation {
  payment_method_id?: string;
  value?: string;
}

type UseBoardPaymentValidation = [
  BoardPaymentValidation,
  Dispatch<SetStateAction<BoardPaymentValidation>>,
  (payment: PaymentData) => Promise<void>
];

export function useBoardPaymentValidation(): UseBoardPaymentValidation {
  async function handleValidation(payment: PaymentData) {
    const schema = yup.object().shape({
      payment_method_id: yup
        .number()
        .typeError('O valor deve ser um número válido')
        .integer()
        .required('O método de pagamento é obrigatório'),
      value: yup
        .number()
        .typeError('O valor é obrigatório')
        .min(0.01, 'O valor deve ser maior que zero')
        .required('O valor é obrigatório'),
    });

    try {
      await schema.validate(payment);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }

      throw err;
    }
  }

  const [validation, setValidation] = useState<BoardPaymentValidation>({});

  return [validation, setValidation, handleValidation];
}
