import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultTheme } from '@material-ui/styles';

type UseStylesProps = {
  backgroundColor: string;
  minHeight: number | string;
};

const useStyles = makeStyles<DefaultTheme, UseStylesProps>({
  button: {
    marginTop: 5,
    display: 'flex',
  },
  container: props => ({
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    background: props.backgroundColor,
    marginTop: 10,
    minHeight: props.minHeight,
  }),
});

interface NoDataProps {
  message: string;
  action?(): void;
  buttonText?: string;
  secondaryMessage?: string;
  backgroundColor?: string;
  minHeight?: string | number;
}

const NoData: React.FC<NoDataProps> = ({
  message,
  action,
  buttonText,
  secondaryMessage,
  backgroundColor = '#fff',
  minHeight = 'unset',
}) => {
  const classes = useStyles({ backgroundColor, minHeight });

  return (
    <div className={classes.container}>
      <Typography variant="body1" align="center">
        {message}
      </Typography>
      {secondaryMessage && (
        <Typography variant="body2" color="textSecondary" align="center">
          {secondaryMessage}
        </Typography>
      )}
      {buttonText && (
        <div className={classes.button}>
          <Button variant="text" size="small" onClick={action} color="primary">
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
};

export default NoData;
