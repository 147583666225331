import React, { useState } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import { useApp } from 'App';
import { Grid, Typography } from '@material-ui/core';
import DashboardDateFilter from './DashboardDateFilter';
import { makeStyles } from '@material-ui/core/styles';
import DashboardActions from './DashboardActions';
import { subDays, format, endOfDay, startOfDay } from 'date-fns';
import ptbr from 'date-fns/locale/pt-BR';
import DashboardCustomDate from './DashboardCustomDate';
import DashboardCard from './cards/DashboardCard';
import DashboardLargeCard from './cards/DashboardLargeCard';
import InsideLoading from 'components/loading/InsideLoading';
import DashboardHeaderButtons from './DashboardHeaderButtons';
import { useSelector } from 'store/redux/selector';
import DashboardWeekDaysChart from './charts/DashboardWeekDaysChart';
import DashboardPaymentMethodsChart from './charts/DashboardPaymentMethodsChart';
import DashboardHoursDayChart from './charts/DashboardHoursDayChart';
import { useFetchDashboard } from './hooks/useFetchDashboard';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttons: {
    '& button': {
      marginRight: 5,
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  cardsWrapper: {
    display: 'grid',
    gridTemplateColumns: '1.3fr 1fr',
    flex: 1,
    marginTop: 15,
    gridGap: 5,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  cardContainer1: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 5,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  container: {
    flexDirection: 'column',
  },
  usernameContent: {
    display: 'flex',
    gap: 6,
    alignItems: 'center',
    '& > .restaurant-name': {
      [theme.breakpoints.down('sm')]: {
        maxWidth: 150,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
  },
}));

export const DashboardLoading: React.FC = () => (
  <div style={{ height: 41, width: '100%', display: 'flex', alignItems: 'center' }}>
    <div className="animated-background" style={{ height: 25, width: '100%' }} />
  </div>
);

const yesterday = subDays(new Date(), 1);

const Dashboard: React.FC = () => {
  const app = useApp();
  const user = useSelector(state => state.user);
  const restaurant = useSelector(state => state.restaurant);
  const classes = useStyles({ restaurantIsOpen: !!restaurant?.is_open });
  const [dateConfig, setDateConfig] = useState<number | null>(1);
  const [initialDate, setInitialDate] = useState(startOfDay(yesterday));
  const [finalDate, setFinalDate] = useState(endOfDay(yesterday));
  const [dialogDate, setDialogDate] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [data, loading] = useFetchDashboard(initialDate, finalDate);

  function handleDateConfig(days: number) {
    setDateConfig(days);
    if (days === 0) {
      const date = new Date();
      setInitialDate(startOfDay(date));
      setFinalDate(endOfDay(date));
    } else {
      const date = new Date();
      setFinalDate(endOfDay(date));
      setInitialDate(startOfDay(subDays(date, days)));
    }
  }

  function handleConfirmCustomDates(initialDate: Date, finalDate: Date) {
    setInitialDate(initialDate);
    setFinalDate(finalDate);
    setDateConfig(null);
  }

  return (
    <>
      <DashboardCustomDate
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        handleConfirmCustomDates={handleConfirmCustomDates}
      />
      {dialogDate && (
        <DashboardDateFilter
          handleDateConfig={handleDateConfig}
          dateConfig={dateConfig}
          loading={loading}
          onExited={() => setDialogDate(false)}
          handleConfirmCustomDates={handleConfirmCustomDates}
        />
      )}
      <CustomAppbar
        title="Dashboard"
        ActionComponents={<DashboardActions handleOpenDialog={() => setDialogDate(true)} />}
      />
      {app.loading ? (
        <InsideLoading />
      ) : (
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.header}>
            <div>
              <div className={classes.usernameContent}>
                <Typography variant="body1">Olá {user.name}</Typography>
                <Typography className="restaurant-name" variant="body2" color="textSecondary">
                  ({restaurant?.name})
                </Typography>
              </div>
              <Typography variant="body2">
                Você está visualizando um resumo de{' '}
                <strong>
                  {format(initialDate, 'P', { locale: ptbr })}
                  {' à '}
                  {format(finalDate, 'P', { locale: ptbr })}
                </strong>
              </Typography>
            </div>
            <DashboardHeaderButtons
              loading={loading}
              handleDateConfig={handleDateConfig}
              setAnchorEl={el => setAnchorEl(el)}
              dateConfig={dateConfig}
            />
          </Grid>
          <div className={classes.cardsWrapper}>
            <div className={classes.cardContainer1}>
              <DashboardCard title="Pedidos" value={data?.order_totals.amount ?? 0} loading={loading} />
              <DashboardCard
                title="Ticket médio"
                value={data?.order_totals.average_ticket ?? 0}
                loading={loading}
                formatAsMoney
              />
              <DashboardCard
                title="Total dos pedidos"
                value={data?.order_totals.total ?? 0}
                loading={loading}
                formatAsMoney
              />
              <DashboardCard title="Novos clientes" value={data?.customers_quantity ?? 0} loading={loading} />
            </div>
            <DashboardLargeCard title="Métodos de pagamento" loading={loading} data={data?.payment_methods ?? []}>
              <DashboardPaymentMethodsChart data={data?.payment_methods ?? []} />
            </DashboardLargeCard>
            <DashboardLargeCard title="Dias da semana" loading={loading} data={data?.days_of_week ?? []}>
              <DashboardWeekDaysChart data={data?.days_of_week ?? []} />
            </DashboardLargeCard>
            <DashboardLargeCard title="Horas do dia" loading={loading} data={data?.orders_by_hours ?? []}>
              <DashboardHoursDayChart data={data?.orders_by_hours ?? []} />
            </DashboardLargeCard>
          </div>
        </Grid>
      )}
    </>
  );
};

export default Dashboard;
