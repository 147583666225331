import React, { useRef, useEffect } from 'react';
import { TextField, Typography, makeStyles } from '@material-ui/core';
import { TaxRule } from '../types/taxRule';
import { TaxRuleValidation } from './validation/useTaxRuleValidation';

const styles = makeStyles({
  row: {
    '& > div': {
      display: 'flex',
      gap: 10,
    },
    padding: 20,
    border: '1px solid #eee',
    margin: '20px 0',
  },
});

interface BoardFormProps {
  handleChange(key: keyof TaxRule, value: any): void;
  taxRule: TaxRule;
  validation: TaxRuleValidation;
}

const BoardForm: React.FC<BoardFormProps> = ({ handleChange, taxRule, validation }) => {
  const classes = styles();

  useEffect(() => {
    handleChange('external_cfop', taxRule.internal_cfop.length ? `6${taxRule.internal_cfop.slice(1)}` : '');
  }, [taxRule.internal_cfop, handleChange]);

  const inputs = {
    description: useRef<HTMLInputElement>(null),
    internal_cfop: useRef<HTMLInputElement>(null),
    external_cfop: useRef<HTMLInputElement>(null),
    icms_code: useRef<HTMLInputElement>(null),
    icms_aliquot: useRef<HTMLInputElement>(null),
    pis_aliquot: useRef<HTMLInputElement>(null),
    cofins_aliquot: useRef<HTMLInputElement>(null),
    pis_cofins_code: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) {
      return;
    }

    if (!inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <>
      <TextField
        inputRef={inputs.description}
        error={!!validation.description}
        helperText={validation.description}
        label="Descrição"
        placeholder="Descriação da regra fiscal"
        fullWidth
        margin="normal"
        value={taxRule.description}
        onChange={e => handleChange('description', e.target.value)}
        autoFocus
      />

      <div className={classes.row}>
        <Typography variant="caption" color="textSecondary">
          CFOP
        </Typography>
        <div>
          <TextField
            inputRef={inputs.internal_cfop}
            error={!!validation.internal_cfop}
            helperText={validation.internal_cfop}
            label="CFOP interna"
            placeholder="CFOP para operações no estado"
            fullWidth
            margin="normal"
            value={taxRule.internal_cfop}
            onChange={e => handleChange('internal_cfop', e.target.value)}
          />

          <TextField
            inputRef={inputs.external_cfop}
            error={!!validation.external_cfop}
            helperText={validation.external_cfop}
            label="CFOP externa"
            placeholder="CFOP para operações para fora do estado"
            fullWidth
            margin="normal"
            value={taxRule.external_cfop}
            onChange={e => handleChange('external_cfop', e.target.value)}
            disabled
          />
        </div>
      </div>

      <div className={classes.row}>
        <Typography variant="caption" color="textSecondary">
          ICMS
        </Typography>
        <div>
          <TextField
            inputRef={inputs.icms_code}
            error={!!validation.icms_code}
            helperText={validation.icms_code}
            label="Situação tributária"
            placeholder="CST ou CSOSN"
            fullWidth
            margin="normal"
            value={taxRule.icms_code}
            onChange={e => handleChange('icms_code', e.target.value)}
          />

          <TextField
            inputRef={inputs.icms_aliquot}
            error={!!validation.icms_aliquot}
            helperText={validation.icms_aliquot}
            label="Alíquota do ICMS"
            placeholder="Aliquota do ICMS"
            fullWidth
            margin="normal"
            value={taxRule.icms_aliquot}
            onChange={e => handleChange('icms_aliquot', e.target.value)}
            inputProps={{ inputMode: 'decimal' }}
          />
        </div>
      </div>

      <div className={classes.row}>
        <Typography variant="caption" color="textSecondary">
          PIS / COFINS
        </Typography>
        <div>
          <TextField
            inputRef={inputs.pis_cofins_code}
            error={!!validation.pis_cofins_code}
            helperText={validation.pis_cofins_code}
            label="Situção tributária"
            placeholder="Situação tributária"
            fullWidth
            margin="normal"
            value={taxRule.pis_cofins_code}
            onChange={e => handleChange('pis_cofins_code', e.target.value)}
          />

          <TextField
            inputRef={inputs.pis_aliquot}
            error={!!validation.pis_aliquot}
            helperText={validation.pis_aliquot}
            label="Alíquota PIS"
            placeholder="Aliquota do PIS"
            fullWidth
            margin="normal"
            value={taxRule.pis_aliquot}
            onChange={e => handleChange('pis_aliquot', e.target.value)}
            inputProps={{ inputMode: 'decimal' }}
          />

          <TextField
            inputRef={inputs.cofins_aliquot}
            error={!!validation.cofins_aliquot}
            helperText={validation.cofins_aliquot}
            label="Alíquota COFINS"
            placeholder="Aliquota do COFINS"
            fullWidth
            margin="normal"
            value={taxRule.cofins_aliquot}
            onChange={e => handleChange('cofins_aliquot', e.target.value)}
            inputProps={{ inputMode: 'decimal' }}
          />
        </div>
      </div>
    </>
  );
};

export default BoardForm;
