import React, { FC, useReducer, useState, useContext } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import PushNotificationActions from './PushNotificationActions';
import pushNotificationReducer, {
  INITIAL_STATE as pushNotificationInitialState,
} from 'store/modules/push-notification/reducer';
import { pushNotificationChange } from 'store/modules/push-notification/actions';
import Loading from 'components/loading/Loading';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import history from 'services/history';
import PushNotificationForm from '../PushNotificationForm';
import { usePushNotificationValidation } from '../validation/pushNotificationValidation';

export interface PushNotificationValidation {
  title?: string;
  message?: string;
  url_action?: string;
  image?: string;
  delivery_date?: string;
}

interface PushNotificatoinContext {
  dispatch(): void;
}

export const pushNotificationContext = React.createContext<PushNotificatoinContext>({} as PushNotificatoinContext);

const PushNotifications: FC = () => {
  const [pushNotification, dispatch] = useReducer(pushNotificationReducer, pushNotificationInitialState);
  const [validation, setValidation, validate] = usePushNotificationValidation();
  const [saving, setSaving] = useState(false);
  const messaging = useMessaging();

  function handleValidation() {
    setValidation({});

    validate(pushNotification)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/pushNotifications', pushNotification)
      .then(() => {
        messaging.handleOpen('Salvo');
        history.push('/push-notifications');
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível salvar');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleChange(index: string, value: any) {
    dispatch(pushNotificationChange(index, value));
  }

  return (
    <>
      {saving && <Loading />}
      <CustomAppbar
        title={pushNotification.isImageSelected ? 'Imagem' : 'Notificação Push'}
        backAction={() => handleChange('isImageSelected', !pushNotification.isImageSelected)}
        ActionComponents={
          <PushNotificationActions
            handleValidation={handleValidation}
            saving={false}
            notificationImageSelected={pushNotification.isImageSelected}
            handleChange={handleChange}
          />
        }
      />
      <PageHeader
        title="Nova notificação"
        description="A notificação push é uma mensagem de texto com título em destaque e a logo do app ao lado"
        backAction={() => history.push('/push-notifications')}
      />
      <form onSubmit={handleValidation}>
        <PushNotificationForm pushNotification={pushNotification} handleChange={handleChange} validation={validation} />
      </form>
    </>
  );
};

export function usePushNotification(): PushNotificatoinContext {
  const context = useContext(pushNotificationContext);
  return context;
}

export default PushNotifications;
