import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFound from 'components/notfound/NotFound';
import Login from 'pages/login/Login';
import Forgot from 'pages/forgot/Forgot';
import Dashboard from 'components/dashboard/Dashboard';
import Home from 'components/home/Home';
import Profile from 'components/profile/Profile';
import OpeningHours from 'components/opening-hour/OpeningHour';
import Area from 'components/area/Areas';
import NewProduct from 'pages/products/registration/new/Product';
import EditProduct from 'pages/products/registration/edit/Product';
import Additional from 'pages/additional/Additional';
import EditAdditional from 'pages/additional/registration/edit/EditAdditional';
import NewAdditional from 'pages/additional/registration/new/NewAdditional';
import Ingredient from 'pages/ingredients/Ingredients';
import NewIngredient from 'pages/ingredients/registration/new/IngredientNew';
import EditIngredient from 'pages/ingredients/registration/edit/IngredientEdit';
import NewOrder from 'pages/orders/registration/new/Order';
import EditOrder from 'pages/orders/registration/edit/Order';
import RestaurantConfig from 'pages/settings/RestaurantConfig';
import Promotions from 'pages/promotion/Promotions';
import Coupon from 'pages/coupons/Coupons';
import CouponNew from 'pages/coupons/registration/new/Coupon';
import CouponEdit from 'pages/coupons/registration/edit/Coupon';
import Printers from 'components/printers/Printers';
import PrinterNew from 'components/printers/new/Printer';
import PrinterEdit from 'components/printers/edit/Printer';
import PromotionNew from 'pages/promotion/registration/new/Promotion';
import PromotionEdit from 'pages/promotion/registration/edit/Promotion';
import Report from 'components/reports/Report';
import ReportTopCustomers from 'pages/reports/top_customers/ReportTopCustomers';
import Deliverers from 'components/deliverers/Deliverers';
import DeliverersNew from 'components/deliverers/new/Deliverer';
import DeliverersEdit from 'components/deliverers/edit/Deliverer';
import Account from 'components/account/Account';
import PrivateNotFound from 'components/notfound/PrivateNotFound';
import PushNotifications from 'pages/push-notifications/PushNotifications';
import PushNotificationNew from 'pages/push-notifications/registration/new/PushNotification';
import PushNotificationEdit from 'pages/push-notifications/registration/edit/PushNotification';
import DeliveryOrders from 'pages/orders/Orders';
import BoardOrders from 'pages/board-orders/BoardOrders';
import PasswordReset from 'pages/passoword-reset/PasswordReset';
import Products from 'pages/products/Products';
import Categories from 'pages/categories/Categories';
import Billing from 'pages/billing/Billing';
import CategoryUpdate from 'pages/categories/registration/update/CategoryUpdate';
import CategoryNew from 'pages/categories/registration/new/CategoryNew';
import Users from 'pages/users/Users';
import UserNew from 'pages/users/registration/new/UserNew';
import CashRegisters from 'pages/cash-registers/CashRegisters';
import PrinterSettings from 'pages/printer-settings/PrinterSettings';
import ProductManager from 'pages/product-complements/ProductManager';
import TopProductsReport from 'pages/reports/TopProductsReport';
import Tables from 'pages/boards/Boards';
import BoardNew from 'pages/boards/registration/new/BoardNew';
import BoardEdit from 'pages/boards/registration/edit/BoardEdit';
import BoardManagement from 'pages/board-management/BoardManagement';
import Customers from 'pages/customers/Customers';
import CustomerNew from 'pages/customers/registration/new/CustomerNew';
import CustomerEdit from 'pages/customers/registration/edit/CustomerEdit';
import PublicRoute from './PublicRoute';
import ProtectedRoute from './ProtectedRoute';
import BoardManagementOrder from 'pages/board-management/registration/registration/new/Order';
import BoardControl from 'pages/board-management/registration/board/BoardControl';
import BoardMovements from 'pages/board-movements/BoardMovements';
import CashRegister from 'pages/cash-registers/registration/CashRegister';
import Receipts from 'pages/receipts/Receipts';
import TaxRules from 'pages/tax-rules/TaxRules';
import TaxRuleNew from 'pages/tax-rules/registration/new/TaxRuleNew';
import TaxRuleEdit from 'pages/tax-rules/registration/edit/TaxRuleEdit';
import FiscalSerials from 'pages/fiscal-serials/FiscalSerials';
import FiscalSerialEdit from 'pages/fiscal-serials/registration/edit/FiscalSerialEdit';
import FiscalSerialNew from 'pages/fiscal-serials/registration/new/FiscalSerialNew';
import FiscalSettings from 'pages/fiscal-settings/FiscalSettings';
import PaymentMethods from 'pages/payment-methods/PaymentMethods';
import UpdatePaymentMethod from 'pages/payment-methods/registration/update/UpdatePaymentMethod';
import NewPaymentMethod from 'pages/payment-methods/registration/new/NewPaymentMethod';
import PaymentGatewaySettings from 'pages/payment-gateway-settings/PaymentGatewaySettings';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/login" element={<PublicRoute element={<Login />} />} />
      <Route path="/forgot" element={<PublicRoute element={<Forgot />} />} />
      <Route path="/password-reset" element={<PublicRoute element={<PasswordReset />} />} />
      <Route path="/" element={<ProtectedRoute element={<Home />} />} />
      <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
      <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
      <Route path="/payment-methods" element={<ProtectedRoute element={<PaymentMethods />} />} />
      <Route path="/payment-methods/new" element={<ProtectedRoute element={<NewPaymentMethod />} />} />
      <Route path="/payment-methods/:id" element={<ProtectedRoute element={<UpdatePaymentMethod />} />} />
      <Route path="/payment-gateway-settings" element={<ProtectedRoute element={<PaymentGatewaySettings />} />} />
      <Route path="/opening-hours" element={<ProtectedRoute element={<OpeningHours />} />} />
      <Route path="/area" element={<ProtectedRoute element={<Area />} />} />
      <Route path="/menu/categories" element={<ProtectedRoute element={<Categories />} />} />
      <Route path="/menu/category" element={<ProtectedRoute element={<CategoryNew />} />} />
      <Route path="/menu/categories/:id" element={<ProtectedRoute element={<CategoryUpdate />} />} />
      <Route path="/menu/products" element={<ProtectedRoute element={<Products />} />} />
      <Route path="/menu/product" element={<ProtectedRoute element={<NewProduct />} />} />
      <Route path="/menu/product/:id" element={<ProtectedRoute element={<EditProduct />} />} />
      <Route path="/menu/additional" element={<ProtectedRoute element={<Additional />} />} />
      <Route path="/menu/additional/new" element={<ProtectedRoute element={<NewAdditional />} />} />
      <Route path="/menu/additional/:id" element={<ProtectedRoute element={<EditAdditional />} />} />
      <Route path="/menu/ingredients" element={<ProtectedRoute element={<Ingredient />} />} />
      <Route path="/menu/ingredients/new" element={<ProtectedRoute element={<NewIngredient />} />} />
      <Route path="/menu/ingredients/:id" element={<ProtectedRoute element={<EditIngredient />} />} />
      <Route path="/board-orders" element={<ProtectedRoute element={<BoardOrders />} />} />
      <Route path="/delivery-orders" element={<ProtectedRoute element={<DeliveryOrders />} />} />
      <Route path="/customers" element={<ProtectedRoute element={<Customers />} />} />
      <Route path="/customers/new" element={<ProtectedRoute element={<CustomerNew />} />} />
      <Route path="/customers/:id" element={<ProtectedRoute element={<CustomerEdit />} />} />
      <Route path="/orders/new" element={<ProtectedRoute element={<NewOrder />} />} />
      <Route path="/orders/:id" element={<ProtectedRoute element={<EditOrder />} />} />
      <Route path="/settings" element={<ProtectedRoute element={<RestaurantConfig />} />} />
      <Route path="/coupons" element={<ProtectedRoute element={<Coupon />} />} />
      <Route path="/coupons/new" element={<ProtectedRoute element={<CouponNew />} />} />
      <Route path="/coupons/:id" element={<ProtectedRoute element={<CouponEdit />} />} />
      <Route path="/printers" element={<ProtectedRoute element={<Printers />} />} />
      <Route path="/printers/new" element={<ProtectedRoute element={<PrinterNew />} />} />
      <Route path="/printers/:id" element={<ProtectedRoute element={<PrinterEdit />} />} />
      <Route path="/promotions" element={<ProtectedRoute element={<Promotions />} />} />
      <Route path="/promotions/new" element={<ProtectedRoute element={<PromotionNew />} />} />
      <Route path="/promotions/:id" element={<ProtectedRoute element={<PromotionEdit />} />} />
      <Route path="/reports" element={<ProtectedRoute element={<Report />} />} />
      <Route path="/reports/top-customers" element={<ProtectedRoute element={<ReportTopCustomers />} />} />
      <Route path="/deliverers" element={<ProtectedRoute element={<Deliverers />} />} />
      <Route path="/deliverers/new" element={<ProtectedRoute element={<DeliverersNew />} />} />
      <Route path="/deliverers/:id" element={<ProtectedRoute element={<DeliverersEdit />} />} />
      <Route path="/account" element={<ProtectedRoute element={<Account />} />} />
      <Route path="/not-found" element={<ProtectedRoute element={<PrivateNotFound />} />} />
      <Route path="/push-notifications" element={<ProtectedRoute element={<PushNotifications />} />} />
      <Route path="/push-notifications/new" element={<ProtectedRoute element={<PushNotificationNew />} />} />
      <Route path="/push-notifications/:id" element={<ProtectedRoute element={<PushNotificationEdit />} />} />
      <Route path="/cash-registers" element={<ProtectedRoute element={<CashRegisters />} />} />
      <Route path="/billing" element={<ProtectedRoute element={<Billing />} />} />
      <Route path="/users" element={<ProtectedRoute element={<Users />} />} />
      <Route path="/users/add" element={<ProtectedRoute element={<UserNew />} />} />
      <Route path="/printer-settings" element={<ProtectedRoute element={<PrinterSettings />} />} />
      <Route path="/product-complement-manager" element={<ProtectedRoute element={<ProductManager />} />} />
      <Route path="/reports/top-products" element={<ProtectedRoute element={<TopProductsReport />} />} />
      <Route path="/boards" element={<ProtectedRoute element={<Tables />} />} />
      <Route path="/board" element={<ProtectedRoute element={<BoardNew />} />} />
      <Route path="/board/:id" element={<ProtectedRoute element={<BoardEdit />} />} />
      <Route path="/board-management" element={<ProtectedRoute element={<BoardManagement />} />} />
      <Route path="/board-management/orders" element={<ProtectedRoute element={<BoardManagementOrder />} />} />
      <Route path="/board-management/movements/:id" element={<ProtectedRoute element={<BoardControl />} />} />
      <Route path="/board-movement/movements" element={<ProtectedRoute element={<BoardMovements />} />} />
      <Route path="/receipts" element={<ProtectedRoute element={<Receipts />} />} />
      <Route path="/cash-register/:id" element={<ProtectedRoute element={<CashRegister />} />} />
      <Route path="/tax-rules" element={<ProtectedRoute element={<TaxRules />} />} />
      <Route path="/tax-rules/:id" element={<ProtectedRoute element={<TaxRuleEdit />} />} />
      <Route path="/tax-rules/new" element={<ProtectedRoute element={<TaxRuleNew />} />} />
      <Route path="/fiscal-serials" element={<ProtectedRoute element={<FiscalSerials />} />} />
      <Route path="/fiscal-serials/:id" element={<ProtectedRoute element={<FiscalSerialEdit />} />} />
      <Route path="/fiscal-serials/new" element={<ProtectedRoute element={<FiscalSerialNew />} />} />
      <Route path="/fiscal-settings" element={<ProtectedRoute element={<FiscalSettings />} />} />
    </Routes>
  );
};

export default AppRoutes;
