import React, { useState } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import TaxRuleAppbarButtons from './FiscalSerialNewAppbarButtons';
import PageHeader from 'components/page-header/PageHeader';
import { makeStyles } from '@material-ui/core';
import FiscalSerialRuleForm from '../FiscalSerialRuleForm';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import history from 'services/history';
import Loading from 'components/loading/Loading';
import { FiscalSerial } from 'pages/fiscal-serials/types/fiscal-serial';
import { useFiscalSerialReducer } from '../reducer/reducer';
import { useFiscalSerialValidation } from '../validation/useTaxRuleValidation';
import { fiscalSerialChange } from '../reducer/actions';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 400,
  },
});

const FiscalSerialNew: React.FC = () => {
  const classes = styles();

  const [serial, dispatch] = useFiscalSerialReducer();
  const [validation, setValidation, validate] = useFiscalSerialValidation();
  const messaging = useMessaging();
  const [saving, setSaving] = useState(false);

  function handleValidation() {
    setValidation({});

    validate(serial)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/fiscal-serials', serial)
      .then(() => {
        messaging.handleOpen('Salvo');
        setSaving(false);
        history.push('/fiscal-serials');
      })
      .catch(() => {
        messaging.handleOpen('Não foi possível salvar');
        setSaving(false);
      });
  }

  function handleChange(key: keyof FiscalSerial, value: any) {
    dispatch(fiscalSerialChange(key, value));
  }

  return (
    <>
      {saving && <Loading />}

      <CustomAppbar title="Série fiscal" ActionComponents={<TaxRuleAppbarButtons handleSubmit={handleValidation} />} />

      <PageHeader
        title="Série fiscal"
        description="Cadastro para nova série fiscal"
        backAction={() => history.push('/fiscal-serials')}
      />

      <div className={classes.container}>
        <FiscalSerialRuleForm type="new" handleChange={handleChange} serial={serial} validation={validation} />
      </div>
    </>
  );
};

export default FiscalSerialNew;
