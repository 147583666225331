import React, { useState, useReducer, FormEvent } from 'react';
import { useApp } from 'App';
import { Typography } from '@material-ui/core';
import { userChange } from 'store/modules/user/actions';
import { useMessaging } from 'hooks/messaging';
import userReducer, { INITIAL_STATE as accountInitialState } from 'store/modules/user/reducer';
import PageHeader from 'components/page-header/PageHeader';
import UserForm from '../UserForm';
import * as yup from 'yup';
import { api } from 'services/api';
import Loading from 'components/loading/Loading';
import UserActions from './UserActions';
import history from 'services/history';
import { UserProvider } from 'pages/users/hooks/useUser';
import Appbar from '../../../../components/appbar/Appbar';

export interface Validation {
  name?: string;
  email?: string;
  phone?: string;
  password?: string;
  password_confirmation?: string;
}

const Account: React.FC = () => {
  const app = useApp();
  const messaging = useMessaging();
  const [user, dispatch] = useReducer(userReducer, accountInitialState);
  const [validation, setValidation] = useState<Validation>({} as Validation);
  const [saving, setSaving] = useState(false);

  function handleValidation(event: FormEvent) {
    event.preventDefault();

    const schema = yup.object().shape({
      password_confirmation: yup
        .string()
        .min(4, 'A senha deve ter no mínimo 4 caracteres')
        .oneOf([yup.ref('password'), undefined], 'As senhas devem ser iguais')
        .required('A confirmação da senha é obrigatória'),
      password: yup.string().min(4, 'A senha deve ter no mínino 4 caracteres').required('A senha é obrigatória'),
      phone: yup
        .string()
        .transform((value, originalValue) => {
          return originalValue.replace(/\D/g, '');
        })
        .min(10, 'Telefone inválido')
        .required('O telefone é obrigatório'),
      email: yup.string().email('Informe um e-mail válido').required('O e-mail é obrigatório'),
      name: yup.string().required('O nome é obrigatório'),
    });

    schema
      .validate(user)
      .then(() => {
        setValidation({});
        handleSubmit();
      })
      .catch(err => {
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/users', user)
      .then(() => {
        messaging.handleOpen('Salvo');
        history.push('/users');
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível salvar');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleChange(index: string, value: any): void {
    dispatch(userChange(index, value));
  }

  return (
    <UserProvider value={{ dispatch }}>
      {saving && <Loading />}
      <Appbar
        title="Novo usuário"
        ActionComponents={<UserActions handleValidation={handleValidation} saving={saving} />}
      />
      {app.loading ? (
        <Typography>Carregando...</Typography>
      ) : (
        <>
          <PageHeader
            title="Novo usuário"
            description="Crie novos usuários para acesso ao sistema"
            backAction={() => history.push('/users')}
          />
          <UserForm
            handleChange={handleChange}
            user={user}
            handleValidation={handleValidation}
            validation={validation}
          />
        </>
      )}
    </UserProvider>
  );
};

export default Account;
