import React, { FormEvent, useEffect, useRef, MouseEvent, useState } from 'react';
import { Grid, TextField, Link, Typography, Button } from '@material-ui/core';
import PhoneInput from 'components/masked-input/PhoneInput';
import { Validation } from './Account';
import { makeStyles } from '@material-ui/core/styles';
import AccountImageForm from './AccountImageForm';
import { useAuth } from 'hooks/auth';
import { User } from 'types/user';
import history from 'services/history';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  imageContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 20,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  link: {
    marginTop: 20,
    marginBottom: 20,
    '& a': {
      fontWeight: 400,
    },
  },
  disconnectButton: {
    marginTop: 10,
    backgroundColor: '#da2b1e',
    '&:hover': {
      backgroundColor: '#da2b1e',
    },
  },
  form: {
    marginBottom: 100,
  },
}));

type AccountFormProps = {
  handleValidation(event: FormEvent): void;
  handleResetPasswordLinkClick(event: MouseEvent): void;
  account: User;
  validation: Validation;
  handleChange(index: keyof User, value: any): void;
};

const AccountForm: React.FC<AccountFormProps> = ({
  handleValidation,
  handleResetPasswordLinkClick,
  account,
  validation,
  handleChange,
}) => {
  const classes = useStyles();
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);

  const inputs = {
    name: useRef<HTMLInputElement>(null),
    phone: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  function handleDisconnectClick() {
    setLoading(true);

    logout()
      .then(() => history.push('/login'))
      .catch(() => setLoading(false));
  }

  return (
    <form className={classes.form} onSubmit={handleValidation}>
      <div className={classes.container}>
        <div className={classes.imageContent}>
          <AccountImageForm account={account} handleChange={handleChange} />
        </div>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <TextField
            variant="standard"
            margin="normal"
            fullWidth
            label="E-mail"
            placeholder="Seu e-mail"
            value={account.email}
            onChange={event => handleChange('email', event.target.value)}
            autoComplete="email"
            disabled
          />
          <TextField
            inputRef={ref => (inputs.name = ref)}
            error={!!validation.name}
            helperText={validation.name && validation.name}
            variant="standard"
            margin="normal"
            fullWidth
            label="Nome completo"
            placeholder="Seu nome completo"
            value={account.name}
            autoComplete="name"
            onChange={event => handleChange('name', event.target.value)}
            autoFocus
          />
          <TextField
            inputRef={ref => (inputs.phone = ref)}
            error={!!validation.phone}
            helperText={validation.phone && validation.phone}
            variant="standard"
            margin="normal"
            fullWidth
            label="Telefone"
            placeholder="Seu telefone"
            value={account.phone}
            onChange={event => handleChange('phone', event.target.value)}
            InputProps={{
              inputComponent: PhoneInput as any,
            }}
            autoComplete="tel"
          />
          <div className={classes.link}>
            <Link onClick={handleResetPasswordLinkClick} href="#">
              alterar minha senha
            </Link>
            <Typography variant="caption" color="textSecondary" display="block">
              Uma mensagem será enviada para o e-mail da sua conta, com instruções sobre como gerar uma nova senha
            </Typography>
          </div>
          <Button
            variant="contained"
            color="primary"
            classes={{ containedPrimary: classes.disconnectButton }}
            onClick={handleDisconnectClick}
            size="large"
            fullWidth
            disabled={loading}
          >
            Desconectar
          </Button>
        </Grid>
      </div>
      <button type="submit" style={{ display: 'none' }} />
    </form>
  );
};

export default AccountForm;
