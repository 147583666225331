import React, { HTMLAttributes } from 'react';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import { GridMode } from 'hooks/useGridMode';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export type DisplayModeOptions = GridMode;

interface DisplayModeButtonsProps extends HTMLAttributes<HTMLDivElement> {
  handleShowList(): void;
  handleShowModule(): void;
  displayMode: GridMode;
}

const DisplayModeButtons: React.FC<DisplayModeButtonsProps> = ({
  handleShowList,
  handleShowModule,
  displayMode,
  className,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <div className={`${classes.container} ${className}`} {...rest}>
      <Tooltip title="Exibir no modo tabela">
        <IconButton onClick={handleShowList}>
          <ViewListIcon color={displayMode === 'list' ? 'primary' : undefined} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Exibir no modo blocos">
        <IconButton onClick={handleShowModule}>
          <ViewModuleIcon color={displayMode === 'module' ? 'primary' : undefined} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default DisplayModeButtons;
