import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useMap } from 'providers/google-maps/MapProvider';
import { useCustomerAddress } from './useCustomerAddress';
import { useLocation } from 'providers/location';
import { useRestaurantAddressPosition } from 'hooks/useRestaurantAddressPosition';
import { useRestaurantAddress } from 'hooks/useRestaurantAddress';

type UseMyLocation = [boolean, Dispatch<SetStateAction<boolean>>];

export function useMyLocation(): UseMyLocation {
  const { setStep, setPosition, setAddress } = useCustomerAddress();
  const [devicePositionRequested, setDeviceLocationRequested] = useState(false);
  const { getAddressFromLocation } = useMap();
  const { location, isPermittionDenied } = useLocation();
  const restaurantAddressPosition = useRestaurantAddressPosition();
  const restaurantAddress = useRestaurantAddress();

  const setPositionFromDevice = useCallback(async () => {
    if (!location) {
      throw new Error('Não há coordenadas');
    }

    setPosition({ lat: location.latitude, lng: location.longitude });
    const response = await getAddressFromLocation({ lat: location.latitude, lng: location.longitude });

    if (!response) {
      throw new Error('Não foi encontrado endereço para a coordenada');
    }

    setAddress({
      ...response,
      created_at: '',
      customer_id: 0,
      area_region: null,
      formattedDistance: '',
      formattedDistanceTax: '',
      reference_point: '',
      is_main: false,
      distance: null,
      distance_tax: null,
    });
  }, [getAddressFromLocation, location, setAddress, setPosition]);

  useEffect(() => {
    if (!devicePositionRequested) {
      return;
    }

    if (!restaurantAddress) {
      return;
    }

    if (isPermittionDenied) {
      setAddress({
        ...restaurantAddress,
        created_at: '',
        customer_id: 0,
        area_region: null,
        formattedDistance: '',
        formattedDistanceTax: '',
        reference_point: '',
        is_main: false,
        distance: null,
        distance_tax: null,
      });
      setPosition(restaurantAddressPosition);
      setStep(2);
      return;
    }

    setPositionFromDevice()
      .then(() => setStep(2))
      .catch(err => console.error(err));
  }, [
    devicePositionRequested,
    isPermittionDenied,
    restaurantAddress,
    restaurantAddressPosition,
    setAddress,
    setPosition,
    setPositionFromDevice,
    setStep,
  ]);

  return [devicePositionRequested, setDeviceLocationRequested];
}
