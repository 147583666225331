import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { FiscalSerialOnList } from '../types/fiscal-serial';
import { DisableSequencePayload } from '../types/disable-sequence-payload';

interface TaxRuleContextValue {
  selectedSerial: FiscalSerialOnList | null;
  setSelectedSerial: Dispatch<SetStateAction<FiscalSerialOnList | null>>;
  handleActive(): void;
  handleDisableSequence(payload: DisableSequencePayload): Promise<void>;
  setDisableSequenceModal: Dispatch<SetStateAction<boolean>>;
}

const FiscalSerialContext = createContext<TaxRuleContextValue>({} as TaxRuleContextValue);
export const FiscalSerialProvider = FiscalSerialContext.Provider;
export const FiscalSerialConsumer = FiscalSerialContext.Consumer;

export function useFiscalSerials(): TaxRuleContextValue {
  return useContext(FiscalSerialContext);
}
