import React, { ReactElement } from 'react';
import { IconButton, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';

interface useStylesProps {
  gutterBottom: boolean;
}

const useStyles = makeStyles(theme => ({
  container: ({ gutterBottom }: useStylesProps) => ({
    marginBottom: gutterBottom ? 10 : 0,
    display: 'flex',
    alignItems: 'center',
    '@media print': {
      display: 'none',
    },
  }),
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  actions: {
    '& button + button': {
      marginLeft: 7,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

interface PageHeaderActionsProps {
  title: string;
  description?: string;
  gutterBottom?: boolean;
  ActionComponent?: ReactElement;
  backAction?(): void;
}

const PageHeaderActions: React.FC<PageHeaderActionsProps> = ({
  title,
  description,
  gutterBottom = true,
  ActionComponent,
  backAction,
}) => {
  const classes = useStyles({ gutterBottom });

  return (
    <div className={classes.container}>
      {backAction && (
        <IconButton onClick={backAction}>
          <ArrowBack />
        </IconButton>
      )}
      <div className={classes.content}>
        <div>
          <Typography variant="h6">{title}</Typography>
          {description && (
            <Typography color="textSecondary" variant="body2">
              {description}
            </Typography>
          )}
        </div>
        {ActionComponent && <div className={classes.actions}>{ActionComponent}</div>}
      </div>
    </div>
  );
};

export default PageHeaderActions;
