import { CashRegisterWithMovements } from 'types/cashRegister';
import { useMemo } from 'react';
import { moneyFormat } from 'helpers/NumberFormat';

interface UseCashRegisterCurrentValue {
  currentValue: number;
  formattedCurrentValue: string;
  debit: number;
  credit: number;
}

export function useCashRegisterCurrentValue(
  cashRegister: CashRegisterWithMovements | null
): UseCashRegisterCurrentValue {
  const debit = useMemo(() => {
    if (!cashRegister) {
      return 0;
    }

    return cashRegister.movements.reduce((carry, item) => (item.type === 'debit' ? carry + item.value : carry), 0);
  }, [cashRegister]);

  const credit = useMemo(() => {
    if (!cashRegister) {
      return 0;
    }

    return cashRegister.movements.reduce((carry, item) => (item.type === 'credit' ? carry + item.value : carry), 0);
  }, [cashRegister]);

  const currentValue = useMemo(() => {
    if (!cashRegister) {
      return 0;
    }

    return cashRegister.initial_value + credit - debit;
  }, [cashRegister, debit, credit]);

  const formattedCurrentValue = useMemo(() => moneyFormat(currentValue), [currentValue]);

  return {
    currentValue,
    formattedCurrentValue,
    credit,
    debit,
  };
}
