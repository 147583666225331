import { BoardChart } from 'types/board';
import { BoardMovement } from 'types/boardMovement';
import { BoardManagementActions } from './types';

export function setBoards(boards: BoardChart[]): BoardManagementActions {
  return {
    type: 'SET_BOARDS',
    boards,
  };
}

export function setBusyBoard(boardId: string, boardMovement: BoardMovement): BoardManagementActions {
  return {
    type: 'SET_BUSY_BOARD',
    boardId,
    boardMovement,
  };
}

export function setFreeBoard(boardId: string): BoardManagementActions {
  return {
    type: 'SET_FREE_BOARD',
    boardId,
  };
}
