export const INITIAL_STATE = {
  discount: '',
  discount_type: 'percent',
  valid_at: null,
  usage_amount: null,
  amount_customer: null,
  description: '',
  name: '',
  activated: true,
};

export default function coupon(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CHANGE': {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case 'SET_COUPON': {
      return {
        ...action.coupon,
      };
    }

    default: {
      return state;
    }
  }
}
