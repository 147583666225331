import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';
import CategoryActionImageSelected from '../CategoryActionImageSelected';

CategoryAction.propTypes = {
  saving: PropTypes.bool.isRequired,
  photoSelected: PropTypes.bool,
  handlePhotoDelete: PropTypes.func.isRequired,
  handleValidation: PropTypes.func.isRequired,
};

export default function CategoryAction({ saving, photoSelected, handlePhotoDelete, handleValidation }) {
  return (
    <>
      {photoSelected && <CategoryActionImageSelected handlePhotoDelete={handlePhotoDelete} />}
      <Tooltip title="Salvar">
        <IconButton disabled={saving} color="inherit" onClick={handleValidation}>
          <DoneIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}
