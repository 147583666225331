import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import { Promotion } from 'types/promotion';
import { Pause } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
  },
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: 6,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  icon: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
}));

interface PromotionItemModuleProps {
  promotion: Promotion;
}

const PromotionItemModule: React.FC<PromotionItemModuleProps> = ({ promotion }) => {
  const classes = useStyles();

  return (
    <ListItem
      key={promotion.id}
      className={classes.listItem}
      button
      component={Link}
      to={`/promotions/${promotion.id}`}
    >
      <Typography variant="caption" color="primary">
        Promoção {promotion.id}
      </Typography>
      <Typography variant="h6">{promotion.name}</Typography>
      <Typography color="textSecondary" variant="body2">
        {promotion.description}
      </Typography>
      {promotion.valid_at ? (
        <Typography color="textSecondary" variant="body2">
          Valido até {promotion.formattedValidAt}
        </Typography>
      ) : (
        <Typography color="textSecondary" variant="body2">
          Validade indeterminada
        </Typography>
      )}
      {!promotion.activated && <Pause className={classes.icon} color="primary" />}
    </ListItem>
  );
};

export default PromotionItemModule;
