import React, { useState, useEffect, useReducer, FormEvent } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import AdditionalFormAction from './NewAdditionalActions';
import Loading from 'components/loading/Loading';
import history from 'services/history';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import InsideLoading from 'components/loading/InsideLoading';
import PageHeader from 'components/page-header/PageHeader';
import { useAdditionalValidation } from '../validation/additionalValidation';
import AdditionalForm from '../AdditionalForm';
import { Additional } from 'types/product';
import ErrorMessage from 'components/errors/Error';

const additionalReducer = (state, action) => {
  let additional = Object.assign({}, state);

  switch (action.type) {
    case 'CHANGE':
      additional = {
        ...additional,
        [action.index]: action.value,
      };

      if (action.index === 'category_id') {
        const categoriesCopy = action.categories;
        const selected = categoriesCopy.find(item => item.id === action.value);
        additional.category = selected;
      }
      return additional;

    case 'SET_ADDITIONAL':
      additional = action.additional;
      additional.validation = {
        name: [],
      };
      return additional;

    case 'VALIDATION':
      additional.validation = action.validation;
      return additional;

    case 'RESET':
      additional = {
        ...additional,
        name: '',
        price: '',
        validation: {
          name: [],
        },
      };

      return additional;

    default:
      throw new Error();
  }
};

const NewAdditional: React.FC = () => {
  const { handleOpen } = useMessaging();
  const [additional, dispatch] = useReducer(additionalReducer, {
    name: '',
    category_id: 0,
    validation: {
      name: [],
    },
    price: '',
    category: {
      category_sizes: [],
    },
  });
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [validation, setValidation, validate] = useAdditionalValidation();
  const [error, setError] = useState('');

  useEffect(() => {
    setLoading(true);

    api
      .get('/categories', { params: { has_additional: 1 } })
      .then(response => {
        setCategories(response.data);
        dispatch({
          type: 'CHANGE',
          index: 'category_id',
          value: response.data[0].id,
          categories: response.data,
        });
      })
      .catch(err => {
        setError(err.response ? err.response.data.error : 'Você deve cadastrar as categorias primeiro');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleOpen]);

  function handleChange(index: keyof Additional, value: any) {
    dispatch({
      type: 'CHANGE',
      value,
      index,
      categories,
    });
  }

  function handleValidate(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    setValidation({});

    validate(additional)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('additional', additional)
      .then(() => {
        handleOpen('Salvo');
        history.push('/menu/additional');
        setSaving(false);
      })
      .catch(err => {
        handleOpen(err.response ? err.response.data.error : 'Não foi possível salvar');
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <CustomAppbar
        title="Novo adicional"
        ActionComponents={<AdditionalFormAction loading={loading} saving={saving} handleSubmit={handleValidate} />}
      />
      <PageHeader title="Novo adicional" backAction={() => history.push('/menu/additional')} />
      {loading ? (
        <InsideLoading />
      ) : error ? (
        <ErrorMessage text={error} />
      ) : (
        <form onSubmit={handleValidate}>
          <AdditionalForm
            categories={categories}
            additional={additional}
            validation={validation}
            handleChange={handleChange}
            type="new"
          />
        </form>
      )}
    </>
  );
};

export default NewAdditional;
