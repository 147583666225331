import React from 'react';
import { ListItem, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Order } from 'types/order';

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
    minHeight: 100,
  },
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 6,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  orderData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 6,
  },
  iconButton: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  starIcon: {
    marginLeft: 6,
    color: '#ffc107',
  },
}));

interface CustomerOrderItemProps {
  order: Order;
}

const CustomerOrderItem: React.FC<CustomerOrderItemProps> = ({ order }) => {
  const classes = useStyles();

  return (
    <>
      <ListItem className={classes.listItem} key={order.id} button component={Link} to={`/orders/${order.uuid}`}>
        <Typography variant="body2" color="primary">
          Pedido {order.formattedId}
        </Typography>
        <div className={classes.orderData}>
          <div>
            <Typography>{order.customer_name}</Typography>
            <Typography variant="body2" color="textSecondary" display="inline">
              {order.formattedCreatedAt}
            </Typography>
          </div>
          <Typography variant="h5">{order.formattedTotal}</Typography>
        </div>
      </ListItem>
    </>
  );
};

export default CustomerOrderItem;
