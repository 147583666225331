import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { useEffect, useState } from 'react';
import { api } from 'services/api';
import { OrderStatus } from 'types/orderStatus';

type UseFetchOrderHistories = [OrderStatus[], boolean];

export function useFetchOrderHistories(orderId?: string): UseFetchOrderHistories {
  const [histories, setHistories] = useState<OrderStatus[]>([]);
  const [loading, setLoading] = useState(true);
  const { showErrorDialog } = useErrorHandler();

  useEffect(() => {
    if (!orderId) {
      return;
    }

    api
      .get(`/orders/${orderId}/histories`)
      .then(response => {
        setHistories(
          response.data.map(history => ({
            ...history,
            formattedCreatedAt: format(parseISO(history.created_at), 'Pp', { locale: ptBR }),
          }))
        );
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível recuperar o histórico',
        });
      })
      .finally(() => setLoading(false));
  }, [showErrorDialog, orderId]);

  return [histories, loading];
}
