import { Dispatch, createContext, useContext } from 'react';
import { ProductActionsType } from 'store/modules/product/types';
import { Category } from 'types/category';
import { Additional, Ingredient, Product } from 'types/product';
import { ProductValidation } from '../validation/ProductValidation';
import { ProductStepType } from '../../registration/steps';

export type ProductContextValue = {
  product: Product;
  dispatch: Dispatch<ProductActionsType>;
  handleSetCategory(category: Category): void;
  handleChange(value, index: keyof Product): void;
  validation: ProductValidation;
  handleNext(): void;
  handlePrior(): void;
  handleSetStep(order: number): void;
  setImagePreview(state: boolean): void;
  handleGetPizzaTasteComplements(): void;
  handleFormValidation(): void;
  handlePriceValidation(): void;
  step: number;
  currentStep?: ProductStepType;
  steps: ProductStepType[];
  categories: Category[];
  loading: boolean;
  ingredients: Ingredient[];
  additional: Additional[];
};

const ProductContext = createContext({} as ProductContextValue);
export const ProductContextProvider = ProductContext.Provider;
export const ProductContextConsumer = ProductContext.Consumer;

export function useProduct(): ProductContextValue {
  const context = useContext(ProductContext);
  return context;
}
