import React, { useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import ReceiptItemTable from './TaxRuleItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import ReceiptListMenu from '../ReceiptListMenu';
import { TaxRule } from 'pages/tax-rules/types/taxRule';
import { useTaxRules } from 'pages/tax-rules/hooks/useTaxRules';
import { taxRulesTableTemplate } from 'pages/tax-rules/constants/taxRulesTableTemplate';
import history from 'services/history';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type TaxRuleListTableProps = {
  taxRules: TaxRule[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const TaxRuleListTable: React.FC<TaxRuleListTableProps> = ({ taxRules, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { setSelectedTaxRule } = useTaxRules();

  function handleClick(taxRule: TaxRule) {
    setSelectedTaxRule(taxRule);
    history.push(`/tax-rules/${taxRule.id}`);
  }

  return (
    <>
      <ReceiptListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <TableContainer tableTemplate={taxRulesTableTemplate}>
        <TableContent>
          <TableHeader>
            {taxRulesTableTemplate
              .filter(item => !item.notShow)
              .map(item => (
                <div
                  className={classes.headerItem}
                  key={item.id}
                  onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
                >
                  <Typography noWrap variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId && (
                    <>
                      {orderedIndex.direction === 'asc' ? (
                        <ArrowUpward color="primary" />
                      ) : (
                        <ArrowDownward color="primary" />
                      )}
                    </>
                  )}
                </div>
              ))}
          </TableHeader>

          <TableBody>
            {taxRules.map(item => (
              <TableRow key={item.id} onClick={() => handleClick(item)}>
                <ReceiptItemTable setAnchorEl={el => setAnchorEl(el)} taxRule={item} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </>
  );
};

export default TaxRuleListTable;
