import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { Typography, makeStyles, ListItem, IconButton } from '@material-ui/core';
import { ReceiptOnList } from 'pages/receipts/types/receipt';
import { useReceipts } from 'pages/receipts/hooks/useReceipts';
import { RECEIPT_STATUS_MAPPING } from 'pages/receipts/constants/receiptStatusMapping';
import { MoreVert } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
    minHeight: 120,
    position: 'relative',
    alignItems: 'flex-start',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
    width: 60,
    height: 60,
    borderRadius: '50%',
    backgroundColor: '#eee',
    overflow: 'hidden',
    border: `2px solid #fff`,
    flexShrink: 0,
  },
  image: {
    width: '100%',
  },
  buttonMore: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  status: {
    display: 'inline-flex',
    width: 130,
    padding: 5,
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: 12,
    justifyContent: 'center',
    alignItems: 'center',
    '&.created': {
      backgroundColor: theme.palette.warning.main,
      color: '#FFF',
    },
    '&.authorized': {
      backgroundColor: theme.palette.success.main,
      color: '#fff',
    },
    '&.cancelled': {
      backgroundColor: theme.palette.grey[700],
      color: '#fff',
    },
    '&.cancellation_failed': {
      backgroundColor: theme.palette.error.main,
      color: '#fff',
    },
    '&.authorization_failed': {
      backgroundColor: theme.palette.error.main,
      color: '#fff',
    },
  },
}));

interface ReceiptItemModuleProps {
  receipt: ReceiptOnList;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
}

const ReceiptItemModule: React.FC<ReceiptItemModuleProps> = ({ receipt, setAnchorEl }) => {
  const classes = useStyles();
  const { setSelectedReceipt } = useReceipts();

  function handleClick() {
    setSelectedReceipt(receipt);
  }

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  return (
    <ListItem onClick={handleClick} key={receipt.id} className={classes.listItem} button>
      <span className={`${classes.status} ${receipt.current_status}`}>
        {RECEIPT_STATUS_MAPPING[receipt.current_status]}
      </span>

      <Typography variant="caption" gutterBottom color="primary">
        {`CF-e ${receipt.sequence} - ${receipt.formattedCreatedAt}`}
      </Typography>

      <Typography gutterBottom={!receipt.customer_document}>{receipt.customer_name}</Typography>

      {receipt.customer_document && (
        <Typography gutterBottom variant="caption" color="textSecondary">
          {receipt.customer_document}
        </Typography>
      )}

      <Typography variant="body1">{receipt.formattedTotalValue}</Typography>

      <IconButton onClick={handleMoreClick} className={classes.buttonMore}>
        <MoreVert />
      </IconButton>
    </ListItem>
  );
};

export default ReceiptItemModule;
