import { TableTemplate } from 'types/tableTemplate';

export const categoriesTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 60,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'name',
    description: 'NOME',
    originalId: 'name',
    width: 300,
  },
  {
    id: 'description',
    description: 'DESCRIÇÃO',
    originalId: 'description',
    width: 300,
  },
  {
    id: 'relevance',
    description: 'RELEVÂNCIA',
    originalId: 'relevance',
    width: 100,
  },
  {
    id: 'formattedIsPizza',
    description: 'PIZZA',
    originalId: 'formattedIsPizza',
    width: 60,
  },
  {
    id: 'formattedHasIngredients',
    description: 'INGREDIENTES',
    originalId: 'formattedHasIngredients',
    width: 100,
  },
  {
    id: 'formattedHasAdditional',
    description: 'ADICIONAL',
    originalId: 'formattedHasAdditional',
    width: 80,
  },
  {
    id: 'formattedHasComplement',
    description: 'COMPLEMENTO',
    originalId: 'formattedHasComplement',
    width: 110,
  },
  {
    id: 'formattedIsActive',
    description: 'ATIVO',
    originalId: 'formattedIsActive',
    width: 60,
  },
  {
    id: 'formattedIsAvailableOnDelivery',
    description: 'DELIVERY',
    originalId: 'is_available_on_delivery',
    width: 70,
  },
  {
    id: 'formattedIsAvailableOnBoard',
    description: 'MESA',
    originalId: 'is_available_on_board',
    width: 70,
  },
  {
    id: 'url',
    description: 'URL',
    originalId: 'url',
    width: 200,
  },
  {
    id: 'formattedCreatedAt',
    description: 'CRIADO EM',
    originalId: 'created_at',
    width: 150,
    notSearchable: true,
  },
];
