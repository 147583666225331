import { Menu, MenuItem } from '@material-ui/core';
import React, { Dispatch } from 'react';
import { useBoardControl } from '../hooks/useBoardControl';
import { api } from 'services/api';
import { addBoardProducts, removeBoardProductByReferencedId } from '../reducer/actions';

interface BoardProductListMenuProps {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<HTMLButtonElement | null>;
}

const BoardProductListMenu: React.FC<BoardProductListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedProduct, movement, setShowDialogDelete, setShowDialogUpdate, setShowDialogSplit, dispatch } =
    useBoardControl();

  function handleDeleteClick() {
    setShowDialogDelete(true);
    setAnchorEl(null);
  }

  function handleDetailClick() {
    setShowDialogUpdate(true);
    setAnchorEl(null);
  }

  function openDialogProductSplit() {
    setShowDialogSplit(true);
    setAnchorEl(null);
  }

  function handlePrint() {
    setAnchorEl(null);

    api.post('orders/print/now', {
      order_id: selectedProduct?.order_id,
    });
  }

  function handleJoin() {
    setAnchorEl(null);

    if (!selectedProduct || !movement) {
      return;
    }

    api
      .post(`/board-sessions/${movement.id}/products/${selectedProduct.id}/join`)
      .then(response => {
        dispatch(removeBoardProductByReferencedId(selectedProduct.referenced_id));
        dispatch(addBoardProducts([response.data]));
      })
      .catch(console.log);
  }

  return (
    <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleDetailClick}>detalhes</MenuItem>

      <MenuItem onClick={handlePrint}>imprimir</MenuItem>

      {!selectedProduct?.is_splitted && <MenuItem onClick={openDialogProductSplit}>dividir</MenuItem>}

      {selectedProduct?.is_splitted && <MenuItem onClick={handleJoin}>restaurar</MenuItem>}

      {movement?.is_open && <MenuItem onClick={handleDeleteClick}>excluir</MenuItem>}
    </Menu>
  );
};

export default BoardProductListMenu;
