import React, { FC, useReducer, useState, useContext, useEffect } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import PushNotificationActions from './PushNotificationActions';
import pushNotificationReducer, {
  INITIAL_STATE as pushNotificationInitialState,
} from 'store/modules/push-notification/reducer';
import { pushNotificationChange, setPushNotification } from 'store/modules/push-notification/actions';
import { Image } from 'store';
import Loading from 'components/loading/Loading';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import history from 'services/history';
import { useParams } from 'react-router-dom';
import InsideLoading from 'components/loading/InsideLoading';
import { parseISO, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import PushNotificationForm from '../PushNotificationForm';
import { usePushNotificationValidation } from '../validation/pushNotificationValidation';

export interface PushNotificationValidation {
  title?: string;
  message?: string;
  url_action?: string;
  image?: string;
  delivery_date?: string;
}

interface PushNotificatoinContext {
  dispatch(): void;
}

type PushNotificationParams = { id: string };

export const pushNotificationContext = React.createContext<PushNotificatoinContext>({} as PushNotificatoinContext);

const PushNotifications: FC = () => {
  const [pushNotification, dispatch] = useReducer(pushNotificationReducer, pushNotificationInitialState);
  const [validation, setValidation, validate] = usePushNotificationValidation();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const messaging = useMessaging();
  const { id } = useParams<PushNotificationParams>();

  useEffect(() => {
    api
      .get(`/pushNotifications/${id}`)
      .then(response => {
        const push = {
          ...response.data,
          formattedCreatedAt:
            response.data.created_at && format(parseISO(response.data.created_at), "PPPP 'às' H:mm", { locale: ptBR }),
        };
        dispatch(setPushNotification(push));
      })
      .catch(() => {
        history.push('/push-notifications');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  function handleValidation() {
    setValidation({});

    validate(pushNotification)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    if (pushNotification.delivered) {
      messaging.handleOpen('Essa notificação já foi enviada. Não é possível alterar');
      return;
    }

    setSaving(true);

    api
      .put(`/pushNotifications/${id}`, pushNotification)
      .then(() => {
        messaging.handleOpen('Salvo');
        history.push('/push-notifications');
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível salvar');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleChange(index: string, value: string | Image | boolean | null | Date) {
    dispatch(pushNotificationChange(index, value));
  }

  function handleDelete() {
    setSaving(true);

    api
      .delete(`/pushNotifications/${id}`)
      .then(() => {
        messaging.handleOpen('Excluído');
        setSaving(false);
        history.push('/push-notifications');
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível excluir');
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <CustomAppbar
        title={pushNotification.isImageSelected ? 'Imagem' : 'Editar notificação'}
        backAction={() => handleChange('isImageSelected', !pushNotification.isImageSelected)}
        ActionComponents={
          <PushNotificationActions
            handleValidation={handleValidation}
            saving={false}
            loading={loading}
            notificationImageSelected={pushNotification.isImageSelected}
            handleChange={handleChange}
            delivered={pushNotification.delivered}
            handleDelete={handleDelete}
          />
        }
      />
      <PageHeader
        title="Editar notificação"
        description="A notificação push é uma mensagem de texto com título em destaque e a logo do app ao lado"
        gutterBottom={false}
        backAction={() => history.push('/push-notifications')}
      />

      {loading ? (
        <InsideLoading />
      ) : (
        <form onSubmit={handleValidation}>
          <PushNotificationForm
            pushNotification={pushNotification}
            handleChange={handleChange}
            validation={validation}
          />
        </form>
      )}
    </>
  );
};

export function usePushNotification(): PushNotificatoinContext {
  const context = useContext(pushNotificationContext);
  return context;
}

export default PushNotifications;
