import KeyboardDatePicker from 'components/pickers/KeyboardDatePicker';
import { FC, useState } from 'react';
import { endOfMonth, startOfMonth } from 'date-fns';
import DialogInput, { DialogInputConsumer } from 'components/dialog/DialogInput';
import { Button, Typography, makeStyles } from '@material-ui/core';
import ButtonDownload from 'components/button-download/ButtonDownload';
import { api } from 'services/api';
import { download } from 'helpers/download';
import { uuidv4 } from 'helpers/uuid';
import { useErrorHandler } from 'providers/error-handler/error-handler';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    justifyContent: 'center',
    flex: 1,
    padding: '30px',
    minHeight: 350,
    [theme.breakpoints.down('sm')]: {
      padding: 15,
      minHeight: 400,
    },
  },
  form: {
    display: 'flex',
    flex: 1,
    gap: 15,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridAutoRows: 'min-content',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
    gap: 15,
    marginBottom: 30,
  },
}));

interface DownloadReceiptsModalProps {
  onExited(): void;
}

const DownloadReceiptsModal: FC<DownloadReceiptsModalProps> = ({ onExited }) => {
  const classes = styles();

  const [initialDate, setInitialDate] = useState<Date | null>(startOfMonth(new Date()));
  const [finalDate, setFinalDate] = useState<Date | null>(endOfMonth(new Date()));
  const { showErrorDialog } = useErrorHandler();

  async function handleDownloadClick(closeDialog: () => void) {
    try {
      const response = await api.get(`/download-authorized-xml`, {
        responseType: 'blob',
        params: {
          initial_date: initialDate,
          final_date: finalDate,
        },
      });

      download(response.data, `${uuidv4()}.zip`);

      closeDialog();
    } catch (error) {
      showErrorDialog({
        error,
        message: 'Não foi possível baixar os XML',
      });
    }
  }

  return (
    <DialogInput onExited={onExited} maxWidth="sm">
      <div className={classes.container}>
        <div>
          <Typography align="center" variant="body1">
            Download XML dos <strong>cupons autorizados</strong>
          </Typography>
        </div>
        <div className={classes.form}>
          <KeyboardDatePicker
            label="Data inicial"
            value={initialDate}
            onChange={date => setInitialDate(date)}
            autoOk
            margin="normal"
            fullWidth
          />
          <KeyboardDatePicker
            label="Data Final"
            value={finalDate}
            onChange={date => setFinalDate(date)}
            autoOk
            margin="normal"
            fullWidth
          />
        </div>

        <DialogInputConsumer>
          {context => (
            <div className={classes.actions}>
              <Button onClick={context.handleCloseDialog} variant="text" color="primary">
                fechar
              </Button>
              <ButtonDownload
                variant="contained"
                handleDownload={() => handleDownloadClick(context.handleCloseDialog)}
              />
            </div>
          )}
        </DialogInputConsumer>
      </div>
    </DialogInput>
  );
};

export default DownloadReceiptsModal;
