import { useApp } from 'App';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useSelector } from 'store/redux/selector';
import { Order } from 'types/order';
import { getStatusText } from '../boardOrderStatusMapping';
import { OrderList } from '../types/orderList';
import { useBoardOrderFormatter } from './useBoardOrderFormatter';

const bell = new Audio('/sounds/oldPhone.mp3');

export function useBoardOrdersSocket(orders: OrderList[], setOrders: Dispatch<SetStateAction<OrderList[]>>) {
  const app = useApp();
  const restaurant = useSelector(state => state.restaurant);
  const [formatter] = useBoardOrderFormatter();

  useEffect(() => {
    if (!app.socket || !restaurant) return;
    app.socket.on('board_order_created', (order: Order) => {
      const [orderReceived] = formatter([order]);

      orderReceived.isNew = true;

      const exists = orders.some(o => o.id === order.id);

      if (!exists) {
        setOrders(oldOrders => [orderReceived, ...oldOrders]);
      }

      bell.play().finally(() => bell.play());
    });

    app.socket.on('orderStatusChange', payload => {
      setOrders(oldOrders =>
        oldOrders.map(order => {
          if (order.id === payload.orderId && order.status !== payload.status) {
            const orderStatus = payload.orderStatus.reverse().map(orderStatus => {
              const statusDate = parseISO(orderStatus.created_at);
              orderStatus.formattedDate = format(statusDate, "PP 'às' p", { locale: ptBR });
              return orderStatus;
            });
            order.order_status = orderStatus;
            order.status = payload.status;
            order.statusText = getStatusText(payload.status);
            return order;
          }
          return order;
        })
      );
    });

    return () => {
      if (app.socket) {
        app.socket.off('board_order_created');
        app.socket.off('orderStatusChange');
      }
    };
  }, [restaurant, app.socket, formatter, orders, setOrders]);
}
