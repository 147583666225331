import React from 'react';
import { alpha, ListItem, makeStyles, Typography } from '@material-ui/core';
import { BoardChart } from 'types/board';
import { useBoardManagement } from './hooks/useBoardManagement';
import history from 'services/history';

const styles = makeStyles(theme => ({
  listItem: {
    width: '100%',
    height: 150,
    alignItems: 'flex-start',
    flexDirection: 'column',
    backgroundColor: alpha(theme.palette.success.main, 0.2),
    cursor: 'pointer',
    transition: 'background 0.3s ease',
    position: 'relative',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: alpha(theme.palette.success.main, 0.3),
    },
    '&.busy': {
      backgroundColor: alpha(theme.palette.warning.main, 0.2),
      '&:hover': {
        backgroundColor: alpha(theme.palette.warning.main, 0.5),
      },
    },
    [theme.breakpoints.down('sm')]: {
      height: 120,
    },
  },
  tableNumber: {
    fontWeight: 400,
  },
  header: {
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  deliveryLocation: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
}));

interface BoardManagementItemProps {
  board: BoardChart;
}

const BoardManagementItem: React.FC<BoardManagementItemProps> = ({ board }) => {
  const classes = styles();
  const { setSelectedBoard } = useBoardManagement();

  function handleClick() {
    setSelectedBoard(board);

    if (board.current_movement) {
      history.push(`board-management/movements/${board.current_movement.id}`);
    }
  }

  return (
    <ListItem className={board.current_movement ? `${classes.listItem} busy` : classes.listItem} onClick={handleClick}>
      <div className={classes.header}>
        <Typography className={classes.tableNumber} variant="h6" align="right">
          {board.number}
        </Typography>

        {board.current_movement?.delivery_location && (
          <Typography variant="body2">
            <strong>{board.current_movement.delivery_location}</strong>
          </Typography>
        )}
      </div>

      {board.current_movement && (
        <div>
          <Typography variant="body2">{board.current_movement?.customer?.name}</Typography>
        </div>
      )}
    </ListItem>
  );
};

export default BoardManagementItem;
