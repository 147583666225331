import { Typography } from '@material-ui/core';
import { Customer } from 'types/customer';

interface OrderResumeCustomerProps {
  customer: Customer | null;
}

function OrderResumeCustomer({ customer }: OrderResumeCustomerProps) {
  return (
    <div>
      {customer && (
        <>
          <Typography color="primary" variant="h6">
            Cliente
          </Typography>
          <div>
            <Typography>{customer.name}</Typography>
            <Typography color="textSecondary">{customer.phone}</Typography>
            <Typography color="textSecondary">{customer.email}</Typography>
          </div>
        </>
      )}
    </div>
  );
}

export default OrderResumeCustomer;
