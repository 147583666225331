import React, { MouseEvent } from 'react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@material-ui/icons';
import { Board } from 'types/board';
import { useBoards } from 'pages/boards/hooks/useBoards';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
});

type BoardItemTableProps = {
  board: Board;
};

const BoardItemTable: React.FC<BoardItemTableProps> = ({ board }) => {
  const classes = useStyles();
  const { tableTemplate } = useTable();
  const { setSelectedBoard } = useBoards();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedBoard(board);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{board[item.id as keyof Board]}</Typography>
            </div>
          )
        )}
    </>
  );
};

export default BoardItemTable;
