import React, { FC, FormEvent } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

interface UserActionsProps {
  handleValidation(event: FormEvent): void;
  saving: boolean;
}

const UserActions: FC<UserActionsProps> = ({ handleValidation, saving }) => {
  return (
    <Tooltip title="Salvar">
      <span>
        <IconButton disabled={saving} onClick={handleValidation} color="inherit">
          <DoneIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default UserActions;
