import React, { useState } from 'react';
import List from 'components/list/List';
import BoardMovementItemModule from './BoardMovementItemModule';
import BoardMovementsListMenu from '../BoardMovementsListMenu';
import { BoardMovement } from 'types/boardMovement';

type BoardMovementListModuleProps = {
  movements: BoardMovement[];
};

const BoardMovementListModule: React.FC<BoardMovementListModuleProps> = ({ movements }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <BoardMovementsListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <List>
        {movements.map(item => (
          <BoardMovementItemModule key={item.id} movement={item} />
        ))}
      </List>
    </>
  );
};

export default BoardMovementListModule;
