import { useReducer, Dispatch } from 'react';
import { Category, CategoryAvailabilityHours } from 'types/category';
import { CategoryActions } from './types';

export const INITIAL_STATE: Category = {
  name: '',
  url: '',
  is_pizza: false,
  has_additional: false,
  has_ingredient: false,
  has_complement: false,
  image: null,
  imageSelected: false,
  description: '',
  keywords: '',
  activated: true,
  relevance: 1,
  available_products_amount: 0,
  id: 0,
  created_at: '',
  image_id: null,
  products_amount: 0,
  restaurant_id: 0,
  availability: [
    { day: 0, status: true },
    { day: 1, status: true },
    { day: 2, status: true },
    { day: 3, status: true },
    { day: 4, status: true },
    { day: 5, status: true },
    { day: 6, status: true },
  ],
  availability_hours: {
    start_at: null,
    end_at: null,
  },
  is_available_on_board: true,
  is_available_on_delivery: true,
  formattedIsAvailableOnBoard: 'Sim',
  formattedIsAvailableOnDelivery: 'Sim',
};

function categoryReducer(state = INITIAL_STATE, action: CategoryActions): Category {
  switch (action.type) {
    case 'SET_CATEGORY': {
      return {
        ...action.category,
        availability_hours: action.category.availability_hours
          ? action.category.availability_hours
          : state.availability_hours,
        availability: action.category.availability.length === 0 ? state.availability : action.category.availability,
      };
    }

    case 'CATEGORY_CHANGE': {
      if (action.index === 'name')
        return {
          ...state,
          [action.index]: action.value,
          url: action.value
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/\s/g, '-'),
        };
      if (action.index === 'has_complement')
        return {
          ...state,
          has_additional: action.value ? !action.value : state.has_additional,
          has_ingredient: action.value ? !action.value : state.has_additional,
          [action.index]: action.value,
        };
      if (action.index === 'has_ingredient' || action.index === 'has_additional')
        return {
          ...state,
          has_complement: action.value ? !action.value : state.has_complement,
          [action.index]: action.value,
        };
      return { ...state, [action.index]: action.value };
    }

    case 'SET_SELECTED_IMAGE': {
      return {
        ...state,
        imageSelected: !state.imageSelected,
      };
    }

    case 'DELETE_IMAGE': {
      return {
        ...state,
        imageSelected: false,
        image: null,
      };
    }

    case 'AVAILABILITY_CHANGE': {
      const availability = state.availability.map(item =>
        item.day === action.day ? { ...item, status: action.status } : item
      );

      return {
        ...state,
        availability,
      };
    }

    case 'AVAILABILITY_HOURS_CHANGE': {
      return {
        ...state,
        availability_hours: {
          ...state.availability_hours,
          [action.index as keyof CategoryAvailabilityHours]: action.value,
        },
      };
    }

    default: {
      return state;
    }
  }
}

type UseCategoryReducer = [Category, Dispatch<CategoryActions>];

export function useCategoryReducer(): UseCategoryReducer {
  const reducer = useReducer(categoryReducer, INITIAL_STATE);
  return reducer;
}
