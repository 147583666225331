import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import ptbr from 'date-fns/locale/pt-BR';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker as NativeKeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface CustomerKeyboardDatePickerProps extends KeyboardDatePickerProps {
  hideBackdrop?: boolean;
}

const KeyboardDatePicker: React.FC<CustomerKeyboardDatePickerProps> = ({ hideBackdrop = false, ...rest }) => {
  const now = format(new Date(), 'dd/MM/yyy', { locale: ptBR });

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptbr}>
      <NativeKeyboardDatePicker
        cancelLabel="Cancelar"
        placeholder={now}
        format="dd/MM/yyyy"
        invalidDateMessage="Data inválida"
        DialogProps={{
          hideBackdrop,
        }}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

export default KeyboardDatePicker;
