import { Dispatch, SetStateAction, useState } from 'react';
import { Address } from 'types/address';
import { Restaurant } from 'types/restaurant';
import * as yup from 'yup';

export interface AddressValidation {
  address?: string;
  number?: string;
  district?: string;
  city?: string;
  region?: string;
  areaRegionId?: string;
}

type UseAddressValidation = [
  AddressValidation,
  Dispatch<SetStateAction<AddressValidation>>,
  (address: Address, areaRegionId: number | null) => Promise<void>
];

export function useAddressValidation(restaurant?: Restaurant | null): UseAddressValidation {
  async function handleValidation(address: Address, areaRegionId: number | null) {
    const schema = yup.object().shape({
      region: yup.string().typeError('O estado é obrigatório').required('O estado é obrigatório'),
      city: yup.string().typeError('A cidade é obrigatório').required('A cidade é obrigatória'),
      number: yup.string().typeError('O número é obrigatório').required('O número é obrigatório'),
      address: yup.string().typeError('O logradouro é obrigatório').required('O logradouro é obrigatório'),
      district: yup.string().test('district_validation', 'Bairro é obrigatório', value => {
        if (restaurant?.configs.tax_mode !== 'district') {
          return !!value;
        }

        return true;
      }),
      areaRegionId: yup
        .mixed()
        .nullable()
        .test('area_region_validation', 'Bairro é obrigatório', value => {
          if (restaurant?.configs.tax_mode === 'district') {
            return !!value;
          }

          return true;
        }),
    });

    try {
      await schema.validate({ ...address, areaRegionId });
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as string]: err.message,
        });
      }
      throw err;
    }
  }

  const [validation, setValidation] = useState<AddressValidation>({});
  return [validation, setValidation, handleValidation];
}
