import React, { useReducer, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import PrinterForm from './PrinterForm';
import printerReducer, { INITIAL_STATE as printerInitialState } from 'store/modules/printer/reducer';
import { printerChange, setPrinter } from 'store/modules/printer/actions';
import CustomAppbar from 'components/appbar/Appbar';
import * as yup from 'yup';
import { api } from 'services/api';
import Loading from 'components/loading/Loading';
import history from 'services/history';
import PrinterAction from './PrinterAction';
import { useParams } from 'react-router-dom';
import { useMessaging } from 'hooks/messaging';
import PageHeader from 'components/page-header/PageHeader';
import InsideLoading from 'components/loading/InsideLoading';

const styles = makeStyles({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
});

export default function Printer() {
  const classes = styles();
  const [printer, dispatch] = useReducer(printerReducer, printerInitialState);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleOpen } = useMessaging();
  const [validation, setValidation] = useState({});
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    api
      .get(`/printers/${id}`)
      .then(response => {
        dispatch(setPrinter(response.data));
      })
      .catch(err => {
        if (err.response) {
          handleOpen(err.response.data.err);
          if (err.response.status === 404) history.push('/printers');
        } else handleOpen('Erro ao carregar o cupom');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, handleOpen, id]);

  function handleChange(index, value) {
    dispatch(printerChange(index, value));
  }

  function handleFormValidation(event) {
    event.preventDefault();

    const schema = yup.object().shape({
      address: yup.string().required('A descrição é obrigatória'),
      name: yup.string().required('O nome do cupom é obrigatório'),
    });

    schema
      .validate(printer)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);
    api
      .put(`/printers/${id}`, printer)
      .then(() => {
        handleOpen('Salvo');
        history.push('/printers');
      })
      .catch(err => {
        if (err.response.data.error) handleOpen(err.response.data.error);
        else handleOpen('Não foi possível salvar o cupom');
        setSaving(false);
      });
  }

  function handleDelete() {
    setSaving(true);
    api
      .delete(`/printers/${id}`)
      .then(() => {
        handleOpen('Excluído');
        history.push('/printers');
      })
      .catch(err => {
        if (err.response) handleOpen(err.response.data.error);
        else handleOpen('Não foi possível excluir a impressora');
        setSaving(false);
      });
  }

  function handleStatus() {
    setSaving(true);
    handleChange('activated', !printer.activated);
    api
      .put(`/printers/status/${printer.id}`)
      .then(() => {
        handleOpen('Atualizado');
      })
      .catch(() => {
        handleChange('activated', !printer.activated);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}

      <CustomAppbar
        title="Impressora"
        ActionComponents={
          <PrinterAction
            handleDelete={handleDelete}
            handleFormValidation={handleFormValidation}
            saving={saving}
            loading={loading}
            handleStatus={handleStatus}
            activated={printer.activated}
          />
        }
      />

      <PageHeader title="Editar impressora" backAction={() => history.back('/printers')} />

      <div className={classes.container}>
        {loading ? (
          <InsideLoading />
        ) : (
          <form onSubmit={handleFormValidation}>
            <PrinterForm printer={printer} validation={validation} handleChange={handleChange} />
          </form>
        )}
      </div>
    </>
  );
}
