import { useState, useEffect, Dispatch } from 'react';
import { api } from 'services/api';
import { PaymentMethodActions } from '../registration/reducer/types';
import { setPaymentMethodAction } from '../registration/reducer/actions';
import { useErrorHandler } from 'providers/error-handler/error-handler';

export function useFetchPaymentMethod(dispatch: Dispatch<PaymentMethodActions>, id?: string) {
  const { showErrorDialog } = useErrorHandler();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    api
      .get(`/payment-methods/${id}`)
      .then(response => {
        dispatch(setPaymentMethodAction(response.data));
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível salvar',
        });
      })
      .finally(() => setLoading(false));
  }, [id, dispatch, showErrorDialog]);

  return [loading];
}
