import React, { MouseEvent } from 'react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@material-ui/icons';
import { PaymentMethodForList } from 'types/paymentMethod';
import { usePaymentMethods } from 'pages/payment-methods/hooks/usePaymentMethods';

const useStyles = makeStyles(theme => ({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
}));

type PaymentMethodItemTableProps = {
  paymentMethod: PaymentMethodForList;
  setAnchorEl(el: HTMLButtonElement): void;
};

const PaymentMethodItemTable: React.FC<PaymentMethodItemTableProps> = ({ paymentMethod, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelected } = usePaymentMethods();

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setSelected(paymentMethod);
    setAnchorEl(event.currentTarget);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton onClick={handleMoreClick} key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{paymentMethod[item.id]}</Typography>
            </div>
          )
        )}
    </>
  );
};

export default PaymentMethodItemTable;
