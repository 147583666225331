import React from 'react';
import OrderStatusList from './OrderStatusList';
import DialogInput from 'components/dialog/DialogInput';
import { makeStyles } from '@material-ui/core/styles';
import { OrderList } from '../types/orderList';
import { useFetchOrderHistories } from '../hook/useFetchOrderHistories';
import { useFetchOrderDeliverers } from '../hook/useFetchOrderDeliverers';
import InsideLoading from 'components/loading/InsideLoading';
import OrderHistoriesDialogButtons from './OrderHistoriesDialogButtons';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
    minHeight: 500,
    transition: 'height 0.3s ease',
  },
});

type OrderHistoriesDialogProps = {
  order: OrderList | null;
  onExited(): void;
};

const OrderHistoriesDialog: React.FC<OrderHistoriesDialogProps> = ({ order, onExited }) => {
  const classes = useStyles();
  const [histories, historiesLoading] = useFetchOrderHistories(order?.uuid);
  const [deliverers, deliverersLoading] = useFetchOrderDeliverers(order?.uuid);

  const isLoading = historiesLoading || deliverersLoading;

  return (
    <DialogInput title="Histórico do pedido" onExited={onExited} maxWidth="sm">
      <div className={classes.container}>
        {isLoading ? (
          <InsideLoading />
        ) : (
          <>
            <OrderStatusList histories={histories} deliverers={deliverers} />
            <OrderHistoriesDialogButtons />
          </>
        )}
      </div>
    </DialogInput>
  );
};

export default OrderHistoriesDialog;
