import React, { useState } from 'react';
import { Collapse, IconButton, List, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SidebarItem from '../SidebarItem';
import { SIDEBAR_WIDTH } from 'constants/constants';
import SidebarBackAction from '../SidebarBackAction';
import SidebarItemExpendable from '../SidebarItemExpendable';
import { useApp } from 'App';
import { useSelector } from 'store/redux/selector';
import { Assignment, MonetizationOn } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.secondary.dark,
    zIndex: 2,
    transform: `translateX(-${SIDEBAR_WIDTH}px)`,
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
  drawerHeader: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 5,
  },
}));

type ReportSideBarProps = {
  show: boolean;
  handleClose(): void;
};

type SidebarItemCollapsible = {
  sales: boolean;
  stock: boolean;
};

const ReportSidebar: React.FC<ReportSideBarProps> = ({ show, handleClose }) => {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const app = useApp();
  const [collapsible, setCollapsible] = useState<SidebarItemCollapsible>({
    sales: false,
    stock: false,
  });

  function handleCollapseClick(index: keyof SidebarItemCollapsible): void {
    const keys = Object.keys(collapsible);

    let otherValues: SidebarItemCollapsible = {} as SidebarItemCollapsible;
    keys.forEach(key => {
      otherValues = {
        ...otherValues,
        [key]: false,
      };
    });

    setCollapsible({
      ...otherValues,
      [index]: !collapsible[index],
    });
  }

  return (
    <div className={classes.container} style={{ transform: show ? 'translateX(0)' : undefined }}>
      <div className={classes.drawerHeader}>
        <IconButton color="inherit" onClick={handleClose}>
          <ArrowBackIcon />
        </IconButton>
        <Typography color="inherit" variant="h6">
          relatórios
        </Typography>
      </div>
      {user && (
        <>
          {!app.isMobile && app.windowWidth > 960 && (
            <SidebarBackAction handleClick={handleClose} icon={<ArrowBackIcon />} text="Voltar" />
          )}
          <SidebarItemExpendable
            onClick={() => handleCollapseClick('sales')}
            text="vendas"
            opened={collapsible.sales}
            icon={<MonetizationOn />}
          />
          <Collapse in={collapsible.sales}>
            <List disablePadding>
              <SidebarItem to="/reports/top-customers" icon={<Assignment />} text="total por cliente" nested />
              <SidebarItem to="/reports/top-products" icon={<Assignment />} text="total por produtos" nested />
            </List>
          </Collapse>
        </>
      )}
    </div>
  );
};

export default ReportSidebar;
