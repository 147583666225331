import React, { useState } from 'react';
import { Typography, makeStyles, alpha } from '@material-ui/core';
import OrdertemTable from './OrdertemTable';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { ordersTableTemplate } from '../ordersTableTemplate';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import TableContainer from 'components/table/TableContainer';
import { OrderList } from 'pages/orders/types/orderList';
import OrderListMenu from '../OrderListMenu';
import TableBody from 'components/table/TableBody';
import { useOrders } from 'pages/orders/hook/useOrders';
import TableContent from 'components/table/TableContent';

const useStyles = makeStyles(theme => ({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  tableRow: {
    backgroundColor: alpha(theme.palette.success.main, 0.15),
    borderColor: alpha(theme.palette.success.main, 0.1),
    '&:hover': {
      backgroundColor: alpha(theme.palette.success.main, 0.25),
    },
  },
  newOrder: {
    backgroundColor: '#ffffe0',
  },
}));

type OrderListTableProps = {
  orders: OrderList[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const OrderListTable: React.FC<OrderListTableProps> = ({ orders, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const { setSelectedOrder, setDialogOrderPreview } = useOrders();

  function handleOrderClick(order: OrderList) {
    setSelectedOrder(order);
    setDialogOrderPreview(true);
  }

  return (
    <>
      <OrderListMenu anchorEl={anchorEl} setAnchorEl={el => setAnchorEl(el)} />
      <TableContainer tableTemplate={ordersTableTemplate}>
        <TableContent>
          <TableHeader>
            {ordersTableTemplate.map(item => (
              <div
                className={classes.headerItem}
                key={item.id}
                onClick={item.notFilterable ? undefined : () => handleSort(item.originalId)}
              >
                <Typography noWrap variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </div>
            ))}
          </TableHeader>
          <TableBody>
            {orders.map(order => (
              <TableRow
                key={order.id}
                onClick={() => handleOrderClick(order)}
                className={order.isNew ? classes.newOrder : undefined}
              >
                <OrdertemTable order={order} setAnchorEl={el => setAnchorEl(el)} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </>
  );
};

export default OrderListTable;
