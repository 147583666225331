import { List, makeStyles } from '@material-ui/core';
import React from 'react';
import { Board } from 'types/board';
import BoardControlTransferItem from './BoardControlTransferItem';

const styles = makeStyles({
  list: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
    display: 'grid',
    gap: '10px',
  },
});

interface BoardControlTransferListProps {
  boards: Board[];
  handleTransfer(board: Board, closeModal: () => void): void;
}

const BoardControlTransferList: React.FC<BoardControlTransferListProps> = ({ boards, handleTransfer }) => {
  const classes = styles();

  return (
    <List className={classes.list}>
      {boards.map(board => (
        <BoardControlTransferItem handleTransfer={handleTransfer} board={board} key={board.id} />
      ))}
    </List>
  );
};

export default BoardControlTransferList;
