import { Dispatch } from 'react';
import { updateBoardTotal } from './actions';
import { BoardControlActions } from './types';

export function middleware(dispatch: Dispatch<BoardControlActions>, action: BoardControlActions) {
  const actionsToUpdateTotal = [
    '@boardControl/SET_BOARD_MOVEMENT',
    '@boardControl/SET_PRODUCTS',
    '@boardControl/SET_PAYMENTS',
    '@boardControl/ADD_PRODUCTS',
    '@boardControl/ADD_PAYMENT',
    '@boardControl/SET_DISCOUNT',
    '@boardControl/REMOVE_PAYMENT',
    '@boardControl/REMOVE_PRODUCT',
  ];

  dispatch(action);

  if (actionsToUpdateTotal.includes(action.type)) {
    dispatch(updateBoardTotal());
  }
}
