import React from 'react';
import { makeStyles, ListProps as NativeListProps, List as NativeList, Theme } from '@material-ui/core';

type UseStylesProps = {
  onlyOneRow: boolean;
};

const useStyles = makeStyles<Theme, UseStylesProps>(theme => ({
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
    gridGap: 7,
    flex: 1,
    gridAutoRows: 'min-content',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

interface ListProps extends NativeListProps {
  onlyOneRow?: boolean;
}

const List: React.FC<ListProps> = ({ children, className, onlyOneRow = false, ...rest }) => {
  const classes = useStyles({ onlyOneRow });

  return (
    <NativeList className={`${classes.list} ${className}`} {...rest}>
      {children}
    </NativeList>
  );
};

export default List;
