import React from 'react';
import { Typography } from '@material-ui/core';
import StatusIcon from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from '@material-ui/core/styles';
import { useApp } from 'App';
import KitchenStatus from './KitchenStatus';
import DeliveryStatus from './DeliveryStatus';
import PrinterStatus from './PrinterStatus';

const useStyles = makeStyles({
  container: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    gap: 10,
    backgroundColor: 'rgba(0,0,0,0.3)',
    color: '#fff',
    height: 70,
    padding: 15,
    flexShrink: 0,
    alignItems: 'center',
    position: 'relative',
    '& svg': {
      color: '#dc3545',
    },
  },
});

const SidebarRestaurantStatus: React.FC = () => {
  const classes = useStyles();
  const app = useApp();

  return (
    <>
      {app.wsConnected ? (
        <div className={classes.container}>
          <DeliveryStatus />
          <KitchenStatus />
          <PrinterStatus />
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.content}>
            <StatusIcon />
            <Typography color="inherit">conectando...</Typography>
          </div>
        </div>
      )}
    </>
  );
};

export default SidebarRestaurantStatus;
