import React, { FC } from 'react';
import { IconButton, Tooltip, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import history from 'services/history';

const styles = makeStyles(theme => ({
  container: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

const UsersActions: FC = () => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <Tooltip title="Novo usuário">
        <span>
          <IconButton onClick={() => history.push('/users/add')} color="inherit">
            <AddIcon />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
};

export default UsersActions;
