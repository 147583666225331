import React, { FormEvent, useState, SetStateAction, Dispatch, useRef, useEffect } from 'react';
import { Button, makeStyles, TextField } from '@material-ui/core';
import { useInputDialog } from 'components/dialog/DialogInput';
import { api } from 'services/api';
import { useCashRegisters } from '../hooks/useCashRegisters';
import { moneyFormat } from 'helpers/NumberFormat';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 30,
    [theme.breakpoints.down('sm')]: {
      padding: 15,
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
    columnGap: 10,
  },
  input: {
    fontSize: 30,
  },
}));

interface CashRegisterInitialValueFormProps {
  setError: Dispatch<SetStateAction<string>>;
}

const CashRegisterInitialValueForm: React.FC<CashRegisterInitialValueFormProps> = ({ setError }) => {
  const classes = styles();
  const { selectedCashRegister, handleCashRegisterListUpdate } = useCashRegisters();
  const [initialValue, setInitialValue] = useState<number>(
    selectedCashRegister ? selectedCashRegister.initial_value : 0
  );
  const { handleCloseDialog } = useInputDialog();
  const [saving, setSaving] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.select();
  }, [ref]);

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setSaving(true);

    api
      .put(`/cashRegisters/${selectedCashRegister?.id}`, { initial_value: initialValue })
      .then(() => {
        handleCloseDialog();
        handleUpdateList();
      })
      .catch(err => setError(err.response ? err.response.data.error : 'Não foi possível atualizar o saldo inicial'))
      .finally(() => setSaving(false));
  }

  function handleUpdateList() {
    if (!selectedCashRegister) {
      return;
    }

    handleCashRegisterListUpdate({
      ...selectedCashRegister,
      initial_value: initialValue,
      formattedInitialValue: moneyFormat(initialValue),
    });
  }

  return (
    <form className={classes.container} onSubmit={handleSubmit}>
      <TextField
        inputRef={ref}
        classes={{
          root: classes.input,
        }}
        label="Saldo inicial (R$)"
        margin="normal"
        fullWidth
        value={initialValue}
        onChange={e => setInitialValue(parseFloat(e.target.value))}
        autoFocus
        inputMode="decimal"
        type="number"
      />
      <div className={classes.actions}>
        <Button disabled={saving} type="button" color="primary" variant="text" onClick={handleCloseDialog}>
          Cancelar
        </Button>
        <Button disabled={saving} variant="contained" color="primary" type="submit">
          Confirmar
        </Button>
      </div>
    </form>
  );
};

export default CashRegisterInitialValueForm;
