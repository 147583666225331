import React, { useState } from 'react';
import { FormControlLabel, makeStyles, Switch, TextField, Typography } from '@material-ui/core';
import CustomDialog from 'components/dialog/CustomDialog';
import BoardQuicklyCreateActions from './BoardQuicklyCreateActions';
import { api } from 'services/api';
import { Board } from 'types/board';
import InsideSaving from 'components/loading/InsideSaving';
import { useMessaging } from 'hooks/messaging';

const styles = makeStyles({
  switch: {},
  switchesContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '25px 0',
  },
  radioContainer: {},
});

interface BoardQuicklyCreateProps {
  onExited(): void;
  handleSetBoards(tables: Board[]): void;
}

const BoardQuicklyCreate: React.FC<BoardQuicklyCreateProps> = ({ onExited, handleSetBoards }) => {
  const classes = styles();
  const [boardData, setBoardData] = useState({
    begin: 1,
    end: 15,
    type: 'T',
    max_value: 0,
    customer_name_required: true,
    delivery_location_required: true,
  });
  const [saving, setSaving] = useState(false);
  const messaging = useMessaging();

  function handleSubmit(handleCloseModel: () => void) {
    setSaving(true);
    api
      .post<Board[]>('/boards/many', boardData)
      .then(response => {
        handleSetBoards(response.data);
        handleCloseModel();
      })
      .catch(err => {
        console.error(err);
        messaging.handleOpen(err.response ? err.response.data.error : 'Não foi possível criar as mesas');
      })
      .finally(() => setSaving(false));
  }

  function handleChange(index: keyof typeof boardData, value: any) {
    setBoardData(state => ({
      ...state,
      [index]: value,
    }));
  }

  return (
    <CustomDialog
      maxWidth="sm"
      height="70vh"
      handleModalState={onExited}
      title="Criar rápido mesas"
      componentActions={<BoardQuicklyCreateActions handleSubmit={handleSubmit} />}
      displayBottomActions={false}
    >
      {saving && <InsideSaving />}
      <TextField
        label="Número inicial"
        placeholder="Informe o número inicial"
        margin="normal"
        fullWidth
        inputProps={{
          inputMode: 'numeric',
        }}
        value={boardData.begin}
        onChange={e => handleChange('begin', parseInt(e.target.value))}
      />
      <TextField
        label="Número final"
        placeholder="Informe o número final"
        margin="normal"
        fullWidth
        inputProps={{
          inputMode: 'numeric',
        }}
        value={boardData.end}
        onChange={e => handleChange('end', parseInt(e.target.value))}
      />
      <TextField
        label="Limite de consumo"
        placeholder="Informe o limite de consumo"
        fullWidth
        margin="normal"
        value={boardData.max_value}
        onChange={e => handleChange('max_value', e.target.value)}
        inputProps={{ inputMode: 'decimal' }}
      />
      <div className={classes.switchesContainer}>
        <Typography color="textSecondary" variant="body2">
          Opções
        </Typography>
        <FormControlLabel
          className={classes.switch}
          control={
            <Switch
              checked={boardData.customer_name_required}
              onChange={e => handleChange('customer_name_required', e.target.checked)}
              color="primary"
            />
          }
          label="Solicita nome do cliente"
        />
        <FormControlLabel
          className={classes.switch}
          control={
            <Switch
              checked={boardData.delivery_location_required}
              onChange={e => handleChange('delivery_location_required', e.target.checked)}
              color="primary"
            />
          }
          label="Solicita local de entrega"
        />
      </div>
    </CustomDialog>
  );
};

export default BoardQuicklyCreate;
