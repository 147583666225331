import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';

function ProductIngredientListDescription({ ingredients }) {
  return (
    <Fragment>
      {ingredients.map((item, index) => {
        return <Typography key={index}>{item.name}</Typography>;
      })}
    </Fragment>
  );
}

export default ProductIngredientListDescription;
