import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Restaurant, RestaurantAddress } from 'types/restaurant';

interface ProfileContextValue {
  dispatch: Dispatch<SetStateAction<any>>;
  selectedAddress: RestaurantAddress | null;
  setSelectedAddress: Dispatch<SetStateAction<RestaurantAddress | null>>;
  handleOpenDialogUpdateAddress(): void;
  handleOpenDialogNewAddress(): void;
  handleProfileAddressDelete(addressId: number): void;
  handleSetMainAddress(addressId: number): void;
  profile: Restaurant;
  isCoverSelected: boolean;
  isLogoSelected: boolean;
  setIsCoverSelected: Dispatch<SetStateAction<boolean>>;
  setIsLogoSelected: Dispatch<SetStateAction<boolean>>;
}

const ProfileContext = createContext<ProfileContextValue>({} as ProfileContextValue);
export const ProfileProvider = ProfileContext.Provider;
export const ProfileConsumer = ProfileContext.Consumer;

export function useProfile(): ProfileContextValue {
  const context = useContext(ProfileContext);
  return context;
}
