import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useBoardMovements } from '../hooks/useBoardMovements';

type BoardMovementsListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl(anchorEl: null | HTMLButtonElement): void;
};

const BoardMovementsListMenu: React.FC<BoardMovementsListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedMovement } = useBoardMovements();

  function handleActiveClick() {
    setAnchorEl(null);
  }

  return (
    <>
      {selectedMovement && (
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={handleActiveClick}>Opção 1</MenuItem>
        </Menu>
      )}
    </>
  );
};

export default BoardMovementsListMenu;
