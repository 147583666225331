import React from 'react';
import { Button, Theme, makeStyles } from '@material-ui/core';
import history from 'services/history';

const styles = makeStyles<Theme>(theme => ({
  container: props => ({
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }),
}));

const ReceiptsPageHeaderButtons: React.FC = () => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <Button variant="contained" color="primary" onClick={() => history.push('/payment-methods/new')}>
        Novo
      </Button>
    </div>
  );
};

export default ReceiptsPageHeaderButtons;
