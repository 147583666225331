import { addMinutes, format, isAfter, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { formatId } from 'helpers/formatOrderId';
import { moneyFormat } from 'helpers/NumberFormat';
import { useCallback } from 'react';
import { Order } from 'types/order';
import { getStatusText } from '../boardOrderStatusMapping';
import { OrderList, OrderShipmentList } from '../types/orderList';
import { useSelector } from 'store/redux/selector';
import { formatDistance } from 'pages/orders/formatDistance';

export function useBoardOrderFormatter(): [(orders: Order[]) => OrderList[]] {
  const restaurant = useSelector(state => state.restaurant);

  const formatter = useCallback(
    (orders: Order[]): OrderList[] => {
      if (!restaurant) {
        return [];
      }

      return orders.map(order => {
        const date = parseISO(order.created_at);

        const isLate =
          ['o', 'a', 'd'].includes(order.status) &&
          isAfter(new Date(), addMinutes(parseISO(order.created_at), restaurant.configs.delivery_time));

        const shipment = {
          ...order.shipment,
          formattedScheduledAt: order.shipment?.scheduled_at
            ? format(parseISO(order.shipment?.scheduled_at as string), "PP 'às' p", { locale: ptBR })
            : '',
        } as OrderShipmentList;

        const _order: OrderList = {
          ...order,
          formattedId: formatId(order.id),
          formattedSequence: formatId(order.sequence),
          formattedTotal: moneyFormat(order.total),
          formattedChange: moneyFormat(order.change - order.total),
          formattedCreatedAt: format(date, 'PPp', { locale: ptBR }),
          formattedSubtotal: moneyFormat(order.subtotal),
          formattedDiscount: moneyFormat(order.discount),
          formattedTax: moneyFormat(order.tax),
          dateDistance: formatDistance(date, new Date()),
          statusText: getStatusText(order.status),
          shipmentAddress: order.shipment?.address ?? '',
          paymentMethodName: order.payment_method?.method ?? '',
          paymentMode: order.payment_method?.mode === 'offline' ? 'Pgto na entrega' : 'Pgto online',
          isLate: !!isLate,
          formattedIsLate: isLate ? 'Sim' : 'Não',
          shipmentMethod: order.shipment?.shipment_method === 'delivery' ? 'Entrega' : 'Retira',
          shipment,
        };
        return _order;
      });
    },
    [restaurant]
  );

  return [formatter];
}
