export function delivererChange(index, value) {
  return {
    type: 'DELIVERER_CHANGE',
    index,
    value,
  };
}

export function setDeliverer(deliverer) {
  return {
    type: 'SET_DELIVERER',
    deliverer,
  };
}
