import React, { useState } from 'react';
import { Button, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import DialogInput, { DialogInputConsumer } from 'components/dialog/DialogInput';
import { useCashRegisterFlow } from './hooks/useCashRegisterFlow';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-around',
    textAlign: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  buttonYes: {
    width: 150,
  },
});

interface DeleteMovementDialogProps {
  onExited(): void;
}

const DeleteMovementDialog: React.FC<DeleteMovementDialogProps> = ({ onExited }) => {
  const classes = styles();
  const [deleting, setDeleting] = useState(false);
  const { handleDeleteMovement, selectedMovement, setCashRegister } = useCashRegisterFlow();

  function handleClickYes(handleCloseModal: () => void) {
    if (!selectedMovement) {
      return;
    }

    setDeleting(true);

    handleDeleteMovement(selectedMovement.id)
      .then(() => {
        setCashRegister(state => {
          if (!state) {
            return state;
          }

          return {
            ...state,
            movements: state.movements.filter(_movement => _movement.id !== selectedMovement.id),
          };
        });

        handleCloseModal();
      })
      .catch(err => console.error(err))
      .finally(() => setDeleting(false));
  }

  return (
    <DialogInput onExited={onExited} maxWidth="sm">
      <DialogInputConsumer>
        {context => (
          <div className={classes.container}>
            <Typography>Deseja realmente excluir o lançamento do caixa?</Typography>

            <div className={classes.actions}>
              <Button onClick={context.handleCloseDialog} variant="text" color="primary">
                Cancelar
              </Button>
              <Button
                className={classes.buttonYes}
                onClick={() => handleClickYes(context.handleCloseDialog)}
                disabled={deleting}
                variant="contained"
                color="primary"
              >
                {deleting ? <CircularProgress color="primary" size={16} /> : 'Sim, excluir'}
              </Button>
            </div>
          </div>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default DeleteMovementDialog;
