import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useProduct } from 'pages/products/registration/hooks/useProduct';

const useStyles = makeStyles(theme => ({
  itemDescription: {
    padding: 10,
    borderLeft: `2px solid ${theme.palette.secondary.dark}`,
    marginLeft: 13,
  },
  currentStep: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    width: 25,
    height: 25,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'inline-flex',
    fontSize: 10,
    border: `2px solid ${theme.palette.primary.dark}`,
  },
  stepOrder: {
    color: '#fff',
    backgroundColor: theme.palette.secondary.main,
    width: 25,
    height: 25,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'inline-flex',
    fontSize: 10,
    border: `2px solid ${theme.palette.secondary.dark}`,
  },
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    marginRight: 10,
    padding: 0,
  },
  step: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: 17,
  },
}));

function ProductCategoryDescription() {
  const { currentStep, product, steps } = useProduct();

  const step = steps.find(step => step.id === 'STEP_1');
  const classes = useStyles();
  return (
    <>
      <div className={classes.step}>
        <button className={classes.button}>
          <span className={currentStep.id === 'STEP_1' ? classes.currentStep : classes.stepOrder}>{step.order}</span>
        </button>
        <Typography color={'primary'} variant={'body1'}>
          Categoria
        </Typography>
      </div>
      <div className={classes.itemDescription}>
        <Typography>
          {product.category ? product.category.name : currentStep.id === 'STEP_1' && 'aguardando...'}
        </Typography>
      </div>
    </>
  );
}

export default ProductCategoryDescription;
