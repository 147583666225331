import { makeStyles, Typography } from '@material-ui/core';
import { moneyFormat } from 'helpers/NumberFormat';
import React from 'react';
import { useBoardControl } from './hooks/useBoardControl';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    padding: 20,
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'inherit',
      padding: 0,
    },
    '& > .row': {
      display: 'grid',
      gridTemplateColumns: '1fr 3fr',
      gap: 10,
      alignItems: 'baseline',
      '& > .value': {
        textAlign: 'right',
        fontSize: 20,
      },
      '& > .bold': {
        fontWeight: 'bold',
      },
    },
  },
}));

interface BoardTotalProps {}

const BoardTotal: React.FC<BoardTotalProps> = () => {
  const { movement } = useBoardControl();
  const classes = styles();

  const formattedPaidTotal = moneyFormat(movement?.payments.reduce((previous, payment) => previous + payment.value, 0));
  const orders = new Set(movement?.products.map(product => product.id)).size;
  const formattedTotal = moneyFormat(movement?.discount);

  return (
    <div className={classes.container}>
      <div className="row">
        <Typography>Pedidos</Typography>
        <Typography className="value">{orders}</Typography>
      </div>
      <div className="row">
        <Typography>Desconto</Typography>
        <Typography className="value">{formattedTotal}</Typography>
      </div>
      <div className="row">
        <Typography>Total</Typography>
        <Typography className="value bold">{movement?.formattedTotal}</Typography>
      </div>
      <div className="row">
        <Typography>Pago</Typography>
        <Typography className="value">{formattedPaidTotal}</Typography>
      </div>
    </div>
  );
};

export default BoardTotal;
