import React, { useState, useEffect, useCallback } from 'react';
import Appbar from 'components/appbar/Appbar';
import ProductsAction from 'pages/products/ProductsAction';
import ProductLoading from 'pages/products/ProductsLoading';
import { Button, makeStyles } from '@material-ui/core';
import { api, getCancelTokenSource } from 'services/api';
import NoData from 'components/nodata/NoData';
import history from 'services/history';
import useSearch from 'hooks/search';
import useTableOrder from 'hooks/tableOrder';
import { useApp } from 'App';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { boardsTableTemplate } from './boardsTableTemplate';
import TableContainer from 'components/table/TableContainer';
import TableLoading from 'components/loading/TableLoading';
import PaginationProvider from 'hooks/pagination';
import BoardListTable from './list/table/BoardListTable';
import BoardListModule from './list/module/BoardListModule';
import Pagination from 'components/_pagination/Pagination';
import BoardsFilterBox from './BoardsFilterBox';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Board } from 'types/board';
import { BoardContextProvider } from './hooks/useBoards';
import { moneyFormat } from 'helpers/NumberFormat';
import BoardQuicklyCreate from './registration/BoardQuicklyCreate';
import { useSelector } from 'store/redux/selector';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

const Boards: React.FC = () => {
  const classes = useStyles();
  const [boards, setBoards] = useState<Board[]>([]);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<Board[]>([]);
  const [indexToSearch, setIndexToSearch] = useState('number');
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const search = useSearch();
  const app = useApp();
  const [selectedBoard, setSelectedBoard] = useState<null | Board>(null);
  const [dialogCreate, setDialogCreate] = useState(false);
  const restaurant = useSelector(state => state.restaurant);

  useEffect(() => {
    const p = search(searchValue, indexToSearch, boards);
    setFiltered(p);
  }, [boards, searchValue, search, indexToSearch]);

  const handleSetBoards = useCallback((_boards: Board[]) => {
    const newBoards = _boards.map(board => {
      board.formattedActive = board.active ? 'Sim' : 'Não';
      board.formattedCustomerNameRequired = board.customer_name_required ? 'Sim' : 'Não';
      board.formattedDeliveryLocationRequired = board.delivery_location_required ? 'Sim' : 'Não';
      board.formattedCreatedAt = format(parseISO(board.created_at), 'PPp', { locale: ptBR });
      board.formattedMaxValue = moneyFormat(board.max_value);
      return board;
    });
    setBoards(state => [...state, ...newBoards]);
  }, []);

  useEffect(() => {
    setLoading(true);
    const source = getCancelTokenSource();
    let request = true;

    api
      .get('/boards', {
        cancelToken: source.token,
      })
      .then(response => {
        if (request) {
          handleSetBoards(response.data);
        }
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        if (request) setLoading(false);
      });

    return () => {
      if (request) source.cancel();
      request = false;
    };
  }, [handleSetBoards]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function qrCodeButtonClick() {
    window.open(`${process.env.REACT_APP_BASEURL_API}/restaurants/${restaurant?.uuid}/qrcodes`);
  }

  return (
    <BoardContextProvider value={{ boards, selectedBoard, setSelectedBoard }}>
      {dialogCreate && <BoardQuicklyCreate onExited={() => setDialogCreate(false)} handleSetBoards={handleSetBoards} />}
      <Appbar title="Mesas" ActionComponents={<ProductsAction />} />
      <PageHeaderActions
        title="Mesas"
        description="Gestão das mesas"
        ActionComponent={
          <>
            <Button color="primary" variant="contained" size="small" onClick={() => history.push('/board')}>
              Adicionar
            </Button>
            <Button color="primary" variant="contained" size="small" onClick={() => setDialogCreate(true)}>
              Adicionar rápido
            </Button>

            <Button color="primary" variant="contained" size="small" onClick={qrCodeButtonClick}>
              Códigos QR
            </Button>
          </>
        }
      />

      <TableContainer tableTemplate={boardsTableTemplate}>
        <BoardsFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          indexToSearch={indexToSearch}
          setIndexToSearch={setIndexToSearch}
        />

        <PaginationProvider>
          {loading ? (
            displayMode === 'list' ? (
              <TableLoading />
            ) : (
              <ProductLoading />
            )
          ) : filtered.length === 0 ? (
            <NoData message="Nenhuma mesa para mostrar" />
          ) : (
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <BoardListTable boards={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <BoardListModule boards={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          )}
        </PaginationProvider>
      </TableContainer>
    </BoardContextProvider>
  );
};

export default Boards;
