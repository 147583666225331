import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Customer } from 'types/customer';

export type CustomersContextValue = {
  customers: Customer[];
  selectedCustomer: null | Customer;
  setSelectedCustomer: Dispatch<SetStateAction<Customer | null>>;
};

const CustomersContext = createContext<CustomersContextValue>({} as CustomersContextValue);
export const CustomersProvider = CustomersContext.Provider;
export const CustomersConsumer = CustomersContext.Consumer;

export function useCustomers(): CustomersContextValue {
  const context = useContext(CustomersContext);
  return context;
}
