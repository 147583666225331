import React, { useState } from 'react';
import { IconButton, makeStyles, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import history from 'services/history';

const styles = makeStyles(theme => ({
  container: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

type BoardManagementActionsProps = {};

const BoardManagementActions: React.FC<BoardManagementActionsProps> = () => {
  const classes = styles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  return (
    <div className={classes.container}>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => history.push('/board-movement/movements')}>Histórico</MenuItem>
      </Menu>

      <Tooltip title="Adicionar categoria">
        <IconButton onClick={e => setAnchorEl(e.currentTarget)} color="inherit">
          <MoreVert />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default BoardManagementActions;
