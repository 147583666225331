import { Dispatch, SetStateAction, useState } from 'react';
import { Restaurant } from 'types/restaurant';
import * as yup from 'yup';

export type ProfileValidation = {
  play_store_link?: string;
  twitter_link?: string;
  instagram_link?: string;
  facebook_link?: string;
  cover?: string;
  image?: string;
  secondary_color?: string;
  primary_color?: string;
  keywords?: string;
  title?: string;
  description?: string;
  email?: string;
  alias?: string;
  url?: string;
  minimum_order?: string;
  working_hours?: string;
  name?: string;
  cnpj?: string;
  corporate_name?: string;
  state_subscription?: string;
  accounting_email?: string;
};

type UseProfileValidation = [
  ProfileValidation,
  Dispatch<SetStateAction<ProfileValidation>>,
  (profile: Restaurant) => Promise<void>
];

export function useProfileValidation(): UseProfileValidation {
  async function handleValidation(profile: Restaurant) {
    const schema = yup.object().shape({
      play_store_link: yup.string().url('URL válida').nullable(),
      twitter_link: yup.string().url('URL válida').nullable(),
      instagram_link: yup.string().url('URL válida').nullable(),
      facebook_link: yup.string().url('URL válida').nullable(),
      cover: yup.object().typeError('capa é obrigatória').nullable(),
      image: yup.object().required('logo do restaurante é obrigatória').typeError('logo do restaurante é obrigatória'),
      secondary_color: yup.string().required('cor secundária é obrigatória'),
      primary_color: yup.string().required('cor primária é obrigatória'),
      keywords: yup.string(),
      title: yup.string().required('título da página inicial é obrigátório'),
      description: yup.string().required('descrição do delivery é obrigatória'),
      accounting_email: yup.string().email('e-mail inválido').nullable(),
      email: yup.string().email('e-mail inválido').required('O e-mail é obrigatório'),
      alias: yup.string().required('alias é obrigatório'),
      url: yup.string().url('deve informar uma URL válida').required('URL é obrigatória'),
      minimum_order: yup.number().typeError('número inválido').nullable(true),
      working_hours: yup.string().required('horário de atendimento é obrigatório'),
      state_subscription: yup.string().nullable(),
      cnpj: yup.string().nullable(),
      corporate_name: yup.string().required('A razão social é obrigatória'),
      name: yup.string().required('nome do restaurante é obrigatório'),
    });

    try {
      await schema.validate(profile);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as any]: err.message,
        });
      }
      throw err;
    }
  }

  const [validation, setValidation] = useState<ProfileValidation>({});
  return [validation, setValidation, handleValidation];
}
