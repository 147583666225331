import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { Typography, makeStyles, ListItem, IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { TaxRuleOnList } from 'pages/tax-rules/types/taxRule';
import { useTaxRules } from 'pages/tax-rules/hooks/useTaxRules';
import history from 'services/history';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
    minHeight: 120,
    position: 'relative',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  buttonMore: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
  },
});

interface TaxRuleItemModuleProps {
  taxRule: TaxRuleOnList;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
}

const TaxRuleItemModule: React.FC<TaxRuleItemModuleProps> = ({ taxRule, setAnchorEl }) => {
  const classes = useStyles();
  const { setSelectedTaxRule } = useTaxRules();

  function handleClick() {
    setSelectedTaxRule(taxRule);
    history.push(`/tax-rules/${taxRule.id}`);
  }

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  return (
    <ListItem onClick={handleClick} key={taxRule.id} className={classes.listItem} button>
      <Typography gutterBottom variant="h6">
        {taxRule.description}
      </Typography>

      <div className={classes.row}>
        <Typography variant="caption" color="textSecondary">
          ICMS
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {`${taxRule.icms_code} | ${taxRule.formattedIcmsAliquot}`}
        </Typography>
      </div>

      <div className={classes.row}>
        <Typography variant="caption" color="textSecondary">
          PIS
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {`${taxRule.pis_cofins_code} | ${taxRule.formattedPisAliquot}`}
        </Typography>
      </div>

      <div className={classes.row}>
        <Typography variant="caption" color="textSecondary">
          COFINS
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {`${taxRule.pis_cofins_code} | ${taxRule.formattedPisAliquot}`}
        </Typography>
      </div>

      <IconButton onClick={handleMoreClick} className={classes.buttonMore}>
        <MoreVert />
      </IconButton>
    </ListItem>
  );
};

export default TaxRuleItemModule;
