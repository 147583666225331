import { Dispatch, SetStateAction, useState } from 'react';
import { PushNotification } from 'types/pushNotification';
import * as yup from 'yup';

export type PushNotificationValidation = {
  message?: string;
  title?: string;
  url_action?: string;
};

type UsePushNotificationValidation = [
  PushNotificationValidation,
  Dispatch<SetStateAction<PushNotificationValidation>>,
  (pushNotification: PushNotification) => Promise<void>
];

export function usePushNotificationValidation(): UsePushNotificationValidation {
  const [validation, setValidation] = useState<PushNotificationValidation>({} as PushNotificationValidation);

  async function handleValidation(pushNotification: PushNotification) {
    const schema = yup.object().shape({
      message: yup.string().required('A mensagem é obrigatório'),
      title: yup.string().required('O título é obrigatório'),
      url_action: yup.string().url('Informe uma URL válida').nullable(),
    });

    try {
      await schema.validate(pushNotification);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path) {
        setValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  return [validation, setValidation, handleValidation];
}
