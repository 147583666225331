import React from 'react';
import { TextField } from '@material-ui/core';
import { usePaymentMethods } from '../../hooks/usePaymentMethods';

const PaymentMethodPagSeguro: React.FC = () => {
  const { gatewaySettings, handleConfigsChange } = usePaymentMethods();

  return (
    <>
      <TextField
        label="PagSeguro API Token"
        margin="normal"
        fullWidth
        value={gatewaySettings.pagseguro_api_token}
        onChange={e => handleConfigsChange('pagseguro_api_token', e.target.value)}
        placeholder="PagSeguro API Token"
        autoFocus
      />
    </>
  );
};

export default PaymentMethodPagSeguro;
