import { useCallback } from 'react';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { FiscalSerial, FiscalSerialOnList } from '../types/fiscal-serial';

export function useFiscalSerialFormatter(): [(rule: FiscalSerial) => FiscalSerialOnList] {
  const formatter = useCallback((rule: FiscalSerial): FiscalSerialOnList => {
    return {
      ...rule,
      formattedCreatedAt: format(parseISO(rule.created_at as string), 'Pp', { locale: ptBR }),
      formattedType: rule.type,
      formattedActive: rule.active ? 'Sim' : 'Não',
    };
  }, []);

  return [formatter];
}
