import React, { useEffect, useRef } from 'react';
import { TextField, Grid, MenuItem } from '@material-ui/core';
import { Additional } from 'types/product';
import { Category } from 'types/category';
import { AdditionalValidation } from './validation/additionalValidation';

interface AdditionalFormProps {
  additional: Additional;
  handleChange(index: keyof Additional, value: any): void;
  validation: AdditionalValidation;
  type: 'edit' | 'new';
  categories: Category[];
}

const AdditionalForm: React.FC<AdditionalFormProps> = ({
  additional,
  handleChange,
  validation,
  categories,
  type = 'new',
}) => {
  const inputs = {
    name: useRef<HTMLInputElement>(null),
    price: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <Grid item xs={12} xl={3} lg={4} md={5}>
      <TextField
        inputRef={inputs.name}
        autoComplete="off"
        autoFocus
        label="Nome"
        placeholder="Nome do adicional"
        margin="normal"
        onChange={event => handleChange('name', event.target.value)}
        value={additional.name}
        fullWidth
        error={!!validation.name}
        helperText={validation.name}
        name="name"
        required
      />
      {type === 'edit' ? (
        <TextField
          fullWidth
          name="category"
          margin="normal"
          label="Categoria"
          required
          value={additional.category.name}
          disabled
        />
      ) : (
        <TextField
          fullWidth
          name="category"
          margin="normal"
          select
          label="Categoria"
          required
          value={additional.category_id}
          onChange={event => handleChange('category_id', event.target.value)}
        >
          {categories.length > 0 ? (
            categories.map(category => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem value={0}>Carregando...</MenuItem>
          )}
        </TextField>
      )}
      <TextField
        inputRef={inputs.price}
        type="number"
        autoComplete="off"
        label="Preço"
        placeholder="Preço do adicional"
        margin="normal"
        onChange={event => handleChange('price', event.target.value)}
        value={additional.price}
        error={!!validation.name}
        helperText={validation.name}
        fullWidth
        name="price"
        required
        inputProps={{
          step: 0.01,
          min: 0,
        }}
      />
      <button type="submit" style={{ display: 'none' }} />
    </Grid>
  );
};

export default AdditionalForm;
