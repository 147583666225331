import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';
import PlayIcon from '@material-ui/icons/PlayCircleFilled';
import PauseIcon from '@material-ui/icons/PauseCircleFilled';
import DeleteIcon from '@material-ui/icons/Delete';

PromotionActions.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  activated: PropTypes.bool.isRequired,
  handleStatus: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default function PromotionActions({ handleSubmit, saving, activated, handleStatus, handleDelete, loading }) {
  return (
    <>
      <Tooltip title={activated ? 'Pausar promoção' : 'Ativar promoção'}>
        <span>
          <IconButton disabled={saving || loading} color="inherit" onClick={handleStatus}>
            {activated ? <PauseIcon /> : <PlayIcon />}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Excluir promoção">
        <span>
          <IconButton disabled={saving || loading} color="inherit" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Salvar">
        <span>
          <IconButton disabled={saving || loading} onClick={handleSubmit} color="inherit">
            <DoneIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
}
