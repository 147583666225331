import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import history from 'services/history';
import { PushNotification } from 'types/pushNotification';
import { Done, Schedule } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
  },
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: 6,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  icon: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
}));

interface PushNotificationItemModuleProps {
  pushNotification: PushNotification;
}

const PushNotificationItemModule: React.FC<PushNotificationItemModuleProps> = ({ pushNotification }) => {
  const classes = useStyles();

  function handleClick(pushNotificationId: number) {
    history.push(`/push-notifications/${pushNotificationId}`);
  }

  return (
    <ListItem
      onClick={() => handleClick(pushNotification.id)}
      key={pushNotification.id}
      className={classes.listItem}
      button
    >
      <Typography variant="h6">{pushNotification.title}</Typography>
      <Typography variant="body2">{pushNotification.message}</Typography>
      {pushNotification.formattedDeliveryDate ? (
        <Typography variant="body2" color="textSecondary">
          {pushNotification.delivered ? 'Enviado em ' : 'Programado para '} {pushNotification.formattedDeliveryDate}
        </Typography>
      ) : (
        <Typography variant="body2" color="textSecondary">
          {pushNotification.delivered ? 'Enviado em ' : 'Programado para '} {pushNotification.formattedCreatedAt}
        </Typography>
      )}
      {pushNotification.delivered ? (
        <Done color="primary" className={classes.icon} />
      ) : (
        !pushNotification.delivered &&
        pushNotification.delivery_date && <Schedule color="primary" className={classes.icon} />
      )}
    </ListItem>
  );
};

export default PushNotificationItemModule;
