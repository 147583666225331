import React, { useEffect, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import TableContainer from 'components/table/TableContainer';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import PaginationProvider from 'hooks/pagination';
import useTableOrder from 'hooks/tableOrder';
import { api } from 'services/api';
import CashRegistersFilterBox from './CashRegistersFilterBox';
import { cashRegistersTableTemplate } from './cashRegistersTableTemplate';
import { CashRegistersProvider } from './hooks/useCashRegisters';
import CashRegisterListModule from './list/module/CashRegisterListModule';
import CashRegisterListTable from './list/table/CashRegisterListTable';
import CashRegistersActions from './CashRegistersActions';
import NoData from 'components/nodata/NoData';
import { useApp } from 'App';
import { CashRegister } from 'types/cashRegister';
import { useMessaging } from 'hooks/messaging';
import Loading from 'components/loading/Loading';
import { useFetchCashRegisters } from './hooks/useFetchCashRegisters';
import ApiPagination from 'components/_pagination/ApiPagination';
import CashRegisterInitialValue from './initial-value/CashRegisterInitialValue';
import { moneyFormat } from 'helpers/NumberFormat';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 10,
    flex: 0.5,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '0.5fr 1fr',
      flex: 1,
    },
  },
}));

const CashRegisters: React.FC = () => {
  const classes = useStyles();
  const [selectedCashRegister, setSelectedCashRegister] = useState<null | CashRegister>(null);
  const [saving, setSaving] = useState(false);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<CashRegister[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const messaging = useMessaging();
  const app = useApp();
  const [rows, setRows] = useState(20);
  const [page, setPage] = useState(0);
  const [loading, cashRegisters, setCashRegisters] = useFetchCashRegisters(page, rows);
  const [initialValueDialog, setInitialValueDialog] = useState(false);

  useEffect(() => {
    setFiltered(cashRegisters);
  }, [cashRegisters]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleNewCashRegister() {
    setSaving(true);

    api
      .post('/cashRegisters')
      .then(response =>
        setCashRegisters(previous => [
          {
            ...response.data,
            dayOfTheWeek: format(parseISO(response.data.created_at), 'eeee', { locale: ptBR }),
            status: 'Aberto',
            formattedInitialValue: moneyFormat(response.data.initial_value),
            formattedCreatedAt: format(parseISO(response.data.created_at), 'PPp', {
              locale: ptBR,
            }),
          },
          ...previous,
        ])
      )
      .catch(err => messaging.handleOpen(err.response ? err.response.data.error : 'Não foi possível abrir o caixa'))
      .finally(() => setSaving(false));
  }

  function handleCloseCashRegister(cashRegisterId: number) {
    setSaving(true);
    api
      .put(`/cashRegisters/close/${cashRegisterId}`)
      .then(response => {
        messaging.handleOpen('Salvo');
        setCashRegisters(
          cashRegisters.map(register => {
            if (register.id === cashRegisterId) {
              return {
                ...register,
                closed_at: response.data.closed_at,
                status: 'Fechado',
                formattedClosedAt: format(parseISO(response.data.closed_at), 'PPp', { locale: ptBR }),
              };
            }
            return register;
          })
        );
      })
      .catch(err => {
        messaging.handleOpen(err.response ? err.response.data.error : 'Não foi possível fechar o caixa');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleCashRegisterListUpdate(cashRegister: CashRegister) {
    setCashRegisters(state =>
      state.map(item => {
        if (item.id === cashRegister.id) {
          return cashRegister;
        }

        return item;
      })
    );
  }

  return (
    <CashRegistersProvider
      value={{
        selectedCashRegister,
        setSelectedCashRegister,
        handleCloseCashRegister,
        openInitialValueDialog: () => setInitialValueDialog(true),
        handleCashRegisterListUpdate,
      }}
    >
      {saving && <Loading />}

      {initialValueDialog && <CashRegisterInitialValue onExited={() => setInitialValueDialog(false)} />}

      <Appbar title="Caixa" ActionComponents={<CashRegistersActions handleNewCashRegister={handleNewCashRegister} />} />

      <PageHeaderActions
        title="Caixas"
        description="Gestão dos caixas"
        ActionComponent={
          <>
            <Button size="small" variant="contained" color="primary" onClick={() => handleNewCashRegister()}>
              Adicionar
            </Button>
          </>
        }
      />

      <TableContainer tableTemplate={cashRegistersTableTemplate}>
        <CashRegistersFilterBox
          setDisplayMode={setDisplayMode}
          displayMode={displayMode}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
        />

        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum caixa para mostrar" />
        ) : (
          <PaginationProvider>
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <CashRegisterListTable cashRegisters={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <CashRegisterListModule cashRegisters={filtered} />
              )}
              <ApiPagination
                count={cashRegisters.length}
                onChangePage={page => setPage(page)}
                onChangeRowsPerPage={rows => setRows(rows)}
              />
            </div>
          </PaginationProvider>
        )}
      </TableContainer>
    </CashRegistersProvider>
  );
};

export default CashRegisters;
