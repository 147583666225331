export function promotionChange(index, value) {
  return {
    type: 'CHANGE',
    index,
    value,
  };
}

export function promotionSetCategory(category) {
  return {
    type: 'SET_CATEGORY',
    category,
  };
}

export function promotionDeleteCategory(categoryId) {
  return {
    type: 'DELETE_CATEGORY',
    categoryId,
  };
}

export function promotionSetProduct(product) {
  return {
    type: 'SET_PRODUCT',
    product,
  };
}

export function promotionEditProduct(product) {
  return {
    type: 'EDIT_PRODUCT',
    product,
  };
}

export function promotionDeleteProduct(productUid) {
  return {
    type: 'DELETE_PRODUCT',
    productUid,
  };
}

export function promotionSetOfferedProducts(product) {
  return {
    type: 'SET_OFFERED_PRODUCT',
    product,
  };
}

export function promotionEditOfferedProduct(product) {
  return {
    type: 'EDIT_OFFERED_PRODUCT',
    product,
  };
}

export function promotionDeleteOfferedProduct(productUid) {
  return {
    type: 'DELETE_OFFERED_PRODUCT',
    productUid,
  };
}

export function setPromotion(promotion) {
  return {
    type: 'SET_PROMOTION',
    promotion,
  };
}

export function setPromotionOrderValue(value) {
  return {
    type: 'SET_ORDER_VALUE',
    value,
  };
}

export function promotionAvailabilityChange(day, status) {
  return {
    type: 'AVAILABILITY_CHANGE',
    day,
    status,
  };
}
