import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { OrderIndexData } from 'hooks/tableOrder';
import BoardProductList from './table/BoardProductList';
import BoardSelectedTotal from '../BoardSelectedTotal';
import { useApp } from 'App';
import BoardProductListModule from './module/BoardProductsList';
import { useBoardControl } from '../hooks/useBoardControl';

const styles = makeStyles({
  container: {
    overflowY: 'auto',
  },
});

interface BoardProductsProps {
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
}

const BoardProducts: React.FC<BoardProductsProps> = ({ handleSort, orderedIndex }) => {
  const classes = styles();
  const app = useApp();
  const { movement } = useBoardControl();

  return (
    <div className={classes.container}>
      {app.isMobile ? (
        <BoardProductListModule products={movement?.products ?? []} />
      ) : (
        <BoardProductList products={movement?.products ?? []} handleSort={handleSort} orderedIndex={orderedIndex} />
      )}

      <BoardSelectedTotal />
    </div>
  );
};

export default BoardProducts;
