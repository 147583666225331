import { Typography, makeStyles } from '@material-ui/core';
import DialogInput from 'components/dialog/DialogInput';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import React, { useState } from 'react';
import { useReceipts } from '../hooks/useReceipts';
import CancelReceiptModalActions from './CancelReceiptModalActions';
import CancelReceiptForm from './CancelReceiptForm';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 300,
    justifyContent: 'space-evenly',
    flex: 1,
    padding: '0 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 5px',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
}));

interface CancelReceiptModalProps {
  onExited(): void;
}

const CancelReceiptModal: React.FC<CancelReceiptModalProps> = ({ onExited }) => {
  const classes = styles();
  const [saving, setSaving] = useState(false);
  const { showErrorDialog } = useErrorHandler();
  const { handleCancel } = useReceipts();
  const [justification, setJustification] = useState('');
  const [validation, setValidation] = useState('');

  function handleConfirmClick(closeModal: () => void) {
    setValidation('');

    if (!justification) {
      setValidation('justificativa é obrigatória');
      return;
    }

    handleSubmit(closeModal);
  }

  function handleSubmit(closeModal: () => void) {
    setSaving(true);

    handleCancel(justification)
      .then(closeModal)
      .catch(err =>
        showErrorDialog({
          error: err,
          message: 'Não foi possível cancelar o cupom',
        })
      )
      .finally(() => setSaving(false));
  }

  return (
    <DialogInput maxWidth="sm" onExited={onExited} disableBackdropClick disableEscapeKeyDown>
      <div className={classes.container}>
        <Typography align="center">Justificativa do cancelamento</Typography>

        <CancelReceiptForm justification={justification} setJustification={setJustification} validation={validation} />

        <CancelReceiptModalActions saving={saving} handleConfirmClick={handleConfirmClick} />
      </div>
    </DialogInput>
  );
};

export default CancelReceiptModal;
