import { Typography, makeStyles } from '@material-ui/core';
import DialogInput from 'components/dialog/DialogInput';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import React, { useState } from 'react';
import DisableSequenceModalActions from './DisableSequenceModalActions';
import DisableSequenceForm from './DisableSequenceModalForm';
import { useFiscalSerials } from '../hooks/useFiscalSerials';
import { DisableSequencePayload } from '../types/disable-sequence-payload';
import { useDisableSequenceValidation } from './validation/useDisableSequenceValidation';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400,
    justifyContent: 'space-evenly',
    flex: 1,
    padding: '0 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 5px',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
}));

interface DisableSequenceModalProps {
  onExited(): void;
}

const DisableSequenceModal: React.FC<DisableSequenceModalProps> = ({ onExited }) => {
  const classes = styles();
  const [saving, setSaving] = useState(false);
  const { showErrorDialog } = useErrorHandler();
  const { handleDisableSequence } = useFiscalSerials();
  const [validation, setValidation, validate] = useDisableSequenceValidation();
  const [payload, setPayload] = useState<DisableSequencePayload>({
    from: '',
    to: '',
    justification: '',
  });

  function handleConfirmClick(closeModal: () => void) {
    setValidation({});

    validate(payload)
      .then(() => handleSubmit(closeModal))
      .catch(err => console.error(err));
  }

  function handleSubmit(closeModal: () => void) {
    setSaving(true);

    handleDisableSequence(payload)
      .then(closeModal)
      .catch(err =>
        showErrorDialog({
          error: err,
          message: 'Não foi inutilizar a numeração',
        })
      )
      .finally(() => setSaving(false));
  }

  function handleChange(index: keyof DisableSequencePayload, value: string) {
    setPayload(state => ({
      ...state,
      [index]: value,
    }));
  }

  return (
    <DialogInput maxWidth="sm" onExited={onExited} disableBackdropClick disableEscapeKeyDown>
      <div className={classes.container}>
        <Typography align="center">Inutilização de numeração</Typography>

        <DisableSequenceForm payload={payload} handleChange={handleChange} validation={validation} />

        <DisableSequenceModalActions saving={saving} handleConfirmClick={handleConfirmClick} />
      </div>
    </DialogInput>
  );
};

export default DisableSequenceModal;
