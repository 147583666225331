import React, { useState } from 'react';
import { IconButton, Tooltip, makeStyles, Menu, MenuItem } from '@material-ui/core';
import LoupeIcon from '@material-ui/icons/Loupe';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import MoreIcon from '@material-ui/icons/MoreVert';
import { FileCopy, Search } from '@material-ui/icons';
import { useApp } from 'App';
import { useImageSelector } from './hooks/useImageSelector';
import SearchBox from './SearchBox';

const useStyles = makeStyles({
  upload: {
    display: 'inline-flex',
    padding: 12,
    verticalAlign: 'middle',
    cursor: 'pointer',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
});

const ImageSelectorActions: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const app = useApp();
  const {
    selectedImage,
    handleConfirmImage,
    handleImageDelete,
    showConfirmAction,
    handleCopyImageUrl,
    openImagePreview,
    isSearchBoxOpened,
    setIsSearchBoxOpened,
    handleUploadFiles,
  } = useImageSelector();

  return selectedImage ? (
    <>
      {showConfirmAction ? (
        <Tooltip title="Confirmar">
          <IconButton onClick={handleConfirmImage} color="inherit">
            <DoneIcon />
          </IconButton>
        </Tooltip>
      ) : !app.isMobile ? (
        <>
          <Tooltip title="Copiar endereço da imagem">
            <IconButton onClick={handleCopyImageUrl} color="inherit">
              <FileCopy />
            </IconButton>
          </Tooltip>
          <Tooltip title="Ver imagem">
            <IconButton onClick={openImagePreview} color="inherit">
              <LoupeIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Excluir imagem">
            <IconButton onClick={handleImageDelete} color="inherit">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        app.isMobile && (
          <>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
              <MenuItem onClick={openImagePreview}>Ver foto</MenuItem>
              <MenuItem onClick={handleCopyImageUrl}>Copiar endereço da image</MenuItem>
              <MenuItem onClick={handleImageDelete}>Excluir foto</MenuItem>
            </Menu>
            <IconButton onClick={e => setAnchorEl(e.currentTarget)} color="inherit">
              <MoreIcon />
            </IconButton>
          </>
        )
      )}
    </>
  ) : isSearchBoxOpened ? (
    <SearchBox />
  ) : (
    <>
      <Tooltip title="Pesquisar imagem">
        <IconButton onClick={() => setIsSearchBoxOpened(true)} color="inherit">
          <Search />
        </IconButton>
      </Tooltip>
      <input
        type="file"
        onChange={event => handleUploadFiles(event.target.files)}
        accept="image/*"
        id="new-photo"
        style={{ display: 'none' }}
        multiple
      />
      <label htmlFor="new-photo" className={classes.upload}>
        <CloudUploadIcon />
      </label>
    </>
  );
};

export default ImageSelectorActions;
