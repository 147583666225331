import React, { useReducer, useMemo, useState, useEffect } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import promotionReducer, { INITIAL_STATE as promotionInitialState } from 'store/modules/promotion/reducer';
import { promotionChange, setPromotion } from 'store/modules/promotion/actions';
import { steps as _steps } from '../../steps';
import PromotionActions from './PromotionActions';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import * as yup from 'yup';
import PromotionForm from '../steps/form/PromotionForm';
import { subDays } from 'date-fns';
import PromotionCategory from '../steps/category/PromotionCategory';
import PromotionProducts from '../steps/product/PromotionProducts';
import PromotionOfferedProducts from '../steps/offered_product/PromotionOfferedProducts';
import PromotionDiscount from '../steps/discount/PromotionDiscount';
import PromotionConfirm from '../steps/confirm/PromotionConfirm';
import { api } from 'services/api';
import history from 'services/history';
import Loading from 'components/loading/Loading';
import PromotionOrderValue from '../steps/order_value/PromotionOrderValue';
import { useParams } from 'react-router-dom';
import PromotionAvailability from '../steps/availability/PromotionAvailability';
import { useMessaging } from 'hooks/messaging';
import { PromotionProvider } from '../../hooks/usePromotion';
import PromotionResume from '../resume/PromotionResume';
import InsideLoading from 'components/loading/InsideLoading';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { useApp } from 'App';

const resumeWidth = 350;

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    padding: 30,
    width: `calc(100% - ${resumeWidth}px)`,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '30px 0',
    },
  },
  content: {
    marginBottom: 100,
  },
  stepResume: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    position: 'fixed',
    right: 0,
    top: 0,
    bottom: 0,
    width: resumeWidth,
    backgroundColor: '#fff',
    padding: 15,
    marginTop: 64,
    borderLeft: '1px solid #eee',
    overflowY: 'scroll',
  },
  actions: {
    transition: 'all 0.4s ease 0s',
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
    position: 'fixed',
    right: 350,
    padding: 20,
    borderTop: '1px solid #eee',
    bottom: 0,
    backgroundColor: '#fff',
    [theme.breakpoints.down('sm')]: {
      right: 0,
      padding: 15,
    },
  },
  step: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    width: 25,
    height: 25,
    borderRadius: '50%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    border: `2px solid ${theme.palette.primary.dark}`,
  },
  stepDescription: {
    marginTop: 10,
  },
}));

export default function Promotion() {
  const [promotion, dispatch] = useReducer(promotionReducer, promotionInitialState);
  const [validation, setValidation] = useState({});
  const [step, setStep] = useState(1);
  const [steps, setSteps] = useState(_steps);
  const messaging = useMessaging();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const app = useApp();

  const currentStep = useMemo(() => {
    return steps.find(s => s.order === step);
  }, [steps, step]);

  const classes = useStyles();

  const promotionContextValue = {
    handleNext,
    dispatch,
    promotion,
    handlePrior,
  };

  useEffect(() => {
    api
      .get(`/promotions/${params.id}`)
      .then(response => {
        dispatch(setPromotion(response.data));
      })
      .catch(() => {
        messaging.handleOpen('Não foi possível carregar a promoção');
        history.push('/promotions');
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [params.id]);

  useEffect(() => {
    let newSteps = _steps.slice();
    let order = 0;

    if (promotion.rule_type === 'product') {
      newSteps = newSteps.filter(s => s.id !== 'STEP_2' && s.id !== 'STEP_4');
    }

    if (promotion.rule_type === 'category') {
      newSteps = newSteps.filter(s => s.id !== 'STEP_3' && s.id !== 'STEP_4');
    }

    if (promotion.rule_type === 'order_value') {
      newSteps = newSteps.filter(s => s.id !== 'STEP_2' && s.id !== 'STEP_3');
    }

    if (promotion.type === 'get') {
      newSteps = newSteps.filter(s => s.id !== 'STEP_5');
    }

    if (promotion.type === 'safe') {
      newSteps = newSteps.filter(s => s.id !== 'STEP_6');
    }

    setSteps(
      newSteps.map(step => {
        order++;
        step.order = order;
        return step;
      })
    );
    // eslint-disable-next-line
  }, [promotion.type, promotion.rule_type]);

  async function handleNext() {
    if (currentStep.id === 'STEP_1') {
      try {
        await handleValidation();
        setValidation({});
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (currentStep.id === 'STEP_4') {
      try {
        await handleOrderValueValidation();
        setValidation({});
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (currentStep.id === 'STEP_5') {
      try {
        await handleDiscountValidation();
        setValidation({});
      } catch (err) {
        console.log(err);
        return;
      }
    }

    setStep(oldStep => oldStep < 6 && oldStep + 1);
  }

  function handlePrior() {
    setStep(oldStep => oldStep >= 1 && oldStep - 1);
  }

  function handleChange(index, value) {
    dispatch(promotionChange(index, value));
  }

  async function handleValidation() {
    const schema = yup.object().shape({
      valid_at: yup.date().nullable().min(subDays(new Date(), 1), 'A data precisa ser maior ou igual a data de hoje'),
      description: yup.string().required('A descrição é obrigatória'),
      name: yup.string().required('O nome é obrigatório'),
      image: yup.object().typeError('A imagem é obrigatória').required('A imagem é obrigatório'),
    });

    try {
      await schema.validate(promotion);
    } catch (err) {
      setValidation({
        [err.path]: err.message,
      });
      throw err;
    }
  }

  async function handleDiscountValidation() {
    const schema = yup.object().shape({
      discount: yup.number().typeError('O valor do desconto é obrigatório'),
    });

    try {
      await schema.validate(promotion.safe);
    } catch (err) {
      setValidation({
        [err.path]: err.message,
      });
      throw err;
    }
  }

  async function handleOrderValueValidation() {
    const schema = yup.object().shape({
      order_value: yup.number().typeError('O valor do pedido é obrigatório'),
    });

    try {
      await schema.validate(promotion.order_value);
    } catch (err) {
      setValidation({
        order_value: 'O valor do pedido é obrigatório',
      });
      throw err;
    }
  }

  function handleSubmit() {
    setSaving(true);
    api
      .put(`/promotions/${params.id}`, promotion)
      .then(() => {
        messaging.handleOpen('Salvo');
        history.push('/promotions');
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível salvar a promoção');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleStatus() {
    setSaving(true);

    api
      .put(`/promotions/status/${promotion.id}`)
      .then(() => {
        messaging.handleOpen('Atualizado');
        handleChange('activated', !promotion.activated);
        history.push('/promotions');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleDelete() {
    setSaving(true);

    api
      .delete(`/promotions/${promotion.id}`)
      .then(() => {
        messaging.handleOpen('Excluído');
        history.push('/promotions');
        setSaving(false);
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível excluir a promoção');
        setSaving(false);
      });
  }

  return (
    <PromotionProvider value={promotionContextValue}>
      {saving && <Loading />}
      <CustomAppbar
        title="Alterar promoção"
        ActionComponents={
          <PromotionActions
            handleSubmit={handleSubmit}
            saving={saving}
            handleStatus={handleStatus}
            activated={promotion.activated}
            loading={loading}
            handleDelete={handleDelete}
          />
        }
      />
      <PageHeader title="Alterar promoção" description="Siga os passos e edite uma promoção" />
      {loading ? (
        <InsideLoading />
      ) : (
        <>
          <div className={classes.stepDescription}>
            <Typography variant="body1">
              <span className={classes.step}>{currentStep.order}</span>
              {currentStep.description}
            </Typography>
          </div>
          <div className={classes.container}>
            <div className={classes.content}>
              {currentStep.id === 'STEP_1' ? (
                <PromotionForm validation={validation} promotion={promotion} handleChange={handleChange} />
              ) : currentStep.id === 'STEP_2' ? (
                <PromotionCategory promotionCategories={promotion.categories} />
              ) : currentStep.id === 'STEP_3' ? (
                <PromotionProducts promotionProducts={promotion.products} />
              ) : currentStep.id === 'STEP_4' ? (
                <PromotionOrderValue promotion={promotion} validation={validation} />
              ) : currentStep.id === 'STEP_5' ? (
                <PromotionDiscount promotion={promotion} validation={validation} handleChange={handleChange} />
              ) : currentStep.id === 'STEP_6' ? (
                <PromotionOfferedProducts offeredProducts={promotion.offered_products} />
              ) : currentStep.id === 'STEP_7' ? (
                <PromotionAvailability availability={promotion.availability} />
              ) : (
                <PromotionConfirm handleSubmit={handleSubmit} saving={saving} />
              )}
            </div>
            <div
              className={classes.actions}
              style={{
                left: app.isOpenedMenu ? 300 : 0,
                justifyContent: step > 1 ? 'space-between' : 'flex-end',
              }}
            >
              {step > 1 && (
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<ArrowBack />}
                  onClick={handlePrior}
                  className={classes.button}
                >
                  Voltar
                </Button>
              )}
              {currentStep.order >= 1 && currentStep.id !== 'STEP_8' && (
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  onClick={handleNext}
                  className={classes.button}
                  endIcon={<ArrowForward />}
                >
                  Avançar
                </Button>
              )}
            </div>
          </div>
          <div className={classes.stepResume}>
            <PromotionResume promotion={promotion} currentStep={currentStep} steps={steps} />
          </div>
        </>
      )}
    </PromotionProvider>
  );
}
