import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { alpha, IconButton, makeStyles, Typography } from '@material-ui/core';
import { ordersTableTemplate } from '../ordersTableTemplate';
import { MoreHoriz } from '@material-ui/icons';
import { useOrders } from 'pages/board-orders/hook/useOrders';
import { OrderList } from 'pages/orders/types/orderList';

type OrderItemTableProps = {
  order: OrderList;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const useStyles = makeStyles(theme => ({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
  timer: {
    padding: '4px 10px',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: alpha(theme.palette.success.main, 0.2),
    color: theme.palette.success.main,
  },
  timerLate: {
    backgroundColor: alpha(theme.palette.error.main, 0.2),
    color: theme.palette.error.main,
  },
  p: { backgroundColor: '#17a2b8', color: '#fff' },
  o: { backgroundColor: '#ffc107' },
  a: { backgroundColor: '#8BC34A', color: '#fff' },
  d: { backgroundColor: '#007bff', color: '#fff' },
  c: { backgroundColor: '#6c757d', color: '#fff' },
  x: { backgroundColor: '#dc3545', color: '#fff' },
  status: {
    padding: '4px 10px',
    borderRadius: theme.shape.borderRadius,
    fontSize: 12,
    marginLeft: 6,
  },
}));

const OrderItemTable: React.FC<OrderItemTableProps> = ({ order, setAnchorEl }) => {
  const classes = useStyles();
  const { setSelectedOrder } = useOrders();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSelectedOrder(order);
  }

  return (
    <>
      {ordersTableTemplate.map(item =>
        item.id === 'actions' ? (
          <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
            <MoreHoriz />
          </IconButton>
        ) : item.id === 'dateDistance' ? (
          ['o', 'a', 'd'].includes(order.status) ? (
            <div key={item.id} className={order.isLate ? `${classes.timer} ${classes.timerLate}` : classes.timer}>
              <Typography align="center" variant="caption">
                {order[item.id]}
              </Typography>
            </div>
          ) : (
            <div key={item.id}>
              <Typography align="center">-</Typography>
            </div>
          )
        ) : item.id === 'statusText' ? (
          <div key={item.id} className={`${classes.status} ${classes[order.status]}`}>
            <Typography variant="caption">{order[item.id]}</Typography>
          </div>
        ) : (
          <div key={item.id}>
            <Typography variant="body2">{order[item.id]}</Typography>
          </div>
        )
      )}
    </>
  );
};

export default OrderItemTable;
