import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';

function ProductAdditionalListDescription({ additional }) {
  return (
    <Fragment>
      {additional.map((item, index) => {
        return <Typography key={index}>{item.name}</Typography>;
      })}
    </Fragment>
  );
}

export default ProductAdditionalListDescription;
