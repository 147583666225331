import { Dispatch, useReducer } from 'react';
import { TaxRuleActions } from './types';
import { TaxRule } from 'pages/tax-rules/types/taxRule';

const INITIAL_STATE: TaxRule = {
  pis_cofins_code: '',
  pis_aliquot: 0,
  internal_cfop: '',
  id: '',
  icms_st_aliquot: 0,
  icms_code: '',
  icms_aliquot: 0,
  external_cfop: '',
  description: '',
  created_at: '',
  cofins_aliquot: 0,
};

function boardReducer(state = INITIAL_STATE, action: TaxRuleActions): TaxRule {
  switch (action.type) {
    case 'TAX_RULE_CHANGE': {
      return {
        ...state,
        [action.key]: action.value,
      };
    }

    case 'SET_TAX_RULE': {
      return action.taxRule;
    }

    default: {
      return state;
    }
  }
}

type UseBoardReducer = [TaxRule, Dispatch<TaxRuleActions>];

export function useTaxRuleReducer(): UseBoardReducer {
  return useReducer(boardReducer, INITIAL_STATE);
}
