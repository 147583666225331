import React from 'react';
import PropTypes from 'prop-types';
import PromotionResumeForm from './form/PromotionResumeForm';
import PromotionResumeCategories from './category/PromotionResumeCategories';
import PromotionResumeOfferedProducts from './offered_product/PromotionResumeOfferedProducts';
import PromotionResumeProducts from './product/PromotionResumeProducts';
import PromotionResumeConfirm from './confirm/PromotionResumeConfirm';
import PromotionResumeDiscount from './discount/PromotionResumeDiscount';
import PromotionResumeOrderValue from './order_value/PromotionResumeOrderValue';
import PromotionResumeAvailability from './availability/PromotionResumeAvailability';

PromotionResume.propTypes = {
  promotion: PropTypes.object.isRequired,
  currentStep: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired,
};

export default function PromotionResume({ promotion, currentStep, steps }) {
  return (
    <>
      <PromotionResumeForm promotion={promotion} currentStep={currentStep} steps={steps} />
      {steps.find(step => step.id === 'STEP_2') && (
        <PromotionResumeCategories promotion={promotion} currentStep={currentStep} steps={steps} />
      )}
      {steps.find(step => step.id === 'STEP_3') && (
        <PromotionResumeProducts promotion={promotion} currentStep={currentStep} steps={steps} />
      )}
      {steps.find(step => step.id === 'STEP_4') && (
        <PromotionResumeOrderValue promotion={promotion} currentStep={currentStep} steps={steps} />
      )}
      {steps.find(step => step.id === 'STEP_5') && (
        <PromotionResumeDiscount promotion={promotion} currentStep={currentStep} steps={steps} />
      )}
      {steps.find(step => step.id === 'STEP_6') && (
        <PromotionResumeOfferedProducts promotion={promotion} currentStep={currentStep} steps={steps} />
      )}
      <PromotionResumeAvailability promotion={promotion} currentStep={currentStep} steps={steps} />
      <PromotionResumeConfirm promotion={promotion} currentStep={currentStep} steps={steps} />
    </>
  );
}
