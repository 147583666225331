import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import { Coupon } from 'types/coupon';
import { Link } from 'react-router-dom';
import { Pause } from '@material-ui/icons';

const useStyles = makeStyles({
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
  },

  icon: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
});

interface CouponItemModuleProps {
  coupon: Coupon;
}

const CouponItemModule: React.FC<CouponItemModuleProps> = ({ coupon }) => {
  const classes = useStyles();

  return (
    <ListItem key={coupon.id} className={classes.listItem} button component={Link} to={`/coupons/${coupon.id}`}>
      <Typography variant="h6">{coupon.name}</Typography>
      <Typography color="textSecondary" variant="body2">
        {coupon.description}
      </Typography>
      {coupon.valid_at ? (
        <Typography color="textSecondary" variant="body2">
          Valido até {coupon.formattedValidAt}
        </Typography>
      ) : (
        <Typography color="textSecondary" variant="body2">
          Validade indeterminada
        </Typography>
      )}
      {!coupon.activated && <Pause className={classes.icon} color="primary" />}
    </ListItem>
  );
};

export default CouponItemModule;
