import { cnpjValidator } from 'helpers/cnpjValidator';
import { cpfValidation } from 'helpers/cpfValidation';
import { Dispatch, SetStateAction, useState } from 'react';
import { Customer } from 'types/customer';
import * as yup from 'yup';

export type CustomerValidation = {
  cpf?: string;
  birthday?: string;
  email?: string;
  phone?: string;
  name?: string;
};

type UseCustomerValidation = [
  CustomerValidation,
  Dispatch<SetStateAction<CustomerValidation>>,
  (customer: Customer) => Promise<void>
];

export function useCustomerValidation(): UseCustomerValidation {
  const [validation, setValidation] = useState<CustomerValidation>({} as CustomerValidation);

  async function handleValidation(customer: Customer) {
    const schema = yup.object().shape({
      cpf: yup.mixed().test('cpf', 'CPF inválido', value => {
        if (!value) {
          return false;
        }

        const cnpj = cnpjValidator(value);
        const cpf = cpfValidation(value);

        return cnpj || cpf;
      }),
      email: yup.string().email('Informe um email válido').nullable(),
      phone: yup.string().required('O telefone é obrigatório'),
      name: yup.string().required('O nome é obrigatório'),
    });

    try {
      await schema.validate(customer);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path) {
        setValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  return [validation, setValidation, handleValidation];
}
