import { useCallback } from 'react';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { TaxRule, TaxRuleOnList } from '../types/taxRule';
import { percentFormat } from 'helpers/NumberFormat';

export function useTaxRuleFormatter(): [(rule: TaxRule) => TaxRuleOnList] {
  const formatter = useCallback((rule: TaxRule): TaxRuleOnList => {
    return {
      ...rule,
      formattedCreatedAt: format(parseISO(rule.created_at as string), 'Pp', { locale: ptBR }),
      formattedCofinsAliquot: percentFormat(rule.cofins_aliquot),
      formattedIcmsAliquot: percentFormat(rule.icms_aliquot),
      formattedIcmsStAliquot: percentFormat(rule.icms_st_aliquot),
      formattedPisAliquot: percentFormat(rule.pis_aliquot),
    };
  }, []);

  return [formatter];
}
