import { Dispatch, useReducer } from 'react';
import { Board } from 'types/board';
import { BoardActions } from './types';

const INITIAL_STATE: Board = {
  active: true,
  id: '',
  restaurant_id: 0,
  qr_code: '',
  created_at: '',
  description: '',
  formattedActive: '',
  formattedCreatedAt: '',
  number: '',
  customer_name_required: true,
  delivery_location_required: true,
  max_value: 0,
};

function boardReducer(state = INITIAL_STATE, action: BoardActions): Board {
  switch (action.type) {
    case 'BOARD_CHANGE': {
      return {
        ...state,
        [action.key]: action.value,
      };
    }

    case 'SET_BOARD': {
      return action.board;
    }

    default: {
      return state;
    }
  }
}

type UseBoardReducer = [Board, Dispatch<BoardActions>];

export function useTableReducer(): UseBoardReducer {
  const [board, dispatch] = useReducer(boardReducer, INITIAL_STATE);
  return [board, dispatch];
}
