export function cardBrandValidation(number: string): boolean {
  const brands = [
    {
      name: 'visa',
      regex: /^4[0-9]{12}(?:[0-9]{3})?$/,
    },
    {
      name: 'mastercard',
      regex: /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
    },
    {
      name: 'diners',
      regex: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    },
    {
      name: 'discover',
      regex:
        /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
    },
    {
      name: 'elo',
      regex:
        /^(40117[8-9]|431274|438935|451416|457393|45763[1-2]|506(699|7[0-6][0-9]|77[0-8])|509d{3}|504175|627780|636297|636368|65003[1-3]|6500(3[5-9]|4[0-9]|5[0-1])|6504(0[5-9]|[1-3][0-9])|650(4[8-9][0-9]|5[0-2][0-9]|53[0-8])|6505(4[1-9]|[5-8][0-9]|9[0-8])|6507(0[0-9]|1[0-8])|65072[0-7]|6509(0[1-9]|1[0-9]|20)|6516(5[2-9]|[6-7][0-9])|6550([0-1][0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
    },
    {
      name: 'amex',
      regex: /^3[47][0-9]{13}$/,
    },
    {
      name: 'jcb',
      regex: /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/,
    },
    {
      name: 'aura',
      regex: /^50[0-9]{17}$/,
    },
    {
      name: 'hipercard',
      regex: /^(60)/,
    },
    {
      name: 'maestro',
      regex: /^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/,
    },
  ];

  const brand = brands.find(brand => {
    const match = brand.regex.test(number);
    return !!match;
  });

  return !!brand;
}
