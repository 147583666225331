import { Board } from 'types/board';
import { BoardActions } from './types';

export function tableChange(key: keyof Board, value: any): BoardActions {
  return {
    type: 'BOARD_CHANGE',
    key,
    value,
  };
}

export function setTable(board: Board): BoardActions {
  return {
    type: 'SET_BOARD',
    board,
  };
}
