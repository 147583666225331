import React, { useState, useEffect, useMemo, SetStateAction, Dispatch } from 'react';
import Modal from 'components/modal/Modal';
import { moneyFormat } from 'helpers/NumberFormat';
import { OrderProduct } from 'types/order';
import { Product } from 'types/product';
import { ProductSimpleProvider } from '../../../../steps/products/detail/hooks/useProduct';
import ProductSimpleDetail from '../../../../steps/products/detail/simple/ProductSimpleDetail';
import { useOrderResumeProducts } from '../../../hooks/useOrderResumeProducts';
import OrderResumeProductUpdate from '../action/OrderResumeProductUpdate';
import { useProductAmount } from 'pages/board-management/registration/registration/steps/products/detail/addToCart/hooks/useProductAmount';
import { fetchSimpleProduct } from 'pages/board-management/registration/registration/steps/products/detail/simple/fetchSimpleProduct';

interface ProductSimpleProps {
  onExited(): void;
  hideBackdrop?: boolean;
}

export default function ProductSimple({ onExited, hideBackdrop = true }: ProductSimpleProps) {
  const { selectedProduct } = useOrderResumeProducts();
  const [product, setProduct] = useState<OrderProduct | null>(JSON.parse(JSON.stringify(selectedProduct)));
  const [additionalPrice, setAdditionalPrice] = useState(0);
  const { amount, handleAmountDown, handleAmountUp } = useProductAmount(product);

  useEffect(() => {
    if (!product) {
      return;
    }

    if (product.cadence_quantity) {
      return;
    }

    fetchSimpleProduct(product?.product_id)
      .then(product => setProduct(state => (state ? { ...state, cadence_quantity: product.cadence_quantity } : null)))
      .catch(console.error);
  }, [product]);

  console.log(product?.cadence_quantity);

  const formattedTotal = useMemo(() => {
    if (!product) {
      return moneyFormat(0);
    }

    const productPrice =
      product.promotion_activated && product.special_price ? product.special_price : product.product_price;

    const total = (productPrice + additionalPrice) * amount;
    return moneyFormat(total);
  }, [additionalPrice, amount, product]);

  useEffect(() => {
    if (product) {
      setAdditionalPrice(
        product.additional.reduce(
          (value, additional) => (additional.selected ? value + additional.price * additional.amount : value),
          0
        )
      );
    }
  }, [product, amount]);

  function handleClickIngredient(ingredientId: number) {
    setProduct(state => {
      if (!state) {
        return null;
      }

      return {
        ...state,
        ingredients: state.ingredients.map(ingredient => {
          if (ingredient.id === ingredientId) {
            ingredient.selected = !ingredient.selected;
          }

          return ingredient;
        }),
      };
    });
  }

  function handleClickAdditional(additionalId: number, amount: number) {
    setProduct(state => {
      if (!state) {
        return null;
      }

      return {
        ...state,
        additional: state.additional.map(additional => {
          if (additional.id === additionalId) {
            additional.selected = amount > 0;
            additional.amount = amount;
          }
          return additional;
        }),
      };
    });
  }

  return (
    <Modal
      maxWidth="lg"
      height="80vh"
      title="Atualizar produto"
      backgroundColor="#fafafa"
      onExited={onExited}
      displayBottomActions
      hideBackdrop={hideBackdrop}
    >
      <ProductSimpleProvider
        value={{
          handleClickAdditional,
          handleClickIngredient,
          product: product as Product,
          setProduct: setProduct as Dispatch<SetStateAction<Product | null>>,
        }}
      >
        <ProductSimpleDetail />
      </ProductSimpleProvider>

      <OrderResumeProductUpdate
        handleAmountDown={handleAmountDown}
        amount={amount}
        handleAmountUp={handleAmountUp}
        product={product}
        total={formattedTotal}
      />
    </Modal>
  );
}
