import { TableTemplate } from 'types/tableTemplate';

export const fiscalSerialTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 70,
  },
  {
    id: 'sequence',
    description: 'SEQUÊNCIA',
    originalId: 'sequence',
    width: 150,
  },
  {
    id: 'formattedType',
    description: 'TIPO',
    originalId: 'type',
    width: 100,
  },
  {
    id: 'serial',
    description: 'SÉRIE',
    originalId: 'serial',
    width: 100,
  },
  {
    id: 'formattedActive',
    description: 'ATIVO',
    originalId: 'active',
    width: 100,
  },
  {
    id: 'formattedCreatedAt',
    description: 'CRIADO EM',
    originalId: 'created_at',
    width: 350,
  },
];
