import React, { MouseEvent } from 'react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@material-ui/icons';
import { CashRegister } from 'types/cashRegister';
import { useCashRegisters } from 'pages/cash-registers/hooks/useCashRegisters';

const useStyles = makeStyles({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
  opened: { backgroundColor: '#ffc107', color: '#000', padding: '4px 10px' },
  closed: { backgroundColor: '#6c757d', color: '#fff', padding: '4px 10px' },
});

type CashRegisterItemTableProps = {
  cashRegister: CashRegister;
  setAnchorEl(el: HTMLButtonElement | null): void;
};

const CashRegisterItemTable: React.FC<CashRegisterItemTableProps> = ({ cashRegister, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedCashRegister } = useCashRegisters();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedCashRegister(cashRegister);
    setAnchorEl(e.currentTarget);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton key={item.id} className={classes.iconButton} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : item.id === 'status' ? (
            <div key={item.id} className={cashRegister.closed_at ? classes.closed : classes.opened}>
              <Typography variant="body2">{cashRegister.status}</Typography>
            </div>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{cashRegister[item.id as keyof CashRegister]}</Typography>
            </div>
          )
        )}
    </>
  );
};

export default CashRegisterItemTable;
