import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomAppbar from 'components/appbar/Appbar';
import { Button, IconButton, InputAdornment, TextField } from '@material-ui/core';
import NoData from 'components/nodata/NoData';
import history from 'services/history';
import { api } from 'services/api';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import FilterBox from 'components/filter-box/FilterBox';
import { Close, Search } from '@material-ui/icons';
import useTableOrder from 'hooks/tableOrder';
import { useApp } from 'App';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import TableLoading from 'components/loading/TableLoading';
import PaginationProvider from 'hooks/pagination';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ModuleLoading from 'components/loading/ModuleLoading';
import ApiPagination from 'components/_pagination/ApiPagination';
import { PushNotificationsProvider } from './hooks/usePushNotifications';
import PushNotificationListTable from './list/table/PushNotificationListTable';
import PushNotificationListModule from './list/module/PushNotificationListModule';
import { PushNotification } from 'types/pushNotification';
import PushNotificationsActions from './PushNotificationsActions';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  filter: {
    display: 'grid',
    gridTemplateColumns: '300px',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

let timer: NodeJS.Timeout;

const PushNotificationPage: React.FC = () => {
  const classes = useStyles();
  const [pushNotifications, setPushNotifications] = useState<PushNotification[]>([]);
  const [loading, setLoading] = useState(false);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<PushNotification[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const app = useApp();
  const [selectedPushNotification, setSelectedPushNotification] = useState<PushNotification | null>(null);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(20);
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const [total, setTotal] = useState(0);

  const fetchNotifications = useCallback(
    (query: string) => {
      setLoading(true);

      api
        .get('/pushNotifications', { params: { q: query, page: page + 1, rows } })
        .then(response => {
          const pushes: PushNotification[] = response.data.data;
          setTotal(response.data.total);

          setPushNotifications(
            pushes.map(push => {
              push.formattedDeliveryDate = push.delivery_date
                ? format(parseISO(push.delivery_date), 'PPp', { locale: ptBR })
                : '';
              push.formattedCreatedAt = format(parseISO(push.created_at), 'PPp', { locale: ptBR });
              push.formattedDelivered = push.delivered ? 'Sim' : 'Não';
              return push;
            })
          );
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    },
    [rows, page]
  );

  useEffect(() => {
    fetchNotifications('');
  }, [fetchNotifications]);

  useEffect(() => setFiltered(pushNotifications), [pushNotifications]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleSearch(value: string) {
    setSearchValue(value);
    clearTimeout(timer);

    if (value.length > 0 && value.length <= 2) {
      return;
    }

    timer = setTimeout(() => fetchNotifications(value), 500);
  }

  return (
    <PushNotificationsProvider value={{ pushNotifications, setSelectedPushNotification, selectedPushNotification }}>
      <CustomAppbar title="Push Notifications" ActionComponents={<PushNotificationsActions />} />
      <PageHeaderActions
        title="Push Notifications"
        description="Gestão das notificações push"
        ActionComponent={
          <Button
            color="primary"
            variant="contained"
            onClick={() => history.push('/push-notifications/new')}
            size="small"
          >
            Adicionar
          </Button>
        }
      />
      <FilterBox>
        <div className={classes.filter}>
          <TextField
            inputRef={inputSearchRef}
            label="Pesquisar"
            placeholder="Título da mensagem"
            value={searchValue}
            onChange={e => handleSearch(e.target.value)}
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: searchValue && (
                <InputAdornment position="start">
                  <IconButton size="small" onClick={() => handleSearch('')}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <DisplayModeButtons
          displayMode={displayMode}
          handleShowList={() => setDisplayMode('list')}
          handleShowModule={() => setDisplayMode('module')}
        />
      </FilterBox>
      <PaginationProvider>
        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum adicional para mostrar" />
        ) : (
          <div className={classes.container}>
            {displayMode === 'list' ? (
              <PushNotificationListTable
                pushNotifications={filtered}
                handleSort={handleSort}
                orderedIndex={orderedIndex}
              />
            ) : (
              displayMode === 'module' && <PushNotificationListModule pushNotifications={filtered} />
            )}
            <ApiPagination
              onChangePage={value => setPage(value)}
              onChangeRowsPerPage={value => setRows(value)}
              count={total}
            />
          </div>
        )}
      </PaginationProvider>
    </PushNotificationsProvider>
  );
};

export default PushNotificationPage;
