import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Invoice } from 'types/invoice';
import { Plan } from 'types/plan';
import { RestaurantCreditCard } from 'types/restaurant';

export type BillingContextValue = {
  handleGenerateBoleto(invoiceId: number): void;
  creditCard: RestaurantCreditCard | null;
  setCreditCard(creditCard: RestaurantCreditCard | null): void;
  plans: Plan[];
  handleGeneratePix(invoiceId: number): void;
  selectedInvoice: Invoice | null;
  setSelectedInvoice: Dispatch<SetStateAction<Invoice | null>>;
  handleInvoicePaid(invoiceId: number): void;
};

const BillingContext = createContext<BillingContextValue>({} as BillingContextValue);
export const BillingProvider = BillingContext.Provider;

export function useBilling(): BillingContextValue {
  const context = useContext(BillingContext);
  return context;
}
