import { IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import CustomAppbar from 'components/appbar/Appbar';
import FilterBox from 'components/filter-box/FilterBox';
import TableLoading from 'components/loading/TableLoading';
import NoData from 'components/nodata/NoData';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import React, { useEffect, useRef, useState } from 'react';
import { api } from 'services/api';
import { Product } from 'types/product';
import { ProductManagerContextProvider } from './hooks/useProductManager';
import ProductManagerList from './ProductManagerList';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  content: {},
  inputContent: {
    maxWidth: 600,
    display: 'flex',
    flex: 1,
  },
});

let timer: NodeJS.Timeout;

const ProductManager: React.FC = () => {
  const classes = useStyles();
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!searchValue) {
      setProducts([]);
      return;
    }

    if (searchValue.length < 3) return;

    clearTimeout(timer);

    function handleSearch() {
      setLoading(true);
      setProducts([]);

      api
        .get('/product-complement/search', { params: { query: searchValue } })
        .then(response => {
          setProducts(response.data);
        })
        .catch(err => console.error(err))
        .finally(() => setLoading(false));
    }

    timer = setTimeout(handleSearch, 500);
  }, [searchValue]);

  return (
    <ProductManagerContextProvider value={{ products, setProducts }}>
      <div className={classes.container}>
        <CustomAppbar title="Produtos e Complementos" />
        <PageHeaderActions
          title="Produtos e complementos"
          description="Gerencie a disponibilidade de produtos e complementos no cardápio"
        />
        <FilterBox>
          <div className={classes.inputContent}>
            <TextField
              inputRef={inputSearchRef}
              label="Pesquisar"
              placeholder="Digite sua pesquisa"
              autoFocus
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              fullWidth
              helperText={loading ? 'procurando...' : ''}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment:
                  searchValue.length > 1 ? (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={() => setSearchValue('')}>
                        <Close />
                      </IconButton>
                    </InputAdornment>
                  ) : undefined,
              }}
            />
          </div>
        </FilterBox>
        {loading ? (
          <TableLoading />
        ) : products.length === 0 ? (
          <NoData backgroundColor="inherit" message="Nenhum produto para mostrar" />
        ) : (
          <div className={classes.content}>
            <ProductManagerList products={products} />
          </div>
        )}
      </div>
    </ProductManagerContextProvider>
  );
};

export default ProductManager;
