import { ListItem, makeStyles } from '@material-ui/core';
import React from 'react';

const styles = makeStyles(theme => ({
  preLoading1: {
    padding: 6,
    width: '40%',
    backgroundColor: '#eee',
    marginBottom: 7,
  },
  preLoading2: {
    height: 24,
    width: '30%',
    backgroundColor: '#eee',
    marginBottom: 20,
  },
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    paddingTop: 15,
    height: 82,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 150,
  },
  content: {
    display: 'flex',
    maxWidth: 1000,
    width: '100%',
    margin: '30px auto',
    border: '1px solid #eee',
    padding: '20px 40px',
    backgroundColor: '#fff',
    flexDirection: 'column',
    marginTop: 100,
    gap: 10,
    '& li:not(:last-child)': {
      borderBottom: '1px solid #f5f5f5',
    },
  },
  currentValue: {
    height: 40,
    width: 200,
    margin: '0 auto 50px',
  },
}));

const CashRegisterLoading: React.FC = () => {
  const classes = styles();

  const items = 5;

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={`animated-background ${classes.currentValue}`} />

        {Array.from(Array(items).keys()).map(item => (
          <ListItem component="li" key={item} className={classes.listItem}>
            <div className={`animated-background ${classes.preLoading1}`} />
            <div className={`animated-background ${classes.preLoading2}`} />
          </ListItem>
        ))}
      </div>
    </div>
  );
};

export default CashRegisterLoading;
