import React, { useEffect, useState } from 'react';
import AddressesList from './AddressList';
import { Typography, Button, makeStyles } from '@material-ui/core';
import { api } from 'services/api';
import { useDispatch } from 'react-redux';
import {
  setShipmentMethod,
  setShipmentAddress,
  addCustomerAddress,
  updateCustomerAddress,
} from 'store/redux/modules/order/actions';
import ShipmentCollectSchedule from './collect-schedule/ShipmentCollectSchedule';
import { useMessaging } from 'hooks/messaging';
import { useOrder } from 'pages/board-management/registration/registration/hooks/useOrder';
import { useSelector } from 'store/redux/selector';
import { CustomerAddress } from 'types/customerAddress';
import CustomerAddressNew from 'pages/customers/registration/edit/addresses/registration/new/CustomerAddressNew';
import DialogDelete from 'components/dialog/delete/DialogDelete';
import { deleteCustomerAddress } from 'store/modules/customers/actions';
import { OrderShipmentProvider } from './hook/useOrderShipment';
import CustomerAddressEdit from 'pages/customers/registration/edit/addresses/registration/edit/CustomerAddressEdit';
import NewAddressGoogleMaps from 'pages/customers/registration/edit/addresses/registration/places-api/new/NewAddress';
import EditAddressGoogleMaps from 'pages/customers/registration/edit/addresses/registration/places-api/edit/EditAddress';
import ShipmentDeliverySchedule from './delivery-schudule/ShipmentDeliverySchedule';

const useStyles = makeStyles({
  noAddresses: {
    padding: 15,
  },
  button: {
    padding: 0,
  },
});

const Shipment: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { handleNext, setStep } = useOrder();
  const messaging = useMessaging();
  const { customer } = useSelector(state => state.order);
  const [saving, setSaving] = useState(false);
  const [dialogCollectSchedule, setDialogSchecule] = useState(false);
  const restaurant = useSelector(state => state.restaurant);
  const [selectedAddress, setSelectedAddress] = useState<CustomerAddress | null>(null);
  const [dialogNewAddress, setDialogNewAddress] = useState(false);
  const [dialogEditAddress, setDialogEditAddress] = useState(false);
  const [dialogDeleteAddress, setDialogDeleteAddress] = useState(false);
  const order = useSelector(state => state.order);
  const [dialogDeliverySchedule, setDialogDeliverySchedule] = useState(false);

  useEffect(() => {
    setDialogNewAddress(!!customer && !customer?.addresses.length);
  }, [customer]);

  function handleSelectAddress(address: CustomerAddress) {
    if (!address.area_region && restaurant?.configs.tax_mode === 'district') {
      messaging.handleOpen('Esse endereço precisa ser atualizado');
      setSelectedAddress(address);
      setDialogEditAddress(true);
      return;
    }

    dispatch(setShipmentMethod('delivery'));
    dispatch(setShipmentAddress(address));

    if (restaurant?.configs.allow_schedule_delivery) {
      setDialogDeliverySchedule(true);
      return;
    }

    handleNext();
  }

  async function handleAddressSubmit(data: CustomerAddress, areaRegionId?: number | null) {
    try {
      setSaving(true);

      const response = await api.post(`/customers/${customer?.id}/addresses`, {
        ...data,
        area_region_id: areaRegionId ?? null,
      });

      dispatch(addCustomerAddress(response.data));
      dispatch(setShipmentAddress(response.data));
      dispatch(setShipmentMethod('delivery'));
    } finally {
      setSaving(false);
    }
  }

  async function handleAddressUpdateSubmit(data: CustomerAddress, areaRegionId?: number | null) {
    try {
      setSaving(true);

      const response = await api.put(`/customers/${customer?.id}/addresses/${selectedAddress?.id}`, {
        ...data,
        area_region_id: areaRegionId ?? null,
      });

      // dispatch(setShipmentAddress(response.data));
      dispatch(updateCustomerAddress(response.data));
      dispatch(setShipmentMethod('delivery'));
    } finally {
      setSaving(false);
    }
  }

  function handleSetShipmentCustomerCollect() {
    dispatch(setShipmentMethod('customer_collect'));

    if (restaurant?.configs.shipment_schedule) {
      setDialogSchecule(true);
      return;
    }

    handleNext();
  }

  function handleCloseCollectScheduleDialog() {
    setDialogSchecule(false);
    handleNext();
  }

  function handleCloseDeliveryScheduleDialog() {
    setDialogDeliverySchedule(false);
    handleNext();
  }

  function handleSetStepCustomer() {
    setStep(1);
  }

  function handleConfirmDelete() {
    setSaving(true);
    api
      .delete(`/customers/${customer?.id}/addresses/${selectedAddress?.id}`)
      .then(() => {
        dispatch(deleteCustomerAddress(selectedAddress?.id as number));

        if (order?.shipment?.id === selectedAddress?.id) {
          dispatch(setShipmentAddress(null));
        }
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.error);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <OrderShipmentProvider
      value={{
        selectedAddress,
        setSelectedAddress,
        handleAddressSubmit,
        handleAddressUpdateSubmit,
        setDialogDeleteAddress,
        setDialogEditAddress,
        setDialogNewAddress,
        setDialogSchecule,
        handleSetShipmentCustomerCollect,
        handleSelectAddress,
      }}
    >
      {dialogCollectSchedule && <ShipmentCollectSchedule onExited={handleCloseCollectScheduleDialog} />}

      {dialogDeliverySchedule && <ShipmentDeliverySchedule onExited={handleCloseDeliveryScheduleDialog} />}

      {dialogNewAddress && restaurant?.configs.use_google_map_addresses && (
        <NewAddressGoogleMaps
          handleAddressSubmit={handleAddressSubmit}
          onExited={() => setDialogNewAddress(false)}
          saving={saving}
        />
      )}

      {dialogNewAddress && !restaurant?.configs.use_google_map_addresses && (
        <CustomerAddressNew
          handleAddressSubmit={handleAddressSubmit}
          onExited={() => setDialogNewAddress(false)}
          saving={saving}
        />
      )}

      {dialogEditAddress && selectedAddress && restaurant?.configs.use_google_map_addresses && (
        <EditAddressGoogleMaps
          handleAddressUpdateSubmit={handleAddressUpdateSubmit}
          selectedAddress={selectedAddress}
          onExited={() => setDialogEditAddress(false)}
          saving={saving}
        />
      )}

      {dialogEditAddress && selectedAddress && !restaurant?.configs.use_google_map_addresses && (
        <CustomerAddressEdit
          handleAddressUpdateSubmit={handleAddressUpdateSubmit}
          selectedAddress={selectedAddress}
          handleModalState={() => setDialogEditAddress(false)}
          saving={saving}
        />
      )}

      {dialogDeleteAddress && (
        <DialogDelete
          title="Excluir endereço"
          message="Deseja realmente excluir esse endereço?"
          onExited={() => setDialogDeleteAddress(false)}
          handleDelete={handleConfirmDelete}
          buttonText="Sim, excluir"
        />
      )}

      {customer ? (
        <AddressesList addresses={customer.addresses} />
      ) : (
        <div className={classes.noAddresses}>
          <Typography gutterBottom variant="body1">
            Você precisa selecionar o cliente
          </Typography>

          <Button color="primary" variant="text" className={classes.button} onClick={handleSetStepCustomer}>
            Ir para o cliente
          </Button>
        </div>
      )}
    </OrderShipmentProvider>
  );
};

export default Shipment;
