import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useCashRegisterFlow } from './hooks/useCashRegisterFlow';
import { Done, MoreVert } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

interface CashRegisterAppbarButtonsProps {
  loading: boolean;
}

const CashRegisterAppbarButtons: React.FC<CashRegisterAppbarButtonsProps> = ({ loading }) => {
  const classes = useStyles();
  const {
    setIsOpenedDebitDialog,
    setIsOpenedCloseCashRegisterDialog,
    setIsOpenedExtractReport,
    setIsOpenedReport,
    cashRegister,
  } = useCashRegisterFlow();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function handleCloseCashRegister() {
    if (cashRegister?.closed_at) {
      return;
    }

    setIsOpenedCloseCashRegisterDialog(true);
  }

  function handleExtractPrintClick() {
    setIsOpenedExtractReport(true);
    setAnchorEl(null);
  }

  function handlePrintReportClick() {
    setIsOpenedReport(true);
    setAnchorEl(null);
  }

  return (
    <div className={classes.container}>
      <Menu onClose={() => setAnchorEl(null)} open={!!anchorEl} anchorEl={anchorEl}>
        <MenuItem onClick={handlePrintReportClick}>Imprimir fechamento</MenuItem>
        <MenuItem disabled={loading} onClick={handleExtractPrintClick}>
          Imprimir extrato
        </MenuItem>
      </Menu>

      <IconButton color="inherit" onClick={() => setIsOpenedDebitDialog(true)}>
        <AddIcon />
      </IconButton>

      <IconButton color="inherit" onClick={handleCloseCashRegister}>
        <Done />
      </IconButton>

      <IconButton color="inherit" onClick={event => setAnchorEl(event.currentTarget)}>
        <MoreVert />
      </IconButton>
    </div>
  );
};

export default CashRegisterAppbarButtons;
