import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

function IngredientAction(props) {
  const { handleSubmit } = props;
  return (
    <Tooltip title={'Salvar'}>
      <IconButton onClick={handleSubmit} color={'inherit'}>
        <DoneIcon />
      </IconButton>
    </Tooltip>
  );
}

export default IngredientAction;
