import React, { useReducer, useState, useEffect } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import { Grid } from '@material-ui/core';
import PageHeader from 'components/page-header/PageHeader';
import DelivererForm from './DelivererForm';
import delivererReducer, { INITIAL_STATE as delivererInitialState } from 'store/modules/deliverer/reducer';
import { delivererChange, setDeliverer } from 'store/modules/deliverer/actions';
import * as yup from 'yup';
import { api } from 'services/api';
import Loading from 'components/loading/Loading';
import DelivererAction from './DelivererAction';
import history from 'services/history';
import { useParams } from 'react-router-dom';
import { useMessaging } from 'hooks/messaging';
import InsideLoading from 'components/loading/InsideLoading';

export default function Deliverer() {
  const [deliverer, dispatch] = useReducer(delivererReducer, delivererInitialState);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const messaging = useMessaging();
  const [validation, setValidation] = useState({});
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    api
      .get(`/deliverers/${params.id}`)
      .then(response => {
        dispatch(setDeliverer(response.data));
      })
      .catch(err => {
        if (err.response) {
          messaging.handleOpen(err.response.data.err);
          if (err.response.status === 404) history.push('/deliverers');
        } else messaging.handleOpen('Erro ao carregar o entregador');
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  function handleChange(index, value) {
    dispatch(delivererChange(index, value));
  }

  function handleFormValidation(event) {
    event.preventDefault();

    const schema = yup.object().shape({
      email: yup.string().email('Informe um e-mail válido').nullable(true),
      phone: yup.string().required('O telefone do entregador é obrigatório'),
      name: yup.string().required('O nome do entregador é obrigatório'),
    });

    schema
      .validate(deliverer)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);
    api
      .put(`/deliverers/${params.id}`, deliverer)
      .then(() => {
        messaging.handleOpen('Salvo');
        history.push('/deliverers');
      })
      .catch(err => {
        if (err.response.data.error) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível salvar o entregador');
        setSaving(false);
      });
  }
  return (
    <>
      {saving && <Loading />}
      <CustomAppbar
        title="Novo entregador"
        ActionComponents={<DelivererAction handleFormValidation={handleFormValidation} />}
      />
      {loading ? (
        <InsideLoading />
      ) : (
        <form onSubmit={handleFormValidation}>
          <Grid container>
            <PageHeader title="Novo entregador" />
            <Grid item xs={12} xl={4} lg={4} md={5}>
              <DelivererForm deliverer={deliverer} handleChange={handleChange} validation={validation} />
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
}
