import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useCategories } from '../hooks/useCategories';

type CategoryListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl(anchorEl: null | HTMLButtonElement): void;
};

const CategoryListMenu: React.FC<CategoryListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedCategory, handleCategoryActive, setIsOpenDeleteConfirmation } = useCategories();

  function handleActiveClick() {
    setAnchorEl(null);
    handleCategoryActive();
  }

  function handleDeleteClick() {
    setIsOpenDeleteConfirmation(true);
    setAnchorEl(null);
  }

  return (
    <>
      {selectedCategory && (
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={handleActiveClick}>
            {selectedCategory.activated ? 'Pausar vendas' : 'Ativar vendas'}
          </MenuItem>
          <MenuItem onClick={handleDeleteClick}>Excluir</MenuItem>
        </Menu>
      )}
    </>
  );
};

export default CategoryListMenu;
