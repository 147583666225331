import { createContext, useContext, Dispatch, SetStateAction } from 'react';
import { CustomerAddress } from 'types/customerAddress';

export interface OrderShipmentContextValue {
  selectedAddress: CustomerAddress | null;
  handleAddressSubmit(address: CustomerAddress): void;
  handleAddressUpdateSubmit(address: CustomerAddress): void;
  setSelectedAddress: Dispatch<SetStateAction<CustomerAddress | null>>;
  setDialogNewAddress: Dispatch<SetStateAction<boolean>>;
  setDialogEditAddress: Dispatch<SetStateAction<boolean>>;
  setDialogDeleteAddress: Dispatch<SetStateAction<boolean>>;
  setDialogSchecule: Dispatch<SetStateAction<boolean>>;
  handleSetShipmentCustomerCollect(): void;
  handleSelectAddress(address: CustomerAddress): void;
}

const OrderShipmentContext = createContext<OrderShipmentContextValue>({} as OrderShipmentContextValue);
export const OrderShipmentProvider = OrderShipmentContext.Provider;
export const OrderShipmentConsumer = OrderShipmentContext.Consumer;

export function useOrderShipment(): OrderShipmentContextValue {
  return useContext(OrderShipmentContext);
}
