import { useEffect, useState } from 'react';
import { Socket } from 'socket.io-client';

export function usePrinterConnectionStatus(socket: Socket) {
  const [lastPing, setLastPing] = useState<Date | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [version, setVersion] = useState('');

  useEffect(() => {
    const timer = setInterval(() => {
      if (!lastPing) {
        return;
      }

      const difference = Date.now() - lastPing.getTime();

      if (difference >= 60000) {
        setIsConnected(false);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [lastPing]);

  useEffect(() => {
    socket.on('printer_ping', response => {
      setIsConnected(true);
      setLastPing(new Date());
      setVersion(response.version ?? '');
    });

    socket.on('printer_exited', response => {
      setIsConnected(false);
    });
  }, [socket]);

  return {
    isConnected,
    version,
  };
}
