import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { Address } from 'types/address';
import { AddressValidation } from './validation/useAddressValidation';
import { useSelector } from 'store/redux/selector';
import { api } from 'services/api';
import { AreaRegion } from 'types/area';
import { moneyFormat } from 'helpers/NumberFormat';
import InsideSaving from 'components/loading/InsideSaving';
import { Autocomplete } from '@material-ui/lab';

const styles = makeStyles({
  street: {
    display: 'grid',
    gridTemplateColumns: '30% 1fr',
    gap: '15px',
  },
});

interface FormProps {
  validation: AddressValidation;
  handleChange(index: keyof Address, value: string | null): void;
  address: Address;
  areaRegionId: number | null;
  setAreaRegionId: Dispatch<SetStateAction<number | null>>;
}

const Form: React.FC<FormProps> = ({ validation, handleChange, address, areaRegionId, setAreaRegionId }) => {
  const restaurant = useSelector(state => state.restaurant);
  const classes = styles();
  const [districts, setDistricts] = useState<AreaRegion[]>([]);
  const [loading, setLoading] = useState(false);
  const [district, setDisitrict] = useState<AreaRegion | null>(null);

  useEffect(() => {
    if (!areaRegionId) {
      return;
    }

    setDisitrict(districts.find(item => item.id === areaRegionId) ?? null);
  }, [districts, areaRegionId]);

  const inputs = {
    address: useRef<HTMLInputElement>(null),
    number: useRef<HTMLInputElement>(null),
    district: useRef<HTMLInputElement>(null),
    region: useRef<HTMLInputElement>(null),
    city: useRef<HTMLInputElement>(null),
    complement: useRef<HTMLInputElement>(null),
    areaRegionId: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  useEffect(() => {
    if (restaurant?.configs.tax_mode !== 'district') {
      return;
    }

    setLoading(true);

    api
      .get<AreaRegion[]>('/areaRegions')
      .then(response => {
        setDistricts(
          response.data.map(neighborhood => {
            neighborhood.formattedTax = moneyFormat(neighborhood.tax);
            return neighborhood;
          })
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [restaurant]);

  function handleSelectAreaRegion(district: AreaRegion | null) {
    setDisitrict(district);

    console.log('handle change district');

    if (!district) {
      setAreaRegionId(null);
      handleChange('district', '');
      return;
    }

    handleChange('district', district.name);
    setAreaRegionId(district.id);
  }

  return (
    <div>
      {loading && <InsideSaving />}

      <TextField
        error={!!validation.address}
        helperText={!!validation.address && validation.address}
        label="Endereço"
        placeholder="Digite o endereço"
        margin="normal"
        fullWidth
        value={address.address}
        onChange={
          restaurant?.configs.checkout_allow_change_address
            ? event => handleChange('address', event.target.value)
            : undefined
        }
        autoCapitalize="words"
        autoComplete="address"
      />
      <div className={classes.street}>
        <TextField
          inputRef={inputs.number}
          error={!!validation.number}
          helperText={validation.number}
          label="Número"
          placeholder="Digite o número"
          margin="normal"
          fullWidth
          value={address.number}
          onChange={event => handleChange('number', event.target.value)}
        />

        {restaurant?.configs.tax_mode === 'district' ? (
          <Autocomplete
            id="neighborhood"
            options={districts}
            renderInput={params => (
              <TextField
                {...params}
                inputRef={inputs.district}
                helperText={validation.areaRegionId || validation.district}
                error={!!validation.areaRegionId || !!validation.district}
                margin="normal"
                placeholder="Bairro"
                label="Selecione o bairro"
              />
            )}
            onChange={(event, value) => handleSelectAreaRegion(value)}
            value={district}
            getOptionLabel={option => `${option.name} - ${option.formattedTax}`}
            fullWidth
          />
        ) : (
          <TextField
            inputRef={inputs.district}
            error={!!validation.district}
            helperText={validation.district}
            label="Bairro"
            placeholder="Digite o bairro"
            margin="normal"
            fullWidth
            value={address.district}
            onChange={event => handleChange('district', event.target.value)}
          />
        )}
      </div>

      <TextField
        label="Complemento"
        placeholder="Digite o complemento"
        margin="normal"
        fullWidth
        value={address.complement || ''}
        onChange={event => handleChange('complement', event.target.value)}
      />

      <TextField
        label="Ponto de referência"
        placeholder="Informe um ponto de referência"
        margin="normal"
        fullWidth
        value={address.reference_point}
        onChange={event => handleChange('reference_point', event.target.value)}
      />

      <button type="submit" style={{ display: 'none' }} />
    </div>
  );
};

export default Form;
