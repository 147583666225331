import React, { useState, useEffect } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import TaxRulesAppbarButtons from './FiscalSerialAppbarButtons';
import PageHeader from 'components/page-header/PageHeader';
import { makeStyles } from '@material-ui/core';
import FiscalSerialRuleForm from '../FiscalSerialRuleForm';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import history from 'services/history';
import Loading from 'components/loading/Loading';
import { useParams } from 'react-router-dom';
import InsideLoading from 'components/loading/InsideLoading';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { FiscalSerial } from 'pages/fiscal-serials/types/fiscal-serial';
import { useFiscalSerialReducer } from '../reducer/reducer';
import { useFiscalSerialValidation } from '../validation/useTaxRuleValidation';
import { fiscalSerialChange, setFiscalSerial } from '../reducer/actions';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 400,
  },
});

const FiscalSerialEdit: React.FC = () => {
  const classes = styles();
  const [serial, dispatch] = useFiscalSerialReducer();
  const [validation, setValidation, validate] = useFiscalSerialValidation();
  const messaging = useMessaging();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: string }>();
  const { showErrorDialog } = useErrorHandler();

  useEffect(() => {
    api
      .get(`/fiscal-serials/${id}`)
      .then(response => {
        dispatch(setFiscalSerial(response.data));
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível salvar',
        });
      })
      .finally(() => setLoading(false));
  }, [id, dispatch, showErrorDialog]);

  function handleValidation() {
    setValidation({});

    validate(serial)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/fiscal-serials/${id}`, serial)
      .then(() => {
        messaging.handleOpen('Salvo');
        setSaving(false);
        history.push('/fiscal-serials');
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível salvar',
        });
        setSaving(false);
      });
  }

  function handleChange(key: keyof FiscalSerial, value: any) {
    dispatch(fiscalSerialChange(key, value));
  }

  return (
    <>
      {saving && <Loading />}

      <CustomAppbar title="Série fiscal" ActionComponents={<TaxRulesAppbarButtons handleSubmit={handleValidation} />} />

      <PageHeader title="Editar série fiscal" backAction={() => history.push('/fiscal-serials')} />

      {loading ? (
        <InsideLoading />
      ) : (
        <div className={classes.container}>
          <FiscalSerialRuleForm type="update" handleChange={handleChange} serial={serial} validation={validation} />
        </div>
      )}
    </>
  );
};

export default FiscalSerialEdit;
