import { Button, Typography } from '@material-ui/core';
import React from 'react';

interface OrderErrorProps {
  errorText: string;
  handleResetError(): void;
}

const OrderError: React.FC<OrderErrorProps> = ({ errorText, handleResetError }) => {
  return (
    <div>
      <Typography>{errorText}</Typography>
      <Button variant="contained" color="primary" onClick={handleResetError}>
        Tentar novamente
      </Button>
    </div>
  );
};

export default OrderError;
