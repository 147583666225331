import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';

export const OpeningHourAction = props => {
  const { handleOpeningHourSubmit, saving } = props;
  return (
    <IconButton disabled={saving} onClick={handleOpeningHourSubmit} color={'inherit'}>
      <DoneIcon />
    </IconButton>
  );
};
