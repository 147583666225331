import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, List, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: 6,
    padding: '10px 0',
  },
  listItem: {
    display: 'flex',
    backgroundColor: '#fff',
    border: '1px solid #eee',
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    alignItems: 'flex-start',
    minHeight: 75,
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

ComplementAdditionalImportList.propTypes = {
  complements: PropTypes.array.isRequired,
  handleImportAdditional: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};

export default function ComplementAdditionalImportList({ complements, handleImportAdditional, handleCloseDialog }) {
  const classes = useStyles();

  function handleClick(complementId) {
    handleImportAdditional(complementId, handleCloseDialog);
  }

  return (
    <List className={classes.list}>
      {complements.map(complement => (
        <ListItem button key={complement.id} onClick={() => handleClick(complement.id)} className={classes.listItem}>
          <Typography variant="h6">{complement.name}</Typography>
          <Typography color="textSecondary" variant="body2">
            {complement.description}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
}
