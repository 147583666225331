import React, { useState } from 'react';
import List from 'components/list/List';
import CategoryItemModule from './CustomerItemModule';
import CategoryListMenu from '../CustomersListMenu';
import { Customer } from 'types/customer';

type CustomerListModuleProps = {
  customers: Customer[];
};

const CustomerListModule: React.FC<CustomerListModuleProps> = ({ customers }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <CategoryListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <List>
        {customers.map(customer => (
          <CategoryItemModule key={customer.id} customer={customer} />
        ))}
      </List>
    </>
  );
};

export default CustomerListModule;
