import React, { useReducer, useState } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import IngredientAction from 'pages/ingredients/registration/new/IngredientAction';
import { Grid } from '@material-ui/core';
import IngredientForm from 'pages/ingredients/registration/new/IngredientForm';
import Loading from 'components/loading/Loading';
import { useMessaging } from 'hooks/messaging';
import PageHeader from 'components/page-header/PageHeader';
import history from 'services/history';
import { api } from 'services/api';

const ingredientReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE':
      return {
        ...state,
        [action.index]: action.value,
      };
    default:
      return state;
  }
};

function IngredientNew() {
  const messaging = useMessaging();
  const [ingredient, dispatch] = useReducer(ingredientReducer, {
    name: '',
    activated: true,
  });
  const [loading, setLoading] = useState(false);

  function handleChange(index, value) {
    dispatch({
      type: 'CHANGE',
      index,
      value,
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    api
      .post('/ingredients', ingredient)
      .then(response => {
        messaging.handleOpen('Salvo');
        history.push('/menu/ingredients');
      })
      .catch(err => {
        if (err.respose && err.response.status === 422) {
          messaging.handleOpen('Verifique os campos obrigatórios');
        } else messaging.handleOpen('Aconteceu um erro ao salvar o ingrediente');

        setLoading(false);
      });
  }

  return (
    <>
      {loading && <Loading />}
      <form onSubmit={handleSubmit}>
        <CustomAppbar title="Novo ingrediente" ActionComponents={<IngredientAction handleSubmit={handleSubmit} />} />

        <PageHeader title="Adicionar ingrediente" backAction={() => history.push('/menu/ingredients')} />

        <Grid container>
          <IngredientForm ingredient={ingredient} handleChange={handleChange} />
        </Grid>
      </form>
    </>
  );
}

export default IngredientNew;
