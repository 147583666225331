import { useSelector } from 'store/redux/selector';
import { useEffect, useState } from 'react';
import { RestaurantConfig } from 'types/restaurant';
import { api } from 'services/api';

export function useFetchRestaurantSettings() {
  const restaurant = useSelector(state => state.restaurant);
  const [settings, setSettings] = useState<RestaurantConfig | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!restaurant) {
      return;
    }

    api
      .get(`/restaurants/${restaurant.id}/settings`)
      .then(response => {
        setSettings(response.data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [restaurant]);

  return [settings, setSettings, loading];
}
