import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { api, getCancelTokenSource } from 'services/api';
import { format, parseISO } from 'date-fns';
import { moneyFormat } from 'helpers/NumberFormat';
import ptBR from 'date-fns/locale/pt-BR';
import { CashRegisterWithMovements } from 'types/cashRegister';

export function useFetchCashRegister(
  id?: string
): [CashRegisterWithMovements | null, Dispatch<SetStateAction<CashRegisterWithMovements | null>>, boolean] {
  const [cashRegister, setCashRegister] = useState<CashRegisterWithMovements | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const source = getCancelTokenSource();
    let request = true;

    if (!id) {
      return;
    }

    api
      .get(`/cashRegisters/${id}`)
      .then(response => {
        if (!request) {
          return;
        }

        setCashRegister({
          ...response.data,
          formattedCurrentValue: moneyFormat(response.data.current_value),
          formattedInitialValue: moneyFormat(response.data.initial_value),
          formattedCreatedAt: format(parseISO(response.data.created_at), 'PPp', { locale: ptBR }),
          formattedClosedAt: response.data.closed_at
            ? format(parseISO(response.data.closed_at), 'PPp', { locale: ptBR })
            : '',
          movements: response.data.movements.map(movement => ({
            ...movement,
            formattedCreatedAt: format(parseISO(movement.created_at as any), 'PPp', { locale: ptBR }),
            formattedValue: moneyFormat(movement.value),
          })),
        });
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (request) {
          setLoading(false);
        }
        request = false;
      });

    return () => {
      if (request) source.cancel();
      request = false;
    };
  }, [id]);

  return [cashRegister, setCashRegister, loading];
}
