import React from 'react';
import List from 'components/list/List';
import { usePagination } from 'hooks/pagination';
import PushNotificationItemModule from './PushNotificationItemModule';
import { PushNotification } from 'types/pushNotification';

type PushNotificationListModuleProps = {
  pushNotifications: PushNotification[];
};

const PushNotificationListModule: React.FC<PushNotificationListModuleProps> = ({ pushNotifications }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <>
      <List>
        {pushNotifications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(pushNotification => (
          <PushNotificationItemModule key={pushNotification.id} pushNotification={pushNotification} />
        ))}
      </List>
    </>
  );
};

export default PushNotificationListModule;
