import { Dispatch, SetStateAction, createContext, useContext } from 'react';

interface ReceiptHistoriesContextValue {
  viewMore: boolean;
  setViewMore: Dispatch<SetStateAction<boolean>>;
}

const Context = createContext<ReceiptHistoriesContextValue>({} as ReceiptHistoriesContextValue);
export const ReceiptHistoriesProvider = Context.Provider;

export function useReceiptHistories(): ReceiptHistoriesContextValue {
  return useContext(Context);
}
