import React, { useState, useEffect } from 'react';
import { api } from 'services/api';
import NoData from 'components/nodata/NoData';
import PromotionCategoryList from './PromotionCategoryList';
import { promotionSetCategory } from 'store/modules/promotion/actions';
import PropTypes from 'prop-types';
import PromotionCategoryValue from './PromotionCategoryValue';
import { usePromotion } from 'pages/promotion/hooks/usePromotion';
import CategoryLoading from 'pages/categories/CategoryLoading';

PromotionCategory.propTypes = {
  promotionCategories: PropTypes.array.isRequired,
};

export default function PromotionCategory({ promotionCategories }) {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const { dispatch } = usePromotion();
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    api
      .get('/categories')
      .then(response => {
        const categories = response.data.map(category => {
          category.selected = promotionCategories.some(c => category.id === c.category_id);
          category.category_id = category.category_id || category.id;
          return category;
        });
        setCategories(categories);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCategories(
      categories.map(category => {
        category.selected = promotionCategories.some(c => category.id === c.id);
        return category;
      })
    );
    // eslint-disable-next-line
  }, [promotionCategories]);

  function handleConfirm(value) {
    dispatch(
      promotionSetCategory({
        ...selectedCategory,
        value,
      })
    );
  }

  return (
    <>
      {selectedCategory && (
        <PromotionCategoryValue
          onExited={() => setSelectedCategory(null)}
          selectedCategory={selectedCategory}
          handleConfirm={handleConfirm}
        />
      )}
      {loading ? (
        <CategoryLoading />
      ) : categories.length > 0 ? (
        <PromotionCategoryList categories={categories} handleSelect={category => setSelectedCategory(category)} />
      ) : (
        categories.length === 0 && <NoData message="Não há categorias para exibir" />
      )}
    </>
  );
}
