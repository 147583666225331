import React, { useEffect, useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Order } from 'types/order';
import PrintTypography from 'components/print-typography/PrintTypography';
import { Theme } from '@material-ui/core';
import { api, getCancelTokenSource } from 'services/api';
import DialogFullscreen from 'components/dialog/DialogFullscreen';
import OrderAction from './OrderAction';
import { useSelector } from 'store/redux/selector';
import { format, parseISO } from 'date-fns';
import { moneyFormat } from 'helpers/NumberFormat';
import { ptBR } from 'date-fns/locale';
import { formatId } from 'helpers/formatOrderId';
import Header from './shared-parts/Header';
import Address from './shared-parts/Address';
import Additional from './shared-parts/Additional';
import Ingredients from './shared-parts/Ingredients';
import ComplementCategories from './shared-parts/ComplementCategories';

interface UseStylesProps {
  fontSize: number;
  noMargin: boolean;
  maxWidth: number;
}

const useStyles = makeStyles<Theme, UseStylesProps>({
  container: props => ({
    maxWidth: `${props.maxWidth}mm`,
    minHeight: 300,
    padding: 15,
    fontSize: props.fontSize,
    backgroundColor: '#faebd7',
    border: '2px dashed #ccc',
    '@media print': {
      '&': {
        backgroundColor: 'transparent',
        border: 'none',
        padding: props.noMargin ? '0 0 0 0' : '0 0 0 10px',
        marginRight: 40,
      },
    },
  }),
  annotation: {
    marginLeft: 10,
  },
  products: {
    marginBottom: 15,
    padding: '5px 0 0',
    borderTop: '1px dashed #333',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerProducts: {
    marginTop: 7,
  },
  productName: {
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 600,
  },
  product: {
    width: '100%',
    paddingBottom: 10,
  },
  productAmount: {
    minWidth: 25,
    display: 'flex',
    paddingTop: 0,
  },
  customerData: {
    display: 'grid',
    gridTemplateColumns: '60px 1fr',
    marginBottom: 2,
    columnGap: 7,
  },
  title: {
    fontWeight: 600,
  },
  date: {
    marginBottom: 10,
  },
  complementCategory: {
    display: 'grid',
    gridTemplateColumns: '0.5fr 1fr',
  },
  totals: {
    display: 'grid',
    gridTemplateColumns: '1.5fr 1fr',
    rowGap: '4px',
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  developer: {
    marginTop: 15,
  },
  complement: {
    marginLeft: 6,
  },
  additional: {
    marginRight: 6,
  },
  ingredient: {
    marginRight: 6,
  },
  additionalInfoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '5px',
  },
});

interface OrderPrintOnlyShipmentProps {
  onExited(): void;
  orderId: string;
}

const DispatchedOrderOnly: React.FC<OrderPrintOnlyShipmentProps> = ({ onExited, orderId }) => {
  const [order, setOrder] = useState<Order | null>(null);
  const [loading, setLoading] = useState(true);
  const restaurant = useSelector(state => state.restaurant);
  const classes = useStyles({
    fontSize: restaurant?.printer_settings?.font_size || 14,
    noMargin: !!restaurant?.printer_settings?.no_margin,
    maxWidth: restaurant?.printer_settings?.max_width || 80,
  });

  useEffect(() => {
    let request = true;
    const cancelToken = getCancelTokenSource();

    api
      .get(`/orders/${orderId}`, { cancelToken: cancelToken.token })
      .then(response => {
        if (!request) {
          return;
        }

        setOrder(handleOrder(response.data));
        setTimeout(window.print, 500);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        if (request) {
          setLoading(false);
          window.print();
        }
      });

    return () => {
      if (request) cancelToken.cancel();
      request = false;
    };
  }, [orderId]);

  function handleOrder(order: Order): Order {
    const date = parseISO(order.created_at);

    const shipment = order.shipment
      ? {
          ...order.shipment,
          formattedScheduledAt: order.shipment?.scheduled_at
            ? format(parseISO(order.shipment.scheduled_at as string), 'HH:mm')
            : '',
        }
      : null;

    return {
      ...order,
      shipment,
      formattedId: formatId(order.id),
      formattedSequence: formatId(order.sequence),
      formattedTotal: moneyFormat(order.total),
      formattedChange: moneyFormat(order.change - order.total),
      formattedCreatedAt: format(date, "PP, 'às' p", { locale: ptBR }),
      formattedTax: moneyFormat(order.tax),
      formattedDiscount: moneyFormat(order.discount),
      formattedChangeTo: moneyFormat(order.change),
      products: order.products.map(product => {
        product.formattedFinalPrice = moneyFormat(product.final_price);
        return product;
      }),
    };
  }

  return (
    <DialogFullscreen
      backgroundColor="#fff"
      title="Imprimir entrega"
      handleModalState={onExited}
      componentActions={<OrderAction />}
    >
      {loading && <div>Carregando...</div>}
      {order && (
        <div key={order.id} className={classes.container}>
          <Header formattedSequence={order.formattedSequence} shipment={order.shipment} />

          <div className={classes.customerData}>
            <PrintTypography>Cliente</PrintTypography>
            <PrintTypography>{order.customer?.name}</PrintTypography>
          </div>
          <div className={classes.customerData}>
            <PrintTypography>Telefone</PrintTypography>
            <PrintTypography>{order.customer?.phone}</PrintTypography>
          </div>
          {order.shipment?.shipment_method === 'delivery' && (
            <div className={classes.customerData}>
              <PrintTypography>Endereço</PrintTypography>
              <div>
                <Address shipment={order.shipment} />
              </div>
            </div>
          )}
          <table className={classes.headerProducts}>
            <tbody>
              <tr>
                <td>
                  <PrintTypography>Qtd</PrintTypography>
                </td>
                <td>
                  <PrintTypography>Item</PrintTypography>
                </td>
              </tr>
            </tbody>
          </table>
          <div className={classes.products}>
            <table>
              <tbody>
                {order.products.map(product => (
                  <tr key={product.id}>
                    <td className={classes.productAmount}>
                      <PrintTypography>{product.amount}x</PrintTypography>
                    </td>
                    <td className={classes.product}>
                      <PrintTypography upperCase bold>
                        {product.name} - {product.formattedFinalPrice}
                      </PrintTypography>
                      {product.annotation && (
                        <PrintTypography fontSize={0.8}>Obs: {product.annotation}</PrintTypography>
                      )}

                      <div className={classes.additionalInfoContainer}>
                        <Additional additional={product.additional} />
                        <Ingredients ingredients={product.ingredients} />
                      </div>

                      <ComplementCategories categories={product.complement_categories} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={classes.totals}>
            <div>
              <PrintTypography>Pagamento</PrintTypography>
            </div>
            <div>
              <PrintTypography>
                {order.payment_method?.mode === 'online' ? `Online` : order.payment_method?.method}
              </PrintTypography>
            </div>
            {order.discount > 0 && (
              <>
                <div>
                  <PrintTypography>Desconto</PrintTypography>
                </div>
                <div>
                  <PrintTypography>{order.formattedDiscount}</PrintTypography>
                </div>
              </>
            )}
            {order.tax > 0 && (
              <>
                <div>
                  <PrintTypography>Taxa de entrega</PrintTypography>
                </div>
                <div>
                  <PrintTypography>{order.formattedTax}</PrintTypography>
                </div>
              </>
            )}
            {order.change > 0 && (
              <>
                <div>
                  <PrintTypography>Troco para</PrintTypography>
                </div>
                <div>
                  <PrintTypography>{order.formattedChangeTo}</PrintTypography>
                </div>
                <div>
                  <PrintTypography>Troco</PrintTypography>
                </div>
                <div>
                  <PrintTypography>{order.formattedChange}</PrintTypography>
                </div>
              </>
            )}
            <div>
              <PrintTypography>{order.payment_method?.mode ? 'Total' : 'Total a pagar'}</PrintTypography>
            </div>
            <div>
              <PrintTypography fontSize={1.2} bold>
                {order.formattedTotal}
              </PrintTypography>
            </div>
            {order.deliverers.length > 0 && (
              <>
                {order.deliverers.map(deliverer => (
                  <Fragment key={deliverer.id}>
                    <div>
                      <PrintTypography>Entregador</PrintTypography>
                    </div>
                    <div>
                      <PrintTypography>{deliverer.name}</PrintTypography>
                    </div>
                  </Fragment>
                ))}
              </>
            )}
          </div>
          <div className={classes.developer}>
            <PrintTypography fontSize={0.9} align="center">
              www.sgrande.delivery
            </PrintTypography>
          </div>
        </div>
      )}
    </DialogFullscreen>
  );
};

export default DispatchedOrderOnly;
