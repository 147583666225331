import React, { useState } from 'react';
import { Typography, makeStyles, alpha } from '@material-ui/core';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import BoardProductItem from './BoardProductItem';
import { BoardOrderProduct } from 'types/boardOrderProduct';
import { boardProductsTableTemplate } from './boardProductsTableTemplate';
import BoardProductListMenu from '../BoardProductListMenu';
import { useBoardControl } from '../../hooks/useBoardControl';

const useStyles = makeStyles(theme => ({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  p: { backgroundColor: '#17a2b8', color: '#fff' },
  o: { backgroundColor: theme.palette.warning.light },
  a: { backgroundColor: theme.palette.success.light, color: '#fff' },
  d: { backgroundColor: theme.palette.info.light, color: '#fff' },
  c: { backgroundColor: '#6c757d', color: '#fff' },
  x: { backgroundColor: theme.palette.error.light, color: '#fff' },
  status: {
    padding: '4px 10px',
    borderRadius: 4,
    fontSize: 12,
    marginLeft: 6,
  },
  row: {
    '&.selected': {
      backgroundColor: alpha('#17a2b8', 0.2),
    },
    '&.paid': {
      backgroundColor: '#eee',
    },
  },
}));

type BoardProductListProps = {
  products: BoardOrderProduct[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const BoardProductList: React.FC<BoardProductListProps> = ({ products, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const { selectedProducts, handleSetSelectedProducts } = useBoardControl();

  function handleClick(product: BoardOrderProduct) {
    handleSetSelectedProducts(product);
  }

  function isSelected(product: BoardOrderProduct) {
    return selectedProducts.some(item => item.id === product.id);
  }

  function getTableRowClass(product: BoardOrderProduct) {
    if (isSelected(product)) {
      return `${classes.row} selected`;
    }

    return classes.row;
  }

  return (
    <>
      <BoardProductListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <TableContainer tableTemplate={boardProductsTableTemplate}>
        <TableContent>
          <TableHeader>
            {boardProductsTableTemplate
              .filter(item => !item.notShow)
              .map(item => (
                <div
                  className={classes.headerItem}
                  key={item.id}
                  onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
                >
                  <Typography noWrap variant="caption" color="primary">
                    {item.description}
                  </Typography>

                  {orderedIndex.index === item.originalId && (
                    <>
                      {orderedIndex.direction === 'asc' ? (
                        <ArrowUpward color="primary" />
                      ) : (
                        <ArrowDownward color="primary" />
                      )}
                    </>
                  )}
                </div>
              ))}
          </TableHeader>

          <TableBody>
            {products.map(product => (
              <TableRow className={getTableRowClass(product)} onClick={() => handleClick(product)} key={product.id}>
                <BoardProductItem product={product} setAnchorEl={setAnchorEl} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </>
  );
};

export default BoardProductList;
