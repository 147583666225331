import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

export default function RestaurantConfigAction({ loading, saving, handleValidation }) {
  return (
    <Tooltip title="Salvar">
      <div>
        <IconButton disabled={saving || loading} onClick={handleValidation} color="inherit">
          <DoneIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
}
