import React, { MouseEvent } from 'react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { Receipt } from 'pages/receipts/types/receipt';
import { RECEIPT_STATUS_MAPPING } from 'pages/receipts/constants/receiptStatusMapping';
import { MoreHoriz } from '@material-ui/icons';
import { useReceipts } from 'pages/receipts/hooks/useReceipts';

const useStyles = makeStyles(theme => ({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
  status: {
    width: 160,
  },
  authorized: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    padding: '4px 10px',
    marginRight: 10,
  },
  cancelled: {
    backgroundColor: theme.palette.grey[700],
    color: '#fff',
    padding: '4px 10px',
    marginRight: 10,
  },
  created: {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
    padding: '4px 10px',
    marginRight: 10,
  },
  cancellation_failed: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    padding: '4px 10px',
    marginRight: 10,
  },
  authorization_failed: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
    padding: '4px 10px',
    marginRight: 10,
  },
}));

type ReceiptItemTableProps = {
  receipt: Receipt;
  setAnchorEl(el: HTMLButtonElement): void;
};

const ReceiptItemTable: React.FC<ReceiptItemTableProps> = ({ receipt, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedReceipt } = useReceipts();

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    setSelectedReceipt(receipt);
    setAnchorEl(event.currentTarget);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton onClick={handleMoreClick} key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : item.id === 'formattedCurrentStatus' ? (
            <div key={item.id} className={`${classes.status} ${classes[receipt.current_status]}`}>
              <Typography variant="body2">{RECEIPT_STATUS_MAPPING[receipt.current_status]}</Typography>
            </div>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{receipt[item.id]}</Typography>
            </div>
          )
        )}
    </>
  );
};

export default ReceiptItemTable;
