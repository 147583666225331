import React, { useState } from 'react';
import { Typography, Dialog, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    minHeight: 350,
    padding: 20,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  title: {
    textAlign: 'center',
  },
  content: {},
});

function DialogDelete({ handleDelete, title, onExited, message, buttonText, cancelText = 'Cancelar' }) {
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  function handleAction() {
    handleDelete();
    handleClose();
  }

  return (
    <Dialog
      classes={{ paperScrollPaper: classes.container }}
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      TransitionProps={{
        onExited,
      }}
    >
      <Typography className={classes.title} variant="h6">
        {title}
      </Typography>
      <div className={classes.content}>
        <Typography className={classes.title}>{message}</Typography>
      </div>
      <div className={classes.actions}>
        <Button variant="text" color="primary" onClick={handleClose}>
          {cancelText}
        </Button>
        <Button onClick={handleAction} variant="contained" color="primary">
          {buttonText}
        </Button>
      </div>
    </Dialog>
  );
}

export default DialogDelete;
