import { IconButton, InputAdornment, MuiThemeProvider, TextField, createTheme } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import { useImageSelector } from './hooks/useImageSelector';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#ff7008',
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        color: '#fff',
      },
    },
    MuiInputBase: {
      root: {
        color: '#fff',
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: 'rgba(255,255,255,0.23)',
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: 'white',
        },
      },
      disabled: {},
      focused: {},
      error: {},
    },
    MuiInputLabel: {
      root: {
        color: '#fff',
        '&$focused': {
          color: '#fff',
        },
      },
      focused: {},
    },
    MuiButton: {
      root: {
        '&:hover$disabled': {
          borderColor: 'rgba(0,0,0,0.23)',
        },
      },
      containedSecondary: {
        color: '#fff',
      },
    },
  },
});

const SearchBox: React.FC = () => {
  const { term, handleSearch } = useImageSelector();

  return (
    <MuiThemeProvider theme={theme}>
      <TextField
        placeholder="pesquisar..."
        fullWidth
        margin="none"
        value={term}
        onChange={event => handleSearch(event.target.value)}
        autoFocus
        InputProps={{
          endAdornment: term ? (
            <InputAdornment position="end">
              <IconButton color="inherit" onClick={() => handleSearch('')} size="small">
                <Close />
              </IconButton>
            </InputAdornment>
          ) : undefined,
        }}
      />
    </MuiThemeProvider>
  );
};

export default SearchBox;
