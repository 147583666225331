import { useCallback } from 'react';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { User, UserForList } from 'types/user';

const rules = {
  'admin-admin': 'administrador',
  'admin-operator': 'operador',
  'admin-waiter': 'garçom',
};

export function useUserFormatter(): [(user: User) => UserForList] {
  const formatter = useCallback((user: User): UserForList => {
    return {
      ...user,
      formattedCreatedAt: format(parseISO(user.created_at as string), 'Pp', { locale: ptBR }),
      formattedRule: rules[user.rule],
      formattedActive: user.active ? 'Sim' : 'Não',
    };
  }, []);

  return [formatter];
}
