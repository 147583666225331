import React from 'react';
import { IconButton, Tooltip, makeStyles } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

const styles = makeStyles(theme => ({
  container: {},
}));

interface CustomerNewActionsProps {
  loading: boolean;
}

const CustomerActions: React.FC<CustomerNewActionsProps> = ({ loading }) => {
  const classes = styles();
  return (
    <div className={classes.container}>
      <Tooltip title="Salvar">
        <IconButton disabled={loading} type="submit" color="inherit">
          <DoneIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default CustomerActions;
