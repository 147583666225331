import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { Button, Typography } from '@material-ui/core';
import EmailStep from 'pages/login/steps/EmailStep';
import PasswordStep from 'pages/login/steps/PasswordStep';
import { makeStyles } from '@material-ui/core/styles';
import history from 'services/history';
import { useAuth } from 'hooks/auth';
import { useMessaging } from 'hooks/messaging';
import InsideSaving from 'components/loading/InsideSaving';
import ForgotPasswordLink from './ForgotPasswordLink';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#fff',
    },
  },
  paper: {
    padding: 30,
    boxShadow: 'none',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    backgroundColor: '#fff',
    minWidth: 400,
    [theme.breakpoints.down('sm')]: {
      minWidth: 350,
      padding: 15,
    },
  },
  logo: {
    width: 90,
  },
  title: {
    margin: '30px 0 0',
  },
}));

const Login: React.FC = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [step, setStep] = useState('email');
  const [loading, setLoading] = useState(false);
  const [shownPassword, setShownPassword] = useState(false);
  const messaging = useMessaging();
  const auth = useAuth();

  useEffect(() => {
    setLoading(true);

    auth
      .validate()
      .then(() => history.push('/dashboard'))
      .catch(() => setLoading(false));
  }, [auth]);

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (step === 'email') {
      handleCheckEmail();
      return;
    }

    handleLogin();
  }

  function handleCheckEmail() {
    setLoading(true);
    auth
      .checkEmail(email)
      .then(response => {
        setName(response.name);
        setStep('password');
        messaging.handleClose();
      })
      .catch(err => {
        messaging.handleOpen(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleLogin() {
    setLoading(true);

    auth
      .login(email, password)
      .then(() => {
        setLoading(false);
        history.push('/dashboard');
      })
      .catch(err => {
        setLoading(false);
        messaging.handleOpen(err.message);
      });
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.name === 'email') setEmail(event.target.value);
    else setPassword(event.target.value);
  }

  function handleStepBack() {
    setStep('email');
  }

  function handlePasswordVisibility() {
    setShownPassword(!shownPassword);
  }

  return (
    <div className={classes.container}>
      <div className={classes.paper}>
        {loading && <InsideSaving />}
        <div>
          <img src="/logo192.png" className={classes.logo} alt="Logo sgrande.delivery" />
        </div>
        <div className={classes.title}>
          <Typography variant="h6">Login</Typography>
        </div>
        <form onSubmit={handleSubmit} autoComplete="on">
          {step === 'email' ? (
            <EmailStep handleChange={handleChange} email={email} loading={loading} />
          ) : (
            <PasswordStep
              handleChange={handleChange}
              login={{ email, password, name }}
              shownPassword={shownPassword}
              handleStepBack={handleStepBack}
              handlePasswordVisibility={handlePasswordVisibility}
              loading={loading}
            />
          )}
          <Button disabled={loading} type="submit" variant="contained" color="primary" fullWidth>
            {step === 'email' ? 'Próximo' : 'Entrar'}
          </Button>
          <ForgotPasswordLink />
        </form>
        <Typography variant="caption" color="textSecondary">
          SGrande Delivery 2020
        </Typography>
      </div>
    </div>
  );
};

export default Login;
