import React, { Fragment, Component } from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import CustomTimerPicker from 'components/pickers/TimerPicker';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  shift: {
    border: '1px dotted #ccc',
    padding: 10,
    borderRadius: 4,
    marginBottom: 5,
    position: 'relative',
  },
  delete: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
};

class OpeningHourShift extends Component {
  render() {
    const { indexWorkingTime, itemWorkingTime, handleDateChange, handleDeleteShift, classes } = this.props;

    return (
      <Fragment>
        {itemWorkingTime.working_hours.map((itemHours, indexHours) => {
          return (
            <div className={classes.shift} key={indexHours}>
              <Typography component={'span'} color={'primary'}>
                Turno {indexHours + 1}
              </Typography>
              <Grid container spacing={2} className={classes.turn} key={indexHours}>
                <Grid item xs={6} xl={4} sm={6}>
                  <CustomTimerPicker
                    name={'starting_hour'}
                    handleDateChange={handleDateChange(indexWorkingTime, indexHours, 'starting_hour')}
                    value={itemHours.starting_hour}
                    label={'Início'}
                  />
                </Grid>
                <Grid item xs={6} sm={6} xl={4}>
                  <CustomTimerPicker
                    name={'final_hour'}
                    handleDateChange={handleDateChange(indexWorkingTime, indexHours, 'final_hour')}
                    value={itemHours.final_hour}
                    label={'Fim'}
                  />
                </Grid>
              </Grid>
              <IconButton
                className={classes.delete}
                color={'primary'}
                onClick={() => handleDeleteShift(indexWorkingTime, indexHours)}
              >
                <CancelIcon />
              </IconButton>
            </div>
          );
        })}
      </Fragment>
    );
  }
}

export default withStyles(styles)(OpeningHourShift);
