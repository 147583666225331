import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  preLoading1: {
    padding: 6,
    width: 250,
    backgroundColor: '#eee',
    marginBottom: 10,
  },
  preLoading2: {
    height: 20,
    width: 150,
    backgroundColor: '#eee',
    marginBottom: 7,
  },
  preLoading3: {
    padding: 6,
    width: 150,
    backgroundColor: '#eee',
  },
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 1px 1px #eee',
    borderRadius: '4px',
    paddingTop: 15,
    paddingBottom: 15,
  },
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: 6,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

export default function DeliverersLoading() {
  let cont;
  const content = [];
  const classes = useStyles();

  for (cont = 0; cont < 12; cont++) {
    content[cont] = (
      <ListItem key={cont} className={classes.listItem}>
        <div className={`animated-background ${classes.preLoading2}`} />
        <div className={`animated-background ${classes.preLoading1}`} />
      </ListItem>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <List dense className={classes.list}>
          {content}
        </List>
      </Grid>
    </Grid>
  );
}
