import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Typography } from '@material-ui/core';
import { CustomDialogContext } from 'components/dialog/CustomDialog';

const useStyles = makeStyles(theme => ({
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: 10,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: theme.shape.borderRadius,
    padding: 20,
  },
}));

export default function AreaCityList({ data, handleSetArea }) {
  const classes = useStyles();

  return (
    <Fragment>
      <Typography variant={'caption'}>Selecione uma cidade abaixo:</Typography>
      <List dense className={classes.list}>
        {data.map(item => {
          return (
            <CustomDialogContext.Consumer key={item.ibge_id}>
              {({ handleCloseDialog }) => (
                <ListItem
                  onClick={() => {
                    handleSetArea(item);
                    handleCloseDialog();
                  }}
                  button
                  className={classes.listItem}
                >
                  <Typography variant="body1">{item.name}</Typography>
                  <Typography color="textSecondary">{item.uf}</Typography>
                </ListItem>
              )}
            </CustomDialogContext.Consumer>
          );
        })}
      </List>
    </Fragment>
  );
}
