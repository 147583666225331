import React, { useState } from 'react';
import CustomDialog from 'components/dialog/CustomDialog';
import { makeStyles } from '@material-ui/core/styles';
import ProductSimpleAction from './ProductSimpleAction';
import { Grid, Typography } from '@material-ui/core';
import ImagePreview from 'components/image-preview/ImagePreview';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  imageContainer: {
    width: 200,
    maxHeight: 200,
    minHeight: 100,
    marginRight: 20,
    marginBottom: 10,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  image: {
    width: '100%',
    cursor: 'zoom-in',
    borderRadius: 4,
  },
  container: {
    marginBottom: 0,
  },
  icon: {
    backgroundColor: '#fff',
    borderRadius: '50%',
    position: 'absolute',
    right: 15,
  },
  complementName: {
    fontWeight: 400,
  },
  productData: {
    marginBottom: 15,
    marginTop: 10,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  annotationContainer: {
    marginTop: 15,
  },
  imageWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginBottom: 15,
    },
  },
}));

ProductSimple.propTypes = {
  onExited: PropTypes.func.isRequired,
  selectedProduct: PropTypes.object.isRequired,
  handleConfirmProduct: PropTypes.func.isRequired,
};

export default function ProductSimple({ onExited, selectedProduct, handleConfirmProduct }) {
  const [amount, setAmount] = useState(selectedProduct.amount || 1);
  const [imagePreview, setImagePreview] = useState(false);
  const classes = useStyles();
  const product = JSON.parse(JSON.stringify(selectedProduct));

  function handleAmountUp() {
    setAmount(amount + 1);
  }

  function handleAmountDown() {
    if (amount > 1) {
      setAmount(amount - 1);
    }
  }

  function handleImagePreview() {
    setImagePreview(!imagePreview);
  }

  function handleConfirmClick() {
    handleConfirmProduct({
      ...product,
      amount,
    });
  }

  return (
    <CustomDialog title="Adicionar produto ao pedido" backgroundColor="#fafafa" handleModalState={onExited}>
      {imagePreview && product.image && (
        <ImagePreview src={product.image.imageUrl} description={product.name} onExited={handleImagePreview} />
      )}
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <div className={classes.productData}>
            <div className={classes.imageWrapper}>
              <div className={classes.imageContainer}>
                <img
                  onClick={handleImagePreview}
                  className={classes.image}
                  src={product.image && product.image.imageUrl}
                  alt={product.name}
                />
              </div>
            </div>
            <div>
              <Typography color="primary" variant="caption" display="block">
                Produto {product.id}
              </Typography>
              <Typography variant="h6">{product.name}</Typography>
              <Typography>{product.description}</Typography>
              <Typography color="textSecondary">{product.formattedPrice}</Typography>
            </div>
          </div>
        </Grid>
      </Grid>
      <ProductSimpleAction
        handleAmountDown={handleAmountDown}
        amount={amount}
        handleAmountUp={handleAmountUp}
        handleConfirmClick={handleConfirmClick}
      />
    </CustomDialog>
  );
}
