import React, { useState, useEffect } from 'react';
import { useTableReducer } from 'pages/boards/reducer/reducer';
import CustomAppbar from 'components/appbar/Appbar';
import BoardEditActions from './BoardEditActions';
import PageHeader from 'components/page-header/PageHeader';
import { Grid, makeStyles } from '@material-ui/core';
import BoardForm from '../BoardForm';
import { setTable, tableChange } from 'pages/boards/reducer/actions';
import { Board } from 'types/board';
import { useBoardValidation } from '../validation/useBoardValidation';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import history from 'services/history';

import Loading from 'components/loading/Loading';

import { useParams } from 'react-router-dom';
import InsideLoading from 'components/loading/InsideLoading';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const BoardEdit: React.FC = () => {
  const classes = styles();

  const [board, dispatch] = useTableReducer();
  const [validation, setValidation, validate] = useBoardValidation();
  const messaging = useMessaging();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    api
      .get(`/boards/${id}`)
      .then(response => {
        dispatch(setTable(response.data));
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [id, dispatch]);

  function handleValidation() {
    setValidation({});

    validate(board)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/boards/${id}`, board)
      .then(() => {
        messaging.handleOpen('Salvo');
        setSaving(false);
        history.push('/boards');
      })
      .catch(() => {
        messaging.handleOpen('Não foi possível salvar');
        setSaving(false);
      });
  }

  function handleChange(key: keyof Board, value: any) {
    dispatch(tableChange(key, value));
  }

  return (
    <>
      {saving && <Loading />}
      <CustomAppbar title="Mesa" ActionComponents={<BoardEditActions handleSubmit={handleValidation} />} />
      <PageHeader title="Editar mesa" backAction={() => history.push('/boards')} />
      {loading ? (
        <InsideLoading />
      ) : (
        <div className={classes.container}>
          <Grid item xs={12} xl={4} lg={4} md={6}>
            <BoardForm handleChange={handleChange} board={board} validation={validation} />
          </Grid>
        </div>
      )}
    </>
  );
};

export default BoardEdit;
