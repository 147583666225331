import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles, MenuItem, TextField } from '@material-ui/core';
import { PaymentMethod } from 'types/paymentMethod';
import { BoardPaymentValidation } from '../validation/useBoardPaymentValidation';

const styles = makeStyles(theme => ({
  container: {
    maxWidth: '100%',
    padding: 20,
  },
  input: {
    fontSize: 20,
  },
  meta: {
    display: 'flex',
    gap: 20,
    marginTop: 20,
  },
}));

interface BoardControlPaymentFormProps {
  value: number;
  paymentMethodId: string | null;
  setPaymentMethodId: Dispatch<SetStateAction<string | null>>;
  setValue: Dispatch<SetStateAction<number>>;
  paymentMethods: PaymentMethod[];
  validation: BoardPaymentValidation;
}

const BoardControlPaymentForm: React.FC<BoardControlPaymentFormProps> = ({
  value,
  setValue,
  paymentMethods,
  paymentMethodId,
  validation,
  setPaymentMethodId,
}) => {
  const classes = styles();

  const [receivedValue, setReceivedValue] = useState('');

  const inputs = {
    payment_method_id: useRef<HTMLSelectElement>(null),
    value: useRef<HTMLInputElement>(null),
  };

  const fixedValue = value ? parseFloat(value.toFixed(2)) : '';

  const change = useMemo(() => {
    if (!receivedValue) {
      return 0;
    }

    if (parseFloat(receivedValue) < value) {
      return 0;
    }

    return (parseFloat(receivedValue) - value).toFixed(2);
  }, [receivedValue, value]);

  const selectedPaymentMethod = useMemo(
    () => paymentMethods.find(payment => payment.id === paymentMethodId),
    [paymentMethodId, paymentMethods]
  );

  useEffect(() => {
    inputs.value.current?.select();
  }, [inputs.value]);

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) {
      return;
    }

    if (!inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <div className={classes.container}>
      <TextField
        inputRef={inputs.payment_method_id}
        select
        label="Método de pagamento"
        fullWidth
        value={paymentMethodId || ''}
        margin="normal"
        helperText={validation.payment_method_id}
        error={!!validation.payment_method_id}
        onChange={e => setPaymentMethodId(e.target.value)}
      >
        {paymentMethods
          .filter(item => item.mode === 'offline')
          .map(method => (
            <MenuItem key={method.id} value={method.id}>
              {method.method}
            </MenuItem>
          ))}
      </TextField>

      <TextField
        margin="normal"
        inputRef={inputs.value}
        autoFocus
        label="Valor (R$)"
        fullWidth
        value={fixedValue}
        onChange={e => setValue(e.target.value ? parseFloat(e.target.value) : 0)}
        inputMode="decimal"
        required
        type="number"
        InputProps={{
          classes: { input: classes.input },
        }}
        inputProps={{
          min: 0,
          step: 0.01,
        }}
        helperText={validation.value}
        error={!!validation.value}
      />

      {selectedPaymentMethod?.kind === 'money' && (
        <div className={classes.meta}>
          <TextField
            variant="outlined"
            margin="normal"
            autoFocus
            label="Valor Recebido (R$)"
            fullWidth
            value={receivedValue}
            onChange={e => setReceivedValue(e.target.value)}
            inputMode="decimal"
            required
            type="number"
            InputProps={{
              classes: { input: classes.input },
            }}
            inputProps={{
              min: 0,
              step: 0.01,
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            autoFocus
            label="Troco (R$)"
            fullWidth
            value={change}
            InputProps={{
              classes: { input: classes.input },
            }}
            inputMode="decimal"
            disabled
            type="number"
          />
        </div>
      )}

      <button type="submit" style={{ display: 'none' }} />
    </div>
  );
};

export default BoardControlPaymentForm;
