import React from 'react';
import { IconButton, Tooltip, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';

const styles = makeStyles(theme => ({
  container: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

type CategoryActionProps = {
  setDialogAddCategory(): void;
};

const CategoryAction: React.FC<CategoryActionProps> = ({ setDialogAddCategory }) => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <Tooltip title="Adicionar categoria">
        <IconButton component={Link} to="/menu/category" onClick={setDialogAddCategory} color="inherit">
          <AddIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default CategoryAction;
