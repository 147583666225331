import { useCallback, useEffect, useState } from 'react';
import { ReceiptOnList } from '../types/receipt';
import { api } from 'services/api';
import { ReceiptsQueryParams } from '../Receipts';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { useReceiptFormatter } from './useReceiptFormatter';
import { subDays } from 'date-fns';

const initialQueryParams: ReceiptsQueryParams = {
  rows: 20,
  page: 1,
  initial_date: subDays(new Date(), 7),
  final_date: new Date(),
  status: 'all',
};

export type ReceiptsTotals = { authorized: number; created: number };

export function useFetchReceipts() {
  const [queryParams, setQueryParams] = useState<ReceiptsQueryParams>(initialQueryParams);
  const [receipts, setReceipts] = useState<ReceiptOnList[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [total, setTotal] = useState(0);
  const { showErrorDialog } = useErrorHandler();
  const [formatter] = useReceiptFormatter();
  const [totals, setTotals] = useState<ReceiptsTotals>({ created: 0, authorized: 0 });

  useEffect(() => {
    if (loading) {
      setTotals({
        authorized: 0,
        created: 0,
      });
    }
  }, [loading]);

  const fetch = useCallback(
    (term: string) => {
      setLoading(true);

      api
        .get(`/receipts`, { params: { ...queryParams, term } })
        .then(response => {
          setTotal(response.data.receipts.total);
          setReceipts(response.data.receipts.data.map(receipt => formatter(receipt)));
          setTotals(response.data.totals);
        })
        .catch(error => {
          showErrorDialog({
            error,
            message: 'Não foi possível carregar os cupons',
          });
        })
        .finally(() => setLoading(false));
    },
    [queryParams, showErrorDialog, formatter]
  );

  return {
    loading,
    fetch,
    error,
    setError,
    receipts,
    total,
    setQueryParams,
    queryParams,
    setReceipts,
    totals,
  };
}
