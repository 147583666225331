import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useApp } from 'App';
import { SIDEBAR_WIDTH } from 'constants/constants';
import Sidebar from 'components/sidebar/Sidebar';

const useStyles = makeStyles(theme => ({
  sidebarWrapper: {
    position: 'fixed',
    height: '100%',
    zIndex: 1101,
  },
  container: {
    display: 'flex',
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  contentWrapper: ({ sidebarWidth, isOpenedMenu }: { sidebarWidth: number; isOpenedMenu: boolean }) => ({
    width: '100%',
    margin: '64px 0 0',
    transition: 'padding-left 0.4s ease',
    paddingLeft: isOpenedMenu ? sidebarWidth : 0,
    position: 'relative',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down('xs')]: {
      margin: '57px 0 0',
    },
    '@media print': {
      padding: 0,
      margin: 0,
      overflowY: 'initial',
    },
  }),
  content: {
    minHeight: 'calc(100vh - 104px)',
    padding: '20px 30px 0',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      padding: '15px',
    },
    '@media print': {
      padding: 0,
    },
  },
}));

interface DefaultLayoutProps {
  children: ReactNode;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
  const { isOpenedMenu } = useApp();
  const classes = useStyles({ sidebarWidth: SIDEBAR_WIDTH, isOpenedMenu });

  return (
    <div className={classes.container}>
      <div className={classes.sidebarWrapper}>
        <Sidebar />
      </div>
      <div id="content-wrapper" className={classes.contentWrapper}>
        <div id="app-content" className={classes.content}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
