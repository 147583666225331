import React, { useEffect, useState, useCallback } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { BoardManagementProvider } from './hooks/useBoardManagement';
import { useBoardManagementReducer } from './reducer/reducer';
import { api } from 'services/api';
import { setBusyBoard, setBoards, setFreeBoard } from './reducer/actions';
import { makeStyles } from '@material-ui/core';
import TableManagementList from './BoardManagementList';
import { Board, BoardChart } from 'types/board';
import { BoardMovement } from 'types/boardMovement';
import OccupyTable from './registration/OccupyBoard';
import NoData from 'components/nodata/NoData';
import BoardManagementLoading from './BoardManagementLoading';
import history from 'services/history';
import { useApp } from 'App';
import BoardManagementActions from './BoardManagementActions';
import HeaderActions from './HeaderActions';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const BoardManagement: React.FC = () => {
  const classes = styles();
  const [boardManagement, dispatch] = useBoardManagementReducer();
  const [loading, setLoading] = useState(true);
  const [selectedBoard, setSelectedBoard] = useState<BoardChart | null>(null);
  const [error, setError] = useState(false);
  const app = useApp();

  const loadTables = useCallback(async () => {
    try {
      const boardRequest = api.get<Board[]>('/boards');
      const boardMovementsRequest = api.get<BoardMovement[]>('/boardMovements', { params: { only_open: 1 } });

      const [boardResponse, boardMovementsResponse] = await Promise.all([boardRequest, boardMovementsRequest]);

      const boards = boardResponse.data.map(board => {
        const currentMovement = boardMovementsResponse.data.find(busyBoard => busyBoard.board_id === board.id);
        const _board = {
          ...board,
          state: currentMovement ? 'busy' : 'free',
          current_movement: currentMovement || null,
        };
        return _board as BoardChart;
      });

      dispatch(setBoards(boards));
    } catch (err: any) {
      setError(err?.response?.data.error ?? 'Não foi possível carregar as mesas');
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    loadTables();
  }, [loadTables]);

  useEffect(() => {
    if (!app.socket) {
      return;
    }

    app.socket.on('board_movement_created', (movement: BoardMovement) => {
      dispatch(setBusyBoard(movement.board_id, movement));
    });

    app.socket.on('board_movement_completed', (movement: BoardMovement) => {
      dispatch(setFreeBoard(movement.board_id));
    });
  }, [dispatch, app.socket]);

  async function handleCreateMovement(referenceName: string, deliveryLocation: string, tableId: string): Promise<void> {
    const data = {
      reference_name: referenceName,
      board_id: tableId,
      delivery_location: deliveryLocation,
    };

    const response = await api.post<BoardMovement>('/boardMovements', data);

    dispatch(setBusyBoard(tableId, response.data));

    history.push(`/board-management/movements/${response.data.id}`);
  }

  return (
    <BoardManagementProvider
      value={{
        boardManagement,
        dispatch,
        handleCreateMovement,
        selectedBoard,
        setSelectedBoard,
      }}
    >
      <CustomAppbar title="Mesas" ActionComponents={<BoardManagementActions />} />

      <PageHeaderActions title="Mesas" description="Controle de mesas" ActionComponent={<HeaderActions />} />

      {selectedBoard?.state === 'free' && <OccupyTable onExited={() => setSelectedBoard(null)} />}

      {error ? (
        <NoData message="Aconteceu um erro ao carregar as mesas" />
      ) : loading ? (
        <BoardManagementLoading />
      ) : boardManagement.boards.length === 0 ? (
        <NoData message="Nenhuma mesa para mostrar" backgroundColor="inherit" />
      ) : (
        <div className={classes.container}>
          <TableManagementList />
        </div>
      )}
    </BoardManagementProvider>
  );
};

export default BoardManagement;
