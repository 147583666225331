import { FiscalSetting } from 'pages/fiscal-settings/types/fiscal-setting';
import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';
import { satSchema } from './satSchema';
import { nfceSchema } from './nfceSchema';

export interface FiscalSettingsValidation {
  'sat.software_house_token'?: string;
  'sat.activation_code'?: string;
  'sat.sat_version'?: string;
  'sat.dll_path'?: string;
  'sat.printer_name'?: string;
  'sat.escpos_type'?: string;
  'sat.escpos_port'?: string;
  'sat.escpos_columns'?: string;
  'sat.escpos_line_height'?: string;
  'sat.escpos_lines'?: string;
  'nfce.csc'?: string;
  'nfce.cscid'?: string;
  'nfce.certificate_password'?: string;
  'nfce.schema_version'?: string;
  'nfce.schema_path'?: string;
  'nfce.printer_name'?: string;
  'nfce.escpos_type'?: string;
  'nfce.escpos_port'?: string;
  'nfce.escpos_columns'?: string;
  'nfce.escpos_line_height'?: string;
  'nfce.escpos_lines'?: string;
  'nfce.paper_width'?: string;
  'nfce.final_space'?: string;
}

type UseFiscalSettingsValidation = [
  FiscalSettingsValidation,
  Dispatch<SetStateAction<FiscalSettingsValidation>>,
  (settings: FiscalSetting) => Promise<void>
];

export function useFiscalSettingsValidation(): UseFiscalSettingsValidation {
  const [validation, setValidation] = useState<FiscalSettingsValidation>({} as FiscalSettingsValidation);

  async function handleValidation(settings: FiscalSetting) {
    const schema = yup.object().shape({
      sat: satSchema,
      nfce: nfceSchema,
    });

    try {
      await schema.validate(settings);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  return [validation, setValidation, handleValidation];
}
