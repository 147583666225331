import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { Done } from '@material-ui/icons';

interface UpdatePaymentMethodAppbarButtonsProps {
  handleSubmit(): void;
}

const UpdatePaymentMethodAppbarButtons: React.FC<UpdatePaymentMethodAppbarButtonsProps> = ({ handleSubmit }) => {
  return (
    <Tooltip title="Salvar">
      <IconButton color="inherit" onClick={handleSubmit}>
        <Done />
      </IconButton>
    </Tooltip>
  );
};

export default UpdatePaymentMethodAppbarButtons;
