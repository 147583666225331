import React from 'react';
import OrderResumeCustomer from '../registration/resume/OrderResumeCustomer';
import OrderResumePaymentMethod from '../registration/resume/OrderResumePaymentMethod';
import OrderResumeShipment from '../registration/resume/OrderResumeShipment2';
import { makeStyles } from '@material-ui/core/styles';
import history from 'services/history';
import OrderPreviewProductList from './OrderPreviewProductList';
import OrderPreviewTotal from './OrderPreviewTotal';
import { useOrders } from '../hook/useOrders';
import { useFetchOrder } from '../hook/useFetchOrder';
import InsideLoading from 'components/loading/InsideLoading';
import { useSelector } from 'store/redux/selector';
import Modal from 'components/modal/Modal';
import OrderPreviewButtons from './OrderPreviewButtons';

const useStyles = makeStyles(theme => ({
  status: {
    padding: '2px 10px',
    borderRadius: 3,
    marginBottom: 7,
    display: 'flex',
    justifyContent: 'center',
  },
  o: { backgroundColor: '#ffc107' },
  a: { backgroundColor: '#8BC34A', color: '#fff' },
  d: { backgroundColor: '#007bff', color: '#fff' },
  c: { backgroundColor: '#6c757d', color: '#fff' },
  x: { backgroundColor: '#dc3545', color: '#fff' },
  header: {
    position: 'absolute',
    top: 75,
    right: 10,
    borderRadius: 4,
  },
  action: {
    position: 'absolute',
    zIndex: 10,
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
    },
  },
  actionContent: {
    display: 'flex',
    padding: 15,
    justifyContent: 'flex-end',
    width: '100%',
    '& button': {
      marginLeft: 10,
    },
  },
  content: {
    display: 'grid',
    rowGap: 10,
    paddingBottom: 80,
  },
}));

interface OrderPreviewProps {
  onExited(): void;
}

export default function OrderPreview({ onExited }: OrderPreviewProps) {
  const classes = useStyles();
  const { selectedOrder, handleOrderStatus, setDialogDeliverer } = useOrders();
  const restaurant = useSelector(state => state.restaurant);
  const [, order] = useFetchOrder(selectedOrder?.uuid);

  function handleEditClick() {
    if (!selectedOrder) {
      return;
    }

    history.push(`/orders/${selectedOrder.uuid}`);
  }

  function handleStatusClick(handleCloseDialog: () => void) {
    if (!restaurant) {
      return;
    }

    if (!selectedOrder) {
      return;
    }

    if (
      selectedOrder.status === 'a' &&
      restaurant.configs.use_deliverer &&
      selectedOrder?.shipment?.shipment_method === 'delivery'
    ) {
      setDialogDeliverer(true);
    } else {
      handleOrderStatus();
    }

    handleCloseDialog();
  }

  return (
    <Modal title={`Pedido ${selectedOrder?.formattedId}`} height="80vh" onExited={onExited} maxWidth="sm">
      {!order ? (
        <InsideLoading />
      ) : (
        <>
          <div className={classes.content}>
            <OrderResumeCustomer customer={order.customer} />
            <OrderResumeShipment order={order} />
            <OrderResumePaymentMethod order={order} />
            <OrderPreviewProductList products={order.products} />
            <OrderPreviewTotal
              subtotal={order.formattedSubtotal}
              discount={order.formattedDiscount}
              total={order.formattedTotal}
              coupon={order.coupon && order.coupon.name}
              tax={order.formattedTax}
            />
          </div>

          <OrderPreviewButtons handleEditClick={handleEditClick} handleStatusClick={handleStatusClick} />
        </>
      )}
    </Modal>
  );
}
