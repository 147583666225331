import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { api } from 'services/api';
import { Category } from 'types/category';

type UseFetchCategories = [Category[], Dispatch<SetStateAction<Category[]>>, boolean];

export function useFetchCategories(): UseFetchCategories {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get('/categories')
      .then(response => {
        setCategories(response.data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  return [categories, setCategories, loading];
}
