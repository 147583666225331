import { useEffect, useState } from 'react';
import { api, getCancelTokenSource } from 'services/api';
import { Deliverer } from 'types/deliverer';

export function useFetchDeliverers() {
  const [deliverers, setDeliverers] = useState<Deliverer[]>([]);

  useEffect(() => {
    const source = getCancelTokenSource();
    let request = true;

    api
      .get('/deliverers', { cancelToken: source.token })
      .then(response => {
        if (request) {
          setDeliverers(response.data);
        }
      })
      .catch(err => {
        console.error(err);
      });

    return () => {
      if (request) source.cancel();
      request = false;
    };
  }, []);

  return [deliverers];
}
