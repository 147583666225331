import { IconButton, ListItem, makeStyles, Typography } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import React, { Dispatch, SetStateAction } from 'react';
import { BoardMovementPayment } from 'types/boardMovementPayment';
import { useBoardControl } from '../../hooks/useBoardControl';

const styles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
  },
  greenCircle: {
    display: 'inline-block',
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: 'green',
  },
  container: {
    display: 'flex',
    gap: 10,
    borderBottom: '1px solid #eee',
    position: 'relative',
  },
  iconButton: {
    position: 'absolute',
    right: 10,
  },
});

interface BoardControlPaymentItemProps {
  payment: BoardMovementPayment;
  setConfirmDeleteDialog: Dispatch<SetStateAction<boolean>>;
  setSelectedPayment: Dispatch<SetStateAction<BoardMovementPayment | null>>;
}

const BoardControlPaymentItem: React.FC<BoardControlPaymentItemProps> = ({
  payment,
  setConfirmDeleteDialog,
  setSelectedPayment,
}) => {
  const classes = styles();
  const { movement } = useBoardControl();

  function handleClick() {
    setSelectedPayment(payment);
    setConfirmDeleteDialog(true);
  }

  return (
    <ListItem button className={classes.container}>
      <span className={classes.greenCircle} />

      <div className={classes.content}>
        <Typography variant="subtitle2">{payment.payment_method.method}</Typography>
        <Typography>{payment.formattedValue}</Typography>

        <Typography variant="body2" color="textSecondary">
          {payment.formattedCreatedAt}
        </Typography>
      </div>

      {movement?.is_open && (
        <IconButton onClick={handleClick} className={classes.iconButton}>
          <DeleteOutline />
        </IconButton>
      )}
    </ListItem>
  );
};

export default BoardControlPaymentItem;
