import { Middleware } from 'redux';
import { RootState } from 'store/redux/selector';
import { updateTotal, setConfigs } from './actions';

export const orderMiddleware: Middleware<any, RootState> = store => next => action => {
  const actionsToUpdateOrder = [
    '@order/SET_ORDER',
    '@order/ADD_PRODUCT',
    '@order/REMOVE_PRODUCT',
    '@order/UPDATE_AMOUNT',
    '@order/UPDATE_PRODUCT',
    '@order/UPDATE_COMPLEMENTS',
    '@order/SET_SHIPMENT_METHOD',
    '@order/SET_SHIPMENT_ADDRESS',
    '@order/SET_CUSTOMER',
  ];

  if (actionsToUpdateOrder.includes(action.type)) {
    const order = store.getState().order;

    const restaurant = store.getState().restaurant;

    if (!order.settings && restaurant?.configs) {
      store.dispatch(setConfigs(restaurant.configs));
    }
  }

  next(action);

  if (actionsToUpdateOrder.includes(action.type)) {
    const order = store.getState().order;

    const restaurant = store.getState().restaurant;

    if (!order.settings && restaurant?.configs) {
      setConfigs(restaurant.configs);
    }

    store.dispatch(updateTotal());
  }
};
