import React, { useEffect, useState, useRef } from 'react';
import { Grid, TextField, InputAdornment } from '@material-ui/core';
import NoData from 'components/nodata/NoData';
import ProductIngredientList from './ProductIngredientList';
import SearchIcon from '@material-ui/icons/Search';
import { useProduct } from 'pages/products/registration/hooks/useProduct';
import { setProductIngredients } from 'store/modules/product/actions';
import useSearch from 'hooks/search';

function ProductIngredientStep() {
  const { product, dispatch, ingredients: originalIngredients } = useProduct();
  const [ingredients, setIngredients] = useState(originalIngredients);
  const [ingredientsFiltered, setIngredientsFiltered] = useState([]);
  const inputSearch = useRef(null);
  const search = useSearch();
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setIngredients(ingredients =>
      ingredients.map(ingredient => {
        ingredient.selected = product.ingredients.some(productIngredient => productIngredient.id === ingredient.id);
        return ingredient;
      })
    );
  }, [product]);

  useEffect(() => {
    const i = search(searchValue, 'name', ingredients);
    setIngredientsFiltered(i);
  }, [searchValue, search, ingredients]);

  function handleSelectIngredient(id) {
    const ingredientsCopy = ingredients.map(ingredient => {
      if (ingredient.id === id) ingredient.selected = !ingredient.selected;
      return ingredient;
    });

    dispatch(setProductIngredients(ingredientsCopy.filter(item => item.selected)));
  }

  return (
    <Grid item xs={12}>
      <TextField
        inputRef={input => (inputSearch.current = input)}
        label="Pesquisar"
        placeholder="Pesquise pelos ingredientes"
        margin="normal"
        fullWidth
        onChange={e => setSearchValue(e.target.value)}
        value={searchValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      {ingredients.length === 0 && (
        <NoData message="Não há ingredientes cadastrados" buttonText="Cadastrar ingredientes" action={() => {}} />
      )}
      {ingredients.length > 0 && (
        <ProductIngredientList ingredients={ingredientsFiltered} handleSelectIngredient={handleSelectIngredient} />
      )}
    </Grid>
  );
}

export default ProductIngredientStep;
