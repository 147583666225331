import React, { useReducer, useState } from 'react';
import PrinterForm from './PrinterForm';
import printerReducer, { INITIAL_STATE as printerInitialState } from 'store/modules/printer/reducer';
import { printerChange } from 'store/modules/printer/actions';
import CustomAppbar from 'components/appbar/Appbar';
import * as yup from 'yup';
import { api } from 'services/api';
import Loading from 'components/loading/Loading';
import history from 'services/history';
import CouponAction from './PrinterAction';
import { useMessaging } from 'hooks/messaging';
import PageHeader from 'components/page-header/PageHeader';
import { makeStyles } from '@material-ui/core';

const styles = makeStyles({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
});

export default function Printer() {
  const [printer, dispatch] = useReducer(printerReducer, printerInitialState);
  const [saving, setSaving] = useState(false);
  const messaging = useMessaging();
  const [validation, setValidation] = useState({});
  const classes = styles();

  function handleChange(index, value) {
    dispatch(printerChange(index, value));
  }

  function handleFormValidation(event) {
    event.preventDefault();

    const schema = yup.object().shape({
      address: yup.string().required('A descrição é obrigatória'),
      name: yup.string().required('O nome do cupom é obrigatório'),
    });

    schema
      .validate(printer)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);
    api
      .post('/printers', printer)
      .then(() => {
        messaging.handleOpen('Salvo');
        history.push('/printers');
      })
      .catch(err => {
        if (err.response.data.error) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível salvar o cupom');
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}

      <CustomAppbar
        title="Impressora"
        ActionComponents={<CouponAction handleFormValidation={handleFormValidation} />}
      />

      <PageHeader title="Nova de impressora" backAction={() => history.back('/printers')} />

      <div className={classes.container}>
        <form onSubmit={handleFormValidation}>
          <PrinterForm printer={printer} validation={validation} handleChange={handleChange} />
        </form>
      </div>
    </>
  );
}
