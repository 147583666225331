import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { api, getCancelTokenSource } from 'services/api';
import { PaymentMethod } from 'types/paymentMethod';

type UseFetchPaymentMethods = [PaymentMethod[], Dispatch<SetStateAction<PaymentMethod[]>>, boolean];

export function useFetchPaymentMethods(): UseFetchPaymentMethods {
  const [methods, setMethods] = useState<PaymentMethod[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let request = true;
    const source = getCancelTokenSource();

    setLoading(true);

    api
      .get('order/paymentMethods', { cancelToken: source.token })
      .then(response => {
        if (request) {
          setMethods(response.data);
        }
      })
      .catch(err => console.error(err))
      .finally(() => {
        if (request) {
          setLoading(false);
        }
        request = false;
      });

    return () => {
      if (request) source.cancel();
      request = false;
    };
  }, []);

  return [methods, setMethods, loading];
}
