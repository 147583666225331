import React from 'react';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { List, ListItem, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 6,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  listItem: {
    display: 'flex',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
    minHeight: 100,
    position: 'relative',
    alignItems: 'center',
  },
  selected: {
    display: 'flex',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    position: 'relative',
    alignItems: 'center',
    minHeight: 100,
    '&:focus': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
    width: 60,
    height: 60,
    borderRadius: '50%',
    backgroundColor: '#eee',
    overflow: 'hidden',
    border: `2px solid #fff`,
  },
  image: {
    width: '100%',
  },
  checkIcon: {
    position: 'absolute',
    right: 10,
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
}));

PromotionCategoryList.propTypes = {
  categories: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default function PromotionCategoryList({ categories, handleSelect }) {
  const classes = useStyles();

  function handleClick(category) {
    handleSelect(category);
  }

  return (
    <List className={classes.list}>
      {categories.map(category => {
        return (
          <ListItem
            key={category.id}
            className={category.selected ? classes.selected : classes.listItem}
            button
            onClick={() => handleClick(category)}
          >
            <div className={classes.imageContainer}>
              <img
                className={classes.image}
                src={
                  category.image && category.image.imageThumbUrl
                    ? category.image.imageThumbUrl
                    : category.image.imageUrl
                }
                alt={category.name}
              />
            </div>
            <div>
              <Typography variant="h6">{category.name}</Typography>
              <Typography variant="body2" color="textSecondary">
                {category.products_amount === 1
                  ? `${category.products_amount} produto`
                  : `${category.products_amount} produtos`}
              </Typography>
            </div>
            {category.selected && <CheckCircleIcon color="primary" className={classes.checkIcon} />}
          </ListItem>
        );
      })}
    </List>
  );
}
