import React from 'react';
import { IconButton, InputAdornment, makeStyles, MenuItem, TextField } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/KeyboardDatePicker';
import { BoardMovementsParams } from './BoardMovements';

const useStyles = makeStyles(theme => ({
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '200px 1fr',
    columnGap: 10,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      flex: 1,
    },
    '& > .search-params': {
      display: 'grid',
      gridTemplateColumns: '200px 200px 200px',
      columnGap: 20,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  actions: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

type BoardMovementsFilterBoxProps = {
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
  params: BoardMovementsParams;
  handleChangeParams(index: keyof BoardMovementsParams, value: any): void;
};

const BoardMovementsFilterBox: React.FC<BoardMovementsFilterBoxProps> = ({
  setDisplayMode,
  displayMode,
  params,
  handleChangeParams,
}) => {
  const classes = useStyles();

  return (
    <FilterBox>
      <div className={classes.filter}>
        <TextField
          label="Nome do cliente"
          placeholder="Nome do cliente"
          value={params.customer_name}
          onChange={e => handleChangeParams('customer_name', e.target.value)}
          autoFocus
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: params.customer_name && (
              <InputAdornment position="start">
                <IconButton size="small" onClick={() => handleChangeParams('customer_name', '')}>
                  <Close />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div className="search-params">
          <TextField
            select
            label="Estado"
            fullWidth
            autoFocus
            value={params.is_open}
            onChange={e => handleChangeParams('is_open', e.target.value)}
          >
            <MenuItem value={1}>Aberto</MenuItem>
            <MenuItem value={0}>Fechado</MenuItem>
          </TextField>
          <KeyboardDatePicker
            label="Data inicial"
            value={params.initial_date}
            onChange={date => handleChangeParams('initial_date', date)}
            autoOk
            margin="none"
          />
          <KeyboardDatePicker
            label="Data Final"
            value={params.final_date}
            onChange={date => handleChangeParams('final_date', date)}
            autoOk
            margin="none"
          />
        </div>
      </div>
      <div className={classes.actions}>
        <DisplayModeButtons
          displayMode={displayMode}
          handleShowList={() => setDisplayMode('list')}
          handleShowModule={() => setDisplayMode('module')}
        />
      </div>
    </FilterBox>
  );
};

export default BoardMovementsFilterBox;
