import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { Done } from '@material-ui/icons';

interface FiscalSerialAppbarButtonsProps {
  handleSubmit(): void;
}

const FiscalSerialAppbarButtons: React.FC<FiscalSerialAppbarButtonsProps> = ({ handleSubmit }) => {
  return (
    <Tooltip title="Salvar">
      <IconButton color="inherit" onClick={handleSubmit}>
        <Done />
      </IconButton>
    </Tooltip>
  );
};

export default FiscalSerialAppbarButtons;
