import { TableTemplate } from 'types/tableTemplate';

export const taxRulesTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 70,
  },
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 100,
  },
  {
    id: 'method',
    description: 'MÉTODO',
    originalId: 'method',
    width: 200,
  },
  {
    id: 'mode',
    description: 'MODO',
    originalId: 'mode',
    width: 100,
  },
  {
    id: 'kind',
    description: 'TIPO',
    originalId: 'kind',
    width: 100,
  },
  {
    id: 'sefaz_code',
    description: 'ID SEFAZ',
    originalId: 'sefaz_code',
    width: 100,
  },
  {
    id: 'formattedActive',
    description: 'ATIVO',
    originalId: 'activated',
    width: 100,
  },
  {
    id: 'formattedMustCreateReceipt',
    description: 'DOCUMENTO FISCAL',
    originalId: 'must_create_receipt',
    width: 200,
  },
  {
    id: 'formattedUpdatedAt',
    description: 'ATUALIZADO EM',
    originalId: 'created_at',
    width: 150,
  },
  {
    id: 'formattedCreatedAt',
    description: 'CRIADO EM',
    originalId: 'created_at',
    width: 150,
  },
];
