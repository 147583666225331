import React, { Fragment } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PropTypes from 'prop-types';

ProductComplementIngredientAction.propTypes = {
  handleAddComplementIngredient: PropTypes.func.isRequired,
  openDialogImport: PropTypes.func.isRequired,
};

function ProductComplementIngredientAction({ handleAddComplementIngredient, openDialogImport }) {
  return (
    <Fragment>
      <Tooltip title="Adicionar ingredientes">
        <IconButton onClick={handleAddComplementIngredient} color="inherit">
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Importar ingredientes">
        <IconButton color="inherit" onClick={openDialogImport}>
          <SaveAltIcon />
        </IconButton>
      </Tooltip>
    </Fragment>
  );
}

export default ProductComplementIngredientAction;
