import { useErrorHandler } from 'providers/error-handler/error-handler';
import { useEffect, useState } from 'react';
import { api } from 'services/api';
import { Deliverer } from 'types/deliverer';

type UseFetchOrderDeliverers = [Deliverer[], boolean];

export function useFetchOrderDeliverers(orderId?: string): UseFetchOrderDeliverers {
  const [deliverers, setDeliverers] = useState<Deliverer[]>([]);
  const [loading, setLoading] = useState(true);
  const { showErrorDialog } = useErrorHandler();

  useEffect(() => {
    if (!orderId) {
      return;
    }

    api
      .get(`/orders/${orderId}/deliverers`)
      .then(response => {
        setDeliverers(response.data);
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível recuperar os entregadores do pedido',
        });
      })
      .finally(() => setLoading(false));
  }, [showErrorDialog, orderId]);

  return [deliverers, loading];
}
