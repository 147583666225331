import React, { useCallback, useState } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import TaxRuleAppbarButtons from './TaxRuleAppbarButtons';
import PageHeader from 'components/page-header/PageHeader';
import { makeStyles } from '@material-ui/core';
import BoardForm from '../TaxRuleForm';
import { useTaxRuleValidation } from '../validation/useTaxRuleValidation';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import history from 'services/history';
import Loading from 'components/loading/Loading';
import { useTaxRuleReducer } from '../reducer/reducer';
import { taxRuleChange } from '../reducer/actions';
import { TaxRule } from 'pages/tax-rules/types/taxRule';
import { useErrorHandler } from 'providers/error-handler/error-handler';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 600,
  },
});

const TaxRuleNew: React.FC = () => {
  const classes = styles();

  const [taxRule, dispatch] = useTaxRuleReducer();
  const [validation, setValidation, validate] = useTaxRuleValidation();
  const messaging = useMessaging();
  const [saving, setSaving] = useState(false);
  const { showErrorDialog } = useErrorHandler();

  function handleValidation() {
    setValidation({});

    validate(taxRule)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/tax-rules', taxRule)
      .then(() => {
        messaging.handleOpen('Salvo');
        setSaving(false);
        history.push('/tax-rules');
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível salvar o regra fiscal',
        });
        setSaving(false);
      });
  }

  const handleChange = useCallback(
    (key: keyof TaxRule, value: any) => {
      dispatch(taxRuleChange(key, value));
    },
    [dispatch]
  );

  return (
    <>
      {saving && <Loading />}

      <CustomAppbar title="Regra fiscal" ActionComponents={<TaxRuleAppbarButtons handleSubmit={handleValidation} />} />

      <PageHeader
        title="Regra fiscal"
        description="Cadastro de nova regra fiscal"
        backAction={() => history.push('/tax-rules')}
      />

      <div className={classes.container}>
        <BoardForm handleChange={handleChange} taxRule={taxRule} validation={validation} />
      </div>
    </>
  );
};

export default TaxRuleNew;
