import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import PageHeader from 'components/page-header/PageHeader';
import { usePaymentMethods } from '../hooks/usePaymentMethods';
import { picpayConfigChange } from 'store/modules/payment/actions';

export default function PaymentPicPayConfig() {
  const { dispatch, picpaySettings } = usePaymentMethods();

  function handleChange(index, value) {
    dispatch(picpayConfigChange(index, value));
  }

  return (
    <Grid container>
      <Grid item xs={12} md={6} lg={4} xl={4}>
        <PageHeader
          title="Configurações para o PicPay"
          description="Os tokens podem ser obtidos acessando sua conta PicPay E-commerce em https://www.picpay.com/empresas"
        />
        <TextField
          label="Validade (minutos)"
          placeholder="Tempo em minutos para expirar link de pagamento"
          margin="normal"
          fullWidth
          value={picpaySettings.picpay_expiration_time}
          onChange={e => handleChange('picpay_expiration_time', e.target.value)}
          autoFocus
        />
        <TextField
          label="x-picpay-token"
          placeholder="Informe o x-picpay-token"
          margin="normal"
          fullWidth
          value={picpaySettings.picpay_token}
          onChange={e => handleChange('picpay_token', e.target.value)}
        />
        <TextField
          label="x-seller-token"
          placeholder="Informe o x-seller-token"
          margin="normal"
          fullWidth
          value={picpaySettings.picpay_seller_token}
          onChange={e => handleChange('picpay_seller_token', e.target.value)}
        />
      </Grid>
    </Grid>
  );
}
