import { FC, useState } from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import DialogInput, { DialogInputConsumer } from 'components/dialog/DialogInput';
import { useCategories } from './hooks/useCategories';
import { useErrorHandler } from 'providers/error-handler/error-handler';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-around',
    textAlign: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
});

interface DeleteCategoryConfirmationProps {
  onExited(): void;
}

const DeleteCategoryConfirmation: FC<DeleteCategoryConfirmationProps> = ({ onExited }) => {
  const classes = styles();
  const { handleDelete } = useCategories();
  const [deleting, setDeleting] = useState(false);
  const { showErrorDialog } = useErrorHandler();

  function handleSubmit(closeModal: () => void) {
    setDeleting(true);

    handleDelete()
      .then(closeModal)
      .catch(error => {
        showErrorDialog({
          error,
          message:
            error.response && error.response.data.code === 'categories.in_use'
              ? 'Essa categoria não pode ser excluída porque existem produtos relacionados'
              : 'Aconteceu um erro ao excluir a categoria',
        });
      })
      .finally(() => setDeleting(false));
  }

  return (
    <DialogInput maxWidth="sm" onExited={onExited} disableEscapeKeyDown disableBackdropClick>
      <DialogInputConsumer>
        {context => (
          <div className={classes.container}>
            <Typography variant="body1">Deseja realmente excluir a categoria?</Typography>

            <div className={classes.actions}>
              <Button onClick={context.handleCloseDialog} variant="text" color="primary">
                Cancelar
              </Button>
              <Button
                onClick={() => handleSubmit(context.handleCloseDialog)}
                disabled={deleting}
                variant="contained"
                color="primary"
              >
                Sim, excluir
              </Button>
            </div>
          </div>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default DeleteCategoryConfirmation;
