import React from 'react';
import List from 'components/list/List';
import CategoryItemModule from './IngredientItemModule';
import { Ingredient } from 'types/product';

type IngredientListModuleProps = {
  ingredients: Ingredient[];
};

const IngredientListModule: React.FC<IngredientListModuleProps> = ({ ingredients }) => {
  return (
    <List>
      {ingredients.map(ingredient => (
        <CategoryItemModule key={ingredient.id} ingredient={ingredient} />
      ))}
    </List>
  );
};

export default IngredientListModule;
