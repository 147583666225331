export function printerChange(index, value) {
  return {
    type: 'CHANGE',
    index,
    value,
  };
}

export function setPrinter(printer) {
  return {
    type: 'SET_PRINTER',
    printer,
  };
}
