import React from 'react';
import { List, ListItem, Typography } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
    gridGap: 6,
    padding: '10px 0',
  },
  listItem: {
    display: 'flex',
    backgroundColor: '#fff',
    border: '1px solid #eee',
    borderRadius: 4,
    position: 'relative',
    alignItems: 'center',
    minHeight: 120,
  },
  selected: {
    display: 'flex',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 120,
    border: `2px dashed ${theme.palette.primary.light}`,
    '&:focus': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
    },
  },
  img: {
    width: '100%',
    borderRadius: 4,
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: 100,
    borderRadius: 4,
    overflow: 'hidden',
    border: `2px solid #fff`,
  },
  productData: {
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1',
    alignItems: 'flex-start',
  },
  price: {
    fontWeight: 300,
  },
  oldPrice: {
    textDecoration: 'line-through',
  },
  specialPrice: {},
  specialPriceContent: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    minWidth: 135,
  },
}));

PromotionOfferedProductList.propTypes = {
  products: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default function PromotionOfferedProductList({ products, handleSelect }) {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      {products.map(product => {
        return (
          <ListItem
            button
            onClick={() => handleSelect(product)}
            key={product.id}
            className={product.selected ? classes.selected : classes.listItem}
          >
            <div className={classes.productData}>
              <Typography variant="h6">{product.name}</Typography>
              <Typography variant="body2" color="textSecondary">
                {product.description}
              </Typography>
              {product.promotion_activated && product.special_price > 0 ? (
                <div className={classes.specialPriceContent}>
                  <Typography variant="body2" color="textSecondary" className={classes.oldPrice}>
                    {product.formattedPrice}
                  </Typography>
                  <Typography variant="h6" className={classes.specialPrice} color="primary">
                    {product.formattedSpecialPrice}
                  </Typography>
                </div>
              ) : (
                product.price > 0 && (
                  <Typography variant="h6" className={classes.price} color="primary">
                    {product.formattedPrice}
                  </Typography>
                )
              )}
              {product.category.has_complement && <Typography color="primary">Produto com complemento</Typography>}
            </div>
            <div className={classes.imageContainer}>
              {product.image && (
                <img
                  className={classes.img}
                  src={product.image.imageThumbUrl ? product.image.imageThumbUrl : product.image.imageUrl}
                  alt={product.name}
                />
              )}
            </div>
          </ListItem>
        );
      })}
    </List>
  );
}
