import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'store/redux/selector';
import { OrderProduct } from 'types/order';
import { moneyFormat } from 'helpers/NumberFormat';
import { getPizzaComplementsPrice } from 'store/redux/modules/order/cases/addProduct';
import { Product } from 'types/product';
import Modal from 'components/modal/Modal';
import { useBoardControl } from '../../../hooks/useBoardControl';
import BoardProductDetailActions from '../BoardProductDetailActions';
import { ProductPizzaProvider } from '../hooks/useProductPizza';
import ProductPizzaDetail from './ProductPizzaDetail';

interface ProductPizzaComplementProps {
  onExited(): void;
  hideBackdrop?: boolean;
}

export default function ProductPizzaComplement({ onExited, hideBackdrop = false }: ProductPizzaComplementProps) {
  const restaurant = useSelector(state => state.restaurant);
  const { selectedProduct } = useBoardControl();
  const [amount] = useState(selectedProduct?.amount ?? 0);
  const [product] = useState<OrderProduct | null>(JSON.parse(JSON.stringify(selectedProduct)));
  const [complementsPrice, setComplementsPrice] = useState(0);

  const categoryComplementSize = useMemo(
    () => product?.complement_categories.find(category => category.is_pizza_size) || null,
    [product]
  );

  const complementSizeSelected = useMemo(
    () => categoryComplementSize?.complements.find(complement => complement.selected) || null,
    [categoryComplementSize]
  );

  const formattedTotal = useMemo(() => {
    if (!product) {
      return moneyFormat(0);
    }

    const total = (complementsPrice + product.product_price) * amount;
    return moneyFormat(total);
  }, [amount, complementsPrice, product]);

  useEffect(() => {
    if (!restaurant || !product) {
      return;
    }

    const price = getPizzaComplementsPrice(product.complement_categories, restaurant.configs);

    setComplementsPrice(price);
  }, [product, restaurant]);

  const productPizzaContextValue = {
    product: product as Product,
    complementSizeSelected,
  };

  return (
    <Modal
      backgroundColor="#fafafa"
      onExited={onExited}
      title="Detalhes do produto"
      displayBottomActions
      maxWidth="lg"
      height="80vh"
      hideBackdrop={hideBackdrop}
    >
      <ProductPizzaProvider value={productPizzaContextValue}>
        <ProductPizzaDetail />
      </ProductPizzaProvider>

      <BoardProductDetailActions product={product} total={formattedTotal} amount={amount} />
    </Modal>
  );
}
