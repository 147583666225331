import React, { useCallback, useEffect, useState } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import { makeStyles } from '@material-ui/core';
import { api, getCancelTokenSource } from 'services/api';
import NoData from 'components/nodata/NoData';
import { parseISO, format, isAfter, addMinutes } from 'date-fns';
import OrderSearch from './search/OrderSearch';
import Loading from 'components/loading/Loading';
import DialogDelete from 'components/dialog/delete/DialogDelete';
import { getStatusText } from './boardOrderStatusMapping';
import { useSelector } from 'store/redux/selector';
import { OrderStatusOptions } from 'types/orderStatus';
import { OrderList } from './types/orderList';
import PaginationProvider from 'hooks/pagination';
import OrderListModule from './list/module/OrderListModule';
import ptBR from 'date-fns/locale/pt-BR';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import TableLoading from 'components/loading/TableLoading';
import OrderListTable from './list/table/OrderListTable';
import useTableOrder from 'hooks/tableOrder';
import ApiPagination from 'components/_pagination/ApiPagination';
import OrdersFilterBox from './OrdersFilterBox';
import { OrdersContextProvider, OrdersContextValue } from './hook/useOrders';
import ApprovedBoardOrder from 'pages/orders/print/ApprovedBoardOrder';
import { useBoardOrdersSocket } from './hook/useBoardOrdersSocket';
import history from 'services/history';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { useFetchBoardOrders } from './hook/useFetchBoardOrders';
import { useOrdersPageTitle } from 'pages/orders/hook/useOrdersPageTitle';
import OrderHistoriesDialog from 'pages/orders/histories/OrderHistoriesDialog';
import OrderLoading from 'pages/orders/OrderLoading';
import { formatDistance } from 'pages/orders/formatDistance';
import { useGridMode } from 'hooks/useGridMode';
import { useMessaging } from 'hooks/messaging';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

export type BoardOrdersQueryParams = {
  status: OrderStatusOptions;
  initial_date: null | Date;
  final_date: null | Date;
  page: number;
  rows: number;
};

let timer: NodeJS.Timeout;

const BoardOrders: React.FC = () => {
  const classes = useStyles();
  const [filtered, setFiltered] = useState<OrderList[]>([]);
  const [saving, setSaving] = useState(false);
  const [dialogSearch, setDialogSearch] = useState(false);
  const [dialogPrintOrder, setDialogPrintOrder] = useState(false);
  const [dialogCancelConfirm, setDialogCancelConfirm] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderList | null>(null);
  const [dialogTrack, setDialogTrack] = useState(false);
  const [displayMode, setDisplayMode] = useGridMode('board-orders-grid-mode');
  const [orderedIndex, sort] = useTableOrder();
  const restaurant = useSelector(state => state.restaurant);
  const [term, setTerm] = useState('');
  const { showErrorDialog } = useErrorHandler();
  const { orders, setOrders, fetch, loading, setQueryParams, queryParams, total } = useFetchBoardOrders();
  const { handleOpen } = useMessaging();

  useBoardOrdersSocket(orders, setOrders);
  useOrdersPageTitle(orders);

  const sendToPrintOrder = useCallback(() => {
    if (!selectedOrder) return;

    setSaving(true);
    api
      .post('/orders/print/now', { order_id: selectedOrder.id })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setSaving(false);
      });
  }, [selectedOrder]);

  const handleOrderStatus = useCallback(
    (status?: OrderStatusOptions, deliveryId?: number) => {
      if (!selectedOrder) return;
      if (!restaurant) return;

      const data = {
        order_id: selectedOrder.id,
        status,
        deliverer_id: deliveryId || null,
      };

      setSaving(true);

      api
        .post('orderStatus', data)
        .then(response => {
          const orderStatus = response.data.order_status;
          const deliverers = response.data.deliverers;

          setOrders(orders =>
            orders.map(order => {
              if (order.id === selectedOrder.id && order.status !== orderStatus.status) {
                order.status = orderStatus.status;
                order.statusText = getStatusText(orderStatus.status);
                order.order_status = [
                  {
                    ...orderStatus,
                    formattedDate: format(parseISO(orderStatus.created_at), "PP 'às' p", { locale: ptBR }),
                  },
                  ...order.order_status,
                ];
                order.isNew = false;
                selectedOrder.deliverers = deliverers || order.deliverers;
              }
              return order;
            })
          );

          if (restaurant.configs.use_printer) {
            setSelectedOrder(null);
            return;
          }

          if (orderStatus.status === 'a') {
            setDialogPrintOrder(true);
          }
        })
        .catch(error => {
          showErrorDialog({
            error,
            message: 'Não foi possível atualizar o status do pedido',
          });
        })
        .finally(() => {
          setSaving(false);
        });
    },
    [restaurant, selectedOrder, setOrders, showErrorDialog]
  );

  useEffect(() => {
    setFiltered(orders);
  }, [orders]);

  useEffect(() => {
    if (!restaurant) {
      return;
    }

    let request = true;

    const cancelToken = getCancelTokenSource();

    fetch('', cancelToken);

    return () => {
      if (request) {
        cancelToken.cancel();
      }

      request = false;
    };
  }, [fetch, restaurant]);

  useEffect(() => {
    const amount = orders.reduce((sum, order) => (order.status === 'o' ? sum + 1 : sum), 0);

    if (amount > 0) {
      document.title = `(${amount}) SGrande Delivery`;
    }

    return () => {
      document.title = 'SGrande Delivery';
    };
  }, [orders]);

  useEffect(() => {
    if (!restaurant) {
      return;
    }

    const timer = setInterval(() => {
      setFiltered(orders =>
        orders.map(order => {
          order.dateDistance = formatDistance(parseISO(order.created_at), new Date());
          order.isLate = isAfter(new Date(), addMinutes(parseISO(order.created_at), restaurant.configs.delivery_time));
          return order;
        })
      );
    }, 60000);

    return () => {
      clearInterval(timer);
    };
  }, [restaurant]);

  function handleSearchTermChange(value: string) {
    setTerm(value);

    clearTimeout(timer);

    if (value.length < 2) {
      return;
    }

    timer = setTimeout(() => fetch(value), 500);
  }

  function handleQueryParamsChange(index: keyof BoardOrdersQueryParams, value: any) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleCloseDialogPrintOrder() {
    setDialogPrintOrder(false);
    setSelectedOrder(null);
  }

  function handleCancelConfirm() {
    handleOrderStatus('x');
  }

  function handleCreateReceipt() {
    setSaving(true);

    const data = {
      additional_data: `pedido #${selectedOrder?.sequence} de ${selectedOrder?.formattedCreatedAt}`,
    };

    api
      .post(`/orders/${selectedOrder?.uuid}/receipts`, data)
      .then(() => {
        handleOpen('Cupom fiscal em processamento');
      })
      .catch(error => {
        showErrorDialog({
          message: 'Não foi possível emitir o cupom fiscal',
          error,
        });
      })
      .finally(() => setSaving(false));
  }

  const ordersContextValue: OrdersContextValue = {
    orders,
    selectedOrder,
    sendToPrintOrder,
    handleOrderStatus,
    setSelectedOrder,
    handleCancel: () => setDialogCancelConfirm(true),
    setDialogTrack: state => setDialogTrack(state),
    setDialogPrintOrder: state => setDialogPrintOrder(state),
    handleCreateReceipt,
  };

  return (
    <OrdersContextProvider value={ordersContextValue}>
      {saving && <Loading />}

      {dialogTrack && <OrderHistoriesDialog order={selectedOrder} onExited={() => setDialogTrack(false)} />}

      {dialogPrintOrder && selectedOrder && (
        <ApprovedBoardOrder onExited={handleCloseDialogPrintOrder} orderId={selectedOrder.uuid} />
      )}

      {dialogCancelConfirm && selectedOrder && (
        <DialogDelete
          title="Confirmação"
          onExited={() => setDialogCancelConfirm(false)}
          handleDelete={handleCancelConfirm}
          message={`Deseja realmente cancelar o pedido ${selectedOrder.formattedId}?`}
          buttonText="Cancelar agora!"
        />
      )}

      {dialogSearch && (
        <OrderSearch
          onExited={() => setDialogSearch(false)}
          queryParams={queryParams}
          handleQueryParamsChange={handleQueryParamsChange}
        />
      )}

      <CustomAppbar title="pedidos" />

      <PageHeaderActions
        title="Pedidos de mesa"
        description="Gerencie seus pedidos"
        backAction={() => history.push('/board-management')}
      />

      <OrdersFilterBox
        displayMode={displayMode}
        setDisplayMode={setDisplayMode}
        queryParams={queryParams}
        handleQueryParamsChange={handleQueryParamsChange}
        handleSearchTermChange={handleSearchTermChange}
        term={term}
      />

      <PaginationProvider>
        {loading ? (
          displayMode === 'module' ? (
            <OrderLoading />
          ) : (
            <TableLoading />
          )
        ) : filtered.length > 0 ? (
          <div className={classes.container}>
            {displayMode === 'module' ? (
              <OrderListModule orders={filtered} />
            ) : (
              <OrderListTable handleSort={handleSort} orderedIndex={orderedIndex} orders={filtered} />
            )}
          </div>
        ) : (
          filtered.length === 0 && <NoData message="Nenhum pedido para mostrar." backgroundColor="inherit" />
        )}

        <ApiPagination
          onChangePage={value => handleQueryParamsChange('page', value)}
          onChangeRowsPerPage={value => handleQueryParamsChange('rows', value)}
          count={total}
        />
      </PaginationProvider>
    </OrdersContextProvider>
  );
};

export default BoardOrders;
