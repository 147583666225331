import { useErrorHandler } from 'providers/error-handler/error-handler';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { api, getCancelTokenSource } from 'services/api';
import { PaymentMethod, PaymentMethodForList } from 'types/paymentMethod';
import { usePaymentMethodFormatter } from './usePaymentMethodFormatter';

export type PaymentMethodsQueryParams = {
  mode: string;
  active: string;
};

type UseFetchPaymentMethods = [
  PaymentMethodForList[],
  boolean,
  PaymentMethodsQueryParams,
  Dispatch<SetStateAction<PaymentMethodsQueryParams>>
];

const initialQueryParams: PaymentMethodsQueryParams = {
  mode: 'offline',
  active: '1',
};

export function useFetchPaymentMethods(): UseFetchPaymentMethods {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodForList[]>([]);
  const [queryParams, setQueryParams] = useState<PaymentMethodsQueryParams>(initialQueryParams);
  const [loading, setLoading] = useState(true);
  const { showErrorDialog } = useErrorHandler();
  const [formatter] = usePaymentMethodFormatter();

  useEffect(() => {
    let request = true;
    const source = getCancelTokenSource();

    setLoading(true);

    api
      .get<PaymentMethod[]>('payment-methods', { cancelToken: source.token, params: queryParams })
      .then(response => {
        setPaymentMethods(response.data.map(paymentMethod => formatter(paymentMethod)));
      })
      .catch(err => {
        showErrorDialog({
          error: err,
          message: 'Não foi possível recuperar as formas de pagamento',
        });
      })
      .finally(() => {
        if (request) {
          setLoading(false);
        }
        request = false;
      });

    return () => {
      if (request) source.cancel();
      request = false;
    };
  }, [showErrorDialog, formatter, queryParams]);

  return [paymentMethods, loading, queryParams, setQueryParams];
}
