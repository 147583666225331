import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useCallback, useEffect, useState } from 'react';
import { api } from 'services/api';
import { Ingredient } from 'types/product';

type UseFetchIngredients = [boolean, Ingredient[], number, (text: string, page: number, rows: number) => void];

export function useFetchIngredients(): UseFetchIngredients {
  const [ingredients, setIngredients] = useState<Ingredient[]>([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const fetch = useCallback((text = '', page = 0, rows = 20) => {
    if (text.length > 0 && text.length <= 3) {
      return;
    }

    setLoading(true);

    api
      .get('/ingredients', {
        params: { page: page + 1, rows, q: text },
      })
      .then(response => {
        setTotal(response.data.ingredients.total);

        const _data = response.data.ingredients.data.map(ingredient => {
          ingredient.formattedCreatedAt = format(parseISO(ingredient.created_at), 'Pp', { locale: ptBR });
          ingredient.formattedActive = ingredient.activated ? 'Sim' : 'Não';
          return ingredient;
        });
        setIngredients(_data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return [loading, ingredients, total, fetch];
}
