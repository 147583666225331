import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { CashRegisterWithMovements } from 'types/cashRegister';
import { CashRegisterMovement } from 'types/cashRegisterMovement';

export interface UseCashRegisterContextValue {
  cashRegister: CashRegisterWithMovements | null;
  isOpenedDebitDialog: boolean;
  setIsOpenedDebitDialog: Dispatch<SetStateAction<boolean>>;
  setCashRegister: Dispatch<SetStateAction<CashRegisterWithMovements | null>>;
  currentValue: number;
  formattedCurrentValue: string;
  handleDeleteMovement(movementId: string): Promise<void>;
  selectedMovement: CashRegisterMovement | null;
  setSelectedMovement: Dispatch<SetStateAction<CashRegisterMovement | null>>;
  isOpenedDeleteMovementDialog: boolean;
  setIsOpenedDeleteMovementDialog: Dispatch<SetStateAction<boolean>>;
  isOpenedCloseCashRegisterDialog: boolean;
  setIsOpenedCloseCashRegisterDialog: Dispatch<SetStateAction<boolean>>;
  handleCloseCashRegister(): Promise<void>;
  credit: number;
  debit: number;
  isOpenedExtractReport: boolean;
  setIsOpenedExtractReport: Dispatch<SetStateAction<boolean>>;
  isOpenedReport: boolean;
  setIsOpenedReport: Dispatch<SetStateAction<boolean>>;
}

const CashRegisterContext = createContext({} as UseCashRegisterContextValue);
export const CashRegisterFlowProvider = CashRegisterContext.Provider;
export const CashRegisterFlowConsumer = CashRegisterContext.Consumer;

export function useCashRegisterFlow(): UseCashRegisterContextValue {
  return useContext(CashRegisterContext);
}
