import { createContext, useContext, MouseEvent, Dispatch, SetStateAction } from 'react';
import { Category } from 'types/category';
import { OrderProduct } from 'types/order';
import { PaymentMethod } from 'types/paymentMethod';
import { Product } from 'types/product';

export type OrderContextValue = {
  handleNext(event?: MouseEvent<HTMLButtonElement>): void;
  handlePrior(): void;
  handleAddProduct(product: Product): void;
  handleUpdateProduct(product: OrderProduct, amount: number): void;
  handleRemoveProduct(uid: string): void;
  handleUpdateComplements(product: OrderProduct, amount: number): void;
  handleSetChange(value: number): void;
  handleSetAnnotation(annotation: string): void;
  handleSetPaymentMethod(paymentMethod: PaymentMethod): void;
  handleAddCategory(category: Category): void;
  handleSubmit(customerName?: string): void;
  setShowSearchDialog: Dispatch<SetStateAction<boolean>>;
  setStep: Dispatch<SetStateAction<number>>;
  loadingCategories: boolean;
  categories: Category[];
  paymentMethods: PaymentMethod[];
  step: number;
  selectedCategory: null | Category;
  saving: boolean;
};

const OrderContext = createContext<OrderContextValue>({} as OrderContextValue);
export const OrderContextProvider = OrderContext.Provider;

export function useOrder(): OrderContextValue {
  const context = useContext(OrderContext);
  return context;
}
