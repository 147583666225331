import React from 'react';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import history from 'services/history';

const styles = makeStyles(theme => ({
  tooltip: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

const AdditionalActions: React.FC = () => {
  const classes = styles();

  return (
    <Tooltip title="Cadastrar adicional" className={classes.tooltip}>
      <IconButton onClick={() => history.push('/menu/additional/new')} color={'inherit'}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
};

export default AdditionalActions;
