import { moneyFormat, numberFormat } from 'helpers/NumberFormat';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import packageJson from '../../../../../package.json';
import { Order } from 'types/order';
import { Customer } from 'types/customer';
import { OrderActions } from './types';
import { v4 as uuid } from 'uuid';
import { updateTotal } from './cases/updateTotal';
import { addProduct, getComplementsPrice } from './cases/addProduct';
import { setOrder } from './cases/setOrder';
import { Shipment } from 'types/shipment';

export const INITIAL_STATE: Order = {
  uuid: uuid(),
  id: 0,
  created_at: new Date().toISOString(),
  status: 'a',
  pix_payment: null,
  picpay_payment: null,
  order_status: [],
  customer_name: '',
  deliverers: [],
  formattedTax: '',
  formattedDiscount: '',
  formattedCreatedAt: '',
  formattedChangeTo: '',
  settings: null,
  customer: null,
  payment_method: null,
  shipment: null,
  products: [],
  coupon: null,
  change: 0,
  total: 0,
  discount: 0,
  subtotal: 0,
  tax: 0,
  promotionDiscount: 0,
  formattedTotal: '',
  board_movement_id: null,
  formattedChange: '',
  formattedId: '',
  annotation: '',
  formattedSubtotal: '',
  origin: {
    version: packageJson.version,
    app_name: packageJson.name,
    platform: 'admin',
  },
  statusText: '',
  formattedSequence: '',
  sequence: 0,
  board_movement: null,
};

export default function order(state = INITIAL_STATE, action: OrderActions): Order {
  switch (action.type) {
    case '@order/SET_INITIAL_STATE': {
      return INITIAL_STATE;
    }

    case '@order/SET_ORDER': {
      const order = setOrder({ ...action.order, settings: state.settings });

      return {
        ...state,
        ...order,
      };
    }

    case '@order/SET_CUSTOMER': {
      if (!action.customer) {
        return {
          ...state,
          customer: null,
        };
      }

      const customer: Customer = {
        ...action.customer,
        addresses: action.customer.addresses.map(address => ({
          ...address,
          formattedDistanceTax: moneyFormat(address.distance_tax),
          formattedDistance: numberFormat(address.distance) + ' km',
        })),
      };

      const address = customer.addresses.find(address => address.is_main);

      if (!address) {
        return {
          ...state,
          customer,
        };
      }

      return {
        ...state,
        customer,
        shipment: {
          ...address,
          formattedScheduledAt: '',
          shipment_method: 'delivery',
          scheduled_at: null,
        },
      };
    }

    case '@order/SET_CUSTOMER_ADDRESSES': {
      if (!state.customer) {
        return state;
      }

      return {
        ...state,
        customer: {
          ...state.customer,
          addresses: action.addresses.map(address => ({
            ...address,
            formattedDistanceTax: moneyFormat(address.distance_tax),
            formattedDistance: numberFormat(address.distance) + ' km',
          })),
        },
      };
    }

    case '@order/ADD_PRODUCT': {
      const product = addProduct(state, action.product, action.amount);

      return {
        ...state,
        products: [...state.products, product],
      };
    }

    case '@order/REMOVE_PRODUCT': {
      const products = state.products.filter(product => product.uid !== action.uid);

      return {
        ...state,
        products,
      };
    }

    case '@order/SET_SHIPMENT_METHOD': {
      if (!state.shipment) {
        return {
          ...state,
          shipment: {
            shipment_method: action.method,
          } as Shipment,
        };
      }

      if (action.method !== 'delivery') {
        return {
          ...state,
          shipment: {
            shipment_method: action.method,
          } as Shipment,
        };
      }

      return {
        ...state,
        shipment: {
          ...state.shipment,
          shipment_method: action.method,
        },
      };
    }

    case '@order/SET_SHIPMENT_ADDRESS': {
      if (!action.address) {
        return {
          ...state,
          shipment: null,
        };
      }

      if (!state.shipment) {
        return {
          ...state,
          shipment: {
            ...action.address,
            scheduled_at: null,
            shipment_method: 'delivery',
            formattedScheduledAt: '',
          },
        };
      }

      return {
        ...state,
        shipment: {
          ...state.shipment,
          ...action.address,
        },
      };
    }

    case '@order/ADD_CUSTOMER_ADDRESS': {
      if (!state.customer) {
        return state;
      }

      return {
        ...state,
        customer: {
          ...state.customer,
          addresses: [
            ...state.customer.addresses,
            {
              ...action.address,
              formattedDistanceTax: moneyFormat(action.address.distance_tax),
              formattedDistance: numberFormat(action.address.distance) + ' km',
            },
          ],
        },
      };
    }

    case '@order/UPDATE_CUSTOMER_ADDRESS': {
      if (!state.customer) {
        return state;
      }

      return {
        ...state,
        customer: {
          ...state.customer,
          addresses: state.customer.addresses.map(address => {
            if (address.id === action.address.id) {
              return {
                ...action.address,
                formattedDistanceTax: moneyFormat(action.address.distance_tax),
                formattedDistance: numberFormat(action.address.distance) + ' km',
              };
            }

            return address;
          }),
        },
      };
    }

    case '@order/UPDATE_AMOUNT': {
      const products = state.products.map(product => {
        if (product.uid === action.uid) {
          product.amount = action.amount;
          product.final_price = action.amount * product.price;
          product.formattedFinalPrice = moneyFormat(action.amount * product.price);
          product.formattedAmount = numberFormat(action.amount, 2);
        }

        return product;
      });

      return {
        ...state,
        products,
      };
    }

    case '@order/UPDATE_PRODUCT': {
      const products = state.products.map(product => {
        if (product.uid !== action.product.uid) {
          return product;
        }

        const additionalPrice = action.product.additional
          .filter(item => item.selected)
          .reduce((value, additional) => value + additional.price * additional.amount, 0);

        const complementsPrice = getComplementsPrice(action.product, state?.settings);

        const price = action.product.product_price + additionalPrice + complementsPrice;

        const finalPrice = price * action.amount;

        return {
          ...action.product,
          amount: action.amount,
          price,
          final_price: finalPrice,
          formattedPrice: moneyFormat(price),
          formattedFinalPrice: moneyFormat(finalPrice),
          additionalPrice,
          formattedAmount: numberFormat(action.amount, 2),
        };
      });

      return {
        ...state,
        products,
      };
    }

    case '@order/SET_ANNOTATION': {
      return {
        ...state,
        annotation: action.annotation,
      };
    }

    case '@order/SET_PAYMENT_METHOD': {
      return {
        ...state,
        payment_method: action.paymentMethod,
      };
    }

    case '@order/UPDATE_COMPLEMENTS': {
      const product = addProduct(state, action.product, action.amount);

      return {
        ...state,
        products: state.products.map(item => (item.uid === action.product.uid ? product : item)),
      };
    }

    case '@order/SET_CHANGE': {
      return {
        ...state,
        change: action.value,
        formattedChange: moneyFormat(action.value),
      };
    }

    case '@order/SET_CONFIGS': {
      return {
        ...state,
        settings: action.settings,
      };
    }

    case '@order/SET_TAX': {
      return {
        ...state,
        tax: action.tax,
        formattedTax: moneyFormat(action.tax),
      };
    }

    case '@order/SET_SCHEDULE': {
      if (!state.shipment) {
        return state;
      }

      return {
        ...state,
        shipment: {
          ...state.shipment,
          scheduled_at: action.date,
          formattedScheduledAt: action.date ? format(action.date, 'HH:mm', { locale: ptBR }) : '',
        },
      };
    }

    case '@order/SET_BOARD_MOVEMENT_ID': {
      return {
        ...state,
        board_movement_id: action.boardMovementId,
      };
    }

    case '@order/UPDATE_TOTAL': {
      return updateTotal(state);
    }

    case '@order/SET_ORDER_CUSTOMER_NAME': {
      return {
        ...state,
        customer_name: action.customerName,
        customer: {
          name: action.customerName,
        } as any,
      };
    }

    default: {
      return state;
    }
  }
}
