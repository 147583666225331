import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import CouponItemTable from './CouponItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import history from 'services/history';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { Coupon } from 'types/coupon';
import { couponsTableTemplate } from 'pages/coupons/couponsTableTemplate';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type CouponListTableProps = {
  coupons: Coupon[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const CouponListTable: React.FC<CouponListTableProps> = ({ coupons, handleSort, orderedIndex }) => {
  const classes = useStyles();

  return (
    <TableContainer tableTemplate={couponsTableTemplate}>
      <TableContent>
        <TableHeader>
          {couponsTableTemplate
            .filter(item => !item.notShow)
            .map(item => (
              <div
                className={classes.headerItem}
                key={item.id}
                onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
              >
                <Typography noWrap variant="caption" color="primary">
                  {item.description}
                </Typography>
                {orderedIndex.index === item.originalId && (
                  <>
                    {orderedIndex.direction === 'asc' ? (
                      <ArrowUpward color="primary" />
                    ) : (
                      <ArrowDownward color="primary" />
                    )}
                  </>
                )}
              </div>
            ))}
        </TableHeader>
        <TableBody>
          {coupons.map(coupon => (
            <TableRow key={coupon.id} onClick={() => history.push(`/coupons/${coupon.id}`)}>
              <CouponItemTable coupon={coupon} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </TableContainer>
  );
};

export default CouponListTable;
