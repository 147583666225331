export function download(content: string, filename: string) {
  const blob = new Blob([content]);

  const url = URL.createObjectURL(blob);

  const anchor = createLink(url, filename);

  handleDownloadClick(anchor, url);
}

function createLink(url: string, filename: string) {
  const anchor = document.createElement('a');

  anchor.href = url;
  anchor.style.display = 'none';
  anchor.download = filename;

  document.body.appendChild(anchor);

  return anchor;
}

function handleDownloadClick(anchor: HTMLAnchorElement, url: string) {
  anchor.click();

  document.body.removeChild(anchor);

  URL.revokeObjectURL(url);
}
