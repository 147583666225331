import React, { useState } from 'react';
import { List, ListItem, Typography, Menu, MenuItem, IconButton } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MoreIcon from '@material-ui/icons/MoreHoriz';

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    borderBottom: '1px solid #f5f5f5',
    position: 'relative',
  },
  selected: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #f5f5f5',
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
    position: 'relative',
    '&:focus': {
      backgroundColor: alpha(theme.palette.primary.main, 0.05),
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.15),
    },
  },
  list: {
    padding: 0,
  },
  icon: ({ isPizzaTaste }) => ({
    backgroundColor: '#fff',
    borderRadius: '50%',
    position: 'absolute',
    right: isPizzaTaste ? 10 : 15,
    top: isPizzaTaste ? 10 : 'inherit',
  }),
  complementName: {
    fontWeight: 400,
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
    width: 70,
    height: 70,
    overflow: 'hidden',
    borderRadius: '50%',
  },
  image: {
    border: `2px solid #fff`,
    width: '100%',
  },
  complementData: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  price: {
    fontWeight: 500,
  },
  more: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  additional: {
    color: '#4CAF50',
    marginRight: 6,
  },
  ingredients: {
    color: '#c53328',
    marginRight: 6,
  },
}));

function ProductPizzaComplementItem({
  complements,
  handleClickPizzaComplements,
  productId,
  category,
  setComplementCategoryIdSelected,
  setComplementIdSelected,
  openDialogAdditional,
  openDialogIngredients,
}) {
  const classes = useStyles({ isPizzaTaste: category.is_pizza_taste });
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClickMore(event, complementId) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setComplementIdSelected(complementId);
    setComplementCategoryIdSelected(category.id);
  }

  return (
    <>
      {category.is_pizza_taste && (
        <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
          <MenuItem
            onClick={() => {
              openDialogAdditional();
              setAnchorEl(null);
            }}
          >
            Adicionais
          </MenuItem>
          <MenuItem
            onClick={() => {
              openDialogIngredients();
              setAnchorEl(null);
            }}
          >
            Ingredientes
          </MenuItem>
        </Menu>
      )}
      <List className={classes.list}>
        {complements.map(complement => (
          <ListItem
            className={complement.selected ? classes.selected : classes.listItem}
            button
            key={complement.id}
            onClick={() => handleClickPizzaComplements(productId, category.id, complement.id)}
          >
            {category.is_pizza_taste && (
              <IconButton className={classes.more} onClick={event => handleClickMore(event, complement.id)}>
                <MoreIcon />
              </IconButton>
            )}
            <div className={classes.complementData}>
              {complement.image && (
                <div className={classes.imageContainer}>
                  <img className={classes.image} src={complement.image.imageUrl} alt={complement.name} />
                </div>
              )}
              <div>
                <Typography variant="body1" className={classes.complementName}>
                  {complement.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {complement.description}
                </Typography>
                <div>
                  {complement.ingredients &&
                    complement.ingredients.map(
                      ingredient =>
                        !ingredient.selected && (
                          <Typography
                            key={ingredient.id}
                            variant="caption"
                            display="inline"
                            className={classes.ingredients}
                          >
                            - {ingredient.name}
                          </Typography>
                        )
                    )}
                </div>
                <div>
                  {complement.additional &&
                    complement.additional.map(
                      additional =>
                        additional.selected && (
                          <Typography
                            key={additional.id}
                            variant="caption"
                            display="inline"
                            className={classes.additional}
                          >
                            + {additional.name} {additional.prices.find(price => price.selected).formattedPrice}
                          </Typography>
                        )
                    )}
                </div>
                {complement.prices.map(
                  price =>
                    price.selected &&
                    price.price && (
                      <Typography className={classes.price} key={price.id} color="primary">
                        {!category.is_pizza_taste && <span>+</span>} {price.formattedPrice}
                      </Typography>
                    )
                )}
              </div>
            </div>
            {complement.selected && <CheckCircleIcon className={classes.icon} color="primary" />}
          </ListItem>
        ))}
      </List>
    </>
  );
}

export default ProductPizzaComplementItem;
