import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import BoardItemTable from './BoardItemTable';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { usePagination } from 'hooks/pagination';
import history from 'services/history';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { useTable } from 'components/table/hook/useTable';
import { Board } from 'types/board';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type BoardListTableProps = {
  boards: Board[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const BoardListTable: React.FC<BoardListTableProps> = ({ boards, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const { rowsPerPage, page } = usePagination();
  const { tableTemplate } = useTable();

  return (
    <>
      <TableContent>
        <TableHeader>
          {tableTemplate.map(item => (
            <div
              className={classes.headerItem}
              key={item.id}
              onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
            >
              <Typography noWrap variant="caption" color="primary">
                {item.description}
              </Typography>
              {orderedIndex.index === item.originalId && (
                <>
                  {orderedIndex.direction === 'asc' ? (
                    <ArrowUpward color="primary" />
                  ) : (
                    <ArrowDownward color="primary" />
                  )}
                </>
              )}
            </div>
          ))}
        </TableHeader>
        <TableBody>
          {boards.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(board => (
            <TableRow
              style={!board.active ? { backgroundColor: 'RGBA(255,0,0,0.2)' } : undefined}
              key={board.id}
              onClick={() => history.push(`/board/${board.id}`)}
            >
              <BoardItemTable board={board} />
            </TableRow>
          ))}
        </TableBody>
      </TableContent>
    </>
  );
};

export default BoardListTable;
