import React, { useState, useEffect, useCallback } from 'react';
import Appbar from 'components/appbar/Appbar';
import ProductsAction from 'pages/products/ProductsAction';
import ProductLoading from 'pages/products/ProductsLoading';
import { Button, makeStyles } from '@material-ui/core';
import { api } from 'services/api';
import NoData from 'components/nodata/NoData';
import { moneyFormat } from 'helpers/NumberFormat';
import history from 'services/history';
import useTableOrder from 'hooks/tableOrder';
import { Product } from 'types/product';
import { useApp } from 'App';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import { productsTableTemplate } from './productsTableTemplate';
import TableContainer from 'components/table/TableContainer';
import TableLoading from 'components/loading/TableLoading';
import PaginationProvider from 'hooks/pagination';
import ProductListTable from './list/table/ProductListTable';
import ProductListModule from './list/module/ProductListModule';
import ProductsFilterBox from './ProductsFilterBox';
import { ProductsContextProvider } from './hooks/useProducts';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useMessaging } from 'hooks/messaging';
import Loading from 'components/loading/Loading';
import ApiPagination from 'components/_pagination/ApiPagination';
import DeleteProductConfirmation from './DeleteProductConfirmation';
import { download } from 'helpers/download';
import { uuidv4 } from 'helpers/uuid';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

let timer: NodeJS.Timeout;

const Products: React.FC = () => {
  const classes = useStyles();
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<Product[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const app = useApp();
  const [selectedProduct, setSelectedProduct] = useState<null | Product>(null);
  const { handleOpen } = useMessaging();
  const [saving, setSaving] = useState(false);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(20);
  const [total, setTotal] = useState(0);
  const [isOpenDeleteConfirmation, setIsOpenDeleteConfirmation] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const fetchProducts = useCallback(
    (query: string) => {
      setLoading(true);

      api
        .get('/products', { params: { page: page + 1, rows, q: query } })
        .then(response => {
          setTotal(response.data.total);

          const productsResponse: Product[] = response.data.data;
          setProducts(
            productsResponse.map(product => {
              product.formattedPrice = moneyFormat(product.price);
              product.formattedSpecialPrice = moneyFormat(product.special_price);
              product.hasComplements = product.category.has_complement ? 'Sim' : 'Não';
              product.formattedActive = product.activated ? 'Sim' : 'Não';
              product.formattedCreatedAt = format(parseISO(product.created_at), 'PPp', { locale: ptBR });
              product.categoryName = product.category.name;
              product.formattedIsAvailableOnBoard = product.is_available_on_board ? 'Sim' : 'Não';
              product.formattedIsAvailableOnDelivery = product.is_available_on_delivery ? 'Sim' : 'Não';
              return product;
            })
          );
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [rows, page]
  );

  useEffect(() => {
    fetchProducts('');
  }, [fetchProducts]);

  useEffect(() => {
    setFiltered(products);
  }, [products]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleProductActive() {
    if (!selectedProduct) return;
    setSaving(true);

    api
      .put(`/products/status/${selectedProduct.id}`)
      .then(() => {
        handleOpen('Atualizado');
        setProducts(products =>
          products.map(product => {
            product.activated = product.id === selectedProduct.id ? !product.activated : product.activated;
            return product;
          })
        );
        setSaving(false);
      })
      .catch(err => {
        handleOpen(err.response ? err.response.data.error : 'Não foi possível alterar o produto');
        setSaving(false);
      });
  }

  function handleExportExcel() {
    setIsDownloading(true);

    api
      .get(`/products/export/excel`, { responseType: 'blob' })
      .then(response => {
        download(response.data, `products-${uuidv4()}.xlsx`);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setIsDownloading(false));
  }

  function handleSearch(value: string) {
    setSearchValue(value);
    clearTimeout(timer);

    if (value.length > 0 && value.length <= 2) {
      return;
    }

    timer = setTimeout(() => fetchProducts(value), 500);
  }

  async function handleDelete() {
    if (!selectedProduct) {
      return;
    }

    await api.delete(`/products/${selectedProduct.id}`);

    setProducts(state => state.filter(product => product.id !== selectedProduct.id));
  }

  return (
    <ProductsContextProvider
      value={{
        products,
        selectedProduct,
        setSelectedProduct: (product: null | Product) => setSelectedProduct(product),
        handleProductActive,
        handleDelete,
        setIsOpenDeleteConfirmation,
      }}
    >
      {saving && <Loading />}

      <Appbar title="Menu" ActionComponents={<ProductsAction />} />

      {isOpenDeleteConfirmation && <DeleteProductConfirmation onExited={() => setIsOpenDeleteConfirmation(false)} />}

      <PageHeaderActions
        title="Produtos"
        description="Gestão dos produtos"
        ActionComponent={
          <>
            <Button
              disabled={isDownloading}
              color="primary"
              variant="contained"
              size="small"
              onClick={handleExportExcel}
            >
              Exportar
            </Button>
            <Button color="primary" variant="contained" size="small" onClick={() => history.push('/menu/product')}>
              Adicionar
            </Button>
          </>
        }
      />

      <TableContainer tableTemplate={productsTableTemplate}>
        <ProductsFilterBox
          handleSearch={handleSearch}
          displayMode={displayMode}
          setDisplayMode={setDisplayMode}
          searchValue={searchValue}
        />

        <PaginationProvider>
          {loading ? (
            displayMode === 'list' ? (
              <TableLoading />
            ) : (
              <ProductLoading />
            )
          ) : filtered.length === 0 ? (
            <NoData message="Nenhum produto para mostrar" />
          ) : (
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <ProductListTable products={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <ProductListModule products={filtered} />
              )}
            </div>
          )}

          <ApiPagination
            onChangePage={value => setPage(value)}
            onChangeRowsPerPage={value => setRows(value)}
            count={total}
          />
        </PaginationProvider>
      </TableContainer>
    </ProductsContextProvider>
  );
};

export default Products;
