import * as yup from 'yup';

export const satSchema = yup.object().shape({
  software_house_token: yup.string().when('active', { is: true, then: yup.string().required('o token é obrigatório') }),
  activation_code: yup.string().when('active', {
    is: true,
    then: yup.string().required('codigo de ativação é obrigatório'),
  }),
  sat_version: yup.string().when('active', {
    is: true,
    then: yup.string().required('o tipo da série é obrigatório'),
  }),
  dll_path: yup.string().when('active', {
    is: true,
    then: yup.string().required('o caminho da DLL é obrigatório'),
  }),
  printer_name: yup.string().when('active', {
    is: true,
    then: yup.string().required('o nome da impressora é obrigatório'),
  }),
  escpos_type: yup.mixed().when('printer_name', {
    is: 'ESCPOS',
    then: yup.string().required('o tipo da impressora ESCPOS é obrigatório'),
  }),
  escpos_port: yup.mixed().when('printer_name', {
    is: 'ESCPOS',
    then: yup.string().required('a porta da impressora ESCPOS é obrigatório'),
  }),
  escpos_columns: yup.mixed().when('printer_name', {
    is: 'ESCPOS',
    then: yup.string().required('a quantidade de colunas da impressora ESCPOS é obrigatório'),
  }),
  escpos_line_height: yup.mixed().when('printer_name', {
    is: 'ESCPOS',
    then: yup
      .number()
      .typeError('o espaçamento entre linhas da impressora ESCPOS é obrigatório')
      .required('o espaçamento entre linhas da impressora ESCPOS é obrigatório'),
  }),
  escpos_lines: yup.mixed().when('printer_name', {
    is: 'ESCPOS',
    then: yup
      .number()
      .typeError('a quantidade de linhas da impressora ESCPOS é obrigatório')
      .required('a quantidade de linhas da impressora ESCPOS é obrigatório'),
  }),
});
