import * as types from './types';
import { Category } from 'types/category';
import { Product, Ingredient, Additional } from 'types/product';

export function setCategory(category: Category): types.ProductActionsType {
  return {
    type: types.SET_CATEGORY,
    category,
  };
}

export function productChange(index: string, value: any): types.ProductActionsType {
  return {
    type: types.PRODUCT_CHANGE,
    index,
    value,
  };
}

export function setProduct(product: Product): types.ProductActionsType {
  return {
    type: types.SET_PRODUCT,
    product,
  };
}

export function photoSelect(): types.ProductActionsType {
  return {
    type: types.PHOTO_SELECT,
  };
}

export function setProductIngredients(ingredients: Ingredient[]): types.ProductActionsType {
  return {
    type: types.SET_PRODUCT_INGREDIENTS,
    ingredients,
  };
}

export function setProductAdditional(additional: Additional[]): types.ProductActionsType {
  return {
    type: types.SET_PRODUCT_ADDITIONAL,
    additional,
  };
}

export function setStep(step: number): types.ProductActionsType {
  return {
    type: types.SET_STEP,
    step,
  };
}

export function availabilityChange(day: number, status: boolean): types.ProductActionsType {
  return {
    type: types.AVAILABILITY_CHANGE,
    day,
    status,
  };
}

export function availabilityHoursChange(index: string, value: string): types.ProductActionsType {
  return {
    type: types.AVAILABILITY_HOURS_CHANGE,
    index,
    value,
  };
}

export function setIsPizza(): types.ProductActionsType {
  return {
    type: types.SET_IS_PIZZA,
  };
}

export function addComplementCategory(): types.ProductActionsType {
  return {
    type: types.ADD_COMPLEMENT_CATEGORY,
  };
}

export function addComplement(complementCategoryId: number, isPizza: boolean): types.ProductActionsType {
  return {
    type: types.ADD_COMPLEMENT,
    complementCategoryId,
    isPizza,
  };
}

export function deleteComplementCategory(complementCategoryId: number): types.ProductActionsType {
  return {
    type: types.DELETE_COMPLEMENT_CATEGORY,
    complementCategoryId,
  };
}

export function deleteComplement(
  complementCategoryId: number,
  complementId: number,
  isPizza: boolean
): types.ProductActionsType {
  return {
    type: types.DELETE_COMPLEMENT,
    complementCategoryId,
    complementId,
    isPizza,
  };
}

export function changeComplementCategory(
  index: string,
  value: string | number | boolean,
  complementCategoryId: number
): types.ProductActionsType {
  return {
    type: types.CHANGE_COMPLEMENT_CATEGORY,
    complementCategoryId,
    index,
    value,
  };
}

export function changeComplement(
  index: string,
  value: any,
  complementCategoryId: number,
  complementId: number
): types.ProductActionsType {
  return {
    type: types.CHANGE_COMPLEMENT,
    complementCategoryId,
    complementId,
    index,
    value,
  };
}

export function addComplementAdditional(complementCategoryId: number, complementId: number): types.ProductActionsType {
  return {
    type: types.ADD_COMPLEMENT_ADDITIONAL,
    complementCategoryId,
    complementId,
  };
}

export function deleteComplementAdditional(
  complementCategoryId: number,
  complementId: number,
  additionalId: number
): types.ProductActionsType {
  return {
    type: types.DELETE_COMPLEMENT_ADDITIONAL,
    complementCategoryId,
    complementId,
    additionalId,
  };
}

export function changeComplementAdditional(
  complementCategoryId: number,
  complementId: number,
  additionalId: number,
  name: string
): types.ProductActionsType {
  return {
    type: types.CHANGE_COMPLEMENT_ADDITIONAL,
    complementCategoryId,
    complementId,
    additionalId,
    name,
  };
}

export function addComplementIngredient(complementCategoryId: number, complementId: number): types.ProductActionsType {
  return {
    type: types.ADD_COMPLEMENT_INGREDIENT,
    complementCategoryId,
    complementId,
  };
}

export function deleteComplementIngredient(
  complementCategoryId: number,
  complementId: number,
  ingredientId: number
): types.ProductActionsType {
  return {
    type: types.DELETE_COMPLEMENT_INGREDIENT,
    complementCategoryId,
    complementId,
    ingredientId,
  };
}

export function changeComplementIngredient(
  complementCategoryId: number,
  complementId: number,
  ingredientId: number,
  name: string
): types.ProductActionsType {
  return {
    type: types.CHANGE_COMPLEMENT_INGREDIENT,
    complementCategoryId,
    complementId,
    ingredientId,
    name,
  };
}

export function changeComplementPrice(
  complementCategoryId: number,
  complementId: number,
  priceId: number,
  value: number
): types.ProductActionsType {
  return {
    type: types.CHANGE_COMPLEMENT_PRICE,
    complementCategoryId,
    complementId,
    priceId,
    value,
  };
}

export function changeComplementAdditionalPrice(
  complementCategoryId: number,
  complementId: number,
  additionalId: number,
  priceId: number,
  value: number
): types.ProductActionsType {
  return {
    type: types.CHANGE_COMPLEMENT_ADDITIONAL_PRICE,
    complementCategoryId,
    complementId,
    additionalId,
    priceId,
    value,
  };
}

export function importComplementAdditional(fromComplementId: number, toComplementId: number): types.ProductActionsType {
  return {
    type: types.IMPORT_COMPLEMENT_ADDITIONAL,
    fromComplementId,
    toComplementId,
  };
}

export function importComplementIngredients(
  fromComplementId: number,
  toComplementId: number
): types.ProductActionsType {
  return {
    type: types.IMPORT_COMPLEMENT_INGREDIENTS,
    fromComplementId,
    toComplementId,
  };
}

export function complementCategoriesReorder(from: number, to: number): types.ProductActionsType {
  return {
    type: types.CATEGORY_COMPLEMENTS_REORDER,
    from,
    to,
  };
}

export function changeComplementStatus(complementCategoryId: number, complementId: number): types.ProductActionsType {
  return {
    type: types.CHANGE_COMPLEMENT_STATUS,
    complementCategoryId,
    complementId,
  };
}

export function importProduct(product: Product): types.ProductActionsType {
  return {
    type: types.IMPORT_PRODUCT,
    product,
  };
}
