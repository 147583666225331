import { Category, CategoryAvailabilityHours } from 'types/category';
import { CategoryActions } from './types';

export function categoryChange(index: keyof Category, value): CategoryActions {
  return {
    type: 'CATEGORY_CHANGE',
    index,
    value,
  };
}

export function setSelectedPhoto(): CategoryActions {
  return {
    type: 'SET_SELECTED_IMAGE',
  };
}

export function deletePhoto(): CategoryActions {
  return {
    type: 'DELETE_IMAGE',
  };
}

export function setCategory(category: Category): CategoryActions {
  return {
    type: 'SET_CATEGORY',
    category,
  };
}

export function availabilityChange(day: number, status: boolean): CategoryActions {
  return {
    type: 'AVAILABILITY_CHANGE',
    day,
    status,
  };
}

export function availabilityHoursChange(index: keyof CategoryAvailabilityHours, value): CategoryActions {
  return {
    type: 'AVAILABILITY_HOURS_CHANGE',
    index,
    value,
  };
}
