import React from 'react';
import { MenuItem, TextField, makeStyles } from '@material-ui/core';
import { PrinterSetting } from 'types/printerSettings';

const styles = makeStyles({
  downloadLinkContent: {
    marginTop: 20,
  },
});

interface PrinterSettingsFormProps {
  settings: PrinterSetting;
  handleChange(index: keyof PrinterSetting, value: any): void;
}

const PrinterSettingsForm: React.FC<PrinterSettingsFormProps> = ({ settings, handleChange }) => {
  const classes = styles();

  return (
    <>
      <TextField
        select
        fullWidth
        margin="normal"
        onChange={e => handleChange('font_size', e.target.value)}
        label="Tamanho da fonte"
        value={settings?.font_size || 14}
        helperText="Tamanho base da fonte para impressão dos documentos de produção e entrega"
      >
        <MenuItem value={12}>12px</MenuItem>
        <MenuItem value={14}>14px</MenuItem>
        <MenuItem value={16}>16px</MenuItem>
        <MenuItem value={18}>18px</MenuItem>
        <MenuItem value={20}>20px</MenuItem>
        <MenuItem value={22}>22px</MenuItem>
        <MenuItem value={24}>24px</MenuItem>
      </TextField>

      <TextField
        select
        fullWidth
        margin="normal"
        onChange={e => handleChange('production_template_copies', e.target.value)}
        label="Cópias impressão produção"
        value={settings?.production_template_copies || 1}
        helperText="Quantidade de cópias do documento de produção do pedido"
      >
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
      </TextField>

      <TextField
        select
        fullWidth
        margin="normal"
        onChange={e => handleChange('shipment_template_copies', e.target.value)}
        label="Cópias impressão entrega"
        value={settings?.shipment_template_copies || 1}
        helperText="Quantidade de cópias do documento de entrega do pedido"
      >
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
      </TextField>

      <TextField
        select
        fullWidth
        margin="normal"
        onChange={e => handleChange('no_margin', e.target.value)}
        label="Remover margens"
        value={settings?.no_margin ? 1 : 0}
        helperText="Remove margens nos documentos. Pode ser útil para corrigir falhas na impressão de determinadas partes do documento"
      >
        <MenuItem value={1}>Sim</MenuItem>
        <MenuItem value={0}>Não</MenuItem>
      </TextField>

      <TextField
        select
        fullWidth
        margin="normal"
        onChange={e => handleChange('margin_size', e.target.value)}
        label="Tamanho das margens"
        value={settings?.margin_size ?? 15}
        helperText="Pode ser útil para corrigir falhas na impressão de determinadas partes do documento"
      >
        <MenuItem value={1}>0px</MenuItem>
        <MenuItem value={10}>10px</MenuItem>
        <MenuItem value={15}>15px</MenuItem>
        <MenuItem value={20}>20px</MenuItem>
        <MenuItem value={25}>25px</MenuItem>
        <MenuItem value={30}>30px</MenuItem>
        <MenuItem value={35}>35px</MenuItem>
        <MenuItem value={40}>40px</MenuItem>
      </TextField>

      <TextField
        label="Versão Delivery Printer"
        placeholder="Informa a versão do Delivery Printer"
        margin="normal"
        value={settings?.delivery_printer_version}
        fullWidth
        helperText="Versão que será baixada para instalação do Delivery Printer, software para impressão automática dos pedidos"
        onChange={event => handleChange('delivery_printer_version', event.target.value)}
      />

      <div className={classes.downloadLinkContent}>
        <a
          target="blank"
          href={`https://github.com/raphaelcarreiro/delivery-printer-2/releases/download/v${settings.delivery_printer_version}/SGrande-Delivery-Printer-Setup-${settings.delivery_printer_version}.exe`}
        >
          Download Delivery Printer
        </a>
      </div>
    </>
  );
};

export default PrinterSettingsForm;
