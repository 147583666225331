import { useEffect, useState, useCallback } from 'react';
import { io } from 'socket.io-client';
import { BoardMovementPayment } from 'types/boardMovementPayment';
import { BoardOrderProduct } from 'types/boardOrderProduct';
import { BoardControlActions } from '../reducer/types';
import { addBoardPayment, addBoardProducts, removeBoardPayment, removeBoardProduct } from '../reducer/actions';

const socket = io(`${process.env.REACT_APP_EXPRESS_SERVER}/board`);

type UseBoardControlSocket = [boolean];

export function useBoardControlSocket(
  dispatch: (action: BoardControlActions) => void,
  boardMovementId?: string
): UseBoardControlSocket {
  const [isConnected] = useState(socket.connected);

  const handleProductsAdded = useCallback(
    (products: BoardOrderProduct[]) => {
      dispatch(addBoardProducts(products));
    },
    [dispatch]
  );

  const handlePaymentAdded = useCallback(
    (payment: BoardMovementPayment) => {
      dispatch(addBoardPayment(payment));
    },
    [dispatch]
  );

  const handleDeleteProduct = useCallback(
    (orderProductId: number) => {
      dispatch(removeBoardProduct(orderProductId));
    },
    [dispatch]
  );

  const handleDeletePayment = useCallback(
    (paymentId: string) => {
      dispatch(removeBoardPayment(paymentId));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!boardMovementId) {
      return;
    }

    socket.emit('register', boardMovementId);

    socket.on('board_products_added', (products: BoardOrderProduct[]) => handleProductsAdded(products));
    socket.on('board_payment_added', (payment: BoardMovementPayment) => handlePaymentAdded(payment));
    socket.on('board_payment_deleted', (payload: { paymentId: string }) => handleDeletePayment(payload.paymentId));

    socket.on('board_product_deleted', (payload: { order_product_id: number }) =>
      handleDeleteProduct(payload.order_product_id)
    );

    return () => {
      socket.off(boardMovementId);
      socket.off('board_products_added');
      socket.off('board_payment_added');
      socket.off('board_product_deleted');
      socket.off('board_payment_deleted');
    };
  }, [handleProductsAdded, handlePaymentAdded, boardMovementId, handleDeleteProduct, handleDeletePayment]);

  useEffect(() => {
    if (boardMovementId && isConnected) {
      socket.emit('register', boardMovementId);
    }
  }, [boardMovementId, isConnected]);

  return [isConnected];
}
