import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Complement, ComplementCategory, OrderProductAdditional } from 'types/product';
import { BoardControlActions } from './types';
import { moneyFormat, numberFormat } from 'helpers/NumberFormat';
import { getStatusText } from 'pages/board-orders/boardOrderStatusMapping';
import { BoardControlMovement } from 'types/boardControlMovement';
import { useCallback, useReducer } from 'react';
import { middleware } from './middleware';
import { BoardOrderProduct } from 'types/boardOrderProduct';

const INITIAL_STATE: BoardControlMovement | null = null;

export default function reducer(state = INITIAL_STATE, action: BoardControlActions): BoardControlMovement | null {
  const complementCategoriesMapping = (categories: ComplementCategory[]) => {
    let sizeSelected: Complement | null = null;

    return categories.map(category => {
      category.product_complement_category_id = category.id;
      category.complements = category.complements.map(complement => {
        complement.product_complement_id = complement.id;
        complement.formattedPrice = complement.price ? moneyFormat(complement.price) : '';

        if (category.is_pizza_size && category.complements.length === 1) {
          complement.selected = true;
          sizeSelected = complement;
        } else complement.selected = !!complement.selected;

        complement.prices = complement.prices.map((price, index) => {
          price.product_complement_price_id = price.id;
          price.formattedPrice = price.price ? moneyFormat(price.price) : '';
          price.selected = index === 0;
          return price;
        });

        complement.ingredients = complement.ingredients.map(ingredient => {
          ingredient.product_complement_ingredient_id = ingredient.id;
          return ingredient;
        });

        complement.additional = complement.additional.map(additional => {
          additional.product_complement_additional_id = additional.id;
          additional.prices = additional.prices.map(price => {
            price.product_complement_additional_price_id = price.id;
            price.selected = price.product_complement_size_id === sizeSelected?.id;
            price.formattedPrice = price.price ? moneyFormat(price.price) : '';
            return price;
          });
          return additional;
        });
        return complement;
      });
      return category;
    });
  };

  const formatProduct = (state: BoardControlMovement, product: BoardOrderProduct) => {
    const ids: number[] = [];
    state.payments.forEach(payment => payment.parsedOrderProductIds.forEach(id => ids.push(id)));
    const isPaid = ids.some(id => id === product.id);

    return {
      ...product,
      formattedFinalPrice: moneyFormat(product.final_price),
      additional: additionalMapping(product.additional),
      complement_categories: complementCategoriesMapping(product.complement_categories),
      formattedPrice: moneyFormat(product.price),
      formattedProductPrice: moneyFormat(product.product_price),
      formattedSpecialPrice: moneyFormat(product.special_price),
      formattedStatus: getStatusText(product.status),
      formattedCreatedAt: format(parseISO(product.created_at), 'p', { locale: ptBR }),
      formattedAmount: numberFormat(product.amount),
      formattedIsPaid: isPaid ? 'Sim' : 'Não',
      formattedIsSplitted: product.is_splitted ? 'Sim' : 'Não',
    };
  };

  const additionalMapping = (additional: OrderProductAdditional[]) => {
    return additional.map(additional => ({
      ...additional,
      formattedPrice: moneyFormat(additional.price),
    }));
  };

  switch (action.type) {
    case '@boardControl/SET_BOARD_MOVEMENT': {
      return {
        ...action.movement,
        formattedCreatedAt: format(parseISO(action.movement.created_at), 'PPp', { locale: ptBR }),
        products: [],
        payments: [],
        isBoardPaid: false,
        total: 0,
        totalPaid: 0,
        formattedTotal: '',
        formattedTotalPaid: '',
        formattedDiscount: '',
        discount: 0,
      };
    }

    case '@boardControl/SET_PRODUCTS': {
      if (!state) {
        return state;
      }

      return {
        ...state,
        products: action.products.map(product => formatProduct(state, product)),
      };
    }

    case '@boardControl/ADD_PRODUCTS': {
      if (!state) {
        return state;
      }

      const onlyNonExistingProducts = action.products.filter(product => state.products.every(p => p.id !== product.id));

      return {
        ...state,
        products: [...state.products, ...onlyNonExistingProducts.map(product => formatProduct(state, product))],
      };
    }

    case '@boardControl/REMOVE_PRODUCT': {
      if (!state) {
        return state;
      }

      return {
        ...state,
        products: state.products.filter(product => product.id !== action.orderProductId),
      };
    }

    case '@boardControl/REMOVE_PRODUCT_REFERENCED_ID': {
      if (!state) {
        return state;
      }

      return {
        ...state,
        products: state.products.filter(product => product.referenced_id !== action.referencedId),
      };
    }

    case '@boardControl/REMOVE_PAYMENT': {
      if (!state) {
        return state;
      }

      const _state = {
        ...state,
        payments: state.payments.filter(payment => payment.id !== action.paymentId),
      };

      const products = state.products.map(product => formatProduct(_state, product));

      return {
        ..._state,
        products,
      };
    }

    case '@boardControl/ADD_PAYMENT': {
      if (!state) {
        return state;
      }

      const exist = state.payments.find(payment => payment.id === action.payment.id);

      if (exist) {
        return state;
      }

      const _state = {
        ...state,
        payments: [
          ...state.payments,
          {
            ...action.payment,
            parsedOrderProductIds: action.payment.order_product_ids.split(',').map(item => parseInt(item)),
            formattedCreatedAt: format(parseISO(action.payment.created_at), 'PPp', { locale: ptBR }),
            formattedValue: moneyFormat(action.payment.value),
          },
        ],
      };

      const products = state.products.map(product => formatProduct(_state, product));

      return {
        ..._state,
        products,
      };
    }

    case '@boardControl/SET_PAYMENTS': {
      if (!state) {
        return state;
      }

      return {
        ...state,
        payments: action.payments.map(payment => ({
          ...payment,
          parsedOrderProductIds: payment.order_product_ids?.split(',').map(item => parseInt(item)),
          formattedCreatedAt: format(parseISO(payment.created_at), 'PPp', { locale: ptBR }),
          formattedValue: moneyFormat(payment.value),
        })),
      };
    }

    case '@boardControl/SET_CUSTOMER': {
      if (!state) {
        return state;
      }

      return {
        ...state,
        customerName: action.name,
        customer: {
          name: action.name,
        } as any,
      };
    }

    case '@boardControl/UPDATE_TOTAL': {
      if (!state) {
        return state;
      }

      const total = state.products.reduce((previous, product) => previous + product.final_price, 0);

      const totalPaid = state.payments.reduce((previous, payment) => previous + payment.value, 0);

      const isBoardPaid = total - totalPaid < 0.0001;

      return {
        ...state,
        total: total - state.discount,
        formattedTotal: moneyFormat(total - state.discount),
        totalPaid,
        formattedTotalPaid: moneyFormat(totalPaid),
        isBoardPaid,
      };
    }

    case '@boardControl/SET_DISCOUNT': {
      if (!state) {
        return state;
      }

      return {
        ...state,
        discount: action.discount,
        formattedDiscount: moneyFormat(action.discount),
      };
    }

    case '@board/CHANGE_MOVEMENT_BOARD': {
      if (!state) {
        return state;
      }

      return {
        ...state,
        board: action.board,
      };
    }

    default: {
      return state;
    }
  }
}

type UseBoardControlReducer = [BoardControlMovement | null, (action: BoardControlActions) => void];

export function useBoardControlReducer(): UseBoardControlReducer {
  const [movement, defaultDispatch] = useReducer(reducer, null);

  const dispatch = useCallback(
    (action: BoardControlActions) => {
      middleware(defaultDispatch, action);
    },
    [defaultDispatch]
  );

  return [movement, dispatch];
}
