import { Button, makeStyles, Typography } from '@material-ui/core';
import { addMinutes, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { Dispatch, FormEvent, SetStateAction } from 'react';
import { useInputDialog } from 'components/dialog/DialogInput';
import CustomTimerPicker from 'components/pickers/TimerPicker';
import { useSelector } from 'store/redux/selector';

const useStyles = makeStyles(theme => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: 250,
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      height: 200,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    flex: 1,
    textAlign: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  input: {
    padding: '0 50px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    },
  },
}));

interface ShipmentDeliverySchedulingProps {
  handleSubmit(event: FormEvent<HTMLFormElement>, handleCloseDialog: () => void): void;
  scheduledAt: Date | null | string;
  setScheduledAt: Dispatch<SetStateAction<Date | null | string>>;
  dateTest(date: Date | string | null): boolean;
  currentTime: Date;
  setHasSchedule: Dispatch<SetStateAction<boolean>>;
}

const ShipmentDeliveryScheduling: React.FC<ShipmentDeliverySchedulingProps> = ({
  handleSubmit,
  scheduledAt,
  setScheduledAt,
  dateTest,
  currentTime,
  setHasSchedule,
}) => {
  const classes = useStyles();
  const { handleCloseDialog } = useInputDialog();
  const restaurant = useSelector(state => state.restaurant);

  return (
    <div className={classes.container}>
      <form onSubmit={event => handleSubmit(event, handleCloseDialog)} className={classes.form}>
        <Typography variant="h6">Escolha a hora para a entrega do pedido</Typography>

        <div className={classes.input}>
          <CustomTimerPicker
            value={scheduledAt}
            onChange={date => setScheduledAt(date as Date)}
            label="Horário da entrega"
            hideBackdrop
            autoOk
            helperText={`Agende para depois das ${format(
              addMinutes(currentTime, restaurant?.configs.delivery_time || 0),
              'HH:mm:ss',
              {
                locale: ptBR,
              }
            )}`}
          />
        </div>

        <div className={classes.actions}>
          <Button onClick={() => setHasSchedule(false)} type="button" color="primary" variant="text">
            voltar
          </Button>
          <Button type="submit" disabled={!dateTest(scheduledAt)} color="primary" variant="contained">
            Confirmar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ShipmentDeliveryScheduling;
