import React, { useState } from 'react';
import List from 'components/list/List';
import ReceiptItemModule from './FiscalSerialItemModule';
import ReceiptListMenu from '../FiscalSerialListMenu';
import { FiscalSerialOnList } from 'pages/fiscal-serials/types/fiscal-serial';

type FiscalSerialListModuleProps = {
  serials: FiscalSerialOnList[];
};

const FiscalSerialListModule: React.FC<FiscalSerialListModuleProps> = ({ serials }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <ReceiptListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <List>
        {serials.map(item => (
          <ReceiptItemModule setAnchorEl={setAnchorEl} key={item.id} serial={item} />
        ))}
      </List>
    </>
  );
};

export default FiscalSerialListModule;
