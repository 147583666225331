import { List, makeStyles } from '@material-ui/core';
import React from 'react';
import { useBoardManagement } from './hooks/useBoardManagement';
import TableManagementItem from './BoardManagementItem';

const styles = makeStyles(theme => ({
  list: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
    display: 'grid',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
    },
  },
}));

const BoardManagementList: React.FC = () => {
  const classes = styles();

  const { boardManagement } = useBoardManagement();

  return (
    <List className={classes.list}>
      {boardManagement.boards.map(board => (
        <TableManagementItem board={board} key={board.id} />
      ))}
    </List>
  );
};

export default BoardManagementList;
