import React from 'react';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import history from 'services/history';

const styles = makeStyles(theme => ({
  container: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

const PaymentMethodsAppbarButtons: React.FC = () => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <Tooltip title="Adicionar forma de pagamento">
        <IconButton onClick={() => history.push('/payment-methods/new')} color="inherit">
          <FilterList />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default PaymentMethodsAppbarButtons;
