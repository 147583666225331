import React, { useState } from 'react';
import ProductComplementAdditionalAction from './ProductComplementAdditionalAction';
import { Grid, TextField, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import CustomDialog from 'components/dialog/CustomDialog';
import ComplementAdditionalImport from './import/ComplementAdditionalImport';
import {
  addComplementAdditional,
  deleteComplementAdditional,
  changeComplementAdditional,
  changeComplementAdditionalPrice,
} from 'store/modules/product/actions';
import { useProduct } from 'pages/products/registration/hooks/useProduct';

const useStyles = makeStyles(theme => ({
  container: {},
  additional: {
    padding: 10,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid #eee`,
    marginBottom: 10,
    position: 'relative',
    backgroundColor: '#fff',
  },
  cancelIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 100,
  },
}));

function ProductComplementAdditional({ complement, category, onExit }) {
  const { dispatch } = useProduct();
  const classes = useStyles();
  const [dialogImport, setDialogImport] = useState(false);

  function handleAddComplementAdditional() {
    dispatch(addComplementAdditional(category.id, complement.id));
  }

  function handleDeleteComplementAdditional(additionalId) {
    dispatch(deleteComplementAdditional(category.id, complement.id, additionalId));
  }

  function handleChangeComplementAdditional(additionalId, name) {
    dispatch(changeComplementAdditional(category.id, complement.id, additionalId, name));
  }

  function handleChangeComplementAdditionalPrice(additionalId, priceId, value) {
    dispatch(changeComplementAdditionalPrice(category.id, complement.id, additionalId, priceId, value));
  }

  return (
    <CustomDialog
      backgroundColor="#fafafa"
      handleModalState={onExit}
      title={`${complement.name} - adicionais`}
      displayBottomActions={false}
      maxWidth="sm"
      height="80vh"
      componentActions={
        <ProductComplementAdditionalAction
          handleAddComplementAdditional={handleAddComplementAdditional}
          openDialogImport={() => setDialogImport(true)}
        />
      }
    >
      <>
        {dialogImport && (
          <ComplementAdditionalImport complementId={complement.id} onExited={() => setDialogImport(false)} />
        )}
        {complement.additional.length > 0 && (
          <Grid item xs={12}>
            <div className={classes.container}>
              {complement.additional.map(additional => (
                <Grid container spacing={1} key={additional.id} className={classes.additional}>
                  <IconButton
                    size="small"
                    className={classes.cancelIcon}
                    onClick={() => handleDeleteComplementAdditional(additional.id)}
                  >
                    <CancelIcon color="primary" />
                  </IconButton>
                  <Grid item xs={10}>
                    <TextField
                      label="Nome do adicional"
                      placeholder="Nome do adicional"
                      fullWidth
                      autoFocus
                      value={additional.name}
                      onChange={event => handleChangeComplementAdditional(additional.id, event.target.value)}
                    />
                  </Grid>
                  <Grid item container spacing={1}>
                    {additional.prices.map(price => (
                      <Grid key={price.id} item xl={3} lg={3} md={4} sm={6} xs={10}>
                        <TextField
                          type="number"
                          label={`${price.name || price.product_complement_size_name} (R$)`}
                          placeholder={`${price.name || price.product_complement_size_name} (R$)`}
                          inputProps={{
                            step: 0.01,
                            min: 1,
                          }}
                          value={price.price}
                          onChange={event =>
                            handleChangeComplementAdditionalPrice(additional.id, price.id, event.target.value)
                          }
                          fullWidth
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </div>
          </Grid>
        )}
      </>
    </CustomDialog>
  );
}

export default ProductComplementAdditional;
