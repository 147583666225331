import React, { useState, useEffect, FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import DialogInput from 'components/dialog/DialogInput';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { setSchedule } from 'store/redux/modules/order/actions';
import { addMinutes, isBefore } from 'date-fns';
import { useSelector } from 'store/redux/selector';
import ShipmentCollectQuestion from './ShipmentCollectQuestion';
import ShipmentCollectScheduling from './ShipmentCollectScheduling';

const useStyles = makeStyles(theme => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    textAlign: 'center',
  },
  total: {
    fontWeight: 500,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flex: 1,
  },
}));

ShipmentCollectSchedule.propTypes = {
  onExited: PropTypes.func.isRequired,
};

export default function ShipmentCollectSchedule({ onExited }) {
  const restaurant = useSelector(state => state.restaurant);
  const order = useSelector(state => state.order);
  const [hasSchedule, setHasSchedule] = useState(false);
  const dispatch = useDispatch();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [scheduledAt, setScheduledAt] = useState<Date | string | null>(
    order.shipment?.scheduled_at
      ? order.shipment.scheduled_at
      : addMinutes(currentTime, (restaurant?.configs.delivery_time ?? 0) + 1)
  );
  const classes = useStyles();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  function handleScheduleYes() {
    setHasSchedule(true);
  }

  function handleScheduleNo() {
    setHasSchedule(false);
    dispatch(setSchedule(null));
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>, handleCloseDialog: () => void) {
    event.preventDefault();
    dispatch(setSchedule(scheduledAt as Date));
    handleCloseDialog();
  }

  function dateTest(date: Date) {
    return isBefore(addMinutes(new Date(), restaurant?.configs.delivery_time ?? 1), date);
  }

  return (
    <DialogInput onExited={onExited} maxWidth="sm">
      {!hasSchedule ? (
        <ShipmentCollectQuestion handleScheduleNo={handleScheduleNo} handleScheduleYes={handleScheduleYes} />
      ) : (
        <div className={classes.container}>
          <ShipmentCollectScheduling
            handleSubmit={handleSubmit}
            dateTest={dateTest}
            currentTime={currentTime}
            scheduledAt={scheduledAt}
            setScheduledAt={setScheduledAt}
            setHasSchedule={setHasSchedule}
          />
        </div>
      )}
    </DialogInput>
  );
}
