import React, { useReducer, useState } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import { Grid } from '@material-ui/core';
import PageHeader from 'components/page-header/PageHeader';
import DelivererForm from './DelivererForm';
import delivererReducer, { INITIAL_STATE as delivererInitialState } from 'store/modules/deliverer/reducer';
import { delivererChange } from 'store/modules/deliverer/actions';
import * as yup from 'yup';
import { api } from 'services/api';
import Loading from 'components/loading/Loading';
import DelivererAction from './DelivererAction';
import history from 'services/history';
import { useMessaging } from 'hooks/messaging';

export default function Deliverer() {
  const [deliverer, dispatch] = useReducer(delivererReducer, delivererInitialState);
  const [saving, setSaving] = useState(false);
  const messaging = useMessaging();
  const [validation, setValidation] = useState({});

  function handleChange(index, value) {
    dispatch(delivererChange(index, value));
  }

  function handleFormValidation(event) {
    event.preventDefault();

    const schema = yup.object().shape({
      email: yup.string().email('Informe um e-mail válido').nullable(true),
      phone: yup.string().required('O telefone do entregador é obrigatório'),
      name: yup.string().required('O nome do entregador é obrigatório'),
    });

    schema
      .validate(deliverer)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);
    api
      .post('/deliverers', deliverer)
      .then(() => {
        messaging.handleOpen('Salvo');
        history.push('/deliverers');
      })
      .catch(err => {
        if (err.response.data.error) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível salvar o entregador');
        setSaving(false);
      });
  }
  return (
    <>
      {saving && <Loading />}
      <CustomAppbar
        title="Novo entregador"
        ActionComponents={<DelivererAction handleFormValidation={handleFormValidation} />}
      />
      <form onSubmit={handleFormValidation}>
        <Grid container>
          <PageHeader title="Novo entregador" />
          <Grid item xs={12} xl={4} lg={4} md={5}>
            <DelivererForm deliverer={deliverer} handleChange={handleChange} validation={validation} />
          </Grid>
        </Grid>
      </form>
    </>
  );
}
