import React, { useState } from 'react';
import { Typography, alpha, makeStyles } from '@material-ui/core';
import ReceiptItemTable from './FiscalSerialItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import ReceiptListMenu from '../FiscalSerialListMenu';
import history from 'services/history';
import { FiscalSerialOnList } from 'pages/fiscal-serials/types/fiscal-serial';
import { useFiscalSerials } from 'pages/fiscal-serials/hooks/useFiscalSerials';
import { fiscalSerialTableTemplate } from 'pages/fiscal-serials/constants/fiscalSerialTableTemplate';

const useStyles = makeStyles(theme => ({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  active: {
    backgroundColor: alpha(theme.palette.success.main, 0.3),
    '&:hover': {
      backgroundColor: alpha(theme.palette.success.main, 0.4),
    },
  },
}));

type FiscalSerialListTableProps = {
  serials: FiscalSerialOnList[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const FiscalSerialListTable: React.FC<FiscalSerialListTableProps> = ({ serials, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { setSelectedSerial } = useFiscalSerials();

  function handleClick(fiscal: FiscalSerialOnList) {
    setSelectedSerial(fiscal);
    history.push(`/fiscal-serials/${fiscal.id}`);
  }

  return (
    <>
      <ReceiptListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <TableContainer tableTemplate={fiscalSerialTableTemplate}>
        <TableContent>
          <TableHeader>
            {fiscalSerialTableTemplate
              .filter(item => !item.notShow)
              .map(item => (
                <div
                  className={classes.headerItem}
                  key={item.id}
                  onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
                >
                  <Typography noWrap variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId && (
                    <>
                      {orderedIndex.direction === 'asc' ? (
                        <ArrowUpward color="primary" />
                      ) : (
                        <ArrowDownward color="primary" />
                      )}
                    </>
                  )}
                </div>
              ))}
          </TableHeader>

          <TableBody>
            {serials.map(serial => (
              <TableRow
                className={serial.active ? classes.active : undefined}
                key={serial.id}
                onClick={() => handleClick(serial)}
              >
                <ReceiptItemTable setAnchorEl={el => setAnchorEl(el)} fiscalSerial={serial} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </>
  );
};

export default FiscalSerialListTable;
