import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ListItemIcon, ListItemText, ListItem } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles(theme => ({
  listItemText: {
    fontSize: 14,
  },
  listItem: {
    color: theme.palette.primary.contrastText,
  },
  listItemIcon: {
    color: theme.palette.primary.contrastText,
  },
}));

interface SidebarItemMoreProps {
  text: string;
  icon: React.ReactElement;
  handleClick(): void;
}

const SidebarItemMore: React.FC<SidebarItemMoreProps> = ({ icon, text, handleClick }) => {
  const classes = useStyles();

  return (
    <ListItem className={classes.listItem} onClick={handleClick} button>
      <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
      <ListItemText color="inherit" classes={{ primary: classes.listItemText }} primary={text} />
      <ChevronRightIcon color="inherit" />
    </ListItem>
  );
};

export default SidebarItemMore;
