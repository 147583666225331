import {
  UserRegisterActionsType,
  USER_CHANGE,
  SET_USER,
  USER_IMAGE_SELECT,
  USER_IMAGE_DELETE,
  UserRegister,
} from './types';

export function userChange(index: string, value: any): UserRegisterActionsType {
  return {
    type: USER_CHANGE,
    index,
    value,
  };
}

export function setUser(user: UserRegister): UserRegisterActionsType {
  return {
    type: SET_USER,
    user,
  };
}

export function userImageSelect(): UserRegisterActionsType {
  return {
    type: USER_IMAGE_SELECT,
  };
}

export function userImageDelete(): UserRegisterActionsType {
  return {
    type: USER_IMAGE_DELETE,
  };
}
