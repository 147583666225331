import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import AreaCityRegionAction from 'components/area/region/new/AreaCityRegionAction';
import CustomDialogForm from 'components/dialog/CustomDialogForm';
import { moneyFormat } from 'helpers/NumberFormat';
import { AreaRegion } from 'types/area';

interface AreaRegionEditProps {
  onExited(): void;
  handleEditAreaRegion(region: AreaRegion): void;
  selectedRegion: AreaRegion;
}

const AreaRegionEdit: React.FC<AreaRegionEditProps> = ({ onExited, handleEditAreaRegion, selectedRegion }) => {
  const [region, setRegion] = useState(selectedRegion);

  function handleSubmit() {
    const regionEdited = {
      ...region,
      formattedTax: moneyFormat(region.tax),
    };

    handleEditAreaRegion(regionEdited);
  }

  return (
    <CustomDialogForm
      handleModalState={onExited}
      title="Editar bairro"
      handleSubmit={handleSubmit}
      closeOnSubmit
      componentActions={<AreaCityRegionAction />}
      hideBackdrop
      maxWidth="sm"
    >
      <TextField
        label="Bairro"
        placeholder="Digite o nome do bairro"
        margin="normal"
        fullWidth
        autoFocus
        required
        value={region.name}
        onChange={event => setRegion({ ...region, name: event.target.value })}
      />
      <TextField
        label="Taxa de entrega (R$)"
        placeholder="Digite o valor da taxa de entrega"
        margin="normal"
        fullWidth
        value={region.tax}
        type="number"
        inputProps={{
          step: 0.01,
          min: 0.1,
          inputMode: 'numeric',
        }}
        onChange={event => setRegion({ ...region, tax: parseFloat(event.target.value) })}
      />
    </CustomDialogForm>
  );
};

export default AreaRegionEdit;
