import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Coupon } from 'types/coupon';

export type CouponsContextValue = {
  coupons: Coupon[];
  selectedCoupon: null | Coupon;
  setSelectedCoupon: Dispatch<SetStateAction<Coupon | null>>;
};

const CouponsContext = createContext<CouponsContextValue>({} as CouponsContextValue);
export const CouponsProvider = CouponsContext.Provider;
export const CouponsConsumer = CouponsContext.Consumer;

export function useCoupons(): CouponsContextValue {
  const context = useContext(CouponsContext);
  return context;
}
