import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { api, getCancelTokenSource } from 'services/api';
import ProductLoading from 'pages/products/ProductsLoading';
import ConnectionErrorMessage from 'components/errors/ConnectionErrorMessage';
import { makeStyles } from '@material-ui/core';
import { Product } from 'types/product';
import NoData from 'components/nodata/NoData';
import { useDispatch } from 'react-redux';
import { addProducts } from 'store/redux/modules/order/actions';
import { OrderProduct } from 'types/order';
import ProductSimple from 'pages/board-management/registration/registration/steps/products/detail/simple/ProductSimple';
import ProductPizzaComplement from 'pages/board-management/registration/registration/steps/products/detail/pizza_complement/ProductPizzaComplement';
import ProductComplement from 'pages/board-management/registration/registration/steps/products/detail/complement/ProductComplement';
import ProductList from 'pages/board-management/registration/registration/steps/products/ProductList';
import {
  ProductsProvider,
  ProductsContextValue,
} from 'pages/board-management/registration/registration/steps/products/hooks/useProducts';
import { useOrder } from 'pages/board-management/registration/registration/hooks/useOrder';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
});

const Products: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { selectedCategory } = useOrder();
  const [loading, setLoading] = useState(!!selectedCategory);
  const [products, setProducts] = useState<Product[]>([]);
  const [httpStatusCode, setHttpStatusCode] = useState<number | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const isPizza = useMemo(() => {
    return !!selectedProduct?.category.is_pizza;
  }, [selectedProduct]);

  const isComplement = useMemo(() => {
    return !!selectedProduct?.category.has_complement && !selectedProduct?.category.is_pizza;
  }, [selectedProduct]);

  const isSimple = useMemo(() => {
    return selectedProduct ? !selectedProduct.category.has_complement : false;
  }, [selectedProduct]);

  useEffect(() => {
    if (!selectedCategory) {
      return;
    }

    const source = getCancelTokenSource();
    let request = true;

    api
      .get('/order/products', {
        params: { category_id: selectedCategory.id },
        cancelToken: source.token,
      })
      .then(response => {
        if (request) {
          setProducts(response.data);
          setHttpStatusCode(response.status);
        }
      })
      .catch(err => {
        if (request) setHttpStatusCode(err.response ? err.response.status : 0);
      })
      .finally(() => {
        if (request) setLoading(false);
        request = false;
      });

    return () => {
      if (request) source.cancel();
      request = false;
    };
  }, [selectedCategory]);

  const handleAddProduct = useCallback(
    (product: OrderProduct, amount: number) => {
      dispatch(addProducts(product, amount));
    },
    [dispatch]
  );

  const context: ProductsContextValue = {
    selectedProduct,
    handleSelectProduct: (product: Product | null) => setSelectedProduct(product),
    handleAddProduct,
    isPizza,
    isComplement,
    isSimple,
  };

  return (
    <ProductsProvider value={context}>
      <div className={classes.container}>
        {isSimple && <ProductSimple />}

        {isPizza && <ProductPizzaComplement />}

        {isComplement && <ProductComplement />}

        {loading ? (
          <ProductLoading />
        ) : products.length > 0 ? (
          <ProductList products={products} />
        ) : httpStatusCode === 200 && products.length === 0 ? (
          <NoData message="Nenhum produto para mostrar" backgroundColor="inherit" />
        ) : (
          !httpStatusCode && <ConnectionErrorMessage statusCode={httpStatusCode} />
        )}
      </div>
    </ProductsProvider>
  );
};

export default Products;
