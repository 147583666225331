import {
  AreaActionTypes,
  SET_AREAS,
  ADD_AREA,
  DELETE_AREA,
  ADD_REGION,
  DELETE_REGION,
  EDIT_REGION,
  ADD_DISTANCES,
  SET_RESTAURANT_ADDRESS,
} from './types';
import { Area, AreaRegion, AreaDistance, AreaSetting } from 'types/area';
import { RestaurantAddress } from 'types/restaurant';

export function setAreas(areas: Area[]): AreaActionTypes {
  return {
    type: SET_AREAS,
    areas,
  };
}

export function addArea(area: Area): AreaActionTypes {
  return {
    type: ADD_AREA,
    area,
  };
}

export function deleteArea(areaId: number): AreaActionTypes {
  return {
    type: DELETE_AREA,
    areaId,
  };
}

export function addAreaRegion(region: AreaRegion, areaId: number): AreaActionTypes {
  return {
    type: ADD_REGION,
    region,
    areaId,
  };
}

export function deleteAreaRegion(areaId: number, regionId: number): AreaActionTypes {
  return {
    type: DELETE_REGION,
    areaId,
    regionId,
  };
}

export function editAreaRegion(region: AreaRegion, areaId: number): AreaActionTypes {
  return {
    type: EDIT_REGION,
    region,
    areaId,
  };
}

export function addAreaDistances(areaId: number, distances: AreaDistance[]): AreaActionTypes {
  return {
    type: ADD_DISTANCES,
    areaId,
    distances,
  };
}

export function setRestaurantAddress(address: RestaurantAddress, areaId: number): AreaActionTypes {
  return {
    type: SET_RESTAURANT_ADDRESS,
    address,
    areaId,
  };
}

export function areaSettingChange(index: keyof AreaSetting, value: boolean, areaId: number): AreaActionTypes {
  return {
    type: 'AREA_SETTING_CHANGE',
    index,
    value,
    areaId,
  };
}
