import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomAppbar from 'components/appbar/Appbar';
import CategoryAction from './CategoryAction';
import { Button, IconButton, InputAdornment, TextField } from '@material-ui/core';
import CategoryLoading from './CategoryLoading';
import NoData from 'components/nodata/NoData';
import history from 'services/history';
import { api } from 'services/api';
import { Category } from 'types/category';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import FilterBox from 'components/filter-box/FilterBox';
import { Close, Search } from '@material-ui/icons';
import useTableOrder from 'hooks/tableOrder';
import { useApp } from 'App';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import TableLoading from 'components/loading/TableLoading';
import PaginationProvider from 'hooks/pagination';
import CategoryListTable from './list/table/CategoryListTable';
import CategoryListModule from './list/module/CategoryListModule';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { CategoriesContextProvider } from './hooks/useCategories';
import { useMessaging } from 'hooks/messaging';
import Loading from 'components/loading/Loading';
import ApiPagination from 'components/_pagination/ApiPagination';
import { Paginated } from 'types/paginated';
import DeleteCategoryConfirmation from './DeleteCategoryConfirmation';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  filter: {
    display: 'grid',
    gridTemplateColumns: '300px',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

let timer: NodeJS.Timeout;

const Categories: React.FC = () => {
  const classes = useStyles();
  const [categories, setCategories] = useState<Category[]>([]);
  const [dialogAddCategory, setDialogAddCategory] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<Category[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const app = useApp();
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const { handleOpen } = useMessaging();
  const [saving, setSaving] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(20);
  const [isOpenDeleteConfirmation, setIsOpenDeleteConfirmation] = useState(false);

  const fetchCategories = useCallback(
    (query: string) => {
      setLoading(true);

      api
        .get<Paginated<Category[]>>('/categories', { params: { page: page + 1, rows, term: query } })
        .then(response => {
          setTotal(response.data.total);

          setCategories(
            response.data.data.map(category => {
              const date = parseISO(category.created_at);
              category.formattedCreatedAt = format(date, 'PPp', { locale: ptBR });
              category.formattedHasAdditional = category.has_additional ? 'Sim' : 'Não';
              category.formattedHasIngredients = category.has_ingredient ? 'Sim' : 'Não';
              category.formattedHasComplement = category.has_complement ? 'Sim' : 'Não';
              category.formattedIsPizza = category.is_pizza ? 'Sim' : 'Não';
              category.formattedIsActive = category.activated ? 'Sim' : 'Não';
              category.formattedIsAvailableOnBoard = category.is_available_on_board ? 'Sim' : 'Não';
              category.formattedIsAvailableOnDelivery = category.is_available_on_delivery ? 'Sim' : 'Não';
              return category;
            })
          );
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [rows, page]
  );

  useEffect(() => fetchCategories(''), [fetchCategories]);

  useEffect(() => setFiltered(categories), [categories]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleCategoryActive() {
    setSaving(true);

    if (!selectedCategory) return;

    api
      .put(`/categories/${selectedCategory.id}/status`)
      .then(() => {
        handleOpen('Atualizado');
        setSaving(false);
        setCategories(categories =>
          categories.map(category => {
            category.activated = category.id === selectedCategory.id ? !category.activated : category.activated;
            return category;
          })
        );
      })
      .catch(err => console.log(err))
      .finally(() => setSaving(false));
  }

  function handleSearch(value: string) {
    setSearchValue(value);
    clearTimeout(timer);

    if (value.length > 0 && value.length <= 3) {
      return;
    }

    timer = setTimeout(() => fetchCategories(value), 500);
  }

  async function handleDelete() {
    if (!selectedCategory) {
      return;
    }

    await api.delete(`/categories/${selectedCategory.id}`);

    setCategories(state => state.filter(category => category.id !== selectedCategory.id));
  }

  return (
    <CategoriesContextProvider
      value={{
        setSelectedCategory,
        selectedCategory,
        categories,
        handleCategoryActive,
        setIsOpenDeleteConfirmation,
        handleDelete,
      }}
    >
      {saving && <Loading />}

      {isOpenDeleteConfirmation && <DeleteCategoryConfirmation onExited={() => setIsOpenDeleteConfirmation(false)} />}

      <CustomAppbar
        title="Categorias"
        ActionComponents={<CategoryAction setDialogAddCategory={() => setDialogAddCategory(!dialogAddCategory)} />}
      />

      <PageHeaderActions
        title="Categorias"
        description="Categorias agrupam produtos e definem configurações"
        ActionComponent={
          <>
            <Button color="primary" variant="contained" onClick={() => history.push('/menu/category')} size="small">
              Adicionar
            </Button>
          </>
        }
      />

      <FilterBox>
        <div className={classes.filter}>
          <TextField
            inputRef={inputSearchRef}
            label="Pesquisar"
            placeholder="Nome da categoria"
            value={searchValue}
            onChange={e => handleSearch(e.target.value)}
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: searchValue && (
                <InputAdornment position="start">
                  <IconButton size="small" onClick={() => handleSearch('')}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <DisplayModeButtons
          displayMode={displayMode}
          handleShowList={() => setDisplayMode('list')}
          handleShowModule={() => setDisplayMode('module')}
        />
      </FilterBox>

      <PaginationProvider>
        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <CategoryLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhuma categoria para mostrar" />
        ) : (
          <div className={classes.container}>
            {displayMode === 'list' ? (
              <CategoryListTable categories={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
            ) : (
              displayMode === 'module' && <CategoryListModule categories={filtered} />
            )}
          </div>
        )}
        <ApiPagination
          count={total}
          onChangePage={value => setPage(value)}
          onChangeRowsPerPage={value => setRows(value)}
        />
      </PaginationProvider>
    </CategoriesContextProvider>
  );
};

export default Categories;
