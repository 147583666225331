import React, { useState, useEffect } from 'react';
import { Typography, Grid, TextField } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import ProductComplementItem from './ProductComplementItem';
import CustomDialog from 'components/dialog/CustomDialog';
import ProductComplementAction from './ProductComplementAction';
import PropTypes from 'prop-types';
import { useMessaging } from 'hooks/messaging';

const useStyles = makeStyles(theme => ({
  header: {
    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
    padding: '7px 15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  chip: {
    display: 'inline-block',
    padding: '3px 5px',
    borderRadius: 4,
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    fontSize: 10,
  },
  category: {
    display: 'block',
    margin: '10px 0',
  },
  categoryName: {
    fontWeight: 400,
  },
  container: {
    marginBottom: 0,
  },
  actionContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 20,
  },
  amountControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #eee',
    borderRadius: 4,
    marginRight: 10,
    height: 40,
  },
  action: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  amount: {
    textAlign: 'center',
  },
  buttonAmount: {
    minWidth: 50,
  },
  finalPrice: {
    color: ({ isSelected }) => (isSelected ? theme.palette.primary.contrastText : '#888'),
    textAlign: 'right',
    minWidth: 80,
    fontWeight: 500,
  },
  annotationContainer: {
    marginTop: 15,
  },
}));

ProductComplement.propTypes = {
  onExited: PropTypes.func.isRequired,
  selectedProduct: PropTypes.object.isRequired,
  handleConfirmProduct: PropTypes.func.isRequired,
};

export default function ProductComplement({ onExited, selectedProduct, handleConfirmProduct }) {
  const [amount, setAmount] = useState(selectedProduct.amount || 1);
  const [product, setProduct] = useState(JSON.parse(JSON.stringify(selectedProduct)));
  const messaging = useMessaging();
  const classes = useStyles();
  const [complementsPrice, setComplementsPrice] = useState(0);

  useEffect(() => {
    setComplementsPrice(
      product.complement_categories.reduce((value, category) => {
        const categoryPrice = category.complements.reduce((sum, complement) => {
          return complement.selected && complement.price ? sum + complement.price : sum;
        }, 0);
        return categoryPrice + value;
      }, 0)
    );
  }, [product]);

  function handleAmountUp() {
    if (!product.ready) {
      messaging.handleOpen('Você precisa selecionar os itens obrigatórios');
      return;
    }
    setAmount(amount + 1);
  }

  function handleAmountDown() {
    if (!product.ready) {
      messaging.handleOpen('Você precisa selecionar os itens obrigatórios');
      return;
    }
    if (amount > 1) {
      setAmount(amount - 1);
    }
  }

  function handleClickConfirm() {
    if (!product.ready) {
      messaging.handleOpen('Você precisa selecionar os itens obrigatórios');
      return;
    }

    handleConfirmProduct({
      ...product,
      amount,
    });
  }

  function handleClickComplements(productId, complementCategoryId, complementId) {
    const categories = product.complement_categories.map(category => {
      if (category.id === complementCategoryId) {
        const selectedAmount = category.complements.reduce((sum, complement) => {
          return complement.selected ? sum + 1 : sum;
        }, 0);

        category.complements = category.complements.map(complement => {
          if (category.max_quantity === 1) {
            complement.selected = complement.id === complementId && !complement.selected;
          } else {
            if (complement.id === complementId) {
              if (complement.selected) complement.selected = !complement.selected;
              else if (category.max_quantity > selectedAmount) complement.selected = !complement.selected;
            }
          }

          return complement;
        });
      }
      return category;
    });

    const ready = product.complement_categories.every(category => {
      if (category.is_required) {
        const selectedAmount = category.complements.reduce((sum, complement) => {
          return complement.selected ? sum + 1 : sum;
        }, 0);

        return category.min_quantity <= selectedAmount;
      }
      return true;
    });

    const newProduct = {
      ...product,
      ready,
      complement_categories: categories,
    };

    setProduct(newProduct);
  }

  return (
    <CustomDialog backgroundColor="#fafafa" handleModalState={onExited} title={`${product.name} - Complementos`}>
      <Grid container className={classes.container} direction="column">
        <Grid item xs={12}>
          {product.complement_categories.map(category => (
            <section className={classes.category} key={category.id}>
              <div className={classes.header}>
                <div>
                  <Typography className={classes.categoryName} variant="h6">
                    {category.name}
                  </Typography>
                  {category.max_quantity === 1 ? (
                    <Typography color="textSecondary" variant="body2">
                      Escolha 1 opção.
                    </Typography>
                  ) : (
                    <Typography color="textSecondary" variant="body2">
                      Escolha até {category.max_quantity} opções.
                    </Typography>
                  )}
                </div>
                <div>{category.is_required && <span className={classes.chip}>Obrigatório</span>}</div>
              </div>
              <ProductComplementItem
                productId={product.id}
                complementCategoryId={category.id}
                handleClickComplements={handleClickComplements}
                complements={category.complements}
              />
            </section>
          ))}
        </Grid>
        <Grid item xs={12} className={classes.annotationContainer}>
          <TextField
            multiline
            minRows={4}
            label="Tem alguma observação?"
            placeholder="Por exemplo, carne do hamburguer bem passada"
            fullWidth
            margin="normal"
            value={product.annotation}
            onChange={event => {
              setProduct({ ...product, annotation: event.target.value });
            }}
          />
        </Grid>
      </Grid>
      <ProductComplementAction
        amount={amount}
        complementsPrice={complementsPrice}
        handleAmountDown={handleAmountDown}
        handleAmountUp={handleAmountUp}
        handleClickConfirm={handleClickConfirm}
        product={product}
        isReady={product.ready}
      />
    </CustomDialog>
  );
}
