import { useCallback } from 'react';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { PaymentMethod, PaymentMethodForList } from 'types/paymentMethod';

export function usePaymentMethodFormatter(): [(paymentMethod: PaymentMethod) => PaymentMethodForList] {
  const formatter = useCallback((paymentMethod: PaymentMethod): PaymentMethodForList => {
    return {
      ...paymentMethod,
      formattedCreatedAt: format(parseISO(paymentMethod.created_at as string), 'Pp', { locale: ptBR }),
      formattedUpdatedAt: format(parseISO(paymentMethod.updated_at as string), 'Pp', { locale: ptBR }),
      formattedMustCreateReceipt: paymentMethod.must_create_receipt ? 'Sim' : 'Não',
      formattedActive: paymentMethod.activated ? 'Sim' : 'Não',
    };
  }, []);

  return [formatter];
}
