import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  preLoading1: {
    padding: 6,
    width: '80%',
    backgroundColor: '#eee',
    marginBottom: 7,
  },
  preLoading2: {
    height: 24,
    width: '60%',
    backgroundColor: '#eee',
    marginBottom: 20,
  },
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    border: '1px solid #f5f5f5',
    paddingTop: 15,
    height: 150,
    [theme.breakpoints.down('sm')]: {
      height: 120,
    },
  },
  list: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
    display: 'grid',
    gap: '10px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
    },
  },
}));

const BoardManagementLoading: React.FC = () => {
  const classes = useStyles();

  const items = 15;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <List className={classes.list}>
          {Array.from(Array(items).keys()).map(item => (
            <ListItem key={item} className={classes.listItem}>
              <div className={`animated-background ${classes.preLoading2}`} />
              <div className={`animated-background ${classes.preLoading1}`} />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default BoardManagementLoading;
