import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';

function ProductComplementListDescription({ complements }) {
  return (
    <Fragment>
      {complements.map(item => {
        return <Typography key={item.id}>{item.name}</Typography>;
      })}
    </Fragment>
  );
}

export default ProductComplementListDescription;
