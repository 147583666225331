import React from 'react';
import OrderResumeCustomer from './OrderResumeCustomer';
import OrderResumeShipment from './OrderResumeShipment2';
import { makeStyles } from '@material-ui/core/styles';
import OrderResumeTotal from './OrderResumeTotal';
import OrderResumePaymentMethod from './OrderResumePaymentMethod';
import OrderResumeProducts from 'pages/board-management/registration/registration/resume/products/OrderResumeProducts';
import Modal from 'components/modal/Modal';
import { useSelector } from 'store/redux/selector';

const useStyles = makeStyles({
  container: {
    marginBottom: 50,
    gap: 20,
    display: 'grid',
  },
});

interface OrderResumeProps {
  onExited(): void;
}

function OrderResume({ onExited }: OrderResumeProps) {
  const classes = useStyles();
  const order = useSelector(state => state.order);

  return (
    <Modal
      backgroundColor="#fafafa"
      onExited={onExited}
      title="resumo do pedido"
      displayBottomActions={false}
      maxWidth="sm"
    >
      <div className={classes.container}>
        <OrderResumeCustomer customer={order.customer} />
        <OrderResumeShipment order={order} />
        <OrderResumePaymentMethod order={order} />
        <OrderResumeProducts />
        <OrderResumeTotal order={order} />
      </div>
    </Modal>
  );
}

export default OrderResume;
