import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

type CategoryActionImageSelectedProps = {
  handlePhotoDelete(): void;
};

const CategoryActionImageSelected: React.FC<CategoryActionImageSelectedProps> = ({ handlePhotoDelete }) => {
  return (
    <Tooltip title="Excluir foto">
      <IconButton onClick={handlePhotoDelete} color="inherit">
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};

export default CategoryActionImageSelected;
