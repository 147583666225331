import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import history from 'services/history';
import { BoardMovement } from 'types/boardMovement';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
    minHeight: 100,
    position: 'relative',
    alignItems: 'flex-start',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
    width: 60,
    height: 60,
    borderRadius: '50%',
    backgroundColor: '#eee',
    overflow: 'hidden',
    border: `2px solid #fff`,
    flexShrink: 0,
  },
  image: {
    width: '100%',
  },
  buttonMore: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  status: {
    display: 'inline-flex',
    width: 80,
    padding: 3,
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: 12,
    justifyContent: 'center',
    alignItems: 'center',
    '&.open': {
      backgroundColor: '#ffc107',
      color: '#000',
    },
    '&.closed': {
      backgroundColor: '#6c757d',
      color: '#fff',
    },
  },
});

interface BoardMovementItemModuleProps {
  movement: BoardMovement;
}

const BoardMovementItemModule: React.FC<BoardMovementItemModuleProps> = ({ movement }) => {
  const classes = useStyles();

  function handleClick(movementId: string) {
    history.push(`/board-management/movements/${movementId}`);
  }

  return (
    <ListItem onClick={() => handleClick(movement.id)} key={movement.id} className={classes.listItem} button>
      <span className={movement.is_open ? `${classes.status} open` : `${classes.status} closed`}>
        {movement.formattedIsOpen}
      </span>
      <Typography variant="caption" gutterBottom color="primary">
        Mesa {movement.board?.number}
      </Typography>

      <Typography variant="body1">{movement.customer?.name}</Typography>
      <Typography variant="caption" color="textSecondary">
        {movement.id}
      </Typography>
    </ListItem>
  );
};

export default BoardMovementItemModule;
