import { ReceiptHistory } from 'pages/receipts/types/receipt';
import React from 'react';
import ReceiptHistoryItem from './ReceiptHistoryItem';
import { makeStyles } from '@material-ui/core';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px',
    '& li:last-child span::after': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
}));

interface ReceiptHistoryListProps {
  histories: ReceiptHistory[];
}

const ReceiptHistoryList: React.FC<ReceiptHistoryListProps> = ({ histories }) => {
  const classes = styles();

  return (
    <ul className={classes.container}>
      {histories.map(history => (
        <ReceiptHistoryItem key={history.id} history={history} />
      ))}
    </ul>
  );
};

export default ReceiptHistoryList;
