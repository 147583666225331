import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import history from 'services/history';
import { Board } from 'types/board';

type BoardItemModuleProps = {
  board: Board;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    backgroundColor: '#fff',
    border: '1px solid #eee',
    position: 'relative',
    alignItems: 'center',
    minHeight: 120,
    flexDirection: 'column',
  },
});

const BoardItemModule: React.FC<BoardItemModuleProps> = ({ board }) => {
  const classes = useStyles();

  return (
    <ListItem
      key={board.id}
      className={classes.listItem}
      button
      onClick={() => history.push(`/board/${board.id}`)}
      style={!board.active ? { opacity: 0.7 } : undefined}
    >
      <Typography variant="h6">{board.number}</Typography>
      <Typography variant="body2" color="textSecondary">
        {board.description}
      </Typography>
    </ListItem>
  );
};

export default BoardItemModule;
