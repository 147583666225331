import { FC, useState } from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import BoardTotal from './BoardTotal';
import { useBoardControl } from './hooks/useBoardControl';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    padding: 20,
    backgroundColor: '#fff',
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      backgroundColor: 'inherit',
      marginBottom: 50,
    },
    '& > .title': {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  freeBoardContent: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
    color: theme.palette.success.main,
    '& > div': {
      display: 'flex',
      gap: 15,
      '& > button': {
        width: 'auto',
      },
    },
  },
}));

interface BoardControlInfoProps {}

const BoardControlInfo: FC<BoardControlInfoProps> = () => {
  const classes = styles();
  const { movement, handleSetFreeBoard } = useBoardControl();
  const canBoardBeFree = movement?.isBoardPaid && !!movement?.is_open;
  const [saving, setSaving] = useState(false);

  function handleFreeClick() {
    setSaving(true);

    handleSetFreeBoard().catch(err => console.error(err));
  }

  return (
    <div className={classes.container}>
      <Typography className="title">Informações da mesa</Typography>

      <BoardTotal />

      {canBoardBeFree && (
        <div className={classes.freeBoardContent}>
          <Typography>Essa mesa pode ser liberada</Typography>
          <div>
            <Button disabled={saving} color="primary" variant="contained" onClick={handleFreeClick}>
              Liberar
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BoardControlInfo;
