import { UserRegisterActionsType, USER_CHANGE, SET_USER, USER_IMAGE_DELETE, UserRegister } from './types';

export const INITIAL_STATE: UserRegister = {
  id: 0,
  name: '',
  phone: '',
  email: '',
  activated: true,
  image: null,
  rule: 'admin-operator',
  password: '',
  password_confirmation: '',
};

export default function userReducer(state = INITIAL_STATE, action: UserRegisterActionsType): UserRegister {
  switch (action.type) {
    case SET_USER: {
      return action.user;
    }
    case USER_CHANGE: {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case USER_IMAGE_DELETE: {
      return {
        ...state,
        image: null,
      };
    }
    default: {
      return state;
    }
  }
}
