import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { useApp } from 'App';

const useStyles = makeStyles({
  loading: (props: { isOpenedMenu: boolean; hideBackground: boolean }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1400,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    // paddingLeft: props.isOpenedMenu ? SIDEBAR_WIDTH : 0,
    paddingLeft: 0,
    backgroundColor: props.hideBackground ? '#fafafa' : 'rgba(250, 250, 250, 0.5)',
  }),
  circularProgress: {
    top: '25%',
    position: 'absolute',
  },
  body: {
    overflowY: 'hidden',
    paddingRight: 17,
  },
});

type LoadingProps = {
  hideBackground?: boolean;
};

const Loading: React.FC<LoadingProps> = ({ hideBackground = false }) => {
  const { isOpenedMenu } = useApp();

  const stylesProps = { isOpenedMenu: hideBackground || isOpenedMenu, hideBackground };
  const classes = useStyles(stylesProps);

  return (
    <div className={classes.loading}>
      <CircularProgress color="primary" />
    </div>
  );
};

export default Loading;
