import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';

PrinterAction.propTypes = {
  handleFormValidation: PropTypes.func.isRequired,
};

export default function PrinterAction({ handleFormValidation }) {
  return (
    <Tooltip title="Adicionar cupom">
      <IconButton type="submit" color="inherit" onClick={handleFormValidation}>
        <DoneIcon />
      </IconButton>
    </Tooltip>
  );
}
