import React, { useRef, useEffect } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useProduct } from 'pages/products/registration/hooks/useProduct';
import ImageUpload from 'components/image-upload/ImageUpload';

const useStyles = makeStyles({
  container: {
    minHeight: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  button: {
    marginRight: 10,
  },
  inputs: {
    width: '100%',
  },
  action: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  icon: {
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
  uploadImageContainer: {
    marginTop: 20,
  },
});

export default function ProductFormStep() {
  const classes = useStyles();
  const { product, handleChange, validation, handleNext } = useProduct();

  const inputs = {
    name: useRef(null),
    description: useRef(null),
    url: useRef(null),
    cadence_quantity: useRef(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation);

    if (!key || !inputs[key]) return;

    inputs[key].current.focus();
  }, [validation]); // eslint-disable-line

  function handleSubmit(event) {
    event.preventDefault();
    handleNext();
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid item xs={12} xl={4} md={6} lg={4} className={classes.inputs}>
        <TextField
          inputRef={inputs.name}
          error={!!validation.name}
          helperText={!!validation.name && validation.name}
          label="Nome"
          placeholder="Digite o nome do produto"
          margin="normal"
          fullWidth
          value={product.name}
          onChange={event => handleChange(event.target.value, 'name')}
          autoFocus
        />
        <TextField
          error={!!validation.description}
          helperText={!!validation.description && validation.description}
          label="Descrição"
          placeholder="Digite a descrição do produto"
          margin="normal"
          fullWidth
          value={product.description ? product.description : ''}
          onChange={event => handleChange(event.target.value, 'description')}
          multiline
          minRows={4}
          inputRef={inputs.description}
        />
        <TextField
          error={!!validation.url}
          helperText={!!validation.url && validation.url}
          label="URL"
          placeholder="Digite a url do produto"
          margin="normal"
          fullWidth
          value={product.url}
          onChange={event => handleChange(event.target.value, 'url')}
          inputRef={inputs.url}
        />

        <TextField
          error={!!validation.cadence_quantity}
          helperText={
            validation.cadence_quantity ||
            'Valor usado para aumentar e decrementar a quantidade do produto que será adicionado ao carrinho'
          }
          label="Cadência da quantidade"
          placeholder="Informa a cadência da quantidade"
          margin="normal"
          fullWidth
          value={product.cadence_quantity}
          onChange={event => handleChange(event.target.value, 'cadence_quantity')}
          inputRef={inputs.cadence_quantity}
          type="number"
          inputMode="decimal"
        />

        <ImageUpload
          handleRemoveImage={() => handleChange(null, 'image')}
          handleSetImage={image => handleChange(image, 'image')}
          image={product.image}
          validationError={validation.image}
          className={classes.uploadImageContainer}
        />
        <button type="submit" style={{ display: 'none' }}>
          Salvar
        </button>
      </Grid>
    </form>
  );
}
