import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import PaginationProvider from 'hooks/pagination';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/nodata/NoData';
import useTableOrder from 'hooks/tableOrder';
import PaymentMethodsFilterBox from './PaymentMethodsFilterBox';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import PaymentMethodsPageHeaderButtons from './PaymentMethodsPageHeaderButtons';
import { PaymentMethodsQueryParams, useFetchPaymentMethods } from './hooks/useFetchPaymentMethods';
import { PaymentMethodForList } from 'types/paymentMethod';
import { useGridMode } from 'hooks/useGridMode';
import { PaymentMethodsProvider } from './hooks/usePaymentMethods';
import PaymentMethodsAppbarButtons from './PaymentMethodsAppbarButtons';
import PaymentMethodListTable from './list/table/PaymentMethodListTable';
import PaymentMethodListModule from './list/module/PaymentMethodListModule';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

const PaymentMethods: React.FC = () => {
  const classes = styles();
  const [paymentMethods, loading, queryParams, setQueryParams] = useFetchPaymentMethods();
  const [selected, setSelected] = useState<PaymentMethodForList | null>(null);
  const [filtered, setFiltered] = useState<PaymentMethodForList[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [gridMode, setGridMode] = useGridMode('payment-methods-grid-mode');

  useEffect(() => {
    setFiltered(paymentMethods);
  }, [paymentMethods]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleParamsChange(index: keyof PaymentMethodsQueryParams, value: string | number) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));
  }

  return (
    <PaymentMethodsProvider
      value={{
        selected,
        setSelected,
      }}
    >
      <div className={classes.container}>
        <Appbar title="Formas de pagamento" ActionComponents={<PaymentMethodsAppbarButtons />} />

        <PageHeaderActions
          title="Formas de pagamento"
          description="Gerenciador das formas de pagamentos utilizadas na emissão de pedidos"
          ActionComponent={<PaymentMethodsPageHeaderButtons />}
        />

        <PaymentMethodsFilterBox
          handleParamsChange={handleParamsChange}
          queryParams={queryParams}
          gridMode={gridMode}
          setGridMode={setGridMode}
        />

        <PaginationProvider>
          {loading ? (
            gridMode === 'list' ? (
              <TableLoading />
            ) : (
              <ModuleLoading />
            )
          ) : filtered.length === 0 ? (
            <NoData message="Nenhuma forma de pagamento para mostrar" />
          ) : (
            <div className={classes.container}>
              {gridMode === 'list' ? (
                <PaymentMethodListTable paymentMethods={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                gridMode === 'module' && <PaymentMethodListModule paymentMethods={filtered} />
              )}
            </div>
          )}
        </PaginationProvider>
      </div>
    </PaymentMethodsProvider>
  );
};

export default PaymentMethods;
