import { useEffect, useState } from 'react';
import { Socket } from 'socket.io-client';

export function useIntegratorConnectionStatus(socket: Socket) {
  const [isIntegratorConnected, setIsIntegratorConnected] = useState(false);
  const [lastPing, setLastPing] = useState<Date | null>(null);

  useEffect(() => {
    const timer = setInterval(() => {
      if (!lastPing) {
        return;
      }

      const difference = Date.now() - lastPing.getTime();

      if (difference >= 60000) {
        setIsIntegratorConnected(false);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [lastPing]);

  useEffect(() => {
    socket.on('receipt_creator_ping', data => {
      setIsIntegratorConnected(true);
      setLastPing(new Date());
    });
  }, [socket]);

  return {
    isIntegratorConnected,
  };
}
