import React, { Fragment } from 'react';
import { Fade, IconButton, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';

interface AreaActionProps {
  handleDialogCitySearch(): void;
  handleSave(): void;
  saving: boolean;
}

const AreaAction: React.FC<AreaActionProps> = ({ handleDialogCitySearch, handleSave, saving }) => {
  return (
    <Fragment>
      <Tooltip title="Adicionar cidade">
        <Fade in>
          <IconButton onClick={handleDialogCitySearch} color="inherit">
            <AddIcon />
          </IconButton>
        </Fade>
      </Tooltip>
      <Tooltip title="Salvar">
        <IconButton disabled={saving} onClick={handleSave} color="inherit">
          <DoneIcon />
        </IconButton>
      </Tooltip>
    </Fragment>
  );
};

export default AreaAction;
