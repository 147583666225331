import React, { useState } from 'react';
import { IconButton, makeStyles, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { FilterList, MoreVert } from '@material-ui/icons';

const styles = makeStyles(theme => ({
  container: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

type ReceiptsAppbarButtonsProps = {
  openDialog(): void;
  openDownloadReceiptModal(): void;
  openIntegratorStatusModal(): void;
};

const ReceiptsAppbarButtons: React.FC<ReceiptsAppbarButtonsProps> = ({
  openDialog,
  openDownloadReceiptModal,
  openIntegratorStatusModal,
}) => {
  const classes = styles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function handleDownloadXMLClick() {
    openDownloadReceiptModal();
    setAnchorEl(null);
  }

  function handleIntegratorStatusClick() {
    openIntegratorStatusModal();
    setAnchorEl(null);
  }

  return (
    <div className={classes.container}>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handleDownloadXMLClick}>Download XML</MenuItem>
        <MenuItem onClick={handleIntegratorStatusClick}>Integrador CF-e</MenuItem>
      </Menu>

      <Tooltip title="Adicionar categoria">
        <IconButton onClick={openDialog} color="inherit">
          <FilterList />
        </IconButton>
      </Tooltip>

      <IconButton onClick={event => setAnchorEl(event.currentTarget)} color="inherit">
        <MoreVert />
      </IconButton>
    </div>
  );
};

export default ReceiptsAppbarButtons;
