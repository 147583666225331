import React, { FC } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { useModal } from 'components/modal/hooks/useModal';
import { BoardControlTabValues } from '../BoardControlPaymentTabs';

interface BoardControlPaymentActionsProps {
  saving: boolean;
  handleValidation(closeDialog: () => void): void;
  activeTab: BoardControlTabValues;
}

const BoardControlPaymentActions: FC<BoardControlPaymentActionsProps> = ({ handleValidation, saving, activeTab }) => {
  const { handleModalClose } = useModal();

  return (
    <>
      {activeTab === 'pay' && (
        <Tooltip title="Salvar">
          <span>
            <IconButton disabled={saving} onClick={() => handleValidation(handleModalClose)} color="inherit">
              <DoneIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </>
  );
};

export default BoardControlPaymentActions;
