import { Button, makeStyles } from '@material-ui/core';
import { useInputDialog } from 'components/dialog/DialogInput';
import React from 'react';

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const OrderHistoriesDialogButtons: React.FC = () => {
  const classes = useStyles();
  const { handleCloseDialog } = useInputDialog();

  return (
    <div className={classes.actions}>
      <Button variant="text" color="primary" onClick={handleCloseDialog}>
        Fechar
      </Button>
    </div>
  );
};

export default OrderHistoriesDialogButtons;
