import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { moneyFormat } from 'helpers/NumberFormat';

const useStyles = makeStyles({
  title: {
    fontWeight: 600,
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
  editButton: {
    position: 'absolute',
    right: 0,
  },
});

function PaymentStatus({ order, handleSetStep, productsAmount }) {
  const classes = useStyles();

  return (
    <>
      {order.paymentMethod && (
        <>
          <div className={classes.head}>
            <Typography className={classes.title}>Forma de pagamento</Typography>
            {productsAmount > 0 && (
              <Button size="small" className={classes.editButton} color="primary" onClick={() => handleSetStep(5)}>
                Editar
              </Button>
            )}
          </div>
          <div>
            <Typography>{order.paymentMethod.method}</Typography>
            {order.paymentMethod.kind === 'money' && order.change > 0 && (
              <Typography color="textSecondary">
                Troco para {order.formattedChange} ({moneyFormat(order.change - order.total)})
              </Typography>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default PaymentStatus;
