import { Dispatch, SetStateAction, useState } from 'react';
import { Additional } from 'types/product';
import * as yup from 'yup';

export type AdditionalValidation = {
  name?: string;
  price?: string;
};

type UseAdditionalValidation = [
  AdditionalValidation,
  Dispatch<SetStateAction<AdditionalValidation>>,
  (additional: Additional) => Promise<void>
];

export function useAdditionalValidation(): UseAdditionalValidation {
  const [validation, setValidation] = useState<AdditionalValidation>({} as AdditionalValidation);

  async function handleValidation(additional: Additional) {
    const schema = yup.object().shape({
      price: yup.string().required('O preço é obrigatório'),
      name: yup.string().required('O nome é obrigatório'),
    });

    try {
      await schema.validate(additional);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path) {
        setValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  return [validation, setValidation, handleValidation];
}
