import { useApp } from 'App';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Order } from 'types/order';
import { useOrderFormatter } from './useOrderFormatter';
import { format, parseISO } from 'date-fns';
import { getStatusText } from '../orderStatusMapping';
import { ptBR } from 'date-fns/locale';
import { OrderList } from '../types/orderList';

const bell = new Audio('/sounds/oldPhone.mp3');

export function useOrderSocketEvents(setOrders: Dispatch<SetStateAction<OrderList[]>>) {
  const { socket } = useApp();
  const [formatter] = useOrderFormatter();

  useEffect(() => {
    socket.on('stored', (order: Order) => {
      const [orderReceived] = formatter([order]);

      setOrders(state => {
        const exists = state.some(o => o.id === order.id);

        if (exists) {
          return state;
        }

        return [
          {
            ...orderReceived,
            isNew: true,
          },
          ...state,
        ];
      });

      bell.play().finally(() => bell.play());
    });

    return () => {
      socket.off('stored');
    };
  }, [socket, formatter, setOrders]);

  useEffect(() => {
    socket.on('orderStatusChange', payload => {
      setOrders(state =>
        state.map(order => {
          if (order.id === payload.orderId && order.status !== payload.status) {
            const order_status = payload.orderStatus.reverse().map(orderStatus => ({
              ...orderStatus,
              formattedDate: format(parseISO(orderStatus.created_at), "PP 'às' p", { locale: ptBR }),
            }));

            return {
              ...order,
              order_status,
              status: payload.status,
              statusText: getStatusText(order.shipment?.shipment_method, payload.status),
            };
          }

          return order;
        })
      );
    });

    return () => {
      socket.off('orderStatusChange');
    };
  }, [setOrders, socket]);
}
