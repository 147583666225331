import React, { useState, useEffect } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import { getCancelTokenSource, api } from 'services/api';
import { Grid, makeStyles } from '@material-ui/core';
import PageHeader from 'components/page-header/PageHeader';
import DeliverersLoading from './DeliverersLoading';
import NoData from 'components/nodata/NoData';
import NoDataSearch from 'components/nodata/NoDataSearch';
import ConnectionErrorMessage from 'components/errors/ConnectionErrorMessage';
import DeliverersList from './DeliverersList';
import DeliverersAction from './DeliverersAction';

const styles = makeStyles({
  container: {
    flex: 1,
  },
});

export default function Deliverers() {
  const classes = styles();
  const [deliverers, setDeliverers] = useState([]);
  const [httpStatusCode, setHttpStatusCode] = useState(200);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const source = getCancelTokenSource();
    let request = true;

    api
      .get('/deliverers')
      .then(response => {
        setDeliverers(response.data);
      })
      .catch(err => {
        if (request)
          if (err.response) setHttpStatusCode(err.response.status);
          else setHttpStatusCode(0);
      })
      .finally(() => {
        setLoading(false);
        request = false;
      });

    return () => {
      if (request) source.cancel();
      request = false;
    };
  }, []);

  return (
    <>
      <CustomAppbar title="Entregadores" ActionComponents={<DeliverersAction />} />
      <PageHeader
        title="Entregadores"
        description="Quando o status de um pedido é alterado para Saiu para Entrega, é necessário informar um entregador"
      />
      <Grid container className={classes.container}>
        {loading ? (
          <DeliverersLoading />
        ) : deliverers.length === 0 && httpStatusCode === 200 ? (
          <NoData message="Não há entregadores para mostrar" backgroundColor="inherit" />
        ) : deliverers.length === 0 && httpStatusCode === 200 ? (
          <NoDataSearch
            message="Nada encontrado"
            secondaryMessage="Dá uma olhadinha nas palavras que você usou para pesquisar"
          />
        ) : deliverers.length > 0 ? (
          <Grid item xs={12}>
            <DeliverersList deliverers={deliverers} />
          </Grid>
        ) : (
          <ConnectionErrorMessage statusCode={httpStatusCode} />
        )}
      </Grid>
    </>
  );
}
