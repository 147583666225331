import React, { FormEvent } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import IconAdd from '@material-ui/icons/Add';
import IconDone from '@material-ui/icons/Done';
import { useCustomDialog } from 'components/dialog/CustomDialog';

interface AreaDistanceActionsProps {
  handleAdd(): void;
  handleConfirm(event: FormEvent | null): void;
}

const AreaDistancesActions: React.FC<AreaDistanceActionsProps> = ({ handleAdd, handleConfirm }) => {
  const dialog = useCustomDialog();

  function handleConfirmClick() {
    handleConfirm(null);
    dialog.handleCloseDialog();
  }
  return (
    <>
      <Tooltip title="Adicionar">
        <IconButton color="inherit" onClick={handleAdd}>
          <IconAdd />
        </IconButton>
      </Tooltip>
      <Tooltip title="Confirmar">
        <IconButton color="inherit" onClick={handleConfirmClick}>
          <IconDone />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default AreaDistancesActions;
