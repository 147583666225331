import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomAppbar from 'components/appbar/Appbar';
import { Button, IconButton, InputAdornment, TextField } from '@material-ui/core';
import NoData from 'components/nodata/NoData';
import history from 'services/history';
import { api, getCancelTokenSource } from 'services/api';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import FilterBox from 'components/filter-box/FilterBox';
import { Close, Search } from '@material-ui/icons';
import useTableOrder from 'hooks/tableOrder';
import { useApp } from 'App';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import TableLoading from 'components/loading/TableLoading';
import PaginationProvider from 'hooks/pagination';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ModuleLoading from 'components/loading/ModuleLoading';
import ApiPagination from 'components/_pagination/ApiPagination';
import { Additional } from 'types/product';
import { AdditionalProvider } from './hooks/useAdditional';
import { moneyFormat } from 'helpers/NumberFormat';
import AdditionalActions from './AdditionalActions';
import AdditionalListTable from './list/table/AdditionalListTable';
import AdditionalListModule from './list/module/AdditionalListModule';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  filter: {
    display: 'grid',
    gridTemplateColumns: '300px',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

let timer: NodeJS.Timeout;

const AdditionalPage: React.FC = () => {
  const classes = useStyles();
  const [additional, setAdditional] = useState<Additional[]>([]);
  const [loading, setLoading] = useState(false);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<Additional[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const app = useApp();
  const [selectedAdditional, setSelectedAdditional] = useState<Additional | null>(null);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(20);
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const [total, setTotal] = useState(0);

  const fetchCustomers = useCallback(
    (query: string) => {
      setLoading(true);

      api
        .get('/additional', { params: { page: page + 1, rows, q: query } })
        .then(response => {
          setTotal(response.data.total);

          setAdditional(
            response.data.data.map(item => {
              const date = parseISO(item.created_at);
              item.formattedCreatedAt = format(date, 'PPp', { locale: ptBR });
              item.formattedPrice = moneyFormat(item.price);
              item.categoryName = item.category.name;
              return item;
            })
          );
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [rows, page]
  );

  useEffect(() => {
    let request = true;

    const source = getCancelTokenSource();

    fetchCustomers('');

    return () => {
      if (request) source.cancel();
      request = false;
    };
  }, [fetchCustomers]);

  useEffect(() => setFiltered(additional), [additional]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleSearch(value: string) {
    setSearchValue(value);
    clearTimeout(timer);

    if (value.length > 0 && value.length <= 2) {
      return;
    }

    timer = setTimeout(() => fetchCustomers(value), 500);
  }

  return (
    <AdditionalProvider value={{ additional, setSelectedAdditional, selectedAdditional }}>
      <CustomAppbar title="Clientes" ActionComponents={<AdditionalActions />} />
      <PageHeaderActions
        title="Adicionais"
        description="Gestão dos adicionais"
        ActionComponent={
          <Button color="primary" variant="contained" onClick={() => history.push('/menu/additional/new')} size="small">
            Adicionar
          </Button>
        }
      />
      <FilterBox>
        <div className={classes.filter}>
          <TextField
            inputRef={inputSearchRef}
            label="Pesquisar"
            placeholder="Nome do adicional"
            value={searchValue}
            onChange={e => handleSearch(e.target.value)}
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: searchValue && (
                <InputAdornment position="start">
                  <IconButton size="small" onClick={() => handleSearch('')}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <DisplayModeButtons
          displayMode={displayMode}
          handleShowList={() => setDisplayMode('list')}
          handleShowModule={() => setDisplayMode('module')}
        />
      </FilterBox>
      <PaginationProvider>
        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhum adicional para mostrar" />
        ) : (
          <div className={classes.container}>
            {displayMode === 'list' ? (
              <AdditionalListTable additional={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
            ) : (
              displayMode === 'module' && <AdditionalListModule additional={filtered} />
            )}
            <ApiPagination
              onChangePage={value => setPage(value)}
              onChangeRowsPerPage={value => setRows(value)}
              count={total}
            />
          </div>
        )}
      </PaginationProvider>
    </AdditionalProvider>
  );
};

export default AdditionalPage;
