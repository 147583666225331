import React, { MouseEvent } from 'react';
import { List, ListItem, Typography, makeStyles, alpha } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Image } from 'types/image';
import { useModal } from 'components/modal/hooks/useModal';

const width = 150;

const useStyles = makeStyles(theme => ({
  image: {
    width,
  },
  container: {
    width,
    height: width,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  list: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(${width}px, 1fr))`,
    gap: '10px',
  },
  listItem: {
    width,
    border: '2px solid transparent',
    borderRadius: 4,
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  selected: {
    borderRadius: 4,
    width,
    border: `2px solid ${alpha(theme.palette.primary.main, 0.5)}`,
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageWrapper: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'absolute',
    display: 'flex',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
  },
  icon: {
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
  noImages: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type ImageSelectorListProps = {
  images: Image[];
  handleSetSelectedImage(image: Image, handleClose: () => void): void;
  selectedImageId?: number;
  handleConfirmImage(): void;
};

const ImageSelectorList: React.FC<ImageSelectorListProps> = ({
  images,
  handleSetSelectedImage,
  selectedImageId,
  handleConfirmImage,
}) => {
  const classes = useStyles();
  const { handleModalClose } = useModal();

  function handleClick(image: Image, event: MouseEvent) {
    if (event.detail === 1) {
      handleSetSelectedImage(image, handleModalClose);
      return;
    }

    if (event.detail === 2) {
      handleConfirmImage();
      handleModalClose();
    }
  }

  return (
    <>
      {images.length > 0 ? (
        <List className={classes.list} disablePadding>
          {images.map(image => (
            <ListItem
              button
              key={image.id}
              disableGutters
              className={image.id === selectedImageId ? classes.selected : classes.listItem}
              onClick={event => handleClick(image, event)}
            >
              <div className={classes.container}>
                {image.id === selectedImageId && (
                  <div className={classes.imageWrapper}>
                    <CheckCircleIcon className={classes.icon} color={'primary'} />
                  </div>
                )}
                <img
                  src={image.imageThumbUrl ? image.imageThumbUrl : image.imageUrl}
                  alt={`Imagem ${image.id}`}
                  className={classes.image}
                />
              </div>
            </ListItem>
          ))}
        </List>
      ) : (
        <div className={classes.noImages}>
          <Typography variant="body1" color="secondary">
            Sem imagens para mostrar
          </Typography>
        </div>
      )}
    </>
  );
};

export default ImageSelectorList;
