import React from 'react';
import CategoryList from './CategoryList';
import CategoryLoading from 'pages/categories/CategoryLoading';
import { useOrder } from 'pages/board-management/registration/registration/hooks/useOrder';

const Category: React.FC = () => {
  const { loadingCategories, handleAddCategory, categories } = useOrder();

  return (
    <>
      {loadingCategories ? (
        <CategoryLoading />
      ) : (
        categories.length > 0 && <CategoryList categories={categories} handleAddCategory={handleAddCategory} />
      )}
    </>
  );
};

export default Category;
