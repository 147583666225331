import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { api } from 'services/api';
import { useSelector } from 'store/redux/selector';
import { setRestaurant } from 'store/redux/modules/restaurant/actions';
import { setUser } from 'store/redux/modules/user/actions';
import { useLocation } from 'react-router-dom';

type UseFetchUser = [boolean];

const publicRoutes = ['/login', '/password-reset', '/forgot'];

export function useFetchCurrentUser(): UseFetchUser {
  const [loading, setLoading] = useState(false);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (user.id) {
      return;
    }

    if (publicRoutes.includes(location.pathname)) {
      return;
    }

    setLoading(true);

    api
      .get('/users/me')
      .then(response => {
        dispatch(setRestaurant(response.data.restaurant));
        dispatch(setUser(response.data));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user, dispatch, location.pathname]);

  return [loading];
}
