import React from 'react';
import { makeStyles } from '@material-ui/core';
import NoData from 'components/nodata/NoData';
import CustomerOrderItem from './CustomerOrderItem';
import { useCustomer } from '../../hooks/useCustomer';
import List from 'components/list/List';

const useStyles = makeStyles(theme => ({
  list: {
    //
  },
}));

const CustomerOrdersList: React.FC = () => {
  const classes = useStyles();
  const { customer } = useCustomer();

  return (
    <>
      {customer.orders.length ? (
        <List className={classes.list}>
          {customer.orders.map(order => (
            <CustomerOrderItem key={order.uuid} order={order} />
          ))}
        </List>
      ) : (
        <NoData backgroundColor="inherit" message="Este cliente ainda não efetuou pedidos." />
      )}
    </>
  );
};

export default CustomerOrdersList;
