import { useReducer, Dispatch } from 'react';
import { Plan } from 'types/plan';
import { PrinterSetting } from 'types/printerSettings';
import { Restaurant, RestaurantAddress, RestaurantConfig, RestaurantCreditCard } from 'types/restaurant';
import { ProfileActions } from './type';

export const INITIAL_STATE: Restaurant = {
  id: 0,
  uuid: '',
  name: '',
  description: '',
  title: '',
  url: '',
  email: '',
  primary_color: '',
  secondary_color: '',
  facebook_link: '',
  instagram_link: '',
  twitter_link: '',
  keywords: '',
  corporate_name: '',
  cnpj: '',
  company_tax_regime: '',
  ind_rat_iss: false,
  iss_tax_regime: '',
  state_subscription: '',
  addresses: [],
  phones: [],
  working_hours: '',
  image: null,
  cover: null,
  mobile_cover: null,
  play_store_link: '',
  alias: '',
  is_open: false,
  configs: {} as RestaurantConfig,
  cover_id: 0,
  credit_card: {} as RestaurantCreditCard,
  delivery_max_distance: 0,
  image_id: 0,
  minimum_order: 0,
  plan: {} as Plan,
  plan_id: 0,
  printer_settings: {} as PrinterSetting,
  is_kitchen_open: false,
  accounting_email: '',
};

function profileReducer(state = INITIAL_STATE, action: ProfileActions): Restaurant {
  switch (action.type) {
    case 'SET_RESTAURANT': {
      return action.restaurant;
    }

    case 'CHANGE': {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case 'ADD_ADDRESS': {
      let address: RestaurantAddress;
      if (state.addresses.length === 0) address = { ...action.address, id: new Date().getTime(), is_main: true };
      else address = { ...action.address, id: new Date().getTime(), is_main: false };
      return {
        ...state,
        addresses: [...state.addresses, address],
      };
    }

    case 'DELETE_ADDRESS': {
      const addresses = state.addresses.filter(address => address.id !== action.addressId);
      return {
        ...state,
        addresses,
      };
    }

    case 'EDIT_ADDRESS': {
      const addresses = state.addresses.map(address => (address.id === action.address.id ? action.address : address));
      return {
        ...state,
        addresses,
      };
    }

    case 'SET_MAIN_ADDRESS': {
      const addresses = state.addresses.map(address => {
        address.is_main = address.id === action.addressId;
        return address;
      });

      return {
        ...state,
        addresses,
      };
    }

    case 'ADD_PHONE': {
      return {
        ...state,
        phones: [
          ...state.phones,
          {
            ...action.phone,
            uid: new Date().getTime(),
          },
        ],
      };
    }

    case 'DELETE_PHONE': {
      const phones = state.phones.filter(phone => phone.uid !== action.phoneUid);
      return {
        ...state,
        phones,
      };
    }

    case 'IMAGE_DELETE': {
      return {
        ...state,
        image: null,
      };
    }

    case 'COVER_DELETE': {
      return {
        ...state,
        cover: null,
      };
    }

    case 'TOGGLE_ACTIVE_ADDRESS': {
      const addresses = state.addresses.map(address => {
        address.active = address.id === action.addressId ? !address.active : address.active;
        return address;
      });

      return {
        ...state,
        addresses,
      };
    }

    default: {
      return state;
    }
  }
}

type UseProfileReducer = [Restaurant, Dispatch<ProfileActions>];

export function useProfileReducer(): UseProfileReducer {
  const [profile, dispatch] = useReducer(profileReducer, INITIAL_STATE);
  return [profile, dispatch];
}
