import React from 'react';
import List from 'components/list/List';
import { usePagination } from 'hooks/pagination';
import CategoryItemModule from './AdditionalItemModule';
import { Additional } from 'types/product';

type AdditionalListModuleProps = {
  additional: Additional[];
};

const AdditionalListModule: React.FC<AdditionalListModuleProps> = ({ additional }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <>
      <List>
        {additional.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => (
          <CategoryItemModule key={item.id} additional={item} />
        ))}
      </List>
    </>
  );
};

export default AdditionalListModule;
