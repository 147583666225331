import React, { useState } from 'react';
import AddPhotoIcon from '@material-ui/icons/AddAPhoto';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles, alpha } from '@material-ui/core/styles';
import ImagePreview from 'components/image-preview/ImagePreview';
import ImageSelector from 'components/image-selector/ImageSelector';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { changeComplement } from 'store/modules/product/actions';
import { useProduct } from 'pages/products/registration/hooks/useProduct';

const useStyles = makeStyles(theme => ({
  image: {
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#eee',
    cursor: 'zoom-in',
  },
  imageContainer: {
    display: 'flex',
    width: 150,
    height: 150,
    border: `2px dashed ${alpha(theme.palette.primary.main, 0.2)}`,
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    marginRight: 15,
    padding: 5,
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 15,
      width: 120,
      height: 120,
    },
  },
  removeImageButton: {
    position: 'absolute',
    top: -0,
    right: -0,
    backgroundColor: '#fff',
    padding: 0,
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:active': {
      backgroundColor: '#fff',
    },
    [theme.breakpoints.down('sm')]: {
      top: -0,
      left: -0,
      right: 'auto',
    },
  },
}));

ProductComplementItemImage.propTypes = {
  category: PropTypes.object.isRequired,
  complement: PropTypes.object.isRequired,
};

function ProductComplementItemImage({ category, complement }) {
  const [imagePreview, setImagePreview] = useState(false);
  const [imageSelector, setImageSelector] = useState(false);
  const classes = useStyles();
  const { dispatch } = useProduct();

  function handleImagePreview() {
    setImagePreview(!imagePreview);
  }

  function handleSetComplementImage(image) {
    dispatch(changeComplement('image', image, category.id, complement.id));
  }

  function handleDeleteComplementImage() {
    dispatch(changeComplement('image', null, category.id, complement.id));
  }

  return (
    <>
      {imagePreview && complement.image && (
        <ImagePreview src={complement.image.imageUrl} description={complement.name} onExited={handleImagePreview} />
      )}
      {imageSelector && (
        <ImageSelector
          onExited={() => setImageSelector(false)}
          handleSetImageId={image => handleSetComplementImage(image)}
        />
      )}
      {!complement.image ? (
        <>
          <div className={classes.imageContainer}>
            <IconButton size="medium" onClick={() => setImageSelector(true)}>
              <AddPhotoIcon color="primary" />
            </IconButton>
          </div>
        </>
      ) : (
        <div className={classes.imageContainer}>
          <IconButton className={classes.removeImageButton} onClick={handleDeleteComplementImage}>
            <CancelIcon color="primary" />
          </IconButton>
          <img
            onClick={handleImagePreview}
            className={classes.image}
            src={complement.image.imageUrl}
            alt={complement.name}
          />
        </div>
      )}
    </>
  );
}

export default ProductComplementItemImage;
