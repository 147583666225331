import React, { useState, useEffect } from 'react';
import { Grid, TextField, InputAdornment } from '@material-ui/core';
import ProductAdditionalList from './ProductAdditionalList';
import SearchIcon from '@material-ui/icons/Search';
import { useProduct } from 'pages/products/registration/hooks/useProduct';
import { setProductAdditional } from 'store/modules/product/actions';
import useSearch from 'hooks/search';

function ProductAdditionalStep() {
  const { product, dispatch, additional: originalAdditional } = useProduct();
  const [additional, setAdditional] = useState(originalAdditional);
  const [additionalFiltered, setAdditionalFiltered] = useState([]);
  const search = useSearch();
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setAdditional(additional =>
      additional.map(a => {
        a.selected = product.additional.some(productAdditional => productAdditional.id === a.id);
        return a;
      })
    );
  }, [product.additional]);

  useEffect(() => {
    const a = search(searchValue, 'name', additional);
    setAdditionalFiltered(a);
  }, [searchValue, search, additional]);

  function handleSelect(id) {
    const newAdditional = additional.map(a => {
      if (id === a.id) a.selected = !a.selected;
      return a;
    });
    dispatch(setProductAdditional(newAdditional.filter(item => item.selected)));
  }

  return (
    <Grid item xs={12}>
      <TextField
        label="Pesquisar"
        placeholder="Pesquise pelos adicionais"
        margin="normal"
        fullWidth
        onChange={e => setSearchValue(e.target.value)}
        value={searchValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <ProductAdditionalList handleSelect={handleSelect} additional={additionalFiltered} />
    </Grid>
  );
}

export default ProductAdditionalStep;
