import React, { FormEvent, useEffect, useState } from 'react';
import { api, getCancelTokenSource } from 'services/api';
import Loading from 'components/loading/Loading';
import CustomAppbar from 'components/appbar/Appbar';
import {
  paymentMethodChange,
  paymentMethodConfigsChange,
  setPaymentMethodConfigs,
  setPicPayConfig,
} from 'store/modules/payment/actions';
import { usePaymentMethodsReducer } from 'store/modules/payment/reducer';
import PaymentMethodTabs from './tabs/PaymentMethodTabs';
import PaymentMethodTab2 from './tabs/PaymentMethodTab2';
import { useMessaging } from 'hooks/messaging';
import PaymentPicPayConfig from './picpay/PaymentPicpayConfig';
import { PaymentMethodsProvider } from './hooks/usePaymentMethods';
import { PaymentMethodConfigs } from 'types/paymentMethod';
import PaymentMethodPix from './pix/PaymentMethodPix';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import PaymentGatewaySettingsAppbarActions from './PaymentGatewaySettingsAppbarActions';
import InsideLoading from 'components/loading/InsideLoading';

const PaymentGatewaySettings: React.FC = () => {
  const messaging = useMessaging();
  const [paymentMethods, dispatch] = usePaymentMethodsReducer();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [tab, setTab] = useState(0);
  const { showErrorDialog } = useErrorHandler();

  useEffect(() => {
    let request = true;
    const source = getCancelTokenSource();

    api
      .get('payment-gateway-settings', { cancelToken: source.token })
      .then(response => {
        dispatch(setPicPayConfig(response.data.picpay_settings));
        dispatch(setPaymentMethodConfigs(response.data.gateway_settings));
      })
      .catch(err => {
        showErrorDialog({
          error: err,
          message: 'Não foi possível recuperar as configurações de pagamento',
        });
      })
      .finally(() => {
        if (request) {
          setLoading(false);
        }

        request = false;
      });

    return () => {
      if (request) source.cancel();
      request = false;
    };
  }, [dispatch, showErrorDialog]);

  function handleChange(paymentMethodId: number) {
    dispatch(paymentMethodChange(paymentMethodId));
  }

  function handleConfigsChange(index: keyof PaymentMethodConfigs, value: any) {
    dispatch(paymentMethodConfigsChange(index, value));
  }

  function handleSubmit(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    setSaving(true);

    api
      .put('/payment-gateway-settings', paymentMethods)
      .then(() => messaging.handleOpen('Salvo!'))
      .catch(err =>
        showErrorDialog({
          error: err,
          message: 'Não foi possível salvar as configurações de pagamento',
        })
      )
      .finally(() => setSaving(false));
  }

  function handleTabChange(value: number) {
    setTab(value);
  }

  return (
    <>
      {saving && <Loading />}
      <CustomAppbar
        title="Formas de pagamento"
        ActionComponents={
          <PaymentGatewaySettingsAppbarActions handleSubmit={handleSubmit} loading={loading} saving={saving} />
        }
        Tab={<PaymentMethodTabs tab={tab} handleTabChange={handleTabChange} loading={loading} />}
      />

      <PaymentMethodsProvider
        value={{
          handleChange,
          loading,
          picpaySettings: paymentMethods.picpay_settings,
          gatewaySettings: paymentMethods.gateway_settings,
          dispatch,
          handleSubmit,
          handleConfigsChange,
        }}
      >
        {loading ? (
          <InsideLoading />
        ) : tab === 0 ? (
          <PaymentMethodTab2 />
        ) : tab === 1 ? (
          <PaymentPicPayConfig />
        ) : (
          tab === 2 && <PaymentMethodPix />
        )}
      </PaymentMethodsProvider>
    </>
  );
};

export default PaymentGatewaySettings;
