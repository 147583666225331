import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ListItemIcon, ListItemText, ListItem } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  listItemIcon: {
    color: theme.palette.primary.contrastText,
  },
  listItemText: {
    color: '#fff',
    fontSize: 14,
  },
  listItem: {
    paddingLeft: 16,
    borderLeft: '2px solid transparent',
    minHeight: 50,
  },
}));

interface SidebarItemProps {
  text: string;
  icon: React.ReactElement;
  handleClick(): void;
}

const SidebarBackAction: React.FC<SidebarItemProps> = ({ icon, text, handleClick }) => {
  const classes = useStyles();

  return (
    <ListItem button onClick={handleClick} className={classes.listItem}>
      <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
      <ListItemText classes={{ primary: classes.listItemText }} primary={text} />
    </ListItem>
  );
};

export default SidebarBackAction;
