import React, { FormEvent, useState } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import { Grid } from '@material-ui/core';
import CustomerForm from '../CustomerForm';
import { useCustomerReducer } from 'store/modules/customers/reducer';
import { change } from 'store/modules/customers/actions';
import { api } from 'services/api';
import Loading from 'components/loading/Loading';
import * as yup from 'yup';
import { cpfValidation } from 'helpers/cpfValidation';
import { useMessaging } from 'hooks/messaging';
import history from 'services/history';
import { Customer } from 'types/customer';
import PageHeader from 'components/page-header/PageHeader';
import CustomerActions from './CustomerActions';
import { cnpjValidator } from 'helpers/cnpjValidator';

const CustomerNew: React.FC = () => {
  const messaging = useMessaging();
  const [customer, dispatch] = useCustomerReducer();
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState({});

  function handleSubmit() {
    setLoading(true);

    api
      .post('/customers', customer)
      .then(() => {
        messaging.handleOpen('Salvo');
        history.push(`/customers/`);
      })
      .catch(err => {
        messaging.handleOpen(err.response ? err.response.data.error : 'Não foi possível salvar o cliente');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleValidation(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const schema = yup.object().shape({
      cpf: yup.mixed().test('cpf', 'CPF inválido', value => {
        if (!value) {
          return false;
        }

        const cnpj = cnpjValidator(value);
        const cpf = cpfValidation(value);

        return cnpj || cpf;
      }),
      email: yup.string().email('Informe um email válido').nullable(),
      phone: yup.string().required('O telefone é obrigatório'),
      name: yup.string().required('O nome é obrigatório'),
    });

    schema
      .validate(customer)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err.message);
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleChange(index: keyof Customer, value: any) {
    dispatch(change(index, value));
  }

  return (
    <>
      {loading && <Loading />}
      <form onSubmit={handleValidation}>
        <CustomAppbar title="Novo cliente" ActionComponents={<CustomerActions loading={loading} />} />
        <PageHeader title="Cadastrar cliente" backAction={() => history.push('/customers')} />
        <Grid container>
          <Grid item xl={3} lg={4} md={5} xs={12}>
            <CustomerForm customer={customer} handleChange={handleChange} validation={validation} />
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default CustomerNew;
