import { PushNotification } from 'types/pushNotification';
import { PushNotificationActions, PUSH_CHANGE, SET_PUSH } from './types';

export function pushNotificationChange(index: string, value: any): PushNotificationActions {
  return {
    type: PUSH_CHANGE,
    index,
    value,
  };
}

export function setPushNotification(pushNotification: PushNotification): PushNotificationActions {
  return {
    type: SET_PUSH,
    pushNotification,
  };
}
