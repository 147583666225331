import React, { useState, useRef } from 'react';
import { Grid, TextField } from '@material-ui/core';
import AreaCityRegionAction from 'components/area/region/new/AreaCityRegionAction';
import CustomDialogForm from 'components/dialog/CustomDialogForm';
import { moneyFormat } from 'helpers/NumberFormat';
import { AreaRegion } from 'types/area';

interface AreaRegionAddProps {
  onExited(): void;
  handleAddAreaRegion(region: AreaRegion): void;
  hideBackdrop: boolean;
}

const AreaRegionAdd: React.FC<AreaRegionAddProps> = ({ onExited, handleAddAreaRegion, hideBackdrop = false }) => {
  const [region, setRegion] = useState({ name: '', tax: 0 });
  const ref = useRef<HTMLInputElement>(null);

  function handleSubmit() {
    const _region = {
      ...region,
      formattedTax: moneyFormat(region.tax),
      id: new Date().getTime(),
    };

    handleAddAreaRegion(_region);

    region.tax = !region.tax ? 0 : region.tax;

    setRegion({
      name: '',
      tax: 0,
    });

    if (ref.current) ref.current.focus();
  }

  function handleChange(value, name) {
    setRegion({ ...region, [name]: value });
  }

  return (
    <CustomDialogForm
      handleModalState={onExited}
      title="Adicionar bairro"
      handleSubmit={handleSubmit}
      componentActions={<AreaCityRegionAction />}
      hideBackdrop={hideBackdrop}
      maxWidth="sm"
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            label="Bairro"
            placeholder="Digite o nome do bairro"
            margin="normal"
            fullWidth
            autoFocus
            required
            value={region.name}
            onChange={event => handleChange(event.target.value, 'name')}
            inputRef={ref}
          />
          <TextField
            label="Taxa de entrega (R$)"
            placeholder="Digite o valor da taxa de entrega"
            margin="normal"
            fullWidth
            value={region.tax || ''}
            type="number"
            inputProps={{
              step: 0.01,
              min: 0,
              inputMode: 'numeric',
            }}
            onChange={event => handleChange(event.target.value, 'tax')}
          />
        </Grid>
      </Grid>
    </CustomDialogForm>
  );
};

export default AreaRegionAdd;
