import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { DashboardLoading } from '../Dashboard';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { makeStyles } from '@material-ui/core/styles';
import { moneyFormat } from 'helpers/NumberFormat';

const useStyles = makeStyles({
  card: {
    height: 150,
    boxShadow: 'none',
    border: '1px solid #f5f5f5',
    '& .card-header': {
      padding: 20,
      '& > .title': {
        lineHeight: 1,
      },
    },
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 40px',
    alignItems: 'center',
    '& h6': {
      fontSize: 26,
    },
    '& svg': {
      fontSize: 30,
    },
  },
  title: {
    fontSize: 16,
  },
});

interface DashboardCardProps {
  value: number;
  loading: boolean;
  title: string;
  formatAsMoney?: boolean;
}

export default function DashboardCard({ value, loading, title, formatAsMoney = false }: DashboardCardProps) {
  const classes = useStyles();

  const formattedValue = formatAsMoney ? moneyFormat(value) : value;

  return (
    <Card className={classes.card}>
      <div className="card-header">
        <Typography variant="body2" className="title">
          {title}
        </Typography>
      </div>
      <CardContent>
        <div className={classes.cardContent}>
          {loading ? (
            <DashboardLoading />
          ) : (
            <>
              <Typography variant="h6">{formattedValue}</Typography>
              <ShowChartIcon color="primary" />
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
