import React, { MouseEvent } from 'react';
import { Typography, makeStyles, ListItem, IconButton } from '@material-ui/core';
import history from 'services/history';
import { CashRegister } from 'types/cashRegister';
import { Lock, LockOpen, MoreHoriz } from '@material-ui/icons';
import { useCashRegisters } from 'pages/cash-registers/hooks/useCashRegisters';

type CashRegisterItemModuleProps = {
  cashRegister: CashRegister;
  setAnchorEl(el: HTMLButtonElement | null): void;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff',
    minHeight: 100,
    justifyContent: 'center',
    '& > .content': {
      margin: '10px 0',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '80%',
      alignItems: 'center',
    },
  },
  prices: {
    display: 'grid',
    gridTemplateColumns: '150px 1fr',
  },
  buttonMore: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  icon: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
});

const CashRegisterItemModule: React.FC<CashRegisterItemModuleProps> = ({ cashRegister, setAnchorEl }) => {
  const classes = useStyles();
  const { setSelectedCashRegister } = useCashRegisters();

  function handleMoreClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedCashRegister(cashRegister);

    setAnchorEl(e.currentTarget);
  }

  return (
    <ListItem button className={classes.listItem} onClick={() => history.push(`/cash-register/${cashRegister.id}`)}>
      <Typography variant="h6">
        caixa #{cashRegister.sequence} - {cashRegister.dayOfTheWeek}
      </Typography>
      {!cashRegister.closed_at ? (
        <Typography variant="body2" color="textSecondary">
          Aberto em {cashRegister.formattedCreatedAt}
        </Typography>
      ) : (
        <Typography variant="body2" color="textSecondary">
          {cashRegister.formattedCreatedAt} - {cashRegister.formattedClosedAt}
        </Typography>
      )}
      {cashRegister.closed_at ? (
        <Lock color="primary" className={classes.icon} />
      ) : (
        <LockOpen color="primary" className={classes.icon} />
      )}
      <IconButton onClick={e => handleMoreClick(e)} className={classes.buttonMore}>
        <MoreHoriz />
      </IconButton>
    </ListItem>
  );
};

export default CashRegisterItemModule;
