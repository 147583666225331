import React from 'react';
import { Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { Promotion } from 'types/promotion';

type PromotionItemTableProps = {
  promotion: Promotion;
};

const PromotionItemTable: React.FC<PromotionItemTableProps> = ({ promotion }) => {
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div key={item.id}>
            <Typography variant="body2">{promotion[item.id] as keyof Promotion}</Typography>
          </div>
        ))}
    </>
  );
};

export default PromotionItemTable;
