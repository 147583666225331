import React from 'react';
import { TextField, Grid, makeStyles } from '@material-ui/core';
import ImageUpload from 'components/image-upload/_ImageUpload';
import { Restaurant } from 'types/restaurant';

const useStyles = makeStyles({
  imageContainer: {
    margin: '20px 0',
  },
});

interface ProfileCustomizationProps {
  restaurant: Restaurant;
  handleInputChange(index: keyof Restaurant, value: any): void;
  validation: any;
}

const ProfileCustomization: React.FC<ProfileCustomizationProps> = ({ restaurant, handleInputChange, validation }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} xl={3} lg={4} md={5} sm={7}>
        <TextField
          autoFocus
          label="Cor primária"
          placeholder="Cor primária"
          margin="normal"
          fullWidth
          value={restaurant.primary_color}
          onChange={event => handleInputChange('primary_color', event.target.value)}
          name="primary_color"
          type="color"
        />

        <TextField
          label="Cor secondária"
          placeholder="Cor secundária"
          margin="normal"
          fullWidth
          value={restaurant.secondary_color}
          onChange={event => handleInputChange('secondary_color', event.target.value)}
          name="secondary_color"
          type="color"
        />

        <ImageUpload
          className={classes.imageContainer}
          image={restaurant.image}
          handleRemoveImage={() => handleInputChange('image', null)}
          handleSetImage={image => handleInputChange('image', image)}
          label="Logo da loja"
          validationError={validation.image}
        />

        <ImageUpload
          className={classes.imageContainer}
          image={restaurant.cover}
          handleRemoveImage={() => handleInputChange('cover', null)}
          handleSetImage={image => handleInputChange('cover', image)}
          label="Capa da página inicial"
          validationError={validation.cover}
        />

        <ImageUpload
          className={classes.imageContainer}
          image={restaurant.mobile_cover}
          handleRemoveImage={() => handleInputChange('mobile_cover', null)}
          handleSetImage={image => handleInputChange('mobile_cover', image)}
          label="Capa versão celular da página inicial"
          validationError={validation.mobile_cover}
        />
      </Grid>
    </Grid>
  );
};

export default ProfileCustomization;
