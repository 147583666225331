import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import history from 'services/history';
import { Typography, makeStyles } from '@material-ui/core';
import Appbar from 'components/appbar/Appbar';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import CashRegisterMovementList from './CashRegisterMovementList';
import { useFetchCashRegister } from './hooks/useFetchCashRegister';
import CashRegisterHeaderButtons from './CashRegisterHeaderButtons';
import { CashRegisterFlowProvider } from './hooks/useCashRegisterFlow';
import NewCashRegisterMovement from './movement/NewCashRegisterMovement';
import { api } from 'services/api';
import { useCashRegisterCurrentValue } from './hooks/useCashRegisterCurrentValue';
import { CashRegisterMovement } from 'types/cashRegisterMovement';
import DeleteMovementDialog from './DeleteMovementDialog';
import CloseCashRegisterDialog from './CloseCashRegisterDialog';
import CashRegisterExtractReport from './extract-report/CashRegisterExtractReport';
import CashRegisterLoading from './CashRegisterLoading';
import CashRegisterAppbarButtons from './CashRegisterAppbarButtons';
import CashRegisterReportModal from './report/CashRegisterReportModal';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 150,
  },
  content: {
    display: 'flex',
    maxWidth: 1000,
    width: '100%',
    margin: '30px auto',
    border: '1px solid #eee',
    padding: '20px 40px',
    backgroundColor: '#fff',
    flexDirection: 'column',
    marginTop: 100,
    gap: 10,
    minHeight: '50vh',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'unset',
      padding: 10,
      border: 'none',
      marginTop: 20,
      gap: 25,
    },
  },
  currentValueContent: {
    textAlign: 'center',
  },
  initialValueContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 25,
    alignItems: 'center',
  },
}));

const CashRegister: FC = () => {
  const classes = useStyles();
  const { id } = useParams<{ id?: string }>();
  const [cashRegister, setCashRegister, loading] = useFetchCashRegister(id);
  const [isOpenedDebitDialog, setIsOpenedDebitDialog] = useState(false);
  const { currentValue, formattedCurrentValue, debit, credit } = useCashRegisterCurrentValue(cashRegister);
  const [isOpenedDeleteMovementDialog, setIsOpenedDeleteMovementDialog] = useState(false);
  const [selectedMovement, setSelectedMovement] = useState<CashRegisterMovement | null>(null);
  const [isOpenedCloseCashRegisterDialog, setIsOpenedCloseCashRegisterDialog] = useState(false);
  const [isOpenedExtractReport, setIsOpenedExtractReport] = useState(false);
  const [isOpenedReport, setIsOpenedReport] = useState(false);

  function getSubheaderText() {
    if (loading) {
      return 'carregando...';
    }
    if (cashRegister && !cashRegister.closed_at) {
      return `${cashRegister.formattedCreatedAt} até o momento`;
    }

    return `${cashRegister?.formattedCreatedAt} até ${cashRegister?.formattedClosedAt}`;
  }

  async function handleDeleteMovement(movementId: string) {
    await api.delete(`/cashRegisters/${cashRegister?.id}/movements/${movementId}`);
  }

  async function handleCloseCashRegister() {
    await api.put(`/cashRegisters/${cashRegister?.id}/close`);
  }

  return (
    <CashRegisterFlowProvider
      value={{
        cashRegister,
        setCashRegister,
        isOpenedDebitDialog,
        setIsOpenedDebitDialog,
        currentValue,
        formattedCurrentValue,
        handleDeleteMovement,
        setSelectedMovement,
        selectedMovement,
        setIsOpenedDeleteMovementDialog,
        isOpenedDeleteMovementDialog,
        setIsOpenedCloseCashRegisterDialog,
        isOpenedCloseCashRegisterDialog,
        handleCloseCashRegister,
        debit,
        credit,
        isOpenedExtractReport,
        setIsOpenedExtractReport,
        isOpenedReport,
        setIsOpenedReport,
      }}
    >
      {isOpenedDebitDialog && <NewCashRegisterMovement onExited={() => setIsOpenedDebitDialog(false)} />}

      {isOpenedDeleteMovementDialog && <DeleteMovementDialog onExited={() => setIsOpenedDeleteMovementDialog(false)} />}

      {isOpenedCloseCashRegisterDialog && (
        <CloseCashRegisterDialog onExited={() => setIsOpenedCloseCashRegisterDialog(false)} />
      )}

      {isOpenedExtractReport && (
        <CashRegisterExtractReport cashRegister={cashRegister} onExited={() => setIsOpenedExtractReport(false)} />
      )}

      {isOpenedReport && <CashRegisterReportModal onExited={() => setIsOpenedReport(false)} />}

      <div className={classes.container}>
        <Appbar title="Caixa" ActionComponents={<CashRegisterAppbarButtons loading={loading} />} />

        <PageHeaderActions
          backAction={() => history.push('/cash-registers')}
          title="Gerenciamento de caixa"
          description="Fechamento, lançamentos e relatórios"
          ActionComponent={<CashRegisterHeaderButtons loading={loading} cashRegister={cashRegister} />}
        />

        {loading ? (
          <CashRegisterLoading />
        ) : (
          <div className={classes.content}>
            <div className={classes.currentValueContent}>
              <Typography variant="h4">{formattedCurrentValue}</Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {getSubheaderText()}
              </Typography>
            </div>
            <div className={classes.initialValueContent}>
              <Typography variant="body2" color="textSecondary">
                Saldo inicial
              </Typography>
              <Typography variant="h6">{cashRegister?.formattedInitialValue}</Typography>
            </div>
            <CashRegisterMovementList movements={cashRegister?.movements || []} />
          </div>
        )}
      </div>
    </CashRegisterFlowProvider>
  );
};

export default CashRegister;
