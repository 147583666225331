import React, { FC } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';

interface PushNotificationActionsProps {
  handleValidation(): void;
  saving: boolean;
  loading: boolean;
  notificationImageSelected: boolean;
  handleChange(index: string, value: null | boolean): void;
  delivered: boolean;
  handleDelete(): void;
}

const PushNotificationActions: FC<PushNotificationActionsProps> = ({
  handleValidation,
  saving,
  loading,
  notificationImageSelected,
  handleChange,
  delivered,
  handleDelete,
}) => {
  function handleDeleteClick() {
    handleChange('image', null);
    handleChange('isImageSelected', false);
  }

  return (
    <>
      {notificationImageSelected ? (
        <Tooltip title="Excluir imagem">
          <span>
            <IconButton disabled={saving || loading} onClick={handleDeleteClick} color="inherit">
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <>
          {!delivered && (
            <>
              <Tooltip title="Excluir notificação">
                <span>
                  <IconButton disabled={saving || loading} onClick={handleDelete} color="inherit">
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Salvar">
                <span>
                  <IconButton disabled={saving || loading} onClick={handleValidation} color="inherit">
                    <DoneIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          )}
        </>
      )}
    </>
  );
};

export default PushNotificationActions;
