import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { api, getCancelTokenSource } from 'services/api';
import { setOrder } from 'store/redux/modules/order/actions';
import { useSelector } from 'store/redux/selector';

export function useFetchOrderAndDistances(id?: string): [boolean] {
  const dispatch = useDispatch();
  const restaurant = useSelector(state => state.restaurant);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!restaurant) {
      return;
    }

    if (!id) {
      return;
    }

    let requesting = true;
    const source = getCancelTokenSource();

    api
      .get(`orders/${id}`, { cancelToken: source.token })
      .then(response => {
        if (!requesting) {
          return;
        }

        api
          .get(`/distances/${response.data.customer.id}`)
          .then(addressesResponse => {
            dispatch(
              setOrder({
                ...response.data,
                customer: {
                  ...response.data.customer,
                  addresses: addressesResponse.data,
                },
              })
            );
          })
          .catch(err => console.error(err))
          .finally(() => setLoading(false));
      })
      .catch(err => {
        if (requesting) {
          console.error(err);
          setLoading(false);
        }
      })
      .finally(() => {
        requesting = false;
      });

    return () => {
      if (requesting) source.cancel();
      requesting = false;
    };
  }, [id, restaurant, dispatch]);

  return [loading];
}
