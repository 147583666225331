import React, { useCallback, useEffect, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { api, getCancelTokenSource } from 'services/api';
import OrderLoading from './OrderLoading';
import NoData from 'components/nodata/NoData';
import { parseISO, format, isAfter, addMinutes } from 'date-fns';
import OrderSearch from './search/OrderSearch';
import Loading from 'components/loading/Loading';
import DialogDelete from 'components/dialog/delete/DialogDelete';
import DispatchedOrder from './print/DispatchedOrder';
import ApprovedOrder from './print/ApprovedOrder';
import { getStatusText } from './orderStatusMapping';
import OrderDeliverer from './OrderDeliverer';
import { useMessaging } from 'hooks/messaging';
import { OrdersContextProvider, OrdersContextValue } from '../orders/hook/useOrders';
import { useSelector } from 'store/redux/selector';
import { OrderStatusOptions } from 'types/orderStatus';
import { OrderList } from './types/orderList';
import PaginationProvider from 'hooks/pagination';
import OrderListModule from './list/module/OrderListModule';
import OrderPreview from './preview/OrderPreview';
import OrderTrack from './histories/OrderHistoriesDialog';
import ptBR from 'date-fns/locale/pt-BR';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import history from 'services/history';
import OrdersAction from './OrdersActions';
import TableLoading from 'components/loading/TableLoading';
import OrderListTable from './list/table/OrderListTable';
import useTableOrder from 'hooks/tableOrder';
import OrdersDeliveryTime from './delivery-time/OrdersDeliveryTime';
import ApiPagination from 'components/_pagination/ApiPagination';
import DispatchedOrderOnly from './print/DispatchedOrderOnly';
import OrdersFilterBox from './OrdersFilterBox';
import { formatDistance } from './formatDistance';
import Appbar from 'components/appbar/Appbar';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { useOrderSocketEvents } from './hook/useOrderSocketEvents';
import { useFetchOrders } from './hook/useFetchOrders';
import { useFetchDeliverers } from './hook/useFetchDeliverers';
import { useOrdersPageTitle } from './hook/useOrdersPageTitle';
import { useGridMode } from '../../hooks/useGridMode';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

export type OrdersQueryParams = {
  status: OrderStatusOptions;
  initial_date: null | Date;
  final_date: null | Date;
  page: number;
  rows: number;
};

let timer: NodeJS.Timeout;

const Orders: React.FC = () => {
  const classes = useStyles();
  const { handleOpen } = useMessaging();
  const [filtered, setFiltered] = useState<OrderList[]>([]);
  const [saving, setSaving] = useState(false);
  const [dialogSearch, setDialogSearch] = useState(false);
  const [dialogPrintShipment, setDialogPrintShipment] = useState(false);
  const [dialogPrintOnlyShipment, setDialogPrintOnlyShipment] = useState(false);
  const [dialogPrintOrder, setDialogPrintOrder] = useState(false);
  const [dialogCancelConfirm, setDialogCancelConfirm] = useState(false);
  const [dialogDeliverer, setDialogDeliverer] = useState(false);
  const [dialogOrderPreview, setDialogOrderPreview] = useState(false);
  const [dialogDeliveryTime, setDialogDeliveryTime] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderList | null>(null);
  const [dialogTrack, setDialogTrack] = useState(false);
  const [gridMode, setGridMode] = useGridMode('orders-grid-mode');
  const [orderedIndex, sort] = useTableOrder();
  const restaurant = useSelector(state => state.restaurant);
  const [term, setTerm] = useState('');
  const { showErrorDialog } = useErrorHandler();
  const { orders, setOrders, fetch, loading, setQueryParams, queryParams, total } = useFetchOrders();
  const [deliverers] = useFetchDeliverers();

  useOrderSocketEvents(setOrders);
  useOrdersPageTitle(orders);

  const sendToPrintShipment = useCallback(() => {
    if (!selectedOrder) return;

    setSaving(true);
    api
      .post('/shipment', { order_id: selectedOrder.id })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setSaving(false);
      });
  }, [selectedOrder]);

  const sendToPrintOrder = useCallback(() => {
    if (!selectedOrder) return;

    setSaving(true);
    api
      .post('/orders/print/now', { order_id: selectedOrder.id })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setSaving(false);
      });
  }, [selectedOrder]);

  const handleOrderStatus = useCallback(
    (status?: OrderStatusOptions, deliveryId?: number) => {
      if (!selectedOrder) {
        return;
      }

      if (!restaurant) {
        return;
      }

      const data = {
        order_id: selectedOrder.id,
        status,
        deliverer_id: deliveryId || null,
      };

      setSaving(true);

      api
        .post('orderStatus', data)
        .then(response => {
          const orderStatus = response.data.order_status;
          const deliverers = response.data.deliverers;

          setOrders(orders =>
            orders.map(order => {
              if (order.id === selectedOrder.id && order.status !== orderStatus.status) {
                order.status = orderStatus.status;
                order.statusText = getStatusText(order.shipment.shipment_method, orderStatus.status);
                order.order_status = [
                  {
                    ...orderStatus,
                    formattedDate: format(parseISO(orderStatus.created_at), "PP 'às' p", { locale: ptBR }),
                  },
                  ...order.order_status,
                ];
                order.isNew = false;
                selectedOrder.deliverers = deliverers || order.deliverers;
              }
              return order;
            })
          );

          if (restaurant.configs.use_printer) {
            setSelectedOrder(null);
            return;
          }

          if (restaurant.configs.print_only_shipment) {
            if (orderStatus.status === 'a') {
              setDialogPrintShipment(true);
            }

            return;
          }

          if (orderStatus.status === 'a') {
            setDialogPrintOrder(true);
            return;
          }

          if (orderStatus.status === 'd') {
            setDialogPrintShipment(true);
          }
        })
        .catch(error => {
          showErrorDialog({
            error,
            message: 'Não foi possível atualizar o status do pedido',
          });
        })
        .finally(() => {
          setSaving(false);
        });
    },
    [showErrorDialog, restaurant, selectedOrder, setOrders]
  );

  useEffect(() => {
    setFiltered(orders);
  }, [orders]);

  useEffect(() => {
    if (!restaurant) {
      return;
    }

    let request = true;

    const cancelToken = getCancelTokenSource();

    fetch('', cancelToken);

    return () => {
      if (request) {
        cancelToken.cancel();
      }

      request = false;
    };
  }, [fetch, restaurant]);

  useEffect(() => {
    if (!restaurant) {
      return;
    }

    const timer = setInterval(() => {
      setFiltered(orders =>
        orders.map(order => {
          order.dateDistance = formatDistance(parseISO(order.created_at), new Date());
          order.isLate = isAfter(new Date(), addMinutes(parseISO(order.created_at), restaurant.configs.delivery_time));
          return order;
        })
      );
    }, 60000);

    return () => {
      clearInterval(timer);
    };
  }, [restaurant]);

  function handleCancelConfirm() {
    handleOrderStatus('x');
  }

  function handleSearchTermChange(value: string) {
    setTerm(value);

    clearTimeout(timer);

    if (value.length < 2) {
      return;
    }

    timer = setTimeout(() => fetch(value), 500);
  }

  function handleQueryParamsChange(index: keyof OrdersQueryParams, value: any) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleCloseDialogPrintOrder() {
    setDialogPrintOrder(false);
    setSelectedOrder(null);
  }

  function handleCloseDialogPrintShipment() {
    setDialogPrintShipment(false);
    setSelectedOrder(null);
  }

  function handleCloseDialogPrintOnlyShipment() {
    setDialogPrintOnlyShipment(false);
    setSelectedOrder(null);
  }

  function handleCreateReceipt() {
    setSaving(true);

    const data = {
      additional_data: `pedido #${selectedOrder?.sequence} de ${selectedOrder?.formattedCreatedAt}`,
      customer_name: selectedOrder?.customer_name,
      customer_document: selectedOrder?.customer?.cpf,
      // shipment_street: selectedOrder?.shipment.address,
      // shipment_street_number: selectedOrder?.shipment.number,
      // shipment_complement: selectedOrder?.shipment.complement ?? undefined,
      // shipment_neighborhood: selectedOrder?.shipment.district,
      // shipment_city: selectedOrder?.shipment.city,
      // shipment_region: selectedOrder?.shipment.region,
    };

    api
      .post(`/orders/${selectedOrder?.uuid}/receipts`, data)
      .then(() => {
        handleOpen('Cupom fiscal em processamento');
      })
      .catch(error => {
        showErrorDialog({
          message: 'Não foi possível emitir o cupom fiscal',
          error,
        });
      })
      .finally(() => setSaving(false));
  }

  const ordersContextValue: OrdersContextValue = {
    orders,
    deliverers,
    selectedOrder,
    sendToPrintOrder,
    handleOrderStatus,
    sendToPrintShipment,
    setSelectedOrder,
    handleCancel: () => setDialogCancelConfirm(true),
    setDialogTrack,
    setDialogPrintOrder,
    setDialogPrintShipment,
    setDialogDeliverer,
    setDialogOrderPreview,
    setDialogDeliveryTime,
    setDialogPrintOnlyShipment,
    handleCreateReceipt,
  };

  return (
    <OrdersContextProvider value={ordersContextValue}>
      {saving && <Loading />}

      {dialogTrack && <OrderTrack order={selectedOrder} onExited={() => setDialogTrack(false)} />}

      {dialogOrderPreview && <OrderPreview onExited={() => setDialogOrderPreview(false)} />}

      {dialogDeliverer && (
        <OrderDeliverer
          onExited={() => setDialogDeliverer(false)}
          deliverers={deliverers}
          hideBackdrop={dialogOrderPreview}
        />
      )}

      {dialogPrintOnlyShipment && selectedOrder && (
        <DispatchedOrderOnly onExited={handleCloseDialogPrintOnlyShipment} orderId={selectedOrder.uuid} />
      )}

      {dialogPrintShipment && selectedOrder && (
        <DispatchedOrder onExited={handleCloseDialogPrintShipment} orderId={selectedOrder.uuid} />
      )}

      {dialogPrintOrder && selectedOrder && (
        <ApprovedOrder onExited={handleCloseDialogPrintOrder} orderId={selectedOrder.uuid} />
      )}

      {dialogCancelConfirm && selectedOrder && (
        <DialogDelete
          title="Confirmação"
          onExited={() => setDialogCancelConfirm(false)}
          handleDelete={handleCancelConfirm}
          message={`Deseja realmente cancelar o pedido ${selectedOrder.formattedId}?`}
          buttonText="sim, cancelar"
          cancelText="Não"
        />
      )}

      {dialogSearch && (
        <OrderSearch
          onExited={() => setDialogSearch(false)}
          queryParams={queryParams}
          handleQueryParamsChange={handleQueryParamsChange}
          handleSearchTermChange={handleSearchTermChange}
        />
      )}

      {dialogDeliveryTime && <OrdersDeliveryTime onExited={() => setDialogDeliveryTime(false)} />}

      <Appbar
        title="Pedidos"
        ActionComponents={
          <OrdersAction openDeliveryTime={() => setDialogDeliveryTime(true)} openSearch={() => setDialogSearch(true)} />
        }
      />

      <PageHeaderActions
        title="Pedidos de delivery"
        description="Gerencie seus pedidos"
        ActionComponent={
          <>
            <Button size="small" color="primary" variant="contained" onClick={() => setDialogDeliveryTime(true)}>
              Alterar tempo
            </Button>
            <Button size="small" color="primary" variant="contained" onClick={() => history.push('/orders/new')}>
              Adicionar
            </Button>
          </>
        }
      />

      <OrdersFilterBox
        gridMode={gridMode}
        setGridMode={setGridMode}
        queryParams={queryParams}
        handleSearchTermChange={handleSearchTermChange}
        handleQueryParamsChange={handleQueryParamsChange}
        term={term}
      />

      <PaginationProvider>
        {loading ? (
          gridMode === 'module' ? (
            <OrderLoading />
          ) : (
            <TableLoading />
          )
        ) : filtered.length > 0 ? (
          <div className={classes.container}>
            {gridMode === 'module' ? (
              <OrderListModule orders={filtered} />
            ) : (
              <OrderListTable handleSort={handleSort} orderedIndex={orderedIndex} orders={filtered} />
            )}
          </div>
        ) : (
          filtered.length === 0 && <NoData message="Nenhum pedido para mostrar." backgroundColor="inherit" />
        )}

        <ApiPagination
          onChangePage={value => handleQueryParamsChange('page', value)}
          onChangeRowsPerPage={value => handleQueryParamsChange('rows', value)}
          count={total}
        />
      </PaginationProvider>
    </OrdersContextProvider>
  );
};

export default Orders;
