import React from 'react';
import MaskedInput from 'react-text-mask';

const mask = (): Array<RegExp | string> => {
  return [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
};

interface TextMaskCustomProps {
  inputRef: (ref: HTMLElement | null) => void;
}

const CardInput: React.FC<TextMaskCustomProps> = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  );
};

export default CardInput;
