import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, List, ListItem, Typography, Tooltip } from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

const useStyles = makeStyles(theme => ({
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: 6,
    padding: '10px 0',
    width: '100%',
  },
  listItem: {
    display: 'flex',
    backgroundColor: '#fff',
    border: '1px solid #eee',
    position: 'relative',
    alignItems: 'center',
    minHeight: 90,
    justifyContent: 'space-between',
  },
  price: {
    fontWeight: 300,
  },
}));

ProductImportList.propTypes = {
  products: PropTypes.array.isRequired,
  handleProductImport: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};

export default function ProductImportList({ products, handleProductImport, handleCloseDialog }) {
  const classes = useStyles();

  function handleClick(product) {
    handleProductImport(product, handleCloseDialog);
  }

  return (
    <List className={classes.list}>
      {products.map(product => (
        <ListItem key={product.id} className={classes.listItem} button onClick={() => handleClick(product)}>
          <div>
            <Typography variant="h6">{product.name}</Typography>
            <Typography color="textSecondary" variant="body2">
              {product.description}
            </Typography>
            {product.price > 0 && (
              <Typography variant="h6" className={classes.price} color="primary">
                {product.formattedPrice}
              </Typography>
            )}
          </div>
          <Tooltip title="Clique para importar">
            <SaveAltIcon color="primary" />
          </Tooltip>
        </ListItem>
      ))}
    </List>
  );
}
