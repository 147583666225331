import { useApp } from 'App';
import { useEffect, useState, Dispatch, SetStateAction } from 'react';

export type GridMode = 'list' | 'module';

type UseGridMode = [GridMode, Dispatch<SetStateAction<GridMode>>];

export function useGridMode(key: string): UseGridMode {
  const [gridMode, setGridMode] = useState<GridMode>('list');
  const app = useApp();

  useEffect(() => {
    if (app.windowWidth >= 930) {
      setGridMode('list');
      return;
    }

    setGridMode('module');
  }, [app.isMobile, app.windowWidth]);

  useEffect(() => {
    const mode = localStorage.getItem(key);

    if (mode !== 'list' && mode !== 'module') {
      return;
    }

    setGridMode(mode);
  }, [key]);

  useEffect(() => {
    localStorage.setItem(key, gridMode);
  }, [gridMode, key]);

  return [gridMode, setGridMode];
}
