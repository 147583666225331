import { createContext, useContext } from 'react';

interface DialogPrintContextValue {
  handleClose(): void;
}

const DialogPrintContext = createContext<DialogPrintContextValue>({} as DialogPrintContextValue);
export const DialogPrintContextProvider = DialogPrintContext.Provider;
export const DialogPrintContextConsumer = DialogPrintContext.Consumer;

export function useDialogPrint(): DialogPrintContextValue {
  const context = useContext(DialogPrintContext);
  return context;
}
