import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
    fontWeight: 600,
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
  editButton: {
    position: 'absolute',
    right: 0,
  },
});

function CustomerStatus({ customer, handleSetStep }) {
  const classes = useStyles();

  return (
    <>
      {customer && (
        <>
          <div className={classes.head}>
            <Typography className={classes.title}>Cliente</Typography>
            <Button size="small" className={classes.editButton} color="primary" onClick={() => handleSetStep(1)}>
              Editar
            </Button>
          </div>
          <div className={classes.address}>
            <Typography>
              {customer.name}, {customer.phone}
            </Typography>
          </div>
        </>
      )}
    </>
  );
}

export default CustomerStatus;
