import { Plan } from 'types/plan';
import { PrinterSetting } from 'types/printerSettings';
import { Restaurant, RestaurantConfig, RestaurantCreditCard } from 'types/restaurant';

export const SET_RESTAURANT = '@restaurant/SET_RESTAURANT';
export const SET_IS_OPEN = '@restaurant/SET_IS_OPEN';
export const SET_IS_KITCHEN_OPEN = '@restaurant/SET_IS_KITCHEN_OPEN';
export const ADD_CREDIT_CARD = '@restaurant/ADD_CREDIT_CARD';
export const DELETE_CREDIT_CARD = '@restaurant/DELETE_CREDIT_CARD';
export const UPDATE_RESTAURANT_PLAN = '@restaurant/UPDATE_RESTAURANT_PLAN';
export const SET_PRINTER_SETTINGS = '@restaurant/SET_PRINTER_SETTINGS';

export interface SetRestaurantAction {
  type: typeof SET_RESTAURANT;
  restaurant: Restaurant;
}

export interface SetRestaurantIsOpen {
  type: typeof SET_IS_OPEN;
  isOpen: boolean;
}

export interface SetRestaurantKitchenIsOpen {
  type: typeof SET_IS_KITCHEN_OPEN;
  isKitchenOpen: boolean;
}

export interface AddCreditCardAction {
  type: typeof ADD_CREDIT_CARD;
  card: RestaurantCreditCard;
}

export interface DeleteCreditCardAction {
  type: typeof DELETE_CREDIT_CARD;
}

export interface UpdateRestaurantPlan {
  type: typeof UPDATE_RESTAURANT_PLAN;
  plan: Plan;
}

export interface SetPrinterSettingsAction {
  type: typeof SET_PRINTER_SETTINGS;
  settings: PrinterSetting;
}

export interface UpdateSettingAction {
  type: '@restaurant/UPDATE_SETTING';
  key: keyof RestaurantConfig;
  value: any;
}

export type RestaurantActionTypes =
  | SetRestaurantAction
  | SetRestaurantIsOpen
  | AddCreditCardAction
  | DeleteCreditCardAction
  | UpdateRestaurantPlan
  | SetPrinterSettingsAction
  | UpdateSettingAction
  | SetRestaurantKitchenIsOpen;
