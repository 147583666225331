import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import List from 'components/list/List';

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
    minHeight: 80,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 6,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  categoryIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
  },
  avatar: {
    borderRadius: '50%',
    border: `2px solid #fff`,
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#eee',
  },
}));

function ProductCategoryList({ categories, handleSetCategory }) {
  const classes = useStyles();

  return (
    <List>
      {categories.map(item => {
        return (
          <ListItem onClick={() => handleSetCategory(item)} key={item.id} className={classes.listItem} button>
            <div className={classes.content}>
              <div className={classes.categoryIcon}>
                <img className={classes.avatar} src={item.image && item.image.imageUrl} alt={item.name} />
              </div>
              <div>
                <Typography variant={'h6'}>{item.name}</Typography>
              </div>
            </div>
          </ListItem>
        );
      })}
    </List>
  );
}

ProductCategoryList.propTypes = {
  categories: PropTypes.array.isRequired,
};

export default ProductCategoryList;
