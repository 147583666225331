import { MenuItem, TextField } from '@material-ui/core';
import Modal from 'components/modal/Modal';
import KeyboardDatePicker from 'components/pickers/KeyboardDatePicker';
import { FC } from 'react';
import { BoardMovementsParams } from './BoardMovements';

interface BoardMovementMobileFilterProps {
  onExited(): void;
  params: BoardMovementsParams;
  handleChangeParams(index: keyof BoardMovementsParams, value: any): void;
}

const BoardMovementMobileFilter: FC<BoardMovementMobileFilterProps> = ({ onExited, params, handleChangeParams }) => {
  return (
    <Modal onExited={onExited} maxWidth="sm" title="Filtro">
      <TextField
        select
        label="Estado"
        fullWidth
        autoFocus
        value={params.is_open}
        onChange={e => handleChangeParams('is_open', e.target.value)}
        margin="normal"
      >
        <MenuItem value={1}>Aberto</MenuItem>
        <MenuItem value={0}>Fechado</MenuItem>
      </TextField>
      <KeyboardDatePicker
        label="Data inicial"
        value={params.initial_date}
        onChange={date => handleChangeParams('initial_date', date)}
        autoOk
        margin="normal"
      />
      <KeyboardDatePicker
        label="Data Final"
        value={params.final_date}
        onChange={date => handleChangeParams('final_date', date)}
        autoOk
        margin="normal"
      />
    </Modal>
  );
};

export default BoardMovementMobileFilter;
