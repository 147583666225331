import { Dispatch, SetStateAction, useState } from 'react';
import { Category } from 'types/category';
import * as yup from 'yup';

export type CategoryValidation = {
  image?: string;
  url?: string;
  name?: string;
  relevance?: string;
};

type UseCategoryValidation = [
  CategoryValidation,
  Dispatch<SetStateAction<CategoryValidation>>,
  (category: Category) => Promise<void>
];

export function useCategoryValidation(): UseCategoryValidation {
  const [validation, setValidation] = useState<CategoryValidation>({} as CategoryValidation);

  async function handleValidation(category: Category) {
    const schema = yup.object().shape({
      image: yup.object().typeError('A imagem é obrigatória').required('A imagem é obrigatória'),
      relevance: yup.string().required('A relevância é obrigatória'),
      url: yup.string().required('A URL é obrigatória'),
      name: yup.string().required('O nome é obrigatório'),
    });

    try {
      await schema.validate(category);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as any]: err.message,
        });
      }
      throw err;
    }
  }

  return [validation, setValidation, handleValidation];
}
