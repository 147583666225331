import React, { Fragment } from 'react';
import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  paper: {
    marginTop: 20,
    padding: 20,
  },
  day: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px',
  },
  animatedLoading: {
    width: '100%',
    height: 20,
    margin: '14px 0',
  },
};

function OpenHourListLoading({ classes }) {
  let cont;
  const content = [];

  for (cont = 0; cont < 7; cont++) {
    content[cont] = (
      <Paper key={cont} className={classes.paper}>
        <div className={classes.day}>
          <div className={`animated-background ${classes.animatedLoading}`} />
        </div>
      </Paper>
    );
  }

  return <Fragment>{content}</Fragment>;
}

export default withStyles(styles)(OpenHourListLoading);
