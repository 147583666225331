import { TableTemplate } from 'types/tableTemplate';

export const productsTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 60,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
    notFilterable: true,
  },
  {
    id: 'name',
    description: 'NOME',
    originalId: 'name',
    width: 300,
  },
  {
    id: 'description',
    description: 'DESCRIÇÃO',
    originalId: 'description',
    width: 350,
  },
  {
    id: 'categoryName',
    description: 'CATEGORIA',
    originalId: 'categoryName',
    width: 150,
  },
  {
    id: 'formattedPrice',
    description: 'PREÇO',
    originalId: 'price',
    width: 80,
  },
  {
    id: 'formattedSpecialPrice',
    description: 'PREÇO ESPECIAL',
    originalId: 'special_price',
    width: 120,
  },
  {
    id: 'hasComplements',
    description: 'COMPLEMENTOS',
    originalId: 'hasComplements',
    width: 120,
  },
  {
    id: 'formattedIsAvailableOnDelivery',
    description: 'DELIVERY',
    originalId: 'is_available_on_delivery',
    width: 70,
  },
  {
    id: 'formattedIsAvailableOnBoard',
    description: 'MESA',
    originalId: 'is_available_on_board',
    width: 70,
  },
  {
    id: 'formattedActive',
    description: 'ATIVO',
    originalId: 'formattedActive',
    width: 60,
  },
  {
    id: 'formattedCreatedAt',
    description: 'DATA',
    originalId: 'formattedCreatedAt',
    width: 150,
    notSearchable: true,
  },
];
