import { Area } from 'types/area';
import {
  AreaActionTypes,
  SET_AREAS,
  ADD_AREA,
  DELETE_AREA,
  ADD_REGION,
  DELETE_REGION,
  EDIT_REGION,
  ADD_DISTANCES,
  SET_RESTAURANT_ADDRESS,
} from './types';

export const INITIAL_STATE: Area[] = [];

export default function area(state = INITIAL_STATE, action: AreaActionTypes): Area[] {
  switch (action.type) {
    case SET_AREAS: {
      return action.areas;
    }

    case ADD_AREA: {
      return [
        ...state,
        {
          ...action.area,
          regions: [],
          distances: [],
          restaurant_address: null,
          setting: {
            delivery: true,
            customer_collect: true,
          },
        },
      ];
    }

    case DELETE_AREA: {
      const areas = state.filter(area => area.id !== action.areaId);
      return areas;
    }

    case ADD_REGION: {
      const areas = state.map(area => {
        if (area.id === action.areaId) {
          const region = { ...action.region, tax: action.region.tax };
          area.regions.push(region);
          return area;
        }
        return area;
      });

      return areas;
    }

    case DELETE_REGION: {
      const areas = state.map(area => {
        if (area.id === action.areaId) {
          area.regions = area.regions.filter(region => region.id !== action.regionId);
          return area;
        }
        return area;
      });

      return areas;
    }

    case EDIT_REGION: {
      const areas = state.map(area => {
        if (area.id === action.areaId) {
          area.regions = area.regions.map(region => {
            if (region.id === action.region.id) {
              return action.region;
            }
            return region;
          });
          return area;
        }
        return area;
      });

      return areas;
    }

    case ADD_DISTANCES: {
      const areas = state.map(area => {
        if (area.id === action.areaId) {
          area.distances = action.distances;
          return area;
        }
        return area;
      });

      return areas;
    }

    case SET_RESTAURANT_ADDRESS: {
      const areas = state.map(area => {
        if (area.id === action.areaId) {
          area.restaurant_address = action.address;
        }

        return area;
      });

      return areas;
    }

    case 'AREA_SETTING_CHANGE': {
      const areas = state.map(area => {
        if (area.id === action.areaId) {
          area.setting = {
            ...area.setting,
            [action.index]: action.value,
          };
        }
        return area;
      });

      return areas;
    }

    default: {
      return state;
    }
  }
}
