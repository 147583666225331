import { useReducer, Dispatch } from 'react';
import { PaymentMethodConfigs, PicPayConfig } from 'types/paymentMethod';
import { PaymentMethodsActions } from './types';

type PaymentMethodsReducer = {
  gateway_settings: PaymentMethodConfigs;
  picpay_settings: PicPayConfig;
};

export const INITIAL_STATE: PaymentMethodsReducer = {
  gateway_settings: {
    gateway: 'pagarme',
    pix_gateway: 'mercadopago',
    pagarme_api_key: '',
    cielo_merchant_id: '',
    cielo_merchant_key: '',
    cielo_env: 'development',
    pagseguro_api_token: '',
    rede_env: 'development',
    rede_pv: '',
    rede_token: '',
    mercado_pago_access_token: '',
    mercado_pago_public_key: '',
  },
  picpay_settings: {
    picpay_token: '',
    picpay_expiration_time: 0,
    picpay_seller_token: '',
  },
};

function paymentMethodsReducer(state = INITIAL_STATE, action: PaymentMethodsActions): PaymentMethodsReducer {
  switch (action.type) {
    case 'PAYMENT_METHOD_CONFIGS_CHANGE': {
      return {
        ...state,
        gateway_settings: {
          ...state.gateway_settings,
          [action.index]: action.value,
        },
      };
    }

    case 'SET_PICPAY_CONFIG': {
      return {
        ...state,
        picpay_settings: action.picpayConfig,
      };
    }

    case 'PICPAY_CONFIG_CHANGE': {
      return {
        ...state,
        picpay_settings: {
          ...state.picpay_settings,
          [action.index]: action.value,
        },
      };
    }

    case 'SET_PAYMENT_METHOD_CONFIGS': {
      return {
        ...state,
        gateway_settings: action.configs,
      };
    }

    default: {
      return state;
    }
  }
}

type UsePaymentMethodsReducer = [PaymentMethodsReducer, Dispatch<PaymentMethodsActions>];

export function usePaymentMethodsReducer(): UsePaymentMethodsReducer {
  const reducer = useReducer(paymentMethodsReducer, INITIAL_STATE);
  return reducer;
}
