import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { User } from 'types/user';

export interface UsersContextValue {
  handleToggleUserState(): void;
  users: User[];
  selectedUser: User | null;
  setSelectedUser: Dispatch<SetStateAction<User | null>>;
}

const UsersContext = createContext<UsersContextValue>({} as UsersContextValue);
export const UsersProvider = UsersContext.Provider;

export function useUsers(): UsersContextValue {
  const context = useContext(UsersContext);
  return context;
}
