import React, { useState } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import UpdatePaymentMethodAppbarButtons from './UpdatePaymentMethodAppbarButtons';
import PageHeader from 'components/page-header/PageHeader';
import { makeStyles } from '@material-ui/core';
import PaymentMethodForm from '../PaymentMethodForm';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import history from 'services/history';
import Loading from 'components/loading/Loading';
import { useParams } from 'react-router-dom';
import InsideLoading from 'components/loading/InsideLoading';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { usePaymentMethodReducer } from '../reducer/reducer';
import { usePaymentMethodValidation } from '../validation/usePaymentMethodValidation';
import { paymentMethodChangeAction } from '../reducer/actions';
import { useFetchPaymentMethod } from 'pages/payment-methods/hooks/useFetchPaymentMethod';
import { PaymentMethod } from 'types/paymentMethod';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 400,
  },
});

const UpdatePaymentMethod: React.FC = () => {
  const classes = styles();
  const messaging = useMessaging();
  const { id } = useParams<{ id: string }>();
  const { showErrorDialog } = useErrorHandler();
  const [serial, dispatch] = usePaymentMethodReducer();
  const [validation, setValidation, validate] = usePaymentMethodValidation();
  const [saving, setSaving] = useState(false);
  const [loading] = useFetchPaymentMethod(dispatch, id);

  function handleValidation() {
    setValidation({});

    validate(serial)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/payment-methods/${id}`, serial)
      .then(() => {
        messaging.handleOpen('Salvo');
        setSaving(false);
        history.push('/payment-methods');
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível salvar',
        });
        setSaving(false);
      });
  }

  function handleChange(key: keyof PaymentMethod, value: any) {
    dispatch(paymentMethodChangeAction(key, value));
  }

  return (
    <>
      {saving && <Loading />}

      <CustomAppbar
        title="Método de pagamento"
        ActionComponents={<UpdatePaymentMethodAppbarButtons handleSubmit={handleValidation} />}
      />

      <PageHeader title="Editar método de pagamento" backAction={() => history.push('/payment-methods')} />

      {loading ? (
        <InsideLoading />
      ) : (
        <div className={classes.container}>
          <PaymentMethodForm handleChange={handleChange} paymentMethod={serial} validation={validation} />
        </div>
      )}
    </>
  );
};

export default UpdatePaymentMethod;
