import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Menu, MenuItem } from '@material-ui/core';
import { useCustomer } from '../../hooks/useCustomer';
import CustomerAddressItem from './CustomerAddressItem';
import List from 'components/list/List';

const useStyles = makeStyles(theme => ({
  list: {
    //
  },
}));

const CustomerAddressList: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { customer, handleUpdateIsMainAddress, selectedAddress, setDialogEditAddress } = useCustomer();

  return (
    <>
      {selectedAddress && (
        <Menu onClose={() => setAnchorEl(null)} anchorEl={anchorEl} open={Boolean(anchorEl)}>
          {!selectedAddress?.is_main && (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                handleUpdateIsMainAddress(selectedAddress.id);
              }}
            >
              Marcar como principal
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setDialogEditAddress(true);
            }}
          >
            Editar
          </MenuItem>
        </Menu>
      )}
      <List className={classes.list}>
        {customer.addresses.map(address => (
          <CustomerAddressItem address={address} key={address.id} setAnchorEl={setAnchorEl} />
        ))}
      </List>
    </>
  );
};

export default CustomerAddressList;
