import { Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import { useProducts } from '../hooks/useProducts';

type ProductsListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl(anchorEl: null | HTMLButtonElement): void;
};

const ProductsListMenu: React.FC<ProductsListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedProduct, handleProductActive, setIsOpenDeleteConfirmation } = useProducts();

  function handleActiveClick() {
    setAnchorEl(null);
    handleProductActive();
  }

  function handleDeleteClick() {
    setIsOpenDeleteConfirmation(true);
    setAnchorEl(null);
  }

  return (
    <>
      {selectedProduct && (
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={handleActiveClick}>
            {selectedProduct.activated ? 'Pausar vendas' : 'Ativar vendas'}
          </MenuItem>
          <MenuItem onClick={handleDeleteClick}>Excluir</MenuItem>
        </Menu>
      )}
    </>
  );
};

export default ProductsListMenu;
