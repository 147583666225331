import React, { useEffect, useState } from 'react';
import DialogInput from 'components/dialog/DialogInput';
import { useInvoiceSocket } from '../hooks/useInvoiceSocket';
import { Invoice } from 'types/invoice';
import BillingPixSuccess from './BillingPixSuccess';
import { useBilling } from '../hooks/useBilling';
import BillingPixQrCode from './BillingPixQrCode';

interface BillingPixProps {
  qrCode: string;
  qrCodeBase64: string;
  onExited(): void;
  invoice: Invoice | null;
}

const BillingPix: React.FC<BillingPixProps> = ({ qrCode, qrCodeBase64, onExited, invoice }) => {
  const [socket] = useInvoiceSocket(invoice);
  const [isPaid, setIsPaid] = useState(false);
  const { handleInvoicePaid } = useBilling();

  useEffect(() => {
    socket.on('invoice_paid', (response: any) => {
      setIsPaid(true);
      handleInvoicePaid(response.invoice_id);
    });
  }, [socket, handleInvoicePaid]);

  return (
    <DialogInput onExited={onExited}>
      {isPaid ? <BillingPixSuccess /> : <BillingPixQrCode qrCode={qrCode} qrCodeBase64={qrCodeBase64} />}
    </DialogInput>
  );
};

export default BillingPix;
