import React, { useEffect, useMemo, useState } from 'react';
import BoardButtons from './BoardButtons';
import InsideSaving from 'components/loading/InsideSaving';
import { BoardOrderProduct } from 'types/boardOrderProduct';
import { api } from 'services/api';
import { makeStyles } from '@material-ui/core';
import useTableOrder from 'hooks/tableOrder';
import TableLoading from 'components/loading/TableLoading';
import BoardProducts from './products/BoardProducts';
import { BoardControlProvider } from './hooks/useBoardControl';
import { useParams } from 'react-router-dom';
import Appbar from 'components/appbar/Appbar';
import { useFetchBoardMovement } from './hooks/useFetchBoardMovement';
import { useFecthBoardMovementProducts } from './hooks/useFetchBoardMovementProducts';
import history from 'services/history';
import BoardControlPayment from './payment/registration/BoardControlPayment';
import { useFetchBoardMovementPayment } from './hooks/useFetchBoardMovementPayments';
import NoData from 'components/nodata/NoData';
import BoardControlLoading from './BoardControlLoading';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import BoardControlInfo from './BoardControlInfo';
import BoardControlActions from './BoardControlActions';
import { useBoardControlSocket } from './hooks/useBoardControlSocket';
import { useApp } from 'App';
import ProductSimple from './products/detail/simple/ProductSimple';
import ProductPizzaComplement from './products/detail/pizza_complement/ProductPizzaComplement';
import ProductComplement from './products/detail/complement/ProductComplement';
import BoardControlDeleteProduct from './BoardControlDeleteProduct';
import BoardControlTransfer from './transfer/BoardControlTransfer';
import BoardControlBill from './print/BoardControlBill';
import { useBoardControlReducer } from './reducer/reducer';
import { removeBoardProduct } from './reducer/actions';
import BoardControlSplitProduct from './divide/BoardControlSplitProduct';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { useMessaging } from 'hooks/messaging';

const styles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gap: 10,
    gridTemplateColumns: '1.5fr 0.5fr',
    flex: 1,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));

const BoardControl: React.FC = () => {
  const classes = styles();
  const { id } = useParams<{ id: string }>();
  const app = useApp();
  const [movement, dispatch] = useBoardControlReducer();
  const [saving, setSaving] = useState(false);
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<BoardOrderProduct[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<BoardOrderProduct | null>(null);
  const [movementLoading] = useFetchBoardMovement(dispatch, id);
  const [paymentsLoading] = useFetchBoardMovementPayment(dispatch, movement ? id : undefined);
  const [productsLoading] = useFecthBoardMovementProducts(dispatch, movement ? id : undefined);
  const [selectedProducts, setSelectedProducts] = useState<BoardOrderProduct[]>([]);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [showDialogUpdate, setShowDialogUpdate] = useState(false);
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const [showDialogTransfer, setShowDialogTransfer] = useState(false);
  const [showDialogBill, setShowDialogBill] = useState(false);
  const [showDialogSplit, setShowDialogSplit] = useState(false);
  const { showErrorDialog } = useErrorHandler();
  const { handleOpen } = useMessaging();

  useBoardControlSocket(dispatch, id);

  const isPizza = useMemo(() => {
    return !!selectedProduct?.category.is_pizza;
  }, [selectedProduct]);

  const isComplement = useMemo(() => {
    return !!selectedProduct?.category.has_complement && !selectedProduct?.category.is_pizza;
  }, [selectedProduct]);

  const isSimple = useMemo(() => {
    return selectedProduct ? !selectedProduct.category.has_complement : false;
  }, [selectedProduct]);

  const isLoading = useMemo(
    () => movementLoading || productsLoading || paymentsLoading,
    [productsLoading, movementLoading, paymentsLoading]
  );

  const paidProductIds = useMemo(() => {
    if (!movement) {
      return [];
    }

    const ids: number[] = [];

    movement.payments.forEach(payment => ids.push(...payment.parsedOrderProductIds));

    return ids;
  }, [movement]);

  useEffect(() => {
    setFiltered(movement?.products ?? []);
  }, [movement]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  async function handleDeleteProduct(product: BoardOrderProduct) {
    await api.delete(`/orderProducts/${product.id}`);
    dispatch(removeBoardProduct(product.id));
  }

  async function handleSetFreeBoard() {
    await api.patch(`/boardMovements/${id}/free`);
    history.push('/board-management');
  }

  async function handleCreateReceipt() {
    const payment = movement?.payments.find(payment => payment.payment_method.must_create_receipt);

    const data = {
      additional_data: `sessão de mesa ${movement?.id}`,
      payment_method_id: payment?.payment_method_id ?? movement?.payments[0].payment_method_id,
    };

    try {
      setSaving(true);
      await api.post(`/board-sessions/${id}/receipts`, data);
      handleOpen('Cupom emitido com successo');
    } catch (error) {
      showErrorDialog({
        error,
        message: 'Não foi possível emitir o cupom fiscal',
      });
    } finally {
      setSaving(false);
    }
  }

  function handleSetSelectedProducts(product: BoardOrderProduct) {
    const exist = selectedProducts.some(item => item.id === product.id);

    if (exist) {
      setSelectedProducts(state => state.filter(item => item.id !== product.id));
      return;
    }

    setSelectedProducts(state => [...state, product]);
  }

  function getHeaderTitle() {
    if (movement?.customer) {
      return `${movement?.customer?.name} - mesa ${movement?.board?.number}`;
    }

    return `mesa ${movement?.board?.number}`;
  }

  return (
    <BoardControlProvider
      value={{
        setSelectedProduct,
        handleDeleteProduct,
        setIsPaymentModalOpen,
        setSelectedProducts,
        handleSetFreeBoard,
        handleSetSelectedProducts,
        setShowDialogUpdate,
        setShowDialogDelete,
        setShowDialogTransfer,
        setShowDialogBill,
        setShowDialogSplit,
        dispatch,
        handleCreateReceipt,
        selectedProduct,
        selectedProducts,
        movement,
        paidProductIds,
      }}
    >
      {isLoading ? (
        <BoardControlLoading />
      ) : (
        <>
          <PageHeaderActions
            title={getHeaderTitle()}
            description={`Mesa aberta em ${movement?.formattedCreatedAt}`}
            backAction={app.isMobile ? undefined : history.back}
            ActionComponent={<BoardButtons saving={saving} setSaving={setSaving} />}
          />

          <Appbar
            backAction={history.back}
            title={`Mesa ${movement?.board?.number}`}
            ActionComponents={<BoardControlActions saving={saving} setSaving={setSaving} />}
          />

          {isSimple && showDialogUpdate && <ProductSimple onExited={() => setShowDialogUpdate(false)} />}

          {isPizza && showDialogUpdate && <ProductPizzaComplement onExited={() => setShowDialogUpdate(false)} />}

          {isComplement && showDialogUpdate && <ProductComplement onExited={() => setShowDialogUpdate(false)} />}

          {saving && <InsideSaving />}

          {isPaymentModalOpen && <BoardControlPayment onExited={() => setIsPaymentModalOpen(false)} />}

          {showDialogDelete && <BoardControlDeleteProduct onExited={() => setShowDialogDelete(false)} />}

          {showDialogTransfer && (
            <BoardControlTransfer boardMovementId={id} onExited={() => setShowDialogTransfer(false)} />
          )}

          {showDialogBill && <BoardControlBill onExited={() => setShowDialogBill(false)} />}

          {showDialogSplit && <BoardControlSplitProduct onExited={() => setShowDialogSplit(false)} />}

          <div className={classes.container}>
            {productsLoading ? (
              <TableLoading />
            ) : !movement?.products.length ? (
              <NoData minHeight={200} backgroundColor="inherit" message="nenhum produto para mostrar" />
            ) : (
              <>
                <BoardProducts handleSort={handleSort} orderedIndex={orderedIndex} />
                <BoardControlInfo />
              </>
            )}
          </div>
        </>
      )}
    </BoardControlProvider>
  );
};

export default BoardControl;
