import { useCallback } from 'react';
import { Receipt, ReceiptOnList } from '../types/receipt';
import { format, parseISO } from 'date-fns';
import { moneyFormat } from 'helpers/NumberFormat';
import { RECEIPT_STATUS_MAPPING } from '../constants/receiptStatusMapping';
import { ptBR } from 'date-fns/locale';

export function useReceiptFormatter(): [(receipt: Receipt) => ReceiptOnList] {
  const formatter = useCallback((receipt: Receipt) => {
    return {
      ...receipt,
      formattedCreatedAt: format(parseISO(receipt.created_at as string), 'Pp', { locale: ptBR }),
      formattedTaxValue: moneyFormat(receipt.tax_value),
      formattedUpdatedAt: format(parseISO(receipt.updated_at as string), 'Pp', { locale: ptBR }),
      formattedTotalValue: moneyFormat(receipt.total_value),
      formattedCurrentStatus: RECEIPT_STATUS_MAPPING[receipt.current_status],
      customer_name: receipt.customer_name || 'Não informado',
      type: receipt.fiscal_serial?.type ?? '',
      serial: receipt.fiscal_serial?.serial ?? '',
    };
  }, []);

  return [formatter];
}
