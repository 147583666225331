import React, { useState } from 'react';
import { Grid, TextField, IconButton, FormControlLabel, Switch, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { deleteComplement, changeComplement, changeComplementStatus } from 'store/modules/product/actions';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import PlayIcon from '@material-ui/icons/PlayCircleFilled';
import PauseIcon from '@material-ui/icons/PauseCircleFilled';
import Loading from 'components/loading/Loading';
import { useProduct } from 'pages/products/registration/hooks/useProduct';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    borderRight: `2px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    borderBottom: `2px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    borderRadius: '0 0 4px 0',
    marginBottom: 10,
    padding: 10,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: 15,
    },
  },
  cancelIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 100,
  },
  moreVertIcon: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 100,
  },
  actions: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  playPauseButton: {
    padding: 0,
    margin: '5px 15px 5px 0',
  },
}));

ProductComplementItem.propTypes = {
  complements: PropTypes.array.isRequired,
  categoryId: PropTypes.number.isRequired,
};

function ProductComplementItem({ complements, categoryId }) {
  const classes = useStyles();
  const [selectedComplement, setSelectedComplement] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { dispatch } = useProduct();
  const messaging = useMessaging();
  const [saving, setSaving] = useState(false);

  function handleClickDelete() {
    handleDeleteComplement(selectedComplement.id);
    setSelectedComplement(null);
    setAnchorEl(null);
  }

  function handleClickMore(event, complement) {
    setSelectedComplement(complement);
    setAnchorEl(event.currentTarget);
  }

  function handleDeleteComplement(complementId) {
    dispatch(deleteComplement(categoryId, complementId, true));
  }

  function handleChangeComplement(index, value, complementId) {
    dispatch(changeComplement(index, value, categoryId, complementId));
  }

  function handleComplementStatus(complementId) {
    setSaving(true);
    api
      .put(`/products/complements/status/${complementId}`)
      .then(response => {
        messaging.handleOpen(`Complemento ${response.is_ativated ? 'ativado' : 'desativado'}`);
        dispatch(changeComplementStatus(categoryId, complementId));
      })
      .catch(() => {
        messaging.handleOpen('Aconteceu um erro ao atualizar o complemento');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      {selectedComplement && (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={handleClickDelete}>Excluir</MenuItem>
        </Menu>
      )}
      {complements.map((complement, complementIndex) => (
        <Grid key={complement.id} container spacing={1} className={classes.container}>
          <IconButton onClick={event => handleClickMore(event, complement)} className={classes.moreVertIcon}>
            <MoreVertIcon />
          </IconButton>
          <Grid item xl={4} lg={4} md={4} xs={12}>
            <TextField
              autoFocus
              label="Nome"
              placeholder="Digite o nome do complemento"
              fullWidth
              value={complement.name}
              onChange={event => handleChangeComplement('name', event.target.value, complement.id)}
            />
          </Grid>
          <Grid item xl={5} lg={5} md={5} xs={12}>
            <TextField
              label="Descrição"
              placeholder="Digite a descrição do complemento"
              fullWidth
              value={complement.description}
              onChange={event => handleChangeComplement('description', event.target.value, complement.id)}
            />
          </Grid>
          <Grid item xl={2} lg={2} md={3} xs={12}>
            <TextField
              type="number"
              label="Preço R$"
              placeholder="Digite o preço do complemento"
              fullWidth
              value={complement.price ? complement.price : ''}
              inputProps={{
                step: 0.01,
                min: 0.01,
              }}
              onChange={event => handleChangeComplement('price', event.target.value, complement.id)}
            />
          </Grid>
          <div className={classes.actions}>
            {!complement.new && (
              <Tooltip title={complement.is_activated ? 'Pausar vendas' : 'Ativar vendas'}>
                <IconButton onClick={() => handleComplementStatus(complement.id)} className={classes.playPauseButton}>
                  {complement.is_activated ? <PauseIcon color="primary" /> : <PlayIcon />}
                </IconButton>
              </Tooltip>
            )}
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  onChange={event => handleChangeComplement('selected', event.target.checked, complement.id)}
                  checked={complement.selected}
                />
              }
              label="Selecionado"
            />
          </div>
        </Grid>
      ))}
    </>
  );
}

export default ProductComplementItem;
