import { useMemo } from 'react';
import { useSelector } from 'store/redux/selector';
import { Position } from 'types/position';

export function useRestaurantAddressPosition(): Position {
  const restaurant = useSelector(state => state.restaurant);

  const mainAddress = restaurant?.addresses.find(address => address.is_main);

  const restaurantAddressCoordinate = useMemo(() => {
    return {
      lat: mainAddress?.latitude as number,
      lng: mainAddress?.longitude as number,
    };
  }, [mainAddress]);

  return restaurantAddressCoordinate;
}
