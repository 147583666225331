import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { useProduct } from 'pages/products/registration/hooks/useProduct';

const useStyles = makeStyles(theme => ({
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 6,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  listItem: {
    display: 'flex',
    marginBottom: 5,
    backgroundColor: '#fff',
    border: '1px solid #eee',
    borderRadius: theme.shape.borderRadius,
  },
  selected: {
    display: 'flex',
    marginBottom: 5,
    border: '1px solid #eee',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    justifyContent: 'space-between',
    '&:focus': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
    },
  },
  icon: {
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
}));

ProductPrintersStep.propTypes = {
  printers: PropTypes.array.isRequired,
};

export default function ProductPrintersStep({ printers }) {
  const classes = useStyles();
  const { product, handleChange, handleNext } = useProduct();
  function handleClick(printer) {
    handleChange(printer, 'printer');
    handleNext();
  }

  return (
    <List className={classes.list}>
      <ListItem
        onClick={() => handleClick(null)}
        button
        className={!product.printer ? classes.selected : classes.listItem}
      >
        <Typography>Sem impressora</Typography>
        {!product.printer && <CheckCircleIcon className={classes.icon} color="primary" />}
      </ListItem>
      {printers.map(
        printer =>
          printer.activated && (
            <ListItem
              key={printer.id}
              className={product.printer && printer.id === product.printer.id ? classes.selected : classes.listItem}
              onClick={() => handleClick(printer)}
              button
            >
              <div>
                <Typography>{printer.name}</Typography>
                <Typography color="textSecondary" variant="body2">
                  {printer.address}
                </Typography>
              </div>
              {product.printer && (
                <>{printer.id === product.printer.id && <CheckCircleIcon className={classes.icon} color="primary" />}</>
              )}
            </ListItem>
          )
      )}
    </List>
  );
}
