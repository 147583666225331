import React, { FC } from 'react';
import { ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Product } from 'types/product';
import { useProducts } from './hooks/useProducts';

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    backgroundColor: '#fff',
    border: '1px solid #f5f5f5',
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    alignItems: 'center',
    minHeight: 120,
  },
  img: {
    width: '100%',
    borderRadius: 4,
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: 100,
    overflow: 'hidden',
    borderRadius: 4,
  },
  productData: {
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1',
    alignItems: 'flex-start',
  },
  price: {
    fontWeight: 300,
  },
  oldPrice: {
    textDecoration: 'line-through',
  },
  specialPrice: {},
  specialPriceContent: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    minWidth: 135,
  },
}));

interface ProductItemProps {
  product: Product;
}

const ProductItem: FC<ProductItemProps> = ({ product }) => {
  const classes = useStyles();
  const { handleSelectProduct } = useProducts();

  return (
    <ListItem button onClick={() => handleSelectProduct(product)} key={product.id} className={classes.listItem}>
      <div className={classes.productData}>
        <Typography variant="h6">{product.name}</Typography>

        <Typography variant="body2" color="textSecondary">
          {product.description}
        </Typography>

        {product.promotion_activated && product?.special_price ? (
          <div className={classes.specialPriceContent}>
            <Typography variant="body2" color="textSecondary" className={classes.oldPrice}>
              {product.formattedPrice}
            </Typography>
            <Typography variant="h6" className={classes.specialPrice} color="primary">
              {product.formattedSpecialPrice}
            </Typography>
          </div>
        ) : (
          product.price > 0 && (
            <Typography variant="h6" className={classes.price} color="primary">
              {product.formattedPrice}
            </Typography>
          )
        )}
      </div>

      {product.image && (
        <div className={classes.imageWrapper}>
          <img
            className={classes.img}
            src={product.image.imageThumbUrl ? product.image.imageThumbUrl : product.image.imageUrl}
            alt={product.name}
          />
        </div>
      )}
    </ListItem>
  );
};

export default ProductItem;
