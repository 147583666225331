import { makeStyles, Switch, Typography } from '@material-ui/core';
import CustomDialog from 'components/dialog/CustomDialog';
import React from 'react';
import { areaSettingChange } from 'store/modules/area/actions';
import { AreaSetting as AreaSettingType } from 'types/area';
import { useArea } from '../Areas';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    flexDirection: 'column',
    display: 'flex',
    borderBottom: '1px solid #eee',
    marginBottom: 15,
    paddingBottom: 15,
    '& > div': {
      marginBottom: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
});

interface AreaSettingProps {
  onExited(): void;
}

const AreaSetting: React.FC<AreaSettingProps> = ({ onExited }) => {
  const classes = useStyles();
  const { dispatch, selectedArea } = useArea();

  function handleChange(index: keyof AreaSettingType, value: boolean) {
    dispatch(areaSettingChange(index, value, selectedArea.id));
  }

  return (
    <CustomDialog maxWidth="sm" handleModalState={onExited} title={`Configuração da área de ${selectedArea.name}`}>
      <div className={classes.container}>
        <div className={classes.formControl}>
          <div>
            <span>ENTREGA</span>
            <Switch
              color="primary"
              onChange={event => handleChange('delivery', event.target.checked)}
              checked={selectedArea.setting.delivery}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Permite ao usuário escolher a opção {`"Entrega"`}, na finalização do pedido
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>RETIRADA</span>
            <Switch
              color="primary"
              onChange={event => handleChange('customer_collect', event.target.checked)}
              checked={selectedArea.setting.customer_collect}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Permite ao usuário escolher a opção {`"Retirada"`}, na finalização do pedido
          </Typography>
        </div>
      </div>
    </CustomDialog>
  );
};

export default AreaSetting;
