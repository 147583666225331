import React from 'react';
import { Grid, Button, Paper, TextField, IconButton, Switch, FormControlLabel } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import ProductComplementItem from './ProductComplementItem';
import { addComplement, deleteComplementCategory, changeComplementCategory } from 'store/modules/product/actions';
import { useProduct } from 'pages/products/registration/hooks/useProduct';

const useStyles = makeStyles({
  paper: {
    padding: 20,
    marginBottom: 10,
    position: 'relative',
    boxShadow: 'none',
    border: '1px solid #eee',
    '&:first-child': {
      marginTop: 15,
    },
  },
  buttonAddComplement: {
    margin: '15px 0 10px',
  },
  cancelIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 100,
  },
  container: {
    marginBottom: 15,
  },
  isRequiredContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

function ProductComplementCategory() {
  const classes = useStyles();
  const { product, dispatch } = useProduct();

  function handleAddComplement(complementCategoryId) {
    const category = product.complement_categories.find(category => category.id === complementCategoryId);

    if (!category) return;
    dispatch(addComplement(complementCategoryId, true));
  }

  function handleDeleteComplementCategory(complementCategoryId) {
    dispatch(deleteComplementCategory(complementCategoryId));
  }

  function handleChangeComplementCategory(index, value, complementCategoryId) {
    dispatch(changeComplementCategory(index, value, complementCategoryId));
  }

  return (
    <>
      {product.complement_categories.map((category, categoryIndex) => (
        <Paper key={category.id} className={classes.paper}>
          <IconButton className={classes.cancelIcon} onClick={() => handleDeleteComplementCategory(category.id)}>
            <CancelIcon color="primary" />
          </IconButton>
          <TextField
            autoFocus
            label="Nome"
            placeholder="Digite o nome da categoria do complemento"
            margin="normal"
            fullWidth
            value={category.name}
            onChange={event => handleChangeComplementCategory('name', event.target.value, category.id)}
          />
          <TextField
            autoFocus
            label="Nome para impressão"
            placeholder="Digite o nome da categoria para impressão"
            margin="normal"
            fullWidth
            value={category.print_name || ''}
            onChange={event => handleChangeComplementCategory('print_name', event.target.value, category.id)}
          />
          <Grid container spacing={1} className={classes.container}>
            <Grid item xl={2} lg={2} md={4} xs={12}>
              <TextField
                disabled={!category.is_required}
                label="Quantidade mínima"
                placeholder="Quantidade mínima do complemento"
                margin="normal"
                fullWidth
                value={category.min_quantity}
                onChange={event => handleChangeComplementCategory('min_quantity', event.target.value, category.id)}
              />
            </Grid>
            <Grid item xl={2} lg={2} md={4} xs={12}>
              <TextField
                label="Quantidade máxima"
                placeholder="Quantidade máxima do complemento"
                margin="normal"
                fullWidth
                value={category.max_quantity}
                onChange={event => handleChangeComplementCategory('max_quantity', event.target.value, category.id)}
              />
            </Grid>
            <Grid item xl={8} lg={4} md={4} xs={12} className={classes.isRequiredContent}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    onChange={event =>
                      handleChangeComplementCategory('order_by_name', event.target.checked, category.id)
                    }
                    checked={category.order_by_name}
                  />
                }
                label="Ordenar por nome"
              />
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    onChange={event => handleChangeComplementCategory('is_required', event.target.checked, category.id)}
                    checked={category.is_required}
                  />
                }
                label="Obrigatório"
              />
            </Grid>
          </Grid>
          <ProductComplementItem categoryId={category.id} complements={category.complements} />
          <Grid item xs={12}>
            <Button
              size="small"
              color="primary"
              variant="text"
              onClick={() => handleAddComplement(category.id)}
              className={classes.buttonAddComplement}
            >
              Adicionar complemento
            </Button>
          </Grid>
        </Paper>
      ))}
    </>
  );
}

export default ProductComplementCategory;
