import React, { useState, useEffect, useCallback } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import TaxRulesAppbarButtons from './TaxRulesAppbarButtons';
import PageHeader from 'components/page-header/PageHeader';
import { makeStyles } from '@material-ui/core';
import BoardForm from '../TaxRuleForm';
import { useTaxRuleValidation } from '../validation/useTaxRuleValidation';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import history from 'services/history';
import Loading from 'components/loading/Loading';
import { useParams } from 'react-router-dom';
import InsideLoading from 'components/loading/InsideLoading';
import { useTaxRuleReducer } from '../reducer/reducer';
import { setTaxRule, taxRuleChange } from '../reducer/actions';
import { TaxRule } from 'pages/tax-rules/types/taxRule';
import { useErrorHandler } from 'providers/error-handler/error-handler';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 600,
  },
});

const TaxRuleEdit: React.FC = () => {
  const classes = styles();
  const [taxRule, dispatch] = useTaxRuleReducer();
  const [validation, setValidation, validate] = useTaxRuleValidation();
  const messaging = useMessaging();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: string }>();
  const { showErrorDialog } = useErrorHandler();

  useEffect(() => {
    api
      .get(`/tax-rules/${id}`)
      .then(response => {
        dispatch(setTaxRule(response.data));
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível salvar',
        });
      })
      .finally(() => setLoading(false));
  }, [id, dispatch, showErrorDialog]);

  function handleValidation() {
    setValidation({});

    validate(taxRule)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/tax-rules/${id}`, taxRule)
      .then(() => {
        messaging.handleOpen('Salvo');
        setSaving(false);
        history.push('/tax-rules');
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível salvar',
        });
        setSaving(false);
      });
  }

  const handleChange = useCallback(
    (key: keyof TaxRule, value: any) => {
      dispatch(taxRuleChange(key, value));
    },
    [dispatch]
  );

  return (
    <>
      {saving && <Loading />}

      <CustomAppbar title="Regra fiscal" ActionComponents={<TaxRulesAppbarButtons handleSubmit={handleValidation} />} />

      <PageHeader title="Editar regra fiscal" backAction={() => history.push('/tax-rules')} />

      {loading ? (
        <InsideLoading />
      ) : (
        <div className={classes.container}>
          <BoardForm handleChange={handleChange} taxRule={taxRule} validation={validation} />
        </div>
      )}
    </>
  );
};

export default TaxRuleEdit;
