import React from 'react';
import { TextField } from '@material-ui/core';
import { usePaymentMethods } from '../../hooks/usePaymentMethods';

export default function PaymentMethodPagarMe() {
  const { handleConfigsChange, gatewaySettings } = usePaymentMethods();

  return (
    <>
      <TextField
        label="Pagar-me API key"
        margin="normal"
        fullWidth
        value={gatewaySettings.pagarme_api_key}
        onChange={e => handleConfigsChange('pagarme_api_key', e.target.value)}
        placeholder="Pagar-me API key"
        autoFocus
      />
    </>
  );
}
