export type StepIds = 'STEP_1' | 'STEP_2' | 'STEP_3' | 'STEP_4' | 'STEP_5' | 'STEP_6';

interface Steps {
  id: StepIds;
  order: number;
  title: string;
  description: string;
}

const steps: Steps[] = [
  {
    id: 'STEP_1',
    order: 1,
    title: 'Cliente',
    description: 'Qual é o cliente?',
  },
  {
    id: 'STEP_2',
    order: 2,
    title: 'Categoria',
    description: 'O que o cliente deseja?',
  },
  {
    id: 'STEP_3',
    order: 3,
    title: 'Produto',
    description: 'Selecione o produto.',
  },
  {
    id: 'STEP_4',
    order: 4,
    title: 'Endereço de entrega',
    description: 'Qual é o endereço de entrega?',
  },
  {
    id: 'STEP_5',
    order: 5,
    title: 'Pagamento',
    description: 'Qual é a forma de pagamento?',
  },
  {
    id: 'STEP_6',
    order: 6,
    title: 'Finalizar',
    description: 'Confirmação do pedido',
  },
];

export { steps };
