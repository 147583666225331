import React from 'react';
import { IconButton } from '@material-ui/core';
import { Print } from '@material-ui/icons';

const CashRegisterExtractReportActions: React.FC = () => {
  return (
    <IconButton color="inherit" onClick={window.print}>
      <Print />
    </IconButton>
  );
};

export default CashRegisterExtractReportActions;
