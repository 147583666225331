import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { useInputDialog } from 'components/dialog/DialogInput';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    gap: 30,
  },
});

const BillingPixSuccess: React.FC = () => {
  const classes = useStyles();
  const { handleCloseDialog } = useInputDialog();

  return (
    <div className={classes.container}>
      <Typography>Recebemos seu pagamento, obrigado!</Typography>
      <Button onClick={handleCloseDialog} color="primary" variant="contained">
        Fechar
      </Button>
    </div>
  );
};

export default BillingPixSuccess;
