import React from 'react';
import { makeStyles } from '@material-ui/core';
import { DefaultTheme } from '@material-ui/styles';

type UseStylesProps = {
  backgroundColor: string;
};

const useStyles = makeStyles<DefaultTheme, UseStylesProps>({
  row: {
    height: 35,
    marginBottom: 7,
  },
  container: props => ({
    margin: '10px 0',
    backgroundColor: props.backgroundColor,
    flex: 1,
  }),
});

interface TableLoadingProps {
  backgroundColor?: string;
}

const TableLoading: React.FC<TableLoadingProps> = ({ backgroundColor = '#fff' }) => {
  const classes = useStyles({ backgroundColor });

  return (
    <div className={classes.container}>
      <div className={`animated-background ${classes.row}`} />
      <div className={`animated-background ${classes.row}`} />
      <div className={`animated-background ${classes.row}`} />
      <div className={`animated-background ${classes.row}`} />
    </div>
  );
};

export default TableLoading;
