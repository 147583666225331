import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
    fontWeight: 600,
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
  editButton: {
    position: 'absolute',
    right: 0,
  },
});

function CustomerStatus({ customer }) {
  const classes = useStyles();

  return (
    <>
      {customer.id && (
        <>
          <div className={classes.head}>
            <Typography className={classes.title}>Cliente</Typography>
          </div>
          <div className={classes.address}>
            <Typography>{customer.name}</Typography>
          </div>
        </>
      )}
    </>
  );
}

export default CustomerStatus;
