import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { moneyFormat, numberFormat } from 'helpers/NumberFormat';
import { getStatusText } from 'pages/orders/orderStatusMapping';
import { Customer } from 'types/customer';
import { Order } from 'types/order';
import { ComplementCategory, OrderProductAdditional } from 'types/product';
import { Shipment } from 'types/shipment';
import { v4 } from 'uuid';
import { addProduct } from './addProduct';

export function setOrder(order: Order): Order {
  const products = getProducts(order);
  const customer = getCustomer(order.customer as Customer);
  const shipment = getShipment(order.shipment as Shipment);

  return {
    ...order,
    products,
    shipment,
    customer,
    statusText: getStatusText(order.shipment?.shipment_method, order.status),
    formattedChange: moneyFormat(order.change),
    formattedId: ('000000' + order.id).slice(-6),
    formattedCreatedAt: format(parseISO(order.created_at), "PPPP 'às' p", {
      locale: ptBR,
    }),
  };
}

function getProducts(order: Order) {
  return order.products.map(product => {
    const _product = addProduct(order, product, product.amount);
    const additional = getProductAdditional(_product.additional);
    const complement_categories = getProductComplementCategories(_product.complement_categories);

    return {
      ...{ ..._product, complement_categories },
      uid: v4(),
      ready: true,
      additional,
    };
  });
}

function getProductAdditional(additional: OrderProductAdditional[]) {
  return additional.map(additional => ({
    ...additional,
    formattedPrice: moneyFormat(additional.price),
  }));
}

function getProductComplementCategories(categories: ComplementCategory[]) {
  return categories.map(category => ({
    ...category,
    complements: category.complements.map(complement => ({
      ...complement,
      formattedPrice: moneyFormat(complement.price),
      prices: complement.prices.map(item => ({
        ...item,
        formattedPrice: moneyFormat(item.price),
      })),
    })),
  }));
}

function getShipment(shipment: Shipment): Shipment {
  return {
    ...shipment,
    formattedScheduledAt: shipment.scheduled_at ? format(parseISO(shipment.scheduled_at as string), 'HH:mm') : '',
  };
}

function getCustomer(customer: Customer): Customer {
  return {
    ...customer,
    addresses: customer.addresses.map(address => ({
      ...address,
      formattedDistanceTax: moneyFormat(address.distance_tax),
      formattedDistance: numberFormat(address.distance) + ' km',
    })),
  };
}
