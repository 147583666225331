import { TableTemplate } from 'types/tableTemplate';

export const taxRulesTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 70,
  },
  {
    id: 'description',
    description: 'DESCRIÇÃO',
    originalId: 'description',
    width: 150,
  },
  {
    id: 'internal_cfop',
    description: 'CFOP INTERNA',
    originalId: 'internal_cfop',
    width: 150,
  },
  {
    id: 'external_cfop',
    description: 'CFOP EXTERNA',
    originalId: 'external_cfop',
    width: 150,
  },
  {
    id: 'pis_cofins_code',
    description: 'SITUAÇÃO PIS/COFINS',
    originalId: 'pis_cofins_code',
    width: 200,
  },
  {
    id: 'icms_code',
    description: 'SITUAÇÃO ICMS',
    originalId: 'icms_code',
    width: 150,
  },
  {
    id: 'formattedIcmsAliquot',
    description: '% ICMS',
    originalId: 'icms_aliquot',
    width: 150,
  },
  {
    id: 'formattedIcmsStAliquot',
    description: '% ICMS ST',
    originalId: 'icms_st_aliquot',
    width: 150,
  },
  {
    id: 'formattedPisAliquot',
    description: '% PIS',
    originalId: 'pis_aliquot',
    width: 150,
  },
  {
    id: 'formattedCofinsAliquot',
    description: '% COFINS',
    originalId: 'cofins_aliquot',
    width: 150,
  },
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 350,
  },
  {
    id: 'formattedCreatedAt',
    description: 'CRIADO EM',
    originalId: 'created_at',
    width: 350,
  },
];
