import { useEffect, useState } from 'react';
import { api, getCancelTokenSource } from 'services/api';
import { useOrderFormatter } from './useOrderFormatter';
import { OrderList } from 'pages/board-orders/types/orderList';

export function useFetchOrder(id?: string): [boolean, OrderList | null] {
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<OrderList | null>(null);
  const [, formatter] = useOrderFormatter();

  useEffect(() => {
    if (!id) {
      return;
    }

    let requesting = true;
    const source = getCancelTokenSource();

    api
      .get(`orders/${id}`, { cancelToken: source.token })
      .then(response => {
        if (!requesting) {
          return;
        }

        setOrder(formatter(response.data));
      })
      .catch(err => {
        if (requesting) {
          console.error(err);
        }
      })
      .finally(() => {
        if (requesting) {
          setLoading(false);
        }
        requesting = false;
      });

    return () => {
      if (requesting) source.cancel();
      requesting = false;
    };
  }, [id, formatter]);

  return [loading, order];
}
