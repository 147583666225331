import { CustomerAddress } from 'types/customerAddress';
import { Customer } from 'types/customer';
import { CustomerActions } from './types';

export function change(index: keyof Customer, value: any): CustomerActions {
  return {
    type: 'CUSTOMER_CHANGE',
    index,
    value,
  };
}

export function setInitialState(): CustomerActions {
  return {
    type: 'SET_CUSTOMER_INITIAL_STATE',
  };
}

export function setCustomer(customer: Customer): CustomerActions {
  return {
    type: 'SET_CUSTOMER',
    customer,
  };
}

export function addAddress(address: CustomerAddress): CustomerActions {
  return {
    type: 'ADD_CUSTOMER_ADDRESS',
    address,
  };
}

export function updateAddress(address: CustomerAddress): CustomerActions {
  return {
    type: 'UPDATE_CUSTOMER_ADDRESS',
    address,
  };
}

export function setMainCustomerAddress(addressId: number): CustomerActions {
  return {
    type: 'SET_MAIN_CUSTOMER_ADDRESS',
    addressId,
  };
}

export function deleteCustomerAddress(addressId: number): CustomerActions {
  return {
    type: 'DELETE_CUSTOMER_ADDRESS',
    addressId,
  };
}
