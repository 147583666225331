import React, { FC, Fragment } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import LoupeIcon from '@material-ui/icons/Loupe';
import PlayIcon from '@material-ui/icons/PlayCircleFilled';
import PauseIcon from '@material-ui/icons/PauseCircleFilled';
import { useProduct } from '../hooks/useProduct';

interface ProductActionProps {
  handleDelete(): void;
  handleStatus(): void;
}

const ProductAction: FC<ProductActionProps> = ({ handleDelete, handleStatus }: ProductActionProps) => {
  const { product, handleChange, setImagePreview } = useProduct();

  return (
    <>
      {product.photoSelected ? (
        <>
          <Tooltip title="Ver foto">
            <IconButton onClick={() => setImagePreview(true)} color="inherit">
              <LoupeIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Excluir photo">
            <IconButton onClick={() => handleChange(null, 'image')} color="inherit">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip title={product.activated ? 'Pausar vendas' : 'Ativar vendas'}>
            <span>
              <IconButton color="inherit" onClick={handleStatus}>
                {product.activated ? <PauseIcon /> : <PlayIcon />}
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Excluir produto">
            <div>
              <IconButton onClick={handleDelete} color="inherit">
                <DeleteIcon />
              </IconButton>
            </div>
          </Tooltip>
        </>
      )}
    </>
  );
};

export default ProductAction;
