import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { promotionDeleteCategory } from 'store/modules/promotion/actions';
import { usePromotion } from 'pages/promotion/hooks/usePromotion';

const useStyles = makeStyles(theme => ({
  itemDescription: {
    padding: 10,
    borderLeft: `2px solid ${theme.palette.secondary.dark}`,
    marginLeft: 13,
  },
  currentStep: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    width: 25,
    height: 25,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'inline-flex',
    fontSize: 10,
    border: `2px solid ${theme.palette.primary.dark}`,
  },
  stepOrder: {
    color: '#fff',
    backgroundColor: theme.palette.secondary.main,
    width: 25,
    height: 25,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'inline-flex',
    fontSize: 10,
    border: `2px solid ${theme.palette.secondary.dark}`,
  },
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    marginRight: 10,
    padding: 0,
  },
  step: {
    display: 'flex',
    alignItems: 'center',
  },
  links: {
    cursor: 'pointer',
  },
  actions: {
    display: 'flex',
    '&>span': {
      marginRight: 10,
      display: 'inline-flex',
    },
  },
  categoryContainer: {
    marginBottom: 6,
    display: 'flex',
    flexDirection: 'column',
  },
  categoryContent: {
    display: 'flex',
    '& p': {
      marginRight: 10,
    },
  },
}));

PromotionResumeCategories.propTypes = {
  promotion: PropTypes.object.isRequired,
  currentStep: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired,
};

export default function PromotionResumeCategories({ promotion, currentStep, steps }) {
  const step = steps.find(step => step.id === 'STEP_2');
  const classes = useStyles();
  const { dispatch } = usePromotion();

  function handleDeleteCategory(categoryId) {
    dispatch(promotionDeleteCategory(categoryId));
  }

  return (
    <>
      <div className={classes.step}>
        <button className={classes.button}>
          <span className={currentStep.id === 'STEP_2' ? classes.currentStep : classes.stepOrder}>{step.order}</span>
        </button>
        <Typography color={'primary'} variant={'body1'}>
          Categorias
        </Typography>
      </div>
      <div className={classes.itemDescription}>
        <Typography>
          {promotion.categories.length > 0 ? (
            <>
              {promotion.categories.map(category => (
                <div key={category.id} className={classes.categoryContainer}>
                  <div className={classes.categoryContent}>
                    <Typography>{category.name}</Typography>
                    <Typography>{category.formattedValue}</Typography>
                  </div>
                  <div className={classes.actions}>
                    <Typography
                      variant="body2"
                      className={classes.links}
                      component="span"
                      onClick={() => handleDeleteCategory(category.id)}
                    >
                      Excluir
                    </Typography>
                  </div>
                </div>
              ))}
            </>
          ) : (
            currentStep.id === 'STEP_2' && (
              <Typography variant="body2" component="span">
                aguardando...
              </Typography>
            )
          )}
        </Typography>
      </div>
    </>
  );
}
