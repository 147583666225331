import { DisableSequencePayload } from 'pages/fiscal-serials/types/disable-sequence-payload';
import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';

export interface DisableSequenceValidation {
  from?: string;
  to?: string;
  justification?: string;
}

type UseDisableSequenceValidation = [
  DisableSequenceValidation,
  Dispatch<SetStateAction<DisableSequenceValidation>>,
  (payload: DisableSequencePayload) => Promise<void>
];

export function useDisableSequenceValidation(): UseDisableSequenceValidation {
  const [validation, setValidation] = useState<DisableSequenceValidation>({} as DisableSequenceValidation);

  async function handleValidation(payload: DisableSequencePayload) {
    const schema = yup.object().shape({
      justification: yup.string().required('justificativa é obrigatório'),
      to: yup.string().required('número final é obrigatóario'),
      from: yup.string().required('número inicial é obrigatório'),
    });

    try {
      await schema.validate(payload);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  return [validation, setValidation, handleValidation];
}
