import React, { useEffect, useState, useContext } from 'react';
import { mobileCheck } from 'helpers/MobileCheck';
import { api } from 'services/api';
import { getFirebaseMessaging } from 'config/FirebaseConfig';
import MessagingProvider from 'hooks/messaging';
import { useSelector } from 'store/redux/selector';
import InvoiceError from 'components/invoice-error/InvoiceError';
import LocationProvider from 'providers/location';
import AppRoutes from 'routes/Routes';
import { useAppSocket } from 'hooks/useAppSocket';
import { Socket } from 'socket.io-client';
import ErrorHandlerProvider from 'providers/error-handler/error-handler';
import { useFetchCurrentUser } from 'hooks/useFetchCurrentUser';
import { usePrinterConnectionStatus } from 'hooks/usePrinterConnectionStatus';

interface AppContextData {
  isOpenedMenu: boolean;
  isMobile: boolean;
  loading: boolean;
  windowWidth: number;
  wsConnected: boolean;
  socket: Socket;
  handleOpenMenu(): void;
  handleRestaurantIsOpen(): Promise<void>;
  handleKitchenState(): Promise<void>;
  isPrinterConnected: boolean;
  printerVersion: string;
}

const isNotificationSupported = () =>
  'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;

export const menuWidth = 266;

export const AppContext = React.createContext<AppContextData>({} as AppContextData);

const App: React.FC = () => {
  const restaurant = useSelector(state => state.restaurant);
  const user = useSelector(state => state.user);
  const [isMobile, setIsMobile] = useState(mobileCheck());
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isOpenedMenu, setIsOpenedMenu] = useState(!mobileCheck() && windowWidth > 1280);
  const [invoiceError, setInvoiceError] = useState(false);
  const [socket, wsConnected] = useAppSocket();
  const [loading] = useFetchCurrentUser();
  const { isConnected: isPrinterConnected, version: printerVersion } = usePrinterConnectionStatus(socket);

  const appContextValue: AppContextData = {
    isOpenedMenu,
    isMobile: isMobile || windowWidth < 1280,
    loading,
    socket,
    wsConnected,
    windowWidth,
    handleOpenMenu,
    handleRestaurantIsOpen,
    handleKitchenState,
    isPrinterConnected,
    printerVersion,
  };

  useEffect(() => {
    document.body.classList.add('zoom-animation');
    window.addEventListener('resize', handleResize);
  }, [user]);

  useEffect(() => {
    if (!user.id) {
      return;
    }

    if (!isNotificationSupported()) {
      return;
    }

    Notification.requestPermission()
      .then(async () => {
        const firebaseMessaging = getFirebaseMessaging();
        const token = await firebaseMessaging.getToken();

        const param = {
          push_notification_token: token,
          device: navigator.platform,
          type: 'admin',
        };

        api.post('/pushTokens', param).catch(error => console.error(error));
      })
      .catch(error => console.error(error));
  }, [user]);

  function handleOpenMenu() {
    setIsOpenedMenu(!isOpenedMenu);
  }

  function handleResize() {
    setIsMobile(mobileCheck());
    setWindowWidth(window.innerWidth);
  }

  async function handleRestaurantIsOpen() {
    if (!restaurant) {
      return;
    }

    const data = {
      state: !restaurant.is_open,
    };

    try {
      await api.patch(`/restaurants/${restaurant.id}/state`, data);
    } catch (err) {
      const error = err as any;

      if (error?.response?.status === 400) {
        setInvoiceError(true);
        throw new Error('Existem faturas em aberto');
      }
      throw new Error('Não foi possível alterar o status');
    }
  }

  async function handleKitchenState() {
    if (!restaurant) {
      return;
    }

    const data = {
      state: !restaurant.is_kitchen_open,
    };

    try {
      await api.patch(`/restaurants/${restaurant.id}/kitchen-state`, data);
    } catch (err) {
      const error = err as any;

      if (error?.response?.status === 400) {
        setInvoiceError(true);
        throw new Error('Existem faturas em aberto');
      }
      throw new Error('Não foi possível alterar o status');
    }
  }

  return (
    <AppContext.Provider value={appContextValue}>
      {invoiceError && <InvoiceError onExited={() => setInvoiceError(false)} />}
      <LocationProvider>
        <MessagingProvider>
          <ErrorHandlerProvider>
            <AppRoutes />
          </ErrorHandlerProvider>
        </MessagingProvider>
      </LocationProvider>
    </AppContext.Provider>
  );
};

export function useApp(): AppContextData {
  return useContext(AppContext);
}

export const AppContextConsumer = AppContext.Consumer;

export default App;
