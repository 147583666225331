import React from 'react';
import List from 'components/list/List';
import { usePagination } from 'hooks/pagination';
import PromotionItemModule from './PromotionItemModule';
import { Promotion } from 'types/promotion';

type PromotionListModuleProps = {
  promotions: Promotion[];
};

const PromotionListModule: React.FC<PromotionListModuleProps> = ({ promotions }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <List>
      {promotions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(promotion => (
        <PromotionItemModule key={promotion.id} promotion={promotion} />
      ))}
    </List>
  );
};

export default PromotionListModule;
