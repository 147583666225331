import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useCashRegisters } from '../hooks/useCashRegisters';

type CashRegisterListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl(anchorEl: null | HTMLButtonElement): void;
};

const CashRegisterListMenu: React.FC<CashRegisterListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { openInitialValueDialog } = useCashRegisters();

  function handleIntialValueClick() {
    setAnchorEl(null);
    openInitialValueDialog();
  }

  return (
    <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
      <MenuItem onClick={handleIntialValueClick}>Saldo inicial</MenuItem>
    </Menu>
  );
};

export default CashRegisterListMenu;
