import React, { FC, useState } from 'react';
import DialogInput, { DialogInputConsumer } from 'components/dialog/DialogInput';
import { TextField, MenuItem, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useOrders } from './hook/useOrders';
import { Deliverer } from 'types/deliverer';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    minHeight: 350,
    justifyContent: 'space-around',
    flexDirection: 'column',
    flex: 1,
    '& > .title': {
      textAlign: 'center',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
});

interface OrderDelivererProps {
  onExited(): void;
  deliverers: Deliverer[];
  hideBackdrop?: boolean;
}

const OrderDeliverer: FC<OrderDelivererProps> = ({ onExited, deliverers, hideBackdrop = false }) => {
  const classes = useStyles();
  const { handleOrderStatus } = useOrders();
  const [delivererId, setDelivererId] = useState<number | null>(
    () => deliverers.find(deliverer => !!deliverer.is_main)?.id ?? null
  );

  function handleConfirm(handleCloseDialog: () => void) {
    const deliverer = deliverers.find(deliverer => deliverer.id === delivererId);
    handleOrderStatus(undefined, deliverer?.id);
    handleCloseDialog();
  }

  return (
    <DialogInput onExited={onExited} maxWidth="sm" hideBackdrop={hideBackdrop}>
      <DialogInputConsumer>
        {context => (
          <div className={classes.container}>
            <Typography className="title" variant="h6">
              Entregador
            </Typography>
            <TextField
              select
              label="Entregador"
              fullWidth
              value={delivererId}
              onChange={e => setDelivererId(parseInt(e.target.value))}
              margin="normal"
            >
              {deliverers.map(deliverer => (
                <MenuItem value={deliverer.id} key={deliverer.id}>
                  {deliverer.name}
                </MenuItem>
              ))}
            </TextField>
            <div className={classes.actions}>
              <Button variant="text" color="primary" onClick={context.handleCloseDialog}>
                Cancelar
              </Button>
              <Button color="primary" onClick={() => handleConfirm(context.handleCloseDialog)} variant="contained">
                Confirmar
              </Button>
            </div>
          </div>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default OrderDeliverer;
