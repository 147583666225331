import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

interface NewAdditionalActionsProps {
  loading: boolean;
  saving: boolean;
  handleSubmit(): void;
}

const NewAdditionalActions: React.FC<NewAdditionalActionsProps> = ({ loading, saving, handleSubmit }) => {
  return (
    <>
      <Tooltip title={'Salvar'}>
        <span>
          <IconButton disabled={loading || saving} onClick={handleSubmit} color="inherit">
            <DoneIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};

export default NewAdditionalActions;
