import { PushNotification } from 'types/pushNotification';
import { PushNotificationActions, PUSH_CHANGE, SET_PUSH } from './types';

export const INITIAL_STATE: PushNotification = {
  title: '',
  message: '',
  url_action: '',
  image: null,
  isImageSelected: false,
  delivery_date: null,
  delivered: false,
  created_at: '',
  formattedCreatedAt: '',
  formattedDeliveryDate: '',
  id: 0,
  formattedDelivered: '',
};

export default function pushNotification(
  state: PushNotification = INITIAL_STATE,
  action: PushNotificationActions
): PushNotification {
  switch (action.type) {
    case PUSH_CHANGE: {
      return {
        ...state,
        [action.index]: action.value,
      };
    }
    case SET_PUSH: {
      return {
        ...state,
        ...action.pushNotification,
      };
    }
    default: {
      return state;
    }
  }
}
