import React, { Dispatch, FC, SetStateAction } from 'react';
import { ListItem, Typography, makeStyles } from '@material-ui/core';
import BoardProductItemComplements from './OrderResumeProductComplements';
import { useOrder } from '../../hooks/useOrder';
import { OrderProduct } from 'types/order';

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0 7px',
    alignItems: 'flex-start',
    rowGap: 7,
    [theme.breakpoints.down('md')]: {
      borderBottom: '1px solid #eee',
      borderTop: 'none',
    },
  },
  product: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    '&>div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  price: {
    fontWeight: 300,
  },
  link: {
    cursor: 'pointer',
  },
  linkDelete: {
    color: 'red',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    '& p': {
      marginRight: 15,
    },
    width: '100%',
  },
  productImage: {
    width: '100%',
  },
  additional: {
    color: '#4CAF50',
  },
  ingredients: {
    color: '#c53328',
  },
  options: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 7,
  },
  productName: {
    fontWeight: 300,
  },
  imageContainer: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    overflow: 'hidden',
    display: 'flex',
    backgroundColor: '#eee',
    flexShrink: 0,
  },
}));

interface BoardProductItemProps {
  product: OrderProduct;
  setSelectedProduct: Dispatch<SetStateAction<OrderProduct | null>>;
}

const BoardProductItem: FC<BoardProductItemProps> = ({ product, setSelectedProduct }) => {
  const classes = useStyles();
  const { handleRemoveProduct } = useOrder();

  function handleDeleteClick() {
    handleRemoveProduct(product.uid);
  }

  function handleDetailClick() {
    setSelectedProduct(product);
  }

  function showAdditionalOrIngredients(product: OrderProduct) {
    const showIngredients = product.ingredients.some(ingredient => !ingredient.selected);
    const showAdditional = product.additional.some(additional => additional.selected);
    return showAdditional || showIngredients;
  }

  return (
    <ListItem disableGutters key={product.id} className={classes.listItem}>
      <div className={classes.product} id="product-data">
        <div>
          <div className={classes.imageContainer}>
            {product.image && (
              <img
                src={product.image.imageThumbUrl ? product.image.imageThumbUrl : product.image.imageUrl}
                alt={product.name}
                className={classes.productImage}
              />
            )}
          </div>

          <div>
            <Typography variant="body1" className={classes.productName}>
              {product.amount}x {product.name}
            </Typography>
          </div>
        </div>

        <Typography variant="h6" className={classes.price}>
          {product.formattedFinalPrice}
        </Typography>
      </div>

      {product.category.has_complement && <BoardProductItemComplements categories={product.complement_categories} />}

      {showAdditionalOrIngredients(product) && (
        <div className={classes.options}>
          {product.ingredients
            .filter(ingredient => !ingredient.selected)
            .map(ingredient => (
              <Typography key={ingredient.id} variant="body2" display="inline" className={classes.ingredients}>
                s/ {ingredient.name}
              </Typography>
            ))}

          {product.additional
            .filter(additional => additional.selected)
            .map(additional => (
              <Typography key={additional.id} variant="body2" display="inline" className={classes.additional}>
                c/ {additional.amount}x {additional.name}
              </Typography>
            ))}
        </div>
      )}

      <div className={classes.actions}>
        <Typography color="inherit" className={classes.link} onClick={handleDetailClick}>
          editar
        </Typography>
        <Typography color="initial" className={`${classes.link} ${classes.linkDelete}`} onClick={handleDeleteClick}>
          excluir
        </Typography>
      </div>
    </ListItem>
  );
};

export default BoardProductItem;
