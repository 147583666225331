import { TableTemplate } from 'types/tableTemplate';

export const pushNotificationTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'title',
    description: 'TÍTULO',
    originalId: 'title',
    width: 300,
  },
  {
    id: 'formattedDelivered',
    description: 'ENVIADO',
    originalId: 'formattedDelivered',
    width: 100,
  },
  {
    id: 'formattedDeliveryDate',
    description: 'PROGRAMADO PARA',
    originalId: 'formattedDeliveryDate',
    width: 300,
  },
  {
    id: 'formattedCreatedAt',
    description: 'CRIADO EM',
    originalId: 'formattedCreatedAt',
    width: 200,
  },
];
