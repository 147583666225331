import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomAppbar from 'components/appbar/Appbar';
import CustomersActions from './CustomersActions';
import { Button, IconButton, InputAdornment, TextField } from '@material-ui/core';
import NoData from 'components/nodata/NoData';
import history from 'services/history';
import { api, getCancelTokenSource } from 'services/api';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import FilterBox from 'components/filter-box/FilterBox';
import { Close, Search } from '@material-ui/icons';
import useTableOrder from 'hooks/tableOrder';
import { useApp } from 'App';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import TableLoading from 'components/loading/TableLoading';
import PaginationProvider from 'hooks/pagination';
import CategoryListTable from './list/table/CustomerListTable';
import CategoryListModule from './list/module/CustomerListModule';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ModuleLoading from 'components/loading/ModuleLoading';
import { Customer } from 'types/customer';
import { CustomersProvider } from './hooks/useCustomers';
import ApiPagination from 'components/_pagination/ApiPagination';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  filter: {
    display: 'grid',
    gridTemplateColumns: '300px',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

let timer: NodeJS.Timeout;

const Customers: React.FC = () => {
  const classes = useStyles();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [dialogAddCategory, setDialogAddCategory] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<Customer[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const app = useApp();
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(20);
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const [total, setTotal] = useState(0);

  const fetchCustomers = useCallback(
    (query: string) => {
      setLoading(true);

      api
        .get('/customers', { params: { page: page + 1, rows, q: query } })
        .then(response => {
          setTotal(response.data.total);

          setCustomers(
            response.data.data.map(customer => {
              const date = parseISO(customer.created_at);
              customer.formattedCreatedAt = format(date, 'PPp', { locale: ptBR });
              return customer;
            })
          );
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [rows, page]
  );

  useEffect(() => {
    let request = true;

    const source = getCancelTokenSource();

    fetchCustomers('');

    return () => {
      if (request) source.cancel();
      request = false;
    };
  }, [fetchCustomers]);

  useEffect(() => setFiltered(customers), [customers]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleSearch(value: string) {
    setSearchValue(value);
    clearTimeout(timer);

    if (value.length > 0 && value.length <= 3) {
      return;
    }

    timer = setTimeout(() => fetchCustomers(value), 500);
  }

  return (
    <CustomersProvider value={{ customers, setSelectedCustomer, selectedCustomer }}>
      <CustomAppbar
        title="Clientes"
        ActionComponents={<CustomersActions setDialogAddCategory={() => setDialogAddCategory(!dialogAddCategory)} />}
      />

      <PageHeaderActions
        title="Clientes"
        description="Gestão dos clientes"
        ActionComponent={
          <Button color="primary" variant="contained" onClick={() => history.push('/customers/new')} size="small">
            Adicionar
          </Button>
        }
      />

      <FilterBox>
        <div className={classes.filter}>
          <TextField
            inputRef={inputSearchRef}
            label="Pesquisar"
            placeholder="Nome ou telefone do cliente"
            value={searchValue}
            onChange={e => handleSearch(e.target.value)}
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: searchValue && (
                <InputAdornment position="start">
                  <IconButton size="small" onClick={() => handleSearch('')}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <DisplayModeButtons
          displayMode={displayMode}
          handleShowList={() => setDisplayMode('list')}
          handleShowModule={() => setDisplayMode('module')}
        />
      </FilterBox>
      <PaginationProvider>
        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhuma categoria para mostrar" />
        ) : (
          <div className={classes.container}>
            {displayMode === 'list' ? (
              <CategoryListTable customers={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
            ) : (
              displayMode === 'module' && <CategoryListModule customers={filtered} />
            )}
            <ApiPagination
              onChangePage={value => {
                setPage(value);
                console.log(value);
              }}
              onChangeRowsPerPage={value => setRows(value)}
              count={total}
            />
          </div>
        )}
      </PaginationProvider>
    </CustomersProvider>
  );
};

export default Customers;
