import { Button, List, ListItem, makeStyles, Typography } from '@material-ui/core';
import CustomDialog, { CustomDialogContext } from 'components/dialog/CustomDialog';
import InsideSaving from 'components/loading/InsideSaving';
import { useMessaging } from 'hooks/messaging';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { api } from 'services/api';
import { updateRestaurantPlan } from 'store/redux/modules/restaurant/actions';
import { useSelector } from 'store/redux/selector';
import { Plan } from 'types/plan';
import { useBilling } from '../hooks/useBilling';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flex: 1,
    marginBottom: 25,
  },
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 200px))',
    gap: '10px',
    justifyContent: 'center',
  },
  listItem: {
    display: 'flex',
    height: 300,
    minWidth: 200,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    border: '1px solid #eee',
    transition: 'border 0.4s ease',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  selectedListItem: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  actions: {
    display: 'flex',
    marginTop: 30,
    justifyContent: 'space-evenly',
  },
}));

type BillingPlanEditProps = {
  onExited(): void;
};

const BillingPlanEdit: React.FC<BillingPlanEditProps> = ({ onExited }) => {
  const classes = useStyles();
  const { plans } = useBilling();
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const messaging = useMessaging();
  const restaurant = useSelector(state => state.restaurant);

  function handleSelectPlan(plan: Plan) {
    setSelectedPlan(plan);
  }

  function handleConfirm(handleCloseDialog: () => void) {
    setSaving(true);

    api
      .post('/restaurants/plans/update', { plan_id: selectedPlan?.id })
      .then(response => {
        dispatch(updateRestaurantPlan(response.data));
        handleCloseDialog();
      })
      .catch(err => {
        messaging.handleOpen(err.response ? err.response.data.error : 'Não foi possível atualizar o plano');
      })
      .finally(() => setSaving(false));
  }

  return (
    <CustomDialog handleModalState={onExited} height="60vh" displayBottomActions={false}>
      {saving && <InsideSaving />}
      <div className={classes.container}>
        <List className={classes.list}>
          {plans.map(plan => (
            <ListItem
              className={
                selectedPlan?.id === plan.id ? `${classes.listItem} ${classes.selectedListItem}` : classes.listItem
              }
              onClick={() => handleSelectPlan(plan)}
              key={plan.id}
            >
              <Typography>{plan.name}</Typography>
              <Typography variant="caption" align="center" color="textSecondary">
                {plan.description}
              </Typography>
              <Typography>{plan.formattedPrice}</Typography>
              {restaurant?.plan.id === plan.id && <Typography variant="subtitle2">Plano atual</Typography>}
            </ListItem>
          ))}
        </List>
        <div>
          <Typography variant="body2" align="center">
            Atenção! Somente as próximas faturas considerarão o preço do plano escolhido.
          </Typography>
        </div>
        <CustomDialogContext.Consumer>
          {({ handleCloseDialog }) => (
            <div className={classes.actions}>
              <Button onClick={handleCloseDialog} variant="text" color="primary">
                Cancelar
              </Button>
              <Button
                disabled={!selectedPlan || restaurant?.plan.id === selectedPlan.id || saving}
                onClick={() => handleConfirm(handleCloseDialog)}
                variant="contained"
                color="primary"
              >
                Confirmar
              </Button>
            </div>
          )}
        </CustomDialogContext.Consumer>
      </div>
    </CustomDialog>
  );
};

export default BillingPlanEdit;
