export function couponChange(index, value) {
  return {
    type: 'CHANGE',
    index,
    value,
  };
}

export function setCoupon(coupon) {
  return {
    type: 'SET_COUPON',
    coupon,
  };
}
