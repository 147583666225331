import { makeStyles, Typography } from '@material-ui/core';
import { useMessaging } from 'hooks/messaging';
import React, { MouseEvent } from 'react';
import { api } from 'services/api';
import { Complement } from 'types/product';
import { useProductManager } from './hooks/useProductManager';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
  },
  content: {},
  complement: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
  },
});

interface ProductManagerItemComplementProps {
  complements: Complement[];
  categoryId?: number;
  productId: number;
}

const ProductManagerItemComplement: React.FC<ProductManagerItemComplementProps> = ({
  complements,
  categoryId,
  productId,
}) => {
  const classes = useStyles();
  const messaging = useMessaging();
  const { setProducts } = useProductManager();

  function handleComplementStatus(e: MouseEvent<HTMLAnchorElement>, complementId?: number) {
    e.preventDefault();

    api
      .put(`/products/complements/status/${complementId}`)
      .then(() => {
        setProducts(products =>
          products.map(product => {
            if (product.id === productId) {
              product.complement_categories = product.complement_categories.map(category => {
                if (category.id === categoryId) {
                  category.complements = category.complements.map(complement => {
                    complement.is_activated =
                      complement.id === complementId ? !complement.is_activated : complement.is_activated;
                    return complement;
                  });
                }
                return category;
              });
            }
            return product;
          })
        );
      })
      .catch(err => {
        console.error(err);
        messaging.handleOpen('Aconteceu um erro ao atualizar o complemento');
      });
  }

  return (
    <div className={classes.container}>
      {complements.map(complement => (
        <div key={complement.id} className={classes.content}>
          <div className={classes.complement}>
            <Typography>{complement.name}</Typography>
            <a href="/" onClick={e => handleComplementStatus(e, complement.id)}>
              {complement.is_activated ? 'pausar' : 'ativar'}
            </a>
          </div>
          {complement.description && (
            <Typography variant="caption" color="textSecondary">
              {complement.description}
            </Typography>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProductManagerItemComplement;
