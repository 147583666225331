import React, { useEffect, useState } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import { Button, makeStyles } from '@material-ui/core';
import IngredientsActions from 'pages/ingredients/IngredientsActions';
import NoData from 'components/nodata/NoData';
import history from 'services/history';
import ApiPagination from 'components/_pagination/ApiPagination';
import PaginationProvider from 'hooks/pagination';
import { useFetchIngredients } from './hooks/useFetchIngredients';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import { Ingredient } from 'types/product';
import IngredientListModule from './list/module/IngredientListModule';
import IngredientListTable from './list/table/IngredientListTable';
import useTableOrder from 'hooks/tableOrder';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import IngredientsFilterBox from './IngredientsFilterBox';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

let timer: NodeJS.Timeout;

const Ingredients: React.FC = () => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(50);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<Ingredient[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const [loading, ingredients, total, fetch] = useFetchIngredients();

  useEffect(() => {
    setFiltered(ingredients);
  }, [ingredients]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleSearch(value: string) {
    setSearchValue(value);
    clearTimeout(timer);

    if (value.length > 0 && value.length <= 3) {
      return;
    }

    timer = setTimeout(() => fetch(value, page, rows), 500);
  }

  function handleChangeRows(value: number) {
    setRows(value);
    fetch(searchValue, page, value);
  }

  function handleChangePage(value: number) {
    setPage(value);
    fetch(searchValue, value, rows);
  }

  return (
    <>
      <CustomAppbar ActionComponents={<IngredientsActions />} title="Ingredientes" />
      <div className={classes.container}>
        <PageHeaderActions
          title="Ingredientes"
          description="Se incluídos no produto podem informar o cliente dos ingredientes de um produto, além de possibilitar a personalização de um pedido"
          ActionComponent={
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => history.push('/menu/ingredients/new')}
            >
              adicionar
            </Button>
          }
        />

        <IngredientsFilterBox
          displayMode={displayMode}
          setDisplayMode={setDisplayMode}
          handleSearch={handleSearch}
          searchValue={searchValue}
        />

        <PaginationProvider>
          {loading ? (
            displayMode === 'list' ? (
              <TableLoading />
            ) : (
              <ModuleLoading />
            )
          ) : !ingredients.length ? (
            <NoData
              message="Não há ingredientes cadastrados."
              buttonText="Cadastrar ingrediente"
              action={() => {
                history.push('/menu/ingredients/new');
              }}
            />
          ) : (
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <IngredientListTable ingredients={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <IngredientListModule ingredients={filtered} />
              )}
              <ApiPagination
                onChangePage={value => handleChangePage(value)}
                onChangeRowsPerPage={value => handleChangeRows(value)}
                count={total}
              />
            </div>
          )}
        </PaginationProvider>
      </div>
    </>
  );
};

export default Ingredients;
