import React, { useEffect, useRef } from 'react';
import { FormControlLabel, MenuItem, Switch, TextField, makeStyles } from '@material-ui/core';
import { FiscalSetting, FiscalSettingSat } from '../types/fiscal-setting';
import { Printer } from 'types/printer';
import { FiscalSettingsValidation } from '../validation/useFiscalSettingsValidation';

const styles = makeStyles({
  container: {
    marginBottom: 200,
  },
  row: {
    margin: '10px 0',
    '& > div': {
      display: 'flex',
      gap: 10,
      padding: 15,
    },
  },
});

interface SatSettingsFormProps {
  settings: FiscalSetting;
  handleChange(index: keyof FiscalSettingSat, value: any): void;
  printers: Printer[];
  validation: FiscalSettingsValidation;
}

const SatSettingsForm: React.FC<SatSettingsFormProps> = ({ settings, handleChange, printers, validation }) => {
  const classes = styles();

  const inputs = {
    'sat.software_house_token': useRef<HTMLInputElement>(null),
    'sat.activation_code': useRef<HTMLInputElement>(null),
    'sat.sat_version': useRef<HTMLInputElement>(null),
    'sat.dll_path': useRef<HTMLInputElement>(null),
    'sat.printer_name': useRef<HTMLInputElement>(null),
    'sat.escpos_type': useRef<HTMLInputElement>(null),
    'sat.escpos_port': useRef<HTMLInputElement>(null),
    'sat.escpos_columns': useRef<HTMLInputElement>(null),
    'sat.escpos_line_height': useRef<HTMLInputElement>(null),
    'sat.escpos_lines': useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) {
      return;
    }

    if (!inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <div className={classes.container}>
      <FormControlLabel
        control={
          <Switch
            checked={settings.sat.active}
            onChange={e => handleChange('active', e.target.checked)}
            color="primary"
          />
        }
        label="ativo"
      />
      <TextField
        label="Token"
        placeholder="Token"
        margin="normal"
        value={settings.sat.software_house_token}
        fullWidth
        onChange={event => handleChange('software_house_token', event.target.value)}
        multiline
        minRows={5}
        autoFocus
        inputRef={inputs['sat.software_house_token']}
        error={!!validation['sat.software_house_token']}
        helperText={validation['sat.software_house_token']}
      />

      <TextField
        label="Código de ativação"
        placeholder="Código de ativação"
        margin="normal"
        value={settings.sat.activation_code}
        fullWidth
        onChange={event => handleChange('activation_code', event.target.value)}
        inputRef={inputs['sat.activation_code']}
        error={!!validation['sat.activation_code']}
        helperText={validation['sat.activation_code']}
      />

      <TextField
        label="Versão do SAT"
        placeholder="Versão do SAT"
        margin="normal"
        value={settings.sat.sat_version}
        fullWidth
        onChange={event => handleChange('sat_version', event.target.value)}
        inputRef={inputs['sat.sat_version']}
        error={!!validation['sat.sat_version']}
        helperText={validation['sat.sat_version']}
      />

      <TextField
        label="Caminho da DLL"
        placeholder="Caminho da DLL"
        margin="normal"
        value={settings.sat.dll_path}
        fullWidth
        onChange={event => handleChange('dll_path', event.target.value)}
        inputRef={inputs['sat.dll_path']}
        error={!!validation['sat.dll_path']}
        helperText={validation['sat.dll_path']}
      />

      <TextField
        label="Ambiente"
        placeholder="Ambiente"
        margin="normal"
        value={settings.sat.environment}
        fullWidth
        onChange={event => handleChange('environment', event.target.value)}
        select
      >
        <MenuItem value={0}>produção</MenuItem>
        <MenuItem value={1}>homologação</MenuItem>
      </TextField>

      <TextField
        label="Tipo da DLL"
        placeholder="Tipo da DLL"
        margin="normal"
        value={settings.sat.dll_type}
        fullWidth
        onChange={event => handleChange('dll_type', event.target.value)}
        select
      >
        <MenuItem value={0}>padrão</MenuItem>
        <MenuItem value={1}>cdecl</MenuItem>
        <MenuItem value={2}>stdcall</MenuItem>
        <MenuItem value={3}>mfe_integrador_xml</MenuItem>
      </TextField>

      <TextField
        label="Tamanho do papel"
        placeholder="Tamanho do papel"
        margin="normal"
        value={settings.sat.paper_width}
        fullWidth
        onChange={event => handleChange('paper_width', event.target.value)}
        select
      >
        <MenuItem value={400}>80mm</MenuItem>
        <MenuItem value={300}>54mm</MenuItem>
      </TextField>

      <TextField
        label="Margem superior"
        placeholder="Margem superior"
        margin="normal"
        value={settings.sat.paper_margin_top}
        fullWidth
        onChange={event => handleChange('paper_margin_top', event.target.value)}
        select
      >
        <MenuItem value={0}>0</MenuItem>
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
      </TextField>

      <TextField
        label="Margem direita"
        placeholder="Margem direita"
        margin="normal"
        value={settings.sat.paper_margin_right}
        fullWidth
        onChange={event => handleChange('paper_margin_right', event.target.value)}
        select
      >
        <MenuItem value={0}>0</MenuItem>
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
      </TextField>

      <TextField
        label="Margem inferior"
        placeholder="Margem inferior"
        margin="normal"
        value={settings.sat.paper_margin_bottom}
        fullWidth
        onChange={event => handleChange('paper_margin_bottom', event.target.value)}
        select
      >
        <MenuItem value={0}>0</MenuItem>
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
      </TextField>

      <TextField
        label="Margem esquerda"
        placeholder="Margem esquerda"
        margin="normal"
        value={settings.sat.paper_margin_left}
        fullWidth
        onChange={event => handleChange('paper_margin_left', event.target.value)}
        select
      >
        <MenuItem value={0}>0</MenuItem>
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
      </TextField>

      <TextField
        label="Imprimir em uma linha"
        placeholder="Imprimir em uma linha"
        margin="normal"
        value={settings.sat.print_in_one_line}
        fullWidth
        onChange={event => handleChange('print_in_one_line', event.target.value)}
        select
      >
        <MenuItem value={1}>Sim</MenuItem>
        <MenuItem value={0}>Não</MenuItem>
      </TextField>

      <TextField
        label="Imprimir código QR"
        placeholder="Imprimir código QR"
        margin="normal"
        value={settings.sat.print_qrcode}
        fullWidth
        onChange={event => handleChange('print_qrcode', event.target.value)}
        select
      >
        <MenuItem value={1}>Sim</MenuItem>
        <MenuItem value={0}>Não</MenuItem>
      </TextField>

      <TextField
        label="Imprimir logo"
        placeholder="Imprimir logo"
        margin="normal"
        value={settings.sat.print_logo}
        fullWidth
        onChange={event => handleChange('print_logo', event.target.value)}
        select
      >
        <MenuItem value={1}>Sim</MenuItem>
        <MenuItem value={0}>Não</MenuItem>
      </TextField>

      <TextField
        label="Imprimir descrição"
        placeholder="Imprimir descrição"
        margin="normal"
        value={settings.sat.print_description}
        fullWidth
        onChange={event => handleChange('print_description', event.target.value)}
        select
      >
        <MenuItem value={1}>Sim</MenuItem>
        <MenuItem value={0}>Não</MenuItem>
      </TextField>

      <TextField
        label="Imprimir na autorização"
        placeholder="Imprimir autorização"
        margin="normal"
        value={settings.sat.print_on_authorization}
        fullWidth
        onChange={event => handleChange('print_on_authorization', event.target.value)}
        select
      >
        <MenuItem value={1}>Sim</MenuItem>
        <MenuItem value={0}>Não</MenuItem>
      </TextField>

      <TextField
        label="Impressora"
        placeholder="Impressora"
        margin="normal"
        value={settings.sat.printer_name}
        fullWidth
        onChange={event => handleChange('printer_name', event.target.value)}
        select
        inputRef={inputs['sat.printer_name']}
        error={!!validation['sat.printer_name']}
        helperText={validation['sat.printer_name']}
      >
        {printers.map(printer => (
          <MenuItem value={printer.name} key={printer.id}>
            {printer.name}
          </MenuItem>
        ))}
        <MenuItem value="ESCPOS">ESCPOS</MenuItem>
      </TextField>
      {settings.sat.printer_name === 'ESCPOS' && (
        <div>
          <TextField
            label="ESCPOS - Modelo"
            margin="normal"
            value={settings.sat.escpos_type}
            fullWidth
            onChange={event => handleChange('escpos_type', event.target.value)}
            select
            inputRef={inputs['sat.escpos_type']}
            error={!!validation['sat.escpos_type']}
            helperText={validation['sat.escpos_type']}
          >
            <MenuItem value={0}>Texto</MenuItem>
            <MenuItem value={1}>PosEpson</MenuItem>
            <MenuItem value={2}>Bematech</MenuItem>
            <MenuItem value={3}>Daruma</MenuItem>
            <MenuItem value={4}>Vox</MenuItem>
            <MenuItem value={5}>Diebold</MenuItem>
            <MenuItem value={6}>EpsonP2</MenuItem>
            <MenuItem value={7}>CustomPos</MenuItem>
            <MenuItem value={8}>PosStar</MenuItem>
            <MenuItem value={9}>ZJiang</MenuItem>
            <MenuItem value={10}>GPrinter</MenuItem>
            <MenuItem value={11}>Datecs</MenuItem>
            <MenuItem value={12}>Sunmi</MenuItem>
            <MenuItem value={13}>Externo</MenuItem>
          </TextField>

          <TextField
            label="ESCPOS - Porta"
            placeholder="ESCPOS - Porta"
            margin="normal"
            value={settings.sat.escpos_port}
            fullWidth
            onChange={event => handleChange('escpos_port', event.target.value)}
            inputRef={inputs['sat.escpos_port']}
            error={!!validation['sat.escpos_port']}
            helperText={validation['sat.escpos_port']}
          />

          <TextField
            label="ESCPOS - Colunas"
            placeholder="ESCPOS - Colunas"
            margin="normal"
            value={settings.sat.escpos_columns}
            fullWidth
            onChange={event => handleChange('escpos_columns', event.target.value)}
            inputRef={inputs['sat.escpos_columns']}
            error={!!validation['sat.escpos_columns']}
            helperText={validation['sat.escpos_columns']}
          />

          <TextField
            label="ESCPOS - Espaço entre linhas"
            placeholder="ESCPOS - Espaço entre linhas"
            margin="normal"
            value={settings.sat.escpos_line_height}
            fullWidth
            onChange={event => handleChange('escpos_line_height', event.target.value)}
            inputRef={inputs['sat.escpos_line_height']}
            error={!!validation['sat.escpos_line_height']}
            helperText={validation['sat.escpos_line_height']}
          />

          <TextField
            label="ESCPOS - pular linhas"
            placeholder="ESCPOS - pular linhas"
            margin="normal"
            value={settings.sat.escpos_lines}
            fullWidth
            onChange={event => handleChange('escpos_lines', event.target.value)}
            inputRef={inputs['sat.escpos_lines']}
            error={!!validation['sat.escpos_lines']}
            helperText={validation['sat.escpos_lines']}
          />
        </div>
      )}
    </div>
  );
};

export default SatSettingsForm;
