import React, { useRef, useEffect } from 'react';
import { Grid, TextField, FormControlLabel, Switch, Typography, makeStyles } from '@material-ui/core';
import { useProduct } from 'pages/products/registration/hooks/useProduct';

const useStyles = makeStyles({
  noTaxContainer: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
  },
});

function ProductPricesStep() {
  const { handleChange, product, handleNext, validation } = useProduct();
  const classes = useStyles();

  const inputs = {
    price: useRef(null),
    special_price: useRef(null),
  };

  useEffect(() => {
    if (validation.price) inputs.price.focus();
    else if (validation.special_price) inputs.special_price.focus();
    // eslint-disable-next-line
  }, [validation]);

  function handleSubmit(event) {
    event.preventDefault();
    handleNext();
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid item xs={12} xl={4} lg={5} md={5} sm={6}>
        <TextField
          inputRef={ref => (inputs.price = ref)}
          error={!!validation.price}
          helperText={!!validation.price && validation.price}
          type="number"
          autoFocus
          label="Preço (R$)"
          placeholder="Digite o preço"
          fullWidth
          margin="normal"
          name="price"
          onChange={event => handleChange(event.target.value, 'price')}
          value={product.price || ''}
          inputProps={{
            step: 0.01,
            min: 0,
          }}
        />
        <TextField
          inputRef={ref => (inputs.special_price = ref)}
          error={!!validation.special_price}
          helperText={!!validation.special_price && validation.special_price}
          type="number"
          autoFocus
          label="Preço promocional (R$)"
          placeholder="Digite o preço promocional"
          fullWidth
          margin="normal"
          onChange={event => handleChange(event.target.value, 'special_price')}
          value={product.special_price || ''}
          required
          disabled={!product.promotion_activated}
          inputProps={{
            step: 0.01,
            min: 0,
          }}
        />
        <FormControlLabel
          control={
            <Switch
              color="primary"
              onChange={event => handleChange(event.target.checked, 'promotion_activated')}
              checked={product.promotion_activated}
            />
          }
          label="Preço promocional"
        />
        <div className={classes.noTaxContainer}>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                onChange={event => handleChange(event.target.checked, 'no_tax')}
                checked={product.no_tax}
              />
            }
            label="Sem taxa de entrega"
          />
          <Typography color="textSecondary" variant="caption">
            Quando este produto estiver no carrinho não será cobrado taxa de entrega.
          </Typography>
        </div>
      </Grid>
      <button type="submit" style={{ display: 'none' }}>
        Salvar
      </button>
    </form>
  );
}

export default ProductPricesStep;
