import React from 'react';
import { TextField } from '@material-ui/core';
import { usePaymentMethods } from '../../hooks/usePaymentMethods';

const PaymentMethodMercadoPago: React.FC = () => {
  const { gatewaySettings, handleConfigsChange } = usePaymentMethods();

  return (
    <>
      <TextField
        label="Public Key"
        margin="normal"
        fullWidth
        value={gatewaySettings.mercado_pago_public_key}
        onChange={e => handleConfigsChange('mercado_pago_public_key', e.target.value)}
        placeholder="Mercado Pago Public Key"
        autoFocus
      />
      <TextField
        label="Mercado Pago Access Token"
        margin="normal"
        fullWidth
        value={gatewaySettings.mercado_pago_access_token}
        onChange={e => handleConfigsChange('mercado_pago_access_token', e.target.value)}
        placeholder="Mercado Pago Access Token"
        autoFocus
      />
    </>
  );
};

export default PaymentMethodMercadoPago;
