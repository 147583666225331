import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { api, getCancelTokenSource } from 'services/api';
import { Category } from 'types/category';

type UseFetchCategories = [Category[], Dispatch<SetStateAction<Category[]>>, boolean];

export function useFetchCategories(): UseFetchCategories {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let request = true;

    const source = getCancelTokenSource();

    setLoading(true);

    api
      .get('order/categories', { cancelToken: source.token })
      .then(response => {
        if (request) {
          setCategories(response.data);
        }
      })
      .catch(err => console.error(err))
      .finally(() => {
        if (request) {
          setLoading(false);
        }

        request = false;
      });

    return () => {
      if (request) source.cancel();
      request = false;
    };
  }, []);

  return [categories, setCategories, loading];
}
