import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

ProfileSocial.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
  restaurant: PropTypes.object.isRequired,
};

export default function ProfileSocial({ handleSubmit, validation, restaurant, handleInputChange }) {
  return (
    <Grid item xs={12} xl={3} lg={4} md={6}>
      <form onSubmit={handleSubmit}>
        <TextField
          error={!!validation.facebook_link}
          helperText={validation.facebook_link && validation.facebook_link}
          variant="standard"
          margin="normal"
          fullWidth
          label="Conta Facebook"
          placeholder="Informe o link da conta do Facebook"
          value={restaurant.facebook_link}
          onChange={event => handleInputChange('facebook_link', event.target.value)}
        />
        <TextField
          error={!!validation.instagram_link}
          helperText={validation.instagram_link && validation.instagram_link}
          variant="standard"
          margin="normal"
          fullWidth
          label="Conta Instagram"
          placeholder="Informe o link da conta do Instagram"
          value={restaurant.instagram_link}
          onChange={event => handleInputChange('instagram_link', event.target.value)}
        />
        <TextField
          error={!!validation.twitter_link}
          helperText={validation.twitter_link && validation.twitter_link}
          variant="standard"
          margin="normal"
          fullWidth
          label="Conta Twitter"
          placeholder="Informe o link da conta do Twitter"
          value={restaurant.twitter_link}
          onChange={event => handleInputChange('twitter_link', event.target.value)}
        />
        <button type="submit" style={{ display: 'none' }} />
      </form>
    </Grid>
  );
}
