import React from 'react';
import { useProduct } from '../hooks/useProduct';
import { Button, Theme, makeStyles } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { useApp } from 'App';

const styles = makeStyles<Theme, { width?: number }>(theme => ({
  action: props => ({
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    alignItems: 'center',
    position: 'fixed',
    bottom: 0,
    right: props.width,
    borderTop: '1px solid #eee',
    backgroundColor: '#fff',
    padding: 15,
    zIndex: 100,
    transition: 'all 0.15s ease 0s',
    justifyContent: 'space-between',
    [theme.breakpoints.between('xs', 'sm')]: {
      left: 0,
      right: 0,
      bottom: 0,
    },
  }),
}));

interface ProductButtonsProps {
  width?: number;
}

const ProductButtons: React.FC<ProductButtonsProps> = ({ width = 310 }) => {
  const app = useApp();
  const { step, handlePrior, currentStep, handleNext } = useProduct();
  const classes = styles({ width });

  return (
    <div
      style={{
        justifyContent: step > 1 ? 'space-between' : 'flex-end',
        left: app.isOpenedMenu && !app.isMobile ? 300 : 0,
      }}
      className={classes.action}
    >
      {step > 1 && (
        <Button startIcon={<ArrowBack />} color="primary" variant="contained" onClick={handlePrior}>
          Voltar
        </Button>
      )}

      {currentStep && currentStep?.order > 1 && currentStep?.id !== 'STEP_10' && (
        <Button endIcon={<ArrowForward />} color="primary" variant="contained" type="submit" onClick={handleNext}>
          Avançar
        </Button>
      )}
    </div>
  );
};

export default ProductButtons;
