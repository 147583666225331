import React, { Dispatch, SetStateAction } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Add } from '@material-ui/icons';

type AreaRegionViewActionsProps = {
  setDialogAddCityRegion: Dispatch<SetStateAction<boolean>>;
};

const AreaRegionViewActions: React.FC<AreaRegionViewActionsProps> = ({ setDialogAddCityRegion }) => {
  return (
    <Tooltip title="Adicionar bairro">
      <IconButton color="inherit" onClick={() => setDialogAddCityRegion(true)}>
        <Add />
      </IconButton>
    </Tooltip>
  );
};

export default AreaRegionViewActions;
