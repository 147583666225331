import React from 'react';
import { Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { Customer } from 'types/customer';

type CustomerItemTableProps = {
  customer: Customer;
  setAnchorEl(el: HTMLButtonElement): void;
};

const CustomerItemTable: React.FC<CustomerItemTableProps> = ({ customer }) => {
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div key={item.id}>
            <Typography variant="body2">{customer[item.id] as keyof Customer}</Typography>
          </div>
        ))}
    </>
  );
};

export default CustomerItemTable;
