import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useFetchTaxRules } from './hooks/useFetchTaxRules';
import Appbar from 'components/appbar/Appbar';
import PaginationProvider from 'hooks/pagination';
import ApiPagination from 'components/_pagination/ApiPagination';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/nodata/NoData';
import TaxRuleListTable from './list/table/TaxRuleListTable';
import TaxRuleListModule from './list/module/TaxRuleListModule';
import useTableOrder from 'hooks/tableOrder';
import ReceiptsFilterBox from './TaxRulesFilterBox';
import ReceiptsAppbarButtons from './TaxRulesAppbarButtons';
import { useApp } from 'App';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import TaxRulesPageHeaderButtons from './TaxRulesPageHeaderButtons';
import { TaxRule, TaxRuleOnList } from './types/taxRule';
import { TaxRulesProvider } from './hooks/useTaxRules';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

export type TaxRulesQueryParams = {
  page: number;
  rows: number;
};

let timer: NodeJS.Timeout;

const TaxRules: React.FC = () => {
  const classes = styles();
  const { taxRules, fetch, total, loading, setQueryParams, queryParams } = useFetchTaxRules();
  const [selectedTaxRule, setSelectedTaxRule] = useState<TaxRule | null>(null);
  const [filtered, setFiltered] = useState<TaxRuleOnList[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [term, setTerm] = useState('');
  const app = useApp();
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>(app.isMobile ? 'module' : 'list');

  useEffect(() => {
    setFiltered(taxRules);
  }, [taxRules]);

  useEffect(() => {
    fetch('');
  }, [fetch]);

  useEffect(() => {
    if (app.windowWidth >= 930) {
      setDisplayMode('list');
      return;
    }

    setDisplayMode('module');
  }, [app.isMobile, app.windowWidth]);

  function handleParamsChange(index: keyof TaxRulesQueryParams, value: string | number) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSearch(value: string) {
    setTerm(value);

    clearTimeout(timer);

    timer = setTimeout(() => fetch(value), 500);
  }

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  return (
    <TaxRulesProvider
      value={{
        setSelectedTaxRule,
        selectedTaxRule,
      }}
    >
      <div className={classes.container}>
        <Appbar title="Regras fiscais" ActionComponents={<ReceiptsAppbarButtons openDialog={() => alert('teste')} />} />

        <PageHeaderActions
          title="Regras fiscais"
          description="Gerenciador de regras fiscais para emissão de CF-e e NFC-e"
          ActionComponent={<TaxRulesPageHeaderButtons />}
        />

        <ReceiptsFilterBox
          displayMode={displayMode}
          setDisplayMode={setDisplayMode}
          params={queryParams}
          handleParamsChange={handleParamsChange}
          handleSearch={handleSearch}
          term={term}
        />

        <PaginationProvider>
          {loading ? (
            displayMode === 'list' ? (
              <TableLoading />
            ) : (
              <ModuleLoading />
            )
          ) : filtered.length === 0 ? (
            <NoData message="Nenhum cupom para mostrar" />
          ) : (
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <TaxRuleListTable taxRules={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <TaxRuleListModule taxRules={filtered} />
              )}
            </div>
          )}

          <ApiPagination
            onChangePage={value => handleParamsChange('page', value)}
            onChangeRowsPerPage={value => handleParamsChange('rows', value)}
            count={total}
          />
        </PaginationProvider>
      </div>
    </TaxRulesProvider>
  );
};

export default TaxRules;
