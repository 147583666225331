import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Product } from 'types/product';

export interface ProductManagerContextValue {
  products: Product[];
  setProducts: Dispatch<SetStateAction<Product[]>>;
}

const ProductManagerContext = createContext<ProductManagerContextValue>({} as ProductManagerContextValue);
export const ProductManagerContextProvider = ProductManagerContext.Provider;
export const ProductManagerContextConsumer = ProductManagerContext.Consumer;

export function useProductManager(): ProductManagerContextValue {
  const context = useContext(ProductManagerContext);
  return context;
}
