import React from 'react';
import { CashRegisterMovement } from 'types/cashRegisterMovement';
import CashRegisterMovementItem from './CashRegisterMovementItem';
import { makeStyles } from '@material-ui/core';

const styles = makeStyles({
  list: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10,
    flex: 1,
    '& > div:not(:last-child)': {
      borderBottom: '1px solid #eee',
    },
  },
});

interface CashRegisterMovementListProps {
  movements: CashRegisterMovement[];
}

const CashRegisterMovementList: React.FC<CashRegisterMovementListProps> = ({ movements }) => {
  const classes = styles();

  return (
    <ul className={classes.list}>
      {movements.map(movement => (
        <CashRegisterMovementItem movement={movement} key={movement.id} />
      ))}
    </ul>
  );
};

export default CashRegisterMovementList;
