import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { api, getCancelTokenSource } from 'services/api';
import { Theme } from '@material-ui/core';
import Additional from './shared-parts/Additional';
import Address from './shared-parts/Address';
import Ingredients from './shared-parts/Ingredients';
import ComplementCategories from './shared-parts/ComplementCategories';
import { Order } from 'types/order';
import { useSelector } from 'store/redux/selector';
import { format, parseISO } from 'date-fns';
import { formatId } from 'helpers/formatOrderId';
import { moneyFormat } from 'helpers/NumberFormat';
import { ptBR } from 'date-fns/locale';
import PrintTypography from 'components/print-typography/PrintTypography';
import DialogFullscreen from 'components/dialog/DialogFullscreen';
import OrderAction from './OrderAction';

interface UseStylesProps {
  fontSize: number;
  noMargin: boolean;
}

const useStyles = makeStyles<Theme, UseStylesProps>({
  container: props => ({
    maxWidth: '80mm',
    width: '100%',
    padding: '15px 15px 30px 15px',
    backgroundColor: '#faebd7',
    border: '2px dashed #ccc',
    fontSize: props.fontSize,
    '@media print': {
      '&': {
        backgroundColor: 'transparent',
        border: 'none',
        padding: props.noMargin ? '0 0 0 0' : '0 0 0 10px',
        marginRight: 0,
      },
    },
  }),
  products: {
    padding: '7px 0 0',
    borderTop: '1px dashed #333',
  },
  headerProducts: {
    marginTop: 7,
  },
  product: {
    width: '100%',
    paddingBottom: 10,
  },
  productAmount: {
    minWidth: 25,
    paddingBottom: 10,
    display: 'flex',
    paddingTop: 0,
  },
  additionalInfoContainer: {
    // display: 'flex',
    flexWrap: 'wrap',
    columnGap: 5,
  },
  header: {
    textAlign: 'center',
    borderBottom: '1px dashed #000',
    paddingBottom: 10,
    marginBottom: 10,
  },
});

interface ApprovedBoardOrderProps {
  onExited(): void;
  orderId: string;
}

const ApprovedBoardOrder: React.FC<ApprovedBoardOrderProps> = ({ onExited, orderId }) => {
  const restaurant = useSelector(state => state.restaurant);
  const [order, setOrder] = useState<Order | null>(null);
  const classes = useStyles({
    fontSize: restaurant?.printer_settings?.font_size || 14,
    noMargin: !!restaurant?.printer_settings?.no_margin,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let request = true;
    const cancelToken = getCancelTokenSource();

    api
      .get(`/orders/${orderId}`, { cancelToken: cancelToken.token })
      .then(response => {
        if (request) {
          setOrder(handleOrder(response.data));
          setTimeout(window.print, 500);
        }
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        if (request) {
          setLoading(false);
        }
      });

    return () => {
      if (request) cancelToken.cancel();
      request = false;
    };
  }, [orderId]);

  function handleOrder(order: Order) {
    const date = parseISO(order.created_at);

    const shipment = order.shipment
      ? {
          ...order.shipment,
          formattedScheduledAt: order.shipment?.scheduled_at
            ? format(parseISO(order.shipment.scheduled_at as string), 'HH:mm')
            : '',
        }
      : null;

    return {
      ...order,
      formattedId: formatId(order.id),
      formattedSequence: formatId(order.sequence),
      formattedTotal: moneyFormat(order.total),
      formattedCreatedAt: format(date, "PP, 'às' p", { locale: ptBR }),
      shipment,
    };
  }

  return (
    <DialogFullscreen
      backgroundColor="#fff"
      title="Imprimir pedido"
      handleModalState={onExited}
      componentActions={<OrderAction />}
    >
      {loading && <div>Carregando...</div>}

      {order && (
        <div className={classes.container} key={order.id}>
          <div className={classes.header}>
            <PrintTypography fontSize={1.2} bold>
              MESA {order.board_movement?.board?.number}
            </PrintTypography>
          </div>

          <PrintTypography bold gutterBottom>
            PEDIDO {order.formattedSequence}
          </PrintTypography>

          <PrintTypography>{order.formattedCreatedAt}</PrintTypography>

          <PrintTypography gutterBottom>{order.customer?.name}</PrintTypography>

          {order.shipment?.shipment_method === 'delivery' && <Address shipment={order.shipment} />}

          {order.shipment?.shipment_method === 'customer_collect' && !order.shipment?.scheduled_at && (
            <PrintTypography bold>**Cliente retirará**</PrintTypography>
          )}

          {order.shipment?.scheduled_at && (
            <PrintTypography>**Cliente retirará ás {order.shipment?.formattedScheduledAt}**</PrintTypography>
          )}

          <table className={classes.headerProducts}>
            <tbody>
              <tr>
                <td>
                  <PrintTypography>Qtd</PrintTypography>
                </td>
                <td>
                  <PrintTypography>Item</PrintTypography>
                </td>
              </tr>
            </tbody>
          </table>
          <div className={classes.products}>
            <table>
              <tbody>
                {order.products.map(product => (
                  <tr key={product.id}>
                    <td className={classes.productAmount}>
                      <PrintTypography>{product.amount}x</PrintTypography>
                    </td>

                    <td className={classes.product}>
                      <PrintTypography upperCase bold>
                        {product.name}
                      </PrintTypography>

                      {product.annotation && (
                        <PrintTypography fontSize={0.8}>Obs: {product.annotation}</PrintTypography>
                      )}

                      <div className={classes.additionalInfoContainer}>
                        <Additional additional={product.additional} />
                        <Ingredients ingredients={product.ingredients} />
                      </div>

                      <ComplementCategories categories={product.complement_categories} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <PrintTypography align="center">.</PrintTypography>
        </div>
      )}
    </DialogFullscreen>
  );
};

export default ApprovedBoardOrder;
