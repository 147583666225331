import React, { useState } from 'react';
import List from 'components/list/List';
import ReceiptItemModule from './TaxRuleItemModule';
import ReceiptListMenu from '../ReceiptListMenu';
import { TaxRuleOnList } from 'pages/tax-rules/types/taxRule';

type TaxRuleListModuleProps = {
  taxRules: TaxRuleOnList[];
};

const TaxRuleListModule: React.FC<TaxRuleListModuleProps> = ({ taxRules }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <ReceiptListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <List>
        {taxRules.map(item => (
          <ReceiptItemModule setAnchorEl={setAnchorEl} key={item.id} taxRule={item} />
        ))}
      </List>
    </>
  );
};

export default TaxRuleListModule;
