import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
    padding: 15,
    alignItems: 'center',
  },
  title: {},
  icon: {
    fontSize: 40,
  },
});

export default function NotFound() {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.container}>
          <Typography variant="h4" align="center" className={classes.title} gutterBottom>
            404 - página não encontrada
          </Typography>

          <Link to={-1}>voltar</Link>
        </div>
      </Grid>
    </>
  );
}
