import React, { useState } from 'react';
import List from 'components/list/List';
import UserItemModule from './UserItemModule';
import { User } from 'types/user';
import UsersListMenu from '../UsersListMenu';

type UserListModuleProps = {
  users: User[];
};

const UserListModule: React.FC<UserListModuleProps> = ({ users }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <UsersListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <List>
        {users.map(item => (
          <UserItemModule setAnchorEl={setAnchorEl} key={item.id} user={item} />
        ))}
      </List>
    </>
  );
};

export default UserListModule;
