import React, { useEffect, useRef } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Category } from 'types/category';
import { CategoryValidation } from '../registration/validation/categoryValidation';
import ImageUpload from 'components/image-upload/ImageUpload';

const useStyles = makeStyles(theme => ({
  categoryPhoto: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 20,
    marginTop: 0,
    maxWidth: 300,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      minWidth: 300,
      marginRight: 0,
    },
  },
}));

type CategoryFormProps = {
  category: Category;
  handleChange(value: any, index: keyof Category): void;
  validation: CategoryValidation;
};

const CategoryForm: React.FC<CategoryFormProps> = ({ category, handleChange, validation }) => {
  const classes = useStyles();
  const inputs = {
    name: useRef<HTMLInputElement>(null),
    url: useRef<HTMLInputElement>(null),
    description: useRef<HTMLInputElement>(null),
    keywords: useRef<HTMLInputElement>(null),
    relevance: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <Grid container>
      <div className={classes.categoryPhoto}>
        <ImageUpload
          label="Imagem da categoria"
          handleRemoveImage={() => handleChange(null, 'image')}
          handleSetImage={image => handleChange(image, 'image')}
          validationError={validation.image}
          image={category.image}
        />
      </div>
      <Grid item xs={12} xl={3} md={6} lg={3}>
        <TextField
          inputRef={inputs.name}
          error={!!validation.name}
          helperText={validation.name && validation.name}
          label="Nome"
          placeholder="Digite o nome da categoria"
          margin="normal"
          variant="standard"
          fullWidth
          value={category.name}
          onChange={event => handleChange(event.target.value, 'name')}
          autoFocus
        />
        <TextField
          inputRef={inputs.url}
          error={!!validation.url}
          helperText={validation.url && validation.url}
          label="URL"
          placeholder="Digite a url da categoria"
          margin="normal"
          variant="standard"
          fullWidth
          value={category.url}
          onChange={event => handleChange(event.target.value, 'url')}
        />
        <TextField
          inputRef={inputs.description}
          minRows={4}
          multiline
          label="Descrição"
          placeholder="Digite a descrição da categoria"
          margin="normal"
          variant="standard"
          fullWidth
          value={category.description ? category.description : ''}
          onChange={event => handleChange(event.target.value, 'description')}
        />
        <TextField
          inputRef={inputs.keywords}
          minRows={4}
          multiline
          label="Palavras chaves"
          placeholder="Digite as palavras chaves"
          margin="normal"
          variant="standard"
          fullWidth
          value={category.keywords ? category.keywords : ''}
          onChange={event => handleChange(event.target.value, 'keywords')}
        />
        <TextField
          inputRef={inputs.relevance}
          label="Relevância"
          placeholder="Informe a relevancia da categoria"
          margin="normal"
          variant="standard"
          fullWidth
          value={category.relevance}
          onChange={event => handleChange(event.target.value, 'relevance')}
          helperText={
            validation.relevance || 'Relevância tem como objetivo definir valores para ordenação das categorias'
          }
          error={!!validation.relevance}
        />
      </Grid>
    </Grid>
  );
};

export default CategoryForm;
