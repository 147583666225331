import { useCallback, useEffect, useState } from 'react';
import { api, getCancelTokenSource } from 'services/api';
import { CashRegisterReportType } from '../CashRegisterReportModal';
import { format, parseISO } from 'date-fns';
import { moneyFormat } from 'helpers/NumberFormat';
import ptBR from 'date-fns/locale/pt-BR';

export function useFetchCashRegister(id?: string): [CashRegisterReportType | null, boolean] {
  const [cashRegister, setCashRegister] = useState<CashRegisterReportType | null>(null);
  const [loading, setLoading] = useState(true);

  const formatCashRegister = useCallback((data: CashRegisterReportType): CashRegisterReportType => {
    return {
      ...data,
      cash_register: {
        ...data.cash_register,
        formattedClosedAt: data.cash_register.closed_at
          ? format(parseISO(data.cash_register.closed_at), 'P H:mm', { locale: ptBR })
          : '',
        formattedInitialValue: moneyFormat(data.cash_register.initial_value),
        formattedCreatedAt: format(parseISO(data.cash_register.created_at), 'P H:mm', { locale: ptBR }),
        formattedCredit: moneyFormat(data.cash_register.credit),
        formattedDebit: moneyFormat(data.cash_register.debit),
        formattedCurrentValue: moneyFormat(data.cash_register.current_value),
      },
      payment_methods: data.payment_methods.map(payment => {
        payment.formattedTotal = moneyFormat(payment.total);
        return payment;
      }),
      deliverers: data.deliverers.map(deliverer => {
        deliverer.payment_methods = deliverer.payment_methods.map(payment => {
          payment.formattedTotal = moneyFormat(payment.total);
          return payment;
        });
        deliverer.formattedTotal = moneyFormat(deliverer.total);
        deliverer.formattedTax = moneyFormat(deliverer.tax);
        return deliverer;
      }),
      products: data.products.map(product => ({
        ...product,
        formattedTotal: moneyFormat(product.total),
      })),
      delivery: {
        ...data.delivery,
        formattedTotal: moneyFormat(data.delivery.total),
        formattedAverageTicket: moneyFormat(data.delivery.average_ticket),
      },
      board: {
        ...data.board,
        formattedTotal: moneyFormat(data.board.total),
        formattedAverageTicket: moneyFormat(data.board.average_ticket),
      },
      formattedTax: moneyFormat(data.tax),
      formattedTotal: moneyFormat(data.total),
      formattedProductsTotal: moneyFormat(data.products_total),
    };
  }, []);

  useEffect(() => {
    const source = getCancelTokenSource();
    let request = true;

    if (!id) {
      return;
    }

    api
      .get(`/cashRegisters/${id}/report`)
      .then(response => {
        if (request) {
          setCashRegister(formatCashRegister(response.data));
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        if (request) {
          setLoading(false);
        }
        request = false;
      });

    return () => {
      if (request) source.cancel();
      request = false;
    };
  }, [id, formatCashRegister]);

  return [cashRegister, loading];
}
