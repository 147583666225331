import { Order, OrderProduct } from 'types/order';
import { Complement, ComplementCategory, Ingredient, OrderProductAdditional } from 'types/product';

export function getOrderDataToSubmit(order: Order): Order {
  const products = getProducts(order.products);

  return {
    ...order,
    products,
  };
}

function getProducts(products: OrderProduct[]): OrderProduct[] {
  return products.map(product => {
    const additional = getAdditional(product.additional);
    const ingredients = getIngredients(product.ingredients);

    product.additional = additional;
    product.ingredients = ingredients;

    product.complement_categories = getComplementCategories(product.complement_categories);

    return product;
  });
}

function getComplementCategories(categories: ComplementCategory[]): ComplementCategory[] {
  return categories.filter(category => {
    category.complements = getComplements(category.complements);
    return category.complements.length > 0;
  });
}

function getComplements(complements: Complement[]): Complement[] {
  return complements.filter(complement => complement.selected);
}

function getAdditional(additional: OrderProductAdditional[]): OrderProductAdditional[] {
  return additional.filter(item => item.selected);
}

function getIngredients(ingredients: Ingredient[]): Ingredient[] {
  return ingredients.filter(item => !item.selected);
}
