import { Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useInputDialog } from 'components/dialog/DialogInput';

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    textAlign: 'center',
  },
  total: {
    fontWeight: 500,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flex: 1,
  },
});

interface ShipmentDeliveryScheduleProps {
  handleScheduleYes(): void;
  handleScheduleNo(): void;
}

const ShipmentDeliverySchedule: React.FC<ShipmentDeliveryScheduleProps> = ({ handleScheduleYes, handleScheduleNo }) => {
  const classes = useStyles();
  const { handleCloseDialog } = useInputDialog();

  function handleClickNo() {
    handleScheduleNo();
    handleCloseDialog();
  }

  return (
    <div className={classes.container}>
      <Typography variant="h6">agendar a entrega?</Typography>
      <div className={classes.actions}>
        <Button onClick={handleClickNo} variant="text" color="primary">
          Não
        </Button>
        <Button onClick={handleScheduleYes} variant="contained" color="primary">
          Sim
        </Button>
      </div>
    </div>
  );
};

export default ShipmentDeliverySchedule;
