import { Button, makeStyles, Typography } from '@material-ui/core';
import DialogInput from 'components/dialog/DialogInput';
import React, { useState } from 'react';
import CashRegisterInitialValueForm from './CashRegisterInitialValueForm';

const styles = makeStyles({
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 220,
    gap: '30px',
  },
});

interface CashRegisterInitialValueProps {
  onExited(): void;
}

const CashRegisterInitialValue: React.FC<CashRegisterInitialValueProps> = ({ onExited }) => {
  const classes = styles();

  const [error, setError] = useState('');

  function handleClearError() {
    setError('');
  }

  return (
    <DialogInput maxWidth="sm" onExited={onExited}>
      {error ? (
        <div className={classes.errorContainer}>
          <Typography>{error}</Typography>
          <Button onClick={handleClearError} color="primary" variant="contained">
            Tentar novamente
          </Button>
        </div>
      ) : (
        <CashRegisterInitialValueForm setError={setError} />
      )}
    </DialogInput>
  );
};

export default CashRegisterInitialValue;
