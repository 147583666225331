import React from 'react';
import { Typography, IconButton } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';

interface useStylesProps {
  gutterBottom: boolean;
}

const useStyles = makeStyles({
  container: ({ gutterBottom }: useStylesProps) => ({
    marginBottom: gutterBottom ? 10 : 0,
    display: 'flex',
    alignItems: 'center',
  }),
});

interface PageHeaderProps {
  title: string;
  description?: string;
  gutterBottom?: boolean;
  backAction?(): void;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, description, gutterBottom = true, backAction }) => {
  const classes = useStyles({ gutterBottom });

  return (
    <div className={classes.container}>
      {backAction && (
        <IconButton onClick={backAction}>
          <ArrowBack />
        </IconButton>
      )}
      <div>
        <Typography variant="h6">{title}</Typography>
        {description && (
          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default PageHeader;
