import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { Typography, makeStyles, ListItem, IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import history from 'services/history';
import { useFiscalSerials } from 'pages/fiscal-serials/hooks/useFiscalSerials';
import { FiscalSerialOnList } from 'pages/fiscal-serials/types/fiscal-serial';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
    minHeight: 120,
    position: 'relative',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  buttonMore: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
  },
});

interface FiscalSerialItemModuleProps {
  serial: FiscalSerialOnList;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
}

const FiscalSerialItemModule: React.FC<FiscalSerialItemModuleProps> = ({ serial, setAnchorEl }) => {
  const classes = useStyles();
  const { setSelectedSerial } = useFiscalSerials();

  function handleClick() {
    setSelectedSerial(serial);
    history.push(`/fiscal-serials/${serial.id}`);
  }

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  return (
    <ListItem onClick={handleClick} key={serial.id} className={classes.listItem} button>
      <Typography gutterBottom variant="caption" color="textSecondary">
        {serial.formattedType}
      </Typography>

      <Typography variant="h6">Série {serial.serial}</Typography>

      <Typography variant="body2" color="textSecondary">
        {serial.active ? 'Ativo' : 'Inativo'}
      </Typography>

      <IconButton onClick={handleMoreClick} className={classes.buttonMore}>
        <MoreVert />
      </IconButton>
    </ListItem>
  );
};

export default FiscalSerialItemModule;
