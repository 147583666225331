import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface UseStylesProps {
  oneColumn: boolean;
}

const useStyles = makeStyles<Theme, UseStylesProps>(theme => ({
  preLoading1: {
    width: 100,
    height: 100,
    backgroundColor: '#eee',
    borderRadius: theme.shape.borderRadius,
  },
  preLoading2: {
    height: 24,
    maxWidth: 250,
    minWidth: 173,
    backgroundColor: '#eee',
    marginBottom: 7,
  },
  preLoading4: {
    padding: 6,
    maxWidth: 150,
    minWidth: 80,
    backgroundColor: '#eee',
    marginBottom: 6,
  },
  listItem: {
    display: 'flex',
    backgroundColor: '#fff',
    border: '1px solid #f5f5f5',
    borderRadius: theme.shape.borderRadius,
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 120,
  },
  list: props => ({
    display: 'grid',
    gridTemplateColumns: props.oneColumn ? '1fr' : 'repeat(3, 1fr)',
    gridGap: 6,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  }),
}));

interface ProductsLoadingProps {
  oneColumn?: boolean;
  quantity?: number;
}

function ProductsLoading({ oneColumn = false, quantity = 9 }: ProductsLoadingProps) {
  const classes = useStyles({ oneColumn });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <List className={classes.list}>
          {Array.from(Array(quantity).keys()).map(item => (
            <ListItem key={item} className={classes.listItem}>
              <div>
                <div className={`animated-background ${classes.preLoading2}`} />
                <div className={`animated-background ${classes.preLoading4}`} />
                <div className={`animated-background ${classes.preLoading4}`} />
              </div>
              <div className={`animated-background ${classes.preLoading1}`} />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
}

export default ProductsLoading;
