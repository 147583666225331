import { PaymentMethod } from 'types/paymentMethod';
import { PaymentMethodActions } from './types';

export function paymentMethodChangeAction(index: keyof PaymentMethod, value: any): PaymentMethodActions {
  return {
    type: 'payment_method_change',
    value,
    index,
  };
}

export function setPaymentMethodAction(value: PaymentMethod): PaymentMethodActions {
  return {
    type: 'set_payment_method',
    value,
  };
}
