import { TextField, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { DisableSequencePayload } from '../types/disable-sequence-payload';
import { DisableSequenceValidation } from './validation/useDisableSequenceValidation';

const styles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      display: 'flex',
      gap: 15,
    },
  },
});

interface DisableSequenceModalFormProps {
  payload: DisableSequencePayload;
  handleChange: (index: keyof DisableSequencePayload, value: string) => void;
  validation: DisableSequenceValidation;
}

const DisableSequenceModalForm: React.FC<DisableSequenceModalFormProps> = ({ payload, handleChange, validation }) => {
  const classes = styles();

  const inputs = {
    from: useRef<HTMLInputElement>(null),
    to: useRef<HTMLInputElement>(null),
    justification: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <div className={classes.content}>
      <div>
        <TextField
          inputRef={inputs.from}
          label="Número inicial"
          placeholder="Número inicial"
          margin="normal"
          value={payload.from}
          onChange={event => handleChange('from', event.target.value)}
          fullWidth
          autoFocus
          helperText={validation.from}
          error={!!validation.from}
        />

        <TextField
          inputRef={inputs.to}
          label="Número final"
          placeholder="Número final"
          margin="normal"
          value={payload.to}
          onChange={event => handleChange('to', event.target.value)}
          fullWidth
          helperText={validation.to}
          error={!!validation.to}
        />
      </div>

      <TextField
        inputRef={inputs.justification}
        label="Justificativa"
        placeholder="Justificativa da inutilização"
        margin="normal"
        value={payload.justification}
        onChange={event => handleChange('justification', event.target.value)}
        fullWidth
        helperText={validation.justification}
        error={!!validation.justification}
        multiline
        minRows={3}
      />
    </div>
  );
};

export default DisableSequenceModalForm;
