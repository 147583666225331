import { TableTemplate } from 'types/tableTemplate';

export const cashRegistersTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 60,
    notFilterable: true,
    notSearchable: true,
  },
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 100,
  },
  {
    id: 'sequence',
    description: 'SEQUÊNCIA',
    originalId: 'id',
    width: 100,
  },
  {
    id: 'dayOfTheWeek',
    description: 'DIA',
    originalId: 'dayOfTheWeek',
    width: 150,
  },
  {
    id: 'formattedInitialValue',
    description: 'SALDO INICIAL',
    originalId: 'initial_value',
    width: 150,
  },
  {
    id: 'status',
    description: 'STATUS',
    originalId: 'status',
    width: 200,
  },
  {
    id: 'formattedCreatedAt',
    description: 'CRIADO EM',
    originalId: 'formattedCreatedAt',
    width: 200,
    notSearchable: true,
  },
  {
    id: 'formattedClosedAt',
    description: 'FECHADO EM',
    originalId: 'formattedClosedAt',
    width: 200,
    notSearchable: true,
  },
];
