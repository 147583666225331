import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

const AreaCityRegionAction: React.FC = () => {
  return (
    <Tooltip title="Salvar">
      <IconButton type="submit" color="inherit">
        <DoneIcon />
      </IconButton>
    </Tooltip>
  );
};

export default AreaCityRegionAction;
