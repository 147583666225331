import React, { useRef, useEffect } from 'react';
import { Grid, TextField, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import PropTypes from 'prop-types';
import { usePromotion } from 'pages/promotion/hooks/usePromotion';

PromotionDiscount.propTypes = {
  promotion: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
};

export default function PromotionDiscount({ promotion, handleChange, validation }) {
  const { handleNext } = usePromotion();

  useEffect(() => {
    if (validation.discount) inputs.discount.focus();
    // eslint-disable-next-line
  }, [validation]);

  const inputs = {
    discount: useRef(null),
  };

  function handleSubmit(event) {
    event.preventDefault();
    handleNext();
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} lg={5} xl={4}>
          <form onSubmit={handleSubmit}>
            <TextField
              inputRef={ref => (inputs.discount = ref)}
              label={`Desconto ${promotion.safe.discount_type === 'value' ? '(R$)' : '(%)'}`}
              placeholder="Digite o desconto que será concedido no carrinho"
              margin="normal"
              value={promotion.safe.discount}
              onChange={e => handleChange('discount', e.target.value)}
              error={!!validation.discount}
              helperText={!!validation.discount && validation.discount}
              fullWidth
              autoFocus
              type="number"
              inputProps={{
                step: 0.01,
              }}
            />
            <RadioGroup
              name="discountType"
              value={promotion.safe.discount_type}
              onChange={e => handleChange('discount_type', e.target.value)}
            >
              <FormControlLabel value="value" control={<Radio />} label="Valor (R$)" />
              <FormControlLabel value="percent" control={<Radio />} label="Percentual" />
            </RadioGroup>
            <button type="submit" style={{ display: 'none' }} />
          </form>
        </Grid>
      </Grid>
    </>
  );
}
