import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';

interface EditAdditionalActionsProps {
  loading: boolean;
  saving: boolean;
  handleSubmit(): void;
  handleDelete(): void;
}

const EditAdditionalActions: React.FC<EditAdditionalActionsProps> = ({
  saving,
  loading,
  handleDelete,
  handleSubmit,
}) => {
  return (
    <>
      <Tooltip title={'Excluir adicional'}>
        <span>
          <IconButton onClick={handleDelete} disabled={saving || loading} color="inherit">
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={'Salvar'}>
        <span>
          <IconButton disabled={saving || loading} onClick={handleSubmit} color="inherit">
            <DoneIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};

export default EditAdditionalActions;
