import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import Done from '@material-ui/icons/Done';
import { useCustomDialog } from 'components/dialog/CustomDialog';

interface TableQuicklyCreateActionsProps {
  handleSubmit(handleCloseModal: () => void): void;
}

const TableQuicklyCreateActions: React.FC<TableQuicklyCreateActionsProps> = ({ handleSubmit }) => {
  const { handleCloseDialog } = useCustomDialog();

  return (
    <>
      <Tooltip title="Confirmar">
        <IconButton onClick={() => handleSubmit(handleCloseDialog)} color="inherit">
          <Done />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default TableQuicklyCreateActions;
