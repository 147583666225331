import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import history from 'services/history';
import { Customer } from 'types/customer';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
    minHeight: 100,
    position: 'relative',
    alignItems: 'flex-start',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
    width: 60,
    height: 60,
    borderRadius: '50%',
    backgroundColor: '#eee',
    overflow: 'hidden',
    border: `2px solid #fff`,
    flexShrink: 0,
  },
  image: {
    width: '100%',
  },
  buttonMore: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
});

interface CustomerItemModuleProps {
  customer: Customer;
}

const CustomerItemModule: React.FC<CustomerItemModuleProps> = ({ customer }) => {
  const classes = useStyles();

  function handleClick(customerId: number) {
    history.push(`/customers/${customerId}`);
  }

  return (
    <ListItem onClick={() => handleClick(customer.id)} key={customer.id} className={classes.listItem} button>
      <Typography variant="caption" color="primary">
        Cliente {customer.id}
      </Typography>

      <Typography variant="h6">{customer.name}</Typography>
      <Typography variant="body2" color="secondary">
        {customer.phone}
      </Typography>
    </ListItem>
  );
};

export default CustomerItemModule;
