import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { InputAdornment, makeStyles, MenuItem, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/KeyboardDatePicker';
import { BoardOrdersQueryParams } from './BoardOrders';

const useStyles = makeStyles(theme => ({
  filter: {
    display: 'grid',
    gridTemplateColumns: '250px 200px 1fr',
    columnGap: 10,
    flex: 1,
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      flex: 1,
    },
    '& > .search-params': {
      display: 'grid',
      alignItems: 'baseline',
      gridTemplateColumns: '200px 150px 150px',
      columnGap: 10,
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 1fr 1fr',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
}));

interface OrdersFilterBoxProps {
  queryParams: BoardOrdersQueryParams;
  handleQueryParamsChange(index: keyof BoardOrdersQueryParams, value: any): void;
  displayMode: 'list' | 'module';
  setDisplayMode: Dispatch<SetStateAction<'list' | 'module'>>;
  handleSearchTermChange(value: string): void;
  term: string;
}

const OrdersFilterBox: React.FC<OrdersFilterBoxProps> = ({
  handleQueryParamsChange,
  handleSearchTermChange,
  queryParams,
  displayMode,
  setDisplayMode,
  term,
}) => {
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  useEffect(() => {
    inputSearchRef.current?.focus();
    inputSearchRef.current?.setSelectionRange(0, inputSearchRef.current.value.length);
  }, [inputSearchRef]);

  return (
    <FilterBox>
      <div className={classes.filter}>
        <TextField
          inputRef={inputSearchRef}
          label="Pesquisar"
          placeholder="Digite sua pesquisa"
          autoFocus
          value={term}
          onChange={e => handleSearchTermChange(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <div className="search-params">
          <TextField
            select
            label="Status"
            value={queryParams.status}
            onChange={e => handleQueryParamsChange('status', e.target.value)}
          >
            <MenuItem value="all">todos</MenuItem>
            <MenuItem value="o">aberto</MenuItem>
            <MenuItem value="a">aprovado</MenuItem>
            <MenuItem value="d">saiu para entrega</MenuItem>
            <MenuItem value="c">completo</MenuItem>
            <MenuItem value="x">cancelado</MenuItem>
          </TextField>
          <KeyboardDatePicker
            label="Data inicial"
            value={queryParams.initial_date}
            onChange={date => handleQueryParamsChange('initial_date', date)}
            autoOk
          />
          <KeyboardDatePicker
            label="Data Final"
            value={queryParams.final_date}
            onChange={date => handleQueryParamsChange('final_date', date)}
            autoOk
          />
        </div>
      </div>
      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default OrdersFilterBox;
