export const INITIAL_STATE = {
  address: 'local',
  name: '',
};

export default function printer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CHANGE': {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case 'SET_PRINTER': {
      return {
        ...action.printer,
      };
    }

    default: {
      return state;
    }
  }
}
