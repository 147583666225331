import { PaymentMethod } from 'types/paymentMethod';
import { PaymentMethodActions } from './types';
import { useReducer } from 'react';
import { uuidv4 } from 'helpers/uuid';

const initialState: PaymentMethod = {
  activated: true,
  created_at: '',
  kind: 'card',
  updated_at: '',
  sefaz_code: '99',
  mode: 'offline',
  method: '',
  must_create_receipt: false,
  id: 0,
  uuid: uuidv4(),
};

export function paymentMethodReducer(state: PaymentMethod, action: PaymentMethodActions): PaymentMethod {
  switch (action.type) {
    case 'payment_method_change': {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case 'set_payment_method': {
      return action.value;
    }

    default: {
      return state;
    }
  }
}

export function usePaymentMethodReducer() {
  return useReducer(paymentMethodReducer, initialState);
}
