import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import ptbr from 'date-fns/locale/pt-BR';
import { MuiPickersUtilsProvider, DatePicker, DatePickerProps } from '@material-ui/pickers';

interface MobileDatePickerProps extends DatePickerProps {
  hideBackdrop?: boolean;
}

const MobileDatePicker: React.FC<MobileDatePickerProps> = ({ hideBackdrop = false, ...rest }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptbr}>
      <DatePicker
        cancelLabel="Cancelar"
        placeholder="Ex.: 01/01/2020"
        format="dd/MM/yyyy"
        invalidDateMessage="Data inválida"
        margin="normal"
        DialogProps={{ hideBackdrop }}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

export default MobileDatePicker;
