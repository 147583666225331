import { useSelector } from 'store/redux/selector';
import { Address } from 'types/address';

export function useRestaurantAddress(): Address | null {
  const restaurant = useSelector(state => state.restaurant);
  const address = restaurant?.addresses.find(address => address.is_main);

  if (address) {
    return {
      district: address.district,
      region: address.region,
      reference_point: '',
      postal_code: address.postal_code,
      number: address.number,
      longitude: address.longitude,
      latitude: address.latitude,
      address: address.address,
      city: address.city,
      complement: address.complement,
      id: address.id,
    };
  }

  return null;
}
