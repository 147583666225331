import React from 'react';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { List, ListItem, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 6,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  listItem: {
    display: 'flex',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
    minHeight: 120,
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  selected: {
    display: 'flex',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 120,
    border: `2px dashed ${theme.palette.primary.light}`,
    '&:focus': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
    },
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: 100,
    borderRadius: 4,
    overflow: 'hidden',
    border: `2px solid #fff`,
  },
  image: {
    width: '100%',
  },
  checkIcon: {
    position: 'absolute',
    right: 10,
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
}));

PromotionProductsList.propTypes = {
  products: PropTypes.array.isRequired,
  handleSetSelectedProduct: PropTypes.func.isRequired,
};

export default function PromotionProductsList({ products, handleSetSelectedProduct }) {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      {products.map(product => {
        return (
          <ListItem
            key={product.id}
            className={product.selected ? classes.selected : classes.listItem}
            button
            onClick={() => handleSetSelectedProduct(product)}
          >
            <div>
              <Typography variant="h6">{product.name}</Typography>
              {product.price > 0 && (
                <Typography variant="body1" color="textSecondary">
                  {product.formattedPrice}
                </Typography>
              )}
              {product.category.has_complement && (
                <Typography variant="body2" color="primary">
                  Monte esse produto
                </Typography>
              )}
            </div>
            <div className={classes.imageContainer}>
              {product.image && (
                <img
                  className={classes.image}
                  src={product.image.imageThumbUrl ? product.image.imageThumbUrl : product.image.imageUrl}
                  alt={product.name}
                />
              )}
            </div>
          </ListItem>
        );
      })}
    </List>
  );
}
