import { TableTemplate } from 'types/tableTemplate';

export const couponsTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'amount_customer',
    description: 'POR CLIENTE',
    originalId: 'amount_customer',
    width: 200,
  },
  {
    id: 'name',
    description: 'NOME',
    originalId: 'name',
    width: 200,
  },
  {
    id: 'description',
    description: 'DESCRIÇÃO',
    originalId: 'description',
    width: 400,
  },
  {
    id: 'discount',
    description: '% DESCONTO',
    originalId: 'discount',
    width: 100,
  },
  {
    id: 'discount_type',
    description: 'TIPO',
    originalId: 'email',
    width: 100,
  },
  {
    id: 'usage_amount',
    description: 'QTD USO',
    originalId: 'email',
    width: 100,
  },
  {
    id: 'formattedCreatedAt',
    description: 'REGISTRADO EM',
    originalId: 'formattedCreatedAt',
    width: 200,
  },
];
