import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SidebarItem from '../SidebarItem';
import { SIDEBAR_WIDTH } from 'constants/constants';
import SidebarBackAction from '../SidebarBackAction';
import { useApp } from 'App';
import { useSelector } from 'store/redux/selector';
import { Payment, People, Store, Print } from '@material-ui/icons';
import { AiFillControl } from 'react-icons/ai';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.secondary.dark,
    zIndex: 2,
    transform: `translateX(-${SIDEBAR_WIDTH}px)`,
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
  drawerHeader: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 5,
  },
}));

type SettingsSidebarProps = {
  show: boolean;
  handleClose(): void;
};

const SettingsSidebar: React.FC<SettingsSidebarProps> = ({ show, handleClose }) => {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const app = useApp();

  return (
    <div className={classes.container} style={{ transform: show ? 'translateX(0)' : undefined }}>
      <div className={classes.drawerHeader}>
        <IconButton color="inherit" onClick={handleClose}>
          <ArrowBackIcon />
        </IconButton>
        <Typography color="inherit" variant="h6">
          configurações
        </Typography>
      </div>
      {user && (
        <>
          {!app.isMobile && app.windowWidth > 960 && (
            <SidebarBackAction handleClick={handleClose} icon={<ArrowBackIcon />} text="Voltar" />
          )}
          <SidebarItem to="/profile" icon={<Store />} text="loja" />
          <SidebarItem to="/settings" text="parâmetros" icon={<AiFillControl size={24} />} />
          <SidebarItem to="/printer-settings" text="impressão" icon={<Print />} />
          <SidebarItem to="/fiscal-settings" text="fiscal" icon={<Print />} />
          <SidebarItem to="/billing" text="faturamento" icon={<Payment />} />
          <SidebarItem to="/users" text="usuários" icon={<People />} />
        </>
      )}
    </div>
  );
};

export default SettingsSidebar;
