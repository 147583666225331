import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import history from 'services/history';

const styles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
});

const HeaderActions: React.FC = () => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <Button color="primary" size="small" variant="contained" onClick={() => history.push('/board-orders')}>
        pedidos
      </Button>
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={() => history.push('/board-movement/movements')}
      >
        histórico
      </Button>
    </div>
  );
};

export default HeaderActions;
