import { Customer } from 'types/customer';
import { CustomerAddress } from 'types/customerAddress';
import { Order, OrderProduct } from 'types/order';
import { PaymentMethod } from 'types/paymentMethod';
import { RestaurantConfig } from 'types/restaurant';
import { OrderActions } from './types';

export function setOrder(order: Order): OrderActions {
  return {
    type: '@order/SET_ORDER',
    order,
  };
}

export function setOrderInitialState(): OrderActions {
  return {
    type: '@order/SET_INITIAL_STATE',
  };
}

export function orderChange(index: keyof Order, value: any): OrderActions {
  return {
    type: '@order/CHANGE',
    index,
    value,
  };
}

export function setChange(value: number): OrderActions {
  return {
    type: '@order/SET_CHANGE',
    value,
  };
}

export function setCustomer(customer: Customer | null): OrderActions {
  return {
    type: '@order/SET_CUSTOMER',
    customer,
  };
}

export function setCustomerAddresses(addresses: CustomerAddress[]): OrderActions {
  return {
    type: '@order/SET_CUSTOMER_ADDRESSES',
    addresses,
  };
}

export function addProducts(product: OrderProduct, amount: number): OrderActions {
  return {
    type: '@order/ADD_PRODUCT',
    product,
    amount,
  };
}

export function confirmProducts(): OrderActions {
  return {
    type: '@order/CONFIRM_PRODUCT',
  };
}

export function setShipmentAddress(address: CustomerAddress | null): OrderActions {
  return {
    type: '@order/SET_SHIPMENT_ADDRESS',
    address,
  };
}

export function addCustomerAddress(address: CustomerAddress): OrderActions {
  return {
    type: '@order/ADD_CUSTOMER_ADDRESS',
    address,
  };
}

export function updateCustomerAddress(address: CustomerAddress): OrderActions {
  return {
    type: '@order/UPDATE_CUSTOMER_ADDRESS',
    address,
  };
}

export function updateAmount(uid: string, amount: number): OrderActions {
  return {
    type: '@order/UPDATE_AMOUNT',
    uid,
    amount,
  };
}

export function removeProduct(uid: string): OrderActions {
  return {
    type: '@order/REMOVE_PRODUCT',
    uid,
  };
}

export function updateProduct(product: OrderProduct, amount: number): OrderActions {
  return {
    type: '@order/UPDATE_PRODUCT',
    product,
    amount,
  };
}

export function setAnnotation(annotation: string): OrderActions {
  return {
    type: '@order/SET_ANNOTATION',
    annotation,
  };
}

export function setPaymentMethod(paymentMethod: PaymentMethod): OrderActions {
  return {
    type: '@order/SET_PAYMENT_METHOD',
    paymentMethod,
  };
}

export function updateComplement(product: OrderProduct, amount: number): OrderActions {
  return {
    type: '@order/UPDATE_COMPLEMENTS',
    product,
    amount,
  };
}

export function setConfigs(settings: RestaurantConfig): OrderActions {
  return {
    type: '@order/SET_CONFIGS',
    settings,
  };
}

export function setShipmentMethod(method: 'delivery' | 'customer_collect' | 'board'): OrderActions {
  return {
    type: '@order/SET_SHIPMENT_METHOD',
    method,
  };
}

export function updateTotal(shipmentMethod?: 'delivery' | 'customer_collect' | 'board'): OrderActions {
  return {
    type: '@order/UPDATE_TOTAL',
    shipmentMethod,
  };
}

export function setTax(tax: number): OrderActions {
  return {
    type: '@order/SET_TAX',
    tax,
  };
}

export function setSchedule(date: Date | null): OrderActions {
  return {
    type: '@order/SET_SCHEDULE',
    date,
  };
}

export function setBoardMovementId(boardMovementId: string): OrderActions {
  return {
    type: '@order/SET_BOARD_MOVEMENT_ID',
    boardMovementId,
  };
}

export function setOrderCustomerName(customerName: string): OrderActions {
  return {
    type: '@order/SET_ORDER_CUSTOMER_NAME',
    customerName,
  };
}
