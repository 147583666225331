import { FC, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useBoardControl } from '../../hooks/useBoardControl';
import BoardControlPaymentForm from './BoardControlPaymentForm';
import { api } from 'services/api';
import InsideSaving from 'components/loading/InsideSaving';
import { PaymentMethod } from 'types/paymentMethod';
import { useBoardPaymentValidation } from '../validation/useBoardPaymentValidation';
import BoardControlPaymentTabs, { BoardControlTabValues } from '../BoardControlPaymentTabs';
import BoardControlPayments from '../payments/BoardControlPayments';
import InsideLoading from 'components/loading/InsideLoading';
import BoardControlPaymentError from './BoardControlPaymentoError';
import Modal from 'components/modal/Modal';
import BoardControlPaymentActions from './BoardControlPaymentActions';
import { BoardMovementPayment } from 'types/boardMovementPayment';
import { addBoardPayment, removeBoardPayment } from '../../reducer/actions';

const styles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'space-between',
    padding: 20,
    flex: 1,
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {},
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 20,
  },
}));

interface BoardControlPaymentProps {
  onExited(): void;
}

export const BoardControlPayment: FC<BoardControlPaymentProps> = ({ onExited }) => {
  const classes = styles();
  const { selectedProducts, setSelectedProducts, movement, dispatch } = useBoardControl();
  const [value, setValue] = useState(movement ? movement.total - movement.totalPaid : 0);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [paymentMethodId, setPaymentMethodId] = useState<null | string>(null);
  const [validation, setValidation, validate] = useBoardPaymentValidation();
  const [tab, setTab] = useState<BoardControlTabValues>(movement?.isBoardPaid ? 'payments' : 'pay');
  const [error, setError] = useState('');

  const total = useMemo(
    () => selectedProducts.reduce((previousValue, product) => previousValue + product.final_price, 0),
    [selectedProducts]
  );

  useEffect(() => {
    api
      .get(`/payment-methods`, { params: { mode: 'offline', active: '1' } })
      .then(response => {
        setPaymentMethods(response.data);
        setPaymentMethodId(response.data[0].id);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!total) {
      return;
    }

    setValue(total);
  }, [total]);

  function handleValidation(closeDialog: () => void) {
    setValidation({});

    validate({ value, payment_method_id: paymentMethodId as any })
      .then(() => handleSubmit(closeDialog))
      .catch(err => console.error(err));
  }

  function handleAddPayment(payment: BoardMovementPayment) {
    dispatch(addBoardPayment(payment));
  }

  function handleSubmit(closeDialog: () => void) {
    setSaving(true);

    const orderProductIds = selectedProducts.length ? selectedProducts.map(product => product.id).join(',') : null;

    api
      .post(`/boardMovements/${movement?.id}/payments`, {
        value,
        payment_method_id: paymentMethodId,
        order_product_ids: orderProductIds,
      })
      .then(response => {
        handleAddPayment(response.data);
        closeDialog();
        setSelectedProducts([]);
      })
      .catch(err => {
        console.error(err);
        setError(err.response ? err.response.data.error : 'Não foi possível realizar o pagamento');
      })
      .finally(() => setSaving(false));
  }

  async function handleDeletePayment(paymentId: string): Promise<void> {
    await api.delete(`/boardMovements/${movement?.id}/payments/${paymentId}`);
    dispatch(removeBoardPayment(paymentId));
  }

  return (
    <Modal
      title="Pagamento"
      maxWidth="sm"
      onExited={onExited}
      componentActions={
        <BoardControlPaymentActions activeTab={tab} handleValidation={handleValidation} saving={saving} />
      }
      height="70vh"
    >
      {saving && <InsideSaving />}

      <div className={classes.container}>
        {error ? (
          <BoardControlPaymentError setError={setError} error={error} />
        ) : loading ? (
          <InsideLoading />
        ) : (
          <>
            <BoardControlPaymentTabs handleTabChange={value => setTab(value)} value={tab} />
            {tab === 'pay' ? (
              <BoardControlPaymentForm
                paymentMethodId={paymentMethodId}
                setPaymentMethodId={setPaymentMethodId}
                paymentMethods={paymentMethods}
                value={value}
                setValue={setValue}
                validation={validation}
              />
            ) : (
              <BoardControlPayments handleDeletePayment={handleDeletePayment} />
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default BoardControlPayment;
