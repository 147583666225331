import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import CustomAppbar from 'components/appbar/Appbar';
import InsideLoading from 'components/loading/InsideLoading';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import FiscalSettingsTab, { FiscalSettingsTabOptions } from './FiscalSettingsTab';
import FiscalSettingsAppbarButtons from './FiscalSettingsAppbarButtons';
import SatSettingsForm from './sat/SatSettingsForm';
import history from 'services/history';
import { useFetchFiscalSettings } from './hooks/useFetchFiscalSettings';
import { FiscalSettingSat, FiscalSettingsNfce } from './types/fiscal-setting';
import { useFetchPrinters } from 'pages/products/hooks/useFetchPrinters';
import { useFiscalSettingsValidation } from './validation/useFiscalSettingsValidation';
import NfceSettingsForm from './nfce/NfceSettingsForm';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    marginTop: 15,
    marginBottom: 20,
    maxWidth: 500,
  },
});

const FiscalSettings: React.FC = () => {
  const classes = useStyles();
  const [settings, setSettings, loading] = useFetchFiscalSettings();
  const [saving, setSaving] = useState(false);
  const messaging = useMessaging();
  const [tab, setTab] = useState<FiscalSettingsTabOptions>('sat');
  const [printers, , printersLoading] = useFetchPrinters();
  const [validation, setValidation, validate] = useFiscalSettingsValidation();

  function handleSatSettingsChange(index: keyof FiscalSettingSat, value: any) {
    setSettings(state => ({
      ...state,
      sat: {
        ...state.sat,
        [index]: value,
      },
    }));
  }

  function handleNfceSettingsChange(index: keyof FiscalSettingsNfce, value: any) {
    setSettings(state => ({
      ...state,
      nfce: {
        ...state.nfce,
        [index]: value,
      },
    }));
  }

  function handleValidate() {
    setValidation({});

    validate(settings)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);
    api
      .put('/fiscal-settings', settings)
      .then(() => {
        messaging.handleOpen('Salvo');
      })
      .catch(err => {
        console.error(err);
        messaging.handleOpen('Não foi possível salvar');
      })
      .finally(() => setSaving(false));
  }

  return (
    <div className={classes.container}>
      {saving && <Loading />}
      <CustomAppbar
        ActionComponents={
          <FiscalSettingsAppbarButtons handleSubmit={handleValidate} loading={loading} saving={saving} />
        }
        title="Configurações fiscais"
        Tab={<FiscalSettingsTab onChange={tab => setTab(tab)} tab={tab} />}
      />
      <PageHeader
        title="Configurações fiscais"
        backAction={() => history.back}
        description="Configurações para emissão de documentos fiscais"
      />
      {loading || printersLoading ? (
        <InsideLoading />
      ) : (
        <div className={classes.content}>
          {tab === 'sat' ? (
            <SatSettingsForm
              validation={validation}
              printers={printers}
              handleChange={handleSatSettingsChange}
              settings={settings}
            />
          ) : tab === 'nfce' ? (
            <NfceSettingsForm
              validation={validation}
              printers={printers}
              handleChange={handleNfceSettingsChange}
              settings={settings}
            />
          ) : (
            <div />
          )}
        </div>
      )}
    </div>
  );
};

export default FiscalSettings;
