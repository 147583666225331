import React, { useMemo, useState, useEffect } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import OrderAction from './OrderAction';
import {
  addProducts,
  removeProduct,
  setPaymentMethod,
  setAnnotation,
  updateComplement,
  updateProduct,
  setChange,
  setCustomer,
  setShipmentMethod,
  setShipmentAddress,
  setBoardMovementId,
  setOrderInitialState,
} from 'store/redux/modules/order/actions';
import PageHeader from 'components/page-header/PageHeader';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Loading from 'components/loading/Loading';
import { api, getCancelTokenSource } from 'services/api';
import history from 'services/history';
import { useDispatch } from 'react-redux';
import { useMessaging } from 'hooks/messaging';
import { useSelector } from 'store/redux/selector';
import { OrderContextProvider, OrderContextValue } from '../hooks/useOrder';
import Category from '../steps/category/Category';
import Products from '../steps/products/Products';
import OrderStatus from '../status/OrderStatus';
import OrderButtons from '../OrderButtons';
import { useQuery } from 'hooks/useQueryParams';
import { steps } from '../steps';
import { Category as CategoryType } from 'types/category';
import { OrderProduct } from 'types/order';
import OrderError from '../OrderError';
import { useFetchBoardMovement } from '../../board/hooks/useFetchBoardMovement';
import OrderResume from '../resume/OrderResume1';
import SearchProducts from '../steps/products/SearchProducts';
import { PaymentMethod } from 'types/paymentMethod';
import OrderCustomer from './customer/OrderCustomer';
import { getOrderDataToSubmit } from 'pages/orders/registration/new/getOrderDataToSubmit';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flex: 1,
  },
  step: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    width: 25,
    height: 25,
    borderRadius: '50%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    marginRight: 10,
    border: `2px solid ${theme.palette.primary.dark}`,
  },
  currentStep: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
  },
  steps: {
    padding: '10px 0',
    display: 'flex',
    alignItems: 'center',
  },
  contentStep: {
    display: 'flex',
    padding: '15px 0',
    borderRadius: 4,
    flexDirection: 'column',
    marginTop: 10,
    width: 'calc(100% - 400px)',
    marginBottom: 50,
    [theme.breakpoints.down('md')]: {
      padding: '30px 0px',
      border: 'none',
      marginTop: 0,
      width: '100%',
    },
  },
  orderStatus: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    position: 'fixed',
    right: 0,
    top: 0,
    bottom: 0,
    width: 400,
    backgroundColor: '#fff',
    padding: 15,
    marginTop: 60,
    borderLeft: '1px solid #eee',
    overflow: 'scroll',
  },
}));

const Order: React.FC = () => {
  const classes = useStyles();
  const order = useSelector(state => state.order);
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [dialogResumeOrder, setDialogResumeOrder] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const { handleOpen } = useMessaging();
  const restaurant = useSelector(state => state.restaurant);
  const [step, setStep] = useState(1);
  const queryParams = useQuery();
  const [error, setError] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<CategoryType | null>(null);
  const customerId = queryParams.get('customer_id');
  const boardMovementId = queryParams.get('board');
  const [, movement] = useFetchBoardMovement(undefined, boardMovementId);
  const [showSearchDialog, setShowSearchDialog] = useState(false);
  const [showCustomerDialog, setShowCustomerDialog] = useState(false);

  const orderContextValue: OrderContextValue = {
    handleNext,
    handlePrior,
    handleAddProduct,
    handleUpdateProduct,
    handleRemoveProduct,
    handleUpdateComplements,
    handleSetChange,
    handleAddCategory,
    handleSetAnnotation,
    handleSetPaymentMethod,
    loadingCategories,
    categories,
    paymentMethods,
    step,
    selectedCategory,
    handleSubmit,
    saving,
    setShowSearchDialog,
    setStep,
  };

  useEffect(() => {
    return () => {
      dispatch(setOrderInitialState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!boardMovementId) {
      return;
    }

    dispatch(setBoardMovementId(boardMovementId));
  }, [boardMovementId, dispatch]);

  useEffect(() => {
    if (!restaurant) {
      return;
    }

    const mainRestaurantAddress = restaurant?.addresses.find(address => address.is_main);

    if (!mainRestaurantAddress) {
      return;
    }

    dispatch(setShipmentAddress(mainRestaurantAddress as any));
    dispatch(setShipmentMethod('board'));
  }, [dispatch, restaurant]);

  useEffect(() => {
    api
      .get(`/customers/${customerId}`)
      .then(response => {
        dispatch(setCustomer(response.data));
      })
      .catch(() => {
        console.log('Não foi possível carregar os dados do cliente');
      });
  }, [dispatch, customerId]);

  useEffect(() => {
    let request = true;
    const source = getCancelTokenSource();

    api
      .get('order/categories', { cancelToken: source.token })
      .then(response => {
        if (!request) {
          return;
        }

        setCategories(response.data);
      })
      .catch(() => {
        if (!request) {
          return;
        }

        setError('Não foi possível carregar as categorias');
      })
      .finally(() => {
        if (request) {
          setLoadingCategories(false);
        }
        request = false;
      });

    return () => {
      if (request) source.cancel();
      request = false;
    };
  }, [handleOpen, dispatch]);

  useEffect(() => {
    const source = getCancelTokenSource();
    let request = true;

    api
      .get('order/paymentMethods', { cancelToken: source.token })
      .then(response => {
        if (!request) {
          return;
        }
        setPaymentMethods(response.data);

        if (response.data.length > 0) {
          dispatch(setPaymentMethod(response.data[0]));
        }
      })
      .catch(() => {
        if (request) {
          setError('Não foi possível carregar as formas de pgamento');
        }
      })
      .finally(() => {
        request = false;
      });

    return () => {
      if (request) source.cancel();
      request = false;
    };
  }, [dispatch]);

  function handleNext() {
    if (step === 2 && !checkIfOrderHasProducts()) {
      handleOpen('Você precisa adicionar produtos ao pedidos');
      return;
    }

    setStep(step + 1);
  }

  function handlePrior() {
    if (step === 1) {
      history.back();
      return;
    }

    setStep(step - 1);
  }

  function handleSetStep(step: number) {
    if (step === 2) setSelectedCategory(null);
    setStep(step);
  }

  function handleAddCategory(category: CategoryType) {
    setSelectedCategory(category);

    handleNext();
  }

  function handleAddProduct(product: OrderProduct, amount: number = 1) {
    dispatch(addProducts(product, amount));
  }

  function checkIfOrderHasProducts() {
    return order.products.length > 0;
  }

  function handleRemoveProduct(uid: string) {
    dispatch(removeProduct(uid));
  }

  function handleSetAnnotation(annotation: string) {
    dispatch(setAnnotation(annotation));
  }

  function handleSetPaymentMethod(paymentMethod: PaymentMethod) {
    dispatch(setPaymentMethod(paymentMethod));
  }

  function handleUpdateComplements(product: OrderProduct, amount: number) {
    dispatch(updateComplement(product, amount));
  }

  function handleUpdateProduct(product: OrderProduct, amount: number) {
    dispatch(updateProduct(product, amount));
  }

  function handleSetChange(value: number) {
    dispatch(setChange(value));
  }

  function formatOrderBeforeCreate(customerName?: string) {
    const data = getOrderDataToSubmit(order);

    if (!customerName) {
      return data;
    }

    return {
      ...data,
      customer_name: customerName,
      customer: {
        name: customerName,
      } as any,
    };
  }

  function handleSubmit(customerName: string) {
    setSaving(true);

    const data = formatOrderBeforeCreate(customerName);

    api
      .post('/orders', data)
      .then(response => {
        if (response.status === 200) {
          handleOpen('Salvo');
        }

        history.push(`/board-management`);
      })
      .catch(err => {
        if (err.response) handleOpen('Não foi possível salvar o pedido');
        else handleOpen(err.message);
        setSaving(false);
      });
  }

  const currentStep = useMemo(() => {
    return steps.find(item => item.order === step);
  }, [step]);

  return (
    <OrderContextProvider value={orderContextValue}>
      {saving && <Loading />}

      <CustomAppbar
        title="pedido de mesa"
        ActionComponents={<OrderAction setDialogResumeOrder={() => setDialogResumeOrder(!dialogResumeOrder)} />}
      />

      <PageHeader
        backAction={handlePrior}
        title={movement?.customer ? `Pedido de ${movement?.customer?.name}` : 'Novo pedido'}
        description={movement?.board ? `Mesa ${movement?.board?.number}` : ''}
      />

      {dialogResumeOrder && <OrderResume onExited={() => setDialogResumeOrder(!dialogResumeOrder)} />}

      {showSearchDialog && <SearchProducts onExited={() => setShowSearchDialog(false)} />}

      {showCustomerDialog && <OrderCustomer onExited={() => setShowCustomerDialog(false)} />}

      <div className={classes.currentStep}>
        <span className={classes.step}>{currentStep?.order}</span>
        <Typography>{currentStep?.description}</Typography>
      </div>

      <div className={classes.container}>
        <div className={classes.contentStep}>
          {error ? (
            <OrderError errorText={error} handleResetError={() => setError('')} />
          ) : step === 1 ? (
            <Category />
          ) : (
            step === 2 && <Products />
          )}

          {order.products.length > 0 && <OrderButtons setShowCustomerDialog={setShowCustomerDialog} />}
        </div>
      </div>

      <div className={classes.orderStatus}>
        <OrderStatus
          handleSetStep={handleSetStep}
          setDialogResumeOrder={() => setDialogResumeOrder(!dialogResumeOrder)}
        />
      </div>
    </OrderContextProvider>
  );
};

export default Order;
