import { Dispatch, useReducer } from 'react';
import { Customer } from 'types/customer';
import { CustomerActions } from './types';

export const INITIAL_STATE: Customer = {
  id: 0,
  user_id: 0,
  name: '',
  phone: '',
  email: '',
  cpf: '',
  birthday: '',
  gender: 'm',
  addresses: [],
  created_at: '',
  formattedCreatedAt: '',
  orders: [],
};

function customerReducer(state = INITIAL_STATE, action: CustomerActions): Customer {
  switch (action.type) {
    case 'SET_CUSTOMER': {
      return action.customer;
    }

    case 'CUSTOMER_CHANGE': {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case 'SET_CUSTOMER_INITIAL_STATE': {
      return INITIAL_STATE;
    }

    case 'ADD_CUSTOMER_ADDRESS': {
      return {
        ...state,
        addresses: [...state.addresses, action.address],
      };
    }

    case 'UPDATE_CUSTOMER_ADDRESS': {
      return {
        ...state,
        addresses: state.addresses.map(address => (address.id === action.address.id ? action.address : address)),
      };
    }

    case 'SET_MAIN_CUSTOMER_ADDRESS': {
      return {
        ...state,
        addresses: state.addresses.map(address => {
          address.is_main = address.id === action.addressId;
          return address;
        }),
      };
    }

    case 'DELETE_CUSTOMER_ADDRESS': {
      return {
        ...state,
        addresses: state.addresses.filter(address => address.id !== action.addressId),
      };
    }

    default: {
      return state;
    }
  }
}

type UseCustomerReducer = [Customer, Dispatch<CustomerActions>];

export function useCustomerReducer(): UseCustomerReducer {
  const [customer, dispatch] = useReducer(customerReducer, INITIAL_STATE);
  return [customer, dispatch];
}
