import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import PaginationProvider from 'hooks/pagination';
import ApiPagination from 'components/_pagination/ApiPagination';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/nodata/NoData';
import FiscalSerialListTable from './list/table/FiscalSerialListTable';
import TaxRuleListModule from './list/module/FiscalSerialListModule';
import useTableOrder from 'hooks/tableOrder';
import FiscalSerialsFilterBox from './FiscalSerialsFilterBox';
import { useApp } from 'App';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import FiscalSerialsPageHeaderButtons from './FiscalSerialsPageHeaderButtons';
import { FiscalSerialOnList } from './types/fiscal-serial';
import { FiscalSerialProvider } from './hooks/useFiscalSerials';
import { useFetchFiscalSerials } from './hooks/useFetchFiscalSerials';
import { api } from 'services/api';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import Loading from 'components/loading/Loading';
import { DisableSequencePayload } from './types/disable-sequence-payload';
import DisableSequenceModal from './disable-sequence/DisableSequenceModal';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

export type TaxRulesQueryParams = {
  page: number;
  rows: number;
};

let timer: NodeJS.Timeout;

const FiscalSerials: React.FC = () => {
  const classes = styles();
  const { serials, fetch, total, loading, setQueryParams, queryParams, setSerials } = useFetchFiscalSerials();
  const [selectedSerial, setSelectedSerial] = useState<FiscalSerialOnList | null>(null);
  const [filtered, setFiltered] = useState<FiscalSerialOnList[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [term, setTerm] = useState('');
  const app = useApp();
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>(app.isMobile ? 'module' : 'list');
  const { showErrorDialog } = useErrorHandler();
  const [saving, setSaving] = useState(false);
  const [disableSequenceModal, setDisableSequenceModal] = useState(false);

  useEffect(() => {
    setFiltered(serials);
  }, [serials]);

  useEffect(() => {
    fetch('');
  }, [fetch]);

  useEffect(() => {
    if (app.windowWidth >= 930) {
      setDisplayMode('list');
      return;
    }

    setDisplayMode('module');
  }, [app.isMobile, app.windowWidth]);

  function handleParamsChange(index: keyof TaxRulesQueryParams, value: string | number) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSearch(value: string) {
    setTerm(value);

    clearTimeout(timer);

    timer = setTimeout(() => fetch(value), 500);
  }

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleActive() {
    if (!selectedSerial) {
      return;
    }
    setSaving(true);

    api
      .patch(`/fiscal-serials/${selectedSerial?.id}/active`)
      .then(response => {
        setSerials(state =>
          state.map(serial => {
            if (selectedSerial.id === serial.id) {
              serial.active = true;
              serial.formattedActive = 'Sim';
              return serial;
            }

            serial.active = false;
            serial.formattedActive = 'Não';
            return serial;
          })
        );
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível atualiar o registro de série',
        });
      })
      .finally(() => setSaving(false));
  }

  async function handleDisableSequence(payload: DisableSequencePayload): Promise<void> {
    await api.post(`fiscal-serials/${selectedSerial?.id}/disable-sequences`, payload);
  }

  return (
    <FiscalSerialProvider
      value={{
        setSelectedSerial,
        selectedSerial,
        handleActive,
        handleDisableSequence,
        setDisableSequenceModal,
      }}
    >
      {saving && <Loading />}

      {disableSequenceModal && <DisableSequenceModal onExited={() => setDisableSequenceModal(false)} />}

      <div className={classes.container}>
        <Appbar title="Séries fiscais" />

        <PageHeaderActions
          title="Séries fiscais"
          description="Gerenciador de séries fiscais para emissão de CF-e e NFC-e"
          ActionComponent={<FiscalSerialsPageHeaderButtons />}
        />

        <FiscalSerialsFilterBox
          displayMode={displayMode}
          setDisplayMode={setDisplayMode}
          params={queryParams}
          handleParamsChange={handleParamsChange}
          handleSearch={handleSearch}
          term={term}
        />

        <PaginationProvider>
          {loading ? (
            displayMode === 'list' ? (
              <TableLoading />
            ) : (
              <ModuleLoading />
            )
          ) : filtered.length === 0 ? (
            <NoData message="Nenhum cupom para mostrar" />
          ) : (
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <FiscalSerialListTable serials={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <TaxRuleListModule serials={filtered} />
              )}
            </div>
          )}

          <ApiPagination
            onChangePage={value => handleParamsChange('page', value)}
            onChangeRowsPerPage={value => handleParamsChange('rows', value)}
            count={total}
          />
        </PaginationProvider>
      </div>
    </FiscalSerialProvider>
  );
};

export default FiscalSerials;
