import { List, makeStyles } from '@material-ui/core';
import React from 'react';
import { Product } from 'types/product';
import ProductManagerItem from './ProductManagerItem';

const useStyles = makeStyles({
  list: {
    flex: 1,
    display: 'grid',
    gap: '10px',
    alignItems: 'flex-start',
    maxWidth: 800,
  },
});

interface ProductManagerListProps {
  products: Product[];
}

const ProductManagerList: React.FC<ProductManagerListProps> = ({ products }) => {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      {products.map(product => (
        <ProductManagerItem key={product.id} product={product} />
      ))}
    </List>
  );
};

export default ProductManagerList;
