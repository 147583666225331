import { format, parseISO } from 'date-fns';
import ptbr from 'date-fns/locale/pt-BR';
import { moneyFormat } from 'helpers/NumberFormat';
import { Product, ComplementCategory, Complement } from 'types/product';
import * as types from './types';
import { Category } from 'types/category';
import { v4 as uuid } from 'uuid';

export const INITIAL_STATE: Product = {
  id: new Date().getTime(),
  encrypted_id: '',
  name: '',
  description: '',
  url: '',
  category: {} as Category,
  image: null,
  price: 0,
  formattedPrice: 'R$ 0,00',
  special_price: 0,
  formattedSpecialPrice: 'R$ 0,00',
  promotion_activated: false,
  photoSelected: false,
  ingredients: [],
  additional: [],
  complement_categories: [],
  step: 1,
  activated: true,
  annotation: '',
  no_tax: false,
  availability: [
    { day: 0, status: true },
    { day: 1, status: true },
    { day: 2, status: true },
    { day: 3, status: true },
    { day: 4, status: true },
    { day: 5, status: true },
    { day: 6, status: true },
  ],
  availability_hours: {
    start_at: null,
    end_at: null,
  },
  printer: null,
  formattedDate: '',
  created_at: '',
  is_available_on_board: true,
  is_available_on_delivery: true,
  cadence_quantity: 1,
  formattedIsAvailableOnBoard: 'Sim',
  formattedIsAvailableOnDelivery: 'Sim',
  cest_code: '',
  measurement_unit: '',
  ncm_code: '',
  ean13: '',
  tax_rule_id: '',
};

export default function product(state = INITIAL_STATE, action: types.ProductActionsType): Product {
  function arraymove(array: ComplementCategory[], from: number, to: number) {
    const newArray = array.slice();

    const tmp = array[from];
    newArray[from] = newArray[to];
    newArray[to] = tmp;

    return newArray;
  }

  switch (action.type) {
    case types.SET_CATEGORY:
      return {
        ...state,
        category: action.category,
        price: 0,
      };

    case types.PRODUCT_CHANGE:
      if (action.index === 'image') {
        return {
          ...state,
          photoSelected: false,
          [action.index]: action.value,
        };
      } else if (action.index === 'name') {
        return {
          ...state,
          [action.index]: action.value,
          url: action.value
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/\s/g, '-'),
        };
      } else if (action.index === 'price') {
        return {
          ...state,
          price: action.value,
          formattedPrice: moneyFormat(action.value),
        };
      }
      return {
        ...state,
        [action.index]: action.value,
      };

    case types.SET_PRODUCT:
      return {
        ...state,
        ...action.product,
        availability: action.product.availability.length === 0 ? state.availability : action.product.availability,
        availability_hours: action.product.availability_hours
          ? action.product.availability_hours
          : state.availability_hours,
        formattedPrice: moneyFormat(action.product.price),
        formattedDate:
          action.product.created_at &&
          format(parseISO(action.product.created_at), "PP 'às' p", {
            locale: ptbr,
          }),
      };

    case types.IMPORT_PRODUCT: {
      return {
        ...state,
        ...action.product,
        complement_categories: action.product.complement_categories.map(category => ({
          ...category,
          id: uuid(),
          complements: category.complements.map(complement => ({
            ...complement,
            id: uuid(),
            prices: complement.prices.map(price => ({
              ...price,
              id: uuid(),
            })),
          })),
        })),
        availability: action.product.availability.length === 0 ? state.availability : action.product.availability,
        availability_hours: action.product.availability_hours
          ? action.product.availability_hours
          : state.availability_hours,
        formattedPrice: moneyFormat(action.product.price),
        formattedDate:
          action.product.created_at &&
          format(parseISO(action.product.created_at), "PP 'às' p", {
            locale: ptbr,
          }),
      };
    }

    case types.PHOTO_SELECT:
      return {
        ...state,
        photoSelected: !state.photoSelected,
      };

    case types.SET_PRODUCT_INGREDIENTS:
      return {
        ...state,
        ingredients: action.ingredients,
      };

    case types.SET_PRODUCT_ADDITIONAL:
      return {
        ...state,
        additional: action.additional,
      };

    case types.SET_STEP: {
      return {
        ...state,
        step: action.step,
      };
    }

    case types.AVAILABILITY_CHANGE: {
      const availability = state.availability.map(item =>
        item.day === action.day ? { ...item, status: action.status } : item
      );

      return {
        ...state,
        availability,
      };
    }

    case types.AVAILABILITY_HOURS_CHANGE: {
      return {
        ...state,
        availability_hours: {
          ...state.availability_hours,
          [action.index]: action.value,
        },
      };
    }

    case types.SET_IS_PIZZA: {
      const categories: ComplementCategory[] = [
        {
          id: 1,
          name: 'Tamanhos',
          print_name: 'Tamanhos',
          min_quantity: 1,
          max_quantity: 1,
          is_required: true,
          is_pizza_size: true,
          is_pizza_taste: false,
          order_by_name: false,
          complements: [
            {
              id: 1,
              name: 'Broto',
              description: '',
              price: null,
              is_activated: true,
              taste_amount: 1,
              selected: false,
              image: null,
              prices: [],
              additional: [],
              ingredients: [],
              new: true,
            },
            {
              id: 2,
              name: 'Grande',
              description: '',
              price: null,
              is_activated: true,
              taste_amount: 1,
              selected: false,
              image: null,
              prices: [],
              additional: [],
              ingredients: [],
              new: true,
            },
          ],
        },
        {
          id: 2,
          name: 'Sabores',
          print_name: 'Sabores',
          min_quantity: 1,
          max_quantity: 1,
          is_required: true,
          order_by_name: false,
          complements: [],
          is_pizza_taste: true,
          is_pizza_size: false,
        },
      ];

      return {
        ...state,
        complement_categories: categories,
      };
    }

    case types.ADD_COMPLEMENT_CATEGORY: {
      return {
        ...state,
        complement_categories: [
          ...state.complement_categories,
          {
            id: new Date().getTime(),
            name: '',
            print_name: '',
            min_quantity: 0,
            max_quantity: 1,
            order_by_name: false,
            is_required: false,
            complements: [],
            is_pizza_size: false,
            is_pizza_taste: false,
          },
        ],
      };
    }

    case types.ADD_COMPLEMENT: {
      const index = state.complement_categories.findIndex(category => category.id === action.complementCategoryId);
      let categories = state.complement_categories.slice();
      const categoryPizzaSize = state.complement_categories.find(category => category.is_pizza_size);

      let complement: Complement;

      if (categories[index].is_pizza_size) {
        complement = {
          id: new Date().getTime(),
          name: '',
          description: '',
          price: null,
          is_activated: true,
          taste_amount: 1,
          selected: false,
          image: null,
          prices: [],
          additional: [],
          ingredients: [],
          new: true,
        };

        // ajusta campos preços nos complementos e adicionais
        categories = categories.map(category => {
          if (!category.is_pizza_size)
            category.complements = category.complements.map(_complement => {
              if (category.is_pizza_taste) {
                _complement.additional.map(additional => {
                  additional.prices.push({
                    id: new Date().getTime(),
                    product_complement_size_id: complement.id ?? null,
                    product_complement_additional_price_id: null,
                    price: null,
                    name: complement.name,
                  });
                  return additional;
                });
              }
              _complement.prices.push({
                id: new Date().getTime(),
                product_complement_size_id: complement.id,
                price: null,
                name: complement.name,
              });
              return _complement;
            });
          return category;
        });
      } else if (action.isPizza && categoryPizzaSize) {
        complement = {
          id: new Date().getTime(),
          name: '',
          description: '',
          prices: categoryPizzaSize.complements.map((complement, index) => {
            return {
              id: index,
              product_complement_size_id: complement.id,
              name: complement.name,
              price: null,
            };
          }),
          is_activated: true,
          ingredients: [],
          additional: [],
          image: null,
          selected: false,
          price: null,
          taste_amount: 0,
          new: true,
        };
      } else
        complement = {
          id: new Date().getTime(),
          name: '',
          description: '',
          price: null,
          is_activated: true,
          selected: false,
          additional: [],
          ingredients: [],
          image: null,
          prices: [],
          taste_amount: 0,
          new: true,
          product_complement_price_id: new Date().getTime(),
        };

      categories[index] = {
        ...categories[index],
        complements: [...categories[index].complements, complement],
      };

      return {
        ...state,
        complement_categories: categories,
      };
    }

    case types.DELETE_COMPLEMENT_CATEGORY: {
      const categories = state.complement_categories.filter(category => category.id !== action.complementCategoryId);
      return {
        ...state,
        complement_categories: categories,
      };
    }

    case types.DELETE_COMPLEMENT: {
      let categories = state.complement_categories.map(category => {
        if (category.id === action.complementCategoryId)
          category.complements = category.complements.filter(complement => complement.id !== action.complementId);
        return category;
      });

      if (action.isPizza) {
        const categoryOfComplementDeleted = state.complement_categories.find(
          category => category.id === action.complementCategoryId
        );
        if (categoryOfComplementDeleted?.is_pizza_size) {
          categories = categories.map(category => {
            if (!category.is_pizza_size)
              category.complements = category.complements.map(complement => {
                complement.prices = complement.prices.filter(
                  price => price.product_complement_size_id !== action.complementId
                );
                if (category.is_pizza_taste)
                  complement.additional = complement.additional.map(additional => {
                    additional.prices = additional.prices.filter(
                      price => price.product_complement_size_id !== action.complementId
                    );
                    return additional;
                  });
                return complement;
              });
            return category;
          });
        }
      }

      return {
        ...state,
        complement_categories: categories,
      };
    }

    case types.CHANGE_COMPLEMENT_CATEGORY: {
      const categories = state.complement_categories.map(category => {
        if (category.id === action.complementCategoryId) {
          if (action.index === 'is_required')
            return {
              ...category,
              is_required: !!action.value,
              min_quantity: action.value ? 1 : 0,
            };
          else {
            return {
              ...category,
              [action.index]: action.value,
            };
          }
        }
        return category;
      });

      return {
        ...state,
        complement_categories: categories,
      };
    }

    case types.CHANGE_COMPLEMENT: {
      let categories = state.complement_categories.map(category => {
        if (category.id === action.complementCategoryId) {
          category.complements = category.complements.map(complement => {
            if (complement.id === action.complementId) {
              return {
                ...complement,
                [action.index]: action.value,
              };
            }
            return complement;
          });
          return category;
        }
        return category;
      });

      const category = categories.find(category => category.id === action.complementCategoryId);
      if (!category)
        return {
          ...state,
          complement_categories: categories,
        };

      const complementSizeEdited = category.complements.find(complement => complement.id === action.complementId);
      if (!complementSizeEdited) return state;

      // set nome nos campos preços dos complementos, quando nome do complemento de tamanho de pizza é alterado
      if (category?.is_pizza_size && action.index === 'name') {
        categories = categories.map(category => {
          if (!category.is_pizza_size)
            category.complements = category.complements.map(complement => {
              complement.prices = complement.prices.map(price => {
                if (price.product_complement_size_id === action.complementId) price.name = complementSizeEdited.name;
                return price;
              });
              if (category.is_pizza_taste)
                complement.additional = complement.additional.map(additional => {
                  additional.prices = additional.prices.map(price => {
                    if (price.product_complement_size_id === action.complementId)
                      price.name = complementSizeEdited.name;
                    return price;
                  });
                  return additional;
                });
              return complement;
            });
          return category;
        });
      }

      return {
        ...state,
        complement_categories: categories,
      };
    }

    case types.ADD_COMPLEMENT_ADDITIONAL: {
      const categoryPizzaSize = state.complement_categories.find(category => category.is_pizza_size);

      const categories = state.complement_categories.map(category => {
        if (category.id === action.complementCategoryId) {
          category.complements = category.complements.map(complement => {
            if (categoryPizzaSize && complement.id === action.complementId)
              complement.additional = [
                ...complement.additional,
                {
                  id: new Date().getTime(),
                  product_complement_size_id: complement.id ?? null,
                  product_complement_additional_id: null,
                  name: '',
                  prices: categoryPizzaSize.complements.map((_complement, index) => {
                    return {
                      id: index,
                      product_complement_size_id: _complement.id ?? null,
                      price: null,
                      name: _complement.name,
                      product_complement_additional_price_id: null,
                    };
                  }),
                },
              ];
            return complement;
          });
          return category;
        }
        return category;
      });

      return {
        ...state,
        complement_categories: categories,
      };
    }

    case types.ADD_COMPLEMENT_INGREDIENT: {
      const categories = state.complement_categories.map(category => {
        if (category.id === action.complementCategoryId)
          category.complements = category.complements.map(complement => {
            if (complement.id === action.complementId)
              complement.ingredients = [
                ...complement.ingredients,
                {
                  id: new Date().getTime(),
                  name: '',
                },
              ];
            return complement;
          });
        return category;
      });

      return {
        ...state,
        complement_categories: categories,
      };
    }

    case types.DELETE_COMPLEMENT_ADDITIONAL: {
      const categories = state.complement_categories.map(category => {
        if (category.id === action.complementCategoryId)
          category.complements = category.complements.map(complement => {
            if (complement.id === action.complementId)
              complement.additional = complement.additional.filter(additional => additional.id !== action.additionalId);
            return complement;
          });

        return category;
      });

      return {
        ...state,
        complement_categories: categories,
      };
    }

    case types.DELETE_COMPLEMENT_INGREDIENT: {
      const categories = state.complement_categories.map(category => {
        if (category.id === action.complementCategoryId)
          category.complements = category.complements.map(complement => {
            if (complement.id === action.complementId)
              complement.ingredients = complement.ingredients.filter(
                ingredient => ingredient.id !== action.ingredientId
              );
            return complement;
          });

        return category;
      });

      return {
        ...state,
        complement_categories: categories,
      };
    }

    case types.CHANGE_COMPLEMENT_ADDITIONAL: {
      const categories = state.complement_categories.map(category => {
        if (category.id === action.complementCategoryId)
          category.complements = category.complements.map(complement => {
            if (complement.id === action.complementId)
              complement.additional = complement.additional.map(additional => {
                if (additional.id === action.additionalId) additional.name = action.name;
                return additional;
              });
            return complement;
          });
        return category;
      });

      return {
        ...state,
        complement_categories: categories,
      };
    }

    case types.CHANGE_COMPLEMENT_INGREDIENT: {
      const categories = state.complement_categories.map(category => {
        if (category.id === action.complementCategoryId)
          category.complements = category.complements.map(complement => {
            if (complement.id === action.complementId)
              complement.ingredients = complement.ingredients.map(ingredient => {
                if (ingredient.id === action.ingredientId) ingredient.name = action.name;
                return ingredient;
              });
            return complement;
          });
        return category;
      });

      return {
        ...state,
        complement_categories: categories,
      };
    }

    case types.CHANGE_COMPLEMENT_PRICE: {
      const categories = state.complement_categories.map(category => {
        if (category.id === action.complementCategoryId)
          category.complements = category.complements.map(complement => {
            if (complement.id === action.complementId)
              complement.prices = complement.prices.map(price => {
                if (price.id === action.priceId) price.price = action.value;
                return price;
              });
            return complement;
          });
        return category;
      });

      return {
        ...state,
        complement_categories: categories,
      };
    }

    case types.CHANGE_COMPLEMENT_ADDITIONAL_PRICE: {
      const categories = state.complement_categories.map(category => {
        if (category.id === action.complementCategoryId)
          category.complements = category.complements.map(complement => {
            if (complement.id === action.complementId)
              complement.additional = complement.additional.map(additional => {
                if (additional.id === action.additionalId)
                  additional.prices = additional.prices.map(price => {
                    if (price.id === action.priceId) price.price = action.value;
                    return price;
                  });
                return additional;
              });
            return complement;
          });
        return category;
      });

      return {
        ...state,
        complement_categories: categories,
      };
    }

    case types.IMPORT_COMPLEMENT_ADDITIONAL: {
      const category = state.complement_categories.find(category => category.is_pizza_taste);
      if (!category) return state;

      const fromComplement = category.complements.find(complement => complement.id === action.fromComplementId);
      if (!fromComplement) return state;

      const additionalToImport = fromComplement.additional;

      const categories = state.complement_categories.map(category => {
        category.complements.map(complement => {
          if (complement.id === action.toComplementId) {
            complement.additional = additionalToImport;
            return complement;
          }
          return complement;
        });
        return category;
      });

      return {
        ...state,
        complement_categories: categories,
      };
    }

    case types.IMPORT_COMPLEMENT_INGREDIENTS: {
      const category = state.complement_categories.find(category => category.is_pizza_taste);
      if (!category) return state;

      const fromComplement = category.complements.find(complement => complement.id === action.fromComplementId);
      if (!fromComplement) return state;

      const ingredientsToImport = fromComplement.ingredients;

      const categories = state.complement_categories.map(category => {
        category.complements.map(complement => {
          if (complement.id === action.toComplementId) {
            complement.ingredients = ingredientsToImport;
            return complement;
          }
          return complement;
        });
        return category;
      });

      return {
        ...state,
        complement_categories: categories,
      };
    }

    case types.CATEGORY_COMPLEMENTS_REORDER: {
      const categories = arraymove(state.complement_categories, action.from, action.to);

      return {
        ...state,
        complement_categories: categories,
      };
    }

    case types.CHANGE_COMPLEMENT_STATUS: {
      const categories = state.complement_categories.map(category => {
        if (category.id === action.complementCategoryId)
          category.complements = category.complements.map(complement => {
            if (complement.id === action.complementId) complement.is_activated = !complement.is_activated;
            return complement;
          });
        return category;
      });

      return {
        ...state,
        complement_categories: categories,
      };
    }

    default:
      return state;
  }
}
