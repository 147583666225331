import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useSelector } from 'store/redux/selector';
import { useOrders } from '../hook/useOrders';
import { useApp } from 'App';

type OrderListMenuProps = {
  setAnchorEl(el: HTMLButtonElement | null): void;
  anchorEl: HTMLButtonElement | null;
};

const OrderListMenu: React.FC<OrderListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const {
    selectedOrder,
    handleCancel,
    handleOrderStatus,
    setDialogPrintOrder,
    setDialogPrintShipment,
    setDialogPrintOnlyShipment,
    sendToPrintOrder,
    sendToPrintShipment,
    setDialogTrack,
    setDialogDeliverer,
    handleCreateReceipt,
  } = useOrders();
  const restaurant = useSelector(state => state.restaurant);
  const { isPrinterConnected } = useApp();

  function handleCancelClick() {
    handleCancel();
    setAnchorEl(null);
  }

  function handleShipmentClick() {
    if (!selectedOrder) return;
    if (!restaurant) return;

    if (restaurant.configs.use_deliverer && selectedOrder.shipment?.shipment_method === 'delivery')
      setDialogDeliverer(true);
    else handleOrderStatus();
    setAnchorEl(null);
  }

  function handlePrintOrderClick() {
    setDialogPrintOrder(true);
    setAnchorEl(null);
  }

  function handlePrintOrderShipmentClick() {
    setDialogPrintShipment(true);
    setAnchorEl(null);
  }

  function handlePrintOrderOnlyShipmentClick() {
    setDialogPrintOnlyShipment(true);
    setAnchorEl(null);
  }

  function handleTrackClick() {
    setDialogTrack(true);
    setAnchorEl(null);
  }

  function handleSendToPrintShipment() {
    sendToPrintShipment();
    setAnchorEl(null);
  }

  function handleSendToPrintOrder() {
    sendToPrintOrder();
    setAnchorEl(null);
  }

  function handleConcludeOrder() {
    handleOrderStatus();
  }

  function handleAceptOrder() {
    handleOrderStatus();
    setAnchorEl(null);
  }

  function handleCreateReceiptClick() {
    handleCreateReceipt();
    setAnchorEl(null);
  }

  return (
    <>
      {selectedOrder && (
        <Menu open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
          {selectedOrder.status === 'o' ? (
            <MenuItem onClick={handleAceptOrder}>aceitar</MenuItem>
          ) : selectedOrder.status === 'a' ? (
            <MenuItem onClick={handleShipmentClick}>entregar</MenuItem>
          ) : (
            selectedOrder.status === 'd' && <MenuItem onClick={handleConcludeOrder}>finalizar</MenuItem>
          )}
          {selectedOrder.status !== 'x' && selectedOrder.status !== 'c' && (
            <MenuItem onClick={handleCancelClick}>cancelar</MenuItem>
          )}
          {restaurant && !restaurant.configs.use_printer ? (
            restaurant.configs.print_only_shipment ? (
              <div>
                <MenuItem onClick={handlePrintOrderOnlyShipmentClick}>imprimir pedido</MenuItem>
              </div>
            ) : (
              <div>
                <MenuItem onClick={handlePrintOrderClick}>imprimir pedido</MenuItem>
                <MenuItem onClick={handlePrintOrderShipmentClick}>imprimir entrega</MenuItem>
              </div>
            )
          ) : restaurant && restaurant.configs.print_only_shipment ? (
            <div>
              <MenuItem disabled={!isPrinterConnected} onClick={handleSendToPrintOrder}>
                imprimir pedido
              </MenuItem>
            </div>
          ) : (
            <div>
              <MenuItem disabled={!isPrinterConnected} onClick={handleSendToPrintOrder}>
                imprimir pedido
              </MenuItem>
              <MenuItem disabled={!isPrinterConnected} onClick={handleSendToPrintShipment}>
                imprimir entrega
              </MenuItem>
            </div>
          )}
          <MenuItem onClick={handleTrackClick}>histórico</MenuItem>
          <MenuItem onClick={handleCreateReceiptClick}>emitir cupom fiscal</MenuItem>
        </Menu>
      )}
    </>
  );
};

export default OrderListMenu;
