import React from 'react';
import ProductIngredientDescription from './ingredients/ProductIngredientDescription';
import ProductCategoryDescription from './category/ProductCategoryDescription';
import ProductFormDescription from './name/ProductFormDescription';
import ProductPriceDescription from './price/ProductPriceDescription';
import ProductDoneDescription from './done/ProductDoneDescription';
import ProductAdditionalDescription from './additional/ProductAdditionalDescription';
import ProductComplementDescription from './complements/ProductComplementsDescription';
import ProductAvailabilityDescription from './availability/ProductAvailabilityDescription';
import ProductPrinterDescription from './printer/ProductPrinterDescription';

function ProductDescription() {
  return (
    <div>
      <ProductCategoryDescription />
      <ProductFormDescription />
      <ProductPriceDescription />
      <ProductIngredientDescription />
      <ProductAdditionalDescription />
      <ProductComplementDescription />
      <ProductAvailabilityDescription />
      <ProductPrinterDescription />
      <ProductDoneDescription />
    </div>
  );
}

export default ProductDescription;
