import React from 'react';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import history from 'services/history';

const styles = makeStyles(theme => ({
  container: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

const IngredientsActions: React.FC = () => {
  const classes = styles();

  return (
    <Tooltip className={classes.container} title="Cadastrar adicional">
      <IconButton onClick={() => history.push('/menu/ingredients/new')} color={'inherit'}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
};

export default IngredientsActions;
