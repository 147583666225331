import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Additional } from 'types/product';

export type AdditionalContextValue = {
  additional: Additional[];
  selectedAdditional: null | Additional;
  setSelectedAdditional: Dispatch<SetStateAction<Additional | null>>;
};

const AdditionalContext = createContext<AdditionalContextValue>({} as AdditionalContextValue);
export const AdditionalProvider = AdditionalContext.Provider;
export const AdditionalConsumer = AdditionalContext.Consumer;

export function useAdditional(): AdditionalContextValue {
  const context = useContext(AdditionalContext);
  return context;
}
