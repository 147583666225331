import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { useOrders } from '../hook/useOrders';
import { useModal } from 'components/modal/hooks/useModal';

const styles = makeStyles(theme => ({
  action: {
    position: 'absolute',
    zIndex: 10,
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
    },
  },
  actionContent: {
    display: 'flex',
    padding: 15,
    justifyContent: 'flex-end',
    width: '100%',
    '& button': {
      marginLeft: 10,
    },
  },
}));

const optionsToRenderStatusButton = ['c', 'x', 'p'];

const statusButtonTextOptions = {
  o: 'aprovar',
  a: 'entregar',
  d: 'finalizar',
};

const editButtonTextOptions = ['o', ''];

interface OrderPreviewButtonsProps {
  handleEditClick(): void;
  handleStatusClick(handleCloseModal: () => void): void;
}

const OrderPreviewButtons: React.FC<OrderPreviewButtonsProps> = ({ handleEditClick, handleStatusClick }) => {
  const classes = styles();
  const { selectedOrder: order } = useOrders();
  const { handleModalClose } = useModal();

  return (
    <div className={classes.action}>
      <div className={classes.actionContent}>
        {!optionsToRenderStatusButton.includes(order?.status as string) && (
          <Button color="primary" size="large" variant="contained" onClick={() => handleStatusClick(handleModalClose)}>
            {statusButtonTextOptions[order?.status as string]}
          </Button>
        )}

        <Button color="primary" size="large" variant="text" onClick={handleEditClick}>
          {editButtonTextOptions.includes(order?.status as string) ? 'Editar' : 'Visualizar'}
        </Button>
      </div>
    </div>
  );
};

export default OrderPreviewButtons;
