import { FC } from 'react';
import DialogInput, { DialogInputConsumer } from 'components/dialog/DialogInput';
import { Button, Theme, Typography, makeStyles } from '@material-ui/core';
import { useReceipts } from './hooks/useReceipts';

interface StyleProps {
  isIntegratorConnected: boolean;
}

const styles = makeStyles<Theme, StyleProps>(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    justifyContent: 'center',
    flex: 1,
    padding: '30px',
    minHeight: 300,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  content: {
    display: 'flex',
    flex: 1,
    gap: 15,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
    gap: 15,
    marginBottom: 30,
  },
  integrator: {
    padding: '10px 20px',
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    alignSelf: 'center',
  },
  status: props => ({
    borderRadius: '50%',
    width: 25,
    height: 25,
    backgroundColor: props.isIntegratorConnected ? theme.palette.success.main : theme.palette.error.main,
    display: 'inline-flex',
  }),
}));

interface ReceiptsIntegratorStatusModalProps {
  onExited(): void;
}

const ReceiptsIntegratorStatusModal: FC<ReceiptsIntegratorStatusModalProps> = ({ onExited }) => {
  const { isIntegratorConnected } = useReceipts();
  const classes = styles({ isIntegratorConnected });

  return (
    <DialogInput onExited={onExited} maxWidth="sm">
      <div className={classes.container}>
        <div>
          <Typography align="center" variant="body1">
            <strong>integrador CF-e</strong>
          </Typography>
        </div>
        <div className={classes.content}>
          <div className={classes.integrator}>
            <span className={classes.status} />
            <div>
              <Typography variant="body1" color="textSecondary">
                {isIntegratorConnected ? 'online' : 'offline'}
              </Typography>
            </div>
          </div>
        </div>

        <DialogInputConsumer>
          {context => (
            <div className={classes.actions}>
              <Button onClick={context.handleCloseDialog} variant="text" color="primary">
                fechar
              </Button>
            </div>
          )}
        </DialogInputConsumer>
      </div>
    </DialogInput>
  );
};

export default ReceiptsIntegratorStatusModal;
