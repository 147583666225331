export const orderStatus = {
  p: 'aberto',
  o: 'aberto',
  a: 'preparo',
  d: 'pronto',
  c: 'completo',
  x: 'cancelado',
};

export function getStatusText(status) {
  return orderStatus[status];
}
