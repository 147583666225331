import React from 'react';
import { Tooltip, IconButton, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';

const styles = makeStyles(theme => ({
  tooltip: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

const CouponsActions: React.FC = () => {
  const classes = styles();

  return (
    <Tooltip className={classes.tooltip} title="Adicionar cupom">
      <IconButton component={Link} to="/coupons/new" color="inherit">
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
};

export default CouponsActions;
