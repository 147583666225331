import { makeStyles } from '@material-ui/core';
import CustomAppbar from 'components/appbar/Appbar';
import InsideLoading from 'components/loading/InsideLoading';
import Loading from 'components/loading/Loading';
import PageHeader from 'components/page-header/PageHeader';
import { useMessaging } from 'hooks/messaging';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { api } from 'services/api';
import { setPrinterSettings } from 'store/redux/modules/restaurant/actions';
import { useSelector } from 'store/redux/selector';
import { PrinterSetting } from 'types/printerSettings';
import PrinterSettingsActions from './PrinterSettingsActions';
import PrinterSettingsForm from './PrinterSettingsForm';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    marginTop: 15,
    marginBottom: 20,
    maxWidth: 500,
  },
});

const PrinterSettings: React.FC = () => {
  const classes = useStyles();
  const [settings, setSettings] = useState<PrinterSetting>({} as PrinterSetting);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const messaging = useMessaging();
  const dispatch = useDispatch();
  const restaurant = useSelector(state => state.restaurant);

  useEffect(() => {
    if (!restaurant) return;
    setSettings(restaurant.printer_settings);

    setLoading(false);
  }, [restaurant]);

  function handleChange(index: keyof PrinterSetting, value: any) {
    setSettings(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSubmit() {
    setSaving(true);
    api
      .put('/printerSettings', settings)
      .then(() => {
        messaging.handleOpen('Salvo');
        dispatch(setPrinterSettings(settings));
      })
      .catch(err => {
        console.error(err);
        messaging.handleOpen('Não foi possível salvar');
      })
      .finally(() => setSaving(false));
  }

  return (
    <div className={classes.container}>
      {saving && <Loading />}
      <CustomAppbar
        ActionComponents={<PrinterSettingsActions handleSubmit={handleSubmit} loading={loading} saving={saving} />}
        title="Impressão"
      />
      <PageHeader title="Configurações de impressão" description="Altere tamanho da fonte e quantidade de cópias" />
      {loading ? (
        <InsideLoading />
      ) : (
        <div className={classes.content}>
          <PrinterSettingsForm handleChange={handleChange} settings={settings} />
        </div>
      )}
    </div>
  );
};

export default PrinterSettings;
