import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';

PromotionActions.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default function PromotionActions({ handleSubmit, saving }) {
  return (
    <>
      <Tooltip title="Salvar">
        <IconButton disabled={saving} onClick={handleSubmit} color="inherit">
          <DoneIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}
