import React, { useState } from 'react';
import { useTableReducer } from 'pages/boards/reducer/reducer';
import CustomAppbar from 'components/appbar/Appbar';
import BoardNewActions from './BoardNewActions';
import PageHeader from 'components/page-header/PageHeader';
import { Grid, makeStyles } from '@material-ui/core';
import BoardForm from '../BoardForm';
import { tableChange } from 'pages/boards/reducer/actions';
import { Board } from 'types/board';
import { useBoardValidation } from '../validation/useBoardValidation';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import history from 'services/history';

import Loading from 'components/loading/Loading';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const BoardNew: React.FC = () => {
  const classes = styles();

  const [board, dispatch] = useTableReducer();
  const [validation, setValidation, validate] = useBoardValidation();
  const messaging = useMessaging();
  const [saving, setSaving] = useState(false);

  function handleValidation() {
    setValidation({});

    validate(board)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post('/boards', board)
      .then(() => {
        messaging.handleOpen('Salvo');
        setSaving(false);
        history.push('/boards');
      })
      .catch(() => {
        messaging.handleOpen('Não foi possível salvar');
        setSaving(false);
      });
  }

  function handleChange(key: keyof Board, value: any) {
    dispatch(tableChange(key, value));
  }

  return (
    <>
      {saving && <Loading />}
      <CustomAppbar title="Mesa" ActionComponents={<BoardNewActions handleSubmit={handleValidation} />} />
      <PageHeader title="Nova mesa" backAction={() => history.push('/boards')} />
      <div className={classes.container}>
        <Grid item xs={12} xl={4} lg={4} md={6}>
          <BoardForm handleChange={handleChange} board={board} validation={validation} />
        </Grid>
      </div>
    </>
  );
};

export default BoardNew;
