import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import LoupeIcon from '@material-ui/icons/Loupe';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { useProduct } from '../hooks/useProduct';

function ProductAction({ openDialogImport }) {
  const { product, step, handleChange, setImagePreview } = useProduct();

  return (
    <>
      {product.photoSelected ? (
        <>
          <Tooltip title="Ampliar imagem">
            <IconButton onClick={() => setImagePreview(true)} color="inherit">
              <LoupeIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Excluir foto">
            <IconButton onClick={() => handleChange(null, 'image')} color="inherit">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip title="Salvar">
            <div>
              <IconButton color="inherit" disabled={step !== 4}>
                <DoneIcon />
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip title="Importar de outro produto">
            <IconButton color="inherit" onClick={openDialogImport}>
              <SaveAltIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  );
}

export default ProductAction;
