import React, { useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import { useSelector } from 'store/redux/selector';
import PrintTypography from 'components/print-typography/PrintTypography';
import { useCashRegisterCurrentValue } from '../hooks/useCashRegisterCurrentValue';
import { CashRegisterWithMovements } from 'types/cashRegister';
import Modal from 'components/modal/Modal';
import './CashRegisterExtractReport.css';
import CashRegisterExtractReportActions from './CashRegisterExtractReportActions';

interface UseStylesProps {
  fontSize: number;
  noMargin: boolean;
}

const useStyles = makeStyles<Theme, UseStylesProps>({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 0.7,
    '@media print': {
      '&': {
        flex: 0,
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      },
    },
  },
  content: props => ({
    maxWidth: '80mm',
    width: '100%',
    padding: 10,
    fontSize: props.fontSize,
    backgroundColor: '#faebd7',
    border: '2px dashed #ccc',
    alignItems: 'center',
    justifyContent: 'center',
    '@media print': {
      '&': {
        backgroundColor: 'transparent',
        border: 'none',
        padding: 0,
      },
    },
  }),
  header: {
    borderBottom: '1px dashed #000',
    marginBottom: 20,
    paddingBottom: 10,
    textAlign: 'center',
  },
  footer: {
    marginBottom: 10,
    textAlign: 'center',
    marginTop: 20,
  },
});

interface CashRegisterExtractReportProps {
  cashRegister: CashRegisterWithMovements | null;
  onExited(): void;
}

const CashRegisterExtractReport: React.FC<CashRegisterExtractReportProps> = ({ cashRegister, onExited }) => {
  const restaurant = useSelector(state => state.restaurant);

  const classes = useStyles({
    fontSize: 14,
    noMargin: !!restaurant?.printer_settings?.no_margin,
  });

  const { formattedCurrentValue } = useCashRegisterCurrentValue(cashRegister);

  useEffect(() => {
    const timer = setTimeout(window.print, 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Modal
      onExited={onExited}
      title="impressão extrato do caixa"
      fullscreen
      componentActions={<CashRegisterExtractReportActions />}
    >
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.header}>
            <PrintTypography fontSize={1.2} bold>
              EXTRATO CAIXA {cashRegister?.sequence}
            </PrintTypography>
          </div>

          <div className="resume">
            <div className="item">
              <PrintTypography bold fontSize={1.5}>
                {formattedCurrentValue}
              </PrintTypography>
              <PrintTypography>
                {cashRegister?.formattedCreatedAt} - {cashRegister?.formattedClosedAt || ' até o momento'}
              </PrintTypography>
            </div>
          </div>

          <div className="initial-value">
            <PrintTypography>saldo inicial</PrintTypography>
            <PrintTypography bold>{cashRegister?.formattedInitialValue}</PrintTypography>
          </div>

          <div className="cash-register-statement">
            <ul>
              {cashRegister?.movements.map(movement => (
                <li key={movement.id}>
                  <div>
                    <PrintTypography>{movement.description}</PrintTypography>
                    <PrintTypography>{movement.formattedCreatedAt}</PrintTypography>
                  </div>

                  <PrintTypography bold>
                    {movement.type === 'debit' && '-'}
                    {movement.formattedValue}
                  </PrintTypography>
                </li>
              ))}
            </ul>
          </div>

          <div className={classes.footer}>
            <PrintTypography>sgrande.delivery</PrintTypography>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CashRegisterExtractReport;
