import React, { Dispatch, SetStateAction, useState } from 'react';
import { IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { Add, CompareArrows, MoreVert, Payment } from '@material-ui/icons';
import history from 'services/history';
import { useBoardControl } from './hooks/useBoardControl';
import { useSelector } from 'store/redux/selector';
import { api } from 'services/api';

const styles = makeStyles(theme => ({
  container: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

type BoardControlActionsProps = {
  setSaving: Dispatch<SetStateAction<boolean>>;
  saving: boolean;
};

const BoardControlActions: React.FC<BoardControlActionsProps> = ({ setSaving, saving }) => {
  const classes = styles();
  const restaurant = useSelector(state => state.restaurant);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { setIsPaymentModalOpen, movement, setShowDialogTransfer, handleSetFreeBoard, setShowDialogBill } =
    useBoardControl();

  function handleFreeBoardClick() {
    setAnchorEl(null);

    if (!movement?.board) {
      return;
    }

    setSaving(true);

    handleSetFreeBoard()
      .catch(err => console.error(err))
      .finally(() => setSaving(true));
  }

  function handleCreateOrderClick() {
    history.push(`/board-management/orders?board=${movement?.id}&customer_id=${movement?.customer?.id}`);
  }

  function handlePrintBillClick() {
    if (restaurant?.configs.use_printer) {
      api.post(`/boardMovements/${movement?.id}/print-billing`, movement).catch(err => console.error(err));
      setAnchorEl(null);
      return;
    }

    setAnchorEl(null);
    setShowDialogBill(true);
  }

  function handleOpenAppBoardClient() {
    window.open(`${restaurant?.configs.board_app_url}?session=${movement?.id}`);
  }

  return (
    <div className={classes.container}>
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handlePrintBillClick}>Imprimir conta</MenuItem>
        {movement?.isBoardPaid && movement?.is_open && (
          <MenuItem onClick={() => handleFreeBoardClick()}>Liberar mesa</MenuItem>
        )}
        <MenuItem onClick={handleOpenAppBoardClient}>Abrir em app de mesa</MenuItem>
      </Menu>

      {movement?.is_open && (
        <IconButton disabled={saving} onClick={handleCreateOrderClick} color="inherit">
          <Add />
        </IconButton>
      )}

      <IconButton onClick={() => setIsPaymentModalOpen(true)} disabled={!movement?.total} color="inherit">
        <Payment />
      </IconButton>

      {movement?.is_open && (
        <IconButton onClick={() => setShowDialogTransfer(true)} color="inherit">
          <CompareArrows />
        </IconButton>
      )}

      <IconButton size="small" color="inherit" onClick={event => setAnchorEl(event.currentTarget)}>
        <MoreVert />
      </IconButton>
    </div>
  );
};

export default BoardControlActions;
