import { Image } from 'types/image';
import { PushNotification } from 'types/pushNotification';

export const PUSH_CHANGE = 'PUSH_CHANGE';
export const SET_PUSH = 'SET_PUSH';

interface SetPushNotificationAction {
  type: typeof SET_PUSH;
  pushNotification: PushNotification;
}

interface PushNotificationChangeAction {
  type: typeof PUSH_CHANGE;
  index: string;
  value: string | boolean | Image | null | Date;
}

export type PushNotificationActions = PushNotificationChangeAction | SetPushNotificationAction;
