import React, { useEffect, useCallback } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { mapStyle } from './mapStyle';
import { useCustomerAddress } from '../hooks/useCustomerAddress';
import GoogleMapHeader from './GoogleMapHeader';
import { infoWindowContent } from './infoWindowContent';
import { RestaurantAddress } from 'types/restaurant';
import { Loader } from '@googlemaps/js-api-loader';

const styles = makeStyles(theme => ({
  map: {
    height: '80vh',
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    '@media (max-width: 600px)': {
      height: '100vh',
    },
    '& .gm-style-iw-a button': {
      visibility: 'hidden',
    },
    '& .gm-style-iw-c': {
      padding: 0,
    },
    '& .gm-style-iw-d': {
      overflow: 'hidden !important',
    },
  },
  actions: {
    background: 'transparent',
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 0,
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    height: 60,
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
}));

const loader = new Loader({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY as string,
  version: 'weekly',
  libraries: ['places', 'geometry'],
});

interface CopyGoogleMapProps {
  lat: number;
  lng: number;
  address: RestaurantAddress;
}

const CopyGoogleMap: React.FC<CopyGoogleMapProps> = ({ lat, lng, address }) => {
  const classes = styles();
  const { handleGetAddress, handleNext } = useCustomerAddress();

  const createMap = useCallback(async (): Promise<google.maps.Map> => {
    const position = { lat, lng };

    await loader.load();

    const map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      zoom: 16,
      center: position,
      mapTypeId: google.maps.MapTypeId.TERRAIN,
      disableDefaultUI: true,
      styles: mapStyle,
    });

    return map;
  }, [lat, lng]);

  const createMarker = useCallback(
    (map: google.maps.Map): google.maps.Marker => {
      const position = { lat, lng };

      const marker = new google.maps.Marker({
        position,
        draggable: true,
      });

      marker.setMap(map);

      return marker;
    },
    [lat, lng]
  );

  const createInfoWindow = useCallback((): google.maps.InfoWindow => {
    const infoWindow = new google.maps.InfoWindow({
      content: infoWindowContent,
    });

    return infoWindow;
  }, []);

  const initMap = useCallback(async () => {
    const map = await createMap();
    const marker = createMarker(map);
    const infowindow = createInfoWindow();

    const openWindow = () =>
      infowindow.open({
        anchor: marker,
        map,
        shouldFocus: true,
      });

    openWindow();

    marker.addListener('dragstart', () => {
      infowindow.close();
    });

    marker.addListener('dragend', () => {
      openWindow();
      const position = marker.getPosition();
      if (!position) return;

      handleGetAddress({ lat: position.toJSON().lat, lng: position.toJSON().lng });
    });
  }, [createMap, createMarker, createInfoWindow, handleGetAddress]);

  useEffect(() => {
    initMap().catch(err => console.error(err));
  }, [initMap]);

  return (
    <>
      <div className={classes.map} id="map" />

      <GoogleMapHeader address={address} />

      <div className={classes.actions}>
        <Button className={classes.button} variant="contained" color="primary" size="large" onClick={handleNext}>
          Confirmar endereço
        </Button>
      </div>
    </>
  );
};

export default CopyGoogleMap;
