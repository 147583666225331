import React, { useState } from 'react';
import { List, Collapse, Typography, Avatar } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MoneyIcon from '@material-ui/icons/MonetizationOn';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import PlaceIcon from '@material-ui/icons/Place';
import SettingsIcon from '@material-ui/icons/Settings';
import CategoryIcon from '@material-ui/icons/Category';
import PaymentIcon from '@material-ui/icons/Payment';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import RestaurantMenu from '@material-ui/icons/RestaurantMenu';
import BooksIcon from '@material-ui/icons/LibraryBooks';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import DescriptionIcon from '@material-ui/icons/Description';
import PromotionIcon from '@material-ui/icons/NewReleases';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import PrintIcon from '@material-ui/icons/Print';
import { useApp } from 'App';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PaymentsIcon from '@material-ui/icons/LocalAtm';
import SidebarItem from './SidebarItem';
import SidebarItemExpendable from './SidebarItemExpendable';
import SidebarRestaurantStatus from './SidebarRestaurantStatus';
import { useSelector } from 'store/redux/selector';
import { SIDEBAR_WIDTH } from 'constants/constants';
import ReportSidebar from './reports/ReportSidebar';
import SidebarItemMore from './SidebarItemMore';
import SettingsSidebar from './settings/SettingsSidebar';
import { GiFullMotorcycleHelmet } from 'react-icons/gi';
import { Receipt, Star, ViewModule, Public, Money } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: SIDEBAR_WIDTH,
    backgroundColor: theme.palette.secondary.dark,
    '@media print': {
      display: 'none',
    },
  },
  drawerHeader: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    height: 64,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
  },
  avatar: {
    width: 40,
    height: 40,
    border: `2px solid ${theme.palette.primary.light}`,
  },
  account: {
    minHeight: 90,
  },
  content: {
    transition: 'opacity 0.4s ease',
    opacity: 1,
  },
}));

interface Collapsible {
  orders: boolean;
  menu: boolean;
  fiscal: boolean;
  payment: boolean;
}

const Sidebar: React.FC = () => {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const app = useApp();
  const [shownReports, setShownReports] = useState(false);
  const [shownSettings, setShownSettings] = useState(false);
  const [collapsible, setCollapsible] = useState<Collapsible>({
    orders: false,
    menu: false,
    fiscal: false,
    payment: false,
  });
  const restaurant = useSelector(state => state.restaurant);

  function handleCollapseClick(index: keyof Collapsible): void {
    const keys = Object.keys(collapsible);

    let otherValues: Collapsible = {} as Collapsible;
    keys.forEach(key => {
      otherValues = {
        ...otherValues,
        [key]: false,
      };
    });

    setCollapsible({
      ...otherValues,
      [index]: !collapsible[index],
    });
  }

  return (
    <Drawer
      variant={app.isMobile || app.windowWidth < 1280 ? 'temporary' : 'persistent'}
      anchor="left"
      classes={{ paper: classes.drawerPaper }}
      open={app.isOpenedMenu}
      onClose={app.handleOpenMenu}
    >
      <ReportSidebar show={shownReports} handleClose={() => setShownReports(false)} />
      <SettingsSidebar show={shownSettings} handleClose={() => setShownSettings(false)} />
      <div className={classes.content} style={shownReports || shownSettings ? { display: 'none' } : undefined}>
        <div className={classes.drawerHeader}>
          <Typography variant="h6">SGrande Admin</Typography>
        </div>
        <SidebarItem to="/dashboard" icon={<DashboardIcon />} text="dashboard" />
        <SidebarItemExpendable
          onClick={() => handleCollapseClick('orders')}
          text="vendas"
          opened={collapsible.orders}
          icon={<MoneyIcon />}
        />
        <Collapse in={collapsible.orders}>
          <List disablePadding>
            <SidebarItem to="/delivery-orders" icon={<AssignmentIcon />} text="delivery" nested />
            <SidebarItem to="/board-management" icon={<ViewModule />} text="mesas" nested />
            <SidebarItem to="/promotions" icon={<PromotionIcon />} text="promoções" nested />
            <SidebarItem to="/coupons" icon={<MoneyOffIcon />} text="cupons" nested />
            <SidebarItem to="/cash-registers" icon={<PaymentsIcon />} text="caixas" nested />
          </List>
        </Collapse>
        <SidebarItemExpendable
          onClick={() => handleCollapseClick('fiscal')}
          text="fiscal"
          opened={collapsible.fiscal}
          icon={<Star />}
        />
        <Collapse in={collapsible.fiscal}>
          <List disablePadding>
            <SidebarItem to="/fiscal-serials" icon={<Star />} text="séries" nested />
            <SidebarItem to="/tax-rules" icon={<Star />} text="regras fiscais" nested />
            <SidebarItem to="/receipts" icon={<Receipt />} text="cupons fiscais" nested />
          </List>
        </Collapse>
        <SidebarItemExpendable
          onClick={() => handleCollapseClick('menu')}
          text="cardápio"
          opened={collapsible.menu}
          icon={<RestaurantMenu />}
        />
        <Collapse in={collapsible.menu}>
          <List disablePadding>
            <SidebarItem to="/menu/categories" icon={<CategoryIcon />} text="categorias" nested />
            <SidebarItem to="/menu/products" icon={<BooksIcon />} text="produtos" nested />
            <SidebarItem to="/menu/ingredients" icon={<DescriptionIcon />} text="ingredientes" nested />
            <SidebarItem to="/menu/additional" icon={<NoteAddIcon />} text="adicionais" nested />
            <SidebarItem to="/product-complement-manager" icon={<BooksIcon />} text="produtos e complementos" nested />
          </List>
        </Collapse>
        <SidebarItem to="/customers" icon={<AccountBoxIcon />} text="clientes" />
        <SidebarItem to="/push-notifications" icon={<NotificationsIcon />} text="notificações push" />
        <SidebarItem to="/opening-hours" icon={<AccessTimeIcon />} text="horário de funcionamento" />

        <SidebarItemExpendable
          onClick={() => handleCollapseClick('payment')}
          text="pagamento"
          opened={collapsible.payment}
          icon={<Money />}
        />
        <Collapse in={collapsible.payment}>
          <List disablePadding>
            <SidebarItem to="/payment-methods" icon={<PaymentIcon />} text="formas de pagamento" nested />
            <SidebarItem
              to="/payment-gateway-settings"
              icon={<Public />}
              text="configurações para pagamento online"
              nested
            />
          </List>
        </Collapse>

        <SidebarItem to="/area" icon={<PlaceIcon />} text="área de entrega" />
        <SidebarItem to="/deliverers" icon={<GiFullMotorcycleHelmet size={24} />} text="entregadores" />
        <SidebarItem to="/printers" icon={<PrintIcon />} text="impressoras" />
        <SidebarItem to="/boards" icon={<ViewModule />} text="mesas e comandas" />
        <SidebarItemMore
          handleClick={() => setShownReports(true)}
          text="relatórios"
          icon={<DescriptionIcon color="inherit" />}
        />
        <SidebarItemMore
          handleClick={() => setShownSettings(true)}
          text="configurações"
          icon={<SettingsIcon color="inherit" />}
        />
        <SidebarItem
          to="/account"
          icon={
            user.image ? (
              <Avatar className={classes.avatar} src={user.image.imageThumbUrl} />
            ) : user.name ? (
              <Avatar className={classes.avatar}>{user.name.charAt(0)}</Avatar>
            ) : (
              <Avatar className={classes.avatar} />
            )
          }
          text={user.name || 'Minha conta'}
          secondaryText={restaurant?.name}
        />
      </div>
      <SidebarRestaurantStatus />
    </Drawer>
  );
};

export default Sidebar;
