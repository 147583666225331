import { TableTemplate } from 'types/tableTemplate';

export const additionalTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'name',
    description: 'NOME',
    originalId: 'name',
    width: 400,
  },
  {
    id: 'formattedPrice',
    description: 'PREÇO',
    originalId: 'formattedPrice',
    width: 150,
  },
  {
    id: 'categoryName',
    description: 'PARA CATEGORIA',
    originalId: 'categoryName',
    width: 300,
  },
  {
    id: 'formattedCreatedAt',
    description: 'CRIADO EM',
    originalId: 'formattedCreatedAt',
    width: 200,
  },
];
