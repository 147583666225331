import { BoardMovement } from 'types/boardMovement';
import { BoardMovementPayment } from 'types/boardMovementPayment';
import { BoardOrderProduct } from 'types/boardOrderProduct';
import { BoardControlActions } from './types';
import { Board } from 'types/board';

export function setBoardMovement(movement: BoardMovement): BoardControlActions {
  return {
    type: '@boardControl/SET_BOARD_MOVEMENT',
    movement,
  };
}

export function setBoardProducts(products: BoardOrderProduct[]): BoardControlActions {
  return {
    type: '@boardControl/SET_PRODUCTS',
    products,
  };
}

export function setBoardPayments(payments: BoardMovementPayment[]): BoardControlActions {
  return {
    type: '@boardControl/SET_PAYMENTS',
    payments,
  };
}

export function addBoardProducts(products: BoardOrderProduct[]): BoardControlActions {
  return {
    type: '@boardControl/ADD_PRODUCTS',
    products,
  };
}

export function addBoardPayment(payment: BoardMovementPayment): BoardControlActions {
  return {
    type: '@boardControl/ADD_PAYMENT',
    payment,
  };
}

export function removeBoardProduct(orderProductId: number | string): BoardControlActions {
  return {
    type: '@boardControl/REMOVE_PRODUCT',
    orderProductId,
  };
}

export function removeBoardProductByReferencedId(referencedId: number): BoardControlActions {
  return {
    type: '@boardControl/REMOVE_PRODUCT_REFERENCED_ID',
    referencedId,
  };
}

export function removeBoardPayment(paymentId: string): BoardControlActions {
  return {
    type: '@boardControl/REMOVE_PAYMENT',
    paymentId,
  };
}

export function updateBoardTotal(): BoardControlActions {
  return {
    type: '@boardControl/UPDATE_TOTAL',
  };
}

export function setBoardCustomer(name: string): BoardControlActions {
  return {
    type: '@boardControl/SET_CUSTOMER',
    name,
  };
}

export function setBoardDiscount(discount: number): BoardControlActions {
  return {
    type: '@boardControl/SET_DISCOUNT',
    discount,
  };
}

export function changeMovementBoard(board: Board): BoardControlActions {
  return {
    type: '@board/CHANGE_MOVEMENT_BOARD',
    board,
  };
}
