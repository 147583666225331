import React, { useState } from 'react';
import AddPhotoIcon from '@material-ui/icons/AddAPhoto';
import { Zoom, IconButton, Typography, makeStyles, alpha, Button } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ImageSelector from 'components/image-selector/ImageSelector';
import { UserRegister } from 'store/modules/user/types';
import { Image } from 'types/image';

const useStyles = makeStyles(theme => ({
  image: {
    width: '100%',
    borderRadius: 4,
    backgroundColor: '#eee',
  },
  imageContainer: {
    display: 'flex',
    width: 200,
    height: 200,
    border: `2px dashed ${alpha(theme.palette.primary.main, 0.2)}`,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden',
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      width: '100%',
    },
  },
  imageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    display: 'flex',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
  },
  icon: {
    position: 'absolute',
    background: '#fff',
    borderRadius: '50%',
    bottom: 10,
    right: 0,
  },
}));

interface UserImageFormProps {
  handleChange(index: keyof UserRegister, value: Image | null): void;
  user: UserRegister;
}

const UserImageForm: React.FC<UserImageFormProps> = ({ handleChange, user }) => {
  const [imageSelector, setImageSelector] = useState(false);
  const classes = useStyles();
  const [selected, setSelected] = useState(false);

  return (
    <>
      {imageSelector && (
        <ImageSelector
          handleSetImageId={image => handleChange('image', image)}
          onExited={() => setImageSelector(false)}
        />
      )}
      {!user.image ? (
        <>
          <div className={classes.imageContainer}>
            <IconButton onClick={() => setImageSelector(true)}>
              <AddPhotoIcon color="primary" />
            </IconButton>
          </div>
          <Typography variant="caption" color="primary">
            Imagem do perfil
          </Typography>
        </>
      ) : (
        <div className={classes.imageContainer} onClick={() => setSelected(selected => !selected)}>
          <Zoom in={selected}>
            <div className={classes.imageWrapper}>
              <Button variant="contained" color="primary" size="small" onClick={() => handleChange('image', null)}>
                Remover
              </Button>
              <CheckCircleIcon color="primary" className={classes.icon} />
            </div>
          </Zoom>
          <img alt="Foto do produto" className={classes.image} src={user.image.imageUrl} />
        </div>
      )}
    </>
  );
};

export default UserImageForm;
