import React, { useState, useEffect, FormEvent } from 'react';
import ProfileAddress from 'components/profile/address/ProfileAddress';
import ProfileBasic from 'components/profile/ProfileBasic';
import ProfilePhone from 'components/profile/phone/ProfilePhone';
import { api } from 'services/api';
import Loading from 'components/loading/Loading';
import ProfilePhoneForm from 'components/profile/phone/ProfilePhoneForm';
import ProfileAction from 'components/profile/ProfileAction';
import { ProfileTabs } from 'components/profile/ProfileTabs';
import ProfileCustomization from './customization/ProfileCustomization';
import { useProfileReducer } from 'store/modules/restaurant/reducer';
import {
  change,
  addPhone,
  deleteAddress,
  deletePhone,
  setRestaurant,
  setMainAddress,
} from 'store/modules/restaurant/actions';
import ProfileSocial from './social/ProfileSocial';
import { useMessaging } from 'hooks/messaging';
import { useDispatch } from 'react-redux';
import { setRestaurant as reduxSetResturant } from 'store/redux/modules/restaurant/actions';
import { ProfileProvider } from './hook/useProfile';
import { useSelector } from 'store/redux/selector';
import { useProfileValidation } from './validation/useProfileValidation';
import { Restaurant, RestaurantAddress, RestaurantPhone } from 'types/restaurant';
import NewAddress from './address/registration/places-api/NewAddress';
import EditAddress from './address/registration/places-api/edit/EditAddress';
import Appbar from 'components/appbar/Appbar';

const Profile: React.FC = () => {
  const messaging = useMessaging();
  const [profile, dispatch] = useProfileReducer();
  const [saving, setSaving] = useState(false);
  const [dialogUpdateAddress, setDialogUpdateAddress] = useState(false);
  const [dialogNewAddress, setDialogNewAddress] = useState(false);
  const [dialogPhone, setDialogPhone] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<RestaurantAddress | null>(null);
  const [validation, setValidation, validate] = useProfileValidation();
  const [tabIndex, setTabIndex] = useState(0);
  const reduxRestaurant = useSelector(state => state.restaurant);
  const reduxDispatch = useDispatch();
  const [isLogoSelected, setIsLogoSelected] = useState(false);
  const [isCoverSelected, setIsCoverSelected] = useState(false);

  useEffect(() => {
    if (!reduxRestaurant) return;

    dispatch(
      setRestaurant({
        ...reduxRestaurant,
        phones: reduxRestaurant.phones.map(phone => {
          phone.uid = phone.id;
          return phone;
        }),
      })
    );
  }, [reduxRestaurant, dispatch]);

  function handleTabChange(event, value: number) {
    setTabIndex(value);
  }

  function handleInputChange(index: keyof Restaurant, value: any) {
    dispatch(change(index, value));
  }

  function handleSetMainAddress(addressId: number) {
    dispatch(setMainAddress(addressId));
  }

  function handleValidation(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    validate(profile)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  async function handleSubmit() {
    setSaving(true);
    setValidation({});

    api
      .put(`/restaurants/${profile.id}`, profile)
      .then(response => {
        messaging.handleOpen('Salvo!');
        reduxDispatch(reduxSetResturant(response.data));
      })
      .catch(err => {
        if (!err.response) messaging.handleOpen(err.message);
        else messaging.handleOpen('Não foi possivel salvar');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleProfileAddressDelete(addressId: number) {
    dispatch(deleteAddress(addressId));
  }

  function handleDialogPhone() {
    setDialogPhone(true);
  }

  function handleProfilePhoneSubmit(phone: RestaurantPhone) {
    dispatch(addPhone(phone));
  }

  function handleProfilePhoneDelete(phoneUid: number) {
    dispatch(deletePhone(phoneUid));
  }

  return (
    <ProfileProvider
      value={{
        dispatch,
        setSelectedAddress,
        selectedAddress,
        handleOpenDialogUpdateAddress: () => setDialogUpdateAddress(true),
        handleOpenDialogNewAddress: () => setDialogNewAddress(true),
        handleProfileAddressDelete,
        handleSetMainAddress,
        profile,
        isCoverSelected,
        isLogoSelected,
        setIsCoverSelected,
        setIsLogoSelected,
      }}
    >
      <Appbar
        title="Perfil do Delivery"
        Tab={<ProfileTabs tab={tabIndex} handleTabChange={handleTabChange} />}
        ActionComponents={
          <ProfileAction
            handleOpenDialogNewAddress={() => setDialogNewAddress(true)}
            handleDialogPhone={() => setDialogPhone(!dialogPhone)}
            handleSubmit={handleValidation}
            saving={saving}
            tab={tabIndex}
          />
        }
      />

      {saving && <Loading />}

      {dialogNewAddress && <NewAddress onExited={() => setDialogNewAddress(false)} />}

      {dialogUpdateAddress && selectedAddress && (
        <EditAddress selectedAddress={selectedAddress} onExited={() => setDialogUpdateAddress(false)} />
      )}

      {dialogPhone && (
        <ProfilePhoneForm
          onExited={() => setDialogPhone(!dialogPhone)}
          handleProfilePhoneSubmit={handleProfilePhoneSubmit}
        />
      )}

      {tabIndex === 0 ? (
        <ProfileBasic
          handleInputChange={handleInputChange}
          restaurant={profile}
          validation={validation}
          handleSubmit={handleSubmit}
        />
      ) : tabIndex === 1 ? (
        <ProfileAddress />
      ) : tabIndex === 2 ? (
        <ProfilePhone
          restaurant={profile}
          handleDialogPhone={handleDialogPhone}
          handleProfilePhoneDelete={handleProfilePhoneDelete}
        />
      ) : tabIndex === 3 ? (
        <ProfileCustomization handleInputChange={handleInputChange} restaurant={profile} validation={validation} />
      ) : (
        <ProfileSocial
          handleInputChange={handleInputChange}
          restaurant={profile}
          validation={validation}
          handleSubmit={handleSubmit}
        />
      )}
    </ProfileProvider>
  );
};

export default Profile;
