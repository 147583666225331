import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { io, Socket } from 'socket.io-client';
import { useSelector } from 'store/redux/selector';

const socket = io(`${process.env.REACT_APP_EXPRESS_SERVER}/receipt`);

type UseReceiptSocket = [Socket, boolean];

export function useReceiptSocket(): UseReceiptSocket {
  const restaurant = useSelector(state => state.restaurant);
  const [connected, setConnected] = useState(socket.connected);
  const dispatch = useDispatch();

  useEffect(() => {
    socket.on('connect', () => {
      setConnected(true);
    });

    socket.on('disconnect', () => {
      setConnected(false);
    });

    socket.on('cancel_receipt', (response: any) => {
      console.log('cancel_receipt', response);
    });

    socket.on('print_receipt', (response: any) => {
      console.log('print_receipt', response);
    });

    return () => {
      socket.off('disconnect');
      socket.off('connect');
    };
  }, [dispatch]);

  useEffect(() => {
    if (restaurant && connected) {
      socket.emit('register', restaurant.id);

      socket.emit('admin_receipts_ping', restaurant.id);
    }
  }, [connected, restaurant]);

  return [socket, connected];
}
