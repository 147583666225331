import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { Product } from 'types/product';

export type ProductsContextValue = {
  products: Product[];
  selectedProduct: null | Product;
  setSelectedProduct(product: null | Product): void;
  handleProductActive(): void;
  handleDelete(): Promise<void>;
  setIsOpenDeleteConfirmation: Dispatch<SetStateAction<boolean>>;
};

const ProductsContext = createContext<ProductsContextValue>({} as ProductsContextValue);
export const ProductsContextProvider = ProductsContext.Provider;
export const ProductsContextConsumer = ProductsContext.Consumer;

export function useProducts(): ProductsContextValue {
  const context = useContext(ProductsContext);
  return context;
}
