import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { Category } from 'types/category';

export type CategoriesContextValue = {
  categories: Category[];
  selectedCategory: null | Category;
  setSelectedCategory(product: null | Category): void;
  handleCategoryActive(): void;
  setIsOpenDeleteConfirmation: Dispatch<SetStateAction<boolean>>;
  handleDelete(): Promise<void>;
};

const CategoriesContext = createContext<CategoriesContextValue>({} as CategoriesContextValue);
export const CategoriesContextProvider = CategoriesContext.Provider;
export const CategoriesContextConsumer = CategoriesContext.Consumer;

export function useCategories(): CategoriesContextValue {
  const context = useContext(CategoriesContext);
  return context;
}
