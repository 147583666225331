import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ListItemIcon, ListItemText, ListItem } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  listItemIcon: {
    color: theme.palette.secondary.contrastText,
  },
  listItemText: {
    color: '#fff',
    fontSize: 14,
  },
  expandColor: {
    color: '#fff',
  },
}));

interface SidebarItemProps {
  text: string;
  icon: React.ReactElement;
  opened: boolean;
  onClick: () => void;
}

const SidebarItemExpendable: React.FC<SidebarItemProps> = ({ icon, text, opened, onClick }) => {
  const classes = useStyles();

  return (
    <ListItem onClick={onClick} button>
      <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
      <ListItemText classes={{ primary: classes.listItemText }} primary={text} />
      {opened ? <ExpandLess className={classes.expandColor} /> : <ExpandMore className={classes.expandColor} />}
    </ListItem>
  );
};

export default SidebarItemExpendable;
