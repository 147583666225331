import React from 'react';
import { Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { PushNotification } from 'types/pushNotification';

type PushNotificationItemTableProps = {
  pushNotification: PushNotification;
};

const PushNotificationItemTable: React.FC<PushNotificationItemTableProps> = ({ pushNotification }) => {
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div key={item.id}>
            <Typography variant="body2">{pushNotification[item.id] as keyof PushNotification}</Typography>
          </div>
        ))}
    </>
  );
};

export default PushNotificationItemTable;
