import React from 'react';
import { useReceipts } from './hooks/useReceipts';
import { Theme, Typography, makeStyles } from '@material-ui/core';

interface StyleProps {
  isIntegratorConnected: boolean;
}

const styles = makeStyles<Theme, StyleProps>(theme => ({
  integrator: {
    border: '1px solid #eee',
    padding: '10px 20px',
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    alignSelf: 'center',
  },
  status: props => ({
    borderRadius: '50%',
    width: 25,
    height: 25,
    backgroundColor: props.isIntegratorConnected ? theme.palette.success.main : theme.palette.error.main,
    display: 'inline-flex',
  }),
}));

const ReceiptsIntegratorStatus: React.FC = () => {
  const { isIntegratorConnected } = useReceipts();
  const classes = styles({ isIntegratorConnected });

  return (
    <div className={classes.integrator}>
      <span className={classes.status} />
      <div>
        <Typography variant="body2">integrador</Typography>
        <Typography variant="caption" color="textSecondary">
          {isIntegratorConnected ? 'online' : 'offline'}
        </Typography>
      </div>
    </div>
  );
};

export default ReceiptsIntegratorStatus;
