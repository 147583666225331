import React, { useState, MouseEvent } from 'react';
import CustomDialog from 'components/dialog/CustomDialog';
import { TextField, IconButton, Menu, MenuItem } from '@material-ui/core';
import AreaDistancesActions from './AreaDistancesActions';
import { useArea } from '../Areas';
import { makeStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreVert';
import { addAreaDistances } from 'store/modules/area/actions';
import { useMessaging } from 'hooks/messaging';
import { AreaDistance } from 'types/area';

const useStyles = makeStyles({
  container: {
    padding: 10,
    position: 'relative',
  },
  buttonClose: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '15px',
  },
});

interface AreaDistancesProps {
  onExited(): void;
}

const AreaDistances: React.FC<AreaDistancesProps> = ({ onExited }) => {
  const classes = useStyles();
  const { dispatch, selectedArea } = useArea();
  const [distances, setDistances] = useState<AreaDistance[]>(selectedArea.distances);
  const messaging = useMessaging();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [selectedDistanceId, setSelecteDistanceId] = useState<number | null>(null);

  function handleAdd() {
    setDistances(oldDistances => [
      ...oldDistances,
      {
        id: new Date().getTime(),
        until_distance: '',
        tax: '',
      },
    ]);
  }

  function handleConfirm() {
    dispatch(addAreaDistances(selectedArea.id, distances));
    messaging.handleOpen('Confirmado');
  }

  function handleChange(index: string, value: number, distanceId: number) {
    setDistances(oldDistances =>
      oldDistances.map(d => {
        if (d.id === distanceId) {
          d[index] = value;
          return d;
        }
        return d;
      })
    );
  }

  function handleMoreClick(event: MouseEvent, distanceId: number) {
    setAnchorEl(event.currentTarget);
    setSelecteDistanceId(distanceId);
  }

  function handleDeleteClick() {
    setDistances(distances => distances.filter(distance => distance.id !== selectedDistanceId));
    setAnchorEl(null);
  }

  return (
    <CustomDialog
      handleModalState={onExited}
      title="Distâncias e Taxas"
      maxWidth="sm"
      componentActions={<AreaDistancesActions handleAdd={handleAdd} handleConfirm={handleConfirm} />}
      displayBottomActions={false}
    >
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handleDeleteClick}>Excluir</MenuItem>
      </Menu>
      {distances.map((distance, index) => (
        <div className={classes.container} key={distance.id}>
          <IconButton className={classes.buttonClose} onClick={e => handleMoreClick(e, distance.id)}>
            <MoreIcon />
          </IconButton>
          <div className={classes.form}>
            <TextField
              label="Até a distância (km)"
              placeholder="Até a distância (km)"
              margin="normal"
              fullWidth
              value={distances[index].until_distance}
              onChange={e => handleChange('until_distance', parseFloat(e.target.value), distance.id)}
              type="number"
              autoFocus
              inputProps={{
                inputMode: 'decimal',
                step: 0.01,
              }}
            />
            <TextField
              label="Taxa (R$)"
              placeholder="Informe a taxa"
              margin="normal"
              fullWidth
              value={distances[index].tax}
              onChange={e => handleChange('tax', parseFloat(e.target.value), distance.id)}
              type="number"
              inputProps={{
                inputMode: 'decimal',
                step: 0.01,
              }}
            />
          </div>
        </div>
      ))}
    </CustomDialog>
  );
};

export default AreaDistances;
