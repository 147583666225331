import { useMessaging } from 'hooks/messaging';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'store/redux/selector';
import { Product } from 'types/product';

interface UseProductAmount {
  handleAmountDown(): void;
  handleAmountUp(): void;
  amount: number;
}

type ProductWithAmount = Product & { amount?: number };

export function useProductAmount<T extends ProductWithAmount>(product: T | null): UseProductAmount {
  const restaurant = useSelector(state => state.restaurant);

  const [amount, setAmount] = useState(() => {
    if (restaurant?.configs.use_fractional_product_quantity) {
      return product?.cadence_quantity ?? 1;
    }

    return 1;
  });

  const { handleOpen } = useMessaging();

  useEffect(() => {
    if (!product) {
      return;
    }

    if (product.amount) {
      setAmount(product.amount);
    }
  }, [product, restaurant]);

  const step = useMemo(
    () => (restaurant?.configs.use_fractional_product_quantity ? product?.cadence_quantity || 1 : 1),
    [restaurant, product]
  );

  const handleAmountUp = useCallback(() => {
    if (!product?.ready) {
      handleOpen('Você precisa selecionar os itens obrigatórios');
      return;
    }

    setAmount(state => state + step);
  }, [product, handleOpen, step]);

  const handleAmountDown = useCallback(() => {
    if (!product?.ready) {
      handleOpen('Você precisa selecionar os itens obrigatórios');
      return;
    }

    setAmount(state => (state > product.cadence_quantity ? state - step : state));
  }, [handleOpen, product, step]);

  return {
    handleAmountDown,
    handleAmountUp,
    amount: parseFloat(amount.toFixed(2)),
  };
}
