import React from 'react';
import { makeStyles, List, ListItem } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  preLoading1: {
    padding: 6,
    width: 100,
    backgroundColor: '#eee',
    marginBottom: 7,
  },
  preLoading2: {
    height: 24,
    width: 80,
    backgroundColor: '#eee',
    marginBottom: 10,
  },
  preLoading3: {
    height: 20,
    width: 200,
    backgroundColor: '#eee',
    marginBottom: 7,
  },
  preLoading4: {
    padding: 6,
    width: 250,
    backgroundColor: '#eee',
    marginBottom: 7,
  },
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    border: '1px solid #f5f5f5',
    paddingTop: 15,
    height: 180,
  },
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
    gridGap: 7,
    flex: 1,
    gridAutoRows: 'min-content',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  container: {
    display: 'flex',
    flex: 1,
  },
}));

function OrderLoading() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <List className={classes.list}>
        {Array.from(Array(6).keys()).map(item => (
          <ListItem key={item} className={classes.listItem}>
            <div className={`animated-background ${classes.preLoading1}`} />
            <div className={`animated-background ${classes.preLoading3}`} />
            <div className={`animated-background ${classes.preLoading4}`} />
            <div className={`animated-background ${classes.preLoading2}`} />
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default OrderLoading;
