import React, { FC, useEffect, useState } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import { User } from 'types/user';
import { api } from 'services/api';
import { UsersProvider } from './hooks/useUsers';
import UsersActions from './UsersActions';
import { useMessaging } from 'hooks/messaging';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/nodata/NoData';
import UserListTable from './list/table/UserListTable';
import UserListModule from './list/module/UserListModule';
import { useGridMode } from 'hooks/useGridMode';
import useTableOrder from 'hooks/tableOrder';
import { makeStyles } from '@material-ui/core';
import UsersFilterBox from './UsersFilterBox';
import { useFetchUsers } from './hooks/useFetchUsers';
import Loading from 'components/loading/Loading';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import UsersHeaderButtons from './UsersHeaderButtons';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

const Users: FC = () => {
  const classes = styles();
  const { users, setUsers, loading, fetch } = useFetchUsers();
  const [saving, setSaving] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const { handleOpen } = useMessaging();
  const { showErrorDialog } = useErrorHandler();
  const [filtered, setFiltered] = useState<User[]>([]);
  const [gridMode, setGridMode] = useGridMode('users-grid-mode');
  const [orderedIndex, sort] = useTableOrder();

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    setFiltered(users);
  }, [users]);

  function handleToggleUserState() {
    if (!selectedUser) {
      return;
    }

    setSaving(true);

    api
      .patch(`users/${selectedUser.id}/status`)
      .then(() => {
        handleOpen('usuário atualizado');
        handleUserState(selectedUser.id);
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível atualizar o usuário',
        });
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleUserState(userId: number): void {
    setUsers(users =>
      users.map(user => {
        if (user.id === userId) {
          user.active = !user.active;
          user.formattedActive = user.active ? 'Sim' : 'Não';
          return user;
        }
        return user;
      })
    );
  }

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleSearch() {
    fetch();
  }

  return (
    <UsersProvider
      value={{
        users,
        handleToggleUserState,
        selectedUser,
        setSelectedUser,
      }}
    >
      <CustomAppbar title="Usuários" ActionComponents={<UsersActions />} />

      <PageHeaderActions
        ActionComponent={<UsersHeaderButtons />}
        title="Usuários"
        description="Gestão dos usuários da loja"
      />

      <UsersFilterBox gridMode={gridMode} setGridMode={setGridMode} handleSearch={handleSearch} />

      {saving && <Loading />}

      {loading ? (
        gridMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : filtered.length === 0 ? (
        <NoData message="Nenhum usuário para mostrar" />
      ) : (
        <div className={classes.container}>
          {gridMode === 'list' ? (
            <UserListTable users={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
          ) : (
            gridMode === 'module' && <UserListModule users={filtered} />
          )}
        </div>
      )}
    </UsersProvider>
  );
};

export default Users;
