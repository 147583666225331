import React, { useState, useEffect, FormEvent } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import CategoryAction from './CategoryAction';
import { api } from 'services/api';
import {
  categoryChange,
  deletePhoto,
  setSelectedPhoto,
  setCategory,
  availabilityChange,
} from 'store/modules/category/actions';
import history from 'services/history';
import { useParams } from 'react-router-dom';
import CategoryDeleteDialog from './CategoryDeleteDialog';
import DialogConfirm from 'components/dialog/DialogConfirm';
import CategoryDeleteDialogAction from './CategoryDeleteDialogAction';
import { useMessaging } from 'hooks/messaging';
import InsideLoading from 'components/loading/InsideLoading';
import { useCategoryReducer } from 'store/modules/category/reducer';
import { useCategoryValidation } from '../validation/categoryValidation';
import { Category } from 'types/category';
import CategoryTabs from '../CategoryTabs';
import { CategoryProvider } from '../hooks/useCategory';
import CategoryForm from '../CategoryForm';
import CategoryExtraForm from '../CategoryExtraForm';
import CategoryAvailability from '../CategoryAvailability';
import PageHeader from 'components/page-header/PageHeader';
import { makeStyles } from '@material-ui/core';
import ErrorMessage from 'components/errors/Error';

const styles = makeStyles({
  form: {
    marginTop: 20,
  },
});

const CategoryUpdate: React.FC = () => {
  const classes = styles();
  const { handleOpen } = useMessaging();
  const [category, dispatch] = useCategoryReducer();
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dialogDelete, setDialogDelete] = useState(false);
  const [tab, setTab] = useState(0);
  const [validation, setValidation, validate] = useCategoryValidation();
  const { id } = useParams<{ id: string }>();
  const [error, setError] = useState('');

  useEffect(() => {
    api
      .get(`/categories/${id}`)
      .then(response => {
        dispatch(setCategory(response.data));
      })
      .catch(err => {
        setError(err.response ? err.response.data.error : 'Não foi possível carregar a categoria');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, id]);

  function handleChange(value: any, index: keyof Category) {
    dispatch(categoryChange(index, value));
  }

  function handleValidation(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();
    setValidation({});

    validate(category)
      .then(() => handleSubmit())
      .catch(err => {
        console.error(err);
        setTab(0);
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`categories/${category.id}`, category)
      .then(() => {
        handleOpen('Salvo!');
        history.push('/menu/categories');
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 422) {
            setTab(0);
            handleOpen('Não salvou. Verifique os campos obrigatórios');
          }
          handleOpen(err.response.data.error);
        } else handleOpen(err.message);
      })
      .finally(() => setSaving(false));
  }

  function handleTabChange(value: number) {
    setTab(value);
  }

  function handleImageSelect() {
    dispatch(setSelectedPhoto());
  }

  function handlePhotoDelete() {
    dispatch(deletePhoto());
  }

  function getCancelAction() {
    if (category.imageSelected) return handleImageSelect;
  }

  function handleDelete() {
    setDialogDelete(true);
  }

  function handleConfirmDelete() {
    setSaving(true);

    api
      .delete(`categories/${category.id}`)
      .then(() => {
        setSaving(false);
        handleOpen('Excluído');
        history.push('/menu/categories');
      })
      .catch(err => {
        handleOpen(err.response ? err.response.data.error : 'Erro ao excluir');
        setSaving(false);
      });
  }

  function handleStatus() {
    setSaving(true);

    api
      .put(`/categories/${category.id}/status`)
      .then(() => {
        handleOpen('Atualizado');
        handleChange(!category.activated, 'activated');
        history.push('/menu/categories');
        setSaving(false);
      })
      .catch(err => {
        setSaving(false);
        console.log(err);
      });
  }

  function handleAvailabilityChange(day: number, status: boolean) {
    dispatch(availabilityChange(day, status));
  }

  return (
    <CategoryProvider value={{ dispatch, category }}>
      {saving && <Loading />}
      {dialogDelete && (
        <DialogConfirm
          onExited={() => setDialogDelete(false)}
          title="Excluir categoria"
          Action={<CategoryDeleteDialogAction />}
        >
          <CategoryDeleteDialog handleDelete={handleConfirmDelete} />
        </DialogConfirm>
      )}

      <CustomAppbar
        backAction={getCancelAction()}
        title={category.imageSelected ? 'Foto' : 'Nova categoria'}
        ActionComponents={
          <CategoryAction
            saving={saving}
            loading={loading}
            handlePhotoDelete={handlePhotoDelete}
            photoSelected={category.imageSelected}
            handleDelete={handleDelete}
            handleStatus={handleStatus}
            activated={category.activated}
            handleValidation={handleValidation}
          />
        }
        Tab={<CategoryTabs tab={tab} handleTabChange={handleTabChange} />}
      />

      <PageHeader
        title="Editar categoria"
        description="Edição de categoria de produto"
        backAction={() => history.push('/menu/categories')}
      />

      {loading ? (
        <InsideLoading />
      ) : error ? (
        <ErrorMessage text={error} />
      ) : (
        <form onSubmit={handleValidation} className={classes.form}>
          {tab === 0 && <CategoryForm validation={validation} category={category} handleChange={handleChange} />}
          {tab === 1 && <CategoryExtraForm category={category} handleChange={handleChange} />}
          {tab === 2 && (
            <CategoryAvailability
              availability={category.availability}
              handleAvailabilityChange={handleAvailabilityChange}
            />
          )}
          <button type="submit" style={{ display: 'none' }} />
        </form>
      )}
    </CategoryProvider>
  );
};

export default CategoryUpdate;
