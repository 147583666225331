import { TableTemplate } from 'types/tableTemplate';

export const boardMovementsTableTemplate: TableTemplate[] = [
  {
    id: 'board_number',
    description: 'NÚMERO',
    originalId: 'board_number',
    width: 100,
  },
  {
    id: 'formattedIsOpen',
    description: 'ESTADO',
    originalId: 'is_open',
    width: 150,
  },
  {
    id: 'customerName',
    description: 'NOME',
    originalId: 'customerName',
    width: 300,
  },
  {
    id: 'formattedCreatedAt',
    description: 'CRIADO EM',
    originalId: 'formattedCreatedAt',
    width: 200,
  },
];
