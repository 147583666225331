import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { RestaurantAddress } from 'types/restaurant';

const styles = makeStyles(theme => ({
  mapHeader: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 64,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.8) 25%, white 100%)',
    padding: '20px 30px',
    '& > p': {
      fontWeight: 500,
    },
    [theme.breakpoints.down('md')]: {
      top: 56,
      padding: '15px 15px 20px',
    },
    [theme.breakpoints.between('xs', 'xs') + ' and (orientation: landscape)']: {
      top: 50,
    },
  },
}));

interface GoogleMapHeaderProps {
  address: RestaurantAddress;
}

const GoogleMapHeader: React.FC<GoogleMapHeaderProps> = ({ address }) => {
  const classes = styles();

  return (
    <>
      {address.address ? (
        <div className={classes.mapHeader}>
          {address.number ? (
            <Typography variant="body2">{`${address.address}, ${address.number}`}</Typography>
          ) : (
            <Typography variant="body2">{address.address}</Typography>
          )}
          {address.district ? (
            <Typography
              variant="body2"
              color="textSecondary"
            >{`${address.district}, ${address.city}, ${address.region}`}</Typography>
          ) : (
            <Typography variant="body2" color="textSecondary">{`${address.city}, ${address.region}`}</Typography>
          )}
        </div>
      ) : (
        <div className={classes.mapHeader}>
          <Typography color="error">Não foi possível encontrar a localização</Typography>
        </div>
      )}
    </>
  );
};

export default GoogleMapHeader;
