import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import history from 'services/history';
import { Product } from 'types/product';

type ProductItemModuleProps = {
  product: Product;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    backgroundColor: '#fff',
    border: '1px solid #eee',
    position: 'relative',
    alignItems: 'center',
    minHeight: 120,
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: 100,
    overflow: 'hidden',
  },
  img: {
    width: '100%',
    borderRadius: 4,
  },
  productData: {
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1',
    alignItems: 'flex-start',
  },
  price: {
    fontWeight: 300,
  },
  oldPrice: {
    textDecoration: 'line-through',
  },
  specialPrice: {},
  specialPriceContent: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    minWidth: 135,
  },
});

const ProductItemModule: React.FC<ProductItemModuleProps> = ({ product }) => {
  const classes = useStyles();

  return (
    <ListItem
      key={product.id}
      className={classes.listItem}
      button
      onClick={() => history.push(`/menu/product/${product.id}`)}
      style={!product.activated ? { opacity: 0.7 } : undefined}
    >
      <div className={classes.productData}>
        <Typography variant="h6">{product.name}</Typography>
        <Typography variant="body2" color="textSecondary">
          {product.description}
        </Typography>
        {product.promotion_activated && !!product.special_price && product.special_price > 0 ? (
          <div className={classes.specialPriceContent}>
            <Typography variant="body2" color="textSecondary" className={classes.oldPrice}>
              {product.formattedPrice}
            </Typography>
            <Typography variant="h6" className={classes.specialPrice} color="primary">
              {product.formattedSpecialPrice}
            </Typography>
          </div>
        ) : (
          product.price > 0 && (
            <Typography variant="h6" className={classes.price} color="primary">
              {product.formattedPrice}
            </Typography>
          )
        )}
        {product.category?.has_complement && <Typography color="primary">Produto com complemento</Typography>}
      </div>
      {product.image && (
        <div className={classes.imageWrapper}>
          <img
            className={classes.img}
            src={product.image.imageThumbUrl ? product.image.imageThumbUrl : product.image.imageUrl}
            alt={product.name}
          />
        </div>
      )}
    </ListItem>
  );
};

export default ProductItemModule;
