import { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { Invoice } from 'types/invoice';

const socket = io(`${process.env.REACT_APP_EXPRESS_SERVER}/invoice`);

type UseInvoiceSocket = [Socket, boolean];

export function useInvoiceSocket(invoice: Invoice | null): UseInvoiceSocket {
  const [connected, setConnected] = useState(socket.connected);

  useEffect(() => {
    socket.on('connect', () => {
      setConnected(true);
    });

    socket.on('disconnect', () => {
      setConnected(false);
    });

    socket.on('invoice_paid', (response: any) => {
      console.log('invoice_paid', response);
    });

    return () => {
      socket.off('disconnect');
      socket.off('connect');
    };
  }, []);

  useEffect(() => {
    if (connected && invoice) {
      socket.emit('register', invoice.id);
    }
  }, [connected, invoice]);

  return [socket, connected];
}
