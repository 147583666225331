import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';

interface ReportData {
  initialDate: Date | null;
  finalDate: Date | null;
}

export type TopProductsReportValidation = {
  initialDate?: string;
  finalDate?: string;
};

type UseTopProductsReportValidation = [
  TopProductsReportValidation,
  Dispatch<SetStateAction<TopProductsReportValidation>>,
  (data: ReportData) => Promise<void>
];

export function useTopProductsReportValidation(): UseTopProductsReportValidation {
  const [validation, setValidation] = useState<TopProductsReportValidation>({} as TopProductsReportValidation);

  async function handleValidation(data: ReportData) {
    const schema = yup.object().shape({
      finalDate: yup.date().typeError('Informe uma data válida').required('O nome é obrigatório'),
      initialDate: yup.date().typeError('Informe uma data válida').required('A URL é obrigatória'),
    });

    try {
      await schema.validate(data);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as any]: err.message,
        });
      }
      throw err;
    }
  }

  return [validation, setValidation, handleValidation];
}
