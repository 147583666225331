import React, { useState } from 'react';
import DialogInput, { DialogInputConsumer } from 'components/dialog/DialogInput';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: 250,
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      height: 200,
    },
  },
  total: {
    fontWeight: 500,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
}));

PromotionCategoryValue.propTypes = {
  onExited: PropTypes.func.isRequired,
  selectedCategory: PropTypes.object.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default function PromotionCategoryValue({ onExited, selectedCategory, handleConfirm }) {
  const classes = useStyles();
  const [value, setValue] = useState('');

  function handleSubmit(event, handleCloseDialog) {
    event.preventDefault();
    handleCloseDialog();
    handleConfirm(value);
  }

  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <DialogInputConsumer>
        {({ handleCloseDialog }) => (
          <div className={classes.container}>
            <form onSubmit={e => handleSubmit(e, handleCloseDialog)} className={classes.form}>
              <Typography>Valor total dos produtos da categoria {selectedCategory.name}?</Typography>
              <TextField
                label="Total (R$)"
                placeholder="Valor total dos produtos da categoria"
                fullWidth
                margin="normal"
                value={value}
                onChange={e => setValue(e.target.value)}
                autoFocus
                type="number"
                inputProps={{
                  inputMode: 'decimal',
                }}
              />
              <div className={classes.actions}>
                <Button type="submit" disabled={value.length === 0} color="primary" variant="contained">
                  Confirmar
                </Button>
                <Button variant="outlined" color="primary" onClick={handleCloseDialog}>
                  Cancelar
                </Button>
              </div>
            </form>
          </div>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
}
