import { createContext, Dispatch, useContext } from 'react';
import { Promotion } from 'types/promotion';

export type PromotionContextValue = {
  handleNext(): Promise<void>;
  handlePrior(): void;
  dispatch: Dispatch<any>;
  promotion: Promotion;
};

const PromotionContext = createContext<PromotionContextValue>({} as PromotionContextValue);
export const PromotionProvider = PromotionContext.Provider;
export const PromotionConsumer = PromotionContext.Consumer;

export function usePromotion(): PromotionContextValue {
  const context = useContext(PromotionContext);
  return context;
}
