import React, { Dispatch, SetStateAction } from 'react';
import { IconButton } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { useModal } from 'components/modal/hooks/useModal';

interface NewCashRegisterMovementAppbarButtonsProps {
  handleValidation(): Promise<void>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const NewCashRegisterMovementAppbarButtons: React.FC<NewCashRegisterMovementAppbarButtonsProps> = ({
  handleValidation,
  loading,
  setLoading,
}) => {
  const { handleModalClose } = useModal();

  function handleClick() {
    setLoading(true);
    handleValidation()
      .then(handleModalClose)
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }

  return (
    <IconButton color="inherit" onClick={handleClick}>
      <Done />
    </IconButton>
  );
};

export default NewCashRegisterMovementAppbarButtons;
