const steps = [
  {
    order: 1,
    title: 'Nome e descrição',
    description: 'Informe alguns dados dessa promoção',
    id: 'STEP_1',
  },
  {
    order: 2,
    title: 'Categorias',
    description: 'Selecione as categorias de produtos que participarão da promoção',
    id: 'STEP_2',
  },
  {
    order: 3,
    title: 'Produtos',
    description: 'Selecione os produtos que participarão da promoção',
    id: 'STEP_3',
  },
  {
    order: 4,
    title: 'Valor do pedido',
    description: 'Valor do pedido que entrará na promoção',
    id: 'STEP_4',
  },
  {
    order: 5,
    title: 'Desconto',
    description: 'Desconto concedido para os produtos ou categorias selecionadas',
    id: 'STEP_5',
  },
  {
    order: 6,
    title: 'Produtos',
    description: 'Quais serão os produtos que oferecidos gratuitamente?',
    id: 'STEP_6',
  },
  {
    order: 7,
    title: 'Disponibilidade',
    description: 'Disponibilidade da promoção durante a semana',
    id: 'STEP_7',
  },
  {
    order: 8,
    title: 'Salvar',
    description: 'Se estiver tudo certo, confirme a promoção',
    id: 'STEP_8',
  },
];

export { steps };
