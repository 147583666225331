import React from 'react';
import { Grid, TextField, Switch, FormControlLabel } from '@material-ui/core';

function IngredientForm({ ingredient, handleChange }) {
  return (
    <Grid item xl={3} lg={4} md={5} xs={4}>
      <TextField
        label="Nome do ingrediente"
        placeholder="Digite o nome do ingrediente"
        margin="normal"
        value={ingredient.name}
        onChange={event => handleChange('name', event.target.value)}
        autoFocus
        fullWidth
      />
      <FormControlLabel
        style={{ marginTop: 30 }}
        control={
          <Switch
            checked={ingredient.activated}
            onChange={event => handleChange('activated', event.target.checked)}
            value="activated"
            color="primary"
          />
        }
        label="Ativo"
      />
    </Grid>
  );
}

export default IngredientForm;
