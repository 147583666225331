import React, { useState, useEffect, FormEvent, MouseEvent } from 'react';
import { useApp } from 'App';
import { useDispatch } from 'react-redux';
import { useMessaging } from 'hooks/messaging';
import CustomAppbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import AccountForm from './AccountForm';
import * as yup from 'yup';
import { api } from 'services/api';
import Loading from 'components/loading/Loading';
import InsideLoading from 'components/loading/InsideLoading';
import AccountActions from './AccountActions';
import { setUser as reduxSetUser } from 'store/redux/modules/user/actions';
import { useSelector } from 'store/redux/selector';
import { Image } from 'types/image';
import { User } from 'types/user';

export interface Validation {
  phone?: string;
  name?: string;
}
const Account: React.FC = () => {
  const app = useApp();
  const messaging = useMessaging();
  const reduxDispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [account, setAccount] = useState<User>({
    id: 0,
    active: true,
    email: '',
    image: {} as Image,
    image_id: null,
    name: '',
    phone: '',
    rule: 'admin-admin',
    created_at: '',
  });
  const [validation, setValidation] = useState<Validation>({} as Validation);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!user) return;

    setAccount({
      id: user.id,
      email: user.email,
      name: user.name,
      phone: user.phone,
      image: user.image,
      active: true,
      rule: 'admin-operator',
      image_id: 0,
      created_at: '',
    });
  }, [user]);

  function handleValidation(event?: FormEvent) {
    event?.preventDefault();

    const schema = yup.object().shape({
      phone: yup
        .string()
        .transform((value, originalValue) => {
          return originalValue.replace(/\D/g, '');
        })
        .min(10, 'Telefone inválido')
        .required('O telefone é obrigatório'),
      name: yup.string().required('O nome é obrigatório'),
    });

    schema
      .validate(user)
      .then(() => {
        setValidation({});
        handleSubmit();
      })
      .catch(err => {
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put(`/users/${account.id}`, account)
      .then(response => {
        messaging.handleOpen('Salvo');
        reduxDispatch(reduxSetUser(response.data));
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível salvar');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleChange(index: keyof User, value: any): void {
    setAccount(account => {
      if (index === 'image' && !!value) {
        return {
          ...account,
          image: value,
          image_id: value.id,
        };
      }

      return {
        ...account,
        [index]: value,
      };
    });
  }

  function handleResetPasswordLinkClick(event: MouseEvent<HTMLAnchorElement>): void {
    event.preventDefault();
    setSaving(true);

    api
      .post('auth/forgot', { email: account.email })
      .then(() => {
        messaging.handleOpen('Um link foi enviado para o e-mail informado');
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Aconteceu um erro');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <CustomAppbar
        title="Minha conta"
        ActionComponents={<AccountActions handleValidation={handleValidation} saving={saving} />}
      />
      {app.loading ? (
        <InsideLoading />
      ) : (
        <>
          <PageHeader
            title={`Olá ${user.name}`}
            description="Aqui você pode fazer alguns ajustes na sua conta de usuário"
          />
          <AccountForm
            handleChange={handleChange}
            account={account}
            handleValidation={handleValidation}
            validation={validation}
            handleResetPasswordLinkClick={handleResetPasswordLinkClick}
          />
        </>
      )}
    </>
  );
};

export default Account;
