import { Dispatch, useReducer } from 'react';
import { BoardManagement, BoardManagementActions } from './types';

const INITIAL_STATE: BoardManagement = {
  boards: [],
};

function boardManagementReducer(state = INITIAL_STATE, action: BoardManagementActions): BoardManagement {
  switch (action.type) {
    case 'SET_BOARDS': {
      return {
        boards: action.boards,
      };
    }

    case 'SET_BUSY_BOARD': {
      const boards = state.boards.map(board => {
        if (board.id === action.boardId) {
          board.state = 'busy';
          board.current_movement = action.boardMovement;
        }
        return board;
      });

      return {
        ...state,
        boards,
      };
    }

    case 'SET_FREE_BOARD': {
      const boards = state.boards.map(table => {
        if (table.id === action.boardId) {
          table.state = 'free';
          table.current_movement = null;
        }

        return table;
      });

      return {
        ...state,
        boards,
      };
    }

    default: {
      return state;
    }
  }
}

type UseTableManagementReducer = [BoardManagement, Dispatch<BoardManagementActions>];

export function useBoardManagementReducer(): UseTableManagementReducer {
  const [boardManagement, dispatch] = useReducer(boardManagementReducer, INITIAL_STATE);

  return [boardManagement, dispatch];
}
