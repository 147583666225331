import { MenuItem, TextField, makeStyles } from '@material-ui/core';
import Modal from 'components/modal/Modal';
import KeyboardDatePicker from 'components/pickers/KeyboardDatePicker';
import { FC } from 'react';
import { ReceiptsQueryParams } from './Receipts';

const styles = makeStyles({
  container: {
    padding: '0 10px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
});

interface ReceiptsMobileFilterProps {
  onExited(): void;
  queryParams: ReceiptsQueryParams;
  handleParamsChange(index: keyof ReceiptsQueryParams, value: any): void;
}

const ReceiptsMobileFilter: FC<ReceiptsMobileFilterProps> = ({ onExited, queryParams, handleParamsChange }) => {
  const classes = styles();

  return (
    <Modal onExited={onExited} maxWidth="sm" title="Filtro">
      <div className={classes.container}>
        <TextField
          select
          label="Status"
          fullWidth
          autoFocus
          value={queryParams.status}
          onChange={e => handleParamsChange('status', e.target.value)}
          margin="normal"
        >
          <MenuItem value="all">todos</MenuItem>
          <MenuItem value="created">pendente</MenuItem>
          <MenuItem value="authorized">autorizado</MenuItem>
          <MenuItem value="cancelled">cancelado</MenuItem>
        </TextField>
        <KeyboardDatePicker
          label="Data inicial"
          value={queryParams.initial_date}
          onChange={date => handleParamsChange('initial_date', date)}
          autoOk
          margin="normal"
        />
        <KeyboardDatePicker
          label="Data Final"
          value={queryParams.final_date}
          onChange={date => handleParamsChange('final_date', date)}
          autoOk
          margin="normal"
        />
      </div>
    </Modal>
  );
};

export default ReceiptsMobileFilter;
