import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Button, IconButton, makeStyles } from '@material-ui/core';
import { useCustomerAddress } from '../hooks/useCustomerAddress';
import { Address } from 'types/address';
import GoogleMapHeader from './GoogleMapHeader';
import { useSelector } from 'store/redux/selector';
import OutOfDeliverableAreaAlert from './OutOfDeliverableAreaAlert';
import { GpsFixed } from '@material-ui/icons';
import { useMap } from 'providers/google-maps/MapProvider';
import { Position } from 'types/position';
import { useMaxDistance } from 'hooks/useMaxDistance';
import { useRestaurantAddressPosition } from 'hooks/useRestaurantAddressPosition';
import { useLocation } from 'providers/location';

const styles = makeStyles(theme => ({
  map: {
    height: '80vh',
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    '@media (max-width: 600px)': {
      height: '100vh',
    },
    '& .gm-style-iw-a button': {
      visibility: 'hidden',
    },
    '& .gm-style-iw-c': {
      padding: 0,
    },
    '& .gm-style-iw-d': {
      overflow: 'hidden !important',
    },
  },
  actions: {
    background: 'transparent',
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 0,
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    height: 60,
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  marker: {
    position: 'absolute',
    top: 'calc(50% + 2px)',
    left: 'calc(50% - 15px)',
  },
  currentPosition: {
    position: 'absolute',
    bottom: '20%',
    right: '5%',
    zIndex: 1,
    backgroundColor: 'white',
    border: '1px solid #ccc',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));

let timer: NodeJS.Timeout;

interface CopyGoogleMapProps {
  position: Position;
  address: Address;
}

let marker: google.maps.Marker;
let map: google.maps.Map;

const GoogleMap: React.FC<CopyGoogleMapProps> = ({ position, address }) => {
  const classes = styles();
  const { handleNext, setPosition, setAddress } = useCustomerAddress();
  const restaurant = useSelector(state => state.restaurant);
  const [distance, setDistance] = useState(0);
  const [lastMarkerPosition, setLastMarkerPosition] = useState<Position | null>(null);
  const maxDistance = useMaxDistance();
  const { location: deviceLocation, askPermittionForLocation } = useLocation();
  const restaurantAddressPosition = useRestaurantAddressPosition();
  const [devicePositionRequested, setDeviceLocationRequested] = useState(false);
  const { createMap, createMarker, createCircle, createInfoWindow, getDistanceMarkerPosition, getAddressFromMarker } =
    useMap();

  const outOfDeliverableArea = useMemo(
    () => (restaurant ? distance > restaurant?.delivery_max_distance : false),
    [restaurant, distance]
  );

  const circleRadius = useMemo(() => (restaurant ? restaurant.delivery_max_distance * 1000 : 0), [restaurant]);

  const setMarkerPosition = () => {
    const markerPosition = marker.getPosition();
    if (!markerPosition) {
      return;
    }

    setLastMarkerPosition({ lat: markerPosition.toJSON().lat, lng: markerPosition.toJSON().lng });
  };

  const setPositionFromDevice = useCallback(() => {
    if (!deviceLocation) return;

    map.panTo({ lat: deviceLocation.latitude, lng: deviceLocation.longitude });
    marker.setPosition({ lat: deviceLocation.latitude, lng: deviceLocation.longitude });
    setMarkerPosition();
  }, [deviceLocation]);

  const initMap = useCallback(async () => {
    map = await createMap(position);
    marker = createMarker(map, position);

    const circle = createCircle(map, circleRadius, restaurantAddressPosition);

    const infowindow = createInfoWindow();

    const openWindow = () =>
      infowindow.open({
        anchor: marker,
        map,
        shouldFocus: true,
      });

    const calculateDistance = () => {
      const newDistance = getDistanceMarkerPosition(marker, restaurantAddressPosition);
      setDistance(newDistance);

      if (newDistance > maxDistance) {
        circle.setVisible(true);
        return;
      }

      circle.setVisible(false);
    };

    map.addListener('drag', () => {
      infowindow.close();
      marker.setPosition(map.getCenter());
      setMarkerPosition();
    });

    map.addListener('zoom_changed', () => {
      const position = marker.getPosition();
      if (position) map.panTo(position);
    });

    marker.addListener('position_changed', () => {
      clearTimeout(timer);

      calculateDistance();

      timer = setTimeout(() => {
        openWindow();
        getAddressFromMarker(marker).then(address => {
          if (!address) {
            return;
          }

          setAddress({
            ...address,
            created_at: '',
            customer_id: 0,
            area_region: null,
            formattedDistance: '',
            formattedDistanceTax: '',
            reference_point: '',
            is_main: false,
            distance: null,
            distance_tax: null,
          });
        });
      }, 500);
    });

    openWindow();
    calculateDistance();
    setMarkerPosition();
  }, [
    createMap,
    position,
    createMarker,
    createCircle,
    circleRadius,
    createInfoWindow,
    getDistanceMarkerPosition,
    restaurantAddressPosition,
    maxDistance,
    getAddressFromMarker,
    setAddress,
  ]);

  useEffect(() => {
    initMap().catch(err => console.error(err));
  }, [initMap]);

  useEffect(() => {
    if (!devicePositionRequested) return;

    setPositionFromDevice();
  }, [devicePositionRequested, setPositionFromDevice]);

  function handleCurrentLocationClick() {
    askPermittionForLocation();
    setDeviceLocationRequested(true);
  }

  function handleConfirm() {
    setPosition(lastMarkerPosition);
    handleNext();
  }

  return (
    <>
      <div className={classes.map} id="map" />

      {outOfDeliverableArea ? <OutOfDeliverableAreaAlert /> : <GoogleMapHeader address={address} />}

      <span className={classes.marker}>
        <img src="/images/mark_map.png" />
      </span>

      <IconButton className={classes.currentPosition} onClick={handleCurrentLocationClick}>
        <GpsFixed color="primary" />
      </IconButton>

      <div className={classes.actions}>
        <Button
          disabled={outOfDeliverableArea}
          className={classes.button}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleConfirm}
        >
          Continuar
        </Button>
      </div>
    </>
  );
};

export default GoogleMap;
