import React, { FC } from 'react';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';

const styles = makeStyles(theme => ({
  tooltip: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

const PushNotificationsActions: FC = () => {
  const classes = styles();

  return (
    <Tooltip title="Nova mensagem" className={classes.tooltip}>
      <span>
        <IconButton component={Link} to="/push-notifications/new" color="inherit">
          <AddIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default PushNotificationsActions;
