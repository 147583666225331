import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';

export interface NewCashRegisterMovement {
  value: number;
  description: string;
  type: string;
}

export type NewDebitValidation = {
  value?: string;
  description?: string;
  type?: string;
};

type CashRegisterMovementValidation = [
  NewDebitValidation,
  Dispatch<SetStateAction<NewDebitValidation>>,
  (movement: NewCashRegisterMovement) => Promise<void>
];

export function useCashRegisterMovementValidation(): CashRegisterMovementValidation {
  async function handleFormValidation(movement: NewCashRegisterMovement) {
    const schema = yup.object().shape({
      type: yup.string().required('O tipo é obrigatório'),
      description: yup.string().required('A descrição é obrigatória'),
      value: yup
        .number()
        .typeError('O valor é obrigatório')
        .min(0.1, 'o valor deve ser maior que R$ 0,1')
        .required('O valor é obrigatório'),
    });

    try {
      await schema.validate(movement);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as any]: err.message,
        });
      }
      console.log(err);
      throw err;
    }
  }

  const [validation, setValidation] = useState<NewDebitValidation>({});
  return [validation, setValidation, handleFormValidation];
}
