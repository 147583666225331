import React, { useState } from 'react';
import List from 'components/list/List';
import { usePagination } from 'hooks/pagination';
import CategoryItemModule from './CategoryItemModule';
import { Category } from 'types/category';
import CategoryListMenu from '../ProductsListMenu';

type CategoryListModuleProps = {
  categories: Category[];
};

const CategoryListModule: React.FC<CategoryListModuleProps> = ({ categories }) => {
  const { rowsPerPage, page } = usePagination();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <CategoryListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <List>
        {categories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(category => (
          <CategoryItemModule key={category.id} category={category} setAnchorEl={el => setAnchorEl(el)} />
        ))}
      </List>
    </>
  );
};

export default CategoryListModule;
