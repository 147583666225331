import React from 'react';
import { FormControlLabel, Checkbox, Typography, makeStyles } from '@material-ui/core';
import { availabilityHoursChange, availabilityChange, productChange } from 'store/modules/product/actions';
import TimerPicker from 'components/pickers/TimerPicker';
import { useProduct } from 'pages/products/registration/hooks/useProduct';
import { ProductAvailabilityDay, ProductAvailabilityHours } from 'types/product';

const styles = makeStyles(theme => ({
  container: {
    borderBottom: '1px solid #eee',
    paddingBottom: 30,
    '& > .subtitle': {
      marginBottom: 30,
    },
    '&:last-child': {
      border: 'none',
    },
  },
  content: {
    paddingLeft: 15,
    gap: 15,
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingLeft: 0,
    },
  },
  main: {
    display: 'grid',
    gap: 20,
  },
  availabilityHoursContainer: {
    maxWidth: 400,
  },
}));

const days = {
  0: 'domingo',
  1: 'segunda-feira',
  2: 'terça-feira',
  3: 'quarta-feira',
  4: 'quinta-feira',
  5: 'sexta-feira',
  6: 'sábado',
};

interface ProductAvailabilityProps {
  availability: ProductAvailabilityDay[];
}

export default function ProductAvailability({ availability }: ProductAvailabilityProps) {
  const classes = styles();

  const { product, dispatch } = useProduct();

  function handleChange(index: keyof ProductAvailabilityHours, value: any) {
    dispatch(availabilityHoursChange(index, value));
  }

  function handleAvailabilityChange(day: number, status: boolean) {
    dispatch(availabilityChange(day, status));
  }

  function handleEnvironmentAvailability(index: 'is_available_on_delivery' | 'is_available_on_board', value: boolean) {
    dispatch(productChange(index, value));
  }

  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <Typography className="subtitle" color="textSecondary">
          Dias da semana
        </Typography>
        <div className={classes.content}>
          {availability.map(item => (
            <div key={item.day}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={item.status}
                    onChange={event => handleAvailabilityChange(item.day, event.target.checked)}
                  />
                }
                label={days[item.day]}
              />
            </div>
          ))}
        </div>
      </div>

      <div className={classes.availabilityHoursContainer}>
        <Typography className="subtitle" color="textSecondary">
          Horário
        </Typography>
        <div className={classes.content}>
          <TimerPicker
            label="Hora inicial"
            value={product.availability_hours.start_at as Date | null}
            onChange={date => handleChange('start_at', date)}
            fullWidth={false}
            margin="dense"
          />

          <TimerPicker
            label="Hora final"
            value={product.availability_hours.end_at as Date | null}
            onChange={date => handleChange('end_at', date)}
            fullWidth={false}
            margin="dense"
          />
        </div>
      </div>

      <div className={classes.container}>
        <Typography className="subtitle" gutterBottom color="textSecondary">
          Ambientes
        </Typography>
        <div className={classes.content}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={product.is_available_on_board}
                onChange={event => handleEnvironmentAvailability('is_available_on_board', event.target.checked)}
              />
            }
            label="Disponível em mesas"
          />

          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={product.is_available_on_delivery}
                onChange={event => handleEnvironmentAvailability('is_available_on_delivery', event.target.checked)}
              />
            }
            label="Disponível para delivery"
          />
        </div>
      </div>
    </div>
  );
}
