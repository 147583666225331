import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  button: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

interface CashRegisterActionsProps {
  handleNewCashRegister(): void;
}

const CashRegistersActions: React.FC<CashRegisterActionsProps> = ({ handleNewCashRegister }) => {
  const classes = useStyles();

  return (
    <IconButton className={classes.button} color="inherit" onClick={handleNewCashRegister}>
      <AddIcon />
    </IconButton>
  );
};

export default CashRegistersActions;
