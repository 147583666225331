import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { usePaymentMethods } from '../hooks/usePaymentMethods';

type PaymentMethodListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl(anchorEl: null | HTMLButtonElement): void;
};

const PaymentMethodListMenu: React.FC<PaymentMethodListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selected } = usePaymentMethods();

  function handleClick() {
    setAnchorEl(null);
  }

  return (
    <>
      {selected && (
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={handleClick}>{selected.activated ? 'Pausar' : 'Ativar'}</MenuItem>
        </Menu>
      )}
    </>
  );
};

export default PaymentMethodListMenu;
