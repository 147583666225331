import React, { FC } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

interface AccountActionsProps {
  saving: boolean;
  handleValidation(): void;
}

const AccountActions: FC<AccountActionsProps> = ({ handleValidation, saving }) => {
  return (
    <Tooltip title="Salvar">
      <span>
        <IconButton disabled={saving} onClick={handleValidation} color="inherit">
          <DoneIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default AccountActions;
