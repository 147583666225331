import { Dispatch, SetStateAction, useState } from 'react';
import { Product } from 'types/product';
import * as yup from 'yup';

export type ProductValidation = {
  image?: string;
  url?: string;
  description?: string;
  name?: string;
  special_price?: string;
  price?: string;
  cadence_quantity?: string;
};

type UseProductValidation = [
  ProductValidation,
  Dispatch<SetStateAction<ProductValidation>>,
  (product: Product) => Promise<void>,
  (product: Product) => Promise<void>
];

export function useProductValidation(): UseProductValidation {
  async function handleFormValidation(product: Product) {
    const schema = yup.object().shape({
      image: yup.object().typeError('A imagem é obrigatória').required('A imagem é obrigatória'),
      url: yup.string().required('A URL é obrigatória'),
      description: yup.string().required('A descrição é obrigatória'),
      name: yup.string().required('O nome é obrigatório'),
      cadence_quantity: yup
        .number()
        .typeError('A quantidade da cadência deve ser um número válido')
        .required('A quantidade da cadência é obrigatória'),
    });

    try {
      await schema.validate(product);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as any]: err.message,
        });
      }
      console.log(err);
      throw err;
    }
  }

  async function handlePriceValidation(product: Product) {
    const schema = yup.object().shape({
      special_price: yup.mixed().test('special_price', 'Informe o preço promocional', value => {
        return product.promotion_activated ? !!value : true;
      }),
      price: yup.number().typeError('O preço é obrigatório').required('O nome é obrigatório'),
    });

    try {
      await schema.validate(product);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as any]: err.message,
        });
      }
      console.log(err);
      throw err;
    }
  }

  const [validation, setValidation] = useState<ProductValidation>({});
  return [validation, setValidation, handleFormValidation, handlePriceValidation];
}
