import React, { HTMLAttributes } from 'react';
import { makeStyles, ListItem } from '@material-ui/core';
import { useTable } from './hook/useTable';

const useStyles = makeStyles({
  row: ({ templateColumns, width }: { templateColumns: string; width?: number }) => ({
    display: 'grid',
    gridTemplateColumns: templateColumns,
    padding: '5px 10px',
    minHeight: 40,
    gridAutoFlow: 'row',
    flexShrink: 0,
    borderBottom: '1px solid #f5f5f5',
    columnGap: 7,
    width: width ? `${width}px` : '100%',
  }),
});

interface TableRowProps extends HTMLAttributes<HTMLDivElement> {
  onClick?(): void;
}

const TableRow: React.FC<TableRowProps> = ({ children, onClick, className, ...rest }) => {
  const { width, templateColumns } = useTable();

  const classes = useStyles({ templateColumns, width });

  return (
    <ListItem {...rest} button className={`${classes.row} ${className}`} onClick={onClick}>
      {children}
    </ListItem>
  );
};

export default TableRow;
