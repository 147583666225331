import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { DialogConfirmContext } from 'components/dialog/DialogConfirm';

const CategoryDeleteDialogAction: React.FC = () => {
  const dialogContext = useContext(DialogConfirmContext);
  return (
    <div>
      <Button onClick={dialogContext.handleClose} variant={'text'} color={'primary'} size={'small'}>
        Cancelar
      </Button>
    </div>
  );
};

export default CategoryDeleteDialogAction;
