import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Ingredient } from 'types/product';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
    minHeight: 100,
    position: 'relative',
    alignItems: 'flex-start',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
    width: 60,
    height: 60,
    borderRadius: '50%',
    backgroundColor: '#eee',
    overflow: 'hidden',
    border: `2px solid #fff`,
    flexShrink: 0,
  },
  image: {
    width: '100%',
  },
  buttonMore: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
});

interface IngredientItemModuleProps {
  ingredient: Ingredient;
}

const IngredientItemModule: React.FC<IngredientItemModuleProps> = ({ ingredient }) => {
  const classes = useStyles();

  return (
    <ListItem
      component={Link}
      key={ingredient.id}
      className={classes.listItem}
      button
      to={`/menu/ingredients/${ingredient.id}`}
    >
      <Typography variant="caption" color="primary">
        Ingrediente {ingredient.id}
      </Typography>
      <Typography variant={'h6'}>{ingredient.name}</Typography>
    </ListItem>
  );
};

export default IngredientItemModule;
