import React, { useState } from 'react';
import { Typography, alpha, makeStyles } from '@material-ui/core';
import UserItemTable from './UserItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import ReceiptListMenu from '../UsersListMenu';
import { User } from 'types/user';
import { useUsers } from 'pages/users/hooks/useUsers';
import { usersTableTemplate } from 'pages/users/constants/usersTableTemplate';

const useStyles = makeStyles(theme => ({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
  inactive: {
    backgroundColor: alpha(theme.palette.error.main, 0.2),
    '&:hover': {
      backgroundColor: alpha(theme.palette.error.main, 0.25),
    },
  },
}));

type UserListTableProps = {
  users: User[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const UserListTable: React.FC<UserListTableProps> = ({ users, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { setSelectedUser } = useUsers();

  function handleClick(user: User) {
    setSelectedUser(user);
  }

  return (
    <>
      <ReceiptListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <TableContainer tableTemplate={usersTableTemplate}>
        <TableContent>
          <TableHeader>
            {usersTableTemplate
              .filter(item => !item.notShow)
              .map(item => (
                <div
                  className={classes.headerItem}
                  key={item.id}
                  onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
                >
                  <Typography noWrap variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId && (
                    <>
                      {orderedIndex.direction === 'asc' ? (
                        <ArrowUpward color="primary" />
                      ) : (
                        <ArrowDownward color="primary" />
                      )}
                    </>
                  )}
                </div>
              ))}
          </TableHeader>

          <TableBody>
            {users.map(user => (
              <TableRow
                className={!user.active ? classes.inactive : undefined}
                key={user.id}
                onClick={() => handleClick(user)}
              >
                <UserItemTable setAnchorEl={el => setAnchorEl(el)} user={user} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </>
  );
};

export default UserListTable;
