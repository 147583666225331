import React from 'react';
import { TextField, MenuItem, Grid } from '@material-ui/core';
import PaymentMethodPagarMe from '../gateway-options/pagarme/PaymentMethodPagarMe';
import PaymentMethodCielo from '../gateway-options/cielo/PaymentMethodCielo';
import PaymentMethodPagSeguro from '../gateway-options/pagseguro/PaymentMethodPagSeguro';
import PageHeader from 'components/page-header/PageHeader';
import { usePaymentMethods } from '../hooks/usePaymentMethods';
import { paymentGateways } from '../gateways';
import PaymentMethodRede from '../gateway-options/rede/PaymentMethodRede';
import PaymentMethodMercadoPago from '../gateway-options/mercado-pago/PaymentMethodMercadoPago';

const gateways = {
  pagarme: <PaymentMethodPagarMe />,
  cielo: <PaymentMethodCielo />,
  pagseguro: <PaymentMethodPagSeguro />,
  rede: <PaymentMethodRede />,
  mercadopago: <PaymentMethodMercadoPago />,
};

export default function PaymentMethodTab2() {
  const { gatewaySettings, handleSubmit, handleConfigsChange } = usePaymentMethods();

  return (
    <Grid container>
      <Grid item xs={12} md={6} lg={5} xl={4}>
        <PageHeader
          title="Configurações do pagamento online"
          description="Escolha o parceiro e informe os dados solicitados"
        />
        <form onSubmit={handleSubmit}>
          <TextField
            select
            label="Gateway de pagamento"
            margin="normal"
            fullWidth
            value={gatewaySettings.gateway}
            onChange={e => handleConfigsChange('gateway', e.target.value)}
          >
            {paymentGateways.map(gateway => (
              <MenuItem value={gateway.id} key={gateway.id}>
                {gateway.name}
              </MenuItem>
            ))}
          </TextField>
          {gateways[gatewaySettings.gateway]}
          <button type="submit" style={{ display: 'none' }} />
        </form>
      </Grid>
    </Grid>
  );
}
