import React, { useState, useEffect, useReducer, useContext } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import AreaAction from 'components/area/AreaAction';
import AreaCitySearch from 'components/area/city/search/AreaCitySearch';
import AreaCity from 'components/area/AreaList';
import Loading from 'components/loading/Loading';
import AreaLoading from 'components/area/AreaLoading';
import AreaCityRegionView from 'components/area/region/AreaRegionView';
import NoData from '../nodata/NoData';
import ConnectionErrorMessage from 'components/errors/ConnectionErrorMessage';
import { api, getCancelTokenSource } from 'services/api';
import { moneyFormat } from 'helpers/NumberFormat';
import { useMessaging } from 'hooks/messaging';
import areaReducer, { INITIAL_STATE as areaInitialState } from 'store/modules/area/reducer';
import { setAreas, addArea, deleteArea, editAreaRegion, deleteAreaRegion } from 'store/modules/area/actions';
import PageHeader from 'components/page-header/PageHeader';
import { Area, AreaRegion } from 'types/area';
import GoogleMap from './map/GoogleMap';

interface AreaContextData {
  dispatch(value: any): void;
  selectedArea: Area;
  areas: Area[];
}

const AreaContext = React.createContext({} as AreaContextData);

export function useArea(): AreaContextData {
  const context = useContext(AreaContext);
  return context;
}

const Areas: React.FC = () => {
  const [areas, dispatch] = useReducer(areaReducer, areaInitialState);
  const [selectedArea, setSelectedArea] = useState<Area>({} as Area);
  const [dialogCitySearch, setDialogCitySearch] = useState(false);
  const [dialogRegions, setDialogRegions] = useState(false);
  const [dialogMap, setDialogMap] = useState(false);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [httpStatusCode, setHttpStatusCode] = useState(200);
  const messaging = useMessaging();

  useEffect(() => {
    setLoading(true);

    let request = true;
    const source = getCancelTokenSource();

    api
      .get('area', { cancelToken: source.token })
      .then(response => {
        if (request) {
          const _areas = response.data.map(city => {
            city.regions = city.regions.map(region => {
              region.formattedTax = moneyFormat(region.tax);
              return region;
            });
            return city;
          });
          dispatch(setAreas(_areas));
          setHttpStatusCode(response.status);
        }
      })
      .catch(err => {
        if (request) {
          if (err.response) setHttpStatusCode(err.response.status);
          else setHttpStatusCode(0);
        }
      })
      .finally(() => {
        if (request) setLoading(false);
        request = false;
      });

    return () => {
      if (request) source.cancel();

      request = false;
    };
  }, []);

  function handleDialogCitySearch() {
    setDialogCitySearch(!dialogCitySearch);
  }

  function handleSetArea(area: Area) {
    const newArea = {
      ...area,
      id: new Date().getTime(),
    };

    setSelectedArea(newArea);
    dispatch(addArea(newArea));
  }

  function handleAreaDelete(areaId: number) {
    dispatch(deleteArea(areaId));
  }

  function handleEditAreaRegion(regionParam: AreaRegion) {
    dispatch(editAreaRegion(regionParam, selectedArea.id));

    setSelectedArea({
      ...selectedArea,
      regions: selectedArea.regions.map(region => {
        return region.id === regionParam.id ? regionParam : region;
      }),
    });
  }

  function handleRegionDelete(regionId: number) {
    if (!selectedArea) return;

    dispatch(deleteAreaRegion(selectedArea.id, regionId));

    setSelectedArea({
      ...selectedArea,
      regions: selectedArea.regions.filter(region => region.id !== regionId),
    });
  }

  function handleSave() {
    setSaving(true);

    api
      .post('/area', areas)
      .then(() => {
        messaging.handleOpen('Salvo');
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.message);
        else messaging.handleOpen('Não foi possível salvar');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <AreaContext.Provider value={{ dispatch, selectedArea, areas }}>
      <CustomAppbar
        title="Área de entrega"
        ActionComponents={
          <AreaAction saving={saving} handleSave={handleSave} handleDialogCitySearch={handleDialogCitySearch} />
        }
      />
      <PageHeader
        title="Defina as áreas de entrega"
        description="Você pode cadastrar cidades, bairros, distâncias e taxas"
      />
      {saving && <Loading />}

      {dialogCitySearch && <AreaCitySearch handleSetArea={handleSetArea} onExited={handleDialogCitySearch} />}

      {dialogMap &&
        selectedArea.restaurant_address &&
        selectedArea.restaurant_address.latitude &&
        selectedArea.restaurant_address.longitude && (
          <GoogleMap
            address={selectedArea.restaurant_address}
            lat={selectedArea.restaurant_address.latitude}
            lng={selectedArea.restaurant_address.longitude}
            onExited={() => setDialogMap(false)}
          />
        )}

      {dialogRegions && (
        <AreaCityRegionView
          selectedArea={selectedArea}
          handleRegionDelete={handleRegionDelete}
          handleEditAreaRegion={handleEditAreaRegion}
          onExited={() => setDialogRegions(false)}
        />
      )}

      {loading ? (
        <AreaLoading />
      ) : areas.length === 0 && httpStatusCode === 200 ? (
        <NoData message="Não há áreas de entrega cadastradas" backgroundColor="inherit" />
      ) : areas.length > 0 ? (
        <AreaCity
          handleAreaDelete={handleAreaDelete}
          areas={areas}
          handleSetSelectedArea={area => setSelectedArea(area)}
          handleShowMap={() => setDialogMap(true)}
          setDialogRegions={() => setDialogRegions(true)}
        />
      ) : (
        areas.length === 0 && httpStatusCode !== 200 && <ConnectionErrorMessage statusCode={httpStatusCode} />
      )}
    </AreaContext.Provider>
  );
};

export default Areas;
