import React, { useCallback, useState } from 'react';
import { api } from 'services/api';
import { TextField, InputAdornment } from '@material-ui/core';
import CustomDialog, { CustomDialogContext } from 'components/dialog/CustomDialog';
import ProductImportList from './ProductImportList';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { moneyFormat } from 'helpers/NumberFormat';
import SearchIcon from '@material-ui/icons/Search';
import { useMessaging } from 'hooks/messaging';
import InsideLoading from 'components/loading/InsideLoading';
import NoData from 'components/nodata/NoData';
import InsideSaving from 'components/loading/InsideSaving';

const useStyles = makeStyles({
  container: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
  },
});

let timer;

ProductImport.propTypes = {
  onExited: PropTypes.func.isRequired,
  handleImportProduct: PropTypes.func.isRequired,
  handleSetSteps: PropTypes.func.isRequired,
  handleSetStep: PropTypes.func.isRequired,
};

export default function ProductImport({ onExited, handleImportProduct, handleSetStep, handleSetSteps }) {
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const messaging = useMessaging();

  const fetchProducts = useCallback(query => {
    setLoading(true);

    api
      .get('/products', { params: { page: 1, rows: 100, q: query } })
      .then(response => {
        const productsResponse = response.data.data;
        setProducts(
          productsResponse.map(product => {
            product.formattedPrice = moneyFormat(product.price);
            return product;
          })
        );
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function handleProductImport(product, handleCloseDialog) {
    setSaving(true);
    api
      .get(`/products/${product.id}`)
      .then(response => {
        handleImportProduct(response.data);
        handleSetSteps(response.data.category);
        handleSetStep(2);
        handleCloseDialog();
      })
      .catch(() => {
        messaging.handleOpen('Aconteceu um erro ao importar o produto');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleSearch(value) {
    setSearchValue(value);
    clearTimeout(timer);

    if (value.length > 0 && value.length <= 2) {
      return;
    }

    timer = setTimeout(() => fetchProducts(value), 500);
  }

  return (
    <CustomDialog title="Importar produto" handleModalState={onExited} displayBottomActions maxWidth="sm">
      {saving && <InsideSaving />}

      <CustomDialogContext>
        {({ handleCloseDialog }) => (
          <div className={classes.container}>
            <TextField
              label="Pesquisar"
              placeholder="Pesquisar produto..."
              margin="normal"
              variant="standard"
              onChange={e => handleSearch(e.target.value)}
              value={searchValue}
              autoFocus
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            {loading ? (
              <InsideLoading />
            ) : products.length === 0 ? (
              <NoData message="Nenhum produto para mostrar" />
            ) : (
              <ProductImportList
                products={products}
                handleProductImport={handleProductImport}
                handleCloseDialog={handleCloseDialog}
              />
            )}
          </div>
        )}
      </CustomDialogContext>
    </CustomDialog>
  );
}
