import { createContext, Dispatch, useContext, SetStateAction } from 'react';
import { BoardControlMovement } from 'types/boardControlMovement';
import { BoardOrderProduct } from 'types/boardOrderProduct';
import { BoardControlActions } from '../reducer/types';

export interface BoardControlContextValue {
  handleDeleteProduct(product: BoardOrderProduct): Promise<void>;
  selectedProduct: BoardOrderProduct | null;
  setSelectedProduct: Dispatch<SetStateAction<BoardOrderProduct | null>>;
  setSelectedProducts: Dispatch<SetStateAction<BoardOrderProduct[]>>;
  selectedProducts: BoardOrderProduct[];
  setIsPaymentModalOpen: Dispatch<SetStateAction<boolean>>;
  handleSetFreeBoard(): Promise<void>;
  movement: BoardControlMovement | null;
  handleSetSelectedProducts(product: BoardOrderProduct): void;
  setShowDialogUpdate: Dispatch<SetStateAction<boolean>>;
  setShowDialogDelete: Dispatch<SetStateAction<boolean>>;
  setShowDialogTransfer: Dispatch<SetStateAction<boolean>>;
  setShowDialogBill: Dispatch<SetStateAction<boolean>>;
  setShowDialogSplit: Dispatch<SetStateAction<boolean>>;
  dispatch(action: BoardControlActions): void;
  handleCreateReceipt(): Promise<void>;
  paidProductIds: number[];
}

const BoardControlContext = createContext<BoardControlContextValue>({} as BoardControlContextValue);
export const BoardControlConsumer = BoardControlContext.Consumer;
export const BoardControlProvider = BoardControlContext.Provider;

export function useBoardControl(): BoardControlContextValue {
  return useContext(BoardControlContext);
}
