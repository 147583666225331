import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import InsideLoading from 'components/loading/InsideLoading';
import { makeStyles } from '@material-ui/core/styles';
import NoData from 'components/nodata/NoData';
import { CashRegister as CashRegisterType } from 'types/cashRegister';
import { useFetchCashRegister } from './hooks/useFetchCashRegisterReport';
import Modal from 'components/modal/Modal';
import CashRegisterActions from './CashRegisterReportModalActions';
import CashRegisterReport from './CashRegisterReport';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 100,
    flex: 0.7,
    '@media print': {
      '&': {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginBottom: 0,
        flex: 1,
      },
    },
  },
}));

interface PaymentMethod {
  payment_method_name: string;
  payment_method_id: number;
  total: number;
  formattedTotal: string;
}

interface Deliverer {
  deliverer_id: number;
  deliverer_name: string;
  quantity: number;
  payment_methods: PaymentMethod[];
  tax: number;
  total: number;
  formattedTax: string;
  formattedTotal: string;
}

interface Product {
  name: string;
  quantity: number;
  total: number;
  formattedTotal: string;
}

export interface CashRegisterData extends CashRegisterType {
  debit: number;
  credit: number;
  current_value: number;
  formattedCurrentValue: string;
  formattedDebit: string;
  formattedCredit: string;
}

export interface Environment {
  total: number;
  quantity: number;
  formattedTotal: string;
  average_ticket: number;
  formattedAverageTicket: string;
}

export interface CashRegisterReportType {
  cash_register: CashRegisterData;
  quantity: number;
  payment_methods: PaymentMethod[];
  deliverers: Deliverer[];
  products: Product[];
  tax: number;
  total: number;
  formattedTotal: string;
  formattedTax: string;
  delivery: Environment;
  board: Environment;
  products_total: number;
  formattedProductsTotal: string;
}

interface CashRegisterReportModalProps {
  onExited(): void;
}

const CashRegisterReportModal: React.FC<CashRegisterReportModalProps> = ({ onExited }) => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const [data, loading] = useFetchCashRegister(id);

  useEffect(() => {
    if (!data) {
      return;
    }

    setTimeout(window.print, 500);
  }, [data]);

  return (
    <Modal
      title="imprimir fechamento de caixa"
      onExited={onExited}
      fullscreen
      componentActions={<CashRegisterActions />}
    >
      {loading ? (
        <InsideLoading />
      ) : !data ? (
        <NoData backgroundColor="transparent" message="Não há movimentações neste caixa" />
      ) : (
        <div className={classes.container}>
          <CashRegisterReport data={data} />
        </div>
      )}
    </Modal>
  );
};

export default CashRegisterReportModal;
