import React, { FC, useEffect, useCallback, useState } from 'react';
import { Button, Typography, makeStyles } from '@material-ui/core';
import Modal from 'components/modal/Modal';
import { api } from 'services/api';
import { Board } from 'types/board';
import { BoardMovement } from 'types/boardMovement';
import BoardControlTransferList from './BoardControlTransferList';
import InsideSaving from 'components/loading/InsideSaving';
import BoardManagementLoading from 'pages/board-management/BoardManagementLoading';
import BoardTransferError from './BoardTransferError';
import { useBoardControl } from '../hooks/useBoardControl';
import { changeMovementBoard } from '../reducer/actions';
import { ModalConsumer } from 'components/modal/hooks/useModal';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 30,
    justifyContent: 'space-around',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: 20,
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface BoardControlTransferProps {
  onExited(): void;
  boardMovementId?: string;
}

const BoardControlTransfer: FC<BoardControlTransferProps> = ({ onExited, boardMovementId }) => {
  const classes = styles();
  const [boards, setBoards] = useState<Board[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const { dispatch } = useBoardControl();

  const fetchBoards = useCallback(async () => {
    setLoading(true);

    const boardRequest = api.get<Board[]>('/boards');
    const boardMovementsRequest = api.get<BoardMovement[]>('/boardMovements', { params: { only_open: 1 } });

    const [boardsResponse, movementsResponse] = await Promise.all([boardRequest, boardMovementsRequest]);

    return boardsResponse.data.filter(board => {
      return !movementsResponse.data.find(movement => movement.board_id === board.id);
    });
  }, []);

  useEffect(() => {
    fetchBoards()
      .then(boards => setBoards(boards))
      .finally(() => setLoading(false));
  }, [fetchBoards, setLoading]);

  function handleTransfer(board: Board, closeModal: () => void): void {
    if (!boardMovementId) {
      return;
    }

    setSaving(true);

    api
      .put(`/boardMovements/${boardMovementId}`, { board_id: board.id })
      .then(() => {
        closeModal();
        dispatch(changeMovementBoard(board));
      })
      .catch(err => {
        setError(err.response ? err.response.data.error : 'Não foi possível transferir a mesa');
        setSaving(false);
      });
  }

  function handleRetry() {
    setError('');

    fetchBoards()
      .then(boards => setBoards(boards))
      .finally(() => setLoading(false));
  }

  return (
    <Modal onExited={onExited} height="60vh">
      {saving && <InsideSaving />}

      {loading ? (
        <BoardManagementLoading />
      ) : error ? (
        <BoardTransferError errorText={error} handleResetError={handleRetry} />
      ) : (
        <div className={classes.container}>
          <Typography gutterBottom variant="h6">
            Escolha uma mesa para transferir
          </Typography>
          <BoardControlTransferList handleTransfer={handleTransfer} boards={boards} />
          <div className={classes.actions}>
            <ModalConsumer>
              {context => (
                <Button onClick={context.handleModalClose} variant="text" color="primary">
                  Cancelar
                </Button>
              )}
            </ModalConsumer>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default BoardControlTransfer;
