import { Button, IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import React, { Dispatch, SetStateAction, useState } from 'react';
import history from 'services/history';
import { useBoardControl } from './hooks/useBoardControl';
import { useSelector } from 'store/redux/selector';
import { api } from 'services/api';

const styles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface BoardButtonsProps {
  saving: boolean;
  setSaving: Dispatch<SetStateAction<boolean>>;
}

const BoardButtons: React.FC<BoardButtonsProps> = ({ setSaving, saving }) => {
  const classes = styles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    setIsPaymentModalOpen,
    movement,
    setShowDialogTransfer,
    handleSetFreeBoard,
    setShowDialogBill,
    handleCreateReceipt,
  } = useBoardControl();
  const restaurant = useSelector(state => state.restaurant);
  const canCreateReceipt = movement?.isBoardPaid && movement.totalPaid > 0;

  function handleCreateOrderClick() {
    if (movement?.customer) {
      history.push(`/board-management/orders?board=${movement?.id}&customer_id=${movement?.customer?.id}`);
      return;
    }

    history.push(`/board-management/orders?board=${movement?.id}`);
  }

  function handleClick() {
    setAnchorEl(null);

    if (!movement?.board) {
      return;
    }

    setSaving(true);

    handleSetFreeBoard()
      .catch(err => console.error(err))
      .finally(() => setSaving(true));
  }

  function handlePrintBillClick() {
    if (restaurant?.configs.use_printer) {
      api.post(`/boardMovements/${movement?.id}/print-billing`, movement).catch(err => console.error(err));
      setAnchorEl(null);
      return;
    }

    setAnchorEl(null);
    setShowDialogBill(true);
  }

  function handlePrintProductionOrder() {
    if (restaurant?.configs.use_printer) {
      api.post(`/board-sessions/${movement?.id}/print`).catch(err => console.error(err));
      setAnchorEl(null);
      return;
    }

    setAnchorEl(null);
  }

  function handleOpenAppBoardClient() {
    window.open(`${restaurant?.configs.board_app_url}?session=${movement?.id}`);
  }

  function handleCreateReceiptClick() {
    setAnchorEl(null);

    setSaving(true);

    handleCreateReceipt().finally(() => setSaving(false));
  }

  return (
    <div className={classes.container}>
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handlePrintBillClick}>imprimir conta</MenuItem>
        <MenuItem onClick={handlePrintProductionOrder}>imprimir pedido para cozinha</MenuItem>
        {movement?.isBoardPaid && movement?.is_open && <MenuItem onClick={() => handleClick()}>liberar mesa</MenuItem>}
        <MenuItem onClick={handleOpenAppBoardClient}>abrir em app de mesa</MenuItem>
        <MenuItem onClick={handleCreateReceiptClick} disabled={!canCreateReceipt}>
          emitir cupom fiscal
        </MenuItem>
      </Menu>

      {movement?.is_open && (
        <Button variant="contained" color="primary" onClick={() => handleCreateOrderClick()} size="small">
          Lançar pedido
        </Button>
      )}

      <Button
        size="small"
        disabled={!movement?.total}
        variant="contained"
        color="primary"
        onClick={() => setIsPaymentModalOpen(true)}
      >
        Pagamento
      </Button>

      {movement?.is_open && (
        <Button onClick={() => setShowDialogTransfer(true)} variant="contained" color="primary" size="small">
          Transferir
        </Button>
      )}

      <IconButton onClick={event => setAnchorEl(event.currentTarget)}>
        <MoreVert />
      </IconButton>
    </div>
  );
};

export default BoardButtons;
