import React from 'react';
import List from 'components/list/List';
import { Grid } from '@material-ui/core';
import ProductItemModule from './ProductItemModule';
import { Product } from 'types/product';

type ProductListModuleProps = {
  products: Product[];
};

const ProductListModule: React.FC<ProductListModuleProps> = ({ products }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {products.map(product => (
            <ProductItemModule key={product.id} product={product} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default ProductListModule;
