import { TableTemplate } from 'types/tableTemplate';

export const customersTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'cpf',
    description: 'CPF',
    originalId: 'cpf',
    width: 200,
  },
  {
    id: 'name',
    description: 'NOME',
    originalId: 'name',
    width: 300,
  },
  {
    id: 'phone',
    description: 'TELEFONE',
    originalId: 'phone',
    width: 100,
  },
  {
    id: 'email',
    description: 'E-MAIL',
    originalId: 'email',
    width: 400,
  },
  {
    id: 'formattedCreatedAt',
    description: 'REGISTRADO EM',
    originalId: 'formattedCreatedAt',
    width: 200,
  },
];
