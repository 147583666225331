import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import KeyboardDatePicker from 'components/pickers/KeyboardDatePicker';

CouponForm.propTypes = {
  coupon: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
};

export default function CouponForm({ coupon, handleChange, validation }) {
  const inputs = {
    name: useRef(null),
    description: useRef(null),
    discount: useRef(null),
  };

  useEffect(() => {
    if (validation.name) inputs.name.focus();
    else if (validation.description) inputs.description.focus();
    else if (validation.discount) inputs.discount.focus();

    // eslint-disable-next-line
  }, [validation]);

  return (
    <>
      <TextField
        inputRef={ref => (inputs.name = ref)}
        label="Nome"
        placeholder="Digite o nome do cupom"
        margin="normal"
        fullWidth
        value={coupon.name}
        onChange={e => handleChange('name', e.target.value)}
        autoFocus
        helperText={validation.name && validation.name}
        error={!!validation.name}
      />
      <TextField
        inputRef={ref => (inputs.description = ref)}
        multiline
        minRows={5}
        label="Descrição"
        placeholder="Digite a descrição do cupom"
        margin="normal"
        fullWidth
        value={coupon.description}
        onChange={e => handleChange('description', e.target.value)}
        helperText={validation.description && validation.description}
        error={!!validation.description}
      />
      <TextField
        label="Quantidade de uso"
        placeholder="Quantas vezes esse cupom pode ser usado"
        margin="normal"
        fullWidth
        value={coupon.usage_amount ? coupon.usage_amount : ''}
        onChange={e => handleChange('usage_amount', e.target.value)}
        helperText={validation.usage_amount && validation.usage_amount}
        error={!!validation.usage_amount}
        type="number"
      />
      <TextField
        label="Quantidade de por cliente"
        placeholder="Quantidade pode cliente"
        margin="normal"
        fullWidth
        value={coupon.amount_customer ? coupon.amount_customer : ''}
        onChange={e => handleChange('amount_customer', e.target.value)}
        helperText={
          validation.amount_customer
            ? validation.amount_customer
            : 'Quantas vezes o mesmo cliente pode usar esse cupom?'
        }
        error={!!validation.amount_customer}
        type="number"
      />
      <KeyboardDatePicker
        label="Válido até"
        placeholder="Digite a data de expiração do cupom"
        value={coupon.valid_at}
        onChange={date => handleChange('valid_at', date)}
        helperText={validation.valid_at && validation.valid_at}
        error={!!validation.valid_at}
        fullWidth
        margin="normal"
        autoOk
      />
      <TextField
        inputRef={ref => (inputs.discount = ref)}
        label={`Desconto ${coupon.discount_type === 'percent' ? '(%)' : '(R$)'}`}
        placeholder={`Valor ${coupon.discount_type === 'percent' ? 'percentual' : 'em reais'} do desconto`}
        margin="normal"
        fullWidth
        value={coupon.discount ? coupon.discount : ''}
        onChange={e => handleChange('discount', e.target.value)}
        helperText={validation.discount && validation.discount}
        error={!!validation.discount}
        type="number"
      />
      <RadioGroup
        name="discountType"
        value={coupon.discount_type}
        onChange={e => handleChange('discount_type', e.target.value)}
      >
        <FormControlLabel value="value" control={<Radio />} label="Valor (R$)" />
        <FormControlLabel value="percent" control={<Radio />} label="Percentual" />
      </RadioGroup>
      <button type="submit" style={{ display: 'none' }} />
    </>
  );
}
