import { Area, AreaRegion, AreaDistance, AreaSetting } from 'types/area';
import { RestaurantAddress } from 'types/restaurant';

export const SET_AREAS = 'SET_AREAS';
export const ADD_AREA = 'ADD_AREA';
export const DELETE_AREA = 'DELETE_AREA';
export const ADD_REGION = 'ADD_REGION';
export const DELETE_REGION = 'DELETE_REGION';
export const EDIT_REGION = 'EDIT_REGION';
export const ADD_DISTANCES = 'ADD_DISTANCES';
export const SET_RESTAURANT_ADDRESS = 'SET_RESTAURANT_ADDRESS';
export const AREA_SETTING_CHANGE = 'AREA_SETTING_CHANGE';

interface SetAreas {
  type: typeof SET_AREAS;
  areas: Area[];
}

interface AddArea {
  type: typeof ADD_AREA;
  area: Area;
}

interface DeleteArea {
  type: typeof DELETE_AREA;
  areaId: number;
}

interface AddAreaRegion {
  type: typeof ADD_REGION;
  region: AreaRegion;
  areaId: number;
}

interface DeleteRegion {
  type: typeof DELETE_REGION;
  areaId: number;
  regionId: number;
}

interface EditRegion {
  type: typeof EDIT_REGION;
  region: AreaRegion;
  areaId: number;
}

interface AddDistances {
  type: typeof ADD_DISTANCES;
  areaId: number;
  distances: AreaDistance[];
}

interface SetRestaurantAddress {
  type: typeof SET_RESTAURANT_ADDRESS;
  address: RestaurantAddress;
  areaId: number;
}

interface AreaSettingChangeAction {
  type: typeof AREA_SETTING_CHANGE;
  index: keyof AreaSetting;
  value: boolean;
  areaId: number;
}

export type AreaActionTypes =
  | SetAreas
  | AddArea
  | DeleteArea
  | AddAreaRegion
  | DeleteRegion
  | EditRegion
  | AddDistances
  | SetRestaurantAddress
  | AreaSettingChangeAction;
