import { Button, makeStyles, Typography } from '@material-ui/core';
import DialogInput, { DialogInputConsumer } from 'components/dialog/DialogInput';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { BoardMovementPayment } from 'types/boardMovementPayment';

const styles = makeStyles({
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-around',
    flexDirection: 'column',
    textAlign: 'center',
  },
  actions: {
    display: 'flex',
    gap: 20,
    justifyContent: 'center',
  },
});

interface BoardControlPaymentConfirmDeleteProps {
  onExited(): void;
  handleDeletePayment(paymentId: string): Promise<void>;
  selectedPayment?: BoardMovementPayment | null;
  setSelectedPayment: Dispatch<SetStateAction<BoardMovementPayment | null>>;
}

const BoardControlPaymentConfirmDelete: React.FC<BoardControlPaymentConfirmDeleteProps> = ({
  onExited,
  handleDeletePayment,
  selectedPayment,
  setSelectedPayment,
}) => {
  const [loading, setLoading] = useState(false);
  const classes = styles();

  function handleClick(closeDialog: () => void) {
    if (!selectedPayment) {
      return;
    }

    setLoading(true);

    handleDeletePayment(selectedPayment.id)
      .then(() => {
        setSelectedPayment(null);
        closeDialog();
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }
  return (
    <DialogInput onExited={onExited} maxWidth="xs" hideBackdrop>
      <DialogInputConsumer>
        {context => (
          <div className={classes.container}>
            <Typography>Deseja realmente excluir o pagamento?</Typography>

            <div className={classes.actions}>
              <Button onClick={context.handleCloseDialog} variant="text" color="primary">
                Não
              </Button>
              <Button
                disabled={loading}
                onClick={() => handleClick(context.handleCloseDialog)}
                variant="contained"
                color="primary"
              >
                Sim, excluir!
              </Button>
            </div>
          </div>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default BoardControlPaymentConfirmDelete;
