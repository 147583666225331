import React from 'react';
import { FormControlLabel, Grid, Checkbox } from '@material-ui/core';
import { Category } from 'types/category';

type CategoryExtraFormProps = {
  category: Category;
  handleChange(value: any, index: keyof Category);
};

const CategoryExtraForm: React.FC<CategoryExtraFormProps> = ({ category, handleChange }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} xl={3} md={6} lg={3}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                value="ispizza"
                color="primary"
                checked={Boolean(category.is_pizza)}
                onChange={event => handleChange(event.target.checked, 'is_pizza')}
              />
            }
            label="É pizza?"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                value="has_additional"
                color="primary"
                checked={Boolean(category.has_additional)}
                onChange={event => handleChange(event.target.checked, 'has_additional')}
              />
            }
            label="Tem adicional?"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                value="has_ingredient"
                color="primary"
                checked={Boolean(category.has_ingredient)}
                onChange={event => handleChange(event.target.checked, 'has_ingredient')}
              />
            }
            label="Tem ingrediente?"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                value="has_complement"
                color="primary"
                checked={Boolean(category.has_complement)}
                onChange={event => handleChange(event.target.checked, 'has_complement')}
              />
            }
            label="Tem complemento?"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CategoryExtraForm;
