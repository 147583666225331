import React, { useMemo } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import ReceiptsIntegrationStatus from './ReceiptsIntegratorStatus';
import { moneyFormat } from 'helpers/NumberFormat';
import { ReceiptsTotals } from './hooks/useFetchReceipts';
import { useApp } from 'App';

const styles = makeStyles(theme => ({
  container: {
    padding: '15px 20px',
    backgroundColor: '#fff',
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
    '& > .totals > div': {
      gap: 20,
      display: 'flex',
      marginTop: 15,
      '& > div': {
        width: 150,
        '& > .success': {
          color: theme.palette.success.main,
        },
        '& > .warning': {
          color: theme.palette.warning.main,
        },
      },
    },
  },
}));

interface ReceiptTotalsProps {
  totals: ReceiptsTotals;
}

const ReceiptTotals: React.FC<ReceiptTotalsProps> = ({ totals }) => {
  const classes = styles();
  const app = useApp();
  const authorized = useMemo(() => moneyFormat(totals.authorized), [totals.authorized]);
  const created = useMemo(() => moneyFormat(totals.created), [totals.created]);

  return (
    <div className={classes.container}>
      <div className="totals">
        <Typography variant="caption" color="textSecondary">
          Total para o periodo selecionado
        </Typography>
        <div>
          <div>
            <Typography variant="subtitle2">Autorizado</Typography>
            <Typography variant="h5" className="success">
              {authorized}
            </Typography>
          </div>

          <div>
            <Typography variant="subtitle2">Pendente</Typography>
            <Typography variant="h5" className="warning">
              {created}
            </Typography>
          </div>
        </div>
      </div>

      {!app.isMobile && <ReceiptsIntegrationStatus />}
    </div>
  );
};

export default ReceiptTotals;
