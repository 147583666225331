import { uuidv4 } from 'helpers/uuid';
import { FiscalSerial } from 'pages/fiscal-serials/types/fiscal-serial';
import { Dispatch, useReducer } from 'react';
import { FiscalSerialActions } from './types';

const INITIAL_STATE: FiscalSerial = {
  id: uuidv4(),
  type: 'sat',
  active: true,
  created_at: '',
  serial: '',
  sequence: 1,
};

function fiscalSerialReducer(state = INITIAL_STATE, action: FiscalSerialActions): FiscalSerial {
  switch (action.type) {
    case 'FISCAL_SERIAL_CHANGE': {
      return {
        ...state,
        [action.key]: action.value,
      };
    }

    case 'SET_FISCAL_SERIAL': {
      return action.serial;
    }

    default: {
      return state;
    }
  }
}

type UseBoardReducer = [FiscalSerial, Dispatch<FiscalSerialActions>];

export function useFiscalSerialReducer(): UseBoardReducer {
  return useReducer(fiscalSerialReducer, INITIAL_STATE);
}
