import React, { ChangeEvent } from 'react';
import { TextField, Typography, InputAdornment, makeStyles } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';

const styles = makeStyles({
  inputContainer: {
    margin: '30px 0 60px',
  },
});

type EmailStepProps = {
  email: string;
  handleChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  loading: boolean;
};

const EmailStep: React.FC<EmailStepProps> = ({ email, handleChange, loading }) => {
  const classes = styles();
  return (
    <>
      <Typography align="center" variant="body2" color="textSecondary">
        Olá. Para iniciar, digite seu e-mail.
      </Typography>
      <div className={classes.inputContainer}>
        <TextField
          label="E-mail"
          placeholder="Digite o seu e-mail"
          fullWidth
          value={email}
          onChange={e => (loading ? undefined : handleChange(e))}
          name="email"
          type="email"
          required
          autoFocus
          autoComplete="username email"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <PersonIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </>
  );
};

export default EmailStep;
