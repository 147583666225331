import React, { useState, useEffect } from 'react';
import { api, getCancelTokenSource } from 'services/api';
import ProductLoading from 'pages/products/ProductsLoading';
import PromotionOfferedProductList from './PromotionOfferedProductList';
import { moneyFormat } from 'helpers/NumberFormat';
import ConnectionErrorMessage from 'components/errors/ConnectionErrorMessage';
import ProductComplement from './complement/ProductComplement';
import ProductPizzaComplement from './pizza_complement/ProductPizzaComplement';
import ProductConfirmation from './simple/ProductSimple';
import PropTypes from 'prop-types';
import NoData from 'components/nodata/NoData';
import { promotionSetOfferedProducts } from 'store/modules/promotion/actions';
import { usePromotion } from 'pages/promotion/hooks/usePromotion';

OfferedProducts.propTypes = {
  offeredProducts: PropTypes.array.isRequired,
};

export default function OfferedProducts({ offeredProducts }) {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [httpStatusCode, setHttpStatusCode] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { dispatch } = usePromotion();

  useEffect(() => {
    const source = getCancelTokenSource();
    let request = true;

    api
      .get('/promotion/products', {
        cancelToken: source.token,
      })
      .then(response => {
        if (request) {
          const newProducts = response.data.map(product => {
            product.selected = offeredProducts.some(p => product.id === p.product_id);
            product.product_id = product.product_id || product.id;
            product.formattedPrice = moneyFormat(product.price);
            product.formattedSpecialPrice = moneyFormat(product.special_price);
            product.ingredients = product.ingredients.map(ingredient => {
              ingredient.ingredient_id = ingredient.id;
              ingredient.selected = true;
              return ingredient;
            });
            product.additional = product.additional.map(additional => {
              additional.selected = false;
              additional.additional_id = additional.id;
              additional.formattedPrice = additional.price ? moneyFormat(additional.price) : null;
              return additional;
            });
            product.complement_categories = product.complement_categories.map(category => {
              category.product_complement_category_id = category.id;
              category.complements = category.complements.map((complement, index) => {
                complement.product_complement_id = complement.id;
                complement.selected = category.is_pizza_size && index === 0;
                complement.formattedPrice = complement.price && moneyFormat(complement.price);

                complement.prices = complement.prices.map((price, index) => {
                  price.product_complement_price_id = price.id;
                  price.formattedPrice = price.price && moneyFormat(price.price);
                  price.selected = index === 0;
                  return price;
                });

                complement.ingredients = complement.ingredients.map(ingredient => {
                  ingredient.product_complement_ingredient_id = ingredient.id;
                  return ingredient;
                });

                complement.additional = complement.additional.map(additional => {
                  additional.product_complement_additional_id = additional.id;
                  additional.prices = additional.prices.map((price, index) => {
                    price.product_complement_additional_price_id = price.id;
                    price.selected = index === 0;
                    price.formattedPrice = price.price && moneyFormat(price.price);
                    return price;
                  });
                  return additional;
                });
                return complement;
              });
              return category;
            });
            return product;
          });

          setProducts(newProducts);
          setHttpStatusCode(response.status);
        }
      })
      .catch(err => {
        if (request) setHttpStatusCode(err.response ? err.response.status : 0);
      })
      .finally(() => {
        if (request) setLoading(false);
        request = false;
      });

    return () => {
      if (request) source.cancel();
      request = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleConfirmProduct(product) {
    const newProducts = products.map(p => {
      p.selected = p.id === product.id ? true : p.selected;
      return p;
    });

    setProducts(newProducts);

    dispatch(promotionSetOfferedProducts(product));
  }

  return (
    <>
      {selectedProduct && (
        <>
          {selectedProduct.category.is_pizza ? (
            <ProductPizzaComplement
              onExited={() => setSelectedProduct(null)}
              selectedProduct={selectedProduct}
              handleConfirmProduct={handleConfirmProduct}
            />
          ) : selectedProduct.category.has_complement ? (
            <ProductComplement
              onExited={() => setSelectedProduct(null)}
              selectedProduct={selectedProduct}
              handleConfirmProduct={handleConfirmProduct}
            />
          ) : (
            <ProductConfirmation
              onExited={() => setSelectedProduct(null)}
              selectedProduct={selectedProduct}
              handleConfirmProduct={handleConfirmProduct}
            />
          )}
        </>
      )}
      {loading ? (
        <ProductLoading />
      ) : products.length > 0 ? (
        <PromotionOfferedProductList products={products} handleSelect={product => setSelectedProduct(product)} />
      ) : httpStatusCode === 200 && products.length === 0 ? (
        <NoData message="Não há produtos para exibir" />
      ) : (
        <ConnectionErrorMessage statusCode={httpStatusCode} />
      )}
    </>
  );
}
