import { Dispatch, SetStateAction, useState } from 'react';
import { PaymentMethod } from 'types/paymentMethod';
import * as yup from 'yup';

export interface PaymentMethodValidation {
  method?: string;
  kind?: string;
  mode?: string;
  sefaz_code?: string;
}

type UsePaymentMethodValidation = [
  PaymentMethodValidation,
  Dispatch<SetStateAction<PaymentMethodValidation>>,
  (method: PaymentMethod) => Promise<void>
];

export function usePaymentMethodValidation(): UsePaymentMethodValidation {
  const [validation, setValidation] = useState<PaymentMethodValidation>({} as PaymentMethodValidation);

  async function handleValidation(method: PaymentMethod) {
    const schema = yup.object().shape({
      method: yup.string().required('nome é obrigatório'),
      kind: yup.string().required('tipo é obrigatório'),
      mode: yup.string().required('modo é obrigatório'),
      sefaz_code: yup.string().required('código SEFAZ obrigatório'),
    });

    try {
      await schema.validate(method);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setValidation({
          [error.path]: error.message,
        });
      }

      throw err;
    }
  }

  return [validation, setValidation, handleValidation];
}
