import React, { Fragment } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

function CustomerFormAction() {
  return (
    <Fragment>
      <Tooltip title="Salvar">
        <IconButton type="submit" color="inherit">
          <DoneIcon />
        </IconButton>
      </Tooltip>
    </Fragment>
  );
}

export default CustomerFormAction;
