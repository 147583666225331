import React, { Fragment } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PropTypes from 'prop-types';

ProductComplementAdditionalAction.propTypes = {
  handleAddComplementAdditional: PropTypes.func.isRequired,
  openDialogImport: PropTypes.func.isRequired,
};

function ProductComplementAdditionalAction({ handleAddComplementAdditional, openDialogImport }) {
  return (
    <Fragment>
      <Tooltip title="Adicionar adicional">
        <IconButton onClick={handleAddComplementAdditional} color="inherit">
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Importar adicional">
        <IconButton color="inherit" onClick={openDialogImport}>
          <SaveAltIcon />
        </IconButton>
      </Tooltip>
    </Fragment>
  );
}

export default ProductComplementAdditionalAction;
