import { TableTemplate } from 'types/tableTemplate';

export const ingredientsTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'name',
    description: 'NOME',
    originalId: 'name',
    width: 500,
  },
  {
    id: 'formattedActive',
    description: 'ATIVADO',
    originalId: 'formattedActive',
    width: 100,
  },
  {
    id: 'formattedCreatedAt',
    description: 'CRIADO EM',
    originalId: 'formattedCreatedAt',
    width: 200,
  },
];
