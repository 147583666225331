import React, { Component } from 'react';
import { Grid, TextField } from '@material-ui/core';
import PhoneInput from 'components/masked-input/PhoneInput';
import { ProfilePhoneActions } from './ProfilePhoneActions';
import CustomDialogForm from 'components/dialog/CustomDialogForm';

class ProfilePhoneForm extends Component {
  state = {
    phone: {
      phone: '',
    },
    validation: {
      phone: [],
    },
  };

  handleChange = event => {
    const value = event.target.value.replace(/\D/g, '');

    if (value.length < 10)
      this.setState({
        validation: {
          phone: ['Telefone inválido'],
        },
      });
    else
      this.setState({
        validation: {
          phone: [],
        },
      });

    this.setState({
      phone: {
        [event.target.name]: event.target.value,
      },
    });
  };

  handleSubmit = () => {
    const { handleProfilePhoneSubmit } = this.props;

    if (this.state.phone.phone) {
      handleProfilePhoneSubmit(this.state.phone);
    }
  };

  render() {
    const { onExited } = this.props;
    const { phone, validation } = this.state;
    return (
      <CustomDialogForm
        maxWidth="sm"
        handleModalState={onExited}
        title="Telefone"
        closeOnSubmit
        handleSubmit={this.handleSubmit}
        componentActions={<ProfilePhoneActions />}
        height="60vh"
      >
        <Grid container spacing={0}>
          <Grid item xs={12} md={6} lg={6} xl={6}>
            <TextField
              required
              error={validation.phone.length > 0}
              helperText={validation.phone.length > 0 && validation.phone[0]}
              variant="standard"
              margin="normal"
              fullWidth
              name="phone"
              label="Telefone"
              placeholder="Digite o telefone"
              onChange={this.handleChange}
              value={phone.phone}
              autoFocus
              InputProps={{
                inputComponent: PhoneInput,
              }}
            />
          </Grid>
        </Grid>
      </CustomDialogForm>
    );
  }
}

export default ProfilePhoneForm;
