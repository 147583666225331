import React from 'react';
import CustomDialog from 'components/dialog/CustomDialog';
import { ListItem, List, Typography, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import UpIcon from '@material-ui/icons/ArrowUpward';
import DownIcon from '@material-ui/icons/ArrowDownward';
import { makeStyles } from '@material-ui/core/styles';
import { complementCategoriesReorder } from 'store/modules/product/actions';
import { useProduct } from 'pages/products/registration/hooks/useProduct';

const useStyles = makeStyles({
  list: {},
  listItem: {
    display: 'flex',
    backgroundColor: '#fff',
    border: '1px solid #eee',
    borderRadius: 4,
    position: 'relative',
    alignItems: 'center',
    minHeight: 75,
    justifyContent: 'space-between',
    marginBottom: 6,
  },
});

ProductComplementReorder.propTypes = {
  onExited: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
};

export default function ProductComplementReorder({ onExited, categories }) {
  const classes = useStyles();
  const { dispatch } = useProduct();

  function handleComplementCategoriesReorder(from, to) {
    dispatch(complementCategoriesReorder(from, to));
  }

  return (
    <CustomDialog title="Reorganizar complementos" handleModalState={onExited} height="80vh" maxWidth="sm">
      <List>
        {categories.map((category, index) => (
          <ListItem key={category.id} className={classes.listItem}>
            <Typography variant="body1">{category.name}</Typography>
            <div>
              <IconButton
                disabled={index + 1 === categories.length}
                onClick={() => handleComplementCategoriesReorder(index, index + 1)}
              >
                <DownIcon />
              </IconButton>
              <IconButton disabled={index === 0} onClick={() => handleComplementCategoriesReorder(index, index - 1)}>
                <UpIcon color="primary" />
              </IconButton>
            </div>
          </ListItem>
        ))}
      </List>
    </CustomDialog>
  );
}
