import React from 'react';
import { ListItem, Typography, List } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
  },
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: 6,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

PrintersList.propTypes = {
  printers: PropTypes.array.isRequired,
};

export default function PrintersList({ printers }) {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      {printers.map(printer => (
        <ListItem key={printer.id} className={classes.listItem} button component={Link} to={`/printers/${printer.id}`}>
          <Typography>{printer.name}</Typography>
          <Typography color="textSecondary">{printer.address}</Typography>
          {printer.activated ? (
            <Typography variant="caption" color="secondary">
              Ativada
            </Typography>
          ) : (
            <Typography variant="caption" color="error">
              Desativada
            </Typography>
          )}
        </ListItem>
      ))}
    </List>
  );
}
