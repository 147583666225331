import React, { useState, useEffect } from 'react';
import { api, getCancelTokenSource } from 'services/api';
import PrintersLoading from './PrintersLoading';
import PrintersList from './PrintersList';
import PrintersActions from './PrintersActions';
import CustomAppbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import NoData from 'components/nodata/NoData';
import ConnectionErrorMessage from 'components/errors/ConnectionErrorMessage';

export default function Printers() {
  const [printers, setPrinters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [httpStatusCode, setHttpStatusCode] = useState(200);

  useEffect(() => {
    const source = getCancelTokenSource();
    let request = true;

    api
      .get('/printers', { cancelToken: source.token })
      .then(response => {
        setPrinters(response.data);
      })
      .catch(err => {
        if (request)
          if (err.response) setHttpStatusCode(err.response.status);
          else setHttpStatusCode(0);
      })
      .finally(() => {
        setLoading(false);
        request = false;
      });

    return () => {
      if (request) source.cancel();
      request = false;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CustomAppbar title="Impressoras" ActionComponents={<PrintersActions />} />
      <PageHeader
        title="Impressoras"
        description="As impressoras podem ser vinculadas a produtos para impressão organizada por setor"
      />
      {loading ? (
        <PrintersLoading />
      ) : printers.length > 0 ? (
        <PrintersList printers={printers} />
      ) : printers.length === 0 && httpStatusCode === 200 ? (
        <NoData message="Não há impressoras cadastradas" />
      ) : (
        <ConnectionErrorMessage statusCode={httpStatusCode} />
      )}
    </>
  );
}
