import React from 'react';
import CategoryLoading from 'pages/categories/CategoryLoading';
import NoData from 'components/nodata/NoData';
import ProductCategoryList from './ProductCategoryList';
import history from 'services/history';
import { useProduct } from 'pages/products/registration/hooks/useProduct';

function CategorySelectStep() {
  const { categories, handleSetCategory, loading } = useProduct();

  return (
    <>
      {loading ? (
        <CategoryLoading />
      ) : categories.length === 0 && !loading ? (
        <NoData
          message="Opa, não há categoria cadastrada."
          buttonText="Cadastrar categoria"
          action={() => {
            history.push('/menu/categories/new');
          }}
        />
      ) : (
        <ProductCategoryList handleSetCategory={handleSetCategory} categories={categories} />
      )}
    </>
  );
}

export default CategorySelectStep;
