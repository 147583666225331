import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Typography } from '@material-ui/core';
import { getStatusText } from '../orderStatusMapping';
import { OrderStatus } from 'types/orderStatus';
import { Deliverer } from 'types/deliverer';
import { useOrders } from '../hook/useOrders';

const useStyles = makeStyles(theme => ({
  historyStatus: {
    borderRadius: '50%',
    display: 'inline-flex',
    width: 25,
    height: 25,
    position: 'relative',
    zIndex: 2,
    '&::after': {
      content: '" "',
      height: 50,
      backgroundColor: '#ccc',
      display: 'block',
      position: 'absolute',
      width: 3,
      top: 25,
      left: 11,
      zIndex: 1,
    },
  },
  p: { backgroundColor: '#17a2b8', color: '#fff' },
  o: { backgroundColor: '#ffc107' },
  a: { backgroundColor: '#8BC34A', color: '#fff' },
  d: { backgroundColor: '#007bff', color: '#fff' },
  c: { backgroundColor: '#6c757d', color: '#fff' },
  x: { backgroundColor: '#dc3545', color: '#fff' },
  historyList: {
    marginBottom: 20,
    padding: 0,
    minHeight: 200,
  },
  historyListItem: {
    '&:last-child span::after': {
      display: 'none',
    },
  },
  historyContent: {
    padding: '0 10px',
  },
  statusDate: {
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
}));

type OrderStatusListProps = {
  histories: OrderStatus[];
  deliverers: Deliverer[];
};

const OrderStatusList: React.FC<OrderStatusListProps> = ({ histories, deliverers }) => {
  const classes = useStyles();
  const { selectedOrder: order } = useOrders();

  return (
    <List className={classes.historyList}>
      {histories.map(status => (
        <ListItem key={status.id} className={classes.historyListItem} disableGutters>
          <span className={`${classes.historyStatus} ${classes[status.status]}`} />
          <div className={classes.historyContent}>
            <Typography variant="caption" color="textSecondary" className={classes.statusDate}>
              {status.formattedCreatedAt}
            </Typography>
            <Typography>{getStatusText(order?.shipment?.shipment_method, status.status)}</Typography>

            {status.status === 'd' && (
              <>
                {deliverers.map(deliverer => (
                  <Typography component="p" variant="body2" color="textSecondary" key={deliverer.id}>
                    {deliverer.name} como entregador
                  </Typography>
                ))}
              </>
            )}
          </div>
        </ListItem>
      ))}
    </List>
  );
};

export default OrderStatusList;
