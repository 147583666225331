import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, Switch, FormControlLabel, makeStyles } from '@material-ui/core';

PrinterForm.propTypes = {
  printer: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
};

const styles = makeStyles({
  container: {
    maxWidth: 400,
  },
});

export default function PrinterForm({ printer, handleChange, validation }) {
  const classes = styles();

  const inputs = {
    name: useRef(null),
    address: useRef(null),
  };

  useEffect(() => {
    if (validation.name) inputs.name.focus();
    else if (validation.address) inputs.address.focus();

    // eslint-disable-next-line
  }, [validation]);

  return (
    <div className={classes.container}>
      <TextField
        inputRef={ref => (inputs.name = ref)}
        label="Nome"
        placeholder="Digite o nome da impressora"
        margin="normal"
        fullWidth
        value={printer.name}
        onChange={e => handleChange('name', e.target.value)}
        autoFocus
        helperText={validation.name && validation.name}
        error={!!validation.name}
      />
      <TextField
        inputRef={ref => (inputs.address = ref)}
        label="Endereço da impressora"
        placeholder="Digite o endereço da impressora"
        margin="normal"
        fullWidth
        value={printer.address}
        onChange={e => handleChange('address', e.target.value)}
        helperText={validation.address ? validation.address : String.raw`\\pc\impressora`}
        error={!!validation.address}
      />
      <div>
        <FormControlLabel
          label="Ativada"
          control={
            <Switch
              color="primary"
              checked={printer.activated}
              onChange={e => handleChange('activated', e.target.checked)}
            />
          }
        />
      </div>
      <button type="submit" style={{ display: 'none' }} />
    </div>
  );
}
