import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { api } from 'services/api';
import { io, Socket } from 'socket.io-client';
import { setRestaurantIsOpen, setRestaurantKitchenIsOpen } from 'store/redux/modules/restaurant/actions';
import { useSelector } from 'store/redux/selector';

const socket = io(`${process.env.REACT_APP_EXPRESS_SERVER}/admin`);

type UseAppSocket = [Socket, boolean];

export function useAppSocket(): UseAppSocket {
  const restaurant = useSelector(state => state.restaurant);
  const [connected, setConnected] = useState(socket.connected);
  const dispatch = useDispatch();

  const getRestaurantState = useCallback(() => {
    api
      .get('/restaurant/state')
      .then(response => {
        dispatch(setRestaurantIsOpen(response.data.is_open));
      })
      .catch(err => {
        console.error(err);
      });
  }, [dispatch]);

  useEffect(() => {
    socket.on('connect', () => {
      setConnected(true);
    });

    socket.on('disconnect', () => {
      setConnected(false);
    });

    socket.on('kitchen_state_changed', (response: { is_kitchen_open: boolean }) => {
      dispatch(setRestaurantKitchenIsOpen(response.is_kitchen_open));
    });

    socket.on('handleRestaurantState', (response: { isOpen: boolean }) => {
      dispatch(setRestaurantIsOpen(response.isOpen));
    });

    return () => {
      socket.off('kitchen_state_changed');
      socket.off('handleRestaurantState');
      socket.off('disconnect');
      socket.off('connect');
    };
  }, [dispatch, getRestaurantState, restaurant]);

  useEffect(() => {
    if (restaurant && connected) {
      socket.emit('register', restaurant.id);
      socket.emit('admin_ping', restaurant.id);
    }
  }, [connected, restaurant]);

  return [socket, connected];
}
