import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';

PrinterAction.propTypes = {
  handleFormValidation: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleStatus: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  activated: PropTypes.bool.isRequired,
};

export default function PrinterAction({ handleFormValidation, handleDelete, loading, saving }) {
  return (
    <>
      <Tooltip title="Excluir impressora">
        <IconButton color="inherit" disabled={saving || loading} onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Salvar impressora">
        <IconButton type="submit" disabled={saving || loading} color="inherit" onClick={handleFormValidation}>
          <DoneIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}
