import React, { Dispatch, SetStateAction } from 'react';
import { TextField } from '@material-ui/core';
import { useBoardManagement } from '../hooks/useBoardManagement';

interface OccupyBoardProps {
  referenceName: string;
  setReferenceName: Dispatch<SetStateAction<string>>;
  setDeliveryLocation: Dispatch<SetStateAction<string>>;
  deliveryLocation: string;
}

const OccupyBoard: React.FC<OccupyBoardProps> = ({
  referenceName,
  setReferenceName,
  deliveryLocation,
  setDeliveryLocation,
}) => {
  const { selectedBoard } = useBoardManagement();
  return (
    <div>
      <TextField
        label="Nome do cliente"
        margin="normal"
        fullWidth
        autoFocus
        value={referenceName}
        onChange={e => setReferenceName(e.target.value)}
      />

      {selectedBoard?.delivery_location_required && (
        <TextField
          label="Local de entrega"
          margin="normal"
          fullWidth
          value={deliveryLocation}
          onChange={e => setDeliveryLocation(e.target.value)}
        />
      )}

      <button type="submit" style={{ display: 'none' }} />
    </div>
  );
};

export default OccupyBoard;
