import React from 'react';
import { Typography, makeStyles, ListItem } from '@material-ui/core';
import history from 'services/history';
import { Additional } from 'types/product';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
    minHeight: 100,
    position: 'relative',
    alignItems: 'flex-start',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
    width: 60,
    height: 60,
    borderRadius: '50%',
    backgroundColor: '#eee',
    overflow: 'hidden',
    border: `2px solid #fff`,
    flexShrink: 0,
  },
  image: {
    width: '100%',
  },
  buttonMore: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
});

interface AdditionalItemModuleProps {
  additional: Additional;
}

const AdditionalItemModule: React.FC<AdditionalItemModuleProps> = ({ additional }) => {
  const classes = useStyles();

  function handleClick(additionalId: number) {
    history.push(`/menu/additional/${additionalId}`);
  }

  return (
    <ListItem onClick={() => handleClick(additional.id)} key={additional.id} className={classes.listItem} button>
      <Typography variant={'h6'}>{additional.name}</Typography>
      <Typography color="textSecondary">{additional.formattedPrice}</Typography>
    </ListItem>
  );
};

export default AdditionalItemModule;
