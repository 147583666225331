import { createContext, Dispatch, useContext, SetStateAction } from 'react';
import { BoardChart } from 'types/board';
import { BoardManagement, BoardManagementActions } from '../reducer/types';

export interface BoardManagementContextValue {
  dispatch: Dispatch<BoardManagementActions>;
  boardManagement: BoardManagement;
  handleCreateMovement(referenceName: string, deliveryLocation: string, boardId: string): Promise<void>;
  selectedBoard: BoardChart | null;
  setSelectedBoard: Dispatch<SetStateAction<BoardChart | null>>;
}

const BoardManagementContext = createContext<BoardManagementContextValue>({} as BoardManagementContextValue);
export const BoardManagementProvider = BoardManagementContext.Provider;
export const BoardManagementConsumder = BoardManagementContext.Consumer;

export function useBoardManagement(): BoardManagementContextValue {
  const context = useContext(BoardManagementContext);
  return context;
}
