import React from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { useApp } from 'App';

type CategoryTabsProps = {
  tab: number;
  handleTabChange(value: number): void;
};

const CategoryTabs: React.FC<CategoryTabsProps> = ({ tab, handleTabChange }) => {
  const app = useApp();

  return (
    <Tabs
      value={tab}
      onChange={(event, value) => handleTabChange(value)}
      scrollButtons={'auto'}
      variant={app.isMobile ? 'scrollable' : 'standard'}
    >
      <Tab label="Informações" />
      <Tab label="Extra" />
      <Tab label="Disponibilidade" />
    </Tabs>
  );
};

export default CategoryTabs;
