import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  title: {
    fontWeight: 600,
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
  editButton: {
    position: 'absolute',
    right: 0,
  },
});

ShipmentStatus.propTypes = {
  shipment: PropTypes.object.isRequired,
  handleSetStep: PropTypes.func.isRequired,
  productsAmount: PropTypes.number.isRequired,
};

function ShipmentStatus({ shipment, handleSetStep, productsAmount }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.head}>
        <Typography className={classes.title}>Entrega</Typography>
        {productsAmount > 0 && (
          <Button size="small" className={classes.editButton} color="primary" onClick={() => handleSetStep(4)}>
            Editar
          </Button>
        )}
      </div>
      {shipment.shipment_method === 'delivery' ? (
        <div className={classes.address}>
          <Typography>
            {`${shipment.address}, ${shipment.number}${shipment.complement ? `, ${shipment.complement}` : ``}`}
          </Typography>
          <Typography color="textSecondary">{shipment.district}</Typography>
          {shipment.postal_code !== '00000000' && <Typography color="textSecondary">{shipment.postal_code}</Typography>}
        </div>
      ) : (
        <>
          <Typography>Cliente retira</Typography>
          {shipment.scheduled_at && <Typography>Agendado para as {shipment.formattedScheduledAt}</Typography>}
        </>
      )}

      {shipment.scheduled_at && <Typography>Agendado para as {shipment.formattedScheduledAt}</Typography>}
    </>
  );
}

export default ShipmentStatus;
