import React, { useState, FormEvent } from 'react';
import { useBoardManagement } from '../hooks/useBoardManagement';
import OccupyBoardForm from './OccupyBoardForm';
import InsideSaving from 'components/loading/InsideSaving';
import DialogInput, { DialogInputConsumer } from 'components/dialog/DialogInput';
import { Button, makeStyles, Typography } from '@material-ui/core';
import ErrorMessage from 'components/errors/Error';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flex: 1,
    minHeight: 350,
    alignItems: 'center',
    '& > .title': {
      textAlign: 'center',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  form: {
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface OccupyTableProps {
  onExited(): void;
}

const OccupyTable: React.FC<OccupyTableProps> = ({ onExited }) => {
  const classes = styles();
  const [referenceName, setReferenceName] = useState('');
  const [deliveryLocation, setDeliveryLocation] = useState('');
  const { handleCreateMovement, selectedBoard, setSelectedBoard } = useBoardManagement();
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');

  function handleSubmit(handleCloseModal: () => void, event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    if (!selectedBoard) return;

    setSaving(true);

    handleCreateMovement(referenceName, deliveryLocation, selectedBoard.id)
      .then(() => {
        handleCloseModal();
        setSelectedBoard(null);
      })
      .catch(err => {
        console.error(err);
        setError(err.response ? err.response.data.error : 'Não foi possível ocupar a mesa');
      })
      .finally(() => setSaving(false));
  }

  return (
    <DialogInput maxWidth="sm" onExited={onExited} title="Ocupar mesa">
      <DialogInputConsumer>
        {context =>
          error ? (
            <ErrorMessage text={error} handleReset={() => setError('')} handleResetText="Voltar" />
          ) : (
            <div className={classes.container}>
              {saving && <InsideSaving />}

              <Typography variant="h6" className="title">
                {`Abrir mesa `}
                <strong>{selectedBoard?.number}</strong>
              </Typography>

              <form className={classes.form} onSubmit={e => handleSubmit(context.handleCloseDialog, e)}>
                <OccupyBoardForm
                  setReferenceName={setReferenceName}
                  referenceName={referenceName}
                  deliveryLocation={deliveryLocation}
                  setDeliveryLocation={setDeliveryLocation}
                />
              </form>

              <div className={classes.actions}>
                <Button color="primary" onClick={context.handleCloseDialog} variant="text">
                  Cancelar
                </Button>
                <Button onClick={() => handleSubmit(context.handleCloseDialog)} variant="contained" color="primary">
                  Abrir mesa
                </Button>
              </div>
            </div>
          )
        }
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default OccupyTable;
