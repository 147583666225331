import React, { useState } from 'react';
import AccountAddressesAction from '../CustomerAddressesAction';
import { Address } from 'types/address';
import { useAddressValidation } from '../validation/useAddressValidation';
import { AxiosError } from 'axios';
import AddressForm from '../AddressForm';
import { useMessaging } from 'hooks/messaging';
import Modal from 'components/modal/Modal';
import InsideSaving from 'components/loading/InsideSaving';
import { useSelector } from 'store/redux/selector';
import { CustomerAddress } from 'types/customerAddress';

interface CustomerAddressEditProps {
  handleAddressUpdateSubmit(address: Address, areaRegionId: number | null): Promise<void>;
  handleModalState(): void;
  saving: boolean;
  selectedAddress: CustomerAddress;
}

const CustomerAddressEdit: React.FC<CustomerAddressEditProps> = ({
  handleAddressUpdateSubmit,
  handleModalState,
  saving,
  selectedAddress,
}) => {
  const messaging = useMessaging();
  const [address, setAddress] = useState<CustomerAddress>(selectedAddress);
  const restaurant = useSelector(state => state.restaurant);
  const [validation, setValidation, validate] = useAddressValidation(restaurant);
  const [areaRegionId, setAreaRegionId] = useState<number | null>(address.area_region?.area_region_id ?? null);

  function handleChange(index: keyof Address, value: any) {
    setAddress(state => ({ ...state, [index]: value }));
  }

  async function handleValidation(handleModalClose: () => void) {
    setValidation({});

    validate(address, areaRegionId)
      .then(() => handleSubmit(handleModalClose))
      .catch(err => console.error(err));
  }

  function handleSubmit(handleModalClose: () => void) {
    handleAddressUpdateSubmit(address, areaRegionId)
      .then(handleModalClose)
      .catch(err => {
        const error = err as AxiosError<any>;

        if (error.response) {
          messaging.handleOpen(error.response.data.error);
        }
      });
  }

  return (
    <Modal
      title="atualizar endereço"
      onExited={handleModalState}
      componentActions={<AccountAddressesAction handleValidation={handleValidation} saving={saving} />}
      maxWidth="sm"
      height="80vh"
    >
      {saving && <InsideSaving />}

      <AddressForm
        setAreaRegionId={setAreaRegionId}
        areaRegionId={areaRegionId}
        validation={validation}
        handleChange={handleChange}
        address={address}
      />
    </Modal>
  );
};

export default CustomerAddressEdit;
