import React, { Component, Fragment } from 'react';
import { Button, Collapse, Paper, Switch, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import OpeningHourShift from 'components/opening-hour/OpeningHourShift';

const styles = {
  paper: {
    marginTop: 20,
    padding: 20,
  },
  day: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 10px',
  },
  hours: {
    padding: '0 10px',
  },
  turnButton: {
    marginTop: 15,
  },
  turn: {
    padding: '0 0 15px',
  },
  animatedLoading: {
    width: '100%',
    height: 20,
    margin: '14px 0',
  },
};

class OpeningHoursList extends Component {
  render() {
    const {
      workingTime,
      classes,
      daysOfTheWeek,
      handleChangeDay,
      handleDateChange,
      handleClickNewTurn,
      handleDeleteShift,
    } = this.props;

    return (
      <Fragment>
        {workingTime.map((itemWorkingTime, indexWorkingTime) => {
          return (
            <Paper className={classes.paper} key={indexWorkingTime}>
              <div className={classes.day}>
                <Typography variant={'subtitle2'}>{daysOfTheWeek[itemWorkingTime.day].toUpperCase()}</Typography>
                <Switch
                  color={'primary'}
                  onChange={handleChangeDay(indexWorkingTime)}
                  checked={Boolean(itemWorkingTime.open)}
                />
              </div>
              <Collapse in={Boolean(itemWorkingTime.open)}>
                <div className={classes.hours}>
                  <OpeningHourShift
                    indexWorkingTime={indexWorkingTime}
                    itemWorkingTime={itemWorkingTime}
                    handleDateChange={handleDateChange}
                    handleDeleteShift={handleDeleteShift}
                  />
                  <div className={classes.turnButton}>
                    <Button
                      size={'small'}
                      color={'primary'}
                      variant={'contained'}
                      onClick={() => handleClickNewTurn(indexWorkingTime)}
                    >
                      Novo turno
                    </Button>
                  </div>
                </div>
              </Collapse>
            </Paper>
          );
        })}
      </Fragment>
    );
  }
}

OpeningHoursList.propTypes = {
  workingTime: PropTypes.array,
  daysOfTheWeek: PropTypes.object,
  handleChangeDay: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleClickNewTurn: PropTypes.func,
};

export default withStyles(styles)(OpeningHoursList);
