import React, { useState } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import Loading from 'components/loading/Loading';
import InsideLoading from 'components/loading/InsideLoading';
import RestaurantConfigAction from './RestaurantConfigAction';
import RestaurantConfigForm from './RestaurantConfigForm';
import { useApp } from 'App';
import { makeStyles } from '@material-ui/core';
import { api } from 'services/api';
import { useSelector, useDispatch } from 'react-redux';
import { setRestaurant } from 'store/redux/modules/restaurant/actions';
import { useMessaging } from 'hooks/messaging';
import { useRestaurantConfigValidation } from './validation/useRestaurantConfigValidation';
import { useFetchRestaurantSettings } from './hooks/useFetchRestaurantSettings';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 100,
  },
});

export default function RestaurantConfig() {
  const classes = useStyles();
  const app = useApp();
  const restaurant = useSelector(state => state.restaurant);
  const messaging = useMessaging();
  const dispatch = useDispatch();
  const [settings, setSettings, loading] = useFetchRestaurantSettings();
  const [saving, setSaving] = useState(false);
  const [validation, setValidation, validate] = useRestaurantConfigValidation();

  function handleChange(index, value) {
    setSettings(_settings => ({
      ..._settings,
      [index]: value,
    }));
  }

  function handleValidation(event) {
    event.preventDefault();

    validate(settings)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);
    setValidation({});
    api
      .put('/configs', settings)
      .then(() => {
        messaging.handleOpen('Salvo');
        dispatch(
          setRestaurant({
            ...restaurant,
            configs: settings,
          })
        );
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Erro ao salvar');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}

      <CustomAppbar
        title="Configurações"
        ActionComponents={
          <RestaurantConfigAction loading={app.loading} saving={saving} handleValidation={handleValidation} />
        }
      />

      {loading ? (
        <InsideLoading />
      ) : (
        <div className={classes.container}>
          <PageHeader title="Parâmetros" />
          {settings && (
            <RestaurantConfigForm
              settings={settings}
              handleChange={handleChange}
              handleValidation={handleValidation}
              validation={validation}
            />
          )}
        </div>
      )}
    </>
  );
}
