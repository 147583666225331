import React, { useEffect, useRef } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { ForgotValidation } from './Forgot';

type LoginFormProps = {
  email: string;
  setEmail(email: string): void;
  validation: ForgotValidation;
};

const ForgotForm: React.FC<LoginFormProps> = ({ email, setEmail, validation }) => {
  const inputs = {
    email: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <>
      <TextField
        autoFocus
        inputRef={inputs.email}
        error={!!validation.email}
        helperText={validation.email}
        label="E-mail"
        placeholder="Seu e-mail"
        margin="normal"
        onChange={e => setEmail(e.target.value)}
        value={email}
        autoComplete="username"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <PersonIcon color="primary" />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default ForgotForm;
