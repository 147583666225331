import React, { useState } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import NewPaymentMethodAppbarButtons from './NewPaymentMethodAppbarButtons';
import PageHeader from 'components/page-header/PageHeader';
import { makeStyles } from '@material-ui/core';
import PaymentMethodForm from '../PaymentMethodForm';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import history from 'services/history';
import Loading from 'components/loading/Loading';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { usePaymentMethodReducer } from '../reducer/reducer';
import { usePaymentMethodValidation } from '../validation/usePaymentMethodValidation';
import { paymentMethodChangeAction } from '../reducer/actions';
import { PaymentMethod } from 'types/paymentMethod';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 400,
  },
});

const NewPaymentMethod: React.FC = () => {
  const classes = styles();
  const messaging = useMessaging();
  const { showErrorDialog } = useErrorHandler();
  const [serial, dispatch] = usePaymentMethodReducer();
  const [validation, setValidation, validate] = usePaymentMethodValidation();
  const [saving, setSaving] = useState(false);

  function handleValidation() {
    setValidation({});

    validate(serial)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .post(`/payment-methods`, serial)
      .then(() => {
        messaging.handleOpen('Salvo');
        setSaving(false);
        history.push('/payment-methods');
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível salvar',
        });
        setSaving(false);
      });
  }

  function handleChange(key: keyof PaymentMethod, value: any) {
    dispatch(paymentMethodChangeAction(key, value));
  }

  return (
    <>
      {saving && <Loading />}

      <CustomAppbar
        title="Método de pagamento"
        ActionComponents={<NewPaymentMethodAppbarButtons handleSubmit={handleValidation} />}
      />

      <PageHeader title="Novo método de pagamento" backAction={() => history.push('/payment-methods')} />

      <div className={classes.container}>
        <PaymentMethodForm handleChange={handleChange} paymentMethod={serial} validation={validation} />
      </div>
    </>
  );
};

export default NewPaymentMethod;
