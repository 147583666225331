import React, { Component } from 'react';
import { Card, CardActions, CardContent, Grid, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    marginTop: 15,
  },
  card: {
    margin: '0 10px 0 0',
    minHeight: 255,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      margin: '0 0 10px',
      minHeight: 200,
    },
  },
  noPhones: {
    height: 200,
    padding: 20,
  },
  button: {
    marginTop: 20,
    display: 'flex',
  },
});

class ProfilePhone extends Component {
  render() {
    const { restaurant, classes, handleDialogPhone, handleProfilePhoneDelete } = this.props;

    return (
      <Grid container className={classes.container}>
        {restaurant.phones.length > 0 ? (
          restaurant.phones.map(phone => (
            <Grid item xs={12} xl={2} lg={3} md={6} key={phone.uid}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography variant="caption" color="primary">
                    Telefone
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    {phone.phone}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    onClick={() => handleProfilePhoneDelete(phone.uid)}
                    size="small"
                    color="primary"
                    variant="text"
                  >
                    Excluir
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid container direction="column" className={classes.noPhones}>
            <Typography variant="h6">Nenhum telefone foi cadastro.</Typography>
            <div className={classes.button}>
              <Button color="primary" onClick={handleDialogPhone} variant="contained">
                Incluir telefone
              </Button>
            </div>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(ProfilePhone);
