import React, { useState } from 'react';
import { Button, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import DialogInput, { DialogInputConsumer } from 'components/dialog/DialogInput';
import { useCashRegisterFlow } from './hooks/useCashRegisterFlow';
import history from 'services/history';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-around',
    textAlign: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  buttonYes: {
    width: 150,
  },
});

interface CloseCashRegisterDialogProps {
  onExited(): void;
}

const CloseCashRegisterDialog: React.FC<CloseCashRegisterDialogProps> = ({ onExited }) => {
  const classes = styles();
  const [saving, setSaving] = useState(false);
  const { handleCloseCashRegister } = useCashRegisterFlow();

  function handleClickYes(closeModal: () => void) {
    setSaving(true);
    handleCloseCashRegister()
      .then(() => {
        closeModal();
        history.push('/cash-registers');
      })
      .catch(err => {
        console.error(err);
      });
  }

  return (
    <DialogInput onExited={onExited} maxWidth="sm">
      <DialogInputConsumer>
        {context => (
          <div className={classes.container}>
            <Typography>Deseja realmente fechar o caixa?</Typography>

            <div className={classes.actions}>
              <Button onClick={context.handleCloseDialog} variant="text" color="primary">
                Cancelar
              </Button>
              <Button
                className={classes.buttonYes}
                onClick={() => handleClickYes(context.handleCloseDialog)}
                disabled={saving}
                variant="contained"
                color="primary"
              >
                {saving ? <CircularProgress color="primary" size={16} /> : 'Sim, encerrar'}
              </Button>
            </div>
          </div>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default CloseCashRegisterDialog;
