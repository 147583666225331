import React, { useState, MouseEvent, Dispatch, SetStateAction } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CardActions,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
import AreaDistances from './distances/AreaDistances';
import AreaRestaurantAddresses from './restaurant-address/AreaRestaurantAddresses';
import { Area } from 'types/area';
import AreaSetting from './setting/AreaSetting';
import { useSelector } from 'store/redux/selector';

const useStyles = makeStyles(theme => ({
  card: {
    minHeight: 290,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      margin: '0 0 10px',
      minHeight: 200,
    },
  },
  cardAction: {
    justifyContent: 'space-between',
  },
  regions: {
    height: 100,
  },
  regionName: {
    minWidth: 100,
    display: 'inline-block',
  },
  address: {
    marginTop: 15,
  },
}));

interface AreaListProps {
  areas: Area[];
  handleAreaDelete(areaId: number): void;
  handleSetSelectedArea(area: Area): void;
  handleShowMap(): void;
  setDialogRegions: Dispatch<SetStateAction<boolean>>;
}

const AreaList: React.FC<AreaListProps> = ({
  areas,
  handleAreaDelete,
  handleSetSelectedArea,
  handleShowMap,
  setDialogRegions,
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const classes = useStyles();
  const [dialogDistance, setDialogDistance] = useState(false);
  const [dialogRestaurantAddress, setDialogRestaurantAddress] = useState(false);
  const [dialogAreaSetting, setDialogAreaSetting] = useState(false);
  const restaurant = useSelector(state => state.restaurant);

  const closeMenu = () => {
    setAnchorEl(null);
  };

  function openMenu(event: MouseEvent<HTMLButtonElement>, area: Area) {
    setAnchorEl(event.currentTarget);
    handleSetSelectedArea(area);
  }

  function handleClickDistances() {
    setDialogDistance(true);
    closeMenu();
  }

  function handleSetRestaurantAddress() {
    setDialogRestaurantAddress(true);
    setAnchorEl(null);
  }

  function handleSetDialogAreaSetting() {
    setDialogAreaSetting(true);
    setAnchorEl(null);
  }

  function handleShowInMapClick() {
    setAnchorEl(null);
    handleShowMap();
  }

  function handleClickDialogRegions() {
    setDialogRegions(true);
    setAnchorEl(null);
  }

  return (
    <Grid container spacing={1}>
      {dialogDistance && <AreaDistances onExited={() => setDialogDistance(false)} />}
      {dialogRestaurantAddress && <AreaRestaurantAddresses onExited={() => setDialogRestaurantAddress(false)} />}
      {dialogAreaSetting && <AreaSetting onExited={() => setDialogAreaSetting(false)} />}

      <Menu disableAutoFocusItem open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        <MenuItem onClick={handleSetRestaurantAddress}>Relacionar endereço da loja</MenuItem>
        <MenuItem onClick={handleClickDistances}>Distâncias e taxas</MenuItem>
        <MenuItem onClick={handleShowInMapClick}>Mapa</MenuItem>
        <MenuItem onClick={handleSetDialogAreaSetting}>Configurações</MenuItem>
        {restaurant?.configs.tax_mode === 'district' && <MenuItem onClick={handleClickDialogRegions}>Bairros</MenuItem>}
      </Menu>

      {areas.map(area => {
        return (
          <Grid key={area.id} item xs={12} sm={6} xl={3} md={4} lg={3}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="caption" color="primary">
                  Área
                </Typography>
                <Typography variant="h6">
                  {area.name} - {area.uf}
                </Typography>

                {area.regions.length > 0 && (
                  <Typography variant="body1" color="textSecondary">
                    {area.regions.length} {area.regions.length > 1 ? 'bairros' : 'bairro'}
                  </Typography>
                )}

                {area.restaurant_address && (
                  <div className={classes.address}>
                    <Typography variant="subtitle2">Endereço da loja</Typography>
                    <Typography variant="body2">
                      {area.restaurant_address.address}, {area.restaurant_address.number}
                    </Typography>
                    <Typography variant="body2">{area.restaurant_address.district}</Typography>
                  </div>
                )}
              </CardContent>

              <CardActions className={classes.cardAction}>
                <Button onClick={() => handleAreaDelete(area.id)} size="small" color="primary" variant="text">
                  Excluir
                </Button>
                <IconButton onClick={event => openMenu(event, area)}>
                  <MoreIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AreaList;
