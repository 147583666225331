import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Promotion } from 'types/promotion';

export type PromotionsContextValue = {
  promotions: Promotion[];
  selectedPromotion: null | Promotion;
  setSelectedPromotion: Dispatch<SetStateAction<Promotion | null>>;
};

const PromotionsContext = createContext<PromotionsContextValue>({} as PromotionsContextValue);
export const PromotionsProvider = PromotionsContext.Provider;
export const PromotionsConsumer = PromotionsContext.Consumer;

export function usePromotions(): PromotionsContextValue {
  const context = useContext(PromotionsContext);
  return context;
}
