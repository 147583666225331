import React from 'react';
import { PieChart, Pie, Tooltip, ResponsiveContainer } from 'recharts';
import { percentFormat } from 'helpers/NumberFormat';
import { theme } from 'theme';
import { DashboardPaymentMethod } from 'types/dashboardData';

interface DashboardPaymentMethodsChartProps {
  data: DashboardPaymentMethod[];
}

export default function DashboardPaymentMethodsChart({ data }: DashboardPaymentMethodsChartProps) {
  return (
    <ResponsiveContainer width="95%">
      <PieChart>
        <Pie
          dataKey="value"
          isAnimationActive={false}
          data={data}
          outerRadius={65}
          fill={theme.palette.primary.main}
          fontSize={16}
          label={entry => entry.name}
        />
        <Tooltip formatter={value => percentFormat(value)} />
      </PieChart>
    </ResponsiveContainer>
  );
}
