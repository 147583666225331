import { Category } from 'types/category';
import { Product, Ingredient, Additional } from 'types/product';

export const SET_CATEGORY = 'SET_CATEGORY';
export const PRODUCT_CHANGE = 'PRODUCT_CHANGE';
export const SET_PRODUCT = 'SET_PRODUCT';
export const PHOTO_SELECT = 'PHOTO_SELECT';
export const SET_PRODUCT_INGREDIENTS = 'SET_PRODUCT_INGREDIENTS';
export const SET_PRODUCT_ADDITIONAL = 'SET_PRODUCT_ADDITIONAL';
export const SET_STEP = 'SET_STEP';
export const AVAILABILITY_CHANGE = 'AVAILABILITY_CHANGE';
export const AVAILABILITY_HOURS_CHANGE = 'AVAILABILITY_HOURS_CHANGE';
export const SET_COMPLEMENT_CATEGORIES = 'SET_COMPLEMENT_CATEGORIES';
export const IMPORT_PRODUCT = 'IMPORT_PRODUCT';

export const SET_IS_PIZZA = 'SET_IS_PIZZA';
export const ADD_COMPLEMENT_CATEGORY = 'ADD_COMPLEMENT_CATEGORY';
export const ADD_COMPLEMENT = 'ADD_COMPLEMENT';
export const DELETE_COMPLEMENT_CATEGORY = 'DELETE_COMPLEMENT_CATEGORY';
export const DELETE_COMPLEMENT = 'DELETE_COMPLEMENT';
export const CHANGE_COMPLEMENT_CATEGORY = 'CHANGE_COMPLEMENT_CATEGORY';
export const CHANGE_COMPLEMENT = 'CHANGE_COMPLEMENT';
export const ADD_COMPLEMENT_ADDITIONAL = 'ADD_COMPLEMENT_ADDITIONAL';
export const ADD_COMPLEMENT_INGREDIENT = 'ADD_COMPLEMENT_INGREDIENT';
export const DELETE_COMPLEMENT_ADDITIONAL = 'DELETE_COMPLEMENT_ADDITIONAL';
export const DELETE_COMPLEMENT_INGREDIENT = 'DELETE_COMPLEMENT_INGREDIENT';
export const CHANGE_COMPLEMENT_ADDITIONAL = 'CHANGE_COMPLEMENT_ADDITIONAL';
export const CHANGE_COMPLEMENT_INGREDIENT = 'CHANGE_COMPLEMENT_INGREDIENT';
export const CHANGE_COMPLEMENT_PRICE = 'CHANGE_COMPLEMENT_PRICE';
export const CHANGE_COMPLEMENT_ADDITIONAL_PRICE = 'CHANGE_COMPLEMENT_ADDITIONAL_PRICE';
export const IMPORT_COMPLEMENT_ADDITIONAL = 'IMPORT_COMPLEMENT_ADDITIONAL';
export const IMPORT_COMPLEMENT_INGREDIENTS = 'IMPORT_COMPLEMENT_INGREDIENTS';
export const CATEGORY_COMPLEMENTS_REORDER = 'CATEGORY_COMPLEMENTS_REORDER';
export const CHANGE_COMPLEMENT_STATUS = 'CHANGE_COMPLEMENT_STATUS';

interface SetCategoryAction {
  type: typeof SET_CATEGORY;
  category: Category;
}

interface ProductChangeAction {
  type: typeof PRODUCT_CHANGE;
  index: string;
  value: any;
}

interface SetProductAction {
  type: typeof SET_PRODUCT;
  product: Product;
}

interface PhotoSelectAction {
  type: typeof PHOTO_SELECT;
}

interface SetProductIngredientsAction {
  type: typeof SET_PRODUCT_INGREDIENTS;
  ingredients: Ingredient[];
}

interface SetProductAdditionalAction {
  type: typeof SET_PRODUCT_ADDITIONAL;
  additional: Additional[];
}

interface SetStepAction {
  type: typeof SET_STEP;
  step: number;
}

interface AvailabilityChangeAction {
  type: typeof AVAILABILITY_CHANGE;
  day: number;
  status: boolean;
}

interface AvailabilityHoursChangeAction {
  type: typeof AVAILABILITY_HOURS_CHANGE;
  index: string;
  value: string;
}

interface SetIsPizza {
  type: typeof SET_IS_PIZZA;
}

interface AddComplementCategoryAction {
  type: typeof ADD_COMPLEMENT_CATEGORY;
}

interface AddComplementAction {
  type: typeof ADD_COMPLEMENT;
  complementCategoryId: number;
  isPizza: boolean;
}

interface DeleteComplementCategoryAction {
  type: typeof DELETE_COMPLEMENT_CATEGORY;
  complementCategoryId: number;
}

interface DeleteComplementAction {
  type: typeof DELETE_COMPLEMENT;
  complementCategoryId: number;
  complementId: number;
  isPizza: boolean;
}

interface ChangeComplementCategoryAction {
  type: typeof CHANGE_COMPLEMENT_CATEGORY;
  complementCategoryId: number;
  index: string;
  value: string | number | boolean;
}

interface ChangeComplementAction {
  type: typeof CHANGE_COMPLEMENT;
  complementCategoryId: number;
  complementId: number;
  index: string;
  value: any;
}

interface AddComplementAdditionAction {
  type: typeof ADD_COMPLEMENT_ADDITIONAL;
  complementCategoryId: number;
  complementId: number;
}

interface AddComplementIngredientAction {
  type: typeof ADD_COMPLEMENT_INGREDIENT;
  complementCategoryId: number;
  complementId: number;
}

interface DeleteComplementAdditionalAction {
  type: typeof DELETE_COMPLEMENT_ADDITIONAL;
  complementCategoryId: number;
  complementId: number;
  additionalId: number;
}

interface DeleteComplementIngredient {
  type: typeof DELETE_COMPLEMENT_INGREDIENT;
  complementCategoryId: number;
  complementId: number;
  ingredientId: number;
}

interface ChangeComplementAdditionalAction {
  type: typeof CHANGE_COMPLEMENT_ADDITIONAL;
  complementCategoryId: number;
  complementId: number;
  additionalId: number;
  name: string;
}

interface ChangeComplementIngredientAction {
  type: typeof CHANGE_COMPLEMENT_INGREDIENT;
  complementCategoryId: number;
  complementId: number;
  ingredientId: number;
  name: string;
}

interface ChangeComplementPriceAction {
  type: typeof CHANGE_COMPLEMENT_PRICE;
  complementCategoryId: number;
  complementId: number;
  priceId: number;
  value: number;
}

interface ChangeComplementAdditionalPriceAction {
  type: typeof CHANGE_COMPLEMENT_ADDITIONAL_PRICE;
  complementCategoryId: number;
  complementId: number;
  additionalId: number;
  priceId: number;
  value: number;
}

interface ImportComplementAdditionalAction {
  type: typeof IMPORT_COMPLEMENT_ADDITIONAL;
  fromComplementId: number;
  toComplementId: number;
}

interface ImportComplementIngredientAction {
  type: typeof IMPORT_COMPLEMENT_INGREDIENTS;
  fromComplementId: number;
  toComplementId: number;
}

interface CategoryComplementReorderAction {
  type: typeof CATEGORY_COMPLEMENTS_REORDER;
  from: number;
  to: number;
}

interface ChangeComplementStatusAction {
  type: typeof CHANGE_COMPLEMENT_STATUS;
  complementCategoryId: number;
  complementId: number;
}

interface ImportProductAction {
  type: typeof IMPORT_PRODUCT;
  product: Product;
}

export type ProductActionsType =
  | AvailabilityHoursChangeAction
  | AvailabilityChangeAction
  | SetStepAction
  | SetProductAdditionalAction
  | SetProductIngredientsAction
  | PhotoSelectAction
  | SetProductAction
  | ProductChangeAction
  | SetCategoryAction
  | SetIsPizza
  | AddComplementCategoryAction
  | AddComplementAction
  | DeleteComplementCategoryAction
  | DeleteComplementAction
  | ChangeComplementCategoryAction
  | ChangeComplementAction
  | AddComplementAdditionAction
  | AddComplementIngredientAction
  | DeleteComplementAdditionalAction
  | DeleteComplementIngredient
  | ChangeComplementAdditionalAction
  | ChangeComplementIngredientAction
  | ChangeComplementPriceAction
  | ChangeComplementAdditionalPriceAction
  | ImportComplementAdditionalAction
  | ImportComplementIngredientAction
  | CategoryComplementReorderAction
  | ChangeComplementStatusAction
  | ImportProductAction;
