import React, { FormEvent, useEffect, useRef } from 'react';
import CustomDialog, { CustomDialogContext } from 'components/dialog/CustomDialog';
import { CreditCard } from 'types/creditCard';
import { BillingCardValidation } from './validation/billingCardValidation';
import CardInput from 'components/masked-input/CardInput';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, makeStyles } from '@material-ui/core';
import BillingCardActions from './BillingCardActions';
import CardExpirationDate from 'components/masked-input/CardExperitionDate';
import CardSecurityCode from 'components/masked-input/CardSecurityCode';
import { CpfDocument } from 'components/masked-input/CpfDocument';
import InsideSaving from 'components/loading/InsideSaving';
import CnpjInput from 'components/masked-input/CnpjInput';

const useStyles = makeStyles({
  formGroup: {
    flexDirection: 'row!important' as any,
  },
  formControl: {
    marginTop: '20px!important' as any,
  },
});

type BillingCardFormProps = {
  onExited(): void;
  card: CreditCard;
  validation: BillingCardValidation;
  handleChange(index: keyof CreditCard, value: string): void;
  handleValidation(handleCloseDialog: () => void, e?: FormEvent<HTMLFormElement>): void;
  saving: boolean;
};

const BillingCardForm: React.FC<BillingCardFormProps> = ({
  onExited,
  validation,
  card,
  handleChange,
  handleValidation,
  saving,
}) => {
  const inputs = {
    number: useRef<HTMLInputElement>(null),
    name: useRef<HTMLInputElement>(null),
    expiration_date: useRef<HTMLInputElement>(null),
    cvv: useRef<HTMLInputElement>(null),
    document: useRef<HTMLInputElement>(null),
  };
  const classes = useStyles();

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <CustomDialog
      title="cartão de crédito"
      handleModalState={onExited}
      componentActions={<BillingCardActions handleValidation={handleValidation} />}
      maxWidth="sm"
      height="80vh"
      displayBottomActions={false}
    >
      {saving && <InsideSaving />}
      <CustomDialogContext.Consumer>
        {({ handleCloseDialog }) => (
          <form onSubmit={e => handleValidation(handleCloseDialog, e)}>
            <TextField
              inputRef={inputs.number}
              error={!!validation.number}
              helperText={validation.number}
              label="Número do cartão"
              margin="normal"
              placeholder="Número do cartão"
              value={card.number}
              onChange={e => handleChange('number', e.target.value)}
              fullWidth
              autoFocus
              autoComplete="cc-number"
              InputProps={{
                inputComponent: CardInput as any,
              }}
              inputProps={{
                inputMode: 'numeric',
              }}
            />
            <TextField
              inputRef={inputs.name}
              label="Nome e sobrenome"
              error={!!validation.name}
              helperText={validation.name ? validation.name : 'Assim como está escrito no cartão'}
              margin="normal"
              placeholder="Nome e sobrenome"
              value={card.name}
              onChange={e => handleChange('name', e.target.value)}
              fullWidth
              autoComplete="cc-name"
            />
            <TextField
              inputRef={inputs.expiration_date}
              error={!!validation.expiration_date}
              helperText={validation.expiration_date ? validation.expiration_date : 'MM/AA'}
              label="Vencimento"
              margin="normal"
              placeholder="Vencimento do cartão"
              value={card.expiration_date}
              onChange={e => handleChange('expiration_date', e.target.value)}
              fullWidth
              autoComplete="cc-exp"
              InputProps={{
                inputComponent: CardExpirationDate as any,
              }}
              inputProps={{
                inputMode: 'numeric',
              }}
            />
            <TextField
              inputRef={inputs.cvv}
              label="Código"
              error={!!validation.cvv}
              helperText={validation.cvv ? validation.cvv : 'Últimos três número do verso do seu cartão'}
              margin="normal"
              placeholder="Código de segurança"
              value={card.cvv}
              onChange={e => handleChange('cvv', e.target.value)}
              autoComplete="cc-csc"
              fullWidth
              InputProps={{
                inputComponent: CardSecurityCode as any,
              }}
              inputProps={{
                inputMode: 'numeric',
              }}
            />
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Tipo</FormLabel>
              <RadioGroup
                classes={{ root: classes.formGroup }}
                aria-label="document type"
                value={card.document_type}
                onChange={e => handleChange('document_type', e.target.value)}
              >
                <FormControlLabel value="cnpj" control={<Radio />} label="CNPJ" />
                <FormControlLabel value="cpf" control={<Radio />} label="CPF" />
              </RadioGroup>
            </FormControl>
            {card.document_type === 'cpf' ? (
              <TextField
                inputRef={inputs.document}
                error={!!validation.document}
                helperText={validation.document}
                label="CPF do titular do cartão"
                margin="normal"
                placeholder="CPF do titular do cartão"
                value={card.document}
                onChange={e => handleChange('document', e.target.value)}
                fullWidth
                InputProps={{
                  inputComponent: CpfDocument,
                }}
              />
            ) : (
              <TextField
                inputRef={inputs.document}
                error={!!validation.document}
                helperText={validation.document}
                label="CNPJ do titular do cartão"
                margin="normal"
                placeholder="CNPJ do titular do cartão"
                value={card.document}
                onChange={e => handleChange('document', e.target.value)}
                fullWidth
                InputProps={{
                  inputComponent: CnpjInput as any,
                }}
              />
            )}
            <button type="submit" style={{ display: 'none' }} />
          </form>
        )}
      </CustomDialogContext.Consumer>
    </CustomDialog>
  );
};

export default BillingCardForm;
