import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  container: {
    margin: 30,
  },
});

PromotionConfirm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default function PromotionConfirm({ handleSubmit, saving }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography gutterBottom variant="h6">
        Configuração da promoção finalizada com sucesso.
      </Typography>
      <Button color="primary" variant="contained" onClick={handleSubmit} disabled={saving}>
        Salvar promoção
      </Button>
    </div>
  );
}
