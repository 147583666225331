import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { Done } from '@material-ui/icons';

interface TaxRulesAppbarButtonsProps {
  handleSubmit(): void;
}

const TaxRulesAppbarButtons: React.FC<TaxRulesAppbarButtonsProps> = ({ handleSubmit }) => {
  return (
    <Tooltip title="Salvar">
      <IconButton color="inherit" onClick={handleSubmit}>
        <Done />
      </IconButton>
    </Tooltip>
  );
};

export default TaxRulesAppbarButtons;
