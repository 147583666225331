import { useCallback, useState } from 'react';
import { api } from 'services/api';
import { Paginated } from 'types/paginated';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { useFiscalSerialFormatter } from './useFiscalSerialFormatter';
import { TaxRulesQueryParams } from '../FiscalSerials';
import { FiscalSerial, FiscalSerialOnList } from '../types/fiscal-serial';

const initialQueryParams: TaxRulesQueryParams = {
  rows: 20,
  page: 1,
};

export function useFetchFiscalSerials() {
  const [queryParams, setQueryParams] = useState<TaxRulesQueryParams>(initialQueryParams);
  const [serials, setSerials] = useState<FiscalSerialOnList[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [total, setTotal] = useState(0);
  const { showErrorDialog } = useErrorHandler();
  const [formatter] = useFiscalSerialFormatter();

  const fetch = useCallback(
    (term: string) => {
      setLoading(true);

      api
        .get<Paginated<FiscalSerial[]>>(`/fiscal-serials`, { params: { ...queryParams, term } })
        .then(response => {
          setTotal(response.data.total);
          setSerials(response.data.data.map(rule => formatter(rule)));
        })
        .catch(error => {
          showErrorDialog({
            error,
            message: 'Não foi possível carregar as regras fiscais',
          });
        })
        .finally(() => setLoading(false));
    },
    [queryParams, showErrorDialog, formatter]
  );

  return {
    loading,
    fetch,
    error,
    setError,
    serials,
    total,
    setQueryParams,
    queryParams,
    setSerials,
  };
}
