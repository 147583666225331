import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import PlayIcon from '@material-ui/icons/PlayCircleFilled';
import PauseIcon from '@material-ui/icons/PauseCircleFilled';

import PropTypes from 'prop-types';
import CategoryActionImageSelected from '../CategoryActionImageSelected';

CategoryAction.propTypes = {
  saving: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  handlePhotoDelete: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleStatus: PropTypes.func.isRequired,
  photoSelected: PropTypes.bool,
  activated: PropTypes.bool.isRequired,
  handleValidation: PropTypes.func.isRequired,
};

export default function CategoryAction({
  saving,
  loading,
  photoSelected,
  handlePhotoDelete,
  handleDelete,
  activated,
  handleStatus,
  handleValidation,
}) {
  return (
    <>
      {photoSelected && <CategoryActionImageSelected handlePhotoDelete={handlePhotoDelete} />}
      {!photoSelected && (
        <>
          <Tooltip title={activated ? 'Pausar vendas' : 'Ativar vendas'}>
            <span>
              <IconButton disabled={saving || loading} color="inherit" onClick={handleStatus}>
                {activated ? <PauseIcon /> : <PlayIcon />}
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Excluir categoria">
            <span>
              <IconButton disabled={saving || loading} color="inherit" onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Salvar">
            <span>
              <IconButton disabled={saving || loading} color="inherit" onClick={handleValidation}>
                <DoneIcon />
              </IconButton>
            </span>
          </Tooltip>
        </>
      )}
    </>
  );
}
