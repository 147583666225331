import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';

type FiscalSettingsAppbarButtonsProps = {
  loading: boolean;
  saving: boolean;
  handleSubmit(): void;
};

const FiscalSettingsAppbarButtons: React.FC<FiscalSettingsAppbarButtonsProps> = ({ loading, saving, handleSubmit }) => {
  return (
    <Tooltip title="Salvar">
      <div>
        <IconButton disabled={saving || loading} onClick={handleSubmit} color="inherit">
          <DoneIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default FiscalSettingsAppbarButtons;
