import { useEffect, useState } from 'react';
import { api } from 'services/api';
import { BoardControlActions } from '../reducer/types';
import { setBoardDiscount, setBoardProducts } from '../reducer/actions';

export type UseFecthBoardMovementProducts = [boolean];

export function useFecthBoardMovementProducts(
  dispatch: (action: BoardControlActions) => void,
  movementId?: string
): UseFecthBoardMovementProducts {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!movementId) {
      return;
    }

    api
      .get(`/boardMovements/${movementId}/products`)
      .then(response => {
        dispatch(setBoardProducts(response.data.products));
        dispatch(setBoardDiscount(response.data.discount));
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [movementId, dispatch]);

  return [loading];
}
