import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { PaymentMethodForList } from 'types/paymentMethod';

interface PaymentMethodsContextValue {
  selected: PaymentMethodForList | null;
  setSelected: Dispatch<SetStateAction<PaymentMethodForList | null>>;
}

const Context = createContext<PaymentMethodsContextValue>({} as PaymentMethodsContextValue);
export const PaymentMethodsProvider = Context.Provider;
export const PaymentMethodsConsumer = Context.Consumer;

export function usePaymentMethods(): PaymentMethodsContextValue {
  return useContext(Context);
}
