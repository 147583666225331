import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { ComplementCategory } from 'types/product';
import ProductManagerItemComplement from './ProductManagerItemComplements';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
  },
  content: {
    marginBottom: 10,
  },
});

interface ProductManagerItemComplementCategoriesProps {
  categories: ComplementCategory[];
  productId: number;
}

const ProductManagerItemComplementCategories: React.FC<ProductManagerItemComplementCategoriesProps> = ({
  categories,
  productId,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {categories.map(category => (
        <div className={classes.content} key={category.id}>
          <Typography key={category.id}>{category.name.toUpperCase()}</Typography>
          <ProductManagerItemComplement
            productId={productId}
            categoryId={category.id}
            complements={category.complements}
          />
        </div>
      ))}
    </div>
  );
};

export default ProductManagerItemComplementCategories;
