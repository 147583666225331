import React, { useState, useEffect, useReducer, FormEvent } from 'react';
import Loading from 'components/loading/Loading';
import CustomAppbar from 'components/appbar/Appbar';
import AdditionalFormAction from './EditAdditionalActions';
import history from 'services/history';
import { api } from 'services/api';
import { useMessaging } from 'hooks/messaging';
import InsideLoading from 'components/loading/InsideLoading';
import { useParams } from 'react-router-dom';
import PageHeader from 'components/page-header/PageHeader';
import { useAdditionalValidation } from '../validation/additionalValidation';
import AdditionalForm from '../AdditionalForm';
import { Additional } from 'types/product';

const additionalReducer = (state, action) => {
  let additional = Object.assign({}, state);

  switch (action.type) {
    case 'CHANGE':
      additional = {
        ...additional,
        [action.index]: action.value,
      };
      return additional;

    case 'SET_ADDITIONAL':
      additional = action.additional;
      additional.validation = {
        name: [],
      };
      return additional;

    case 'VALIDATION':
      additional.validation = action.validation;
      return additional;

    case 'RESET':
      additional = {
        ...additional,
        name: '',
        price: '',
        validation: {
          name: [],
        },
      };
      return additional;

    default:
      throw new Error();
  }
};

const AdditionalEdit: React.FC = () => {
  const { handleOpen } = useMessaging();
  const [additional, dispatch] = useReducer(additionalReducer, {
    name: '',
    is_pizza_edge: false,
    category_id: 0,
    validation: {
      name: [],
    },
    price: '',
    category: {
      name: '',
      category_sizes: [],
    },
  });

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { id } = useParams<{ id: string }>();
  const [validation, setValidation, validate] = useAdditionalValidation();

  useEffect(() => {
    setLoading(true);
    api
      .get('additional/' + id)
      .then(response => {
        dispatch({
          type: 'SET_ADDITIONAL',
          additional: response.data,
        });
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 404) {
            handleOpen('Adicional não encontrado');
            history.push('/menu/additional');
          }
        } else {
          handleOpen('Erro ao carregar adicional');
          history.push('/menu/additional');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, handleOpen]);

  function handleChange(index: keyof Additional, value: any) {
    dispatch({
      type: 'CHANGE',
      value,
      index,
    });
  }

  function handleValidate(event?: FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    setValidation({});

    validate(additional)
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setSaving(true);

    api
      .put('additional/' + additional.id, additional)
      .then(() => {
        handleOpen('Salvo');
        history.push('/menu/additional');
        setSaving(false);
      })
      .catch(err => {
        if (err.response.status === 422) {
          handleOpen('Verifique os campos, você deve preencher todos os que são obrigários');
        }
        setSaving(false);
      });
  }

  function handleDelete() {
    setSaving(true);

    api
      .delete('additional/' + additional.id)
      .then(() => {
        handleOpen('Excluído');
        history.push('/menu/additional');
      })
      .catch(() => {
        handleOpen('Erro ao excluir adicional');
        setSaving(false);
      });
  }
  return (
    <>
      {saving && <Loading />}
      <PageHeader title="Editar adicional" backAction={() => history.push('/menu/additional')} />
      <CustomAppbar
        ActionComponents={
          <AdditionalFormAction
            handleDelete={handleDelete}
            loading={loading}
            saving={saving}
            handleSubmit={handleSubmit}
          />
        }
        title="Editar aditional"
      />
      {loading ? (
        <InsideLoading />
      ) : (
        <form onSubmit={handleValidate}>
          <AdditionalForm
            categories={[]}
            handleChange={handleChange}
            additional={additional}
            validation={validation}
            type="edit"
          />
        </form>
      )}
    </>
  );
};

export default AdditionalEdit;
