import React, { useReducer, useState, useEffect } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import IngredientAction from 'pages/ingredients/registration/edit/IngredientAction';
import { makeStyles } from '@material-ui/core';
import IngredientForm from 'pages/ingredients/registration/edit/IngredientForm';
import Loading from 'components/loading/Loading';
import { useParams } from 'react-router-dom';
import { useMessaging } from 'hooks/messaging';
import PageHeader from 'components/page-header/PageHeader';
import InsideLoading from 'components/loading/InsideLoading';
import history from 'services/history';
import { api } from 'services/api';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  form: {
    flex: 1,
  },
});

const ingredientReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE':
      return {
        ...state,
        [action.index]: action.value,
      };

    case 'SET':
      return {
        ...action.ingredient,
      };

    default:
      return state;
  }
};

function IngredientEdit() {
  const classes = styles();

  const { handleOpen } = useMessaging();
  const [ingredient, dispatch] = useReducer(ingredientReducer, {
    name: '',
    activated: true,
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (!id || typeof parseInt(id) !== 'number') {
      history.push('/menu/ingredients');
      return;
    }

    api
      .get(`ingredients/${id}`)
      .then(response => {
        dispatch({
          type: 'SET',
          ingredient: response.data.ingredient,
        });
      })
      .catch(err => {
        if (err.response && err.response.status === 401) {
          history.push('/menu/ingredients');
        } else if (err.response && err.response.status === 404) {
          handleOpen('Ingrediente não encontrado');
          history.push('/menu/ingredients');
        }
      })
      .finally(() => setLoading(false));
  }, [id, handleOpen]);

  function handleChange(index, value) {
    dispatch({
      type: 'CHANGE',
      index,
      value,
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    setSaving(true);
    api
      .put(`/ingredients/${id}`, ingredient)
      .then(response => {
        dispatch({
          type: 'SET',
          ingredient: response.data.ingredient,
        });
        handleOpen('Salvo');
      })
      .catch(err => {
        if (err.respose && err.response.status === 422) {
          handleOpen('Verifique os campos obrigatórios');
        } else handleOpen('Aconteceu um erro ao salvar o ingrediente');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleDelete() {
    setSaving(true);
    api
      .delete(`/ingredients/${id}`)
      .then(response => {
        handleOpen('Excluído');
        history.push('/menu/ingredients');
      })
      .catch(() => {
        handleOpen('Aconteceu um erro ao excluir o ingrediente');
      });
  }

  return (
    <div className={classes.container}>
      <CustomAppbar
        title="Alterar ingrediente"
        ActionComponents={
          <IngredientAction handleSubmit={handleSubmit} handleDelete={handleDelete} saving={saving} loading={loading} />
        }
      />

      <PageHeader backAction={() => history.push('/menu/ingredients')} title="Editar ingrediente" />

      {loading ? (
        <InsideLoading />
      ) : (
        <form className={classes.form} onSubmit={handleSubmit}>
          {saving && <Loading />}

          <IngredientForm ingredient={ingredient} handleChange={handleChange} />
        </form>
      )}
    </div>
  );
}

export default IngredientEdit;
