import { CircularProgress, Typography, alpha, makeStyles } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Certificate } from 'pages/fiscal-settings/types/certificate';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import React, { DragEvent, useState } from 'react';
import { api } from 'services/api';

const styles = makeStyles(theme => ({
  content: ({ error }: { error: boolean }) => ({
    display: 'flex',
    width: '100%',
    height: 350,
    border: `2px dashed ${error ? theme.palette.error.main : alpha(theme.palette.primary.main, 0.2)}`,
    position: 'relative',
    overflow: 'hidden',
    flexDirection: 'column',
    paddingBottom: 10,
  }),
  inDraggableZone: {
    border: `3px dashed ${theme.palette.primary.main}`,
    opacity: 0.5,
  },
  loading: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flex: 1,
  },
  label: {
    display: 'flex',
    flex: 1,
    cursor: 'pointer',
    justifyContent: 'center',
  },
  uploadText: {
    color: theme.palette.primary.main,
    display: 'inline-flex',
    padding: 10,
    gap: 10,
    alignItems: 'center',
  },
}));

interface UploadProps {
  onUploaded(certificate: Certificate | null): void;
  validationError?: string;
  password: string;
}

const Upload: React.FC<UploadProps> = ({ onUploaded, validationError, password, ...rest }) => {
  const classes = styles({ error: !!validationError });
  const [dragIn, setDragIn] = useState(false);
  const [saving, setSaving] = useState(false);
  const { showErrorDialog } = useErrorHandler();

  function handleUploadFiles(files: FileList | null) {
    if (!files) {
      return;
    }

    const form = new FormData();

    form.append('password', password);

    Array.from(files).forEach(file => {
      form.append(`certificate`, file);
    });

    setSaving(true);

    api
      .post('/certificates', form)
      .then(response => {
        onUploaded({
          ...response.data,
          expires_in: format(parseISO(response.data.expires_in), 'Pp', { locale: ptBR }),
        });
      })
      .catch(error => {
        showErrorDialog({
          message: 'Não foi possível carregar a imagem',
          error,
        });
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleDropFile(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    handleUploadFiles(files);
    setDragIn(false);
  }

  function handleDragEnter(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  function handleDragLeave(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(false);
  }

  function handleDragOver(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  return (
    <div
      className={dragIn ? `${classes.content} ${classes.inDraggableZone}` : `${classes.content}`}
      onDrop={e => handleDropFile(e)}
      onDragLeave={e => handleDragLeave(e)}
      onDragEnter={e => handleDragEnter(e)}
      onDragOver={e => handleDragOver(e)}
      draggable
    >
      {saving ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <input
            type="file"
            style={{ display: 'none' }}
            id="input-file"
            onChange={event => handleUploadFiles(event.target.files)}
            accept=".pfx"
          />

          <label htmlFor="input-file" className={classes.label}>
            <span className={classes.uploadText}>
              escolher o certificado A1 <CloudUpload />
            </span>
          </label>

          <Typography variant="caption" color="textSecondary" align="center">
            você também pode arrastar o certificado aqui
          </Typography>
        </>
      )}
    </div>
  );
};

export default Upload;
