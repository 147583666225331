import React, { useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import ProductComplementCategory from './ProductComplementCategory';
import { addComplementCategory } from 'store/modules/product/actions';
import ProductComplementReorder from './ProductComplementReorder';
import { makeStyles } from '@material-ui/core/styles';
import { useProduct } from 'pages/products/registration/hooks/useProduct';

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: 7,
    },
  },
});

function ProductComplement() {
  const { dispatch, product, handleNext } = useProduct();
  const [dialogComplementsReorder, setDialogComplementsReorder] = useState(false);
  const classes = useStyles();

  function handleSubmit(event) {
    event.preventDefault();
    handleNext();
  }

  function handleAddComplementCategory() {
    dispatch(addComplementCategory());
  }

  return (
    <>
      {dialogComplementsReorder && (
        <ProductComplementReorder
          categories={product.complement_categories}
          onExited={() => setDialogComplementsReorder(false)}
        />
      )}
      <div className={classes.actions}>
        <Button onClick={handleAddComplementCategory} color="primary" variant="text" size="small">
          Adicionar
        </Button>
        <Button size="small" onClick={() => setDialogComplementsReorder(true)} color="primary" variant="text">
          Reordenar
        </Button>
      </div>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <ProductComplementCategory />
          <button type="submit" style={{ display: 'none' }} />
        </form>
      </Grid>
    </>
  );
}

export default ProductComplement;
