import { FC, useMemo, useState } from 'react';
import { List, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { useOrder } from '../../hooks/useOrder';
import { useSelector } from 'store/redux/selector';
import { OrderProduct } from 'types/order';
import ProductSimple from './detail/simple/ProductSimple';
import OrderResumeProduct from './OrderResumeProduct';
import { OrderResumeProductsProvider } from '../hooks/useOrderResumeProducts';
import { useDispatch } from 'react-redux';
import { updateProduct } from 'store/redux/modules/order/actions';
import ProductPizzaComplement from './detail/pizza_complement/ProductPizzaComplement';
import ProductComplement from './detail/complements/ProductComplement';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 0,
  },
  quantity: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 100,
    border: '1px solid #eee',
    borderRadius: 4,
    margin: '10px 0 5px',
  },
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gridGap: 15,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  listItem: {
    display: 'flex',
    backgroundColor: '#fff',
    border: '1px solid #eee',
    borderRadius: 4,
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
    minHeight: 100,
  },
  listItemButton: {
    display: 'flex',
    backgroundColor: '#fff',
    border: '1px solid #eee',
    borderRadius: 4,
    alignItems: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'wrap',
    cursor: 'pointer',
    justifyContent: 'space-between',
  },
  price: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  finalPrice: {
    fontWeight: 300,
  },
}));

const OrderResumeProducts: FC = () => {
  const classes = useStyles();
  const { handleRemoveProduct } = useOrder();
  const [selectedProduct, setSelectedProduct] = useState<OrderProduct | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { products } = useSelector(state => state.order);
  const dispatch = useDispatch();

  const isPizza = useMemo(() => {
    return !!selectedProduct?.category.is_pizza;
  }, [selectedProduct]);

  const isComplement = useMemo(() => {
    return !!selectedProduct?.category.has_complement && !selectedProduct?.category.is_pizza;
  }, [selectedProduct]);

  const isSimple = useMemo(() => {
    return selectedProduct ? !selectedProduct.category.has_complement : false;
  }, [selectedProduct]);

  function handleRemoveProductClick() {
    if (!selectedProduct) {
      return;
    }

    handleRemoveProduct(selectedProduct.uid);
    setAnchorEl(null);
  }

  function handleUpdateProduct(product: OrderProduct, amount: number) {
    dispatch(updateProduct(product, amount));
  }

  return (
    <OrderResumeProductsProvider value={{ handleUpdateProduct, selectedProduct, setSelectedProduct }}>
      <div className={classes.container}>
        <Menu
          disableAutoFocusItem
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          id="product-list-menu"
          anchorEl={anchorEl}
        >
          <MenuItem onClick={handleRemoveProductClick}>Remover</MenuItem>
        </Menu>

        {isSimple && <ProductSimple onExited={() => setSelectedProduct(null)} />}
        {isPizza && <ProductPizzaComplement onExited={() => setSelectedProduct(null)} />}
        {isComplement && <ProductComplement onExited={() => setSelectedProduct(null)} />}

        <List className={classes.list}>
          {products.map(product => (
            <OrderResumeProduct product={product} setSelectedProduct={setSelectedProduct} key={product.uid} />
          ))}
        </List>
      </div>
    </OrderResumeProductsProvider>
  );
};

export default OrderResumeProducts;
