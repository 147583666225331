import React from 'react';
import { makeStyles } from '@material-ui/core';
import DialogPrint from 'components/dialog/dialog-print/DialogPrint';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { moneyFormat } from 'helpers/NumberFormat';
import { useSelector } from 'store/redux/selector';

const useStyles = makeStyles({
  container: {
    flex: 1,
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '1fr 120px 120px',
    marginBottom: 5,
    paddingBottom: 3,
    borderBottom: '1px solid #ccc',
  },
  headerInfo: {
    marginTop: 10,
  },
  total: {
    display: 'grid',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    marginTop: 20,
    gridTemplateColumns: '115px 100px',
    border: '1px solid #ccc',
    padding: 15,
    '& > .label': {
      //
    },
    '& > .value': {
      fontSize: 20,
      fontWeight: 500,
      display: 'inline-flex',
      justifyContent: 'flex-end',
    },
  },
  restaurantName: {
    marginBottom: 10,
  },
});

interface TopProductsReportDataProps {
  data: any;
  onExited(): void;
  initialDate: Date;
  finalDate: Date;
}

const TopProductsReportData: React.FC<TopProductsReportDataProps> = ({ data, onExited, finalDate, initialDate }) => {
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const restaurant = useSelector(state => state.restaurant);

  const total = moneyFormat(data.reduce((sum: number, item: any) => (sum = sum + item.final_price), 0));

  const initial = format(initialDate, 'P', { locale: ptBR });
  const final = format(finalDate, 'P', { locale: ptBR });

  return (
    <DialogPrint onExited={onExited} title="Relatório">
      <div className="header">
        <div>
          <div style={{ marginBottom: 10 }}>
            <p className={classes.restaurantName}>{restaurant?.name}</p>
          </div>

          <h6>Total por produtos</h6>
          <p>Período: {`${initial} - ${final}`}</p>
        </div>
        <div>
          <p>{user?.name}</p>
          <p>{format(new Date(), 'PPpp', { locale: ptBR })}</p>
        </div>
      </div>
      <div className={`${classes.row} table-header`}>
        <p>Descrição</p>
        <p style={{ textAlign: 'center' }}>Qtd</p>
        <p>Total</p>
      </div>
      {data.map(item => (
        <div key={item.name} className={classes.row}>
          <p>{item.name}</p>
          <p style={{ textAlign: 'center' }}>{item.amount}</p>
          <p>{item.formattedFinalPrice}</p>
        </div>
      ))}

      <div className={classes.total}>
        <p className="label">TOTAL</p>
        <p className="value">{total}</p>
      </div>
    </DialogPrint>
  );
};

export default TopProductsReportData;
