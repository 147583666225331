import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, IconButton, Typography } from '@material-ui/core';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import DashboardCustomDate from './DashboardCustomDate';
import DialogInput, { DialogInputConsumer } from 'components/dialog/DialogInput';

const useStyles = makeStyles({
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 15,
    '& button': {
      marginBottom: 10,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: 400,
    justifyContent: 'space-around',
  },
});

type DashboardDateFilterProps = {
  loading: boolean;
  dateConfig: number | null;
  handleDateConfig(days: number): void;
  onExited(): void;
  handleConfirmCustomDates(initialDate: Date, finalDate: Date): void;
};

const DashboardDateFilter: React.FC<DashboardDateFilterProps> = ({
  loading,
  dateConfig,
  handleDateConfig,
  onExited,
  handleConfirmCustomDates,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function handleClick(days: number, handleClose: () => void) {
    handleDateConfig(days);
    handleClose();
  }

  return (
    <DialogInput onExited={onExited} maxWidth="xs">
      <DashboardCustomDate
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        handleConfirmCustomDates={handleConfirmCustomDates}
      />
      <DialogInputConsumer>
        {({ handleCloseDialog }) => (
          <div className={classes.container}>
            <Typography align="center">Escolha o período</Typography>
            <div className={classes.buttons}>
              <Button
                disabled={loading}
                variant="text"
                size="small"
                color={dateConfig === 1 ? 'primary' : 'default'}
                onClick={() => handleClick(1, handleCloseDialog)}
              >
                Ontem
              </Button>
              <Button
                disabled={loading}
                variant="text"
                size="medium"
                color={dateConfig === 0 ? 'primary' : 'default'}
                onClick={() => handleClick(0, handleCloseDialog)}
              >
                Hoje
              </Button>
              <Button
                disabled={loading}
                variant="text"
                size="medium"
                color={dateConfig === 15 ? 'primary' : 'default'}
                onClick={() => handleClick(15, handleCloseDialog)}
              >
                15 dias
              </Button>
              <Button
                disabled={loading}
                variant="text"
                size="medium"
                color={dateConfig === 30 ? 'primary' : 'default'}
                onClick={() => handleClick(30, handleCloseDialog)}
              >
                30 dias
              </Button>
              <Button
                disabled={loading}
                variant="text"
                size="medium"
                color={dateConfig === 60 ? 'primary' : 'default'}
                onClick={() => handleClick(60, handleCloseDialog)}
              >
                60 dias
              </Button>
              <IconButton
                disabled={loading}
                color={dateConfig === null ? 'primary' : 'default'}
                onClick={e => setAnchorEl(e.currentTarget)}
              >
                <CalendarIcon />
              </IconButton>
            </div>
          </div>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default DashboardDateFilter;
