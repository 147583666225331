import React, { useState } from 'react';
import { useSelector } from 'store/redux/selector';
import { Typography, Button, CircularProgress } from '@material-ui/core';
import StatusIcon from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from '@material-ui/core/styles';
import { useApp } from 'App';

interface StyleProps {
  isKitchenOpen: boolean;
}

const useStyles = makeStyles({
  status: (props: StyleProps) => ({
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      color: props.isKitchenOpen ? '#28a745' : '#dc3545',
      marginRight: 6,
    },
    '& > span': {
      display: 'inline-flex',
      flexDirection: 'column',
    },
  }),
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(0,0,0,0.3)',
    color: '#fff',
    height: 70,
    padding: 15,
    flexShrink: 0,
    alignItems: 'center',
    position: 'relative',
  },
});

const KitchenStatus: React.FC = () => {
  const restaurant = useSelector(state => state.restaurant);
  const classes = useStyles({ isKitchenOpen: !!restaurant?.is_kitchen_open });
  const app = useApp();
  const [loading, setLoading] = useState(false);

  function handleState() {
    setLoading(true);
    app
      .handleKitchenState()
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }

  return (
    <div className={classes.content}>
      <div color="inherit" className={classes.status}>
        <StatusIcon />
        <span>
          <Typography variant="caption">mesas</Typography>
          <Typography variant="body1">{restaurant?.is_kitchen_open ? 'online' : 'offline'}</Typography>
        </span>
      </div>

      {loading ? (
        <CircularProgress size={30} color="primary" />
      ) : (
        <Button variant="text" size="small" color="inherit" onClick={handleState}>
          {restaurant?.is_kitchen_open ? 'Ficar offline' : 'Ficar online'}
        </Button>
      )}
    </div>
  );
};

export default KitchenStatus;
