import { createContext, useContext, Dispatch } from 'react';
import { CategoryActions } from 'store/modules/category/types';
import { Category } from 'types/category';

type CategoryContextValue = {
  dispatch: Dispatch<CategoryActions>;
  category: Category;
};

const CategoryContext = createContext<CategoryContextValue>({} as CategoryContextValue);
export const CategoryProvider = CategoryContext.Provider;

export function useCategory(): CategoryContextValue {
  const context = useContext(CategoryContext);
  return context;
}
