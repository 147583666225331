import React, { useState } from 'react';
import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import { FaDownload } from 'react-icons/fa';

interface ButtonDownloadProps extends ButtonProps {
  handleDownload(): Promise<void>;
  text?: string;
  marginTop?: number;
  width?: number;
}

const ButtonDownload: React.FC<ButtonDownloadProps> = ({
  handleDownload,
  text = 'Download',
  size,
  marginTop = 0,
  disabled,
  width = 160,
  ...rest
}) => {
  const [downloading, setDownloading] = useState(false);

  async function handleClick() {
    setDownloading(true);

    await handleDownload().finally(() => setDownloading(false));
  }

  return (
    <Button
      {...rest}
      style={{ marginTop, width }}
      disabled={downloading || disabled}
      onClick={handleClick}
      size={size}
      color="primary"
      startIcon={!downloading ? <FaDownload /> : <CircularProgress size={22} />}
    >
      {text}
    </Button>
  );
};

export default ButtonDownload;
