import React from 'react';
import { ListItem, List, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  list: {
    padding: '0 0 10px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: 7,
    '& > li:not(:last-child)': {
      borderBottom: '1px dashed #eee',
      paddingBottom: 7,
    },
    marginTop: 7,
  },
  listItem: {
    padding: 0,
    display: 'grid',
    gridTemplateColumns: '0.5fr 1fr',
    columnGap: 5,
  },
});

OrderPreviewProductListComplements.propTypes = {
  categories: PropTypes.array.isRequired,
};

export default function OrderPreviewProductListComplements({ categories }) {
  const classes = useStyles();

  function getFormattedComplements(complements) {
    return complements
      .filter(complement => complement.selected)
      .map(complement => complement.name)
      .join(', ');
  }

  return (
    <List className={classes.list}>
      {categories.map(category => {
        return (
          <ListItem key={category.id} className={classes.listItem}>
            {category.complements.some(complement => complement.selected) && (
              <>
                <Typography variant="caption">{category.print_name}</Typography>
                <div>
                  <Typography variant="subtitle2" display="inline">
                    {getFormattedComplements(category.complements)}
                  </Typography>
                </div>
              </>
            )}
          </ListItem>
        );
      })}
    </List>
  );
}
