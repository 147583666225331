import React from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import { useSelector } from 'store/redux/selector';
import PrintTypography from 'components/print-typography/PrintTypography';
import './cashRegisterReport.css';
import { CashRegisterReportType } from './CashRegisterReportModal';

interface UseStylesProps {
  fontSize: number;
  noMargin: boolean;
}

const useStyles = makeStyles<Theme, UseStylesProps>({
  container: props => ({
    maxWidth: '80mm',
    width: '100%',
    padding: 10,
    fontSize: props.fontSize,
    backgroundColor: '#faebd7',
    border: '2px dashed #ccc',
    '@media print': {
      '&': {
        backgroundColor: 'transparent',
        border: 'none',
        padding: 0,
      },
    },
  }),
  header: {
    borderBottom: '1px dashed #000',
    marginBottom: 20,
    paddingBottom: 10,
    textAlign: 'center',
  },
  footer: {
    marginBottom: 10,
    textAlign: 'center',
    marginTop: 20,
  },
});

interface CashRegisterReportProps {
  data: CashRegisterReportType;
}

const CashRegisterReport: React.FC<CashRegisterReportProps> = ({ data }) => {
  const restaurant = useSelector(state => state.restaurant);

  const classes = useStyles({
    fontSize: 14,
    // fontSize: restaurant?.printer_settings?.font_size || 14,
    noMargin: !!restaurant?.printer_settings?.no_margin,
  });

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <PrintTypography fontSize={1.2} bold>
          FECHAMENTO DO CAIXA {data.cash_register.sequence}
        </PrintTypography>
      </div>

      <div className="resume">
        <div className="item">
          <PrintTypography className="subtitle">Inicial</PrintTypography>
          <PrintTypography>{data.cash_register.formattedInitialValue}</PrintTypography>
        </div>
        <div className="item">
          <PrintTypography className="subtitle">Entradas</PrintTypography>
          <PrintTypography>{data.cash_register.formattedCredit}</PrintTypography>
        </div>
        <div className="item">
          <PrintTypography className="subtitle">Saídas</PrintTypography>
          <PrintTypography>{data.cash_register.formattedDebit}</PrintTypography>
        </div>
        <div className="item">
          <PrintTypography className="subtitle">Final</PrintTypography>
          <PrintTypography>{data.cash_register.formattedCurrentValue}</PrintTypography>
        </div>
      </div>

      <div className="payment-methods">
        <PrintTypography className="subtitle">Formas de pagamento</PrintTypography>

        {data.payment_methods.map(payment => (
          <div key={payment.payment_method_id} className="product-item">
            <PrintTypography>{payment.payment_method_name}</PrintTypography>
            <PrintTypography className="to-right">{payment.formattedTotal}</PrintTypography>
          </div>
        ))}
      </div>

      <div className="general">
        <PrintTypography className="subtitle">Resumo das vendas</PrintTypography>

        <div className="item">
          <PrintTypography>Pedidos</PrintTypography>
          <PrintTypography>{data.quantity}</PrintTypography>
        </div>

        <div className="item">
          <PrintTypography>Taxa de entrega</PrintTypography>
          <PrintTypography>{data.formattedTax}</PrintTypography>
        </div>

        <div className="item">
          <PrintTypography>Total geral</PrintTypography>
          <PrintTypography>{data.formattedTotal}</PrintTypography>
        </div>
      </div>

      <div className="environment">
        <PrintTypography className="subtitle">Delivery</PrintTypography>
        <div className="item">
          <PrintTypography>Pedidos</PrintTypography>
          <PrintTypography>{data.delivery.quantity}</PrintTypography>
        </div>
        <div className="item">
          <PrintTypography>Total</PrintTypography>
          <PrintTypography>{data.delivery.formattedTotal}</PrintTypography>
        </div>
        <div className="item">
          <PrintTypography>Ticket médio</PrintTypography>
          <PrintTypography>{data.delivery.formattedAverageTicket}</PrintTypography>
        </div>
      </div>

      <div className="environment">
        <PrintTypography className="subtitle">Mesas</PrintTypography>
        <div className="item">
          <PrintTypography>Pedidos</PrintTypography>
          <PrintTypography>{data.board.quantity}</PrintTypography>
        </div>
        <div className="item">
          <PrintTypography>Total</PrintTypography>
          <PrintTypography>{data.board.formattedTotal}</PrintTypography>
        </div>
        <div className="item">
          <PrintTypography>Ticket médio</PrintTypography>
          <PrintTypography>{data.board.formattedAverageTicket}</PrintTypography>
        </div>
      </div>

      <div className="products">
        <PrintTypography className="subtitle">Produtos</PrintTypography>

        {data.products.map(payment => (
          <div key={payment.name} className="product-item">
            <PrintTypography>{payment.quantity}</PrintTypography>
            <PrintTypography>{payment.name}</PrintTypography>
            <PrintTypography className="to-right">{payment.formattedTotal}</PrintTypography>
          </div>
        ))}

        <div className="product-item">
          <PrintTypography></PrintTypography>
          <PrintTypography>Total</PrintTypography>
          <PrintTypography className="to-right">{data.formattedProductsTotal}</PrintTypography>
        </div>
      </div>

      <div className="deliverers">
        {data.deliverers.map(deliverer => (
          <div className="deliverer-item" key={deliverer.deliverer_name}>
            <PrintTypography className="subtitle">Entregas de {deliverer.deliverer_name}</PrintTypography>

            <div className="deliverer-item__item">
              <PrintTypography>Pedidos</PrintTypography>
              <PrintTypography>{deliverer.quantity}</PrintTypography>
            </div>

            {deliverer.payment_methods.map(payment => (
              <div key={payment.payment_method_id} className="deliverer-item__item">
                <PrintTypography>Pgto. em {payment.payment_method_name}</PrintTypography>
                <PrintTypography>{payment.formattedTotal}</PrintTypography>
              </div>
            ))}

            <div className="deliverer-item__item">
              <PrintTypography>Taxa de entrega</PrintTypography>
              <PrintTypography>{deliverer.formattedTax}</PrintTypography>
            </div>

            <div className="deliverer-item__item">
              <PrintTypography>Total dos pedidos</PrintTypography>
              <PrintTypography>{deliverer.formattedTotal}</PrintTypography>
            </div>
          </div>
        ))}
      </div>

      <div className={classes.footer}>
        <PrintTypography>sgrande.delivery</PrintTypography>
      </div>
    </div>
  );
};

export default CashRegisterReport;
