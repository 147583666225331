import React, { useEffect, useRef, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { setPromotionOrderValue } from 'store/modules/promotion/actions';
import { usePromotion } from 'pages/promotion/hooks/usePromotion';

PromotionOrderValue.propTypes = {
  promotion: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired,
};

export default function PromotionOrderValue({ validation, promotion }) {
  const { handleNext, dispatch } = usePromotion();
  const [value, setValue] = useState(promotion.order_value ? promotion.order_value.order_value : '');

  useEffect(() => {
    if (validation.order_value) inputs.orderValue.focus();
    // eslint-disable-next-line
  }, [validation]);

  const inputs = {
    orderValue: useRef(null),
  };

  function handleSubmit(event) {
    event.preventDefault();
    handleNext();
  }

  function handleChange(value) {
    setValue(value);
    dispatch(setPromotionOrderValue(value));
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} lg={5} xl={4}>
          <form onSubmit={handleSubmit}>
            <TextField
              inputRef={ref => (inputs.orderValue = ref)}
              label="Valor do pedido"
              placeholder="Digite o valor do pedido"
              margin="normal"
              value={value}
              onChange={e => handleChange(e.target.value)}
              error={!!validation.order_value}
              helperText={!!validation.order_value && validation.order_value}
              fullWidth
              autoFocus
              type="number"
              inputProps={{
                step: 0.01,
              }}
            />
          </form>
        </Grid>
      </Grid>
    </>
  );
}
