import React from 'react';
import List from 'components/list/List';
import CouponItemModule from './CouponItemModule';
import { Coupon } from 'types/coupon';

type CouponListModuleProps = {
  coupons: Coupon[];
};

const CouponListModule: React.FC<CouponListModuleProps> = ({ coupons }) => {
  return (
    <List>
      {coupons.map(coupon => (
        <CouponItemModule key={coupon.id} coupon={coupon} />
      ))}
    </List>
  );
};

export default CouponListModule;
