import React, { MouseEvent } from 'react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@material-ui/icons';
import { FiscalSerialOnList } from 'pages/fiscal-serials/types/fiscal-serial';
import { useFiscalSerials } from 'pages/fiscal-serials/hooks/useFiscalSerials';

const useStyles = makeStyles(theme => ({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
}));

type FiscalSerialItemTableProps = {
  fiscalSerial: FiscalSerialOnList;
  setAnchorEl(el: HTMLButtonElement): void;
};

const FiscalSerialItemTable: React.FC<FiscalSerialItemTableProps> = ({ fiscalSerial, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedSerial } = useFiscalSerials();

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setSelectedSerial(fiscalSerial);
    setAnchorEl(event.currentTarget);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton onClick={handleMoreClick} key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{fiscalSerial[item.id]}</Typography>
            </div>
          )
        )}
    </>
  );
};

export default FiscalSerialItemTable;
