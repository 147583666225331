import React from 'react';
import { Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { Ingredient } from 'types/product';

type IngredientItemTableProps = {
  ingredient: Ingredient;
};

const IngredientItemTable: React.FC<IngredientItemTableProps> = ({ ingredient }) => {
  const { tableTemplate } = useTable();

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item => (
          <div key={item.id}>
            <Typography variant="body2">{ingredient[item.id] as keyof Ingredient}</Typography>
          </div>
        ))}
    </>
  );
};

export default IngredientItemTable;
