import React from 'react';
import { Tabs, Tab } from '@material-ui/core';

interface PaymentMethodTabsProps {
  tab: number;
  handleTabChange(tab: number): void;
  loading: boolean;
}

export default function PaymentMethodTabs({ tab, handleTabChange, loading }: PaymentMethodTabsProps) {
  return (
    <Tabs value={tab} onChange={(event, tab) => handleTabChange(tab)} variant="scrollable">
      <Tab label="Pagamento online" disabled={loading} />
      <Tab label="PicPay" disabled={loading} />
      <Tab label="Pix" disabled={loading} />
    </Tabs>
  );
}
