import React, { useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'store/redux/selector';
import { RestaurantAddress } from 'types/restaurant';
import { infoWindowContent } from './infoWindowContent';
import GoogleMapHeader from './GoogleMapHeader';
import Modal from 'components/modal/Modal';
import { mapStyle } from 'components/profile/address/registration/places-api/map/mapStyle';
import { useArea } from '../Areas';
import { Loader } from '@googlemaps/js-api-loader';

const styles = makeStyles(theme => ({
  map: {
    height: '100vh',
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    '@media (max-width: 600px)': {
      height: '100vh',
    },
    '& .gm-style-iw-a button': {
      visibility: 'hidden',
    },
    '& .gm-style-iw-c': {
      padding: 0,
    },
    '& .gm-style-iw-d': {
      overflow: 'hidden !important',
    },
  },
  actions: {
    background: 'transparent',
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 0,
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    height: 60,
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
}));

const loader = new Loader({
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY as string,
  version: 'weekly',
  libraries: ['places', 'geometry'],
});

interface GoogleMapProps {
  lat: number;
  lng: number;
  address: RestaurantAddress;
  onExited(): void;
}

const GoogleMap: React.FC<GoogleMapProps> = ({ lat, lng, address, onExited }) => {
  const classes = styles();
  const restaurant = useSelector(state => state.restaurant);
  const { selectedArea } = useArea();
  const maxDeliveryDistance = useMemo(
    () => (selectedArea.distances[selectedArea.distances.length - 1].until_distance as number) || 0,
    [selectedArea]
  );

  const circleRadius = useMemo(() => maxDeliveryDistance * 1000, [maxDeliveryDistance]);

  const restaurantAddressCoordinates = useMemo(() => {
    return {
      lat,
      lng,
    };
  }, [lat, lng]);

  const createMap = useCallback(async (): Promise<google.maps.Map> => {
    const position = { lat, lng };

    await loader.load();

    const map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      // zoom: 16,
      center: position,
      mapTypeId: google.maps.MapTypeId.TERRAIN,
      disableDefaultUI: true,
      styles: mapStyle,
    });

    return map;
  }, [lat, lng]);

  const createMarker = useCallback(
    (map: google.maps.Map): google.maps.Marker => {
      const position = { lat, lng };

      const marker = new google.maps.Marker({
        position,
        draggable: false,
      });

      marker.setMap(map);

      return marker;
    },
    [lat, lng]
  );

  const createCircle = useCallback(
    (map: google.maps.Map): google.maps.Circle => {
      const circle = new google.maps.Circle({
        radius: circleRadius,
        strokeColor: '#FF0000',
        strokeOpacity: 0.2,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.05,
        center: restaurantAddressCoordinates,
      });

      circle.setMap(map);

      return circle;
    },
    [circleRadius, restaurantAddressCoordinates]
  );

  const createInfoWindow = useCallback((): google.maps.InfoWindow => {
    const infoWindow = new google.maps.InfoWindow({
      content: infoWindowContent(restaurant?.name || ''),
    });

    return infoWindow;
  }, [restaurant]);

  const initMap = useCallback(async () => {
    const map = await createMap();
    const marker = createMarker(map);
    const circle = createCircle(map);
    const infowindow = createInfoWindow();

    const openWindow = () =>
      infowindow.open({
        anchor: marker,
        map,
        shouldFocus: true,
      });

    openWindow();

    const circleBounds = circle.getBounds();

    if (circleBounds) {
      map.fitBounds(circleBounds);
    }
  }, [createMap, createMarker, createCircle, createInfoWindow]);

  useEffect(() => {
    initMap().catch(err => console.error(err));
  }, [initMap]);

  return (
    <Modal onExited={onExited} title="Área de entrega">
      <div className={classes.map} id="map" />
      <GoogleMapHeader address={address} />
    </Modal>
  );
};

export default GoogleMap;
