export const paymentGateways = [
  {
    id: 'pagarme',
    name: 'Pagar-me',
  },
  {
    id: 'cielo',
    name: 'Cielo',
  },
  {
    id: 'pagseguro',
    name: 'PagSeguro 4.0',
  },
  {
    id: 'rede',
    name: 'Rede',
  },
  {
    id: 'mercadopago',
    name: 'Mercado Pago',
  },
];
