import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    marginTop: 10,
    marginBottom: 15,
    display: 'grid',
    gridTemplateColumns: '1.3fr 0.7fr',
    gridGap: 6,
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    '& p': {
      lineHeight: '15px',
    },
  },
  colTotal: {
    marginTop: 5,
  },
  total: {
    fontWeight: 600,
  },
});

OrderPreviewTotal.propTypes = {
  subtotal: PropTypes.string.isRequired,
  discount: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
  coupon: PropTypes.string,
  tax: PropTypes.string.isRequired,
};

export default function OrderPreviewTotal({ subtotal, discount, total, coupon, tax }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <div>
          <Typography>Subtotal</Typography>
        </div>
        <div>
          <Typography>{subtotal}</Typography>
        </div>
        <div>
          <Typography>Desconto {coupon && `(${coupon})`}</Typography>
        </div>
        <div>
          <Typography>{discount}</Typography>
        </div>
        <div>
          <Typography>Taxa de entrega</Typography>
        </div>
        <div>
          <Typography>{tax}</Typography>
        </div>
        <div className={classes.colTotal}>
          <Typography>Total à pagar</Typography>
        </div>
        <div className={classes.colTotal}>
          <Typography variant="h5" className={classes.total}>
            {total}
          </Typography>
        </div>
      </div>
    </>
  );
}
