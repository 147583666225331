import { Button } from '@material-ui/core';
import React from 'react';
import history from 'services/history';

const UsersHeaderButtons: React.FC = () => {
  return (
    <>
      <Button onClick={() => history.push('/users/add')} variant="contained" color="primary" size="small">
        Novo
      </Button>
    </>
  );
};

export default UsersHeaderButtons;
