import { useErrorHandler } from 'providers/error-handler/error-handler';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { api } from 'services/api';
import { Printer } from 'types/printer';

type UseFetchPrinters = [Printer[], Dispatch<SetStateAction<Printer[]>>, boolean];

export function useFetchPrinters(): UseFetchPrinters {
  const [printers, setPrinters] = useState<Printer[]>([]);
  const [loading, setLoading] = useState(true);
  const { showErrorDialog } = useErrorHandler();

  useEffect(() => {
    api
      .get('/printers')
      .then(response => {
        setPrinters(response.data);
      })
      .catch(err => {
        showErrorDialog({
          error: err,
          message: 'Não foi possível carregar as impressoras',
        });
      })
      .finally(() => setLoading(false));
  }, [showErrorDialog]);

  return [printers, setPrinters, loading];
}
