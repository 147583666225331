import React, { HTMLAttributes, useState } from 'react';
import { Zoom, Typography, Button, makeStyles, alpha } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Certificate } from 'pages/fiscal-settings/types/certificate';
import CertificateData from './CertificateData';
import Upload from './Upload';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  content: ({ error }: { error: boolean }) => ({
    display: 'flex',
    width: '100%',
    height: 350,
    border: `2px dashed ${error ? theme.palette.error.main : alpha(theme.palette.primary.main, 0.2)}`,
    position: 'relative',
    overflow: 'hidden',
    flexDirection: 'column',
    paddingBottom: 10,
  }),
  wrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    display: 'flex',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    '& > svg': {
      position: 'absolute',
      bottom: 0,
      right: 0,
    },
    '& > button': {
      cursor: 'default',
    },
  },
  icon: {
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
}));

interface CertificateUploadProps extends HTMLAttributes<HTMLDivElement> {
  certificate: Certificate | null;
  validationError?: string;
  label: string;
  handleRemove(): void;
  onUploaded(certificate: Certificate | null): void;
  disabled?: boolean;
  hideSelectorBackdrop?: boolean;
  password: string;
}

const CertificateUpload: React.FC<CertificateUploadProps> = ({
  certificate,
  handleRemove,
  onUploaded,
  validationError,
  label,
  className,
  disabled = false,
  hideSelectorBackdrop = false,
  password,
  ...rest
}) => {
  const classes = useStyles({ error: !!validationError });
  const [selected, setSelected] = useState(false);

  return (
    <div {...rest} className={className ? `${classes.container} ${className}` : classes.container}>
      {!certificate ? (
        <Upload onUploaded={onUploaded} validationError={validationError} password={password} />
      ) : (
        <div className={`${classes.content}`} onClick={() => setSelected(selected => !selected)}>
          <Zoom in={selected}>
            <div className={classes.wrapper}>
              <CheckCircleIcon className={classes.icon} color="primary" />
              <Button size="small" variant="contained" color="primary" onClick={handleRemove}>
                Remover
              </Button>
            </div>
          </Zoom>
          <CertificateData certificate={certificate} />
        </div>
      )}

      {validationError ? (
        <Typography variant="caption" color="error">
          {validationError}
        </Typography>
      ) : (
        <Typography variant="caption" display="block">
          {label}
        </Typography>
      )}
    </div>
  );
};

export default CertificateUpload;
