import { TableTemplate } from 'types/tableTemplate';

export const promotionsTableTemplate: TableTemplate[] = [
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 60,
  },
  {
    id: 'formattedActive',
    description: 'ATIVO',
    originalId: 'formattedActive',
    width: 100,
  },
  {
    id: 'name',
    description: 'NOME',
    originalId: 'name',
    width: 150,
  },
  {
    id: 'description',
    description: 'DESCRIÇÃO',
    originalId: 'description',
    width: 400,
  },
  {
    id: 'type',
    description: 'TIPO',
    originalId: 'type',
    width: 100,
  },
  {
    id: 'formattedIsAvailableOnDelivery',
    description: 'DELIVERY',
    originalId: 'is_available_on_delivery',
    width: 70,
  },
  {
    id: 'formattedIsAvailableOnBoard',
    description: 'MESA',
    originalId: 'is_available_on_board',
    width: 70,
  },
  {
    id: 'formattedCreatedAt',
    description: 'CRIADO EM',
    originalId: 'formattedCreatedAt',
    width: 200,
  },
];
