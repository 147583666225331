import React, { MouseEvent } from 'react';
import { ListItem, Typography, Tooltip, Button } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { CustomerAddress } from 'types/customerAddress';
import { useSelector } from 'store/redux/selector';
import { useOrderShipment } from './hook/useOrderShipment';

const useStyles = makeStyles(theme => ({
  selected: {
    border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    '&:focus': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
    },
  },
  listItem: {
    display: 'flex',
    backgroundColor: '#fff',
    border: '1px solid #f5f5f5',
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',
    position: 'relative',
    minHeight: 180,
  },
  address: {
    display: 'flex',
    alignItems: 'center',
    '@media (min-width:1280px) and (max-width:1360px)': {
      '&': {
        maxWidth: 300,
        display: 'block',
      },
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
      display: 'block',
    },
  },
  iconButton: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  starIcon: {
    marginLeft: 6,
    color: '#ffc107',
  },
  checkIcon: {
    position: 'absolute',
    right: 10,
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
  button: {
    alignSelf: 'flex-end',
  },
  actions: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  tax: {
    marginTop: 7,
  },
  collect: {
    fontSize: 20,
  },
}));

interface AddressItemProps {
  address: CustomerAddress;
}

const AddressItem: React.FC<AddressItemProps> = ({ address }) => {
  const classes = useStyles();
  const restaurant = useSelector(state => state.restaurant);
  const { setDialogEditAddress, handleSelectAddress, setSelectedAddress } = useOrderShipment();
  const { shipment } = useSelector(state => state.order);

  function handleButtonEditClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setSelectedAddress(address);
    setDialogEditAddress(true);
  }

  function handleClick() {
    handleSelectAddress(address);
  }

  return (
    <ListItem
      onClick={handleClick}
      button
      className={address.id === shipment?.id ? classes.selected : classes.listItem}
      key={address.id}
    >
      <div className={classes.actions}>
        <Button variant="text" color="primary" size="small" onClick={event => handleButtonEditClick(event)}>
          Alterar
        </Button>
      </div>

      <div>
        <Typography variant="h6" className={classes.address} gutterBottom>
          {address.address}, {address.number}
          {address.is_main && (
            <Tooltip title="Endereço principal">
              <StarIcon className={classes.starIcon} />
            </Tooltip>
          )}
        </Typography>

        <Typography variant="body2">{address.district}</Typography>

        <Typography variant="body2">
          {address.city}, {address.region}
          {address.postal_code !== '00000000' && `, ${address.postal_code}`}
        </Typography>

        {!!address.distance && address.distance > 0 && (
          <Typography variant="body2" color="textSecondary">
            {address.formattedDistance}
          </Typography>
        )}

        {restaurant?.configs.tax_mode === 'distance' && !!address.distance_tax && address.distance_tax > 0 && (
          <Typography color="textSecondary" className={classes.tax} variant="body2">
            {address.formattedDistanceTax} de taxa de entrega
          </Typography>
        )}
      </div>

      {address.id === shipment?.id && <CheckCircleIcon color="primary" className={classes.checkIcon} />}
    </ListItem>
  );
};

export default AddressItem;
