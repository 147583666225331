import { List } from '@material-ui/core';
import React, { useState } from 'react';
import { Invoice } from 'types/invoice';
import BillingInvoiceItem from './BillingInvoiceItem';
import BillingMenu from './BillingMenu';

type BillingInvoiceListProps = {
  invoices: Invoice[];
};

const BillingInvoiceList: React.FC<BillingInvoiceListProps> = ({ invoices }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <BillingMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <List>
        {invoices.map(invoice => (
          <BillingInvoiceItem key={invoice.id} invoice={invoice} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        ))}
      </List>
    </>
  );
};

export default BillingInvoiceList;
