import React from 'react';
import { XAxis, YAxis, Tooltip, ResponsiveContainer, Line, Legend, CartesianGrid, LineChart } from 'recharts';
import { useTheme } from '@material-ui/core';
import { DashboardOrderByHour } from 'types/dashboardData';

interface DashboardHoursDayChartProps {
  data: DashboardOrderByHour[];
}

const DashboardHoursDayChart: React.FC<DashboardHoursDayChartProps> = ({ data }) => {
  const theme = useTheme();

  return (
    <ResponsiveContainer width="95%">
      <LineChart width={500} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="hour" />
        <YAxis />
        <Tooltip formatter={value => [value, 'quantidade']} labelFormatter={value => `${value}:00`} />
        <Legend formatter={() => 'quantidade de pedidos'} />
        <Line type="monotone" dataKey="amount" stroke={theme.palette.primary.main} activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default DashboardHoursDayChart;
