import { isBefore, lastDayOfMonth } from 'date-fns';
import { cardBrandValidation } from 'helpers/cardBrandValidation';
import { cnpjValidator } from 'helpers/cnpjValidator';
import { cpfValidation } from 'helpers/cpfValidation';
import { Dispatch, SetStateAction, useState } from 'react';
import { CreditCard } from 'types/creditCard';
import * as yup from 'yup';

export type BillingCardValidation = {
  document?: string;
  cvv?: string;
  expiration_date?: string;
  name?: string;
  number?: string;
};

type UseBillingCardValidation = [
  BillingCardValidation,
  Dispatch<SetStateAction<BillingCardValidation>>,
  (card: CreditCard) => Promise<void>
];

export function useBillingCardValidation(): UseBillingCardValidation {
  const [validation, setValidation] = useState<BillingCardValidation>({} as BillingCardValidation);

  async function handleValidation(card: CreditCard) {
    const documentType = card.document_type === 'cpf' ? 'CPF' : 'CNPJ';

    const schema = yup.object().shape({
      document: yup
        .string()
        .transform((value, originalValue) => {
          return originalValue ? originalValue.replace(/\D/g, '') : '';
        })
        .test('cpfValidation', `${documentType} inválido`, value => {
          if (!value) return false;

          const cpf = cpfValidation(value);
          const cnpj = cnpjValidator(value);

          return cpf || cnpj;
        })
        .required(`${documentType} é obrigatório`),
      cvv: yup
        .string()
        .min(3, 'O código de segurança deve ter 3 digitos')
        .required('O código de segurança é obrigatório'),
      expiration_date: yup
        .string()
        .transform((value, originalValue) => {
          return originalValue.replace(/\D/g, '');
        })
        .test('dataValidation', 'Este cartão está vencido', value => {
          if (!value) return false;

          const now = new Date();

          const year = value.slice(-2);
          const month = value.slice(0, 2);

          const expirationDate = new Date(parseInt(`20${year}`), parseInt(month) - 1, 1);

          if (isBefore(lastDayOfMonth(expirationDate), now)) return false;

          return true;
        })
        .min(4, 'Data de validade inválida')
        .required('A data de validade do cartão é obrigatória'),
      name: yup.string().required('O nome e sobrenome são obrigatórios'),
      number: yup
        .string()
        .transform((value, originalValue) => {
          return originalValue.replace(/\D/g, '');
        })
        .min(12, 'Número do cartão inválido')
        .test('cardValidation', 'Infelizmente não trabalhamos com essa bandeira de cartão', value => {
          if (!value) return false;
          return cardBrandValidation(value);
        })
        .required('O número do cartão é obrigatório'),
    });

    try {
      await schema.validate(card);
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        setValidation({
          [err.path as any]: err.message,
        });
      }
      throw err;
    }
  }

  return [validation, setValidation, handleValidation];
}
