import { FormControlLabel, Radio, RadioGroup, TextField, makeStyles } from '@material-ui/core';
import Modal from 'components/modal/Modal';
import React, { useState, useEffect, useRef } from 'react';
import NewCashRegisterMovementAppbarButtons from './NewCashRegisterMovementAppbarButtons';
import { useCashRegisterMovementValidation } from './validation/cashRegisterMovementValidation';
import { api } from 'services/api';
import { useCashRegisterFlow } from '../hooks/useCashRegisterFlow';
import InsideSaving from 'components/loading/InsideSaving';
import { moneyFormat } from 'helpers/NumberFormat';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const styles = makeStyles({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: 20,
  },
});

interface NNewCashRegisterMovementProps {
  onExited(): void;
}

const NewCashRegisterMovement: React.FC<NNewCashRegisterMovementProps> = ({ onExited }) => {
  const classes = styles();
  const [value, setValue] = useState(0);
  const [type, setType] = useState<'debit' | 'credit'>('debit');
  const [description, setDescription] = useState('');
  const [validation, setValidation, validate] = useCashRegisterMovementValidation();
  const inputs = {
    value: useRef<HTMLInputElement>(null),
    description: useRef<HTMLInputElement>(null),
  };
  const { cashRegister, setCashRegister } = useCashRegisterFlow();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  async function handleValidation() {
    setValidation({});

    await validate({ value, type, description });
    await handleSubmit();
  }

  async function handleSubmit() {
    const response = await api.post(`/cashRegisters/${cashRegister?.id}/movements`, { value, description, type });

    setCashRegister(state => {
      if (!state) {
        return null;
      }

      return {
        ...state,
        movements: [
          {
            ...response.data,
            formattedValue: moneyFormat(response.data.value),
            formattedCreatedAt: format(parseISO(response.data.created_at), 'PPp', { locale: ptBR }),
          },
          ...state.movements,
        ],
      };
    });
  }

  return (
    <Modal
      onExited={onExited}
      title="lançamento no caixa"
      maxWidth="sm"
      height="70vh"
      componentActions={
        <NewCashRegisterMovementAppbarButtons
          handleValidation={handleValidation}
          loading={loading}
          setLoading={setLoading}
        />
      }
    >
      {loading && <InsideSaving />}

      <div className={classes.container}>
        <TextField
          inputRef={inputs.value}
          helperText={validation.value}
          error={!!validation.value}
          label="Valor (R$)"
          placeholder="Informe o valor da despesa"
          margin="normal"
          value={value || ''}
          onChange={event => setValue(parseFloat(event.target.value))}
          fullWidth
          inputMode="decimal"
          type="number"
          autoFocus
        />

        <TextField
          inputRef={inputs.description}
          helperText={validation.description}
          error={!!validation.description}
          label="Descrição"
          placeholder="Informe a descrição do lançamento"
          margin="normal"
          value={description}
          onChange={event => setDescription(event.target.value)}
          fullWidth
          type="text"
          multiline
          minRows={5}
        />

        <RadioGroup name="pixGateway" value={type} onChange={e => setType(e.target.value as any)}>
          <FormControlLabel value="debit" control={<Radio />} label="Débito" />
          <FormControlLabel value="credit" control={<Radio />} label="Crédito" />
        </RadioGroup>
      </div>
    </Modal>
  );
};

export default NewCashRegisterMovement;
