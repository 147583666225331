import { TaxRuleActions } from './types';
import { TaxRule } from 'pages/tax-rules/types/taxRule';

export function taxRuleChange(key: keyof TaxRule, value: any): TaxRuleActions {
  return {
    type: 'TAX_RULE_CHANGE',
    key,
    value,
  };
}

export function setTaxRule(taxRule: TaxRule): TaxRuleActions {
  return {
    type: 'SET_TAX_RULE',
    taxRule,
  };
}
