import React, { useRef, useEffect } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import PhoneInput from 'components/masked-input/PhoneInput';
import { Customer } from 'types/customer';
import { CustomerValidation } from './validation/customerValidation';
import CpfCnpjInput from 'components/masked-input/CpfCnpjInput';

const styles = makeStyles({
  container: {
    maxWidth: 400,
  },
});

interface CustomerFormProps {
  handleChange(index: keyof Customer, value: any): void;
  customer: Customer;
  validation: CustomerValidation;
  type?: 'new' | 'update';
}

const CustomerForm: React.FC<CustomerFormProps> = ({ handleChange, customer, validation, type = 'new' }) => {
  const classes = styles();

  const inputs = {
    name: useRef<HTMLInputElement>(null),
    phone: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null),
    birthday: useRef<HTMLInputElement>(null),
    cpf: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <div className={classes.container}>
      <TextField
        inputRef={inputs.name}
        error={!!validation.name}
        helperText={!!validation.name && validation.name}
        label="Nome completo"
        placeholder="Nome completo do cliente"
        margin="normal"
        fullWidth
        autoComplete="off"
        autoFocus
        value={customer.name}
        name="name"
        onChange={e => handleChange('name', e.target.value)}
      />
      <TextField
        inputRef={inputs.phone}
        error={!!validation.phone}
        helperText={!!validation.phone && validation.phone}
        autoComplete="off"
        label="Telefone"
        placeholder="Telefone fixo ou celular cliente"
        margin="normal"
        fullWidth
        InputProps={{
          inputComponent: PhoneInput as any,
        }}
        value={customer.phone}
        name="phone"
        onChange={e => handleChange('phone', e.target.value)}
      />
      <TextField
        inputRef={inputs.email}
        error={!!validation.email}
        helperText={!!validation.email && validation.email}
        label="E-mail"
        placeholder="E-mail do cliente"
        margin="normal"
        fullWidth
        autoComplete="off"
        type="email"
        value={customer.email}
        name="email"
        onChange={e => handleChange('email', e.target.value)}
        disabled={type === 'update'}
      />
      <TextField
        inputRef={inputs.cpf}
        error={!!validation.cpf}
        helperText={!!validation.cpf && validation.cpf}
        label="CPF / CNPJ"
        placeholder="CPF ou CNPJ do cliente"
        margin="normal"
        fullWidth
        autoComplete="off"
        value={customer.cpf}
        name="cpf"
        onChange={e => handleChange('cpf', e.target.value)}
        InputProps={{
          inputComponent: CpfCnpjInput as any,
        }}
      />
    </div>
  );
};

export default CustomerForm;
