import React from 'react';
import { List, ListItem, makeStyles, Typography } from '@material-ui/core';
import CustomDialog, { DialogConsumer } from 'components/dialog/CustomDialog';
import { setRestaurantAddress } from 'store/modules/area/actions';
import { useSelector } from 'store/redux/selector';
import { RestaurantAddress } from 'types/restaurant';
import { useArea } from '../Areas';

const styles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: theme.shape.borderRadius,
  },
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    rowGap: '10px',
  },
}));

interface AreaRestaurantAddressesProps {
  onExited(): void;
}

const AreaRestaurantAddresses: React.FC<AreaRestaurantAddressesProps> = ({ onExited }) => {
  const classes = styles();
  const restaurant = useSelector(state => state.restaurant);
  const { selectedArea, dispatch } = useArea();

  function handleClick(address: RestaurantAddress, handleDialogClose: () => void) {
    dispatch(setRestaurantAddress(address, selectedArea.id));
    handleDialogClose();
  }

  return (
    <CustomDialog maxWidth="sm" handleModalState={onExited} title="Relacionar endereço da loja">
      <DialogConsumer>
        {context => (
          <List className={classes.list}>
            {restaurant?.addresses.map(address => (
              <ListItem
                onClick={() => handleClick(address, context.handleCloseDialog)}
                className={classes.listItem}
                button
                key={address.id}
              >
                <Typography gutterBottom variant="caption" color="primary">
                  {address.nickname}
                </Typography>
                <Typography>
                  {address.address}, {address.number}, {address.district}
                </Typography>
                <Typography>
                  {address.city} - {address.region}
                </Typography>
              </ListItem>
            ))}
          </List>
        )}
      </DialogConsumer>
    </CustomDialog>
  );
};

export default AreaRestaurantAddresses;
