import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomAppbar from 'components/appbar/Appbar';
import { Button, IconButton, InputAdornment, TextField } from '@material-ui/core';
import NoData from 'components/nodata/NoData';
import history from 'services/history';
import { api, getCancelTokenSource } from 'services/api';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import FilterBox from 'components/filter-box/FilterBox';
import { Close, Search } from '@material-ui/icons';
import useTableOrder from 'hooks/tableOrder';
import { useApp } from 'App';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import TableLoading from 'components/loading/TableLoading';
import PaginationProvider from 'hooks/pagination';
import CategoryListTable from './list/table/PromotionListTable';
import CategoryListModule from './list/module/PromotionListModule';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ModuleLoading from 'components/loading/ModuleLoading';
import ApiPagination from 'components/_pagination/ApiPagination';
import { Promotion } from 'types/promotion';
import PromotionsActions from './PromotionsActions';
import { PromotionsProvider } from './hooks/usePromotions';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  filter: {
    display: 'grid',
    gridTemplateColumns: '300px',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

let timer: NodeJS.Timeout;

const Customers: React.FC = () => {
  const classes = useStyles();
  const [promotions, setPromotions] = useState<Promotion[]>([]);
  const [loading, setLoading] = useState(false);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [filtered, setFiltered] = useState<Promotion[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [searchValue, setSearchValue] = useState('');
  const app = useApp();
  const [selectedPromotion, setSelectedPromotion] = useState<Promotion | null>(null);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(20);
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const [total, setTotal] = useState(0);

  const fetchCustomers = useCallback(
    (query: string) => {
      setLoading(true);

      api
        .get('/promotions', { params: { page: page + 1, rows, term: query } })
        .then(response => {
          setTotal(response.data.total);

          setPromotions(
            response.data.data.map((promotion: Promotion) => {
              const date = promotion.valid_at && parseISO(promotion.valid_at);
              promotion.formattedValidAt = date && format(date, 'P', { locale: ptBR });
              promotion.formattedCreatedAt = format(parseISO(promotion.created_at), 'P', { locale: ptBR });
              promotion.formattedActive = promotion.activated ? 'Sim' : 'Não';
              promotion.formattedIsAvailableOnBoard = promotion.is_available_on_board ? 'Sim' : 'Não';
              promotion.formattedIsAvailableOnDelivery = promotion.is_available_on_delivery ? 'Sim' : 'Não';
              return promotion;
            })
          );
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [rows, page]
  );

  useEffect(() => {
    let request = true;

    const source = getCancelTokenSource();

    fetchCustomers('');

    return () => {
      if (request) source.cancel();
      request = false;
    };
  }, [fetchCustomers]);

  useEffect(() => setFiltered(promotions), [promotions]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleSearch(value: string) {
    setSearchValue(value);
    clearTimeout(timer);

    if (value.length > 0 && value.length <= 3) {
      return;
    }

    timer = setTimeout(() => fetchCustomers(value), 500);
  }

  return (
    <PromotionsProvider value={{ promotions, setSelectedPromotion, selectedPromotion }}>
      <CustomAppbar title="Clientes" ActionComponents={<PromotionsActions />} />
      <PageHeaderActions
        title="Promoções"
        description="Crie promoções do tipo compre e ganhe e as que oferecem desconto no carrinho"
        ActionComponent={
          <Button color="primary" variant="contained" onClick={() => history.push('/promotions/new')} size="small">
            Adicionar
          </Button>
        }
      />
      <FilterBox>
        <div className={classes.filter}>
          <TextField
            inputRef={inputSearchRef}
            label="Pesquisar"
            placeholder="Nome da promoção"
            value={searchValue}
            onChange={e => handleSearch(e.target.value)}
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: searchValue && (
                <InputAdornment position="start">
                  <IconButton size="small" onClick={() => handleSearch('')}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <DisplayModeButtons
          displayMode={displayMode}
          handleShowList={() => setDisplayMode('list')}
          handleShowModule={() => setDisplayMode('module')}
        />
      </FilterBox>
      <PaginationProvider>
        <div className={classes.container}>
          {loading ? (
            displayMode === 'list' ? (
              <TableLoading />
            ) : (
              <ModuleLoading />
            )
          ) : filtered.length === 0 ? (
            <NoData message="Nenhuma promoção para mostrar" />
          ) : (
            <>
              {displayMode === 'list' ? (
                <CategoryListTable promotions={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <CategoryListModule promotions={filtered} />
              )}
            </>
          )}
          <ApiPagination
            onChangePage={value => setPage(value)}
            onChangeRowsPerPage={value => setRows(value)}
            count={total}
          />
        </div>
      </PaginationProvider>
    </PromotionsProvider>
  );
};

export default Customers;
