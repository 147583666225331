import { Button, makeStyles, Typography } from '@material-ui/core';
import DialogInput, { DialogInputConsumer } from 'components/dialog/DialogInput';
import React, { FC, useState } from 'react';
import { useProducts } from './hooks/useProducts';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-around',
    textAlign: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
});

interface DeleteProductConfirmationProps {
  onExited(): void;
}

const DeleteProductConfirmation: FC<DeleteProductConfirmationProps> = ({ onExited }) => {
  const classes = styles();
  const { handleDelete } = useProducts();
  const [deleting, setDeleting] = useState(false);

  function handleSubmit(closeModal: () => void) {
    setDeleting(true);

    handleDelete()
      .then(closeModal)
      .catch(err => console.error(err))
      .finally(() => setDeleting(false));
  }

  return (
    <DialogInput maxWidth="sm" onExited={onExited} disableEscapeKeyDown disableBackdropClick>
      <DialogInputConsumer>
        {context => (
          <div className={classes.container}>
            <Typography variant="body1">Deseja realmente excluir o produto?</Typography>

            <div className={classes.actions}>
              <Button onClick={context.handleCloseDialog} variant="text" color="primary">
                Cancelar
              </Button>
              <Button
                onClick={() => handleSubmit(context.handleCloseDialog)}
                disabled={deleting}
                variant="contained"
                color="primary"
              >
                Sim, excluir
              </Button>
            </div>
          </div>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default DeleteProductConfirmation;
