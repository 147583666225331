import React from 'react';
import { XAxis, YAxis, Tooltip, ResponsiveContainer, Bar, Legend, CartesianGrid, BarChart } from 'recharts';
import { moneyFormat, numberFormat } from 'helpers/NumberFormat';
import { useTheme } from '@material-ui/core';
import { DashboardDaysOfWeek } from 'types/dashboardData';

interface DashboardWeekDaysChartProps {
  data: DashboardDaysOfWeek[];
}

const DashboardWeekDaysChart: React.FC<DashboardWeekDaysChartProps> = ({ data }) => {
  const theme = useTheme();

  return (
    <ResponsiveContainer width="95%">
      <BarChart width={730} height={250} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis tickFormatter={value => numberFormat(value)} />
        <Tooltip formatter={value => [moneyFormat(value), 'total']} />
        <Legend formatter={() => 'total dos pedidos'} />
        <Bar fontSize={16} dataKey="value" label={entry => entry.name} fill={theme.palette.primary.main} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default DashboardWeekDaysChart;
