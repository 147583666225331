import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useUsers } from '../hooks/useUsers';

type UsersListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl(anchorEl: null | HTMLButtonElement): void;
};

const UsersListMenu: React.FC<UsersListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedUser, handleToggleUserState } = useUsers();

  function handleClick() {
    handleToggleUserState();
    setAnchorEl(null);
  }

  return (
    <>
      {selectedUser && (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          {selectedUser && (
            <MenuItem onClick={handleClick}>{selectedUser.active ? 'bloquear usuário' : 'ativar usuário'}</MenuItem>
          )}
        </Menu>
      )}
    </>
  );
};

export default UsersListMenu;
