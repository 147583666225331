import React from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { useApp } from 'App';

export const ProfileTabs = ({ tab, handleTabChange }) => {
  const app = useApp();

  return (
    <Tabs value={tab} onChange={handleTabChange} variant={app.isMobile ? 'scrollable' : 'standard'}>
      <Tab label="Info" />
      <Tab label="Endereço" />
      <Tab label="Telefone" />
      <Tab label="Personalização" />
      <Tab label="Social" />
    </Tabs>
  );
};
