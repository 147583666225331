import React, { FormEvent, useState } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import { subDays } from 'date-fns';
import { api } from 'services/api';
import Loading from 'components/loading/Loading';
import ReportTopCustomersData from './ReportTopCustomersData';
import { moneyFormat } from 'helpers/NumberFormat';
import { useMessaging } from 'hooks/messaging';
import KeyboardDatePicker from 'components/pickers/KeyboardDatePicker';
import { Button, makeStyles } from '@material-ui/core';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  dateFilter: {
    display: 'flex',
    flexDirection: 'column',
    margin: '30px 0',
    maxWidth: 400,
  },
  actions: {
    marginTop: 30,
  },
});

export default function ReportTopCustomers() {
  const [initialDate, setInitialDate] = useState(subDays(new Date(), 30));
  const [finalDate, setFinalDate] = useState(new Date());
  const [reportData, setReportData] = useState(null);
  const messaging = useMessaging();
  const [loading, setLoading] = useState(false);
  const classes = styles();

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    setLoading(true);
    e.preventDefault();
    api
      .get('/reports/topCustomers', {
        params: {
          initialDate,
          finalDate,
        },
      })
      .then(response => {
        setReportData(
          response.data.map(customer => {
            customer.formattedTotal = moneyFormat(customer.total);
            return customer;
          })
        );
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Erro ao emitir o relatório');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      {loading && <Loading />}

      {reportData && <ReportTopCustomersData data={reportData} onExited={() => setReportData(null)} />}

      <form onSubmit={handleSubmit} className={classes.container}>
        <CustomAppbar title="Relatório" />

        <PageHeader
          title="Total de pedidos por clientes"
          description="Totalização de quantidade e total de pedidos por clientes em um período"
        />

        <div className={classes.dateFilter}>
          <KeyboardDatePicker
            autoOk
            label="Data inicial"
            onChange={date => setInitialDate(date as any)}
            value={initialDate}
            fullWidth
            margin="normal"
          />

          <KeyboardDatePicker
            autoOk
            label="Data inicial"
            onChange={date => setFinalDate(date as any)}
            value={finalDate}
            fullWidth
            margin="normal"
          />

          <div className={classes.actions}>
            <Button variant="contained" color="primary" type="submit">
              Gerar relatório
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}
