import React, { FormEvent, useEffect, useRef } from 'react';
import { Grid, TextField, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import PhoneInput from 'components/masked-input/PhoneInput';
import { Validation } from './new/UserNew';
import { makeStyles } from '@material-ui/core/styles';
import UserImageForm from './UserImageForm';
import { UserRegister } from 'store/modules/user/types';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  imageContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 20,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  link: {
    marginTop: 20,
    marginBottom: 20,
    '& a': {
      fontWeight: 400,
    },
  },
  radioContent: {
    marginTop: 20,
  },
}));

type UserFormProps = {
  handleValidation(event: FormEvent): void;
  user: UserRegister;
  validation: Validation;
  handleChange(index: string, value: any): void;
};

const UserForm: React.FC<UserFormProps> = ({ handleValidation, user, validation, handleChange }) => {
  const classes = useStyles();

  const inputs = {
    email: useRef<HTMLInputElement>(null),
    name: useRef<HTMLInputElement>(null),
    phone: useRef<HTMLInputElement>(null),
    password: useRef<HTMLInputElement>(null),
    password_confirmation: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  return (
    <form onSubmit={handleValidation}>
      <div className={classes.container}>
        <div className={classes.imageContent}>
          <UserImageForm user={user} handleChange={handleChange} />
        </div>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <TextField
            inputRef={inputs.email}
            error={!!validation.email}
            helperText={validation.email && validation.email}
            variant="standard"
            margin="normal"
            fullWidth
            label="E-mail"
            placeholder="Seu e-mail"
            value={user.email}
            onChange={event => handleChange('email', event.target.value)}
            autoComplete="email"
            autoFocus
          />
          <TextField
            inputRef={inputs.name}
            error={!!validation.name}
            helperText={validation.name && validation.name}
            variant="standard"
            margin="normal"
            fullWidth
            label="Nome completo"
            placeholder="Seu nome completo"
            value={user.name}
            autoComplete="name"
            onChange={event => handleChange('name', event.target.value)}
          />
          <TextField
            inputRef={inputs.phone}
            error={!!validation.phone}
            helperText={validation.phone && validation.phone}
            variant="standard"
            margin="normal"
            fullWidth
            label="Telefone"
            placeholder="Seu telefone"
            value={user.phone}
            onChange={event => handleChange('phone', event.target.value)}
            InputProps={{
              inputComponent: PhoneInput as any,
            }}
            autoComplete="tel"
          />
          <TextField
            inputRef={inputs.password}
            error={!!validation.password}
            helperText={validation.password && validation.password}
            variant="standard"
            margin="normal"
            fullWidth
            label="Senha"
            placeholder="Sua senha"
            value={user.password}
            autoComplete="current-password"
            onChange={event => handleChange('password', event.target.value)}
            type="password"
          />
          <TextField
            inputRef={inputs.password_confirmation}
            error={!!validation.password_confirmation}
            helperText={validation.password_confirmation && validation.password_confirmation}
            variant="standard"
            margin="normal"
            fullWidth
            label="Confirmação da senha"
            placeholder="A confirmação da senha"
            value={user.password_confirmation}
            autoComplete="new-password"
            onChange={event => handleChange('password_confirmation', event.target.value)}
            type="password"
          />
          <RadioGroup
            className={classes.radioContent}
            name="rule"
            value={user.rule}
            onChange={e => handleChange('rule', e.target.value)}
          >
            <FormControlLabel value="admin-admin" control={<Radio />} label="Administrador" />
            <FormControlLabel value="admin-operator" control={<Radio />} label="Operador" />
            <FormControlLabel value="admin-waiter" control={<Radio />} label="Garçom" />
          </RadioGroup>
        </Grid>
      </div>
      <button type="submit" style={{ display: 'none' }} />
    </form>
  );
};

export default UserForm;
