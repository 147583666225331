import React from 'react';
import { Tab, Tabs } from '@material-ui/core';

interface CustomerTabsProps {
  tab: number;
  handleTabChange(value: any): void;
}

const CustomerTabs: React.FC<CustomerTabsProps> = ({ tab, handleTabChange }) => {
  return (
    <Tabs value={tab} onChange={(e, value) => handleTabChange(value)}>
      <Tab label="Cadastro" />
      <Tab label="Endereços" />
      <Tab label="Pedidos" />
    </Tabs>
  );
};

export default CustomerTabs;
