import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ProductStatus from './ProductStatus';
import { OrderProduct } from 'types/order';
import { useDispatch } from 'react-redux';
import { updateProduct } from 'store/redux/modules/order/actions';
import { OrderResumeProductsProvider } from '../resume/hooks/useOrderResumeProducts';
import ProductSimple from '../resume/products/detail/simple/ProductSimple';
import { useMemo, useState } from 'react';
import ProductPizzaComplement from '../resume/products/detail/pizza_complement/ProductPizzaComplement';
import ProductComplement from '../resume/products/detail/complements/ProductComplement';

const useStyles = makeStyles({
  category: {
    marginBottom: 12,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  span: {
    marginRight: 10,
  },
  categoryName: {
    display: 'inline-block',
    minWidth: 150,
    fontWeight: 500,
  },
  productName: {
    minWidth: 100,
    display: 'inline-flex',
    marginRight: 12,
  },
  doneIcon: {
    marginLeft: 10,
  },
  title: {
    fontWeight: 600,
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
  categoryProducts: {
    marginLeft: 6,
  },
  editButtons: {
    position: 'absolute',
    right: 0,
  },
});

interface ProductsStatusProps {
  products: OrderProduct[];
}

export default function ProductsStatus({ products }: ProductsStatusProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState<OrderProduct | null>(null);

  const isPizza = useMemo(() => {
    return !!selectedProduct?.category.is_pizza;
  }, [selectedProduct]);

  const isComplement = useMemo(() => {
    return !!selectedProduct?.category.has_complement && !selectedProduct?.category.is_pizza;
  }, [selectedProduct]);

  const isSimple = useMemo(() => {
    return selectedProduct ? !selectedProduct.category.has_complement : false;
  }, [selectedProduct]);

  function handleUpdateProduct(product: OrderProduct, amount: number) {
    dispatch(updateProduct(product, amount));
  }

  return (
    <OrderResumeProductsProvider value={{ handleUpdateProduct, selectedProduct, setSelectedProduct }}>
      {isSimple && <ProductSimple hideBackdrop={false} onExited={() => setSelectedProduct(null)} />}
      {isPizza && <ProductPizzaComplement hideBackdrop={false} onExited={() => setSelectedProduct(null)} />}
      {isComplement && <ProductComplement hideBackdrop={false} onExited={() => setSelectedProduct(null)} />}

      <div className={classes.head}>
        <Typography variant="body1" display="inline" className={classes.title}>
          Produtos
        </Typography>
      </div>

      {products.map((product, index) => (
        <div key={index} className={classes.category}>
          <ProductStatus product={product} setSelectedProduct={setSelectedProduct} />
        </div>
      ))}
    </OrderResumeProductsProvider>
  );
}
