export const orderStatus = {
  p: 'aguardando pagamento',
  o: 'aberto',
  a: 'aprovado',
  d: 'saiu para entrega',
  c: 'completo',
  x: 'cancelado',
};

export function getStatusText(shipmentMethod, status) {
  if (status === 'd') {
    return shipmentMethod === 'delivery' ? 'saiu para entrega' : 'pronto para retirada';
  }

  return orderStatus[status];
}
