import React, { useRef, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddressValidation } from './validation/useAddressValidation';
import { Address } from 'types/address';
import { AreaRegion } from 'types/area';
import { useSelector } from 'store/redux/selector';
import { api } from 'services/api';
import { moneyFormat } from 'helpers/NumberFormat';
import InsideSaving from 'components/loading/InsideSaving';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      marginBottom: 72,
    },
  },
  street: {
    display: 'grid',
    gridTemplateColumns: '30% 1fr',
    gap: '15px',
  },
  addressDescription: {
    display: 'flex',
  },
  cityStateContent: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gap: 15,
  },
}));

interface AddressFormProps {
  handleChange(index: keyof Address, value: any): void;
  validation: AddressValidation;
  address: Address;
  areaRegionId: number | null;
  setAreaRegionId: Dispatch<SetStateAction<number | null>>;
}

const AddressForm: React.FC<AddressFormProps> = ({
  validation,
  handleChange,
  address,
  areaRegionId,
  setAreaRegionId,
}) => {
  const classes = useStyles();
  const [districts, setDistricts] = useState<AreaRegion[]>([]);
  const [loading, setLoading] = useState(false);
  const restaurant = useSelector(state => state.restaurant);
  const [district, setDisitrict] = useState<AreaRegion | null>(null);

  useEffect(() => {
    if (!areaRegionId) {
      return;
    }

    setDisitrict(districts.find(item => item.id === areaRegionId) ?? null);
  }, [districts, areaRegionId]);

  const inputs = {
    address: useRef<HTMLInputElement>(null),
    number: useRef<HTMLInputElement>(null),
    district: useRef<HTMLInputElement>(null),
    city: useRef<HTMLInputElement>(null),
    region: useRef<HTMLInputElement>(null),
    areaRegionId: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  function handleSelectAreaRegion(district: AreaRegion | null) {
    setDisitrict(district);

    if (!district) {
      setAreaRegionId(null);
      handleChange('district', '');
      return;
    }

    handleChange('district', district.name);
    setAreaRegionId(district.id);
  }

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];
    if (!key) return;
    if (!inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  useEffect(() => {
    if (restaurant?.configs.tax_mode !== 'district') {
      return;
    }

    setLoading(true);

    api
      .get<AreaRegion[]>('/areaRegions')
      .then(response => {
        setDistricts(
          response.data.map(neighborhood => {
            neighborhood.formattedTax = moneyFormat(neighborhood.tax);
            return neighborhood;
          })
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [restaurant]);

  return (
    <div className={classes.form}>
      {loading && <InsideSaving />}

      {address.city && (
        <div className={classes.addressDescription}>
          <Typography>{`Endereço em ${address.city} - ${address.region}`}</Typography>
        </div>
      )}

      <TextField
        inputRef={inputs.address}
        error={!!validation.address}
        helperText={validation.address}
        label="Endereço"
        placeholder="Digite o endereço"
        margin="normal"
        fullWidth
        value={address.address}
        onChange={event => handleChange('address', event.target.value)}
        autoCapitalize="words"
      />

      <div className={classes.street}>
        <TextField
          inputRef={inputs.number}
          error={!!validation.number}
          helperText={validation.number}
          label="Número"
          placeholder="Digite o número"
          margin="normal"
          fullWidth
          value={address.number}
          onChange={event => handleChange('number', event.target.value)}
          autoFocus={!!address.postal_code}
        />

        {restaurant?.configs.tax_mode === 'district' ? (
          <Autocomplete
            id="neighborhood"
            options={districts}
            renderInput={params => (
              <TextField
                {...params}
                inputRef={inputs.areaRegionId}
                helperText={validation.areaRegionId || validation.district}
                error={!!validation.areaRegionId || !!validation.district}
                margin="normal"
                placeholder="Bairro"
                label="Selecione o bairro"
              />
            )}
            onChange={(event, value) => handleSelectAreaRegion(value)}
            value={district}
            getOptionLabel={option => `${option.name} - ${option.formattedTax}`}
            fullWidth
          />
        ) : (
          <TextField
            inputRef={inputs.district}
            error={!!validation.district}
            helperText={validation.district}
            label="Bairro"
            placeholder="Digite o bairro"
            margin="normal"
            fullWidth
            value={address.district}
            onChange={event => handleChange('district', event.target.value)}
          />
        )}
      </div>

      <TextField
        label="Complemento"
        placeholder="Digite o complemento"
        margin="normal"
        fullWidth
        value={address.complement}
        onChange={event => handleChange('complement', event.target.value)}
      />

      <TextField
        label="Ponto de referência"
        placeholder="Digite o complemento"
        margin="normal"
        fullWidth
        value={address.reference_point}
        onChange={event => handleChange('reference_point', event.target.value)}
      />

      <div className={classes.cityStateContent}>
        <TextField
          inputRef={inputs.city}
          error={!!validation.city}
          helperText={validation.city}
          label="Cidade"
          placeholder="Informa a cidade"
          margin="normal"
          fullWidth
          value={address.city}
          onChange={event => handleChange('city', event.target.value)}
        />

        <TextField
          inputRef={inputs.region}
          error={!!validation.region}
          helperText={validation.region}
          label="Estado"
          placeholder="Informa o estado"
          margin="normal"
          fullWidth
          value={address.region}
          onChange={event => handleChange('region', event.target.value)}
        />
      </div>

      <button type="submit" style={{ display: 'none' }} />
    </div>
  );
};

export default AddressForm;
