import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';

type PaymentGatewaySettingsAppbarActionsProps = {
  handleSubmit(): void;
  loading: boolean;
  saving: boolean;
};

const PaymentGatewaySettingsAppbarActions: React.FC<PaymentGatewaySettingsAppbarActionsProps> = ({
  handleSubmit,
  loading,
  saving,
}) => {
  return (
    <IconButton disabled={loading || saving} onClick={handleSubmit} color="inherit">
      <DoneIcon />
    </IconButton>
  );
};

export default PaymentGatewaySettingsAppbarActions;
