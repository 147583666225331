import React, { useState, useEffect } from 'react';
import { api } from 'services/api';
import ProductLoading from 'pages/products/ProductsLoading';
import NoData from 'components/nodata/NoData';
import { promotionSetProduct } from 'store/modules/promotion/actions';
import PropTypes from 'prop-types';
import PromotionProductsList from './PromotionProductsList';
import { moneyFormat } from 'helpers/NumberFormat';
import ProductComplement from './complement/ProductComplement';
import ProductPizzaComplement from './pizza_complement/ProductPizzaComplement';
import ProductSimple from './simple/ProductSimple';
import { usePromotion } from 'pages/promotion/hooks/usePromotion';

PromotionProducts.propTypes = {
  promotionProducts: PropTypes.array.isRequired,
};

export default function PromotionProducts({ promotionProducts }) {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { dispatch } = usePromotion();
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    api
      .get('/promotion/products')
      .then(response => {
        const products = response.data.map(product => {
          product.selected = promotionProducts.some(p => product.id === p.product_id);
          product.product_id = product.product_id || product.id;
          product.formattedPrice = moneyFormat(product.price);
          product.complement_categories = product.complement_categories.map(category => {
            category.product_complement_category_id = category.id;
            category.complements = category.complements.map((complement, index) => {
              complement.product_complement_id = complement.id;
              complement.selected = category.is_pizza_size && index === 0;
              complement.formattedPrice = complement.price && moneyFormat(complement.price);

              complement.prices = complement.prices.map((price, index) => {
                price.product_complement_price_id = price.id;
                price.formattedPrice = price.price && moneyFormat(price.price);
                price.selected = index === 0;
                return price;
              });

              return complement;
            });

            return category;
          });

          return product;
        });
        setProducts(products);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setProducts(oldProducts =>
      oldProducts.map(product => {
        product.selected = promotionProducts.some(p => p.id === product.id);
        return product;
      })
    );
  }, [promotionProducts]);

  // confirm selected product
  function handleConfirmProduct(product) {
    dispatch(promotionSetProduct(product));
  }

  return (
    <>
      {selectedProduct && (
        <>
          {selectedProduct.category.is_pizza ? (
            <ProductPizzaComplement
              onExited={() => setSelectedProduct(null)}
              selectedProduct={selectedProduct}
              handleConfirmProduct={handleConfirmProduct}
            />
          ) : selectedProduct.category.has_complement ? (
            <ProductComplement
              onExited={() => setSelectedProduct(null)}
              selectedProduct={selectedProduct}
              handleConfirmProduct={handleConfirmProduct}
            />
          ) : (
            <ProductSimple
              onExited={() => setSelectedProduct(null)}
              selectedProduct={selectedProduct}
              handleConfirmProduct={handleConfirmProduct}
            />
          )}
        </>
      )}
      {loading ? (
        <ProductLoading />
      ) : products.length > 0 ? (
        <PromotionProductsList products={products} handleSetSelectedProduct={product => setSelectedProduct(product)} />
      ) : (
        products.length === 0 && <NoData message="Não há categorias para exibir" />
      )}
    </>
  );
}
