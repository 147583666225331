import React, { useRef, useEffect } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { FiscalSerial } from '../types/fiscal-serial';
import { FiscalSerialValidation } from './validation/useTaxRuleValidation';

interface FiscalSerialRuleFormProps {
  handleChange(key: keyof FiscalSerial, value: any): void;
  serial: FiscalSerial;
  validation: FiscalSerialValidation;
  type: 'update' | 'new';
}

const FiscalSerialRuleForm: React.FC<FiscalSerialRuleFormProps> = ({ handleChange, serial, validation, type }) => {
  const inputs = {
    sequence: useRef<HTMLInputElement>(null),
    serial: useRef<HTMLInputElement>(null),
    type: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;
    if (!inputs[key]) return;

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <>
      <TextField
        inputRef={inputs.type}
        error={!!validation.type}
        helperText={validation.type}
        label="Tipo"
        placeholder="Tipo da série"
        fullWidth
        margin="normal"
        value={serial.type}
        onChange={e => handleChange('type', e.target.value)}
        select
        disabled={type === 'update'}
      >
        <MenuItem value="sat">SAT</MenuItem>
        <MenuItem value="nfce">NFC-e</MenuItem>
        <MenuItem value="nfe">NFE</MenuItem>
      </TextField>

      <TextField
        inputRef={inputs.serial}
        error={!!validation.serial}
        helperText={validation.serial}
        label="Série"
        placeholder="Série"
        fullWidth
        margin="normal"
        value={serial.serial}
        onChange={e => handleChange('serial', e.target.value)}
        disabled={type === 'update'}
      />

      <TextField
        inputRef={inputs.sequence}
        error={!!validation.sequence}
        helperText={validation.sequence}
        label="Sequência"
        placeholder="Sequência"
        fullWidth
        margin="normal"
        value={serial.sequence}
        onChange={e => handleChange('sequence', e.target.value)}
        autoFocus
      />
    </>
  );
};

export default FiscalSerialRuleForm;
