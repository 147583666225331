import React, { FormEvent, useState } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import Loading from 'components/loading/Loading';
import CategoryAction from './CategoryAction';
import { api } from 'services/api';
import { categoryChange, deletePhoto, setSelectedPhoto, availabilityChange } from 'store/modules/category/actions';
import history from 'services/history';
import { useMessaging } from 'hooks/messaging';
import { useCategoryReducer } from 'store/modules/category/reducer';
import { CategoryProvider } from '../hooks/useCategory';
import CategoryAvailability from '../CategoryAvailability';
import { Category } from 'types/category';
import { useCategoryValidation } from '../validation/categoryValidation';
import CategoryTabs from '../CategoryTabs';
import CategoryForm from '../CategoryForm';
import CategoryExtraForm from '../CategoryExtraForm';
import PageHeader from 'components/page-header/PageHeader';

const CategoryNew: React.FC = () => {
  const messagingContext = useMessaging();
  const [category, dispatch] = useCategoryReducer();
  const [saving, setSaving] = useState(false);
  const [tab, setTab] = useState(0);
  const [validation, setValidation, validate] = useCategoryValidation();

  function handleChange(value: any, index: keyof Category) {
    dispatch(categoryChange(index, value));
  }

  function handleValidation(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setValidation({});
    validate(category)
      .then(() => handleSubmit())
      .catch(err => {
        console.log(err);
        setTab(0);
      });
  }

  function handleSubmit() {
    setSaving(true);

    let saved = false;

    api
      .post('/categories', category)
      .then(() => {
        messagingContext.handleOpen('Salvo!');
        saved = true;
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 422) {
            setTab(0);
            messagingContext.handleOpen('Não salvou. Verifique os campos obrigatórios');
          }
          messagingContext.handleOpen(err.response.data.error);
        } else messagingContext.handleOpen('Não foi possível salvar a categoria');
      })
      .finally(() => {
        saved ? history.push('/menu/categories') : setSaving(false);
      });
  }

  function handleTabChange(value: number) {
    setTab(value);
  }

  function handleImageSelect() {
    dispatch(setSelectedPhoto());
  }

  function handlePhotoDelete() {
    dispatch(deletePhoto());
  }

  function getCancelAction() {
    if (category.imageSelected) return handleImageSelect;
  }

  function handleAvailabilityChange(day, status) {
    dispatch(availabilityChange(day, status));
  }

  return (
    <CategoryProvider value={{ category, dispatch }}>
      {saving && <Loading />}

      <CustomAppbar
        backAction={getCancelAction()}
        title={category.imageSelected ? 'Foto' : 'Nova categoria'}
        ActionComponents={
          <CategoryAction
            saving={saving}
            handlePhotoDelete={handlePhotoDelete}
            photoSelected={category.imageSelected}
            handleValidation={handleValidation}
          />
        }
        Tab={<CategoryTabs tab={tab} handleTabChange={handleTabChange} />}
      />

      <PageHeader
        title="Nova categoria"
        description="Cadastro de nova categoria de produto"
        backAction={() => history.push('/menu/categories')}
      />

      <form onSubmit={handleValidation}>
        {tab === 0 && <CategoryForm validation={validation} category={category} handleChange={handleChange} />}
        {tab === 1 && <CategoryExtraForm category={category} handleChange={handleChange} />}
        {tab === 2 && (
          <CategoryAvailability
            availability={category.availability}
            handleAvailabilityChange={handleAvailabilityChange}
          />
        )}
        <button type="submit" style={{ display: 'none' }} />
      </form>
    </CategoryProvider>
  );
};

export default CategoryNew;
