import { TableTemplate } from 'types/tableTemplate';

export const boardsTableTemplate: TableTemplate[] = [
  {
    id: 'number',
    description: 'NÚMERO',
    originalId: 'number',
    width: 100,
  },
  {
    id: 'description',
    description: 'DESCRIÇÃO',
    originalId: 'description',
    width: 350,
  },
  {
    id: 'formattedMaxValue',
    description: 'VALOR MÁXIMO',
    originalId: 'max_value',
    width: 120,
    notSearchable: true,
  },
  {
    id: 'formattedCustomerNameRequired',
    description: 'SOLICITA CLIENTE',
    originalId: 'customer_name_required',
    width: 120,
  },
  {
    id: 'formattedDeliveryLocationRequired',
    description: 'SOLICITA LOCAL',
    originalId: 'delivery_location_required',
    width: 120,
  },
  {
    id: 'formattedActive',
    description: 'ATIVO',
    originalId: 'active',
    width: 120,
  },
  {
    id: 'id',
    description: 'ID',
    originalId: 'id',
    width: 300,
    notFilterable: true,
  },
];
