import React, { Dispatch, MouseEvent } from 'react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { Category } from 'types/category';
import { useTable } from 'components/table/hook/useTable';
import { BoardOrderProduct } from 'types/boardOrderProduct';
import { MoreHoriz } from '@material-ui/icons';
import { useBoardControl } from '../../hooks/useBoardControl';

const styles = makeStyles(theme => ({
  p: { backgroundColor: '#17a2b8', color: '#fff' },
  o: { backgroundColor: theme.palette.warning.light },
  a: { backgroundColor: theme.palette.success.light, color: '#fff' },
  d: { backgroundColor: theme.palette.info.light, color: '#fff' },
  c: { backgroundColor: '#6c757d', color: '#fff' },
  x: { backgroundColor: theme.palette.error.light, color: '#fff' },
  status: {
    padding: '4px 10px',
    borderRadius: 0,
    fontSize: 12,
    width: 145,
  },
  payment: {
    padding: '4px 10px',
    borderRadius: 0,
    fontSize: 12,
    width: 80,
    '&.no-paid': {
      backgroundColor: theme.palette.error.light,
      color: '#fff',
    },
    '&.paid': {
      backgroundColor: theme.palette.success.light,
      color: '#fff',
    },
  },
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
}));

type BoardProductItemProps = {
  product: BoardOrderProduct;
  setAnchorEl: Dispatch<HTMLButtonElement | null>;
};

const BoardProductItem: React.FC<BoardProductItemProps> = ({ product, setAnchorEl }) => {
  const classes = styles();
  const { tableTemplate } = useTable();
  const { setSelectedProduct } = useBoardControl();

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();

    setSelectedProduct(product);
    setAnchorEl(e.currentTarget);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton className={classes.iconButton} key={item.id} onClick={handleClick}>
              <MoreHoriz />
            </IconButton>
          ) : item.id === 'formattedStatus' ? (
            <div key={item.id} className={`${classes.status} ${classes[product.status]}`}>
              <Typography variant="caption">{product[item.id]}</Typography>
            </div>
          ) : item.id === 'formattedIsPaid' ? (
            <div key={item.id} className={`${classes.payment} ${product[item.id] === 'Sim' ? 'paid' : 'no-paid'}`}>
              <Typography variant="caption">{product[item.id]}</Typography>
            </div>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{product[item.id] as keyof Category}</Typography>
            </div>
          )
        )}
    </>
  );
};

export default BoardProductItem;
