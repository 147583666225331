import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import PhoneInput from 'components/masked-input/PhoneInput';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  default: {
    marginTop: 10,
  },
});

DelivererForm.propTypes = {
  deliverer: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
};

export default function DelivererForm({ deliverer, handleChange, validation }) {
  const classes = useStyles();
  const inputs = {
    name: useRef(null),
    phone: useRef(null),
    email: useRef(null),
  };

  useEffect(() => {
    if (validation.name) inputs.name.focus();
    else if (validation.phone) inputs.phone.focus();
    else if (validation.email) inputs.email.focus();
    // eslint-disable-next-line
  }, [validation]);

  return (
    <>
      <TextField
        inputRef={ref => (inputs.name = ref)}
        label="Nome"
        placeholder="Digite o nome do entregador"
        margin="normal"
        fullWidth
        value={deliverer.name}
        onChange={e => handleChange('name', e.target.value)}
        helperText={validation.name && validation.name}
        error={!!validation.name}
        autoFocus
      />
      <TextField
        inputRef={ref => (inputs.phone = ref)}
        error={!!validation.phone}
        helperText={!!validation.phone && validation.phone}
        autoComplete="off"
        label="Telefone"
        placeholder="Telefone do entregador"
        margin="normal"
        fullWidth
        InputProps={{
          inputComponent: PhoneInput,
        }}
        value={deliverer.phone}
        onChange={e => handleChange('phone', e.target.value)}
      />
      <TextField
        label="E-mail"
        placeholder="Digite o e-mail do entregador"
        margin="normal"
        fullWidth
        value={deliverer.email ? deliverer.email : ''}
        onChange={e => handleChange('email', e.target.value)}
        helperText={validation.email && validation.email}
        error={!!validation.email}
        type="email"
      />
      <FormControlLabel
        className={classes.default}
        control={
          <Checkbox
            checked={deliverer.is_main}
            onChange={e => handleChange('is_main', e.target.checked)}
            color="primary"
          />
        }
        label="Entregador padrão"
      />

      <button type="submit" style={{ display: 'none' }} />
    </>
  );
}
