import React, { useReducer, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import CouponForm from './CouponForm';
import couponReducer, { INITIAL_STATE as couponInitialState } from 'store/modules/coupon/reducer';
import { couponChange, setCoupon } from 'store/modules/coupon/actions';
import CustomAppbar from 'components/appbar/Appbar';
import * as yup from 'yup';
import { api } from 'services/api';
import Loading from 'components/loading/Loading';
import history from 'services/history';
import CouponAction from './CouponAction';
import { useParams } from 'react-router-dom';
import { subDays, endOfDay } from 'date-fns';
import { useMessaging } from 'hooks/messaging';
import InsideLoading from 'components/loading/InsideLoading';
import PageHeader from 'components/page-header/PageHeader';

export default function Coupon() {
  const [coupon, dispatch] = useReducer(couponReducer, couponInitialState);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const messaging = useMessaging();
  const [validation, setValidation] = useState({});
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    api
      .get(`/coupons/${params.id}`)
      .then(response => {
        dispatch(setCoupon(response.data));
      })
      .catch(err => {
        if (err.response) {
          messaging.handleOpen(err.response.data.err);
          if (err.response.status === 404) history.push('/coupons');
        } else messaging.handleOpen('Erro ao carregar o cupom');
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  function handleChange(index, value) {
    dispatch(couponChange(index, value));
  }

  function handleFormValidation(event) {
    event.preventDefault();

    const schema = yup.object().shape({
      discount: yup
        .number()
        .typeError('O desconto deve ser um número')
        .positive('O desconto deve ser um número positivo')
        .max(100, 'O valor máximo é 100')
        .required('O desconto é obrigatório'),
      valid_at: yup
        .date()
        .nullable(true)
        .min(subDays(new Date(), 1), 'A data precisa ser maior ou igual a data de hoje'),
      amount_customer: yup
        .number()
        .integer('Informe um número válido')
        .typeError('A quantidade por cliente deve ser um número')
        .nullable(true),
      usage_amount: yup
        .number()
        .typeError('A quantidade de uso deve ser um número')
        .integer('Informe um número válido')
        .nullable(true),
      description: yup.string().required('A descrição é obrigatória'),
      name: yup.string().required('O nome do cupom é obrigatório'),
    });

    const dataToValidate = {
      ...coupon,
      usage_amount: coupon.usage_amount === '' ? null : coupon.usage_amount,
      amount_customer: coupon.amount_customer === '' ? null : coupon.amount_customer,
    };

    schema
      .validate(dataToValidate)
      .then(() => {
        handleSubmit();
      })
      .catch(err => {
        console.log(err);
        setValidation({
          [err.path]: err.message,
        });
      });
  }

  function handleSubmit() {
    setSaving(true);

    const _coupon = {
      ...coupon,
      valid_at: coupon.valid_at && endOfDay(coupon.valid_at),
    };

    api
      .put(`/coupons/${params.id}`, _coupon)
      .then(() => {
        messaging.handleOpen('Salvo');
        history.push('/coupons');
      })
      .catch(err => {
        if (err.response.data.error) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível salvar o cupom');
        setSaving(false);
      });
  }

  function handleDelete() {
    setSaving(true);
    api
      .delete(`/coupons/${params.id}`)
      .then(() => {
        messaging.handleOpen('Excluído');
        history.push('/coupons');
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível excluir o cupom');
        setSaving(false);
      });
  }

  function handleStatus() {
    setSaving(true);

    api
      .put(`/coupons/status/${coupon.id}`)
      .then(() => {
        messaging.handleOpen('Atualizado');
        handleChange('activated', !coupon.activated);
        history.push('/coupons');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}
      <PageHeader title="Editar cupom de desconto" backAction={() => history.push('/coupons')} />
      <CustomAppbar
        title="Editar cupom"
        ActionComponents={
          <CouponAction
            handleFormValidation={handleFormValidation}
            handleDelete={handleDelete}
            loading={loading}
            saving={saving}
            activated={coupon.activated}
            handleStatus={handleStatus}
          />
        }
      />
      {loading ? (
        <InsideLoading />
      ) : (
        <form onSubmit={handleFormValidation}>
          <Grid item xs={12} xl={4} lg={4} md={5}>
            <CouponForm coupon={coupon} validation={validation} handleChange={handleChange} />
          </Grid>
        </form>
      )}
    </>
  );
}
