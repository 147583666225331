import React, { useState, MouseEvent } from 'react';
import { Grid, IconButton, List, ListItem, Typography, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreVert';
import CustomDialog from 'components/dialog/CustomDialog';
import AreaCityRegionEdit from './edit/AreaRegionEdit';
import AreaCityRegionAdd from '../region/new/AreaRegionAdd';
import { useArea } from '../Areas';
import { addAreaRegion } from 'store/modules/area/actions';
import AreaRegionViewActions from './AreaRegionViewActions';
import { Area, AreaRegion } from 'types/area';

const useStyles = makeStyles({
  list: {
    display: 'grid',
    gridGap: 6,
    gridTemplateColumns: '1fr',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
  },
  moreIcon: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
});

interface AreaRegionViewProps {
  onExited(): void;
  selectedArea: Area;
  handleRegionDelete(regionId: number): void;
  handleEditAreaRegion(region: AreaRegion): void;
}

const AreaRegionView: React.FC<AreaRegionViewProps> = ({
  onExited,
  selectedArea,
  handleRegionDelete,
  handleEditAreaRegion,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<AreaRegion>({} as AreaRegion);
  const [dialogEditRegion, setDialogEditRegion] = useState(false);
  const [dialogAddCityRegion, setDialogAddCityRegion] = useState(false);
  const { dispatch } = useArea();

  function handleClick(event: MouseEvent, region: AreaRegion) {
    setAnchorEl(event.currentTarget);
    setSelectedRegion(region);
  }

  function handleDeleteClick() {
    handleRegionDelete(selectedRegion.id);
    setAnchorEl(null);
  }

  function handleEditClick() {
    setDialogEditRegion(true);
    setAnchorEl(null);
  }

  function handleAddAreaRegion(region: AreaRegion) {
    dispatch(addAreaRegion(region, selectedArea.id));
  }

  return (
    <CustomDialog
      maxWidth="sm"
      title={'Bairros de ' + selectedArea.name}
      handleModalState={onExited}
      componentActions={<AreaRegionViewActions setDialogAddCityRegion={setDialogAddCityRegion} />}
    >
      {dialogEditRegion && (
        <AreaCityRegionEdit
          handleEditAreaRegion={handleEditAreaRegion}
          selectedRegion={selectedRegion}
          onExited={() => setDialogEditRegion(false)}
        />
      )}
      {dialogAddCityRegion && (
        <AreaCityRegionAdd
          handleAddAreaRegion={handleAddAreaRegion}
          onExited={() => setDialogAddCityRegion(false)}
          hideBackdrop
        />
      )}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handleEditClick}>Alterar</MenuItem>
        <MenuItem onClick={handleDeleteClick}>Excluir</MenuItem>
      </Menu>
      <Grid container>
        <Grid xs={12}>
          <List className={classes.list}>
            {selectedArea.regions.map(region => {
              return (
                <ListItem className={classes.listItem} key={region.id}>
                  <Typography>{region.name}</Typography>
                  <Typography color="textSecondary" variant="body2">
                    Taxa de entrega {region.formattedTax}
                  </Typography>
                  <IconButton className={classes.moreIcon} onClick={e => handleClick(e, region)}>
                    <MoreIcon />
                  </IconButton>
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

export default AreaRegionView;
