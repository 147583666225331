import { Image } from 'types/image';
import { Rules } from 'types/user';

export const USER_CHANGE = 'ACCOUNT_CHANGE';
export const SET_USER = 'SET_ACCOUNT';
export const USER_IMAGE_SELECT = 'USER_IMAGE_SELECT';
export const USER_IMAGE_DELETE = 'USER_IMAGE_DELETE';

export interface UserRegister {
  id: number;
  name: string;
  phone: string;
  email: string;
  image: Image | null;
  password?: string;
  password_confirmation?: string;
  activated: boolean;
  rule: Rules;
}

interface UserChangeAction {
  type: typeof USER_CHANGE;
  index: string;
  value: string | Image;
}

interface SetUserAction {
  type: typeof SET_USER;
  user: UserRegister;
}

interface UserImageSelect {
  type: typeof USER_IMAGE_SELECT;
}

interface UserImageDelete {
  type: typeof USER_IMAGE_DELETE;
}

export type UserRegisterActionsType = UserChangeAction | SetUserAction | UserImageSelect | UserImageDelete;
