import { useState, useEffect, useMemo, Dispatch, SetStateAction } from 'react';
import Modal from 'components/modal/Modal';
import { moneyFormat } from 'helpers/NumberFormat';
import { useMessaging } from 'hooks/messaging';
import { OrderProduct } from 'types/order';
import { Product } from 'types/product';
import { useOrderResumeProducts } from '../../../hooks/useOrderResumeProducts';
import { calculateProductComplementsPrice } from 'pages/board-management/registration/registration/steps/products/detail/complement/calculateProductComplementsPrice';
import { handleSelectProductComplement } from 'pages/board-management/registration/registration/steps/products/detail/complement/handleSelectProductComplement';
import { ProductComplementProvider } from 'pages/board-management/registration/registration/steps/products/detail/hooks/useProductComplement';
import ProductComplementDetail from 'pages/board-management/registration/registration/steps/products/detail/complement/ProductComplementDetail';
import OrderResumeProductUpdate from '../action/OrderResumeProductUpdate';

interface ProductComplementProps {
  onExited(): void;
  hideBackdrop?: boolean;
}

export default function ProductComplement({ onExited, hideBackdrop = true }: ProductComplementProps) {
  const { selectedProduct } = useOrderResumeProducts();
  const [amount, setAmount] = useState(selectedProduct?.amount ?? 0);
  const messaging = useMessaging();
  const [product, setProduct] = useState<OrderProduct | null>(JSON.parse(JSON.stringify(selectedProduct)));
  const [complementsPrice, setComplementsPrice] = useState(0);

  const formattedTotal = useMemo(() => {
    if (!product) {
      return moneyFormat(0);
    }

    const total = (complementsPrice + product.product_price) * amount;
    return moneyFormat(total);
  }, [amount, complementsPrice, product]);

  useEffect(() => {
    const price = calculateProductComplementsPrice(product as Product);
    setComplementsPrice(price);
  }, [product]);

  function handleClickComplements(complementCategoryId: number, complementId: number) {
    const { newProduct } = handleSelectProductComplement(complementCategoryId, complementId, product as OrderProduct);
    setProduct(newProduct as OrderProduct);
  }

  function handleAmountUp() {
    if (!product?.ready) {
      messaging.handleOpen('Você precisa selecionar os itens obrigatórios');
      return;
    }
    setAmount(amount + 1);
  }

  function handleAmountDown() {
    if (!product?.ready) {
      messaging.handleOpen('Você precisa selecionar os itens obrigatórios');
      return;
    }
    if (amount > 1) {
      setAmount(amount - 1);
    }
  }

  const productComplementContextValue = {
    product: product as Product,
    handleClickComplements,
    setProduct: setProduct as Dispatch<SetStateAction<Product | null>>,
  };

  return (
    <Modal
      backgroundColor="#fafafa"
      onExited={onExited}
      title={`${product?.name} - Complementos`}
      displayBottomActions
      maxWidth="lg"
      height="80vh"
      hideBackdrop={hideBackdrop}
    >
      <ProductComplementProvider value={productComplementContextValue}>
        <ProductComplementDetail />
      </ProductComplementProvider>

      <OrderResumeProductUpdate
        handleAmountDown={handleAmountDown}
        handleAmountUp={handleAmountUp}
        product={product}
        amount={amount}
        total={formattedTotal}
      />
    </Modal>
  );
}
