import React from 'react';
import { IconButton } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
}));

DashboardActions.propTypes = {
  handleOpenDialog: PropTypes.func.isRequired,
};

export default function DashboardActions({ handleOpenDialog }) {
  const classes = useStyles();
  return (
    <IconButton className={classes.button} onClick={handleOpenDialog} color="inherit">
      <FilterListIcon />
    </IconButton>
  );
}
