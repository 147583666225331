import { Dispatch, SetStateAction, useEffect } from 'react';
import { useReceiptSocket } from 'hooks/useReceiptSocket';
import { ReceiptHistory, ReceiptOnList } from '../types/receipt';
import { useReceiptFormatter } from './useReceiptFormatter';

export function useReceiptSocketEvents(setReceipts: Dispatch<SetStateAction<ReceiptOnList[]>>) {
  const [socket] = useReceiptSocket();
  const [formatter] = useReceiptFormatter();

  useEffect(() => {
    socket.on('history_created', (response: ReceiptHistory) => {
      setReceipts(state =>
        state.map(receipt => {
          if (receipt.id === response.receipt_id) {
            return {
              ...receipt,
              current_status: response.status,
            };
          }

          return receipt;
        })
      );
    });

    return () => {
      socket.off('history_created');
    };
  }, [socket, setReceipts]);

  useEffect(() => {
    socket.on('receipt_created', (response: any) => {
      setReceipts(state => {
        const exist = state.some(receipt => receipt.id === response._receipt.id);

        if (exist) {
          return state;
        }

        return [formatter(response._receipt), ...state];
      });
    });

    return () => {
      socket.off('receipt_created');
    };
  });
}
