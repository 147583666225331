import React, { ReactElement, ReactNode, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  paperScrollPaper: {
    maxHeight: 'none',
  },
});

export const DialogConfirmContext = React.createContext({ handleClose: () => {} });

type DialogConfirmProps = {
  title: string;
  Action: ReactElement;
  onExited(): void;
  children: ReactNode;
};

const DialogConfirm: React.FC<DialogConfirmProps> = ({ title, children, Action, onExited }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    open: true,
  });

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <Dialog
      classes={{ paperScrollPaper: classes.paperScrollPaper }}
      open={state.open}
      onClose={handleClose}
      TransitionProps={{
        onExited,
      }}
    >
      <DialogConfirmContext.Provider
        value={{
          handleClose,
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>{Action}</DialogActions>
      </DialogConfirmContext.Provider>
    </Dialog>
  );
};

export default DialogConfirm;
