import { Theme, Typography, makeStyles } from '@material-ui/core';
import React, { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { CashRegisterMovement } from 'types/cashRegisterMovement';
import { useCashRegisterFlow } from './hooks/useCashRegisterFlow';
interface StyleProps {
  type: 'credit' | 'debit';
}

const styles = makeStyles<Theme, StyleProps>(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 10,
  },
  value: props => ({
    color: props.type === 'credit' ? theme.palette.success.main : theme.palette.error.main,
    fontWeight: 600,
  }),
  link: {
    display: 'inline-flex',
    gap: 5,
    alignItems: 'center',
    fontSize: 16,
    '& svg': {
      fontSize: 16,
    },
  },
  actions: {
    marginTop: 8,
  },
  deleteLink: {
    color: theme.palette.error.main,
  },
}));

interface CashRegisterMovementItemProps {
  movement: CashRegisterMovement;
}

const CashRegisterMovementItem: React.FC<CashRegisterMovementItemProps> = ({ movement }) => {
  const classes = styles({ type: movement.type });
  const { setIsOpenedDeleteMovementDialog, setSelectedMovement, cashRegister } = useCashRegisterFlow();

  function getDetailDescription() {
    if (movement.order_id) {
      return `pedido #${movement.order?.id}`;
    }

    if (movement.board_movement_payment_id) {
      return `conta de mesa`;
    }

    return movement.description;
  }

  function getLink() {
    if (movement.order_id) {
      return `/orders/${movement.order?.uuid}`;
    }

    if (movement.board_movement_payment_id) {
      return `/board-management/movements/${movement.board_movement_payment?.board_movement_id}`;
    }

    return '#';
  }

  function handleDeleteClick(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    setSelectedMovement(movement);
    setIsOpenedDeleteMovementDialog(true);
  }

  return (
    <div className={classes.container}>
      <div>
        <Typography>{getDetailDescription()}</Typography>

        <Typography variant="body2" color="textSecondary">
          {movement.formattedCreatedAt as any}
        </Typography>

        <div className={classes.actions}>
          {(movement.board_movement_payment_id || movement.order_id) && <Link to={getLink()}>detalhes</Link>}

          {!movement.board_movement_payment_id && !movement.order_id && !cashRegister?.closed_at && (
            <Link className={classes.deleteLink} onClick={handleDeleteClick} to="#">
              excluir
            </Link>
          )}
        </div>
      </div>

      <Typography className={classes.value}>
        {movement.type === 'debit' && '-'}
        {movement.formattedValue}
      </Typography>
    </div>
  );
};

export default CashRegisterMovementItem;
