import { Tab, Tabs } from '@material-ui/core';
import React from 'react';

export type FiscalSettingsTabOptions = 'sat' | 'nfe' | 'nfce';

interface FiscalSettingsProps {
  tab: FiscalSettingsTabOptions;
  onChange(tab: FiscalSettingsTabOptions): void;
}

const FiscalSettingsTab: React.FC<FiscalSettingsProps> = ({ onChange, tab }) => {
  return (
    <Tabs value={tab} onChange={(event, tab) => onChange(tab)}>
      <Tab value="sat" label="SAT" />
      <Tab value="nfce" label="NFC-e" />
      <Tab value="nfe" label="NF-e" />
    </Tabs>
  );
};

export default FiscalSettingsTab;
