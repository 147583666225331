import React from 'react';
import { makeStyles } from '@material-ui/core';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';

const useStyles = makeStyles(theme => ({
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '200px 1fr',
    columnGap: 10,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      flex: 1,
    },
    '& > .search-params': {
      display: 'grid',
      gridTemplateColumns: '200px 200px 200px',
      columnGap: 20,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  actions: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

type UsersFilterBoxProps = {
  gridMode: 'list' | 'module';
  setGridMode(mode: 'list' | 'module'): void;
  handleSearch(value: string): void;
};

const UsersFilterBox: React.FC<UsersFilterBoxProps> = ({ setGridMode, gridMode, handleSearch }) => {
  const classes = useStyles();

  return (
    <FilterBox>
      <div className={classes.filter} />
      <div className={classes.actions}>
        <DisplayModeButtons
          displayMode={gridMode}
          handleShowList={() => setGridMode('list')}
          handleShowModule={() => setGridMode('module')}
        />
      </div>
    </FilterBox>
  );
};

export default UsersFilterBox;
