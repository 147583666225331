import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import Star from '@material-ui/icons/Star';
import React, { Dispatch, SetStateAction } from 'react';
import { RestaurantAddress } from 'types/restaurant';
import { useProfile } from '../hook/useProfile';

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    margin: '0 10px 0 0',
    minHeight: 305,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      margin: '0 0 10px',
      minHeight: 200,
    },
  },
  star: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  btnMore: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  title: {
    marginBottom: 12,
  },
  address: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
  },
}));

interface ProfileAddressItemProps {
  address: RestaurantAddress;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
}

const ProfileAddressItem: React.FC<ProfileAddressItemProps> = ({ address, setAnchorEl }) => {
  const classes = useStyles();
  const { setSelectedAddress, handleProfileAddressDelete, handleOpenDialogUpdateAddress } = useProfile();

  function handleMoreClick(element: HTMLButtonElement) {
    setAnchorEl(element);
    setSelectedAddress(address);
  }

  function handleAddressUpdateClick(address: RestaurantAddress) {
    handleOpenDialogUpdateAddress();
    setSelectedAddress(address);
  }

  return (
    <Grid item xs={12} xl={2} lg={3} md={6}>
      <Card className={classes.card}>
        <CardContent style={!address.active ? { opacity: 0.5 } : undefined}>
          {address.is_main && (
            <Tooltip title="Endereço principal">
              <Star color="primary" className={classes.star} />
            </Tooltip>
          )}
          <Typography className={classes.title} display="block" gutterBottom variant="caption" color="primary">
            Endereço
          </Typography>
          <Typography variant="body2">{address.nickname}</Typography>
          <Typography className={classes.address} variant="h6">
            {address.address}
          </Typography>
          <Typography color={'textSecondary'}>
            {address.city}/{address.region}
          </Typography>
          <Typography color={'textSecondary'}>{address.district}</Typography>
        </CardContent>
        <CardActions>
          <Button onClick={() => handleAddressUpdateClick(address)} size="small" color="primary" variant="text">
            Alterar
          </Button>
          <Button onClick={() => handleProfileAddressDelete(address.id)} size="small" color="primary" variant="text">
            Excluir
          </Button>
        </CardActions>
        <IconButton onClick={event => handleMoreClick(event.currentTarget)} className={classes.btnMore}>
          <MoreVert />
        </IconButton>
      </Card>
    </Grid>
  );
};

export default ProfileAddressItem;
