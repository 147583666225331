import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';

interface CustomerEditActionProps {
  loading: boolean;
  saving: boolean;
  openDialogCustomerForm(): void;
  handleValidation(): void;
}

const CustomerAction: React.FC<CustomerEditActionProps> = ({
  loading,
  saving,
  openDialogCustomerForm,
  handleValidation,
}) => {
  return (
    <>
      <Tooltip title="Novo endereço">
        <div>
          <IconButton disabled={loading || saving} onClick={openDialogCustomerForm} color="inherit">
            <AddIcon />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip title="Salvar">
        <div>
          <IconButton disabled={loading || saving} onClick={handleValidation} color="inherit">
            <DoneIcon />
          </IconButton>
        </div>
      </Tooltip>
    </>
  );
};

export default CustomerAction;
