import { useState, useEffect, useMemo, SetStateAction, Dispatch } from 'react';

import { useMessaging } from 'hooks/messaging';
import { useSelector } from 'store/redux/selector';
import { OrderProduct } from 'types/order';
import { moneyFormat } from 'helpers/NumberFormat';
import { getPizzaComplementsPrice } from 'store/redux/modules/order/cases/addProduct';
import { Product } from 'types/product';
import Modal from 'components/modal/Modal';
import { useOrderResumeProducts } from '../../../hooks/useOrderResumeProducts';
import { handleSelectPizzaProductComplement } from 'pages/board-management/registration/registration/steps/products/detail/pizza_complement/handleSelectPizzaProductComplement';
import { handleSearchComplement } from 'pages/board-management/registration/registration/steps/products/detail/pizza_complement/handleSearchComplement';
import ProductPizzaComplementAdditional from 'pages/board-management/registration/registration/steps/products/detail/pizza_complement/ProductPizzaComplementAdditional';
import ProductPizzaComplementIngredient from 'pages/board-management/registration/registration/steps/products/detail/pizza_complement/ProductPizzaComplementIngredient';
import { ProductPizzaProvider } from 'pages/board-management/registration/registration/steps/products/detail/hooks/useProductPizza';
import ProductPizzaDetail from 'pages/board-management/registration/registration/steps/products/detail/pizza_complement/ProductPizzaDetail';
import OrderResumeProductUpdate from '../action/OrderResumeProductUpdate';

interface ProductPizzaComplementProps {
  onExited(): void;
  hideBackdrop?: boolean;
}

export default function ProductPizzaComplement({ onExited, hideBackdrop = true }: ProductPizzaComplementProps) {
  const messaging = useMessaging();
  const restaurant = useSelector(state => state.restaurant);
  const { selectedProduct } = useOrderResumeProducts();
  const [amount, setAmount] = useState(selectedProduct?.amount ?? 0);
  const [product, setProduct] = useState<OrderProduct | null>(JSON.parse(JSON.stringify(selectedProduct)));
  const [filteredProduct, setFilteredProduct] = useState<OrderProduct | null>(product);
  const [complementsPrice, setComplementsPrice] = useState(0);
  const [dialogIngredients, setDialogIngredients] = useState(false);
  const [dialogAdditional, setDialogAdditional] = useState(false);
  const [complementIdSelected, setComplementIdSelected] = useState<number | null>(null);
  const [complementCategoryIdSelected, setComplementCategoryIdSelected] = useState<number | null>(null);
  const [searchedCategoryId, setSearchedCategoryId] = useState<number | null>(null);
  const [searchedValue, setSearchedValue] = useState('');

  const categoryComplementSize = useMemo(
    () => product?.complement_categories.find(category => category.is_pizza_size) || null,
    [product]
  );

  const complementSizeSelected = useMemo(
    () => categoryComplementSize?.complements.find(complement => complement.selected) || null,
    [categoryComplementSize]
  );

  const formattedTotal = useMemo(() => {
    if (!product) {
      return moneyFormat(0);
    }

    const total = (complementsPrice + product.product_price) * amount;
    return moneyFormat(total);
  }, [amount, complementsPrice, product]);

  useEffect(() => {
    if (!restaurant || !product) {
      return;
    }

    const price = getPizzaComplementsPrice(product.complement_categories, restaurant.configs);

    setComplementsPrice(price);
  }, [product, restaurant]);

  useEffect(() => {
    setFilteredProduct(product);
  }, [product]);

  function handleAmountUp() {
    if (!product?.ready) {
      messaging.handleOpen('Você precisa selecionar os itens obrigatórios');
      return;
    }
    setAmount(amount + 1);
  }

  function handleAmountDown() {
    if (!product?.ready) {
      messaging.handleOpen('Você precisa selecionar os itens obrigatórios');
      return;
    }
    if (amount > 1) {
      setAmount(amount - 1);
    }
  }

  function handleClickPizzaComplements(productId: number, complementCategoryId: number, complementId: number) {
    try {
      const { newProduct } = handleSelectPizzaProductComplement(product as Product, complementCategoryId, complementId);

      setProduct(newProduct as OrderProduct);

      if (!searchedCategoryId) {
        return;
      }

      handleSearch(searchedCategoryId, searchedValue);
    } catch (err) {
      const error = err as any;
      messaging.handleOpen(error.message);
    }
  }

  function handleSearch(categoryId: number, searchValue: string) {
    setSearchedValue(searchValue);

    if (searchValue === '') {
      setFilteredProduct(product);
      setSearchedCategoryId(null);
      return;
    }

    setSearchedCategoryId(categoryId);

    if (!product) {
      return;
    }

    const newProduct = handleSearchComplement(product, searchValue, categoryId);

    setFilteredProduct(newProduct as OrderProduct);
  }

  const productPizzaContextValue = {
    product: product as Product,
    setProduct: setProduct as Dispatch<SetStateAction<Product | null>>,
    filteredProduct,
    handleClickPizzaComplements,
    openDialogAdditional: () => setDialogAdditional(true),
    openDialogIngredients: () => setDialogIngredients(true),
    setComplementCategoryIdSelected,
    setComplementIdSelected,
    complementCategoryIdSelected,
    complementIdSelected,
    complementSizeSelected,
    handleSearch,
  };

  return (
    <Modal
      backgroundColor="#fafafa"
      onExited={onExited}
      title={`${product?.name} - Complementos`}
      displayBottomActions
      maxWidth="lg"
      height="80vh"
      hideBackdrop={hideBackdrop}
    >
      {dialogAdditional && <ProductPizzaComplementAdditional onExited={() => setDialogAdditional(false)} />}
      {dialogIngredients && <ProductPizzaComplementIngredient onExited={() => setDialogIngredients(false)} />}

      <ProductPizzaProvider value={productPizzaContextValue}>
        <ProductPizzaDetail />
      </ProductPizzaProvider>

      <OrderResumeProductUpdate
        product={product}
        handleAmountDown={handleAmountDown}
        handleAmountUp={handleAmountUp}
        total={formattedTotal}
        amount={amount}
      />
    </Modal>
  );
}
