import { PaymentMethod, PaymentMethodConfigs, PicPayConfig } from 'types/paymentMethod';
import { PaymentMethodsActions } from './types';

export function paymentMethodChange(paymentMethodId: number): PaymentMethodsActions {
  return {
    type: 'PAYMENT_METHOD_CHANGE',
    paymentMethodId,
  };
}

export function paymentMethodConfigsChange(index: keyof PaymentMethodConfigs, value: any): PaymentMethodsActions {
  return {
    type: 'PAYMENT_METHOD_CONFIGS_CHANGE',
    index,
    value,
  };
}

export function setPaymentMethods(paymentMethods: PaymentMethod[]): PaymentMethodsActions {
  return {
    type: 'SET_PAYMENT_METHODS',
    paymentMethods,
  };
}

export function setPicPayConfig(picpayConfig: PicPayConfig): PaymentMethodsActions {
  return {
    type: 'SET_PICPAY_CONFIG',
    picpayConfig,
  };
}

export function setPaymentMethodConfigs(configs: PaymentMethodConfigs): PaymentMethodsActions {
  return {
    type: 'SET_PAYMENT_METHOD_CONFIGS',
    configs,
  };
}

export function picpayConfigChange(index: keyof PicPayConfig, value: any): PaymentMethodsActions {
  return {
    type: 'PICPAY_CONFIG_CHANGE',
    index,
    value,
  };
}
