import React from 'react';
import { MenuItem, TextField, makeStyles } from '@material-ui/core';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import { PaymentMethodsQueryParams } from './hooks/useFetchPaymentMethods';

const useStyles = makeStyles(theme => ({
  filter: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 500,
    columnGap: 10,
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      minWidth: 300,
    },
  },
  actions: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

type PaymentMethodsFilterBoxProps = {
  gridMode: 'list' | 'module';
  setGridMode(mode: 'list' | 'module'): void;
  queryParams: PaymentMethodsQueryParams;
  handleParamsChange(index: keyof PaymentMethodsQueryParams, value: string): void;
};

const PaymentMethodsFilterBox: React.FC<PaymentMethodsFilterBoxProps> = ({
  setGridMode,
  gridMode,
  queryParams,
  handleParamsChange,
}) => {
  const classes = useStyles();

  return (
    <FilterBox>
      <div className={classes.filter}>
        <TextField
          label="Modo"
          autoFocus
          fullWidth
          select
          onChange={event => handleParamsChange('mode', event.target.value)}
          value={queryParams.mode}
        >
          <MenuItem value="online">online</MenuItem>
          <MenuItem value="offline">offline</MenuItem>
        </TextField>

        <TextField
          label="Ativo"
          autoFocus
          fullWidth
          select
          onChange={event => handleParamsChange('active', event.target.value)}
          value={queryParams.active}
        >
          <MenuItem value="1">Sim</MenuItem>
          <MenuItem value="0">Não</MenuItem>
        </TextField>
      </div>
      <div className={classes.actions}>
        <DisplayModeButtons
          displayMode={gridMode}
          handleShowList={() => setGridMode('list')}
          handleShowModule={() => setGridMode('module')}
        />
      </div>
    </FilterBox>
  );
};

export default PaymentMethodsFilterBox;
