import { Avatar, Button, IconButton, Typography, makeStyles } from '@material-ui/core';
import DialogInput, { DialogInputConsumer } from 'components/dialog/DialogInput';
import React, { useMemo, useState } from 'react';
import { useBoardControl } from '../hooks/useBoardControl';
import { addBoardProducts, removeBoardProduct } from '../reducer/actions';
import { api } from 'services/api';
import InsideSaving from 'components/loading/InsideSaving';

const styles = makeStyles({
  container: {
    display: 'flex',
    minHeight: 350,
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-around',
    '& > .title': {
      textAlign: 'center',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  amountButtonContent: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
});

interface BoardControlSpliProductProps {
  onExited(): void;
}

const BoardControlSplitProduct: React.FC<BoardControlSpliProductProps> = ({ onExited }) => {
  const classes = styles();
  const { selectedProduct, dispatch, movement } = useBoardControl();
  const [loading, setLoading] = useState(false);

  const numbers = useMemo(() => {
    if (!selectedProduct) {
      return [];
    }

    return Array.from(Array(10).keys()).filter(number => number !== 0 && number !== 1);
  }, [selectedProduct]);

  function handleConfirm(number: number, closeDialog: () => void) {
    if (!selectedProduct) {
      closeDialog();
      return;
    }

    setLoading(true);

    api
      .post(`/board-sessions/${movement?.id}/products/${selectedProduct.id}/split`, {
        quantity: number,
      })
      .then(response => {
        closeDialog();
        dispatch(removeBoardProduct(selectedProduct.id));
        dispatch(addBoardProducts(response.data));
      })
      .catch(console.log);
  }

  return (
    <DialogInput maxWidth="sm" onExited={onExited}>
      <DialogInputConsumer>
        {context => (
          <div className={classes.container}>
            {loading && <InsideSaving />}

            <Typography className="title" variant="h6">
              Escolha em quantas vezes dividir o produto
            </Typography>

            <div className={classes.amountButtonContent}>
              {numbers.map(number => (
                <IconButton onClick={() => handleConfirm(number, context.handleCloseDialog)} key={number}>
                  <Avatar color="primary">{number}</Avatar>
                </IconButton>
              ))}
            </div>

            <div className={classes.actions}>
              <Button onClick={context.handleCloseDialog} variant="text" color="primary">
                Cancelar
              </Button>
            </div>
          </div>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default BoardControlSplitProduct;
