import { api } from 'services/api';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { FiscalSetting } from '../types/fiscal-setting';

export const fiscalSettingsInitialValue: FiscalSetting = {
  sat: {
    active: false,
    software_house_token: '',
    activation_code: '123456789',
    sat_version: '0.06',
    environment: '1',
    dll_type: 1,
    dll_path: 'C:/sat/sat.dll',
    platform_name: 'SGrande Delivery',
    platform_website_url: 'https://www.sgrande.delivery',
    paper_width: 400,
    paper_margin_top: 2,
    paper_margin_bottom: 4,
    paper_margin_right: 2,
    paper_margin_left: 2,
    print_in_one_line: false,
    print_qrcode: true,
    print_logo: true,
    print_description: false,
    printer_name: 'Print iD',
    escpos_type: '0',
    escpos_port: 'COM1',
    escpos_columns: '1',
    escpos_line_height: '20',
    escpos_lines: '20',
    print_on_authorization: false,
  },
  nfe: {},
  nfce: {
    active: false,
    csc: '',
    cscid: '123456789',
    schema_version: '4.00',
    schema_path: '',
    certificate_name: '',
    certificate_password: '',
    environment: 2,
    paper_width: 400,
    paper_margin_top: 2,
    paper_margin_bottom: 4,
    paper_margin_right: 2,
    paper_margin_left: 2,
    print_in_one_line: false,
    print_qrcode_on_side: true,
    print_logo: true,
    print_description: false,
    printer_name: 'Print iD',
    escpos_type: '0',
    escpos_port: 'COM1',
    escpos_columns: '1',
    escpos_line_height: 20,
    escpos_lines: 20,
    print_on_authorization: false,
    final_space: 100,
    font_family: 'arial',
    font_height: 14,
    font_size: 14,
    print_ean: true,
    print_item_addition_discount: true,
    print_in_two_lines: false,
  },
};

type UseFetchFiscalSettings = [FiscalSetting, Dispatch<SetStateAction<FiscalSetting>>, boolean];

export function useFetchFiscalSettings(): UseFetchFiscalSettings {
  const [fiscalSettings, setFiscalSettings] = useState(fiscalSettingsInitialValue);
  const [loading, setLoading] = useState(true);
  const { showErrorDialog } = useErrorHandler();

  useEffect(() => {
    api
      .get('/fiscal-settings')
      .then(response => {
        setFiscalSettings({
          ...response.data,
          sat: {
            ...response.data.sat,
            print_in_one_line: response.data.sat.print_in_one_line ? 1 : 0,
            print_logo: response.data.sat.print_logo ? 1 : 0,
            print_qrcode: response.data.sat.print_qrcode ? 1 : 0,
            print_description: response.data.sat.print_description ? 1 : 0,
            print_on_authorization: response.data.sat.print_on_authorization ? 1 : 0,
          },
          nfce: {
            ...response.data.nfce,
            print_in_one_line: response.data.sat.print_in_one_line ? 1 : 0,
            print_logo: response.data.sat.print_logo ? 1 : 0,
            print_qrcode_on_side: response.data.sat.print_qrcode_on_side ? 1 : 0,
            print_description: response.data.sat.print_description ? 1 : 0,
            print_on_authorization: response.data.sat.print_on_authorization ? 1 : 0,
            print_item_addition_discount: response.data.sat.print_item_addition_discount ? 1 : 0,
            print_in_two_lines: response.data.sat.print_in_two_lines ? 1 : 0,
            print_ean: response.data.sat.print_ean ? 1 : 0,
          },
        });
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível recuperar as configurações fiscais',
        });
      })
      .finally(() => setLoading(false));
  }, [showErrorDialog]);

  return [fiscalSettings, setFiscalSettings, loading];
}
