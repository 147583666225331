import { createContext, useContext } from 'react';

interface UserContextValue {
  dispatch(value: any): void;
}

const UserContext = createContext<UserContextValue>({} as UserContextValue);
export const UserProvider = UserContext.Provider;

export function useUser(): UserContextValue {
  const context = useContext(UserContext);

  if (!context) throw new Error('This hook must be in account context');

  return context;
}
