import React from 'react';
import { makeStyles, TablePagination as NativeTablePagination } from '@material-ui/core';
import { usePagination } from 'hooks/pagination';

type PaginationProps = {
  count: number;
};

const useStyles = makeStyles({
  selectRoot: {
    marginRight: 10,
  },
  toolbar: {
    paddingLeft: '0!important',
  },
  actions: {
    marginLeft: 0,
  },
});

const Pagination: React.FC<PaginationProps> = ({ count }) => {
  const { rowsPerPage, page, handleSetPage, handleSetRowsPerPage, rowsPerPageOption } = usePagination();
  const classes = useStyles();

  return (
    <NativeTablePagination
      classes={{ selectRoot: classes.selectRoot, toolbar: classes.toolbar, actions: classes.actions }}
      labelRowsPerPage="Registros"
      rowsPerPageOptions={rowsPerPageOption}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      backIconButtonProps={{
        'aria-label': 'Página anterior',
      }}
      nextIconButtonProps={{
        'aria-label': 'Próxima Página',
      }}
      onPageChange={(e, page) => handleSetPage(page)}
      onRowsPerPageChange={e => handleSetRowsPerPage(parseFloat(e.target.value))}
    />
  );
};

export default Pagination;
