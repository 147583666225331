import React from 'react';
import MaskedInput from 'react-text-mask';

const mask = (value: string): Array<RegExp | string> => {
  const _value = value.replace(/\D/g, '');

  if (_value.length > 11) {
    return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  }

  return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
};

interface TextMaskCustomProps {
  inputRef: (ref: HTMLElement | null) => void;
}

const CpfCnpjInput: React.FC<TextMaskCustomProps> = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  );
};

export default CpfCnpjInput;
