import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomAppbar from 'components/appbar/Appbar';
import NoData from 'components/nodata/NoData';
import history from 'services/history';
import { api, getCancelTokenSource } from 'services/api';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import useTableOrder from 'hooks/tableOrder';
import { useApp } from 'App';
import TableLoading from 'components/loading/TableLoading';
import PaginationProvider from 'hooks/pagination';
import BoardMovementListTable from './list/table/BoardMovementListTable';
import BoardMovementListModule from './list/module/BoardMovementListModule';
import { format, parseISO, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ModuleLoading from 'components/loading/ModuleLoading';
import { BoardMovementsProvider } from './hooks/useBoardMovements';
import ApiPagination from 'components/_pagination/ApiPagination';
import { BoardMovement } from 'types/boardMovement';
import { Paginated } from 'types/paginated';
import BoardMovementsFilterBox from './BoardMovementFilterBox';
import BoardMovementMobileFilter from './BoardMovementMobileFilter';
import BoardMovementsActions from './BoardMovementsActions';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  filter: {
    display: 'grid',
    gridTemplateColumns: '300px',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

let timer: NodeJS.Timeout;

export type BoardMovementsParams = {
  is_open: number;
  initial_date: Date | null;
  final_date: Date | null;
  customer_name: string;
};

const paramsInitialValue: BoardMovementsParams = {
  is_open: 0,
  initial_date: subDays(new Date(), 7),
  final_date: new Date(),
  customer_name: '',
};

const BoardMovements: React.FC = () => {
  const classes = useStyles();
  const [movements, setMovements] = useState<BoardMovement[]>([]);
  const [filtered, setFiltered] = useState<BoardMovement[]>([]);
  const [loading, setLoading] = useState(false);
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>('list');
  const [orderedIndex, sort] = useTableOrder();
  const app = useApp();
  const [selectedMovement, setSelectedMovement] = useState<BoardMovement | null>(null);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(20);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState<BoardMovementsParams>(paramsInitialValue);
  const [mobileFilterDialog, setMobileFilterDialog] = useState(false);

  const fetchCustomers = useCallback(
    (_params: BoardMovementsParams) => {
      setLoading(true);

      api
        .get<Paginated<BoardMovement[]>>('/boardMovements', { params: { page: page + 1, rows, ..._params } })
        .then(response => {
          setTotal(response.data.total);

          setMovements(
            response.data.data.map(item => {
              const date = parseISO(item.created_at);
              item.formattedCreatedAt = format(date, 'Pp', { locale: ptBR });
              item.formattedIsOpen = item.is_open ? 'aberto' : 'fechado';
              item.customerName = item.customer?.name || '';
              item.board_number = item.board?.number ?? '';
              return item;
            })
          );
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [rows, page]
  );

  useEffect(() => {
    let request = true;

    const source = getCancelTokenSource();

    fetchCustomers(paramsInitialValue);

    return () => {
      if (request) source.cancel();
      request = false;
    };
  }, [fetchCustomers]);

  useEffect(() => setFiltered(movements), [movements]);

  useEffect(() => {
    setDisplayMode(app.isMobile || app.windowWidth < 930 ? 'module' : 'list');
  }, [app.isMobile, app.windowWidth]);

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handleChangeParams(index: keyof BoardMovementsParams, value: any) {
    const state = {
      ...params,
      [index]: value,
    };

    setParams(state);

    if (index !== 'customer_name') {
      fetchCustomers(state);
      return;
    }

    if (value.length > 0 && value.length <= 3) {
      return;
    }

    clearTimeout(timer);

    timer = setTimeout(() => fetchCustomers(state), 500);
  }

  return (
    <BoardMovementsProvider value={{ movements, setSelectedMovement, selectedMovement }}>
      <CustomAppbar
        title="Movimento de mesas"
        ActionComponents={<BoardMovementsActions openDialog={() => setMobileFilterDialog(true)} />}
      />

      <PageHeaderActions
        backAction={() => history.push('/board-management')}
        title="Histórico de mesas"
        description="Consulta histórico de movimento de mesas"
      />

      <BoardMovementsFilterBox
        displayMode={displayMode}
        handleChangeParams={handleChangeParams}
        params={params}
        setDisplayMode={setDisplayMode}
      />

      {mobileFilterDialog && (
        <BoardMovementMobileFilter
          onExited={() => setMobileFilterDialog(false)}
          handleChangeParams={handleChangeParams}
          params={params}
        />
      )}

      <PaginationProvider>
        {loading ? (
          displayMode === 'list' ? (
            <TableLoading />
          ) : (
            <ModuleLoading />
          )
        ) : filtered.length === 0 ? (
          <NoData message="Nenhuma categoria para mostrar" />
        ) : (
          <div className={classes.container}>
            {displayMode === 'list' ? (
              <BoardMovementListTable movements={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
            ) : (
              displayMode === 'module' && <BoardMovementListModule movements={filtered} />
            )}
            <ApiPagination
              onChangePage={value => {
                setPage(value);
              }}
              onChangeRowsPerPage={value => setRows(value)}
              count={total}
            />
          </div>
        )}
      </PaginationProvider>
    </BoardMovementsProvider>
  );
};

export default BoardMovements;
