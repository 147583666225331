import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useFiscalSerials } from '../hooks/useFiscalSerials';

type FiscalSerialListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl(anchorEl: null | HTMLButtonElement): void;
};

const FiscalSerialListMenu: React.FC<FiscalSerialListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedSerial, handleActive, setDisableSequenceModal } = useFiscalSerials();

  function handleClick() {
    handleActive();
    setAnchorEl(null);
  }

  function handleDisableSequenceClick() {
    setDisableSequenceModal(true);
    setAnchorEl(null);
  }

  return (
    <>
      {selectedSerial && (
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
          <MenuItem disabled={selectedSerial.active} onClick={handleClick}>
            ativar
          </MenuItem>
          <MenuItem disabled={selectedSerial.type !== 'nfce'} onClick={handleDisableSequenceClick}>
            inutilizar numeração
          </MenuItem>
        </Menu>
      )}
    </>
  );
};

export default FiscalSerialListMenu;
