import React, { useState } from 'react';
import { Grid, TextField, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import ProductComplementIngredientAction from './ProductComplementIngredientAction';
import CustomDialog from 'components/dialog/CustomDialog';
import ComplementIngredientsImport from './import/ComplementIngredientsImport';
import {
  addComplementIngredient,
  changeComplementIngredient,
  deleteComplementIngredient,
} from 'store/modules/product/actions';
import { useProduct } from 'pages/products/registration/hooks/useProduct';

const useStyles = makeStyles(theme => ({
  container: {},
  ingredient: {
    padding: 10,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid #eee`,
    marginBottom: 10,
    position: 'relative',
    backgroundColor: '#fff',
  },
  cancelIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 100,
  },
}));

function ProductComplementIngredient({ complement, category, onExit }) {
  const { dispatch } = useProduct();
  const classes = useStyles();
  const [dialogImport, setDialogImport] = useState(false);

  function handleAddComplementIngredient() {
    dispatch(addComplementIngredient(category.id, complement.id));
  }

  function handleChangeComplementIngredient(ingredientId, name) {
    dispatch(changeComplementIngredient(category.id, complement.id, ingredientId, name));
  }

  function handleDeleteComplementIngredient(ingredientId) {
    dispatch(deleteComplementIngredient(category.id, complement.id, ingredientId));
  }

  return (
    <CustomDialog
      backgroundColor="#fafafa"
      handleModalState={onExit}
      title={`${complement.name} - ingredientes`}
      maxWidth="sm"
      height="80vh"
      componentActions={
        <ProductComplementIngredientAction
          handleAddComplementIngredient={() => handleAddComplementIngredient(category.id, complement.id)}
          openDialogImport={() => setDialogImport(true)}
        />
      }
    >
      <>
        {dialogImport && (
          <ComplementIngredientsImport complementId={complement.id} onExited={() => setDialogImport(false)} />
        )}
        {complement.ingredients.length > 0 && (
          <Grid item xs={12}>
            <div className={classes.container}>
              {complement.ingredients.map(ingredient => (
                <Grid container spacing={1} key={ingredient.id} className={classes.ingredient}>
                  <IconButton
                    size="small"
                    className={classes.cancelIcon}
                    onClick={() => handleDeleteComplementIngredient(ingredient.id)}
                  >
                    <CancelIcon color="primary" />
                  </IconButton>
                  <Grid item xs={10}>
                    <TextField
                      label="Nome do ingrediente"
                      placeholder="Nome do ingrediente"
                      fullWidth
                      autoFocus
                      value={ingredient.name}
                      onChange={event => handleChangeComplementIngredient(ingredient.id, event.target.value)}
                    />
                  </Grid>
                </Grid>
              ))}
            </div>
          </Grid>
        )}
      </>
    </CustomDialog>
  );
}

export default ProductComplementIngredient;
