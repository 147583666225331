import React from 'react';
import { TextField, MenuItem, makeStyles, Switch, Typography, Theme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { RestaurantConfig, RestaurantConfigTaxMode } from 'types/restaurant';
import { RestaurantConfigValidation } from './validation/useRestaurantConfigValidation';

interface UseStylesProps {
  taxMode: RestaurantConfigTaxMode;
}

const useStyles = makeStyles<Theme, UseStylesProps>(theme => ({
  collapse: ({ taxMode }) => ({
    height: taxMode === 'order_value' || taxMode === 'products_amount' ? 80 : 0,
    transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    overFlow: 'hidden',
    visibility: taxMode === 'order_value' || taxMode === 'products_amount' ? 'visible' : 'hidden',
  }),
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #ccc',
    padding: '20px 0',
    '& div': {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 10,
    },
  },
  container: {
    maxWidth: 600,
    width: '100%',
  },
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    columnGap: 50,
    alignItems: 'flex-start',
  },
}));

RestaurantConfigForm.propTypes = {
  handleValidation: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

interface RestaurantConfigFormProps {
  handleValidation(): void;
  settings: RestaurantConfig;
  handleChange(index: keyof RestaurantConfig, value: any): void;
  validation: RestaurantConfigValidation;
}

export default function RestaurantConfigForm({
  handleValidation,
  settings,
  handleChange,
  validation,
}: RestaurantConfigFormProps) {
  const classes = useStyles({ taxMode: settings.tax_mode });

  return (
    <div className={classes.container}>
      <form onSubmit={handleValidation} className={classes.form}>
        <div className={classes.formControl}>
          <div>
            <span>EXIGIR LOGIN</span>
            <Switch
              color="primary"
              onChange={event => handleChange('require_login', event.target.checked)}
              checked={settings.require_login}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Se ativado, os usuários precisarão fazer login para finalizar os pedidos
          </Typography>
        </div>
        {settings.require_login && (
          <>
            <div className={classes.formControl}>
              <div>
                <span>LOGIN COM GOOGLE</span>
                <Switch
                  color="primary"
                  onChange={event => handleChange('google_login', event.target.checked)}
                  checked={settings.google_login}
                />
              </div>
              <Typography variant="body2" color="textSecondary">
                Permitir que os usuários façam login com o Google
              </Typography>
            </div>
            <div className={classes.formControl}>
              <div>
                <span>LOGIN COM FACEBOOK</span>
                <Switch
                  color="primary"
                  onChange={event => handleChange('facebook_login', event.target.checked)}
                  checked={settings.facebook_login}
                />
              </div>
              <Typography variant="body2" color="textSecondary">
                Permitir que os usuários façam login com o Facebook
              </Typography>
            </div>
          </>
        )}
        <div className={classes.formControl}>
          <div>
            <span>RETIRADA</span>
            <Switch
              color="primary"
              onChange={event => handleChange('customer_collect', event.target.checked)}
              checked={settings.customer_collect}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Permitir que o cliente escolha retirar o pedido
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>AGENDAMENTO DA RETIRADA</span>
            <Switch
              color="primary"
              onChange={event => handleChange('shipment_schedule', event.target.checked)}
              checked={settings.shipment_schedule}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Permitir que o cliente agende a retirada do pedido
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>AGENDAR DATA DA RETIRADA</span>
            <Switch
              color="primary"
              onChange={event => handleChange('shipment_date_schedule', event.target.checked)}
              checked={settings.shipment_date_schedule}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Permitir o cliente selecionar uma data para retirada do pedido. Se <strong>AGENDAMENTO DA RETIRADA</strong>,
            estiver ativo e <strong>AGENDAR DATA RETIRADA</strong> desativado, o cliente poderá apenas selecionar o
            horário
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>AGENDAMENTO DA ENTREGA</span>
            <Switch
              color="primary"
              onChange={event => handleChange('allow_schedule_delivery', event.target.checked)}
              checked={settings.allow_schedule_delivery}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Permitir agendar a entrega do pedido. O usuário deve selecionar apenas o horário da entrega
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>UTILIZA PRINTER</span>
            <Switch
              color="primary"
              onChange={event => handleChange('use_printer', event.target.checked)}
              checked={settings.use_printer}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Imprime pedidos sem passar pela visualização (requer aplicação Windows).
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>IMPRIMIR PEDIDO POR PRODUTO</span>
            <Switch
              color="primary"
              onChange={event => handleChange('print_by_product', event.target.checked)}
              checked={settings.print_by_product}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Imprimir um documento por produto e vias pela quantidade do produto no pedido.
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>IMPRIMIR SOMENTE DOCUMENTO DA ENTREGA</span>
            <Switch
              color="primary"
              onChange={event => handleChange('print_only_shipment', event.target.checked)}
              checked={settings.print_only_shipment}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            A impressão da entrega acontecerá na aprovação do pedido. Ao colocar o pedido no status
            {` "saiu para entrega"`}, nenhum documento será impresso (requer aplicação Windows)
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>APROVAÇÃO NA IMPRESSÃO</span>
            <Switch
              color="primary"
              onChange={event => handleChange('order_auto_approve', event.target.checked)}
              checked={settings.order_auto_approve}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Aprova o pedido automaticamente ao imprimir (requer aplicação Windows)
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>ENDEREÇOS GOOGLE MAPS API</span>
            <Switch
              color="primary"
              onChange={event => handleChange('use_google_map_addresses', event.target.checked)}
              checked={settings.use_google_map_addresses}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Habilitar o cadastro de endereços baseado na API do Google Maps
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>PERMITE ALTERAR ENDEREÇO PARA CADASTRAR</span>
            <Switch
              color="primary"
              onChange={event => handleChange('checkout_allow_change_address', event.target.checked)}
              checked={settings.checkout_allow_change_address}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Depois de escolher o endereço no mapa, permite o usuário fazer alterações na descrição do logradouro
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>ENTREGADORES</span>
            <Switch
              color="primary"
              onChange={event => handleChange('use_deliverer', event.target.checked)}
              checked={settings.use_deliverer}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Habilita o uso de entregadores para os pedidos
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>ACUMULA DESCONTOS NO CARRINHO</span>
            <Switch
              color="primary"
              onChange={event => handleChange('cart_accumulate_discount', event.target.checked)}
              checked={settings.cart_accumulate_discount}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Descontos de promoções e cupons serão somados no carrinho
          </Typography>
        </div>
        <div className={classes.formControl}>
          <div>
            <span>SELECIONA ENDEREÇO DO RESTAURANTE</span>
            <Switch
              color="primary"
              onChange={event => handleChange('restaurant_address_selection', event.target.checked)}
              checked={settings.restaurant_address_selection}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Permite ao usuário selecionar o endereço do restaurante que receberá o pedido.
          </Typography>
        </div>

        <div className={classes.formControl}>
          <div>
            <span>USA QUANTIDADE FRACIONADA DO PRODUTO NO PEDIDO</span>
            <Switch
              color="primary"
              onChange={event => handleChange('use_fractional_product_quantity', event.target.checked)}
              checked={settings.use_fractional_product_quantity}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            Permite que o usuário possa informar quantidade fracionada ao adicionar produto ao carrinho
          </Typography>
        </div>

        <TextField
          select
          fullWidth
          margin="normal"
          onChange={e => handleChange('pizza_calculate', e.target.value)}
          label="Modo de calcular valor da pizza"
          value={settings.pizza_calculate}
        >
          <MenuItem value="higher_value">Maior valor</MenuItem>
          <MenuItem value="average_value">Média</MenuItem>
        </TextField>
        <TextField
          select
          fullWidth
          margin="normal"
          onChange={e => handleChange('tax_mode', e.target.value)}
          label="Modo taxa de entrega"
          value={settings.tax_mode || 'no_tax'}
          helperText={
            settings.tax_mode === 'order_value' && 'Se o valor mínimo do pedido não for atingido a taxa será cobrada'
          }
        >
          <MenuItem value="no_tax">Sem taxa</MenuItem>
          <MenuItem value="order_value">Valor mínimo do pedido</MenuItem>
          <MenuItem value="district">Bairros</MenuItem>
          <MenuItem value="distance">Distância</MenuItem>
          <MenuItem value="products_amount">Quantidade de produtos</MenuItem>
        </TextField>

        <div className={classes.collapse}>
          <TextField
            label="Taxa de entrega (R$)"
            placeholder="Valor taxa de entrega"
            margin="normal"
            value={settings.tax_value || ''}
            onChange={e => handleChange('tax_value', e.target.value)}
            fullWidth
            error={!!validation.tax_value}
            helperText={!!validation.tax_value && validation.tax_value}
            type="number"
            inputProps={{
              step: '0.01',
            }}
          />
        </div>

        <TextField
          label="Valor mínimo pedido (R$)"
          placeholder="Valor mínimo pedido"
          margin="normal"
          value={settings.order_minimum_value || ''}
          onChange={e => handleChange('order_minimum_value', e.target.value)}
          error={!!validation.order_minimum_value}
          helperText={!!validation.order_minimum_value && validation.order_minimum_value}
          fullWidth
          type="number"
          inputProps={{
            step: '0.01',
          }}
        />

        <TextField
          label="Quantidade mínima de produtos"
          placeholder="Quantidade mínima de produtos"
          margin="normal"
          value={settings.order_minimum_products_amount}
          onChange={e => handleChange('order_minimum_products_amount', e.target.value)}
          fullWidth
          type="number"
          inputProps={{
            step: '0.01',
          }}
        />

        <TextField
          label="Tempo para entrega delivery (minutos)"
          placeholder="Tempo para entrega delivery"
          margin="normal"
          value={settings.delivery_time || ''}
          onChange={e => handleChange('delivery_time', e.target.value)}
          fullWidth
          error={!!validation.delivery_time}
          helperText={!!validation.delivery_time && validation.delivery_time}
          type="number"
          inputProps={{
            step: '1',
          }}
        />

        <TextField
          label="Tempo para entrega mesa (minutos)"
          placeholder="Tempo para entrega mesa"
          margin="normal"
          value={settings.board_time || ''}
          onChange={e => handleChange('board_time', e.target.value)}
          fullWidth
          error={!!validation.board_time}
          helperText={!!validation.board_time && validation.board_time}
          type="number"
          inputProps={{
            step: '1',
          }}
        />

        <TextField
          label="Sequência customizada de pedidos"
          placeholder="Sequência customizada de pedidos"
          margin="normal"
          value={settings.order_sequence || ''}
          onChange={e => handleChange('order_sequence', e.target.value)}
          fullWidth
          error={!!validation.order_sequence}
          helperText={!!validation.order_sequence && validation.order_sequence}
          type="number"
          inputProps={{
            step: '1',
          }}
        />

        <TextField
          label="URL do app de mesa"
          placeholder="URL do app de mesa"
          margin="normal"
          value={settings.board_app_url || ''}
          onChange={e => handleChange('board_app_url', e.target.value)}
          fullWidth
        />

        <TextField
          label="Chave PIX"
          placeholder="Chave PIX para pagamentos offline"
          margin="normal"
          value={settings.offline_pix_key || ''}
          onChange={e => handleChange('offline_pix_key', e.target.value)}
          fullWidth
        />

        <TextField
          label="Google Analytics ID"
          placeholder="Google Analytics ID"
          margin="normal"
          value={settings.google_analytics_id || ''}
          onChange={e => handleChange('google_analytics_id', e.target.value)}
          fullWidth
        />

        <TextField
          label="Facebook Pixel ID"
          placeholder="Facebook Pixel ID"
          margin="normal"
          value={settings.facebook_pixel_id || ''}
          onChange={e => handleChange('facebook_pixel_id', e.target.value)}
          fullWidth
        />
        <button type="submit" style={{ display: 'none' }}></button>
      </form>
    </div>
  );
}
