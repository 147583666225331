import React, { useEffect, useRef, useState } from 'react';
import {
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { FiscalSetting, FiscalSettingsNfce } from '../types/fiscal-setting';
import { Printer } from 'types/printer';
import { FiscalSettingsValidation } from '../validation/useFiscalSettingsValidation';
import { CloudUpload } from '@material-ui/icons';
import CertificateUploadDialog from '../CertificateUploadDialog';

const styles = makeStyles({
  container: {
    marginBottom: 200,
  },
  row: {
    margin: '10px 0',
    '& > div': {
      display: 'flex',
      gap: 10,
      padding: 15,
    },
  },
});

interface NfceSettingsFormProps {
  settings: FiscalSetting;
  handleChange(index: keyof FiscalSettingsNfce, value: any): void;
  printers: Printer[];
  validation: FiscalSettingsValidation;
}

const NfceSettingsForm: React.FC<NfceSettingsFormProps> = ({ settings, handleChange, printers, validation }) => {
  const classes = styles();
  const [uploadDialog, setUploadDialog] = useState(false);

  const inputs = {
    'nfce.csc': useRef<HTMLInputElement>(null),
    'nfce.cscid': useRef<HTMLInputElement>(null),
    'nfce.certificate_name': useRef<HTMLInputElement>(null),
    'nfce.certificate_password': useRef<HTMLInputElement>(null),
    'nfce.schema_version': useRef<HTMLInputElement>(null),
    'nfce.schema_path': useRef<HTMLInputElement>(null),
    'nfce.final_space': useRef<HTMLInputElement>(null),
    'nfce.paper_width': useRef<HTMLInputElement>(null),
    'nfce.printer_name': useRef<HTMLInputElement>(null),
    'nfce.escpos_type': useRef<HTMLInputElement>(null),
    'nfce.escpos_port': useRef<HTMLInputElement>(null),
    'nfce.escpos_columns': useRef<HTMLInputElement>(null),
    'nfce.escpos_line_height': useRef<HTMLInputElement>(null),
    'nfce.escpos_lines': useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) {
      return;
    }

    if (!inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <div className={classes.container}>
      {uploadDialog && (
        <CertificateUploadDialog
          handleNfceSettingsChange={handleChange}
          onExited={() => setUploadDialog(false)}
          settings={settings.nfce}
        />
      )}

      <FormControlLabel
        control={
          <Switch
            checked={settings.nfce.active}
            onChange={e => handleChange('active', e.target.checked)}
            color="primary"
          />
        }
        label="ativo"
      />
      <TextField
        label="Código de segurança do contribuinte"
        placeholder="Código de segurança do contribuinte"
        margin="normal"
        value={settings.nfce.csc}
        fullWidth
        onChange={event => handleChange('csc', event.target.value)}
        autoFocus
        inputRef={inputs['nfce.csc']}
        error={!!validation['nfce.csc']}
        helperText={validation['nfce.csc']}
      />

      <TextField
        label="Id do código de segurança do contribuinte"
        placeholder="Id do código de segurança do contribuinte"
        margin="normal"
        value={settings.nfce.cscid}
        fullWidth
        onChange={event => handleChange('cscid', event.target.value)}
        inputRef={inputs['nfce.cscid']}
        error={!!validation['nfce.cscid']}
        helperText={validation['nfce.cscid']}
      />

      <TextField
        label="Senha do certificado digital A1"
        placeholder="Senha do certificado digital A1"
        margin="normal"
        value={settings.nfce.certificate_password}
        fullWidth
        onChange={event => handleChange('certificate_password', event.target.value)}
        inputRef={inputs['nfce.certificate_password']}
        error={!!validation['nfce.certificate_password']}
        helperText={validation['nfce.certificate_password']}
      />

      <TextField
        label="Nome do arquivo do certificado digital A1"
        placeholder="Nome do arquivo do certificado digital A1"
        margin="normal"
        value={settings.nfce.certificate_name}
        fullWidth
        disabled
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={!settings.nfce.certificate_password?.length}
                onClick={() => setUploadDialog(true)}
                size="small"
              >
                <CloudUpload color={settings.nfce.certificate_password?.length ? 'primary' : 'inherit'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        label="Versão do schema"
        placeholder="Versão do schema"
        margin="normal"
        value={settings.nfce.schema_version}
        fullWidth
        onChange={event => handleChange('schema_version', event.target.value)}
        select
      >
        <MenuItem value="4.00">v4</MenuItem>
      </TextField>

      <TextField
        label="Diretório do schema"
        placeholder="Diretório do schema"
        margin="normal"
        value={settings.nfce.schema_path}
        fullWidth
        onChange={event => handleChange('schema_path', event.target.value)}
        inputRef={inputs['nfce.schema_path']}
        error={!!validation['nfce.schema_path']}
        helperText={validation['nfce.schema_path']}
      />

      <TextField
        label="Ambiente"
        placeholder="Ambiente"
        margin="normal"
        value={settings.nfce.environment}
        fullWidth
        onChange={event => handleChange('environment', event.target.value)}
        select
      >
        <MenuItem value={1}>produção</MenuItem>
        <MenuItem value={2}>homologação</MenuItem>
      </TextField>

      <TextField
        label="Tamanho do papel"
        placeholder="Tamanho do papel"
        margin="normal"
        value={settings.nfce.paper_width}
        fullWidth
        onChange={event => handleChange('paper_width', event.target.value)}
        inputRef={inputs['nfce.paper_width']}
        error={!!validation['nfce.paper_width']}
        helperText={validation['nfce.paper_width']}
      />

      <TextField
        label="Espaco final"
        placeholder="Espaço final"
        margin="normal"
        value={settings.nfce.final_space}
        fullWidth
        onChange={event => handleChange('final_space', event.target.value)}
        inputRef={inputs['nfce.final_space']}
        error={!!validation['nfce.final_space']}
        helperText={validation['nfce.final_space']}
      />

      <TextField
        label="Margem superior"
        placeholder="Margem superior"
        margin="normal"
        value={settings.nfce.paper_margin_top}
        fullWidth
        onChange={event => handleChange('paper_margin_top', event.target.value)}
        select
      >
        <MenuItem value={0}>0</MenuItem>
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
      </TextField>

      <TextField
        label="Margem direita"
        placeholder="Margem direita"
        margin="normal"
        value={settings.nfce.paper_margin_right}
        fullWidth
        onChange={event => handleChange('paper_margin_right', event.target.value)}
        select
      >
        <MenuItem value={0}>0</MenuItem>
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
      </TextField>

      <TextField
        label="Margem inferior"
        placeholder="Margem inferior"
        margin="normal"
        value={settings.nfce.paper_margin_bottom}
        fullWidth
        onChange={event => handleChange('paper_margin_bottom', event.target.value)}
        select
      >
        <MenuItem value={0}>0</MenuItem>
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
      </TextField>

      <TextField
        label="Margem esquerda"
        placeholder="Margem esquerda"
        margin="normal"
        value={settings.nfce.paper_margin_left}
        fullWidth
        onChange={event => handleChange('paper_margin_left', event.target.value)}
        select
      >
        <MenuItem value={0}>0</MenuItem>
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
      </TextField>

      <TextField
        label="Imprimir em uma linha"
        placeholder="Imprimir em uma linha"
        margin="normal"
        value={settings.nfce.print_in_one_line}
        fullWidth
        onChange={event => handleChange('print_in_one_line', event.target.value)}
        select
      >
        <MenuItem value={1}>Sim</MenuItem>
        <MenuItem value={0}>Não</MenuItem>
      </TextField>

      <TextField
        label="Imprimir código QR"
        placeholder="Imprimir código QR"
        margin="normal"
        value={settings.nfce.print_qrcode_on_side}
        fullWidth
        onChange={event => handleChange('print_qrcode_on_side', event.target.value)}
        select
      >
        <MenuItem value={1}>Sim</MenuItem>
        <MenuItem value={0}>Não</MenuItem>
      </TextField>

      <TextField
        label="Imprimir logo"
        placeholder="Imprimir logo"
        margin="normal"
        value={settings.nfce.print_logo}
        fullWidth
        onChange={event => handleChange('print_logo', event.target.value)}
        select
      >
        <MenuItem value={1}>Sim</MenuItem>
        <MenuItem value={0}>Não</MenuItem>
      </TextField>

      <TextField
        label="Imprimir descrição"
        placeholder="Imprimir descrição"
        margin="normal"
        value={settings.nfce.print_description}
        fullWidth
        onChange={event => handleChange('print_description', event.target.value)}
        select
      >
        <MenuItem value={1}>Sim</MenuItem>
        <MenuItem value={0}>Não</MenuItem>
      </TextField>

      <TextField
        label="Imprimir na autorização"
        placeholder="Imprimir autorização"
        margin="normal"
        value={settings.nfce.print_on_authorization}
        fullWidth
        onChange={event => handleChange('print_on_authorization', event.target.value)}
        select
      >
        <MenuItem value={1}>Sim</MenuItem>
        <MenuItem value={0}>Não</MenuItem>
      </TextField>

      <TextField
        label="Fonte"
        placeholder="Fonte"
        margin="normal"
        value={settings.nfce.font_family}
        fullWidth
        onChange={event => handleChange('font_family', event.target.value)}
        select
      >
        <MenuItem value="arial">Arial</MenuItem>
        <MenuItem value="monospace">Monospace</MenuItem>
      </TextField>

      <TextField
        label="Tamanho da fonte"
        placeholder="Tamanho da fonte"
        margin="normal"
        value={settings.nfce.font_size}
        fullWidth
        onChange={event => handleChange('font_size', event.target.value)}
        select
      >
        <MenuItem value={12}>10</MenuItem>
        <MenuItem value={12}>12</MenuItem>
        <MenuItem value={14}>14</MenuItem>
        <MenuItem value={16}>16</MenuItem>
        <MenuItem value={18}>18</MenuItem>
        <MenuItem value={20}>20</MenuItem>
      </TextField>

      <TextField
        label="Altura da fonte"
        placeholder="Altura da fonte"
        margin="normal"
        value={settings.nfce.font_height}
        fullWidth
        onChange={event => handleChange('font_height', event.target.value)}
        select
      >
        <MenuItem value={12}>10</MenuItem>
        <MenuItem value={12}>12</MenuItem>
        <MenuItem value={14}>14</MenuItem>
        <MenuItem value={16}>16</MenuItem>
        <MenuItem value={18}>18</MenuItem>
        <MenuItem value={20}>20</MenuItem>
      </TextField>

      <TextField
        label="Imprimir EAN"
        placeholder="Imprimir EAN"
        margin="normal"
        value={settings.nfce.print_ean}
        fullWidth
        onChange={event => handleChange('print_ean', event.target.value)}
        select
      >
        <MenuItem value={1}>Sim</MenuItem>
        <MenuItem value={0}>Não</MenuItem>
      </TextField>

      <TextField
        label="Imprimir desconto e acréscimo"
        placeholder="Imprimir desconto e acréscimo"
        margin="normal"
        value={settings.nfce.print_item_addition_discount}
        fullWidth
        onChange={event => handleChange('print_item_addition_discount', event.target.value)}
        select
      >
        <MenuItem value={1}>Sim</MenuItem>
        <MenuItem value={0}>Não</MenuItem>
      </TextField>

      <TextField
        label="Imprimir em duas linhas"
        placeholder="Imprimir em duas linhas"
        margin="normal"
        value={settings.nfce.print_in_two_lines}
        fullWidth
        onChange={event => handleChange('print_in_two_lines', event.target.value)}
        select
      >
        <MenuItem value={1}>Sim</MenuItem>
        <MenuItem value={0}>Não</MenuItem>
      </TextField>

      <TextField
        label="Impressora"
        placeholder="Impressora"
        margin="normal"
        value={settings.nfce.printer_name}
        fullWidth
        onChange={event => handleChange('printer_name', event.target.value)}
        select
        inputRef={inputs['nfce.printer_name']}
        error={!!validation['nfce.printer_name']}
        helperText={validation['nfce.printer_name']}
      >
        {printers.map(printer => (
          <MenuItem value={printer.name} key={printer.id}>
            {printer.name}
          </MenuItem>
        ))}
        <MenuItem value="ESCPOS">ESCPOS</MenuItem>
      </TextField>
      {settings.nfce.printer_name === 'ESCPOS' && (
        <div>
          <TextField
            label="ESCPOS - Modelo"
            margin="normal"
            value={settings.nfce.escpos_type}
            fullWidth
            onChange={event => handleChange('escpos_type', event.target.value)}
            select
            inputRef={inputs['nfce.escpos_type']}
            error={!!validation['nfce.escpos_type']}
            helperText={validation['nfce.escpos_type']}
          >
            <MenuItem value={0}>Texto</MenuItem>
            <MenuItem value={1}>PosEpson</MenuItem>
            <MenuItem value={2}>Bematech</MenuItem>
            <MenuItem value={3}>Daruma</MenuItem>
            <MenuItem value={4}>Vox</MenuItem>
            <MenuItem value={5}>Diebold</MenuItem>
            <MenuItem value={6}>EpsonP2</MenuItem>
            <MenuItem value={7}>CustomPos</MenuItem>
            <MenuItem value={8}>PosStar</MenuItem>
            <MenuItem value={9}>ZJiang</MenuItem>
            <MenuItem value={10}>GPrinter</MenuItem>
            <MenuItem value={11}>Datecs</MenuItem>
            <MenuItem value={12}>Sunmi</MenuItem>
            <MenuItem value={13}>Externo</MenuItem>
          </TextField>

          <TextField
            label="ESCPOS - Porta"
            placeholder="ESCPOS - Porta"
            margin="normal"
            value={settings.nfce.escpos_port}
            fullWidth
            onChange={event => handleChange('escpos_port', event.target.value)}
            inputRef={inputs['nfce.escpos_port']}
            error={!!validation['nfce.escpos_port']}
            helperText={validation['nfce.escpos_port']}
          />

          <TextField
            label="ESCPOS - Colunas"
            placeholder="ESCPOS - Colunas"
            margin="normal"
            value={settings.nfce.escpos_columns}
            fullWidth
            onChange={event => handleChange('escpos_columns', event.target.value)}
            inputRef={inputs['nfce.escpos_columns']}
            error={!!validation['nfce.escpos_columns']}
            helperText={validation['nfce.escpos_columns']}
          />

          <TextField
            label="ESCPOS - Espaço entre linhas"
            placeholder="ESCPOS - Espaço entre linhas"
            margin="normal"
            value={settings.nfce.escpos_line_height}
            fullWidth
            onChange={event => handleChange('escpos_line_height', event.target.value)}
            inputRef={inputs['nfce.escpos_line_height']}
            error={!!validation['nfce.escpos_line_height']}
            helperText={validation['nfce.escpos_line_height']}
          />

          <TextField
            label="ESCPOS - pular linhas"
            placeholder="ESCPOS - pular linhas"
            margin="normal"
            value={settings.nfce.escpos_lines}
            fullWidth
            onChange={event => handleChange('escpos_lines', event.target.value)}
            inputRef={inputs['nfce.escpos_lines']}
            error={!!validation['nfce.escpos_lines']}
            helperText={validation['nfce.escpos_lines']}
          />
        </div>
      )}
    </div>
  );
};

export default NfceSettingsForm;
