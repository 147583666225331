import React, { useRef, useState, Dispatch, SetStateAction } from 'react';
import { InputAdornment, TextField, Typography, makeStyles } from '@material-ui/core';
import { api } from 'services/api';
import CustomerList from './CustomerList';
import ConnectionErrorMessage from 'components/errors/ConnectionErrorMessage';
import { phoneFormat } from 'helpers/PhoneFormat';
import CustomerSearchAction from './CustomerSearchAction';
import CustomDialog from 'components/dialog/CustomDialog';
import { Search } from '@material-ui/icons';
import InsideSaving from 'components/loading/InsideSaving';
import { Customer } from 'types/customer';

const useStyles = makeStyles({
  nodata: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const interval = 500;
let timer: NodeJS.Timeout;

interface CustomerSearchProps {
  setDialogCustomerSearch: Dispatch<SetStateAction<boolean>>;
  handleSetCustomer(customer: Customer | null): Promise<void>;
  setDialogCustomerForm: Dispatch<SetStateAction<boolean>>;
  textSearch: string;
  setTextSearch: Dispatch<SetStateAction<string>>;
  addressesLoading: boolean;
}

export default function CustomerSearch({
  setDialogCustomerSearch,
  handleSetCustomer,
  setDialogCustomerForm,
  textSearch,
  setTextSearch,
  addressesLoading,
}: CustomerSearchProps) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [httpStatusCode, setHttpStatusCode] = useState<number | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  let request: boolean;

  function handleSearch(value) {
    setTextSearch(value);
    setCustomers([]);
    setHttpStatusCode(null);
    clearTimeout(timer);
    request = false;

    if (value.length === 0) {
      setCustomers([]);
      return;
    }

    if (value.length < 2) return;

    timer = setTimeout(() => {
      request = true;
      setLoading(true);
      api
        .get('search/customers', {
          params: {
            page: 1,
            q: value,
          },
        })
        .then(response => {
          if (request) {
            setCustomers(
              response.data.data.map(customer => {
                customer.phone = customer.phone ? phoneFormat(customer.phone) : null;
                return customer;
              })
            );
            setHttpStatusCode(response.status);
          }
        })
        .catch(err => {
          if (request) {
            if (err.response) setHttpStatusCode(err.response.status);
            else setHttpStatusCode(0);
          }
        })
        .finally(() => {
          if (request) setLoading(false);
          request = false;
          inputRef.current?.focus();
        });
    }, interval);
  }

  return (
    <CustomDialog
      title="Selecione um cliente"
      handleModalState={setDialogCustomerSearch}
      componentActions={<CustomerSearchAction openDialogCustomerForm={() => setDialogCustomerForm(true)} />}
      displayBottomActions={false}
      maxWidth="sm"
    >
      {addressesLoading && <InsideSaving />}

      <TextField
        inputRef={inputRef}
        label="Busca"
        margin="normal"
        placeholder="Procure por nome ou telefone"
        fullWidth
        autoFocus
        onChange={e => handleSearch(e.target.value)}
        value={textSearch}
        helperText={loading ? 'Pesquisando...' : ''}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      {customers.length === 0 && httpStatusCode === 200 ? (
        <div className={classes.nodata}>
          <Typography variant="body1">
            Nenhum cliente foi encontrado pelo termo
            <strong>{` ${textSearch}`}</strong>
          </Typography>
        </div>
      ) : customers.length > 0 ? (
        <CustomerList handleSetCustomer={handleSetCustomer} customers={customers} />
      ) : (
        textSearch.length > 2 &&
        customers.length === 0 &&
        httpStatusCode !== null && <ConnectionErrorMessage statusCode={httpStatusCode} />
      )}
    </CustomDialog>
  );
}
