import React from 'react';
import { TextField, Button, makeStyles, MenuItem } from '@material-ui/core';
import { Restaurant } from 'types/restaurant';
import { ProfileValidation } from './validation/useProfileValidation';

const styles = makeStyles({
  button: {
    display: 'none',
  },
  form: {
    maxWidth: 500,
    marginBottom: 200,
  },
});

interface ProfileBasicProps {
  restaurant: Restaurant;
  handleInputChange(index: keyof Restaurant, value: any): void;
  validation: ProfileValidation;
  handleSubmit(): void;
}

const ProfileBasic: React.FC<ProfileBasicProps> = ({ restaurant, handleInputChange, validation, handleSubmit }) => {
  const classes = styles();

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <TextField
        autoFocus
        error={!!validation.name}
        helperText={validation.name && validation.name}
        variant="standard"
        margin="normal"
        fullWidth
        label="Nome"
        placeholder="Nome do Delivery"
        value={restaurant.name}
        onChange={event => handleInputChange('name', event.target.value)}
        required
      />
      <TextField
        error={!!validation.corporate_name}
        helperText={validation.corporate_name && validation.corporate_name}
        variant="standard"
        margin="normal"
        fullWidth
        label="Razão social"
        placeholder="Informe a razão social"
        value={restaurant.corporate_name}
        onChange={event => handleInputChange('corporate_name', event.target.value)}
      />
      <TextField
        error={!!validation.cnpj}
        helperText={validation.cnpj ? validation.cnpj : 'obrigatório para emissão de notas'}
        variant="standard"
        margin="normal"
        fullWidth
        label="CNPJ"
        placeholder="Informe o CNPJ"
        value={restaurant.cnpj}
        onChange={event => handleInputChange('cnpj', event.target.value)}
      />
      <TextField
        error={!!validation.state_subscription}
        helperText={validation.state_subscription ? validation.state_subscription : 'obrigatório para emissão de notas'}
        variant="standard"
        margin="normal"
        fullWidth
        label="Inscrição estadual"
        placeholder="Informe a inscrição estadual"
        value={restaurant.state_subscription}
        onChange={event => handleInputChange('state_subscription', event.target.value)}
      />
      <TextField
        variant="standard"
        margin="normal"
        fullWidth
        label="Regime tributário"
        placeholder="Regime tributário"
        value={restaurant.company_tax_regime}
        onChange={event => handleInputChange('company_tax_regime', event.target.value)}
        select
        helperText="obrigatório para emissão de notas"
      >
        <MenuItem value="0">simples nacional</MenuItem>
        <MenuItem value="1">lucro presumido</MenuItem>
      </TextField>
      <TextField
        variant="standard"
        margin="normal"
        fullWidth
        label="Regime do ISS"
        placeholder="Regime do ISS"
        value={restaurant.iss_tax_regime}
        onChange={event => handleInputChange('iss_tax_regime', event.target.value)}
        select
        helperText="obrigatório para emissão de notas"
      >
        <MenuItem value="0">microempresa municipal</MenuItem>
        <MenuItem value="1">estimativa</MenuItem>
        <MenuItem value="2">sociedade profissionais</MenuItem>
        <MenuItem value="3">cooperativa</MenuItem>
        <MenuItem value="4">mei</MenuItem>
        <MenuItem value="5">meepp</MenuItem>
        <MenuItem value="6">nenhum</MenuItem>
      </TextField>
      <TextField
        variant="standard"
        margin="normal"
        fullWidth
        label="Rateia desconto entre itens ISSQN"
        placeholder="Ratear desconto entre itens ISSQN"
        value={restaurant.ind_rat_iss}
        onChange={event => handleInputChange('ind_rat_iss', event.target.value)}
        select
        helperText="Indicador de rateio do Desconto sobre subtotal entre itens sujeitos à tributação ISSQN"
      >
        <MenuItem value="1">sim</MenuItem>
        <MenuItem value="0">não</MenuItem>
      </TextField>
      <TextField
        error={!!validation.working_hours}
        helperText={validation.working_hours && validation.working_hours}
        variant="standard"
        margin="normal"
        fullWidth
        label="Horário de atendimento"
        placeholder="Texto para horário de atendimento"
        value={restaurant.working_hours}
        onChange={event => handleInputChange('working_hours', event.target.value)}
        required
      />
      <TextField
        error={!!validation.url}
        helperText={validation.url && validation.url}
        variant="standard"
        margin="normal"
        fullWidth
        label="URL"
        placeholder="URL do Delivery"
        value={restaurant.url}
        onChange={event => handleInputChange('url', event.target.value)}
        required
      />
      <TextField
        error={!!validation.alias}
        helperText={validation.alias ? validation.alias : 'Usado para compor a url, delivery.sgrande.app/alias'}
        variant="standard"
        margin="normal"
        fullWidth
        label="Alias"
        placeholder="Alias do restaurante"
        value={restaurant.alias}
        onChange={event => handleInputChange('alias', event.target.value)}
        required
      />
      <TextField
        error={!!validation.play_store_link}
        helperText={validation.play_store_link && validation.play_store_link}
        variant="standard"
        margin="normal"
        fullWidth
        label="Play Store Link"
        placeholder="Play Store Link"
        value={restaurant.play_store_link}
        onChange={event => handleInputChange('play_store_link', event.target.value)}
      />
      <TextField
        error={!!validation.email}
        helperText={validation.email && validation.email}
        variant="standard"
        margin="normal"
        fullWidth
        label="E-mail de atendimento"
        placeholder="E-mail para atendimento dos clientes"
        value={restaurant.email}
        onChange={event => handleInputChange('email', event.target.value)}
        required
      />
      <TextField
        error={!!validation.accounting_email}
        helperText={
          validation.accounting_email
            ? validation.accounting_email
            : 'utilizado para o envio automático de XML de notas mensalmente'
        }
        variant="standard"
        margin="normal"
        fullWidth
        label="E-mail da contabilidade"
        placeholder="E-mail da contabilidade"
        value={restaurant.accounting_email}
        onChange={event => handleInputChange('accounting_email', event.target.value)}
      />
      <TextField
        error={!!validation.description}
        helperText={validation.description && validation.description}
        variant="standard"
        margin="normal"
        fullWidth
        label="Descrição"
        placeholder="Descrição do Delivery"
        value={restaurant.description}
        onChange={event => handleInputChange('description', event.target.value)}
        required
      />
      <TextField
        error={!!validation.title}
        helperText={validation.title && validation.title}
        variant="standard"
        margin="normal"
        fullWidth
        label="Título"
        placeholder="Título da página inicial do Delivery"
        value={restaurant.title}
        onChange={event => handleInputChange('title', event.target.value)}
        required
      />
      <TextField
        error={!!validation.keywords}
        helperText={
          validation.keywords
            ? validation.keywords
            : 'Keywords auxiliam os buscadores a indentificar o segmento de uma página da internet'
        }
        variant="standard"
        margin="normal"
        fullWidth
        label="Keywords"
        placeholder="Keywords"
        value={restaurant.keywords}
        onChange={event => handleInputChange('keywords', event.target.value)}
        minRows={4}
        multiline
      />
      <Button type="submit" className={classes.button}>
        Save
      </Button>
    </form>
  );
};

export default ProfileBasic;
