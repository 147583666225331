import React, { ReactNode } from 'react';
import { Grid, Card, Typography } from '@material-ui/core';
import { DashboardLoading } from '../Dashboard';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  card: {
    border: '1px solid #f5f5f5',
    boxShadow: 'none',
    height: 305,
    display: 'flex',
    flexDirection: 'column',
    '& .card-header': {
      padding: 20,
      '& > .title': {
        lineHeight: 1,
      },
    },
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    padding: 16,
    '& h6': {
      fontSize: 26,
    },
  },
  title: {
    fontSize: 18,
  },
});

interface DashboardLargeCardProps {
  data: Array<any>;
  loading: boolean;
  title: string;
  children: ReactNode;
}

const DashboardLargeCard: React.FC<DashboardLargeCardProps> = ({ data, loading, title, children }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Card className={classes.card}>
        <div className="card-header">
          <Typography variant="body2" className="title">
            {title}
          </Typography>
        </div>
        <div className={classes.cardContent}>
          {loading ? (
            <DashboardLoading />
          ) : data.length > 0 ? (
            children
          ) : (
            <Typography variant="caption" color="textSecondary">
              Sem dados para mostrar
            </Typography>
          )}
        </div>
      </Card>
    </Grid>
  );
};

export default DashboardLargeCard;
