import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { CustomerActions } from 'store/modules/customers/types';
import { Customer } from 'types/customer';
import { CustomerAddress } from 'types/customerAddress';

type CustomerContextValue = {
  dispatch: Dispatch<CustomerActions>;
  customer: Customer;
  setDialogNewAddress: Dispatch<SetStateAction<boolean>>;
  setDialogEditAddress: Dispatch<SetStateAction<boolean>>;
  handleUpdateIsMainAddress(addressId: number): void;
  handleDeleteAddress(addressId: number): void;
  setSelectedAddress: Dispatch<SetStateAction<CustomerAddress | null>>;
  selectedAddress: CustomerAddress | null;
};

const CustomerContext = createContext<CustomerContextValue>({} as CustomerContextValue);
export const CustomerProvider = CustomerContext.Provider;

export function useCustomer(): CustomerContextValue {
  const context = useContext(CustomerContext);
  return context;
}
