import { Tooltip, IconButton, makeStyles } from '@material-ui/core';
import { Search, ShoppingCart } from '@material-ui/icons';
import { useOrder } from '../hooks/useOrder';
import { useSelector } from 'store/redux/selector';

const styles = makeStyles(theme => ({
  badge: {
    position: 'fixed',
    top: 10,
    color: 'inherit',
    right: 30,
    fontSize: 10,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      top: 10,
      right: 25,
    },
  },
  button: {
    position: 'relative',
  },
}));

interface OrderActionProps {
  setDialogResumeOrder(): void;
}

function OrderAction({ setDialogResumeOrder }: OrderActionProps) {
  const { setShowSearchDialog } = useOrder();
  const order = useSelector(state => state.order);
  const classes = styles();

  return (
    <>
      <Tooltip title="Pesquisar produtos">
        <>
          <IconButton color="inherit" onClick={() => setShowSearchDialog(true)}>
            <Search />
          </IconButton>
        </>
      </Tooltip>

      <Tooltip title="resumo do pedido">
        <IconButton color="inherit" onClick={setDialogResumeOrder} className={classes.button}>
          {order.products.length > 0 && <span className={classes.badge}>{order.products.length}</span>}
          <ShoppingCart />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default OrderAction;
