import React, { useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import ReceiptItemTable from './ReceiptItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import ReceiptListMenu from '../ReceiptListMenu';
import { receiptsTableTemplate } from 'pages/receipts/constants/receiptsTableTemplate';
import { useReceipts } from 'pages/receipts/hooks/useReceipts';
import { Receipt } from 'pages/receipts/types/receipt';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type ReceiptListTableProps = {
  receipts: Receipt[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const ReceiptListTable: React.FC<ReceiptListTableProps> = ({ receipts, handleSort, orderedIndex }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { setSelectedReceipt } = useReceipts();

  function handleClick(receipt: Receipt) {
    setSelectedReceipt(receipt);
  }

  return (
    <>
      <ReceiptListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <TableContainer tableTemplate={receiptsTableTemplate}>
        <TableContent>
          <TableHeader>
            {receiptsTableTemplate
              .filter(item => !item.notShow)
              .map(item => (
                <div
                  className={classes.headerItem}
                  key={item.id}
                  onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
                >
                  <Typography noWrap variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId && (
                    <>
                      {orderedIndex.direction === 'asc' ? (
                        <ArrowUpward color="primary" />
                      ) : (
                        <ArrowDownward color="primary" />
                      )}
                    </>
                  )}
                </div>
              ))}
          </TableHeader>

          <TableBody heightToDiscount={500}>
            {receipts.map(item => (
              <TableRow key={item.id} onClick={() => handleClick(item)}>
                <ReceiptItemTable setAnchorEl={el => setAnchorEl(el)} receipt={item} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </>
  );
};

export default ReceiptListTable;
