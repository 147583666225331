import { Button, makeStyles, Typography } from '@material-ui/core';
import { moneyFormat } from 'helpers/NumberFormat';
import React, { useState } from 'react';
import { useSelector } from 'store/redux/selector';
import BillingPlanEdit from './BillingPlanEdit';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flex: 1,
  },
  noPlan: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  plan: {
    position: 'relative',
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
    background: '#fff',
    border: '1px solid #eee',
    padding: 15,
    flex: 1,
  },
  buttonEdit: {
    alignSelf: 'center',
  },
});

const BillingPlan: React.FC = () => {
  const classes = useStyles();
  const [dialogPlanEdit, setDialogPlanEdit] = useState(false);
  const restaurant = useSelector(state => state.restaurant);

  return (
    <div className={classes.container}>
      {dialogPlanEdit && <BillingPlanEdit onExited={() => setDialogPlanEdit(false)} />}
      {restaurant?.plan ? (
        <div className={classes.plan}>
          <div>
            <Typography>{restaurant.plan.name}</Typography>
            <Typography variant="body2">{moneyFormat(restaurant.plan.price)} por mês</Typography>
          </div>
          <Button
            size="small"
            variant="text"
            color="primary"
            className={classes.buttonEdit}
            onClick={() => setDialogPlanEdit(true)}
          >
            Alterar
          </Button>
        </div>
      ) : (
        <div className={classes.noPlan}>
          <Typography variant="body2" color="textSecondary">
            Sem plano configurado
          </Typography>
        </div>
      )}
    </div>
  );
};

export default BillingPlan;
