import React from 'react';
import { List, ListItem, Typography, Grid } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles, alpha } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    marginBottom: 5,
    backgroundColor: '#fff',
    border: '1px solid #eee',
  },
  selected: {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
    },
  },
  icon: {
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
}));

function ProductIngredientList({ ingredients, handleSelectIngredient }) {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <List dense>
        {ingredients.map(item => {
          return (
            <ListItem
              onClick={() => handleSelectIngredient(item.id)}
              key={item.id}
              className={item.selected ? `${classes.listItem} ${classes.selected}` : `${classes.listItem}`}
              button
            >
              <Typography>{item.name}</Typography>
              {item.selected && <CheckCircleIcon className={classes.icon} color={'primary'} />}
            </ListItem>
          );
        })}
      </List>
    </Grid>
  );
}

export default ProductIngredientList;
