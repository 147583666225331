import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  card: {
    minHeight: 290,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      margin: '0 0 10px',
      minHeight: 200,
    },
  },
  placeholder1: {
    height: 10,
    marginBottom: 15,
    width: 50,
  },
  placeholder2: {
    height: 20,
    marginBottom: 10,
  },
  placeholder3: {
    height: 10,
    width: '50%',
    marginBottom: 7,
  },
}));

const AreaLoading: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} xl={3} md={4} lg={3} sm={6}>
        <Card className={classes.card}>
          <CardContent>
            <div className={`animated-background ${classes.placeholder1}`} />
            <div className={`animated-background ${classes.placeholder2}`} />
            <div className={`animated-background ${classes.placeholder3}`} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AreaLoading;
