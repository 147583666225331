export type ProductStepType = {
  order: number;
  title: string;
  description: string;
  id: StepsIds;
};

type StepsIds =
  | 'STEP_1'
  | 'STEP_2'
  | 'STEP_3'
  | 'STEP_4'
  | 'STEP_5'
  | 'STEP_6'
  | 'STEP_7'
  | 'STEP_8'
  | 'STEP_9'
  | 'STEP_10';

const steps: ProductStepType[] = [
  {
    order: 1,
    title: 'Categoria',
    description: 'Selecione uma categoria',
    id: 'STEP_1',
  },
  {
    order: 2,
    title: 'Nome',
    description: 'Algumas informações basicas do produto',
    id: 'STEP_2',
  },
  {
    order: 3,
    title: 'Preço',
    description: 'Preço',
    id: 'STEP_3',
  },
  {
    order: 4,
    title: 'Ingredientes',
    description: 'Ingredientes',
    id: 'STEP_4',
  },
  {
    order: 5,
    title: 'Adicionais',
    description: 'Adicionais',
    id: 'STEP_5',
  },
  {
    order: 6,
    title: 'Complementos personalizados',
    description: 'Complementos personalizados',
    id: 'STEP_6',
  },
  {
    order: 7,
    title: 'Disponibilidade',
    description: 'Disponibilidade do produto',
    id: 'STEP_7',
  },
  {
    order: 8,
    title: 'Fiscal',
    description: 'Dados para emissão de cupom fiscal',
    id: 'STEP_8',
  },
  {
    order: 9,
    title: 'Impressora',
    description: 'Existe uma impressora padrão para esse produto na impressão dos pedidos?',
    id: 'STEP_9',
  },
  {
    order: 10,
    title: 'Salvar',
    description: 'Salvar produto',
    id: 'STEP_10',
  },
];

export { steps };
