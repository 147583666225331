import { Button, makeStyles, Typography } from '@material-ui/core';
import DialogInput, { DialogInputContext } from 'components/dialog/DialogInput';
import React from 'react';
import history from 'services/history';

const styles = makeStyles({
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    height: 200,
  },
});

interface InvoiceErrorProps {
  onExited(): void;
}

const InvoiceError: React.FC<InvoiceErrorProps> = ({ onExited }) => {
  const classes = styles();

  return (
    <DialogInput onExited={onExited}>
      <DialogInputContext.Consumer>
        {context => (
          <div className={classes.messageContainer}>
            <Typography gutterBottom>Existem faturas em aberto. Não será possível ficar online.</Typography>
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                history.push('/billing');
                context.handleCloseDialog();
              }}
            >
              minhas faturas
            </Button>
          </div>
        )}
      </DialogInputContext.Consumer>
    </DialogInput>
  );
};

export default InvoiceError;
