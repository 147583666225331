import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { CashRegister } from 'types/cashRegister';

type CashRegistersContextValue = {
  selectedCashRegister: CashRegister | null;
  setSelectedCashRegister: Dispatch<SetStateAction<CashRegister | null>>;
  handleCloseCashRegister(cashRegisterId: number): void;
  openInitialValueDialog(): void;
  handleCashRegisterListUpdate(cashRegister: CashRegister): void;
};

const CashRegistersContext = createContext<CashRegistersContextValue>({} as CashRegistersContextValue);
export const CashRegistersProvider = CashRegistersContext.Provider;

export function useCashRegisters(): CashRegistersContextValue {
  const context = useContext(CashRegistersContext);
  return context;
}
