import React from 'react';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  block: {
    height: 24,
    width: '60%',
    backgroundColor: '#eee',
    marginBottom: 20,
  },
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    border: '1px solid #f5f5f5',
    paddingTop: 15,
    height: 150,
  },
  list: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
    display: 'grid',
    gap: '10px',
  },
}));

const ImageSelectorLoading: React.FC = () => {
  const classes = useStyles();

  const items = 10;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <List className={classes.list}>
          {Array.from(Array(items).keys()).map(item => (
            <div key={item} className={`animated-background ${classes.listItem}`} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default ImageSelectorLoading;
