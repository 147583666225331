import { Button, makeStyles } from '@material-ui/core';
import { useInputDialog } from 'components/dialog/DialogInput';
import React from 'react';

const styles = makeStyles({
  actions: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'space-evenly',
  },
});

interface DisableSequenceModalActionsProps {
  saving: boolean;
  handleConfirmClick(closeModal: () => void): void;
}

const DisableSequenceModalActions: React.FC<DisableSequenceModalActionsProps> = ({ saving, handleConfirmClick }) => {
  const classes = styles();
  const { handleCloseDialog } = useInputDialog();

  return (
    <div className={classes.actions}>
      <Button onClick={handleCloseDialog} variant="text" color="primary">
        cancelar
      </Button>
      <Button
        disabled={saving}
        onClick={() => handleConfirmClick(handleCloseDialog)}
        variant="contained"
        color="primary"
      >
        confirmar
      </Button>
    </div>
  );
};

export default DisableSequenceModalActions;
