import { makeStyles, Typography } from '@material-ui/core';
import { useMessaging } from 'hooks/messaging';
import React, { MouseEvent } from 'react';
import { api } from 'services/api';
import { Product } from 'types/product';
import { useProductManager } from './hooks/useProductManager';
import ProductManagerItemComplementCategories from './ProductManagerItemComplementCategories';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    border: '1px solid #eee',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '15px',
    transition: 'background 0.3s ease',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
    },
  },
  productLine: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
  },
});

interface ProductManagerItemProps {
  product: Product;
}

const ProductManagerItem: React.FC<ProductManagerItemProps> = ({ product }) => {
  const classes = useStyles();
  const messaging = useMessaging();
  const { setProducts } = useProductManager();

  function handleProductActive(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();

    api
      .put(`/products/status/${product.id}`)
      .then(() => {
        setProducts(products =>
          products.map(_product => {
            if (_product.id === product.id) {
              _product.activated = !_product.activated;
            }

            return _product;
          })
        );
      })
      .catch(err => {
        messaging.handleOpen(err.response ? err.response.data.error : 'Não foi possível alterar o produto');
      });
  }

  return (
    <li className={classes.container}>
      <div className={classes.productLine}>
        <Typography variant="h6">{product.name}</Typography>
        <a href="/" onClick={handleProductActive}>
          {product.activated ? 'pausar' : 'ativar'}
        </a>
      </div>
      <Typography gutterBottom variant="body2" color="textSecondary">
        {product.description}
      </Typography>
      <ProductManagerItemComplementCategories productId={product.id} categories={product.complement_categories} />
    </li>
  );
};

export default ProductManagerItem;
