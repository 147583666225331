import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { promotionEditOfferedProduct, promotionDeleteOfferedProduct } from 'store/modules/promotion/actions';
import ProductPizzaComplement from '../../steps/offered_product/pizza_complement/ProductPizzaComplement';
import ProductComplement from '../../steps/product/complement/ProductComplement';
import ProductSimple from '../../steps/offered_product/simple/ProductSimple';
import { usePromotion } from 'pages/promotion/hooks/usePromotion';

const useStyles = makeStyles(theme => ({
  itemDescription: {
    padding: 10,
    borderLeft: `2px solid ${theme.palette.secondary.dark}`,
    marginLeft: 13,
  },
  currentStep: {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    width: 25,
    height: 25,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'inline-flex',
    fontSize: 10,
    border: `2px solid ${theme.palette.primary.dark}`,
  },
  stepOrder: {
    color: '#fff',
    backgroundColor: theme.palette.secondary.main,
    width: 25,
    height: 25,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'inline-flex',
    fontSize: 10,
    border: `2px solid ${theme.palette.secondary.dark}`,
  },
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    marginRight: 10,
    padding: 0,
  },
  step: {
    display: 'flex',
    alignItems: 'center',
  },
  links: {
    cursor: 'pointer',
  },
  actions: {
    display: 'flex',
    '&>span': {
      marginRight: 10,
      display: 'inline-flex',
    },
  },
  productContainer: {
    marginBottom: 6,
    display: 'flex',
  },
  productContent: {
    marginLeft: 12,
  },
}));

PromotionResumeOfferedProducts.propTypes = {
  promotion: PropTypes.object.isRequired,
  currentStep: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired,
};

export default function PromotionResumeOfferedProducts({ promotion, currentStep, steps }) {
  const classes = useStyles();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { dispatch } = usePromotion();
  const step = steps.find(step => step.id === 'STEP_6');

  function handleEditProduct(product) {
    dispatch(promotionEditOfferedProduct(product));
  }

  function handleDeleteProduct(productId) {
    dispatch(promotionDeleteOfferedProduct(productId));
  }

  return (
    <>
      {selectedProduct && (
        <>
          {selectedProduct.category.is_pizza ? (
            <ProductPizzaComplement
              onExited={() => setSelectedProduct(null)}
              selectedProduct={selectedProduct}
              handleConfirmProduct={handleEditProduct}
            />
          ) : selectedProduct.category.has_complement ? (
            <ProductComplement
              onExited={() => setSelectedProduct(null)}
              selectedProduct={selectedProduct}
              handleConfirmProduct={handleEditProduct}
            />
          ) : (
            <ProductSimple
              onExited={() => setSelectedProduct(null)}
              selectedProduct={selectedProduct}
              handleConfirmProduct={handleEditProduct}
            />
          )}
        </>
      )}
      <div className={classes.step}>
        <button className={classes.button}>
          <span className={currentStep.id === 'STEP_6' ? classes.currentStep : classes.stepOrder}>{step.order}</span>
        </button>
        <Typography color={'primary'} variant={'body1'}>
          Produtos oferecidos
        </Typography>
      </div>
      <div className={classes.itemDescription}>
        {promotion.offered_products.length > 0 ? (
          <>
            {promotion.offered_products.map(product => (
              <div key={product.id} className={classes.productContainer}>
                <Typography>{product.amount}x</Typography>
                <div className={classes.productContent}>
                  <Typography>{product.name}</Typography>
                  <div className={classes.actions}>
                    <Typography
                      variant="body2"
                      className={classes.links}
                      component="span"
                      onClick={() => setSelectedProduct(product)}
                    >
                      Editar
                    </Typography>
                    <Typography
                      variant="body2"
                      className={classes.links}
                      component="span"
                      onClick={() => handleDeleteProduct(product.uid)}
                    >
                      Excluir
                    </Typography>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          currentStep.id === 'STEP_5' && (
            <Typography variant="body2" component="span">
              aguardando...
            </Typography>
          )
        )}
      </div>
    </>
  );
}
