import React from 'react';
import { IconButton, InputAdornment, makeStyles, MenuItem, TextField } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import DisplayModeButtons from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import KeyboardDatePicker from 'components/pickers/KeyboardDatePicker';
import { ReceiptsQueryParams } from './Receipts';

const useStyles = makeStyles(theme => ({
  filter: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '200px 1fr',
    columnGap: 10,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      flex: 1,
    },
    '& > .search-params': {
      display: 'grid',
      gridTemplateColumns: '200px 200px 200px',
      columnGap: 20,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  actions: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

type ReceiptsFilterBoxProps = {
  displayMode: 'list' | 'module';
  setDisplayMode(mode: 'list' | 'module'): void;
  params: ReceiptsQueryParams;
  handleParamsChange(index: keyof ReceiptsQueryParams, value: any): void;
  handleSearch(value: string): void;
  term: string;
};

const ReceiptsFilterBox: React.FC<ReceiptsFilterBoxProps> = ({
  setDisplayMode,
  displayMode,
  params,
  handleParamsChange,
  handleSearch,
  term,
}) => {
  const classes = useStyles();

  return (
    <FilterBox>
      <div className={classes.filter}>
        <TextField
          label="Cliente"
          placeholder="Nome ou documento"
          value={term}
          onChange={e => handleSearch(e.target.value)}
          autoFocus
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: term && (
              <InputAdornment position="start">
                <IconButton size="small" onClick={() => handleSearch('')}>
                  <Close />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div className="search-params">
          <TextField
            select
            label="Status"
            fullWidth
            autoFocus
            value={params.status}
            onChange={e => handleParamsChange('status', e.target.value)}
          >
            <MenuItem value="all">todos</MenuItem>
            <MenuItem value="created">pendente</MenuItem>
            <MenuItem value="authorized">autorizado</MenuItem>
            <MenuItem value="cancelled">cancelado</MenuItem>
            <MenuItem value="authorization_failed">falha na autorização</MenuItem>
            <MenuItem value="cancellation_failed">falha na cancelamento</MenuItem>
          </TextField>
          <KeyboardDatePicker
            label="Data inicial"
            value={params.initial_date}
            onChange={date => handleParamsChange('initial_date', date)}
            autoOk
            margin="none"
          />
          <KeyboardDatePicker
            label="Data Final"
            value={params.final_date}
            onChange={date => handleParamsChange('final_date', date)}
            autoOk
            margin="none"
          />
        </div>
      </div>
      <div className={classes.actions}>
        <DisplayModeButtons
          displayMode={displayMode}
          handleShowList={() => setDisplayMode('list')}
          handleShowModule={() => setDisplayMode('module')}
        />
      </div>
    </FilterBox>
  );
};

export default ReceiptsFilterBox;
