import React from 'react';
import CustomNavLink from 'components/sidebar/CustomNavLink';
import { makeStyles } from '@material-ui/core/styles';
import { ListItemIcon, ListItemText, ListItem, ListItemProps, Typography } from '@material-ui/core';
import { useApp } from 'App';

const useStyles = makeStyles(theme => ({
  listItemIcon: {
    color: theme.palette.secondary.contrastText,
  },
  listItemText: {
    color: '#fff',
    fontSize: 14,
  },
  nested: (props: { nested: boolean }) => ({
    paddingLeft: props.nested ? theme.spacing(5) : 16,
    borderLeft: '2px solid transparent',
    minHeight: 50,
  }),
  restaurantName: {
    color: '#888',
    fontSize: 12,
  },
}));

interface SidebarItemProps extends ListItemProps {
  to?: string;
  text: string;
  secondaryText?: string;
  nested?: boolean;
  icon: React.ReactElement;
  onClick?: () => void | null;
  isLink?: boolean;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  to,
  icon,
  text,
  nested = false,
  onClick,
  isLink = true,
  className,
  secondaryText,
  ...rest
}) => {
  const classes = useStyles({ nested });
  const app = useApp();

  function handleClick() {
    if (app.isMobile || app.windowWidth <= 1280) app.handleOpenMenu();
  }

  return (
    <ListItem
      {...rest}
      component={isLink ? CustomNavLink : 'div'}
      to={isLink ? to : undefined}
      onClick={onClick || handleClick}
      button
      className={`${classes.nested} ${className}`}
    >
      <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
      <div>
        <ListItemText classes={{ primary: classes.listItemText }} primary={text} />
        <Typography className={classes.restaurantName}>{secondaryText}</Typography>
      </div>
    </ListItem>
  );
};

export default SidebarItem;
