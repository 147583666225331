import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { Typography, makeStyles, ListItem, IconButton } from '@material-ui/core';
import { User } from 'types/user';
import { useUsers } from 'pages/users/hooks/useUsers';
import { Image, MoreVert } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    backgroundColor: '#fff',
    border: '1px solid #eee',
    position: 'relative',
    alignItems: 'center',
    minHeight: 106,
    gap: 10,
  },
  userImage: {
    width: 70,
  },
  userImageContainer: {
    position: 'relative',
    width: 70,
    height: 70,
    borderRadius: '50%',
    overflow: 'hidden',
    border: `2px solid #fff`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#eee',
    flexShrink: 0,
  },
  userData: {
    display: 'flex',
    flexDirection: 'column',
  },
  iconButton: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  blockIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  emailText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: 200,
    whiteSpace: 'nowrap',
  },
}));

const rules = {
  'admin-admin': 'administrador',
  'admin-operator': 'operador',
  'admin-waiter': 'garçom',
};

interface UserItemModuleProps {
  user: User;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
}

const UserItemModule: React.FC<UserItemModuleProps> = ({ user, setAnchorEl }) => {
  const classes = useStyles();
  const { setSelectedUser } = useUsers();

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    setSelectedUser(user);
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  return (
    <ListItem key={user.id} className={classes.listItem} style={!user.active ? { opacity: 0.7 } : undefined}>
      <div className={classes.userImageContainer}>
        {user.image ? (
          <img src={user.image.imageUrl} alt={user.name} className={classes.userImage} />
        ) : (
          <Image color="secondary" />
        )}
      </div>
      <div className={classes.userData}>
        <Typography>{user.name}</Typography>
        <Typography variant="caption" color="textSecondary" className={classes.emailText}>
          {user.email}
        </Typography>
        <Typography variant="caption" color="textSecondary" gutterBottom>
          {rules[user.rule]}
        </Typography>
        {!user.active && <Typography variant="caption">bloqueado</Typography>}
      </div>

      <IconButton size="small" onClick={e => handleMoreClick(e)} className={classes.iconButton}>
        <MoreVert />
      </IconButton>
    </ListItem>
  );
};

export default UserItemModule;
