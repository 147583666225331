import { useEffect } from 'react';
import { Order } from 'types/order';

export function useOrdersPageTitle(orders: Order[]) {
  useEffect(() => {
    const amount = orders.reduce((sum, order) => (order.status === 'o' ? sum + 1 : sum), 0);

    if (amount > 0) {
      document.title = `(${amount}) SGrande Delivery`;
    }

    return () => {
      document.title = 'SGrande Delivery';
    };
  }, [orders]);
}
