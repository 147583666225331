import React, { useState } from 'react';
import { Dialog, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import { useApp } from 'App';

const useStyles = makeStyles(theme => ({
  modal: {
    overflowY: 'auto',
    padding: '0 30px 40px',
    [theme.breakpoints.down('md')]: {
      padding: '0 30px 40px !important',
    },
  },
  root: {
    paddingRight: '0!important',
    position: 'relative',
  },
  appbar: {
    position: 'absolute',
    zIndex: '1102!important',
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
    },
    '@media print': {
      display: 'none',
    },
  },
  grow: {
    flexGrow: 1,
  },
  paper: props => ({
    backgroundColor: props.backgroundColor ? props.backgroundColor : '#fff',
    [theme.breakpoints.up('md')]: {
      height: props.height,
    },
  }),
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingTop: 15,
    paddingLeft: 15,
    paddingRight: 15,
  },
  form: ({ title, displayBottomActions }) => ({
    overflowY: 'auto',
    marginTop: title ? 64 : 15,
    marginBottom: displayBottomActions ? 72 : 0,
    [theme.breakpoints.down('md')]: {
      marginTop: title ? 56 : 15,
    },
    [theme.breakpoints.between('xs', 'xs') + ' and (orientation: landscape)']: {
      marginTop: title ? 48 : 15,
    },
  }),
}));

CustomDialogForm.propTypes = {
  handleModalState: PropTypes.func.isRequired,
  title: PropTypes.string,
  componentActions: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  backgroundColor: PropTypes.string,
  handleSubmit: PropTypes.func,
  closeOnSubmit: PropTypes.bool,
  async: PropTypes.bool,
  hideBackdrop: PropTypes.bool,
  displayBottomActions: PropTypes.bool,
  maxWidth: PropTypes.string,
  height: PropTypes.string,
};

CustomDialogForm.defaultProps = {
  hideBackdrop: false,
  async: false,
  displayBottomActions: false,
  maxWidth: 'md',
  height: '100vh',
};

export const CustomDialogFormContext = React.createContext({
  handleCloseDialog: () => {},
});

export default function CustomDialogForm({
  handleModalState,
  title,
  componentActions,
  children,
  backgroundColor,
  handleSubmit,
  closeOnSubmit,
  async,
  hideBackdrop,
  displayBottomActions,
  maxWidth,
  height,
}) {
  const [open, setOpen] = useState(true);
  const app = useApp();
  const styleProps = { backgroundColor, title: !!title, isMobile: app.isMobile, displayBottomActions, height };
  const classes = useStyles(styleProps);
  function handleClose() {
    setOpen(false);
  }

  async function _handleSubmit(event) {
    event.preventDefault();

    if (async) {
      try {
        await handleSubmit();
        if (closeOnSubmit) handleClose();
      } catch (err) {
        console.log(err);
      }
    } else {
      handleSubmit();
      if (closeOnSubmit) handleClose();
    }
  }

  return (
    <Dialog
      classes={
        ({ root: classes.root },
        {
          paper: classes.paper,
        })
      }
      open={open}
      onClose={handleClose}
      fullWidth
      fullScreen={app.isMobile || app.windowWidth < 960}
      maxWidth={maxWidth}
      hideBackdrop={hideBackdrop}
      TransitionProps={{
        onExited: () => handleModalState(),
      }}
    >
      <form onSubmit={_handleSubmit} className={classes.form}>
        {title && (
          <AppBar className={classes.appbar}>
            <Toolbar>
              <IconButton color="inherit" onClick={handleClose}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="body1" color="inherit" noWrap>
                {title}
              </Typography>
              <div className={classes.grow} />
              <div>{componentActions}</div>
            </Toolbar>
          </AppBar>
        )}
        <div className={classes.content}>
          <CustomDialogFormContext.Provider value={{ handleCloseDialog: handleClose }}>
            {children}
          </CustomDialogFormContext.Provider>
        </div>
      </form>
    </Dialog>
  );
}

export const DialogConsumer = CustomDialogFormContext.Consumer;
