import { Restaurant, RestaurantAddress, RestaurantPhone } from 'types/restaurant';
import { ProfileActions } from './type';

export function setRestaurant(restaurant: Restaurant): ProfileActions {
  return {
    type: 'SET_RESTAURANT',
    restaurant,
  };
}

export function change(index: keyof Restaurant, value: any): ProfileActions {
  return {
    type: 'CHANGE',
    index,
    value,
  };
}

export function addAddress(address: RestaurantAddress): ProfileActions {
  return {
    type: 'ADD_ADDRESS',
    address,
  };
}

export function editAddress(address: RestaurantAddress): ProfileActions {
  return {
    type: 'EDIT_ADDRESS',
    address,
  };
}

export function deleteAddress(addressId: number): ProfileActions {
  return {
    type: 'DELETE_ADDRESS',
    addressId,
  };
}

export function setMainAddress(addressId: number): ProfileActions {
  return {
    type: 'SET_MAIN_ADDRESS',
    addressId,
  };
}

export function toggleActiveAddress(addressId: number): ProfileActions {
  return {
    type: 'TOGGLE_ACTIVE_ADDRESS',
    addressId,
  };
}

export function deletePhone(phoneUid: number): ProfileActions {
  return {
    type: 'DELETE_PHONE',
    phoneUid,
  };
}

export function addPhone(phone: RestaurantPhone): ProfileActions {
  return {
    type: 'ADD_PHONE',
    phone,
  };
}

export function selectImage(): ProfileActions {
  return {
    type: 'IMAGE_SELECT',
  };
}

export function deleteImage(): ProfileActions {
  return {
    type: 'IMAGE_DELETE',
  };
}

export function selectCover(): ProfileActions {
  return {
    type: 'COVER_SELECT',
  };
}

export function deleteCover(): ProfileActions {
  return {
    type: 'COVER_DELETE',
  };
}
