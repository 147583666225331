import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useProfile } from '../hook/useProfile';
import ProfileAddressList from './ProfileAddressList';

const useStyles = makeStyles({
  container: {
    marginTop: 15,
  },
  noAddress: {
    height: 200,
    padding: 20,
  },
  button: {
    marginTop: 20,
    display: 'flex',
  },
});

const ProfileAddresses: React.FC = () => {
  const classes = useStyles();
  const { handleOpenDialogNewAddress, profile } = useProfile();

  return (
    <Grid container className={classes.container}>
      {profile?.addresses && profile?.addresses.length > 0 ? (
        <ProfileAddressList />
      ) : (
        <Grid container direction="column" className={classes.noAddress}>
          <Typography variant="h6">Nenhum endereço foi cadastro.</Typography>
          <div className={classes.button}>
            <Button color="primary" onClick={handleOpenDialogNewAddress} variant="contained">
              Incluir endereço
            </Button>
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default ProfileAddresses;
