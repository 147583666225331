import React from 'react';
import { Fade, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import PropTypes from 'prop-types';

ProfileAction.propTypes = {
  tab: PropTypes.number.isRequired,
  handleOpenDialogNewAddress: PropTypes.func.isRequired,
  handleDialogPhone: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default function ProfileAction({ tab, handleOpenDialogNewAddress, handleDialogPhone, handleSubmit, saving }) {
  return (
    <div>
      {tab === 1 && (
        <Tooltip title="Adicionar endereço">
          <Fade in>
            <IconButton onClick={handleOpenDialogNewAddress} color="inherit">
              <AddIcon />
            </IconButton>
          </Fade>
        </Tooltip>
      )}
      {tab === 2 && (
        <Tooltip title="Adicionar telefone">
          <Fade in>
            <IconButton onClick={handleDialogPhone} color="inherit">
              <AddIcon />
            </IconButton>
          </Fade>
        </Tooltip>
      )}

      <Tooltip title="Salvar">
        <span>
          <IconButton onClick={handleSubmit} disabled={saving} color="inherit">
            <DoneIcon />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
}
