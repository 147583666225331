import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { moneyFormat } from 'helpers/NumberFormat';
import { useCallback, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { api } from 'services/api';
import { CashRegister } from 'types/cashRegister';
import { Paginated } from 'types/paginated';

type UseFetchCashRegister = [boolean, CashRegister[], Dispatch<SetStateAction<CashRegister[]>>];

export function useFetchCashRegisters(page: number, rows: number): UseFetchCashRegister {
  const [cashRegisters, setCashRegisters] = useState<CashRegister[]>([]);
  const [loading, setLoading] = useState(true);

  const fetcher = useCallback(() => {
    const request = true;

    api
      .get<Paginated<CashRegister[]>>('/cashRegisters', { params: { page, rows } })
      .then(response => {
        if (request)
          setCashRegisters(
            response.data.data.map(cashRegister => {
              cashRegister.formattedCreatedAt = format(parseISO(cashRegister.created_at), 'PPp', {
                locale: ptBR,
              });
              cashRegister.formattedClosedAt =
                cashRegister.closed_at &&
                format(parseISO(cashRegister.closed_at), 'PPp', {
                  locale: ptBR,
                });
              cashRegister.dayOfTheWeek = format(parseISO(cashRegister.created_at), 'eeee', { locale: ptBR });
              cashRegister.status = cashRegister.closed_at ? 'Fechado' : 'Aberto';
              cashRegister.formattedInitialValue = moneyFormat(cashRegister.initial_value);
              return cashRegister;
            })
          );
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [page, rows]);

  useEffect(() => {
    fetcher();
  }, [fetcher]);

  return [loading, cashRegisters, setCashRegisters];
}
