import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, Typography, IconButton, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import StarIcon from '@material-ui/icons/Star';
import { CustomerAddress } from 'types/customerAddress';
import { useCustomer } from '../../hooks/useCustomer';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
    minHeight: 150,
  },
  address: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  starIcon: {
    marginLeft: 6,
    color: '#ffc107',
  },
});

interface CustomerAddressItemProps {
  address: CustomerAddress;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
}

const CustomerAddressItem: React.FC<CustomerAddressItemProps> = ({ address, setAnchorEl }) => {
  const classes = useStyles();
  const { setSelectedAddress } = useCustomer();

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>, address: CustomerAddress) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedAddress(address);
  }

  return (
    <ListItem className={classes.listItem} key={address.id}>
      <Typography variant="h6" className={classes.address}>
        {address.address}, {address.number}
        {address.is_main && (
          <Tooltip title="Endereço principal">
            <StarIcon className={classes.starIcon} />
          </Tooltip>
        )}
      </Typography>
      <Typography color="textSecondary">{address.district}</Typography>
      <Typography color="textSecondary">
        {address.city}, {address.region}
      </Typography>
      {address.postal_code !== '00000000' && <Typography color="textSecondary">{address.postal_code}</Typography>}
      <IconButton size="small" className={classes.iconButton} onClick={event => handleMoreClick(event, address)}>
        <MoreVertIcon />
      </IconButton>
    </ListItem>
  );
};

export default CustomerAddressItem;
