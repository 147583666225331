import { TextField, makeStyles } from '@material-ui/core';
import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';

const styles = makeStyles({
  content: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
});

interface CancelReceiptFormProps {
  justification: string;
  setJustification: Dispatch<SetStateAction<string>>;
  validation: string;
}

const CancelReceiptForm: React.FC<CancelReceiptFormProps> = ({ justification, setJustification, validation }) => {
  const classes = styles();
  const input = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!validation) {
      return;
    }

    input.current?.focus();
  }, [validation]);

  return (
    <div className={classes.content}>
      <TextField
        inputRef={input}
        label="Justificativa"
        placeholder="Justificativa do cancelamento do cupom"
        margin="none"
        value={justification}
        onChange={event => setJustification(event.target.value)}
        fullWidth
        autoFocus
        helperText={validation}
        error={!!validation}
      />
    </div>
  );
};

export default CancelReceiptForm;
