import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { BoardMovement } from 'types/boardMovement';

export type CustomersContextValue = {
  movements: BoardMovement[];
  selectedMovement: null | BoardMovement;
  setSelectedMovement: Dispatch<SetStateAction<BoardMovement | null>>;
};

const BoardMovementsContext = createContext<CustomersContextValue>({} as CustomersContextValue);
export const BoardMovementsProvider = BoardMovementsContext.Provider;
export const BoardMovementConstumer = BoardMovementsContext.Consumer;

export function useBoardMovements(): CustomersContextValue {
  const context = useContext(BoardMovementsContext);
  return context;
}
