import React, { ReactNode } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import { useTable } from './hook/useTable';

type StyleProps = {
  width?: number;
  minHeight?: number;
  heightToDiscount: number;
};

const useStyles = makeStyles<Theme, StyleProps>({
  bodyContainer: props => ({
    // overflowX: 'hidden',
    width: props.width ? `${props.width}px` : '100%',
    maxHeight: `calc(100vh - ${props.heightToDiscount}px)`,
  }),
  body: props => ({
    width: props.width ? `${props.width}px` : '100%',
  }),
});

type TableBodyProps = {
  width?: number;
  heightToDiscount?: number;
  minHeight?: number;
  children: ReactNode;
};

const TableBody: React.FC<TableBodyProps> = ({ children, heightToDiscount = 374 }) => {
  const { width } = useTable();
  const classes = useStyles({ width, heightToDiscount });

  return (
    <div className={classes.bodyContainer}>
      <div className={classes.body}>{children}</div>
    </div>
  );
};

export default TableBody;
