import React, { Dispatch, SetStateAction } from 'react';
import { ListItem, makeStyles, Typography, IconButton, Button, alpha } from '@material-ui/core';
import { Invoice } from 'types/invoice';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { useBilling } from '../hooks/useBilling';

const useStyles = makeStyles(theme => ({
  listItem: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '15px',
    '&.not-paid': {
      backgroundColor: alpha(theme.palette.primary.light, 0.1),
    },
  },
  invoice: {
    display: 'grid',
    gridTemplateColumns: '1fr 0.5fr 1fr',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
  },
  invoiceStatusPaid: {
    color: theme.palette.success.main,
    fontWeight: 400,
  },
  invoiceActions: {
    display: 'none',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 0,
    top: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  buttonBoleto: {
    textAlign: 'right',
    padding: 0,
  },
  buttons: {
    display: 'flex',
    gap: 20,
    margin: '10px 0',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

type BillingInvoiceItemProps = {
  invoice: Invoice;
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const BillingInvoiceItem: React.FC<BillingInvoiceItemProps> = ({ invoice, setAnchorEl }) => {
  const classes = useStyles();
  const { handleGenerateBoleto, handleGeneratePix, setSelectedInvoice } = useBilling();

  function handlePixClick() {
    setSelectedInvoice(invoice);
    handleGeneratePix(invoice.id);
  }

  function handleBilletClick() {
    setSelectedInvoice(invoice);
    handleGenerateBoleto(invoice.id);
  }

  function handleMoreClick(target: HTMLButtonElement) {
    setAnchorEl(target);
    setSelectedInvoice(invoice);
  }

  return (
    <ListItem className={`${classes.listItem} ${invoice.paid_at ? undefined : 'not-paid'}`} key={invoice.id}>
      <div className={classes.invoice}>
        <Typography variant="body1">{invoice.formattedPayday}</Typography>
        <Typography variant="body1" align="center">
          {invoice.formattedValue}
        </Typography>
        {invoice.paid_at ? (
          <Typography align="right" variant="body2" className={classes.invoiceStatusPaid}>
            Pago
          </Typography>
        ) : (
          <div className={classes.invoiceActions}>
            <IconButton size="small" onClick={e => handleMoreClick(e.currentTarget)}>
              <MoreHoriz />
            </IconButton>
          </div>
        )}
      </div>

      <Typography variant="caption" color="textSecondary">
        {invoice.description}
      </Typography>

      {!invoice.paid_at && (
        <div className={classes.buttons}>
          <Button onClick={handleBilletClick} variant="outlined" color="primary">
            pagar com Boleto
          </Button>
          <Button onClick={handlePixClick} variant="outlined" color="primary">
            pagar com pix
          </Button>
        </div>
      )}
    </ListItem>
  );
};

export default BillingInvoiceItem;
