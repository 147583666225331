import React from 'react';
import { Typography } from '@material-ui/core';
import CustomerStatus from './CustomerStatus';
import { makeStyles } from '@material-ui/core/styles';
import ShipmentStatus from './ShipmentStatus';
import PaymentStatus from './PaymentStatus';
import PropTypes from 'prop-types';
import ProductsStatus from 'pages/board-management/registration/registration/status/ProductsStatus';

const useStyles = makeStyles({
  content: {
    marginBottom: 7,
    paddingBottom: 10,
    borderBottom: '1px solid #eee',
    '&:last-child': {
      margin: '10px 0',
      border: 'none',
    },
  },
  totals: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 6,
    '& p': {
      lineHeight: '15px',
    },
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  container: {
    padding: '5px 15px',
  },
  title: {
    marginBottom: 6,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  },
  total: {
    fontWeight: 400,
  },
  colValue: {
    justifyContent: 'flex-end',
  },
  rowTotal: {
    marginTop: 10,
  },
});

OrderStatus.propTypes = {
  order: PropTypes.object.isRequired,
  handleSetStep: PropTypes.func.isRequired,
};

function OrderStatus({ order, handleSetStep }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h6">Dados do Pedido</Typography>
      </div>
      {order.customer && (
        <div className={classes.content}>
          <CustomerStatus customer={order.customer} handleSetStep={handleSetStep} />
        </div>
      )}
      {order.products.length > 0 && (
        <div className={classes.content}>
          <ProductsStatus products={order.products} />
        </div>
      )}
      {order.shipment && (
        <>
          {(order.shipment.id || order.shipment.shipment_method === 'customer_collect') && (
            <div className={classes.content}>
              <ShipmentStatus
                productsAmount={order.products.length}
                shipment={order.shipment}
                handleSetStep={handleSetStep}
              />
            </div>
          )}
        </>
      )}
      {order.paymentMethod && (
        <div className={classes.content}>
          <PaymentStatus productsAmount={order.products.length} order={order} handleSetStep={handleSetStep} />
        </div>
      )}
      {order.products.length > 0 && (
        <div className={classes.content}>
          <div className={classes.totals}>
            <div>
              <Typography variant="body1">Subtotal</Typography>
            </div>
            <div className={classes.colValue}>
              <Typography>{order.formattedSubtotal}</Typography>
            </div>
            <div>
              <Typography variant="body1">Desconto {order.coupon && `(${order.coupon.name})`}</Typography>
            </div>
            <div className={classes.colValue}>
              <Typography>{order.formattedDiscount}</Typography>
            </div>
            <div>
              <Typography variant="body1">Taxa de entrega</Typography>
            </div>
            <div className={classes.colValue}>
              <Typography>{order.formattedTax}</Typography>
            </div>
            <div className={classes.rowTotal}>
              <Typography variant="body1">Total à pagar</Typography>
            </div>
            <div className={`${classes.colValue} ${classes.rowTotal}`}>
              <Typography className={classes.total} variant="h5">
                {order.formattedTotal}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OrderStatus;
