import { TableTemplate } from 'types/tableTemplate';

export const boardProductsTableTemplate: TableTemplate[] = [
  {
    id: 'actions',
    description: 'AÇÕES',
    originalId: 'actions',
    width: 50,
  },
  {
    id: 'board_number',
    description: 'MESA',
    originalId: 'board_number',
    width: 50,
  },
  {
    id: 'order_sequence',
    description: 'PEDIDO',
    originalId: 'order_sequence',
    width: 70,
  },
  {
    id: 'name',
    description: 'PRODUTO',
    originalId: 'name',
    width: 300,
  },
  {
    id: 'formattedAmount',
    description: 'QTD',
    originalId: 'amount',
    width: 50,
  },
  {
    id: 'formattedFinalPrice',
    description: 'TOTAL',
    originalId: 'final_price',
    width: 80,
  },
  {
    id: 'formattedStatus',
    description: 'STATUS',
    originalId: 'status',
    width: 145,
  },
  {
    id: 'formattedIsPaid',
    description: 'PAGO',
    originalId: 'formattedIsPaid',
    width: 80,
  },
  {
    id: 'formattedIsSplitted',
    description: 'DIVIDO',
    originalId: 'formattedIsSplitted',
    width: 80,
  },
  {
    id: 'admin_user_name',
    description: 'USUÁRIO',
    originalId: 'admin_user_name',
    width: 150,
  },
  {
    id: 'formattedCreatedAt',
    description: 'HORA',
    originalId: 'created_at',
    width: 150,
  },
];
