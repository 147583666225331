import React, { useContext } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { DialogConfirmContext } from 'components/dialog/DialogConfirm';

const WORD_CONFIRMATION = 'EXCLUIR';

const CategoryDeleteDialog = props => {
  const { handleDelete } = props;
  const dialogConfirmContext = useContext(DialogConfirmContext);

  const handleChange = event => {
    if (event.target.value === WORD_CONFIRMATION) {
      handleDelete();
      dialogConfirmContext.handleClose();
    }
  };

  return (
    <div>
      <Typography variant={'body1'} gutterBottom>
        Deseja realmente excluir esta categoria? Se sim, escreva no campo abaixo a palavra EXCLUIR.
      </Typography>
      <TextField
        autoFocus
        label={'Confirmação'}
        placeholder={'Confirmação'}
        helperText={'Escreva EXCLUIR para confirmar'}
        margin={'normal'}
        onChange={handleChange}
        fullWidth
      />
    </div>
  );
};

export default CategoryDeleteDialog;
