import * as yup from 'yup';

export const nfceSchema = yup.object().shape({
  csc: yup.string().when('active', { is: true, then: yup.string().required('csc é obrigatório') }),
  cscid: yup.string().when('active', {
    is: true,
    then: yup.string().required('cscid é obrigatório'),
  }),
  certificate_password: yup.string().when('active', {
    is: true,
    then: yup.string().required('senha do certificado é obrigatório'),
  }),
  schema_version: yup.string().when('active', {
    is: true,
    then: yup.string().required('versão do schema é obrigatório'),
  }),
  schema_path: yup.string().when('active', {
    is: true,
    then: yup.string().required('diretório do schema é obrigatório'),
  }),
  paper_width: yup.string().when('active', {
    is: true,
    then: yup.string().required('o tamanho do papel é obrigatório'),
  }),
  final_space: yup.string().when('active', {
    is: true,
    then: yup.string().required('o tamanho do espaço final é obrigatório'),
  }),
  printer_name: yup.string().when('active', {
    is: true,
    then: yup.string().required('o nome da impressora é obrigatório'),
  }),
  escpos_type: yup.mixed().when('printer_name', {
    is: 'ESCPOS',
    then: yup.string().required('o tipo da impressora ESCPOS é obrigatório'),
  }),
  escpos_port: yup.mixed().when('printer_name', {
    is: 'ESCPOS',
    then: yup.string().required('a porta da impressora ESCPOS é obrigatório'),
  }),
  escpos_columns: yup.mixed().when('printer_name', {
    is: 'ESCPOS',
    then: yup.string().required('a quantidade de colunas da impressora ESCPOS é obrigatório'),
  }),
  escpos_line_height: yup.mixed().when('printer_name', {
    is: 'ESCPOS',
    then: yup
      .number()
      .typeError('o espaçamento entre linhas da impressora ESCPOS é obrigatório')
      .required('o espaçamento entre linhas da impressora ESCPOS é obrigatório'),
  }),
  escpos_lines: yup.mixed().when('printer_name', {
    is: 'ESCPOS',
    then: yup
      .number()
      .typeError('a quantidade de linhas da impressora ESCPOS é obrigatório')
      .required('a quantidade de linhas da impressora ESCPOS é obrigatório'),
  }),
});
