import React from 'react';
import { FormControlLabel, Checkbox, makeStyles, Typography } from '@material-ui/core';
import TimerPicker from 'components/pickers/TimerPicker';
import { availabilityHoursChange, categoryChange } from 'store/modules/category/actions';
import { CategoryAvailabilityHours, CategoryAvailability as CategoryAvailabilityType } from 'types/category';
import { useCategory } from './hooks/useCategory';

const styles = makeStyles(theme => ({
  container: {
    borderBottom: '1px solid #eee',
    paddingBottom: 30,
    '& > .subtitle': {
      marginBottom: 30,
    },
    '&:last-child': {
      border: 0,
    },
  },
  content: {
    paddingLeft: 15,
    gap: 15,
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingLeft: 0,
    },
  },
  main: {
    display: 'grid',
    gap: 20,
  },
}));

const days = {
  0: 'domingo',
  1: 'segunda-feira',
  2: 'terça-feira',
  3: 'quarta-feira',
  4: 'quinta-feira',
  5: 'sexta-feira',
  6: 'sábado',
};

type CategoryAvailabilityProps = {
  availability: CategoryAvailabilityType[];
  handleAvailabilityChange(day: number, status: boolean): void;
};

const CategoryAvailability: React.FC<CategoryAvailabilityProps> = ({ availability, handleAvailabilityChange }) => {
  const classes = styles();
  const { dispatch, category } = useCategory();

  function checkBoxClick(day: number, status: boolean) {
    handleAvailabilityChange(day, status);
  }

  function handleChange(index: keyof CategoryAvailabilityHours, value) {
    dispatch(availabilityHoursChange(index, value));
  }

  function handleEnvironmentAvailability(index: 'is_available_on_delivery' | 'is_available_on_board', value: boolean) {
    dispatch(categoryChange(index, value));
  }

  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <Typography className="subtitle" color="textSecondary">
          Dias da semana
        </Typography>
        <div className={classes.content}>
          {availability.map(item => (
            <div key={item.day}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={item.status}
                    onChange={event => checkBoxClick(item.day, event.target.checked)}
                  />
                }
                label={days[item.day]}
              />
            </div>
          ))}
        </div>
      </div>

      <div className={classes.container}>
        <Typography className="subtitle" color="textSecondary">
          Horário
        </Typography>
        <div className={classes.content}>
          <TimerPicker
            label="Hora inicial"
            value={category.availability_hours.start_at as Date | null}
            onChange={date => handleChange('start_at', date)}
            fullWidth={false}
            margin="dense"
          />

          <TimerPicker
            label="Hora final"
            value={category.availability_hours.end_at as Date | null}
            onChange={date => handleChange('end_at', date)}
            fullWidth={false}
            margin="dense"
          />
        </div>
      </div>

      <div className={classes.container}>
        <Typography className="subtitle" gutterBottom color="textSecondary">
          Ambientes
        </Typography>
        <div className={classes.content}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={category.is_available_on_board}
                onChange={event => handleEnvironmentAvailability('is_available_on_board', event.target.checked)}
              />
            }
            label="Disponível em mesas"
          />

          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={category.is_available_on_delivery}
                onChange={event => handleEnvironmentAvailability('is_available_on_delivery', event.target.checked)}
              />
            }
            label="Disponível para delivery"
          />
        </div>
      </div>
    </div>
  );
};

export default CategoryAvailability;
