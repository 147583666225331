import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import DialogInput, { DialogInputConsumer } from 'components/dialog/DialogInput';
import { Button, TextField, Typography, makeStyles } from '@material-ui/core';
import { useSelector } from 'store/redux/selector';
import { useOrders } from '../hook/useOrders';
import * as yup from 'yup';
import { api } from 'services/api';
import { useDispatch } from 'react-redux';
import { updateSetting } from 'store/redux/modules/restaurant/actions';
import { useMessaging } from 'hooks/messaging';

type OrdersDeliveryTimeProps = {
  onExited(): void;
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 350,
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    width: '100%',
    gap: 10,
    justifyContent: 'space-evenly',
  },
  sugestionContent: {
    backgroundColor: theme.palette.warning.light,
    padding: 10,
    textAlign: 'center',
  },
  inputContent: {
    width: '80%',
    display: 'grid',
  },
}));

const OrdersDeliveryTime: React.FC<OrdersDeliveryTimeProps> = ({ onExited }) => {
  const classes = useStyles();
  const restaurant = useSelector(state => state.restaurant);
  const [deliveryTime, setDeliveryTime] = useState(0);
  const [boardTime, setBoardTime] = useState(0);
  const { orders } = useOrders();
  const [boardTimeValidation, setBoardTimeValidation] = useState('');
  const [deliveryTimeValidation, setDeliveryTimeValidation] = useState('');
  const dispatch = useDispatch();
  const messaging = useMessaging();
  const [sugestion, setSugestion] = useState(0);
  const boardTimeRef = useRef<HTMLInputElement>();
  const deliveryTimeRef = useRef<HTMLInputElement>();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!restaurant) {
      return;
    }

    setDeliveryTime(restaurant.configs.delivery_time);
    setBoardTime(restaurant.configs.board_time);
  }, [restaurant]);

  const amountCompletedOrders = useMemo(() => {
    return orders.reduce((carry, order) => (order.status === 'c' ? carry + 1 : carry), 0);
  }, [orders]);

  const totalMinutes = useMemo(() => {
    /*
    const minutes = orders.reduce((carry, order) => {
      if (order.status !== 'c') return carry;

      const finalStatus = order.order_status.find(status => status.status === 'c');
      let initialStatus = order.order_status.find(status => status.status === 'o');
      if (!initialStatus) initialStatus = order.order_status.find(status => status.status === 'a');
      if (!finalStatus || !initialStatus) return carry;

      const finalDate = parseISO(finalStatus.created_at);
      const initialDate = parseISO(initialStatus.created_at);
      const minutes = differenceInMinutes(finalDate, initialDate);
      return carry + minutes;
    }, 0);
    return minutes;
    */

    return 0;
  }, []);

  useEffect(() => {
    const average = Math.trunc(totalMinutes / amountCompletedOrders);
    setSugestion(average);
  }, [amountCompletedOrders, totalMinutes]);

  useEffect(() => {
    if (deliveryTime) {
      setDeliveryTimeValidation('');
    }
  }, [deliveryTime]);

  useEffect(() => {
    if (boardTime) {
      setBoardTimeValidation('');
    }
  }, [boardTime]);

  function handleDeliveryTimeValidation(handleCloseDialog: () => void, e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    const schema = yup
      .number()
      .typeError('Informe um número válido')
      .moreThan(0, 'Informe um número maior que zero')
      .required('O tempo de entrega é obrigatório');
    schema
      .validate(deliveryTime)
      .then(() => {
        handleBoardTimeValidation(handleCloseDialog);
      })
      .catch((err: yup.ValidationError) => {
        setDeliveryTimeValidation(err.message);
        deliveryTimeRef.current?.focus();
      });
  }

  function handleBoardTimeValidation(handleCloseDialog: () => void) {
    const schema = yup
      .number()
      .typeError('Informe um número válido')
      .moreThan(0, 'Informe um número maior que zero')
      .required('O tempo de entrega é obrigatório');
    schema
      .validate(boardTime)
      .then(() => {
        handleSubmit(handleCloseDialog);
      })
      .catch((err: yup.ValidationError) => {
        setBoardTimeValidation(err.message);
        boardTimeRef.current?.focus();
      });
  }

  function handleSubmit(handleCloseDialog: () => void) {
    if (!restaurant) {
      return;
    }

    setSaving(true);

    const deliveryTimeRequest = api.patch('/settings/delivery_time', { value: deliveryTime });
    const boardTimeRequest = api.patch('/settings/board_time', { value: boardTime });

    Promise.all([deliveryTimeRequest, boardTimeRequest])
      .then(() => {
        dispatch(updateSetting('delivery_time', deliveryTime));
        dispatch(updateSetting('board_time', boardTime));
        handleCloseDialog();
      })
      .catch(err => () => messaging.handleOpen(err.response ? err.response.data.error : 'Não foi possível salvar'))
      .finally(() => setSaving(false));
  }

  return (
    <DialogInput title="Alterar tempo de entrega" onExited={onExited} maxWidth="sm">
      <DialogInputConsumer>
        {({ handleCloseDialog }) => (
          <form onSubmit={e => handleDeliveryTimeValidation(handleCloseDialog, e)} className={classes.container}>
            <Typography variant="h6">Alterar tempo de entrega</Typography>

            <div className={classes.inputContent}>
              {!!sugestion && (
                <div className={classes.sugestionContent}>
                  <Typography variant="subtitle2">Sugestão de {sugestion} minutos para o tempo de entrega</Typography>
                </div>
              )}

              <TextField
                inputRef={deliveryTimeRef}
                error={!!deliveryTimeValidation}
                autoFocus
                label="Tempo de entrega delivery (minutos)"
                placeholder="Digite o novo tempo de entrega"
                fullWidth
                margin="normal"
                value={deliveryTime || ''}
                type="number"
                inputMode="decimal"
                onChange={e => setDeliveryTime(parseInt(e.target.value))}
                inputProps={{ step: 1 }}
                helperText={deliveryTimeValidation || ''}
              />

              <TextField
                inputRef={boardTimeRef}
                error={!!boardTimeValidation}
                autoFocus
                label="Tempo de entrega mesa (minutos)"
                placeholder="Digite o novo tempo de entrega"
                fullWidth
                margin="normal"
                value={boardTime || ''}
                type="number"
                inputMode="decimal"
                onChange={e => setBoardTime(parseInt(e.target.value))}
                inputProps={{ step: 1 }}
                helperText={boardTimeValidation || ''}
              />
            </div>

            <div className={classes.actions}>
              <Button variant="text" color="primary" onClick={handleCloseDialog}>
                Não
              </Button>
              <Button
                disabled={saving}
                variant="contained"
                color="primary"
                onClick={() => handleBoardTimeValidation(handleCloseDialog)}
              >
                Sim, alterar!
              </Button>
            </div>
          </form>
        )}
      </DialogInputConsumer>
    </DialogInput>
  );
};

export default OrdersDeliveryTime;
