import Modal from 'components/modal/Modal';
import React, { useState } from 'react';
import { ReceiptHistory } from '../types/receipt';
import ReceiptHistoryList from './list/ReceiptHistoryList';
import ReceiptHistoriesAppbarButton from './ReceiptHistoriesAppbarButtons';
import { ReceiptHistoriesProvider } from './hooks/useReceiptHistories';

interface ReceiptHistoriesDialogProps {
  onExited(): void;
  histories: ReceiptHistory[];
}

const ReceiptHistoriesDialog: React.FC<ReceiptHistoriesDialogProps> = ({ onExited, histories }) => {
  const [viewMore, setViewMore] = useState(false);

  return (
    <ReceiptHistoriesProvider value={{ viewMore, setViewMore }}>
      <Modal
        height="80vh"
        title="histórico"
        maxWidth="sm"
        onExited={onExited}
        componentActions={<ReceiptHistoriesAppbarButton />}
      >
        <div>
          <ReceiptHistoryList histories={histories} />
        </div>
      </Modal>
    </ReceiptHistoriesProvider>
  );
};

export default ReceiptHistoriesDialog;
