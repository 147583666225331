import reducer from './modules/reducers';
import { orderMiddleware } from './modules/order/middleware';

import { configureStore } from '@reduxjs/toolkit';

const store = configureStore({
  reducer,
  middleware: [orderMiddleware],
});

export { store };
