import React, { MouseEvent } from 'react';
import { Typography, makeStyles, ListItem, IconButton } from '@material-ui/core';
import history from 'services/history';
import { Category } from 'types/category';
import { MoreVert } from '@material-ui/icons';
import { useCategories } from 'pages/categories/hooks/useCategories';

type CategoryItemModuleProps = {
  category: Category;
  setAnchorEl(el: HTMLButtonElement | null): void;
};

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
    minHeight: 100,
    position: 'relative',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
    width: 60,
    height: 60,
    borderRadius: '50%',
    backgroundColor: '#eee',
    overflow: 'hidden',
    border: `2px solid #fff`,
    flexShrink: 0,
  },
  image: {
    width: '100%',
  },
  buttonMore: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
});

const CategoryItemModule: React.FC<CategoryItemModuleProps> = ({ category, setAnchorEl }) => {
  const classes = useStyles();
  const { setSelectedCategory } = useCategories();

  function handleClick(categoryId) {
    history.push(`/menu/categories/${categoryId}`);
  }

  function handleMoreClick(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setSelectedCategory(category);
    setAnchorEl(e.currentTarget);
  }

  return (
    <ListItem
      style={!category.activated ? { opacity: 0.5 } : undefined}
      onClick={() => handleClick(category.id)}
      key={category.id}
      className={classes.listItem}
      button
    >
      <div className={classes.content}>
        <div className={classes.imageContainer}>
          {category.image && (
            <img
              className={classes.image}
              src={category.image.imageThumbUrl ? category.image.imageThumbUrl : category.image.imageUrl}
              alt={category.name}
            />
          )}
        </div>
        <div>
          <Typography variant="h6">{category.name}</Typography>
        </div>
      </div>
      <IconButton className={classes.buttonMore} onClick={handleMoreClick}>
        <MoreVert />
      </IconButton>
    </ListItem>
  );
};

export default CategoryItemModule;
