import React from 'react';
import { FormControlLabel, Grid, RadioGroup, Radio } from '@material-ui/core';
import { usePaymentMethods } from '../hooks/usePaymentMethods';
import PageHeader from 'components/page-header/PageHeader';

const PaymentMethodPix: React.FC = () => {
  const { gatewaySettings, handleConfigsChange } = usePaymentMethods();

  return (
    <Grid item xs={12} xl={4} lg={4} md={6}>
      <PageHeader
        title="Configurações para o Pix"
        description="Os pagamentos com PIX podem ser intermediados pelo Mercado Pago ou Pagar-me.
        Escolha uma opção."
      />

      <RadioGroup
        name="pixGateway"
        value={gatewaySettings.pix_gateway}
        onChange={e => handleConfigsChange('pix_gateway', e.target.value)}
      >
        <FormControlLabel value="mercadopago" control={<Radio />} label="Mercado Pago" />
        <FormControlLabel value="pagarme" control={<Radio />} label="Pagar-me" />
      </RadioGroup>
    </Grid>
  );
};

export default PaymentMethodPix;
