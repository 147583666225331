import { Menu, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import { toggleActiveAddress } from 'store/modules/restaurant/actions';
import { useProfile } from '../hook/useProfile';
import ProfileAddressItem from './ProfileAddressItem';

const ProfileAddressList: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const { selectedAddress, profile, dispatch, handleSetMainAddress } = useProfile();

  function handlePauseClick() {
    if (!selectedAddress) return;

    setAnchorEl(null);
    dispatch(toggleActiveAddress(selectedAddress.id));
  }

  function handleClickIsMainClick() {
    if (!selectedAddress) return;

    setAnchorEl(null);
    handleSetMainAddress(selectedAddress.id);
  }

  return (
    <>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handlePauseClick}>{selectedAddress?.active ? 'Pausar' : 'Ativar'} venda</MenuItem>
        {!selectedAddress?.is_main && <MenuItem onClick={handleClickIsMainClick}>Marcar como principal</MenuItem>}
      </Menu>
      {profile.addresses.map(address => (
        <ProfileAddressItem key={address.id} address={address} setAnchorEl={setAnchorEl} />
      ))}
    </>
  );
};

export default ProfileAddressList;
