import React, { useState } from 'react';
import { Grid, TextField, IconButton, Menu, MenuItem, FormControlLabel, Switch, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles, alpha } from '@material-ui/core/styles';
import ProductComplementAdditional from './additional/ProductComplementAdditional';
import ProductComplementIngredients from './ingredient/ProductComplementIngredients';
import ProductComplementItemImage from './ProductComplementItemImage';
import PropTypes from 'prop-types';
import {
  deleteComplement,
  changeComplement,
  changeComplementPrice,
  changeComplementStatus,
} from 'store/modules/product/actions';
import { useMessaging } from 'hooks/messaging';
import { api } from 'services/api';
import PlayIcon from '@material-ui/icons/PlayCircleFilled';
import PauseIcon from '@material-ui/icons/PauseCircleFilled';
import Loading from 'components/loading/Loading';
import { useProduct } from 'pages/products/registration/hooks/useProduct';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    borderRight: `2px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    borderBottom: `2px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    marginBottom: 10,
    padding: 10,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: 15,
      flexDirection: 'column',
    },
  },
  cancelIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 100,
  },
  moreVertIcon: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 100,
  },
  actions: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  playPauseButton: {
    padding: 0,
    margin: '5px 15px 5px 0',
  },
}));

ProductComplementItem.propTypes = {
  complements: PropTypes.array.isRequired,
  category: PropTypes.object.isRequired,
  categoryId: PropTypes.number.isRequired,
};

function ProductComplementItem({ complements, category, categoryId }) {
  const classes = useStyles();
  const { dispatch } = useProduct();
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogIngredients, setDialogIngredients] = useState(false);
  const [dialogAdditional, setDialogAdditional] = useState(false);
  const [complementSelected, setComplementSelected] = useState(null);
  const messaging = useMessaging();
  const [saving, setSaving] = useState(false);

  function handleClickMore(event, complement) {
    setAnchorEl(event.currentTarget);
    setComplementSelected(complement);
  }

  function handleClickDelete() {
    handleDeleteComplement(categoryId, complementSelected.id);
    setComplementSelected(null);
    setAnchorEl(null);
  }

  function handleDeleteComplement(complementCategoryId, complementId) {
    dispatch(deleteComplement(complementCategoryId, complementId, true));
  }

  function handleChangeComplement(index, value, complementCategoryId, complementId) {
    dispatch(changeComplement(index, value, complementCategoryId, complementId));
  }

  function handleChangeComplementPrice(complementId, priceId, value) {
    dispatch(changeComplementPrice(category.id, complementId, priceId, value));
  }

  function handleComplementStatus(complementId) {
    setSaving(true);
    api
      .put(`/products/complements/status/${complementId}`)
      .then(response => {
        messaging.handleOpen(`Complemento ${response.is_ativated ? 'ativado' : 'desativado'}`);
        dispatch(changeComplementStatus(categoryId, complementId));
      })
      .catch(() => {
        messaging.handleOpen('Aconteceu um erro ao atualizar o complemento');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <>
      {saving && <Loading />}

      {complementSelected && (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          {category.is_pizza_taste && (
            <MenuItem
              onClick={() => {
                setDialogAdditional(true);
                setAnchorEl(null);
              }}
            >
              Adicionais
            </MenuItem>
          )}
          {category.is_pizza_taste && (
            <MenuItem
              onClick={() => {
                setDialogIngredients(true);
                setAnchorEl(null);
              }}
            >
              Ingredientes
            </MenuItem>
          )}

          <MenuItem onClick={handleClickDelete}>Excluir</MenuItem>
        </Menu>
      )}
      {dialogAdditional && (
        <ProductComplementAdditional
          complement={complementSelected}
          category={category}
          onExit={() => setDialogAdditional(false)}
        />
      )}
      {dialogIngredients && (
        <ProductComplementIngredients
          complement={complementSelected}
          category={category}
          onExit={() => setDialogIngredients(false)}
        />
      )}
      {complements.map(complement => (
        <div key={complement.id} className={classes.container}>
          {category.is_pizza_taste && <ProductComplementItemImage complement={complement} category={category} />}
          <Grid container spacing={1}>
            <IconButton
              size="small"
              onClick={event => handleClickMore(event, complement, complement.id)}
              className={classes.moreVertIcon}
            >
              <MoreVertIcon />
            </IconButton>
            <Grid item xl={4} lg={4} md={3} xs={12}>
              <TextField
                autoFocus
                label="Nome"
                placeholder="Digite o nome do complemento"
                fullWidth
                value={complement.name}
                onChange={event => handleChangeComplement('name', event.target.value, categoryId, complement.id)}
              />
            </Grid>
            <Grid
              item
              xl={category.is_pizza_size ? 5 : 7}
              lg={category.is_pizza_size ? 5 : 7}
              md={category.is_pizza_size ? 6 : 8}
              xs={12}
            >
              <TextField
                label="Descrição"
                placeholder="Digite a descrição do complemento"
                fullWidth
                value={complement.description ? complement.description : ''}
                onChange={event => handleChangeComplement('description', event.target.value, categoryId, complement.id)}
              />
            </Grid>
            {category.is_pizza_size ? (
              <Grid item xl={2} lg={2} md={2} xs={12}>
                <TextField
                  type="number"
                  label="Quantidade de sabores"
                  placeholder="Digite a quantidade de sabores permitida"
                  fullWidth
                  value={complement.taste_amount}
                  inputProps={{
                    step: 1,
                    min: 1,
                  }}
                  onChange={event =>
                    handleChangeComplement('taste_amount', event.target.value, category.id, complement.id)
                  }
                />
              </Grid>
            ) : (
              <Grid item container spacing={1}>
                {complement.prices.map(price => (
                  <Grid key={price.id} item xl={2} lg={3} md={4} sm={6} xs={10}>
                    <TextField
                      type="number"
                      label={`${price.product_complement_size_name || price.name} (R$)`}
                      placeholder={`${price.product_complement_size_name || price.name} (R$)`}
                      inputProps={{
                        step: 1,
                        min: 1,
                      }}
                      value={price.price ? price.price : ''}
                      onChange={event => handleChangeComplementPrice(complement.id, price.id, event.target.value)}
                      fullWidth
                    />
                  </Grid>
                ))}
              </Grid>
            )}
            <div className={classes.actions}>
              {!complement.new && (
                <Tooltip title={complement.is_activated ? 'Pausar vendas' : 'Ativar vendas'}>
                  <IconButton onClick={() => handleComplementStatus(complement.id)} className={classes.playPauseButton}>
                    {complement.is_activated ? <PauseIcon color="primary" /> : <PlayIcon />}
                  </IconButton>
                </Tooltip>
              )}
              {!category.is_pizza_size && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={event =>
                          handleChangeComplement('selected', event.target.checked, category.id, complement.id)
                        }
                        checked={complement.selected}
                      />
                    }
                    label="Selecionado"
                  />
                </Grid>
              )}
            </div>
          </Grid>
        </div>
      ))}
    </>
  );
}

export default ProductComplementItem;
