import React, { useMemo, useState, useEffect, MouseEvent } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import OrderAction from './OrderAction';
import {
  addProducts,
  removeProduct,
  setPaymentMethod,
  setAnnotation,
  updateComplement,
  updateProduct,
  setChange,
  setConfigs,
  setOrderInitialState,
} from 'store/redux/modules/order/actions';
import PageHeader from 'components/page-header/PageHeader';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Loading from 'components/loading/Loading';
import { api } from 'services/api';
import history from 'services/history';
import { useDispatch } from 'react-redux';
import { useMessaging } from 'hooks/messaging';
import { steps } from 'pages/orders/steps';
import { useSelector } from 'store/redux/selector';
import Customer from '../steps/customer/Customer';
import Category from '../steps/category/Category';
import Shipment from '../steps/shipment/Shipment';
import Payment from '../steps/payment/Payment';
import OrderStatus from '../status/OrderStatus';
import OrderButtons from '../OrderButtons';
import OrderResume from '../resume/OrderResume';
import OrderConfirmation from '../steps/confirmation/OrderConfirmation';
import { Category as CategoryType } from 'types/category';
import { OrderProduct } from 'types/order';
import { PaymentMethod } from 'types/paymentMethod';
import Products from '../steps/products/Products';
import SearchProducts from 'pages/board-management/registration/registration/steps/products/SearchProducts';
import {
  OrderContextProvider,
  OrderContextValue,
} from 'pages/board-management/registration/registration/hooks/useOrder';
import { getOrderDataToSubmit } from './getOrderDataToSubmit';
import { useFetchPaymentMethods } from 'pages/orders/hook/useFetchPaymentMethods';
import { useFetchCategories } from 'pages/orders/hook/useFetchCategories';
import { useErrorHandler } from 'providers/error-handler/error-handler';

const useStyles = makeStyles(theme => ({
  step: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    width: 25,
    height: 25,
    borderRadius: '50%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    marginRight: 10,
    border: `2px solid ${theme.palette.primary.dark}`,
  },
  currentStep: {
    marginTop: 10,
    display: 'flex',
    alignItems: 'center',
  },
  steps: {
    padding: '10px 0',
    display: 'flex',
    alignItems: 'center',
  },
  contentStep: {
    display: 'flex',
    padding: '15px 0',
    borderRadius: 4,
    flexDirection: 'column',
    marginTop: 10,
    width: 'calc(100% - 400px)',
    flex: 1,
    marginBottom: 50,
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 350px)',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 0px',
      border: 'none',
      marginTop: 0,
      width: '100%',
    },
  },
  orderStatus: {
    [theme.breakpoints.down('md')]: {
      width: 350,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    position: 'fixed',
    right: 0,
    top: 0,
    bottom: 0,
    width: 400,
    backgroundColor: '#fff',
    padding: 15,
    marginTop: 60,
    borderLeft: '1px solid #eee',
    overflow: 'scroll',
  },
  line: {
    border: `1px solid #ccc`,
    display: 'block',
  },
  lineContainer: {
    display: 'inline-block',
    width: 40,
  },
}));

const Order: React.FC = () => {
  const classes = useStyles();
  const order = useSelector(state => state.order);
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [dialogResumeOrder, setDialogResumeOrder] = useState(false);
  const [categories, , loadingCategories] = useFetchCategories();
  const [paymentMethods] = useFetchPaymentMethods();
  const { handleOpen } = useMessaging();
  const restaurant = useSelector(state => state.restaurant);
  const [step, setStep] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState<CategoryType | null>(null);
  const [showSearchDialog, setShowSearchDialog] = useState(false);
  const { showErrorDialog } = useErrorHandler();

  const orderContextValue: OrderContextValue = {
    handleNext,
    handlePrior,
    handleAddProduct,
    handleUpdateProduct,
    handleRemoveProduct,
    handleUpdateComplements,
    handleSetChange,
    handleAddCategory,
    handleSetAnnotation,
    handleSetPaymentMethod,
    setStep,
    setShowSearchDialog,
    handleSubmit,
    loadingCategories,
    categories,
    paymentMethods,
    step,
    selectedCategory,
    saving,
  };

  useEffect(() => {
    return () => {
      dispatch(setOrderInitialState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (restaurant) {
      dispatch(setConfigs(restaurant.configs));
    }
  }, [restaurant, dispatch]);

  useEffect(() => {
    dispatch(setPaymentMethod(paymentMethods[0]));
  }, [paymentMethods, dispatch]);

  function handleNext(event?: MouseEvent<HTMLButtonElement>) {
    if (step === 3) {
      if (!checkIfOrderHasProducts()) {
        handleOpen('Você precisa adicionar produtos ao pedidos');
        return;
      }
    }

    if (step === 2) {
      if (event?.type === 'click' && order.products.length > 0) {
        setStep(4);
        return;
      } else if (!event && categories.length > 0) {
        setStep(4);
      }
    }

    setStep(step + 1);
  }

  function handlePrior() {
    if (step === 1) {
      history.back();
      return;
    }

    if (step === 4) {
      setStep(2);
      return;
    }

    if (step === 3) {
      setSelectedCategory(null);
    }

    setStep(step - 1);
  }

  function handleSetStep(step) {
    if (step === 2) setSelectedCategory(null);
    setStep(step);
  }

  function handleAddCategory(category: CategoryType) {
    setSelectedCategory(category);

    if (category) {
      handleNext();
    }
  }

  function handleAddProduct(product: OrderProduct, amount = 1) {
    dispatch(addProducts(product, amount));
  }

  function checkIfOrderHasProducts() {
    let hasProducts = false;
    hasProducts = order.products.length > 0;

    return hasProducts;
  }

  function handleRemoveProduct(uid: string) {
    dispatch(removeProduct(uid));
    handleOpen('Excluido', () => {
      alert('Excluido');
    });
  }

  function handleSetAnnotation(annotation: string) {
    dispatch(setAnnotation(annotation));
  }

  function handleSetPaymentMethod(paymentMethod: PaymentMethod) {
    dispatch(setPaymentMethod(paymentMethod));
  }

  function handleUpdateComplements(product: OrderProduct, amount: number) {
    dispatch(updateComplement(product, amount));
  }

  function handleUpdateProduct(product: OrderProduct, amount: number) {
    dispatch(updateProduct(product, amount));
  }

  function handleSetChange(value: number) {
    dispatch(setChange(value));
  }

  function handleSubmit() {
    if (!order.shipment) {
      handleOpen('Você precisa informar os dados da entrega');
      handleSetStep(4);
      return;
    }

    const data = getOrderDataToSubmit(order);

    setSaving(true);
    api
      .post('/orders', data)
      .then(response => {
        if (response.status === 200) {
          handleOpen('Salvo');
        }

        history.push('/delivery-orders');
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível salvar o pedido',
        });
        setSaving(false);
      });
  }

  const currentStep = useMemo(() => {
    return steps.find(item => item.order === step);
  }, [step]);

  return (
    <OrderContextProvider value={orderContextValue}>
      {saving && <Loading />}

      <CustomAppbar
        title="Novo Pedido"
        ActionComponents={<OrderAction setDialogResumeOrder={() => setDialogResumeOrder(!dialogResumeOrder)} />}
      />

      <PageHeader title="Novo pedido" backAction={handlePrior} />

      <div className={classes.currentStep}>
        <span className={classes.step}>{currentStep?.order}</span>
        <Typography>{currentStep?.description}</Typography>
      </div>

      {showSearchDialog && <SearchProducts onExited={() => setShowSearchDialog(false)} />}

      <div className={classes.contentStep}>
        {dialogResumeOrder && <OrderResume onExited={() => setDialogResumeOrder(!dialogResumeOrder)} />}
        {step === 1 ? (
          <Customer />
        ) : step === 2 ? (
          <Category />
        ) : step === 3 ? (
          <Products />
        ) : step === 4 ? (
          <Shipment />
        ) : step === 5 ? (
          <Payment />
        ) : (
          step === 6 && <OrderConfirmation handleSubmit={handleSubmit} saving={saving} />
        )}

        <OrderButtons />
      </div>

      <div className={classes.orderStatus}>
        <OrderStatus order={order} handleSetStep={handleSetStep} />
      </div>
    </OrderContextProvider>
  );
};

export default Order;
