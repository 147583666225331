import React, { MouseEvent } from 'react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { useTable } from 'components/table/hook/useTable';
import { MoreHoriz } from '@material-ui/icons';
import { useTaxRules } from 'pages/tax-rules/hooks/useTaxRules';
import { TaxRule } from 'pages/tax-rules/types/taxRule';

const useStyles = makeStyles(theme => ({
  iconButton: {
    justifySelf: 'baseline',
    padding: 5,
  },
}));

type TaxRuleItemTableProps = {
  taxRule: TaxRule;
  setAnchorEl(el: HTMLButtonElement): void;
};

const TaxRuleItemTable: React.FC<TaxRuleItemTableProps> = ({ taxRule, setAnchorEl }) => {
  const { tableTemplate } = useTable();
  const classes = useStyles();
  const { setSelectedTaxRule } = useTaxRules();

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setSelectedTaxRule(taxRule);
    setAnchorEl(event.currentTarget);
  }

  return (
    <>
      {tableTemplate
        .filter(item => !item.notShow)
        .map(item =>
          item.id === 'actions' ? (
            <IconButton onClick={handleMoreClick} key={item.id} className={classes.iconButton}>
              <MoreHoriz />
            </IconButton>
          ) : (
            <div key={item.id}>
              <Typography variant="body2">{taxRule[item.id]}</Typography>
            </div>
          )
        )}
    </>
  );
};

export default TaxRuleItemTable;
