import React, { useRef, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MyDateTimePicker from 'components/pickers/DateTimePicker';
import ImageUpload from 'components/image-upload/ImageUpload';
import { PushNotification } from 'types/pushNotification';
import { PushNotificationValidation } from './validation/pushNotificationValidation';

const useStyles = makeStyles(theme => ({
  warning: {
    marginTop: 30,
  },
  form: {
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  imageContainer: {
    marginTop: 40,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    marginBottom: 100,
  },
  imageContent: {
    width: '100%',
    maxWidth: 400,
  },
}));

interface PushNotificationFormProps {
  handleChange(index: string, value: any): void;
  pushNotification: PushNotification;
  validation: PushNotificationValidation;
}

const PushNotificationForm: React.FC<PushNotificationFormProps> = ({ handleChange, pushNotification, validation }) => {
  const classes = useStyles();

  const inputs = {
    title: useRef<HTMLInputElement>(null),
    message: useRef<HTMLInputElement>(null),
    url_action: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <div className={classes.container}>
      <div className={classes.imageContent}>
        <ImageUpload
          image={pushNotification.image}
          handleRemoveImage={() => handleChange('image', null)}
          handleSetImage={image => handleChange('image', image)}
          label="Imagem da notificação"
          className={classes.imageContainer}
          disabled={pushNotification.delivered}
        />
      </div>
      <div className={classes.form}>
        <TextField
          label="Título"
          placeholder="Informe o título da mensagem"
          margin="normal"
          fullWidth
          value={pushNotification.title}
          onChange={e => handleChange('title', e.target.value)}
          helperText={validation.title && validation.title}
          error={!!validation.title}
          ref={inputs.title}
          autoFocus
          disabled={pushNotification.delivered}
        />
        <TextField
          multiline
          minRows={5}
          label="Mensagem"
          placeholder="Informe a mensagem"
          margin="normal"
          fullWidth
          value={pushNotification.message}
          onChange={e => handleChange('message', e.target.value)}
          helperText={validation.message && validation.message}
          error={!!validation.message}
          ref={inputs.message}
          disabled={pushNotification.delivered}
        />
        <TextField
          label="URL de ação"
          placeholder="Informe a url de ação"
          margin="normal"
          fullWidth
          value={pushNotification.url_action}
          onChange={e => handleChange('url_action', e.target.value)}
          helperText={
            validation.url_action
              ? validation.url_action
              : 'Quando o cliente clicar ou tocar na mensagem será redirecionado para o endereço aqui informado'
          }
          error={!!validation.url_action}
          ref={inputs.url_action}
          disabled={pushNotification.delivered}
        />
        <MyDateTimePicker
          autoOk
          label="Data e hora do envio"
          placeholder="Data do envio"
          value={pushNotification.delivery_date}
          handleDateChange={date => handleChange('delivery_date', date)}
          disabled={pushNotification.delivered}
        />

        <button type="submit" style={{ display: 'none' }} />
      </div>
    </div>
  );
};

export default PushNotificationForm;
