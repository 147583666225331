import React, { useRef, useEffect } from 'react';
import { FormControlLabel, MenuItem, Switch, TextField, makeStyles } from '@material-ui/core';
import { PaymentMethodValidation } from './validation/usePaymentMethodValidation';
import { PaymentMethod } from 'types/paymentMethod';

const styles = makeStyles({
  switches: {
    marginTop: 20,
  },
});

interface FiscalSerialRuleFormProps {
  handleChange(key: keyof PaymentMethod, value: any): void;
  paymentMethod: PaymentMethod;
  validation: PaymentMethodValidation;
}

const FiscalSerialRuleForm: React.FC<FiscalSerialRuleFormProps> = ({ handleChange, paymentMethod, validation }) => {
  const classes = styles();

  const inputs = {
    method: useRef<HTMLInputElement>(null),
    kind: useRef<HTMLInputElement>(null),
    sefaz_code: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key || !inputs[key]) {
      return;
    }

    inputs[key].current?.focus();
  }, [validation]); //eslint-disable-line

  return (
    <>
      <TextField
        inputRef={inputs.method}
        error={!!validation.method}
        helperText={validation.method}
        label="Nome"
        placeholder="Nome do método de pagamento"
        fullWidth
        margin="normal"
        value={paymentMethod.method}
        onChange={e => handleChange('method', e.target.value)}
        autoFocus
      />

      <TextField
        error={!!validation.mode}
        helperText={validation.mode}
        label="Série"
        placeholder="Série"
        fullWidth
        margin="normal"
        value={paymentMethod.mode}
        onChange={e => handleChange('mode', e.target.value)}
        select
      >
        <MenuItem value="online">online</MenuItem>
        <MenuItem value="offline">offline</MenuItem>
      </TextField>

      <TextField
        inputRef={inputs.kind}
        error={!!validation.kind}
        helperText={validation.kind}
        label="Tipo"
        placeholder="Tipo"
        fullWidth
        margin="normal"
        value={paymentMethod.kind}
        onChange={e => handleChange('kind', e.target.value)}
        autoFocus
        select
      >
        <MenuItem value="pix">pix</MenuItem>
        <MenuItem value="money">dinheiro</MenuItem>
        <MenuItem value="card">cartão</MenuItem>
        <MenuItem value="debit_card">cartão de débito</MenuItem>
        <MenuItem value="credit_card">cartão de crédito</MenuItem>
        <MenuItem value="picpay">picpay</MenuItem>
        <MenuItem value="other">outro</MenuItem>
      </TextField>

      <TextField
        inputRef={inputs.sefaz_code}
        error={!!validation.sefaz_code}
        helperText={validation.sefaz_code}
        label="Código SEFAZ"
        placeholder="Código SEFAZ"
        fullWidth
        margin="normal"
        value={paymentMethod.sefaz_code}
        onChange={e => handleChange('sefaz_code', e.target.value)}
        autoFocus
      />

      <div className={classes.switches}>
        <FormControlLabel
          control={
            <Switch
              checked={paymentMethod.activated}
              onChange={e => handleChange('activated', e.target.checked)}
              color="primary"
            />
          }
          label="Ativo"
        />

        <FormControlLabel
          control={
            <Switch
              checked={paymentMethod.must_create_receipt}
              onChange={e => handleChange('must_create_receipt', e.target.checked)}
              color="primary"
            />
          }
          label="Gera cupom fiscal automaticamente"
        />
      </div>
    </>
  );
};

export default FiscalSerialRuleForm;
