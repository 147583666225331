import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import AdditionalItemTable from './AdditionalItemTable';
import TableContainer from 'components/table/TableContainer';
import TableHeader from 'components/table/TableHeader';
import TableRow from 'components/table/TableRow';
import { OrderIndexData } from 'hooks/tableOrder';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import history from 'services/history';
import TableBody from 'components/table/TableBody';
import TableContent from 'components/table/TableContent';
import { Additional } from 'types/product';
import { additionalTableTemplate } from 'pages/additional/additionalTableTemplate';

const useStyles = makeStyles({
  headerItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: 10,
      fontSize: 20,
    },
  },
});

type AdditionalListTableProps = {
  additional: Additional[];
  handleSort(index: string): void;
  orderedIndex: OrderIndexData;
};

const AdditionalListTable: React.FC<AdditionalListTableProps> = ({ additional, handleSort, orderedIndex }) => {
  const classes = useStyles();

  return (
    <>
      <TableContainer tableTemplate={additionalTableTemplate}>
        <TableContent>
          <TableHeader>
            {additionalTableTemplate
              .filter(item => !item.notShow)
              .map(item => (
                <div
                  className={classes.headerItem}
                  key={item.id}
                  onClick={!item.notFilterable ? () => handleSort(item.originalId) : undefined}
                >
                  <Typography noWrap variant="caption" color="primary">
                    {item.description}
                  </Typography>
                  {orderedIndex.index === item.originalId && (
                    <>
                      {orderedIndex.direction === 'asc' ? (
                        <ArrowUpward color="primary" />
                      ) : (
                        <ArrowDownward color="primary" />
                      )}
                    </>
                  )}
                </div>
              ))}
          </TableHeader>
          <TableBody>
            {additional.map(item => (
              <TableRow key={item.id} onClick={() => history.push(`/menu/additional/${item.id}`)}>
                <AdditionalItemTable additional={item} />
              </TableRow>
            ))}
          </TableBody>
        </TableContent>
      </TableContainer>
    </>
  );
};

export default AdditionalListTable;
