import React from 'react';
import { Typography } from '@material-ui/core';
import StatusIcon from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from '@material-ui/core/styles';
import { useApp } from 'App';

interface StyleProps {
  isConnected: boolean;
}

const useStyles = makeStyles({
  status: (props: StyleProps) => ({
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      color: props.isConnected ? '#28a745' : '#dc3545',
      marginRight: 6,
    },
    '& > span': {
      display: 'inline-flex',
      flexDirection: 'column',
    },
  }),
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(0,0,0,0.3)',
    color: '#fff',
    height: 50,
    padding: 15,
    flexShrink: 0,
    alignItems: 'center',
    position: 'relative',
    borderTop: '1px solid #333',
  },
});

const PrinterStatus: React.FC = () => {
  const { isPrinterConnected, printerVersion } = useApp();
  const classes = useStyles({ isConnected: isPrinterConnected });

  return (
    <div className={classes.content}>
      <div className={classes.status}>
        <StatusIcon />
        <span>
          <Typography variant="caption">printer {printerVersion}</Typography>
        </span>
      </div>
    </div>
  );
};

export default PrinterStatus;
