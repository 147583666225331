import { createContext, useContext, SetStateAction, Dispatch } from 'react';
import { OrderProduct } from 'types/order';

export type OrderResumeProductsValue = {
  handleUpdateProduct(product: OrderProduct, amount: number): void;
  setSelectedProduct: Dispatch<SetStateAction<OrderProduct | null>>;
  selectedProduct: OrderProduct | null;
};

const OrderResumeProductsContext = createContext<OrderResumeProductsValue>({} as OrderResumeProductsValue);
export const OrderResumeProductsProvider = OrderResumeProductsContext.Provider;
export const OrderResumeProductsConsumer = OrderResumeProductsContext.Consumer;

export function useOrderResumeProducts(): OrderResumeProductsValue {
  return useContext(OrderResumeProductsContext);
}
