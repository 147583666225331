import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';

function BoardControlBillAction() {
  return (
    <>
      <Tooltip title="Atualizar">
        <IconButton
          onClick={() => {
            window.print();
          }}
          color="inherit"
        >
          <PrintIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default BoardControlBillAction;
