import React from 'react';
import List from 'components/list/List';
import { Grid } from '@material-ui/core';
import { usePagination } from 'hooks/pagination';
import TableItemModule from './BoardItemModule';
import { Board } from 'types/board';

type BoardListModuleProps = {
  boards: Board[];
};

const BoardListModule: React.FC<BoardListModuleProps> = ({ boards }) => {
  const { rowsPerPage, page } = usePagination();

  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          {boards.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(board => (
            <TableItemModule key={board.id} board={board} />
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default BoardListModule;
