import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTable } from './hook/useTable';

const useStyles = makeStyles({
  header: ({ templateColumns, width }: { templateColumns: string; width?: number }) => ({
    display: 'grid',
    gridTemplateColumns: templateColumns,
    padding: 10,
    borderBottom: '1px solid #eee',
    flexShrink: 0,
    columnGap: 7,
    width: width ? `${width}px` : '100%',
    // position: 'absolute',
    // top: 0,
  }),
});

interface TableHeaderProps {
  children: ReactNode;
}

const TableHeader: React.FC<TableHeaderProps> = ({ children }) => {
  const { width, templateColumns } = useTable();

  const classes = useStyles({ templateColumns, width });

  return <div className={classes.header}>{children}</div>;
};

export default TableHeader;
