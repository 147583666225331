import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';

export default function DeliverersAction() {
  return (
    <Tooltip title="Adicionar cupom">
      <IconButton component={Link} to="/deliverers/new" color="inherit">
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
}
