import React, { Fragment } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';

function IngredientAction({ handleSubmit, handleDelete, saving, loading }) {
  return (
    <Fragment>
      <Tooltip title={'Salvar'}>
        <IconButton disabled={saving || loading} onClick={handleSubmit} color={'inherit'}>
          <DoneIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={'Excluir'}>
        <IconButton disabled={saving || loading} onClick={handleDelete} color={'inherit'}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Fragment>
  );
}

IngredientAction.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  loading: PropTypes.bool,
};

export default IngredientAction;
