import React, { useState } from 'react';
import CustomDialog, { CustomDialogContext } from 'components/dialog/CustomDialog';
import ComplementIngredientsImportList from './ComplementIngredientsImportList';
import PropTypes from 'prop-types';
import { importComplementIngredients } from 'store/modules/product/actions';
import { useProduct } from 'pages/products/registration/hooks/useProduct';

ComplementIngredientsImport.propTypes = {
  onExited: PropTypes.func.isRequired,
  complementId: PropTypes.func.isRequired,
};

export default function ComplementIngredientsImport({ onExited, complementId: toComplementId }) {
  const { dispatch, handleGetPizzaTasteComplements } = useProduct();
  const [complements] = useState(handleGetPizzaTasteComplements());

  function handleImportIngredients(fromComplementId, handleCloseDialog) {
    dispatch(importComplementIngredients(fromComplementId, toComplementId));
    handleCloseDialog();
  }

  return (
    <CustomDialog
      handleModalState={onExited}
      hideBackdrop
      displayBottomActions={false}
      title="Importar adicionais de complemento"
      maxWidth="sm"
      height="80vh"
    >
      <CustomDialogContext>
        {({ handleCloseDialog }) => (
          <ComplementIngredientsImportList
            handleImportIngredients={handleImportIngredients}
            complements={complements}
            handleCloseDialog={handleCloseDialog}
          />
        )}
      </CustomDialogContext>
    </CustomDialog>
  );
}
