import { makeStyles, Typography } from '@material-ui/core';
import List from 'components/list/List';
import NoData from 'components/nodata/NoData';
import React, { useState } from 'react';
import { BoardMovementPayment } from 'types/boardMovementPayment';
import { useBoardControl } from '../../hooks/useBoardControl';
import BoardControlPaymentConfirmDelete from './BoardControlPaymentConfirmDelete';
import BoardControlPaymentItem from './BoardControlPaymentItem';

const styles = makeStyles(theme => ({
  container: {
    padding: '20px 0',
    gridTemplateColumns: '1fr',
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {},
  },
  totalContent: {
    padding: '0 20px',
    textAlign: 'right',
    marginBottom: 20,
  },
}));

interface BoardControlPaymentsProps {
  handleDeletePayment(paymentId: string): Promise<void>;
}

const BoardControlPayments: React.FC<BoardControlPaymentsProps> = ({ handleDeletePayment }) => {
  const classes = styles();
  const { movement } = useBoardControl();
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState<BoardMovementPayment | null>(null);

  return movement?.payments.length ? (
    <>
      {confirmDeleteDialog && (
        <BoardControlPaymentConfirmDelete
          handleDeletePayment={handleDeletePayment}
          onExited={() => setConfirmDeleteDialog(false)}
          selectedPayment={selectedPayment}
          setSelectedPayment={setSelectedPayment}
        />
      )}

      <List className={classes.container}>
        {movement?.payments.map(payment => (
          <BoardControlPaymentItem
            setConfirmDeleteDialog={setConfirmDeleteDialog}
            setSelectedPayment={setSelectedPayment}
            payment={payment}
            key={payment.id}
          />
        ))}
      </List>

      <div className={classes.totalContent}>
        <Typography color="textSecondary">total</Typography>
        <Typography variant="h6">{movement?.formattedTotalPaid}</Typography>
      </div>
    </>
  ) : (
    <NoData message="nenhum pagamento para mostrar" />
  );
};

export default BoardControlPayments;
