import React, { Dispatch, MouseEvent, SetStateAction } from 'react';
import { Typography, makeStyles, ListItem, IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import history from 'services/history';
import { PaymentMethodForList } from 'types/paymentMethod';
import { usePaymentMethods } from 'pages/payment-methods/hooks/usePaymentMethods';

const useStyles = makeStyles({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
    minHeight: 120,
    position: 'relative',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  buttonMore: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '100px 1fr',
  },
});

interface PaymentMethodItemModuleProps {
  paymentMethod: PaymentMethodForList;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
}

const PaymentMethodItemModule: React.FC<PaymentMethodItemModuleProps> = ({ paymentMethod, setAnchorEl }) => {
  const classes = useStyles();
  const { setSelected } = usePaymentMethods();

  function handleClick() {
    setSelected(paymentMethod);
    history.push(`/payment-methods/${paymentMethod.uuid}`);
  }

  function handleMoreClick(event: MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  return (
    <ListItem onClick={handleClick} key={paymentMethod.id} className={classes.listItem} button>
      <Typography gutterBottom variant="h6">
        {paymentMethod.method}
      </Typography>

      <div className={classes.row}>
        <Typography variant="caption" color="textSecondary">
          SEFAZ
        </Typography>
        <Typography variant="body2">{`${paymentMethod.sefaz_code}`}</Typography>
      </div>

      <div className={classes.row}>
        <Typography variant="caption" color="textSecondary">
          Ativo
        </Typography>
        <Typography variant="body2">{`${paymentMethod.formattedActive}`}</Typography>
      </div>

      <div className={classes.row}>
        <Typography variant="caption" color="textSecondary">
          Modo
        </Typography>
        <Typography variant="body2">{`${paymentMethod.mode}`}</Typography>
      </div>

      <IconButton onClick={handleMoreClick} className={classes.buttonMore}>
        <MoreVert />
      </IconButton>
    </ListItem>
  );
};

export default PaymentMethodItemModule;
