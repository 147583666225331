import { TaxRule } from 'pages/tax-rules/types/taxRule';
import { Dispatch, SetStateAction, useState } from 'react';
import * as yup from 'yup';

export interface TaxRuleValidation {
  internal_cfop?: string;
  description?: string;
  external_cfop?: string;
  icms_code?: string;
  icms_aliquot?: string;
  pis_aliquot?: string;
  cofins_aliquot?: string;
  pis_cofins_code?: string;
}

type UseTaxRuleValidation = [
  TaxRuleValidation,
  Dispatch<SetStateAction<TaxRuleValidation>>,
  (taxRule: TaxRule) => Promise<void>
];

export function useTaxRuleValidation(): UseTaxRuleValidation {
  const [validation, setValidation] = useState<TaxRuleValidation>({} as TaxRuleValidation);

  async function handleValidation(taxRule: TaxRule) {
    const schema = yup.object().shape({
      cofins_aliquot: yup.string().required('A alíquota do COFINS é obrigatória'),
      pis_aliquot: yup.string().required('A alíquota do PIS é obrigatória'),
      pis_cofins_code: yup.string().required('A situação tributária do PIS/COFINS é obrigatória'),
      icms_aliquot: yup.string().required('A alíquota do ICMS é obrigatória'),
      icms_code: yup.string().required('a situação tributária do ICMS é obrigatória'),
      external_cfop: yup.string().required('A CFOP externa é obrigatória'),
      internal_cfop: yup.string().required('A CFOP interna é obrigatória'),
      description: yup.string().required('A descrição é obrigatória'),
    });

    try {
      await schema.validate(taxRule);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  return [validation, setValidation, handleValidation];
}
