export const INITIAL_STATE = {
  name: '',
  email: '',
  phone: '',
  is_main: false,
};

export default function deliverer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'DELIVERER_CHANGE': {
      return {
        ...state,
        [action.index]: action.value,
      };
    }

    case 'SET_DELIVERER': {
      return action.deliverer;
    }

    default: {
      return state;
    }
  }
}
