import { Plan } from 'types/plan';
import { PrinterSetting } from 'types/printerSettings';
import { Restaurant, RestaurantConfig, RestaurantCreditCard } from 'types/restaurant';
import { SET_RESTAURANT, RestaurantActionTypes } from './types';

export function setRestaurant(restaurant: Restaurant): RestaurantActionTypes {
  return {
    type: SET_RESTAURANT,
    restaurant,
  };
}

export function setRestaurantIsOpen(isOpen: boolean): RestaurantActionTypes {
  return {
    type: '@restaurant/SET_IS_OPEN',
    isOpen,
  };
}

export function setRestaurantKitchenIsOpen(isKitchenOpen: boolean): RestaurantActionTypes {
  return {
    type: '@restaurant/SET_IS_KITCHEN_OPEN',
    isKitchenOpen,
  };
}

export function addRestaurantCreditCard(card: RestaurantCreditCard): RestaurantActionTypes {
  return {
    type: '@restaurant/ADD_CREDIT_CARD',
    card,
  };
}

export function deleteRestaurantCreditCard(): RestaurantActionTypes {
  return {
    type: '@restaurant/DELETE_CREDIT_CARD',
  };
}

export function updateRestaurantPlan(plan: Plan): RestaurantActionTypes {
  return {
    type: '@restaurant/UPDATE_RESTAURANT_PLAN',
    plan,
  };
}

export function setPrinterSettings(settings: PrinterSetting): RestaurantActionTypes {
  return {
    type: '@restaurant/SET_PRINTER_SETTINGS',
    settings,
  };
}

export function updateSetting(key: keyof RestaurantConfig, value: any): RestaurantActionTypes {
  return {
    type: '@restaurant/UPDATE_SETTING',
    key,
    value,
  };
}
