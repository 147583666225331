import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { api } from 'services/api';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { User, UserForList } from 'types/user';
import { useUserFormatter } from './useUserFormatter';

export interface UseFetchUsers {
  users: UserForList[];
  setUsers: Dispatch<SetStateAction<UserForList[]>>;
  fetch: () => void;
  loading: boolean;
}

export function useFetchUsers(): UseFetchUsers {
  const [users, setUsers] = useState<UserForList[]>([]);
  const [loading, setLoading] = useState(true);
  const { showErrorDialog } = useErrorHandler();
  const [formatter] = useUserFormatter();

  const fetch = useCallback(() => {
    setLoading(true);

    api
      .get<User[]>(`/users`)
      .then(response => {
        setUsers(response.data.map(rule => formatter(rule)));
      })
      .catch(error => {
        showErrorDialog({
          error,
          message: 'Não foi possível carregar as regras fiscais',
        });
      })
      .finally(() => setLoading(false));
  }, [showErrorDialog, formatter]);

  return {
    loading,
    fetch,
    users,
    setUsers,
  };
}
