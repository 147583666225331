import { Theme, Typography, makeStyles } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { RECEIPT_STATUS_MAPPING } from 'pages/receipts/constants/receiptStatusMapping';
import { ReceiptHistory } from 'pages/receipts/types/receipt';
import React, { MouseEvent, useState } from 'react';
import { api } from 'services/api';
import { download } from 'helpers/download';
import { uuidv4 } from 'helpers/uuid';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { useMessaging } from 'hooks/messaging';

const styles = makeStyles<Theme>(theme => ({
  container: {
    display: 'flex',
    minHeight: 100,
  },
  badge: {
    borderRadius: '50%',
    display: 'inline-flex',
    width: 25,
    height: 25,
    position: 'relative',
    zIndex: 2,
    flexShrink: 0,
    '&::after': {
      content: '" "',
      height: 130,
      backgroundColor: '#ccc',
      display: 'block',
      position: 'absolute',
      width: 3,
      top: 25,
      left: 11,
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        height: 240,
      },
    },
    '&.authorized': {
      backgroundColor: theme.palette.success.main,
    },
    '&.cancelled': {
      backgroundColor: theme.palette.grey[700],
    },
    '&.created': {
      backgroundColor: theme.palette.warning.main,
    },
    '&.authorization_failed': {
      backgroundColor: theme.palette.error.main,
    },
    '&.cancellation_failed': {
      backgroundColor: theme.palette.error.main,
    },
  },
  content: {
    display: 'flex',
    padding: '0 10px 15px',
    flexDirection: 'column',
    minHeight: 130,
  },
  code: {
    whiteSpace: 'break-spaces',
    lineBreak: 'anywhere',
    maxHeight: 150,
    overflow: 'auto',
    margin: '5px 0 15px',
    position: 'relative',
    backgroundColor: '#f5f5f5',
    padding: 10,
  },
  actions: {
    display: 'flex',
    gap: 10,
  },
}));

interface ReceiptHistoryItemProps {
  history: ReceiptHistory;
}

const statusForActions = ['authorized', 'cancelled', 'authorization_failed', 'cancellation_failed'];

const ReceiptHistoryItem: React.FC<ReceiptHistoryItemProps> = ({ history }) => {
  const classes = styles();
  const { showErrorDialog } = useErrorHandler();
  const date = format(parseISO(history.created_at), 'PPp', { locale: ptBR });
  const [isDownloading, setIsDownloading] = useState(false);
  const { handleOpen } = useMessaging();

  function getViewLink(type: 'response' | 'request'): string {
    const baseURL = `${process.env.REACT_APP_BASEURL_API}/receipts/${history.receipt_id}/document`;

    const url = new URLSearchParams();

    url.append('type', type);
    url.append('app', 'admin');
    url.append('receipt_history_id', `${history.id}`);

    return `${baseURL}?${url.toString()}`;
  }

  function getDownloadLink(type: 'response' | 'request'): string {
    const baseURL = `/receipts/${history.receipt_id}/download`;

    const url = new URLSearchParams();

    url.append('type', type);
    url.append('receipt_history_id', `${history.id}`);

    return `${baseURL}?${url.toString()}`;
  }

  async function handleDownloadClick(event?: MouseEvent<HTMLAnchorElement>) {
    event?.preventDefault();

    if (isDownloading) {
      return;
    }

    setIsDownloading(true);

    try {
      const response = await api.get(getDownloadLink('request'), {
        responseType: 'blob',
      });

      download(response.data, `${uuidv4()}.xml`);
    } catch (error) {
      showErrorDialog({
        error,
        message: 'Não foi possível baixar os XML',
      });
    } finally {
      setIsDownloading(false);
    }
  }

  function handleCopyKey(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();

    if (!history.key) {
      return;
    }

    navigator.clipboard
      .writeText(history.key)
      .then(() => handleOpen('copiado'))
      .catch(err => console.error(err));
  }

  return (
    <li className={classes.container}>
      <span className={`${classes.badge} ${history.status}`} />
      <div className={classes.content}>
        <Typography variant="caption" color="textSecondary">
          {date}
        </Typography>

        <Typography>{RECEIPT_STATUS_MAPPING[history.status]}</Typography>

        {history.key && history.status === 'authorized' && (
          <Typography style={{ lineBreak: 'anywhere' }} gutterBottom color="textSecondary" variant="body2">
            {history.key}
          </Typography>
        )}

        {history.description && (
          <Typography gutterBottom color="textSecondary" variant="body2">
            {history.description}
          </Typography>
        )}

        {statusForActions.includes(history.status) && (
          <div className={classes.actions}>
            <a href="#" onClick={event => handleDownloadClick(event)}>
              download
            </a>

            <a href={getViewLink('response')} target="blank">
              visualizar
            </a>

            {history.status === 'authorized' && (
              <a href="#" onClick={handleCopyKey} target="blank">
                copiar chave
              </a>
            )}

            {history.status === 'authorization_failed' && (
              <a href={getViewLink('request')} target="blank">
                xml
              </a>
            )}
          </div>
        )}
      </div>
    </li>
  );
};

export default ReceiptHistoryItem;
