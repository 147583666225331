import { makeStyles } from '@material-ui/core';
import OrderResumeTotal from './OrderResumeTotal';
import Modal from 'components/modal/Modal';
import OrderResumeProducts from './products/OrderResumeProducts';
import { FC } from 'react';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
});

interface OrderResumeProps {
  onExited(): void;
}

const OrderResume: FC<OrderResumeProps> = ({ onExited }) => {
  const classes = useStyles();

  return (
    <Modal
      backgroundColor="#fafafa"
      onExited={onExited}
      title="resumo do pedido"
      displayBottomActions={false}
      maxWidth="sm"
    >
      <div className={classes.container}>
        <OrderResumeProducts />
        <OrderResumeTotal />
      </div>
    </Modal>
  );
};

export default OrderResume;
