import { createContext, useContext, Dispatch, SetStateAction } from 'react';
import { Image } from 'types/image';

interface ImageSelectorContextValue {
  selectedImage: Image | null;
  handleImageDelete(): void;
  openImagePreview(): void;
  handleConfirmImage(): void;
  handleUploadFiles(files: FileList | null): void;
  handleCopyImageUrl(): void;
  showConfirmAction: boolean;
  term: string;
  handleSearch(term: string): void;
  isSearchBoxOpened: boolean;
  setIsSearchBoxOpened: Dispatch<SetStateAction<boolean>>;
}

const ImageSelectorContext = createContext<ImageSelectorContextValue>({} as ImageSelectorContextValue);
export const ImageSelectorProvider = ImageSelectorContext.Provider;
export const ImageSelectorConsumer = ImageSelectorContext.Consumer;

export function useImageSelector() {
  return useContext(ImageSelectorContext);
}
