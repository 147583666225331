import { Button, IconButton, Menu, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import { CashRegister } from 'types/cashRegister';
import { useCashRegisterFlow } from './hooks/useCashRegisterFlow';
import { MoreVert } from '@material-ui/icons';

interface CashRegisterHeaderButtonsProps {
  cashRegister: CashRegister | null;
  loading: boolean;
}

const CashRegisterHeaderButtons: React.FC<CashRegisterHeaderButtonsProps> = ({ cashRegister, loading }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { setIsOpenedDebitDialog, setIsOpenedCloseCashRegisterDialog, setIsOpenedExtractReport, setIsOpenedReport } =
    useCashRegisterFlow();

  function handleCloseCashRegister() {
    if (cashRegister?.closed_at) {
      return;
    }

    setIsOpenedCloseCashRegisterDialog(true);
  }

  function handleExtractPrintClick() {
    setIsOpenedExtractReport(true);
    setAnchorEl(null);
  }

  function handlePrintReportClick() {
    setIsOpenedReport(true);
    setAnchorEl(null);
  }

  return (
    <>
      <Menu onClose={() => setAnchorEl(null)} open={!!anchorEl} anchorEl={anchorEl}>
        <MenuItem onClick={handlePrintReportClick}>Imprimir fechamento</MenuItem>
        <MenuItem disabled={loading} onClick={handleExtractPrintClick}>
          Imprimir extrato
        </MenuItem>
      </Menu>

      <Button
        disabled={!!cashRegister?.closed_at || loading}
        onClick={handleCloseCashRegister}
        variant="contained"
        color="primary"
        size="small"
      >
        encerrar
      </Button>

      <Button
        disabled={!!cashRegister?.closed_at || loading}
        onClick={() => setIsOpenedDebitDialog(true)}
        size="small"
        variant="contained"
        color="primary"
      >
        lançamento
      </Button>

      <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
        <MoreVert />
      </IconButton>
    </>
  );
};

export default CashRegisterHeaderButtons;
