import { Button, makeStyles, Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import React from 'react';

const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    flex: 0.7,
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      fontSize: 40,
      color: theme.palette.error.main,
    },
  },
}));

interface ErrorProps {
  text: string;
  handleReset?(): void;
  handleResetText?: string;
}

const ErrorMessage: React.FC<ErrorProps> = ({ handleResetText, handleReset, text }) => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <ErrorOutline />

      <Typography variant="h6">{text}</Typography>

      {handleReset && (
        <Button onClick={handleReset} variant="contained" color="primary">
          {handleResetText ?? 'Tentar novamente'}
        </Button>
      )}
    </div>
  );
};

export default ErrorMessage;
