import React, { useReducer, useMemo, useState, useEffect } from 'react';
import CustomAppbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import promotionReducer, { INITIAL_STATE as promotionInitialState } from 'store/modules/promotion/reducer';
import { promotionChange } from 'store/modules/promotion/actions';
import { steps as _steps } from '../../steps';
import PromotionActions from './PromotionActions';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import * as yup from 'yup';
import PromotionForm from '../steps/form/PromotionForm';
import { subDays } from 'date-fns';
import PromotionCategory from '../steps/category/PromotionCategory';
import PromotionProducts from '../steps/product/PromotionProducts';
import PromotionOfferedProducts from '../steps/offered_product/PromotionOfferedProducts';
import PromotionDiscount from '../steps/discount/PromotionDiscount';
import PromotionConfirm from '../steps/confirm/PromotionConfirm';
import PromotionAvailability from '../steps/availability/PromotionAvailability';
import { api } from 'services/api';
import history from 'services/history';
import Loading from 'components/loading/Loading';
import PromotionOrderValue from '../steps/order_value/PromotionOrderValue';
import { useMessaging } from 'hooks/messaging';
import { PromotionProvider } from 'pages/promotion/hooks/usePromotion';
import PromotionResume from '../resume/PromotionResume';

const resumeWidth = 350;

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    padding: 30,
    width: `calc(100% - ${resumeWidth}px)`,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '30px 0',
    },
  },
  content: {},
  stepResume: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    position: 'fixed',
    right: 0,
    top: 0,
    bottom: 0,
    width: resumeWidth,
    backgroundColor: '#fff',
    padding: 15,
    marginTop: 64,
    borderLeft: '1px solid #eee',
    overflowY: 'scroll',
  },
  actions: ({ step }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: step > 1 ? 'space-between' : 'flex-end',
    marginTop: 20,
  }),
  step: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    width: 25,
    height: 25,
    borderRadius: '50%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10,
    border: `2px solid ${theme.palette.primary.dark}`,
  },
  stepDescription: {
    marginTop: 10,
  },
}));

export default function Promotion() {
  const [promotion, dispatch] = useReducer(promotionReducer, promotionInitialState);
  const [validation, setValidation] = useState({});
  const [step, setStep] = useState(1);
  const [steps, setSteps] = useState(_steps);
  const messaging = useMessaging();
  const [saving, setSaving] = useState(false);

  const currentStep = useMemo(() => {
    return steps.find(s => s.order === step);
  }, [steps, step]);

  const classes = useStyles({ step });

  const promotionContextValue = {
    handleNext,
    dispatch,
    promotion,
    handlePrior,
  };

  useEffect(() => {
    let newSteps = _steps.slice();
    let order = 0;

    if (promotion.rule_type === 'product') {
      newSteps = newSteps.filter(s => s.id !== 'STEP_2' && s.id !== 'STEP_4');
    }

    if (promotion.rule_type === 'category') {
      newSteps = newSteps.filter(s => s.id !== 'STEP_3' && s.id !== 'STEP_4');
    }

    if (promotion.rule_type === 'order_value') {
      newSteps = newSteps.filter(s => s.id !== 'STEP_2' && s.id !== 'STEP_3');
    }

    if (promotion.type === 'get') {
      newSteps = newSteps.filter(s => s.id !== 'STEP_5');
    }

    if (promotion.type === 'safe') {
      newSteps = newSteps.filter(s => s.id !== 'STEP_6');
    }

    setSteps(
      newSteps.map(step => {
        order++;
        step.order = order;
        return step;
      })
    );
    // eslint-disable-next-line
  }, [promotion.type, promotion.rule_type]);

  async function handleNext() {
    if (currentStep.id === 'STEP_1') {
      try {
        await handleValidation();
        setValidation({});
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (currentStep.id === 'STEP_4') {
      try {
        await handleOrderValueValidation();
        setValidation({});
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (currentStep.id === 'STEP_5') {
      try {
        await handleDiscountValidation();
        setValidation({});
      } catch (err) {
        console.log(err);
        return;
      }
    }

    setStep(oldStep => oldStep < 6 && oldStep + 1);
  }

  function handlePrior() {
    setStep(oldStep => oldStep >= 1 && oldStep - 1);
  }

  function handleChange(index, value) {
    dispatch(promotionChange(index, value));
  }

  async function handleValidation() {
    const schema = yup.object().shape({
      valid_at: yup.date().nullable().min(subDays(new Date(), 1), 'A data precisa ser maior ou igual a data de hoje'),
      description: yup.string().required('A descrição é obrigatória'),
      name: yup.string().required('O nome é obrigatório'),
    });

    return schema.validate(promotion).catch(err => {
      setValidation({
        [err.path]: err.message,
      });
      throw new Error(err.message);
    });
  }

  function handleDiscountValidation() {
    const schema = yup.object().shape({
      discount: yup.number().typeError('O valor do desconto é obrigatório'),
    });

    return schema.validate(promotion.safe).catch(err => {
      setValidation({
        [err.path]: err.message,
      });
      throw new Error(err.message);
    });
  }

  function handleOrderValueValidation() {
    const schema = yup.object().shape({
      order_value: yup.number().typeError('O valor do pedido é obrigatório'),
    });

    return schema.validate(promotion.order_value).catch(err => {
      setValidation({
        order_value: 'O valor do pedido é obrigatório',
      });
      throw new Error(err.message);
    });
  }

  function handleSubmit() {
    setSaving(true);
    api
      .post('/promotions', promotion)
      .then(() => {
        messaging.handleOpen('Salvo');
        history.push('/promotions');
      })
      .catch(err => {
        if (err.response) messaging.handleOpen(err.response.data.error);
        else messaging.handleOpen('Não foi possível salvar a promoção');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <PromotionProvider value={promotionContextValue}>
      {saving && <Loading />}
      <CustomAppbar
        title="Nova promoção"
        ActionComponents={<PromotionActions handleSubmit={handleSubmit} saving={saving} />}
      />
      <PageHeader title="Nova promoção" description="Siga os passos e crie uma promoção" />
      <div className={classes.stepDescription}>
        <Typography variant="body1">
          <span className={classes.step}>{currentStep.order}</span>
          {currentStep.description}
        </Typography>
      </div>
      <div className={classes.container}>
        <div className={classes.content}>
          {currentStep.id === 'STEP_1' ? (
            <PromotionForm validation={validation} promotion={promotion} handleChange={handleChange} />
          ) : currentStep.id === 'STEP_2' ? (
            <PromotionCategory promotionCategories={promotion.categories} />
          ) : currentStep.id === 'STEP_3' ? (
            <PromotionProducts promotionProducts={promotion.products} />
          ) : currentStep.id === 'STEP_4' ? (
            <PromotionOrderValue promotion={promotion} validation={validation} />
          ) : currentStep.id === 'STEP_5' ? (
            <PromotionDiscount promotion={promotion} validation={validation} handleChange={handleChange} />
          ) : currentStep.id === 'STEP_6' ? (
            <PromotionOfferedProducts offeredProducts={promotion.offered_products} />
          ) : currentStep.id === 'STEP_7' ? (
            <PromotionAvailability availability={promotion.availability} />
          ) : (
            <PromotionConfirm handleSubmit={handleSubmit} saving={saving} />
          )}
        </div>
        <div className={classes.actions}>
          {step > 1 && (
            <Button color="primary" variant="text" onClick={handlePrior} className={classes.button}>
              Voltar
            </Button>
          )}
          {currentStep.order >= 1 && currentStep.id !== 'STEP_8' && (
            <Button
              color="primary"
              variant="contained"
              size="large"
              type="submit"
              onClick={handleNext}
              className={classes.button}
            >
              Avançar
            </Button>
          )}
        </div>
      </div>
      <div className={classes.stepResume}>
        <PromotionResume promotion={promotion} currentStep={currentStep} steps={steps} />
      </div>
    </PromotionProvider>
  );
}
