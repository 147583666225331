import { Typography, makeStyles } from '@material-ui/core';
import { Certificate } from 'pages/fiscal-settings/types/certificate';
import React from 'react';

const styles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  content: {
    '& > p': {
      display: 'grid',
      gridTemplateColumns: '100px 1fr',
      gap: 20,
      '& > span': {
        fontWeight: 'bold',
      },
    },
  },
});

interface CertificateDataProps {
  certificate: Certificate;
}

const CertificateData: React.FC<CertificateDataProps> = ({ certificate }) => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Typography variant="body2" color="textSecondary">
          <span>Arquivo</span>
          {certificate.filename}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <span>Expira em</span>
          {certificate.expires_in}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <span>Empresa</span>
          {certificate.company_name}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <span>CNPJ</span>
          {certificate.document_number}
        </Typography>
      </div>
    </div>
  );
};

export default CertificateData;
