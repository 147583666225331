import React from 'react';
import { Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 10,
    fontSize: 30,
  },
};

const messages = {
  0: 'Não foi possível conectar ao servidor. Tente novamente.',
  500: 'Não foi possível completar a requisição, erro no servidor.',
};

function ConnectionErrorMessage({ statusCode, classes }) {
  return (
    <div className={classes.container}>
      <Typography color="secondary" variant="h5" className={classes.message}>
        <ErrorIcon className={classes.icon} color="error" />
        {messages[statusCode]}
      </Typography>
    </div>
  );
}

export default withStyles(styles)(ConnectionErrorMessage);
