import React, { Dispatch, SetStateAction } from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useBilling } from '../hooks/useBilling';

type Props = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
};

const BillingMenu: React.FC<Props> = ({ anchorEl, setAnchorEl }) => {
  const { handleGenerateBoleto, handleGeneratePix, selectedInvoice } = useBilling();

  function handleBilletClick() {
    if (!selectedInvoice) {
      return;
    }

    handleGenerateBoleto(selectedInvoice.id);
    setAnchorEl(null);
  }

  function handlePixClick() {
    if (!selectedInvoice) {
      return;
    }

    handleGeneratePix(selectedInvoice.id);
    setAnchorEl(null);
  }

  return (
    <Menu onClose={() => setAnchorEl(null)} open={!!anchorEl} anchorEl={anchorEl}>
      <MenuItem onClick={handleBilletClick}>Gerar boleto</MenuItem>
      <MenuItem onClick={handlePixClick}>Pagar com PIX</MenuItem>
    </Menu>
  );
};

export default BillingMenu;
