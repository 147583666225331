import React, { useState } from 'react';
import List from 'components/list/List';
import ReceiptItemModule from './PaymentMethodItemModule';
import ReceiptListMenu from '../PaymentMethodListMenu';
import { PaymentMethodForList } from 'types/paymentMethod';

type PaymentMethodListModuleProps = {
  paymentMethods: PaymentMethodForList[];
};

const PaymentMethodListModule: React.FC<PaymentMethodListModuleProps> = ({ paymentMethods }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <ReceiptListMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />

      <List>
        {paymentMethods.map(paymentMethod => (
          <ReceiptItemModule setAnchorEl={setAnchorEl} key={paymentMethod.id} paymentMethod={paymentMethod} />
        ))}
      </List>
    </>
  );
};

export default PaymentMethodListModule;
