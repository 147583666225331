import { IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import DisplayModeButtons, { DisplayModeOptions } from 'components/display-buttons/DisplayModeButtons';
import FilterBox from 'components/filter-box/FilterBox';
import React, { Dispatch, SetStateAction, useRef } from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  filter: {
    display: 'grid',
    gridTemplateColumns: '300px',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

interface IngredientsFilterBoxProps {
  displayMode: DisplayModeOptions;
  setDisplayMode: Dispatch<SetStateAction<DisplayModeOptions>>;
  searchValue: string;
  handleSearch(value: string): void;
}

const IngredientsFilterBox: React.FC<IngredientsFilterBoxProps> = ({
  displayMode,
  searchValue,
  handleSearch,
  setDisplayMode,
}) => {
  const classes = useStyles();
  const input = useRef<HTMLInputElement>(null);

  return (
    <FilterBox>
      <div className={classes.filter}>
        <TextField
          inputRef={input}
          label="Pesquisar"
          placeholder="Nome ou telefone do cliente"
          value={searchValue}
          onChange={e => handleSearch(e.target.value)}
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: searchValue && (
              <InputAdornment position="start">
                <IconButton size="small" onClick={() => handleSearch('')}>
                  <Close />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <DisplayModeButtons
        displayMode={displayMode}
        handleShowList={() => setDisplayMode('list')}
        handleShowModule={() => setDisplayMode('module')}
      />
    </FilterBox>
  );
};

export default IngredientsFilterBox;
