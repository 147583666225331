import React, { useState, useEffect, DragEvent, useCallback } from 'react';
import ImageSelectorList from './ImageSelectorList';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { api } from 'services/api';
import ImagePreview from 'components/image-preview/ImagePreview';
import ImageSelectorActions from './ImageSelectorActions';
import { useMessaging } from 'hooks/messaging';
import { Image } from 'types/image';
import Modal from 'components/modal/Modal';
import { Paginated } from 'types/paginated';
import ImageSelectorLoading from './ImageSelectLoading';
import { ImageSelectorProvider } from './hooks/useImageSelector';

const useStyles = makeStyles(theme => ({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(250,250,250,0.5)',
    zIndex: 1110,
  },
  draggableZone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  inDraggableZone: {
    border: `3px dashed ${theme.palette.primary.main}`,
    opacity: 0.5,
  },
  actions: {
    margin: '30px 0',
    display: 'flex',
    justifyContent: 'center',
  },
}));

let handleClose: () => void;

type ImageSelectorProps = {
  handleSetImageId?(image: Image): void;
  onExited(): void;
  title?: string;
  hideBackdrop?: boolean;
};

let timer: NodeJS.Timeout;

const ImageSelector: React.FC<ImageSelectorProps> = ({
  handleSetImageId: onSetImageId,
  onExited,
  title,
  hideBackdrop,
}) => {
  const [images, setImages] = useState<Image[]>([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const messaging = useMessaging();
  const classes = useStyles();
  const [dragIn, setDragIn] = useState(false);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [term, setTerm] = useState('');
  const [isSearchBoxOpened, setIsSearchBoxOpened] = useState(false);
  const [fetchingMore, setFetchingMore] = useState(false);

  const fetchImages = useCallback((query?: string) => {
    setLoading(true);
    setPage(1);

    api
      .get<Paginated<Image[]>>('/images', { params: { rows: 50, page: 1, term: query } })
      .then(response => {
        setImages(response.data.data);
        setLastPage(response.data.last_page);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const fetchMoreImages = useCallback((_page: number, _term?: string) => {
    setFetchingMore(true);

    api
      .get<Paginated<Image[]>>('/images', { params: { rows: 25, page: _page, term: _term } })
      .then(response => {
        setImages(state => [...state, ...response.data.data]);
        setLastPage(response.data.last_page);
      })
      .finally(() => {
        setFetchingMore(false);
      });
  }, []);

  useEffect(() => {
    fetchImages('');
  }, [fetchImages]);

  function handleSearch(value: string) {
    setTerm(value);
    clearTimeout(timer);

    if (value.length > 0 && value.length <= 2) {
      return;
    }

    timer = setTimeout(() => fetchImages(value), 500);
  }

  function handleScroll() {
    const nativeDialogElement = document.getElementById('native-dialog');

    if (!nativeDialogElement) {
      return;
    }

    const clientHeight = nativeDialogElement.clientHeight;
    const scrollTop = nativeDialogElement.scrollTop;
    const scrollHeight = nativeDialogElement.scrollHeight;

    const calc = scrollTop + clientHeight - scrollHeight;

    if (calc < -10) {
      return;
    }

    setPage(state => {
      if (fetchingMore) {
        return state;
      }

      if (state === lastPage) {
        return state;
      }

      const _page = state + 1;

      fetchMoreImages(_page, term);

      return _page;
    });
  }

  function handleSetSelectedImage(image: Image, _handleClose: () => void) {
    setSelectedImage(selectedImage?.id === image.id ? null : image);
    setIsSearchBoxOpened(false);
    handleClose = _handleClose;
  }

  function handleConfirmImage() {
    if (selectedImage && onSetImageId) onSetImageId(selectedImage);
    if (handleClose) handleClose();
  }

  function handleImageDelete() {
    if (!selectedImage) return;

    setSaving(true);
    api
      .delete(`/images/${selectedImage.id}`)
      .then(() => {
        setImages(images.filter(image => image.id !== selectedImage.id));
        setSelectedImage(null);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleUploadFiles(files: FileList | null) {
    if (!files) return;

    const form = new FormData();
    Array.from(files).forEach((file, index) => {
      form.append(`files[${index}]`, file);
    });

    setSaving(true);

    api
      .post('/images', form)
      .then(response => {
        setImages(oldImages => [...response.data, ...oldImages]);
      })
      .catch(() => {
        messaging.handleOpen('Não foi possível carregar a imagem');
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function handleDropFile(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    handleUploadFiles(files);
    setDragIn(false);
  }

  function handleDragEnter(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  function handleDragLeave(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(false);
  }

  function handleDragOver(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragIn(true);
  }

  async function handleCopyImageUrl() {
    if (!selectedImage) return;
    const text = selectedImage.imageUrl;
    await navigator.clipboard.writeText(text);
    messaging.handleOpen('Endereço da imagem copiado');
  }

  function handleCloseSearchBox() {
    setIsSearchBoxOpened(false);
    setTerm('');
  }

  function handleMoreImagesClick() {
    setPage(state => {
      if (fetchingMore) {
        return state;
      }

      if (state === lastPage) {
        return state;
      }

      const _page = state + 1;

      fetchMoreImages(_page, term);

      return _page;
    });
  }

  return (
    <ImageSelectorProvider
      value={{
        selectedImage,
        handleImageDelete,
        openImagePreview: () => setImagePreview(true),
        handleConfirmImage,
        handleUploadFiles,
        handleCopyImageUrl,
        showConfirmAction: !!onSetImageId,
        term,
        handleSearch,
        isSearchBoxOpened,
        setIsSearchBoxOpened,
      }}
    >
      <Modal
        backAction={isSearchBoxOpened ? handleCloseSearchBox : undefined}
        title={title || 'selecione'}
        onExited={onExited}
        hideBackdrop={hideBackdrop}
        onScroll={handleScroll}
        showOnlyActionsContent={isSearchBoxOpened}
        componentActions={<ImageSelectorActions />}
        fullscreen
      >
        {saving && (
          <div className={classes.loading}>
            <CircularProgress color="primary" />
          </div>
        )}

        {imagePreview && (
          <ImagePreview
            description={`Imagem ${selectedImage?.id}`}
            onExited={() => setImagePreview(false)}
            src={selectedImage ? selectedImage.imageUrl : ''}
          />
        )}

        {loading ? (
          <ImageSelectorLoading />
        ) : (
          <div
            className={dragIn ? `${classes.draggableZone} ${classes.inDraggableZone}` : classes.draggableZone}
            onDrop={e => handleDropFile(e)}
            onDragLeave={e => handleDragLeave(e)}
            onDragEnter={e => handleDragEnter(e)}
            onDragOver={e => handleDragOver(e)}
            draggable
          >
            <ImageSelectorList
              images={images}
              handleSetSelectedImage={handleSetSelectedImage}
              handleConfirmImage={handleConfirmImage}
              selectedImageId={selectedImage?.id}
            />

            {fetchingMore && (
              <div className={classes.actions}>
                <CircularProgress />
              </div>
            )}

            {page < lastPage && !fetchingMore && (
              <div className={classes.actions}>
                <Button onClick={handleMoreImagesClick} variant="text" color="primary">
                  mais imagens
                </Button>
              </div>
            )}
          </div>
        )}
      </Modal>
    </ImageSelectorProvider>
  );
};

export default ImageSelector;
