import VMasker from 'vanilla-masker';

function phoneFormat(phone) {
  let phoneFormatted = phone.replace(/\D/g, '');

  if (phoneFormatted.length === 11) phoneFormatted = VMasker.toPattern(phoneFormatted, '99 99999-9999');
  else if (phoneFormatted.length === 10) phoneFormatted = VMasker.toPattern(phoneFormatted, '99 9999-9999');

  return phoneFormatted;
}

export { phoneFormat };
