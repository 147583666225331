import React from 'react';
import { ListItem, Typography, List } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import StarIcon from '@material-ui/icons/Star';

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'block',
    backgroundColor: '#fff',
    boxShadow: '1px 1px 9px 1px #eee',
    borderRadius: 4,
  },
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridGap: 6,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  icon: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
}));

DeliverersList.propTypes = {
  deliverers: PropTypes.array.isRequired,
};

export default function DeliverersList({ deliverers }) {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      {deliverers.map(deliverer => (
        <ListItem
          key={deliverer.id}
          className={classes.listItem}
          button
          component={Link}
          to={`/deliverers/${deliverer.id}`}
        >
          <Typography variant="h6">{deliverer.name}</Typography>
          <Typography color="textSecondary" variant="body2">
            {deliverer.phone}
          </Typography>
          {deliverer.is_main && <StarIcon className={classes.icon} />}
        </ListItem>
      ))}
    </List>
  );
}
