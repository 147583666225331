import { useEffect, useState } from 'react';
import { api } from 'services/api';
import { BoardControlActions } from '../reducer/types';
import { setBoardMovement } from '../reducer/actions';
import { BoardMovement } from 'types/boardMovement';

export type UseFetchBoardMovements = [boolean, BoardMovement | null];

export function useFetchBoardMovement(
  dispatch?: (action: BoardControlActions) => void,
  movementId?: string | null
): UseFetchBoardMovements {
  const [loading, setLoading] = useState(true);
  const [movement, setMovement] = useState<BoardMovement | null>(null);

  useEffect(() => {
    if (!movementId) {
      return;
    }

    api
      .get(`/boardMovements/${movementId}`)
      .then(response => {
        if (dispatch) {
          dispatch(setBoardMovement(response.data));
        }
        setMovement(response.data);
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }, [movementId, dispatch]);

  return [loading, movement];
}
