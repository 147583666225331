import React from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { useBoardControl } from '../hooks/useBoardControl';

export type BoardControlTabValues = 'pay' | 'payments';

interface BoardControlPaymentTabsProps {
  value: BoardControlTabValues;
  handleTabChange(value: BoardControlTabValues): void;
}

const BoardControlPaymentTabs: React.FC<BoardControlPaymentTabsProps> = ({ value, handleTabChange }) => {
  const { movement } = useBoardControl();
  return (
    <Tabs value={value} onChange={(event, value) => handleTabChange(value)} variant="scrollable">
      <Tab value="pay" label="Pagar" disabled={!!movement?.isBoardPaid} />
      <Tab value="payments" label="Pagamentos" />
    </Tabs>
  );
};

export default BoardControlPaymentTabs;
