import React from 'react';
import { ListItem, Typography, makeStyles, alpha } from '@material-ui/core';
import AddressItem from './AddressItem';
import { useSelector } from 'store/redux/selector';
import { CustomerAddress } from 'types/customerAddress';
import { useOrderShipment } from './hook/useOrderShipment';
import { CheckCircle } from '@material-ui/icons';
import List from 'components/list/List';

const useStyles = makeStyles(theme => ({
  listItemNewAddress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 180,
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
    border: `2px dashed ${theme.palette.primary.main}`,
  },
  listItemCustomerCollect: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 180,
    backgroundColor: '#fff',
    border: '1px solid #f5f5f5',
    borderRadius: theme.shape.borderRadius,
    alignItems: 'flex-start',
    position: 'relative',
    justifyContent: 'center',
  },
  selected: {
    border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    '&:focus': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.25),
    },
  },
  checkIcon: {
    position: 'absolute',
    right: 10,
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface AddressListProps {
  addresses: CustomerAddress[];
}

const AddressList: React.FC<AddressListProps> = ({ addresses }) => {
  const classes = useStyles();
  const order = useSelector(state => state.order);
  const { handleSetShipmentCustomerCollect, setDialogNewAddress } = useOrderShipment();

  return (
    <div className={classes.container}>
      <List>
        {addresses.map(address => (
          <AddressItem address={address} key={address.id} />
        ))}

        <ListItem
          button
          className={
            order.shipment?.shipment_method === 'customer_collect'
              ? `${classes.listItemCustomerCollect} ${classes.selected}`
              : classes.listItemCustomerCollect
          }
          onClick={handleSetShipmentCustomerCollect}
        >
          <Typography variant="h6">Retirada</Typography>
          <Typography variant="body2" color="textSecondary">
            Cliente retira no balcão
          </Typography>

          {order.shipment?.scheduled_at && (
            <Typography color="textSecondary" variant="body2">
              Agendado para as {order.shipment?.formattedScheduledAt}
            </Typography>
          )}

          {order.shipment?.shipment_method === 'customer_collect' && (
            <CheckCircle color="primary" className={classes.checkIcon} />
          )}
        </ListItem>

        <ListItem button className={classes.listItemNewAddress} onClick={() => setDialogNewAddress(true)}>
          <Typography variant="h6">adicionar endereço</Typography>
        </ListItem>
      </List>
    </div>
  );
};

export default AddressList;
