import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useCustomers } from '../hooks/useCustomers';

type CustomerListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl(anchorEl: null | HTMLButtonElement): void;
};

const CustomerListMenu: React.FC<CustomerListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { selectedCustomer } = useCustomers();

  function handleActiveClick() {
    setAnchorEl(null);
  }

  return (
    <>
      {selectedCustomer && (
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={handleActiveClick}>Opção 1</MenuItem>
        </Menu>
      )}
    </>
  );
};

export default CustomerListMenu;
