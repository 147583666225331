import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useOrder } from 'pages/board-management/registration/registration/hooks/useOrder';
import { useSelector } from 'store/redux/selector';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { useApp } from 'App';

const styles = makeStyles(theme => ({
  action: {
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    bottom: 0,
    right: 400,
    borderTop: '1px solid #eee',
    backgroundColor: '#fff',
    padding: 15,
    zIndex: 100,
    transition: 'all 0.4s ease 0s',
    [theme.breakpoints.down('md')]: {
      right: 350,
    },
    [theme.breakpoints.down('sm')]: {
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
}));

function OrderButtons() {
  const app = useApp();
  const { handleNext, handlePrior, step } = useOrder();
  const classes = styles();
  const order = useSelector(state => state.order);

  return (
    <div
      style={{
        justifyContent: step > 1 ? 'space-between' : 'flex-end',
        left: app.isOpenedMenu && !app.isMobile ? 300 : 0,
      }}
      className={classes.action}
    >
      {step > 1 && (
        <Button startIcon={<ArrowBack />} variant="contained" color="primary" onClick={handlePrior}>
          Voltar
        </Button>
      )}

      {step === 2 && order.products.length > 0 ? (
        <Button endIcon={<ArrowForward />} variant="contained" color="primary" onClick={handleNext}>
          Próximo
        </Button>
      ) : (
        step < 6 &&
        step !== 2 && (
          <Button endIcon={<ArrowForward />} variant="contained" color="primary" onClick={handleNext}>
            Próximo
          </Button>
        )
      )}
    </div>
  );
}

export default OrderButtons;
