import { Dispatch, SetStateAction, useState } from 'react';
import { Board } from 'types/board';
import * as yup from 'yup';

export interface BoardValidation {
  number?: string;
  description?: string;
  max_value?: string;
}

type UseBoardValidation = [BoardValidation, Dispatch<SetStateAction<BoardValidation>>, (table: Board) => Promise<void>];

export function useBoardValidation(): UseBoardValidation {
  const [validation, setValidation] = useState<BoardValidation>({} as BoardValidation);

  async function handleValidation(table: Board) {
    const schema = yup.object().shape({
      max_value: yup.number().typeError('O valor máximo é obrigatório').required('O valor máximo é obrigatório'),
      description: yup.string().required('A descrição é obrigatória'),
      number: yup.string().required('O número é obrigatório'),
    });

    try {
      await schema.validate(table);
    } catch (err) {
      const error = err as yup.ValidationError;
      if (error.path && error.message) {
        setValidation({
          [error.path]: error.message,
        });
      }
      throw new Error(err as string);
    }
  }

  return [validation, setValidation, handleValidation];
}
