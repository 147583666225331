import React from 'react';
import { TextField, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { usePaymentMethods } from '../../hooks/usePaymentMethods';

const useStyles = makeStyles({
  formControl: {
    marginTop: 15,
  },
});

const PaymentMethodRede: React.FC = () => {
  const classes = useStyles();
  const { gatewaySettings, handleConfigsChange } = usePaymentMethods();

  return (
    <>
      <TextField
        label="PV"
        margin="normal"
        fullWidth
        value={gatewaySettings.rede_pv}
        onChange={e => handleConfigsChange('rede_pv', e.target.value)}
        placeholder="Informe o merchant id"
        autoFocus
      />
      <TextField
        label="Token"
        margin="normal"
        fullWidth
        value={gatewaySettings.rede_token}
        onChange={e => handleConfigsChange('rede_token', e.target.value)}
        placeholder="Informe o merchant key"
      />
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Ambiente</FormLabel>
        <RadioGroup
          name="discountType"
          value={gatewaySettings.rede_env}
          onChange={e => handleConfigsChange('rede_env', e.target.value)}
        >
          <FormControlLabel value="production" control={<Radio />} label="Produção" />
          <FormControlLabel value="development" control={<Radio />} label="Teste" />
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default PaymentMethodRede;
