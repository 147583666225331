import React, { useState } from 'react';
import CustomDialog from 'components/dialog/CustomDialog';
import { Grid, InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import AreaCityList from 'components/area/city/search/AreaCityList';
import PropTypes from 'prop-types';
import { api } from 'services/api';

AreaCitySearch.propTypes = {
  onExited: PropTypes.func.isRequired,
  handleSetArea: PropTypes.func.isRequired,
};

const interval = 500;
let timer = null;

export default function AreaCitySearch({ onExited, handleSetArea }) {
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = event => {
    const query = event.target.value;
    setSearch(query);

    clearTimeout(timer);

    if (query.length > 2)
      timer = setTimeout(() => {
        setLoading(true);

        api
          .get('/cities/search/' + query)
          .then(response => {
            setData(
              response.data.map(city => {
                city.city_id = city.id;
                return city;
              })
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }, interval);
    else setData([]);
  };

  return (
    <CustomDialog maxWidth="sm" title="Adicionar cidade" handleModalState={onExited}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <TextField
            value={search}
            onChange={handleChange}
            margin="normal"
            label="Busque a cidade"
            placeholder="Digite o nome da cidade"
            fullWidth
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            disabled={loading}
            helperText={loading && 'Pesquisando...'}
          />
        </Grid>
        {data.length > 0 && (
          <Grid item xs={12}>
            <AreaCityList handleSetArea={handleSetArea} data={data} />
          </Grid>
        )}
      </Grid>
    </CustomDialog>
  );
}
